import React, { Fragment, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Row, Table, Col, Container, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
//import upload from "./upload.php";
import VcGmdSidebar from "../../component/global/vc-deals-gdm/vc-deals-gdm-sidebar";
import $ from "jquery";
import { Link } from "react-router-dom";
//import Autocomplete from "material-ui/AutoComplete";
//import { makeStyles } from "material-ui/styles";
import Autocomplete from "react-autocomplete";
import {
  matchCompany,
  matchSector,
} from "../../component/global/investment-intellegence-screener/data";
//import window from "react-global";
import "../create-deal/create-deal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Signin from "../../pages/signin/signinIndex";
import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Vc_deals_Gmd_Edit extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleState = this.handleState.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.onChange = (editorState) => this.setState({ editorState });
    this.getLabel = this.getLabel.bind(this);
    this.getLabel1 = this.getLabel1.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.state = {
      dealheadline: "",
      dealcodename: "",
      refresh: "",
      //editorState: EditorState.createEmpty(),
      user: "",
      filterdata: "",
      name_info: "",
      startupname: "",
      startupincorporatedcountry: "",
      selectcategory: "",
      fundinground: "",
      startupestablisheddate: "",
      totalnoofemployees: "",
      startupfounders: "",
      totalamountseeking: "",
      financialstatementsaudited: "",
      title: "",
      email: "",
      fund_round: [],
      contactnumber: "",
      youauthorizedcreatedealbehalfcompany: "",
      items: [],
      sector_data: "",
      result: false,
      country: [],
      category: [],
      sector: [],
      country_name: "",
      sector_name: "",
      companysubindustry: "",
      filter: "",
      subIndustry: [],
      old_route: "",
      pageSize: 25,
      historicalfinancialstatementscompanyaudited: "",
      fiscalyearend: "",
      reportingcurrency: "",
      itemcurrency: [],
      projectedfiscalyearrevenue: "",
      projectedfiscalyearEBITDA: "",
      projectedfiscalyeartotalassets: "",
      lastfiscalyearrevenue: "",
      lastfiscalyearEBITDA: "",
      lastfiscalyeartotalassets: "",
      count: 0,
      file: "",
      regions: [],
      region: "",
      companydealbriefdescription: "",
      fileother: "",
      filepicture: "",
      reportedDate: new Date(),
      reported_date: "",
      refresh: "",
      id: "",
      //editorState: EditorState.createEmpty(),
      sector_data: "",
      result: false,
      country: [],
      sector: [],
      filter: "",
      old_route: "",
      pageSize: 25,
      count: 0,
      emailentry: "",
      file: "",
      fileother: "",
      filepicture: "",
      reportedDate: new Date(),
      reported_date: "",
      details: [],
      sendkey: false,
      refresh: "",
      labelitem: false,
      labelitem1: false,
      status: "",
      filter: "",
    };
  }
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/gdmvcsingle/" +
        this.props.match.params.dealheadline +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        details: res.data,
        // file_teaser: res.data.teaserresentation,
        // acquirersector: res.data.acquirercompany,
        // acquirerURL: res.data.projectpicturestoupload,
        id: res.data.id,
      });
    });
  };

  getLabel = () => {
    const { details } = this.state;
    this.setState({
      labelitem: true,
    });
  };
  getLabel1 = () => {
    const { details } = this.state;
    this.setState({
      labelitem1: true,
    });
  };

  refreshButton = () => {
    this.setState({
      refresh: true,
    });
  };

  handleState = () => {
    this.setState({
      result: false,
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      dealbriefdescription: e.target.getContent(),
    });

    console.log("Content was updated:", this.state.dealbriefdescription);
  };

  handleChange1 = (e) => {
    if (["SubIndustry", "Sector"].includes(e.target.name)) {
      let taskList = [...this.state.taskList];
      taskList[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ [e.target.name]: e.target.value });
      for (let i = 0; i < 2; i++) {
        console.log(taskList[e.target.dataset.id].length, "Sector");
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addNewRow = () => {
    this.setState((prevState) => ({
      taskList: [
        ...prevState.taskList,
        {
          index: Math.random(),
          SubIndustry: "",
          Sector: "",
        },
      ],
    }));
  };

  deteteRow = (index) => {
    this.setState({
      taskList: this.state.taskList.filter((s, sindex) => index !== sindex),
    });
    // const taskList1 = [...this.state.taskList];
    // taskList1.splice(index, 1);
    // this.setState({ taskList: taskList1 });
  };
  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      sendkey: true,
    });
    console.log(this.state.sendkey, "sendkey");
    console.log("Selected file:", this.state.file);

    //.log("Selected file:", event.target.files[0]);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.date === "" || this.state.description === "") {
      //   NotificationManager.warning(
      //     "Please Fill up  Field . Please check Task and Date Field"
      //   );
      return false;
    }
    for (var i = 0; i < this.state.taskList.length; i++) {
      if (
        this.state.taskList[i].projectName === "" ||
        this.state.taskList[i].task === ""
      ) {
        return false;
      }
    }
    let data = { formData: this.state, userData: localStorage.getItem("user") };
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "token"
    );
    axios
      .post("http://localhost:9000/api/task", data)
      .then(() => {
        // if (res.data.success) NotificationManager.success(res.data.msg);
      })
      .catch(() => {
        //     if (error.response.status && error.response.status === 400)
        //       NotificationManager.error("Bad Request");
        //     else NotificationManager.error("Something Went Wrong");
        //     this.setState({ errors: error });
      });
  };
  onSubmit(e) {
    e.preventDefault();
    const { dealheadline } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set("id", this.state.id);
    bodyFormData.set(
      "dealheadline",
      dealheadline == "" ? this.state.details.dealheadline : dealheadline
    );
    bodyFormData.set(
      "dealcodename",
      this.state.dealcodename === ""
        ? this.state.details.dealcodename
        : this.state.dealcodename
    );
    bodyFormData.set(
      "startupname",
      this.state.startupname === ""
        ? this.state.details.startupname
        : this.state.startupname
    );
    bodyFormData.set(
      "startupincorporatedcountry",
      this.state.startupincorporatedcountry === ""
        ? this.state.details.startupincorporatedcountry
        : this.state.startupincorporatedcountry
    );
    bodyFormData.set(
      "startupsector",
      this.state.sector_name === ""
        ? this.state.details.startupsector
        : this.state.sector_name
    );
    bodyFormData.set(
      "reportingcurrency",
      this.state.reportingcurrency === ""
        ? this.state.details.reportingcurrency
        : this.state.reportingcurrency
    );

    bodyFormData.set(
      "selectcategory",
      this.state.selectcategory === ""
        ? this.state.details.selectcategory
        : this.state.selectcategory
    );
    bodyFormData.set(
      "fundinground",
      this.state.fundinground === ""
        ? this.state.details.fundinground
        : this.state.fundinground
    );
    bodyFormData.set(
      "startupestablisheddate",
      this.state.reported_date === ""
        ? this.state.details.startupestablisheddate
        : this.state.reported_date
    );
    bodyFormData.set(
      "jointventurepartnersector",
      this.state.jointventurepartnersector === ""
        ? this.state.details.jointventurepartnersector
        : this.state.jointventurepartnersector
    );
    bodyFormData.set(
      "totalnoofemployees",
      this.state.totalnoofemployees === ""
        ? this.state.details.totalnoofemployees
        : this.state.totalnoofemployees
    );
    bodyFormData.set(
      "startupfounders",
      this.state.startupfounders === ""
        ? this.state.details.startupfounders
        : this.state.startupfounders
    );
    bodyFormData.set(
      "totalamountseeking",
      this.state.totalamountseeking === ""
        ? this.state.details.totalamountseeking
        : this.state.totalamountseeking
    );
    bodyFormData.set(
      "fiscalyearend",
      this.state.fiscalyearend === ""
        ? this.state.details.fiscalyearend
        : this.state.fiscalyearend
    );
    bodyFormData.set(
      "lastfiscalyearrevenue",
      this.state.lastfiscalyearrevenue === ""
        ? this.state.details.lastfiscalyearrevenue
        : this.state.lastfiscalyearrevenue
    );
    bodyFormData.set(
      "lastfiscalyearEBITDA",
      this.state.lastfiscalyearEBITDA === ""
        ? this.state.details.lastfiscalyearEBITDA
        : this.state.lastfiscalyearEBITDA
    );
    bodyFormData.set(
      "lastfiscalyeartotalassets",
      this.state.lastfiscalyeartotalassets === ""
        ? this.state.details.lastfiscalyeartotalassets
        : this.state.lastfiscalyeartotalassets
    );

    bodyFormData.set(
      "projectedfiscalyearrevenue",
      this.state.projectedfiscalyearrevenue === ""
        ? this.state.details.projectedfiscalyearrevenue
        : this.state.projectedfiscalyearrevenue
    );
    bodyFormData.set(
      "projectedfiscalyearEBITDA",
      this.state.projectedfiscalyearEBITDA === ""
        ? this.state.details.projectedfiscalyearEBITDA
        : this.state.projectedfiscalyearEBITDA
    );
    bodyFormData.set(
      "projectedfiscalyeartotalassets",
      this.state.projectedfiscalyeartotalassets === ""
        ? this.state.details.projectedfiscalyeartotalassets
        : this.state.projectedfiscalyeartotalassets
    );

    bodyFormData.set(
      "financialstatementsaudited",
      this.state.financialstatementsaudited === ""
        ? this.state.details.financialstatementsaudited
        : this.state.financialstatementsaudited
    );

    bodyFormData.set(
      "companydealbriefdescription",
      this.state.companydealbriefdescription === ""
        ? this.state.details.companydealbriefdescription
        : this.state.companydealbriefdescription
    );
    bodyFormData.set(
      "name",
      this.state.name_info === ""
        ? this.state.details.name
        : this.state.name_info
    );
    bodyFormData.set(
      "title",
      this.state.title === "" ? this.state.details.title : this.state.title
    );
    bodyFormData.set(
      "email",
      this.state.emailentry === ""
        ? this.state.details.email
        : this.state.emailentry
    );
    bodyFormData.set(
      "contactnumber",
      this.state.contactnumber === ""
        ? this.state.details.contactnumber
        : this.state.contactnumber
    );
    bodyFormData.set(
      "userdealstatus",
      this.state.status === ""
        ? this.state.details.userdealstatus
        : this.state.status
    );

    if (this.state.sendkey === true) {
      bodyFormData.set(
        "teaserpresentation",
        this.state.file === ""
          ? this.state.details.teaserpresentation
          : this.state.file
      );
      console.log(this.state.file, "file send key");
    }
    if (this.state.sendkey === true) {
      bodyFormData.set(
        "otherdocumentstoupload",
        this.state.fileother === ""
          ? this.state.details.otherdocumentstoupload
          : this.state.fileother
      );
      console.log(this.state.file, "file send key");
    }
    if (this.state.sendkey === true) {
      bodyFormData.set(
        "projectpicturevideotoupload",
        this.state.filepicture === ""
          ? this.state.details.projectpicturevideotoupload
          : this.state.filepicture
      );
      console.log(this.state.file, "file send key");
    }
    bodyFormData.set(
      "youauthorizedcreatedealbehalfcompany",
      this.state.youauthorizedcreatedealbehalfcompany === ""
        ? this.state.details.youauthorizedcreatedealbehalfcompany
        : this.state.youauthorizedcreatedealbehalfcompany
    );

    bodyFormData.set("posted_by", this.state.user);

    axios
      .post("https://api.analyzemarkets.com/gdmvcform/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          this.setState({
            result: true,
          });
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }
  componentDidMount = () => {
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });
    // api({
    //   url: "https://api.analyzemarkets.com/country/",
    //   method: "get",
    // }).then((res) => {
    //   // Here you need to use an temporary array to store NeededInfo only
    //   let tmpArray = [];
    //   for (var i = 0; i < res.data.results.length; i++) {
    //     tmpArray.push({
    //       label: res.data.results[i].country,
    //       value: res.data.results[i].country,
    //     });
    //   }
    //   //console.log(tmpArray, "tmArray");
    //   this.setState({
    //     country: tmpArray,
    //   });
    //   //console.log(this.state.country, "tmArray");
    // });
    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ sector: res.data.results });
      }
    });
    axios
      .get("https://api.analyzemarkets.com/reportingcurrency/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ itemcurrency: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    api({
      url: "https://api.analyzemarkets.com/fundinground/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ fund_round: res.data });
      }
    });

    api({
      url: "https://api.analyzemarkets.com/regman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ regions: res.data.results });
      }
    });
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }

    axios
      .get("https://api.analyzemarkets.com/startupcategory/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ category: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    api({
      url: "https://api.analyzemarkets.com/financingtype/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ financial_type: res.data });
      }
    });
    axios
      .get("https://api.analyzemarkets.com/reportingcurrency/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ itemcurrency: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    api({
      url: "https://api.analyzemarkets.com/shariahcompliantfinancing/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ complete_financing: res.data });
      }
    });

    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    api({
      url: "https://api.analyzemarkets.com/companysubindustry/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ subIndustry: res.data });
      }
    });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };

  clickOnDelete(record) {
    this.setState({
      taskList: this.state.taskList.filter((r) => r !== record),
    });
  }
  render() {
    const data = this.state.details;
    // var url = this.state.file_teaser;
    // var urls = this.state.acquirersector;
    // var urlss = this.state.acquirerURL;
    // var url1 = url !== null && url.replace(/^.*[\\\/]/, "");
    // var url2 = urls !== null && urls.replace(/^.*[\\\/]/, "");
    // var url3 = urlss !== null && urlss.replace(/^.*[\\\/]/, "");

    // const url2 = url1.replace("/%20/g", " ");

    //console.log(url, "Teaser");

    let { taskList } = this.state; //let { notes, date, description, taskList } = this.state
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Navfilter />
              {this.state.result}
              <Row className="centerAlign">
                {/* <Col className="w-30p col-sm-3 p-4">
              <div className="widget"></div>
            </Col> */}

                <Col className="">
                  <form onSubmit={this.onSubmit}>
                    <div className="iis greyborder2px">
                      <h4 className="text-left">Edit a Deal</h4>
                      <div className="pdg15">
                        <fieldset>
                          <legend>
                            <b className="upercase">About Startup</b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="dealheadline"
                                defaultValue={data.dealheadline}
                                type="text"
                                placeholder="Deal Headline* (Max 100 characters)
                                  e.g. Health care company is looking for growth capital investment"
                                onChange={this.changeTerm}
                                maxLength="100"
                              />
                            </Col>
                          </Row>

                          <Row className="">
                            <Col>
                              <Form.Control
                                name="dealcodename"
                                defaultValue={data.dealcodename}
                                type="text"
                                placeholder="Deal Code Name *e.g. Project Sun"
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                name="startupname"
                                defaultValue={data.startupname}
                                type="text"
                                placeholder="Startup Name (Optional)"
                                onChange={this.changeTerm}
                              />
                            </Col>

                            <Form.Group as={Col} controlId="ChooseCountry">
                              <Form.Control
                                as="select"
                                name="startupincorporatedcountry"
                                defaultValue={data.startupincorporatedcountry}
                                onChange={this.changeTerm}
                                placeholder="Startup Incorporated Country"
                                rows="3"
                              >
                                {data.startupincorporatedcountry !== null ? (
                                  <option
                                    value={data.startupincorporatedcountry}
                                    className=""
                                  >
                                    {data.startupincorporatedcountry}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Startup Incorporated Country
                                  </option>
                                )}

                                {this.state.country.map((item, i) => (
                                  <option
                                    key={i}
                                    value={
                                      item.country ===
                                      data.startupincorporatedcountry
                                        ? "null"
                                        : item.country
                                    }
                                  >
                                    {item.country}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Row>

                          <Row className="">
                            <Form.Group as={Col} controlId="ChooseCountry">
                              <Form.Control
                                as="select"
                                name="selectcategory"
                                onChange={this.changeTerm}
                                placeholder="Startup Category*"
                                rows="3"
                              >
                                {data.selectcategory !== null ? (
                                  <option
                                    value={data.selectcategory}
                                    className=""
                                  >
                                    {data.selectcategory}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Startup Category
                                  </option>
                                )}

                                {this.state.category.map((item, i) => (
                                  <option
                                    key={i}
                                    value={
                                      item.name === data.selectcategory
                                        ? "null"
                                        : item.name
                                    }
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            <Col>
                              <div
                                className="form-group autoc"
                                //className={classes.root}
                              >
                                <Form.Control
                                  as="select"
                                  name="sector_name"
                                  onChange={this.changeTerm}

                                  // placeholder="Are you Authorized to create this deal"
                                >
                                  {" "}
                                  <option value={data.startupsector}>
                                    {data.startupsector}
                                  </option>
                                  <option value="">Startup Sector*</option>
                                  {this.state.sector.map((item, i) => (
                                    <option key={i} value={item.sector}>
                                      {item.sector}
                                    </option>
                                  ))}
                                </Form.Control>
                              </div>
                            </Col>

                            <Col>
                              <div
                                className="form-group autoc"
                                //className={classes.root}
                              >
                                <Form.Control
                                  as="select"
                                  name="fundinground"
                                  onChange={this.changeTerm}

                                  // placeholder="Are you Authorized to create this deal"
                                >
                                  <option value={data.fundinground}>
                                    {data.fundinground}
                                  </option>

                                  <option value="">Funding Round*</option>
                                  {this.state.fund_round.map((item, i) => (
                                    <option key={i} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Form.Control>
                              </div>
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <Form>
                                <label>Startup Established Date*</label>

                                <div className="d-flex justify-content-between date-range">
                                  <DatePicker
                                    selected={this.state.reportedDate}
                                    onChange={this.handleReportedDate}
                                  />
                                </div>
                              </Form>
                            </Col>

                            <Col>
                              <label className="colorWhite">
                                Total No. of Employees*
                              </label>
                              <Form.Control
                                name="totalnoofemployees"
                                type="text"
                                defaultValue={data.totalnoofemployees}
                                onChange={this.changeTerm}
                                placeholder="Total No. of Employees*"
                              />
                            </Col>

                            <Col>
                              <label className="colorWhite">
                                Startup Founders (optional)*
                              </label>
                              <Form.Control
                                name="startupfounders"
                                type="text"
                                defaultValue={data.startupfounders}
                                onChange={this.changeTerm}
                                placeholder="Startup Founders (optional)"
                              />
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col sm={6}>
                              <Form.Control
                                name="totalamountseeking"
                                type="text"
                                defaultValue={data.totalamountseeking}
                                onChange={this.changeTerm}
                                placeholder="Total Amount Seeking (USD mn)*"
                              />
                            </Col>
                          </Row>

                          <div className="form-group">
                            <Row className="">
                              <Col>
                                <Editor
                                  initialValue={
                                    data.companydealbriefdescription
                                  }
                                  value={data.companydealbriefdescription}
                                  apiKey="l7d3ab4ybjfthcy2c4ya6vsj9rlub27d3sdqk433oyis6ild"
                                  init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                      "advlist autolink lists link image",
                                      "charmap print preview anchor help",
                                      "searchreplace visualblocks code",
                                      "insertdatetime media table paste wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help",
                                  }}
                                  onChange={this.handleEditorChange}
                                />

                                {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                  as="textarea"
                                  name="companydealbriefdescription"
                                  rows="7"
                                  maxLength="100"
                                  onChange={this.changeTerm}
                                  placeholder="Company/Deal Brief Description* (Maximum 100 words)"
                                />
                              </Form.Group> */}
                              </Col>{" "}
                            </Row>
                          </div>

                          <legend>
                            {" "}
                            <b className="upercase">Key Financials</b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                as="select"
                                name="financialstatementsaudited"
                                onChange={this.changeTerm}
                                // placeholder="Are you Authorized to create this deal"
                              >
                                <option value={data.financialstatementsaudited}>
                                  {data.financialstatementsaudited}
                                </option>

                                <option value="">
                                  Financial Statements Audited*
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Form.Control>
                            </Col>

                            {console.log(this.state.file, "Type of Audit")}
                            <Col>
                              <Form.Control
                                as="select"
                                name="fiscalyearend"
                                onChange={this.changeTerm}
                                placeholder="Fiscal Year End*"
                                rows="3"
                              >
                                <option value={data.fiscalyearend} className="">
                                  {data.fiscalyearend}
                                </option>

                                <option value="" className="">
                                  Fiscal Year End*
                                </option>
                                <option value="Janaury">Janaury</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                              </Form.Control>
                            </Col>

                            <Form.Group as={Col} controlId="ChooseCountry">
                              <Form.Control
                                as="select"
                                name="reportingcurrency"
                                onChange={this.changeTerm}
                                placeholder="Reporting Currency*"
                                rows="3"
                              >
                                <option
                                  value={data.reportingcurrency}
                                  className=""
                                >
                                  {data.reportingcurrency}
                                </option>

                                <option value="" className="">
                                  Reporting Currency*
                                </option>
                                {this.state.itemcurrency.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.reportingcurrency}
                                    className="word-wrap"
                                  >
                                    {item.reportingcurrency}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Col>
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr>
                                    <th width="200"></th>
                                    <th className="colorWhite" width="200">
                                      Last Fiscal Year
                                    </th>
                                    <th className="colorWhite" width="200">
                                      Projected Fiscal Year
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Revenue (USD mn)</td>
                                    <td>
                                      {" "}
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="lastfiscalyearrevenue"
                                            type="text"
                                            defaultValue={
                                              data.lastfiscalyearrevenue
                                            }
                                            placeholder="Required"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      {" "}
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="projectedfiscalyearrevenue"
                                            defaultValue={
                                              data.projectedfiscalyearrevenue
                                            }
                                            type="text"
                                            placeholder="Optional"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>EBITDA (USD mn)</td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="lastfiscalyearEBITDA"
                                            type="text"
                                            defaultValue={
                                              data.lastfiscalyearEBITDA
                                            }
                                            placeholder="Required"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="projectedfiscalyearEBITDA"
                                            type="text"
                                            defaultValue={
                                              data.projectedfiscalyearEBITDA
                                            }
                                            placeholder="Optional"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Total Assests (USD mn)</td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="lastfiscalyeartotalassets"
                                            type="text"
                                            defaultValue={
                                              data.lastfiscalyeartotalassets
                                            }
                                            placeholder="Required"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="projectedfiscalyeartotalassets"
                                            type="text"
                                            defaultValue={
                                              data.projectedfiscalyeartotalassets
                                            }
                                            placeholder="Optional"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>

                          <legend>
                            <b className="upercase">Requirement Documents</b>
                          </legend>
                          <Form.Group>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label> Teaser/Presentation</label>
                              )}

                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label> Teaser/Presentation</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="file"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.teaserpresentationname}
                                      </label>
                                    </div>{" "}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="file"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "File Name")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>
                            </div>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label>Other Documents to upload:</label>
                              )}
                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label>Other Documents to upload:</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="fileother"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.otherdocumentstouploadname}
                                      </label>
                                    </div>{" "}
                                    {console.log(
                                      " file count:",
                                      this.state.fileother
                                    )}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="fileother"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "Type of Audit")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>{" "}
                            </div>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label> Picture and video to upload</label>
                              )}{" "}
                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label> Picture and video to upload</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="filepicture"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.projectpicturevideotouploadname}
                                      </label>
                                    </div>{" "}
                                    {console.log(
                                      " file count:",
                                      this.state.file
                                    )}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="filepicture"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "Type of Audit")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>
                            </div>
                          </Form.Group>

                          <legend>
                            <b className="upercase">
                              Contact Information For Potential Investors
                            </b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="name_info"
                                defaultValue={data.name}
                                type="text"
                                placeholder="Name*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                name="title"
                                defaultValue={data.title}
                                type="text"
                                placeholder="Title*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="emailentry"
                                defaultValue={data.email}
                                type="email"
                                placeholder="Email*"
                                onChange={this.changeTerm}
                              />
                            </Col>

                            <Col>
                              <Form.Control
                                name="contactnumber"
                                type="tel"
                                defaultValue={data.contactnumber}
                                placeholder="Contact number*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col>
                              <label>
                                Are you authorized to create this deal on behalf
                                of the Company*:{" "}
                              </label>
                              <Form.Control
                                as="select"
                                name="youauthorizedcreatedealbehalfcompany"
                                onChange={this.changeTerm}
                                // placeholder="Are you Authorized to create this deal"
                              >
                                {data.youauthorizedcreatedealbehalfcompany !==
                                null ? (
                                  <option
                                    value={
                                      data.youauthorizedcreatedealbehalfcompany
                                    }
                                    className=""
                                  >
                                    {data.youauthorizedcreatedealbehalfcompany}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Are you authorized to create the deal
                                  </option>
                                )}
                                <option
                                  value={
                                    "Yes" ===
                                    data.youauthorizedcreatedealbehalfcompany
                                      ? "null"
                                      : "Yes"
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value={
                                    "No" ===
                                    data.youauthorizedcreatedealbehalfcompany
                                      ? "null"
                                      : "No"
                                  }
                                >
                                  No
                                </option>
                              </Form.Control>
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <label>Deal Status</label>

                              <Form.Control
                                as="select"
                                name="status"
                                onChange={this.changeTerm}
                              >
                                {data.userdealstatus !== null ? (
                                  <option
                                    value={data.userdealstatus}
                                    className=""
                                  >
                                    {data.userdealstatus}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Deal Status *
                                  </option>
                                )}

                                <option
                                  value={
                                    "Active" === data.userdealstatus
                                      ? "null"
                                      : "Active"
                                  }
                                >
                                  Active
                                </option>
                                <option
                                  value={
                                    "Inactive" === data.userdealstatus
                                      ? "null"
                                      : "Inactive"
                                  }
                                >
                                  Inactive
                                </option>
                              </Form.Control>
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col sm={3}>
                              <Button
                                variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                type="submit"
                                size="sm"
                              >
                                Publish Deal
                              </Button>
                            </Col>
                            <Col>
                              <Link
                                className="btn btn-blue mt-4 mb-4 shadow"
                                onClick={this.refreshButton}
                              >
                                Reset
                              </Link>
                            </Col>

                            <Col></Col>
                          </Row>
                        </fieldset>
                        {this.state.refresh && window.location.reload()}

                        {this.state.result && (
                          <div className="borderColors1">
                            <span
                              className="buttonFloat"
                              onClick={this.handleState}
                            >
                              <i className="fa fa-times-circle"></i>
                            </span>
                            <span>
                              <i
                                class="far fa-check-circle"
                                style={{ color: "green" }}
                              ></i>
                            </span>
                            &nbsp;
                            <span>
                              <h4>
                                {" "}
                                <b className="colorError">
                                  Your Deal is Successfully Edited
                                </b>
                              </h4>
                              <br></br>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default Vc_deals_Gmd_Edit;
