import React, { Component, Fragment } from "react";
import { Row, Table, Col, Container } from "react-bootstrap";
import "../create-deal-sidebar/create-deal-sidebar.css";
import { Link, NavLink } from "react-router-dom";
class Gcd_Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signin: [],
      email: "",
      token: "",
      response: "",
      user: "",
      count: "",
      login: [],
      results: false,

      hasError: false,
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  render() {
    return (
      <Fragment>
        <Container>
          <div>
            <Row className="show-grid">
              <Col className="borderstyle">
                <b className="ma-title uppercase">Quick Links</b>
                <p className="border_bottom"></p>
                <div>
                  <p className="font-16 suburls">
                    <NavLink
                      activeClassName="navbar__link--active1"
                      to={"/en/global-company-database/"}
                      className="ma_sidebar_color"
                    >
                      Companies Database Monitor
                    </NavLink>
                  </p>

                  <p className="border_bottomstyle"></p>
                </div>

                <div>
                  <b className="subheading1">Advanced Search</b>
                  <p className="font-16 suburls">
                    <NavLink
                      activeClassName="navbar__link--active1"
                      to={"/en/global_company_search/"}
                      className="ma_sidebar_color"
                    >
                      Global Company Database Search
                    </NavLink>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default Gcd_Sidebar;
