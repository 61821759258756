import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import Linkify from "react-linkify";
import { Helmet } from "react-helmet";
import Signin from "../../pages/signin/signinIndex";
import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class grr_details extends Component {
  constructor(props) {
    super(props);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    details: [],
    contributor: [],
    companyfocus: [],
    filterdata: "",
  };
  componentDidMount = () => {
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/globalrrdsingle/" +
        this.props.match.params.title +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Report Detail");
      this.setState({
        details: res.data,
        contributor: res.data.reportcontributor,
        companyfocus: res.data.companyfocus,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();
    });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const data = this.state.details;

    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Navfilter id="sector-desktop"></Navfilter>
            <Container>
              <Helmet title={data.reportype}>
                <meta name="description" content={data.dealbriefdescription} />
                <meta property="og:title" content="M&A Deals Monitor" />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  name="og:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  itemprop="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Button
                onClick={this.handleBack}
                variant="outline-light"
                className="MarginRight padDown"
              >
                <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
              </Button>
              <h3 className="UpperCase padDown">{data.title}</h3>
              <br></br>

              <Row>
                <Col className="">
                  <Row className="">
                    <Col xs sm={12} class="">
                      <Row>
                        <Col sm={5}>
                          {" "}
                          <div class="" id="5">
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td>Documents</td>
                                  <td>
                                    <a
                                      href={data.uploadfile}
                                      download={data.title}
                                      target="_blank"
                                    >
                                      download
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Report Contributor</td>
                                  {this.state.contributor.map((item, index) => (
                                    <td>
                                      {this.state.contributor !== null && (
                                        <Link
                                          to={
                                            "/en/global-company-database-details/" +
                                            item.api_url +
                                            "/"
                                          }
                                        >
                                          {item.name}
                                        </Link>
                                      )}
                                    </td>
                                  ))}
                                </tr>
                                <tr>
                                  <td>Publication Date</td>
                                  <td>{data.publicationdate}</td>
                                </tr>
                                <tr>
                                  <td>No of Pages</td>
                                  <td>{data.noofpages}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <br></br>
                        </Col>
                        <Col sm={7}>
                          <div class=" hero" id="1">
                            <Table
                              className="tblnoline"
                              size="lg"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td width="150">Report Type</td>

                                  <td>{data.reportype}</td>
                                </tr>

                                <tr>
                                  <td>Region Focus</td>
                                  <td>{data.regionfocus}</td>
                                </tr>

                                <tr>
                                  <td>Sub-Region Focus</td>

                                  <td>{data.subregionfocus}</td>
                                </tr>
                                <tr>
                                  <td width="150" className="space">
                                    Country Focus
                                  </td>

                                  <td>{data.countryfocus}</td>
                                </tr>
                                <tr>
                                  <td>Sector Focus</td>

                                  <td>{data.sectorfocus}</td>
                                </tr>

                                <tr>
                                  <td>Company Focus</td>
                                  {this.state.companyfocus.map(
                                    (item, index) => (
                                      <td>
                                        {this.state.companyfocus !== null && (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              item.api_url +
                                              "/"
                                            }
                                          >
                                            {item.name}
                                          </Link>
                                        )}
                                      </td>
                                    )
                                  )}
                                </tr>

                                <tr>
                                  <td>Deal Reference Number</td>
                                  <td>{data.dealinternalreferencenumber}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>

                          {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default grr_details;
