import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import "../v&c_dealmonitor/v&c_dealmonitor.css";
import {
  Row,
  Table,
  Col,
  Container,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import $ from "jquery";
import { Redirect } from "react-router";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import Pagination from "../../component/global/pagination/index";
import "../../pages/press-release/press-release.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import GlobalCompanyDatabse from "../../component/global/companytable-data/gcd-sidebar";
import Signin from "../../pages/signin/signinIndex";
import { Helmet } from "react-helmet";
import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Gcd_Company_monitor extends Component {
  constructor(props) {
    super(props);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    items: [],
    show_100: false,
    submenu: "",
    exampleItems: [],
    company: [],
    firstmenu: "",
    filterdata: "",
    page: 1,
    current: 0,
    country: [],
    filter: "",
    old_route: "",
    pageSize: 25,
    count: 0,
    is_first: true,
    token: "",
    response: "",
    user: "",
    count: "",
  };
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.getData(this.state.old_route, filterdata, 0, true);
  };
  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      // this.getData("", this.state.filter, page, false);
      if (this.state.filterdata !== "") {
        this.getData("", this.state.filterdata, page, false);
      }

      console.log(55);
    }
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    if (this.state.filterdata !== "") {
      //this.getData1(this.state.filterdata);
      this.getData(current_route, this.state.filterdata, 0, false);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  getData = (route, filterdata, page, is_first) => {
    api({
      url:
        "https://api.analyzemarkets.com/companymanagementprivate/?name=" +
        route +
        "&start=" +
        page +
        "" +
        filterdata,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Company Monitor");
      this.setState({
        items: res.data.result,
        page: res.data.totalcount,
        current: page,
        is_first: false,
      });
      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.totalcount).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.totalcount,
        });
      }
      console.log(res.data.length + "Count Data");
    });
  };

  render() {
    const { data } = this.state;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header />
            <Container>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Helmet title="Global Company Database Monitor">
                <meta
                  name="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta property="og:title" content="M&A Deals Monitor" />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  name="og:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  itemprop="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>

              <Navfilter id="sector-desktop" className="d-lg-none"></Navfilter>

              <Row>
                <Col className="w-30p p-4">
                  <div className="widget">
                    <GlobalCompanyDatabse />
                  </div>
                </Col>

                <Col className="w-70p p-4">
                  <div>
                    <div class="container">
                      <div>
                        <Table
                          striped
                          bordered
                          hover
                          id="table-id"
                          className="tblnoline grid"
                          size="sm"
                          responsive="lg"
                        >
                          <thead>
                            <tr>
                              <th index="0">
                                <span className="table-header">Company</span>{" "}
                                <div class=""></div>
                              </th>
                              <th index="1" width="150">
                                <span className="table-header">Region</span>

                                <div class=""></div>
                              </th>
                              <th index="2">
                                <span className="table-header">Country</span>

                                <div class=""></div>
                              </th>

                              <th index="4">
                                <span className="table-header">Sector</span>

                                <div class=""></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.items.map((item, key) => (
                              <tr>
                                <td>
                                  {item.api_url != null ? (
                                    <Link
                                      to={
                                        "/en/global-company-database-details/" +
                                        item.api_url +
                                        "/"
                                      }
                                    >
                                      {item.name}
                                    </Link>
                                  ) : (
                                    <Link>
                                      <u className="underline">{item.name}</u>
                                    </Link>
                                  )}
                                </td>
                                <td>{item.region}</td>

                                <td>{item.country}</td>
                                <td>{item.sector}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                      <div className="products-view__options justify-content-between mt-4">
                        {/* {this.state.count > 10 && (
                  <div className="view-options__control">
                    <label>Results Per Page</label>
                    <div></div>
                  </div>
                )} */}
                        <Pagination
                          items={this.state.exampleItems}
                          onChangePage={this.onChangePage}
                          pageSize={this.state.pageSize}
                        ></Pagination>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default Gcd_Company_monitor;
