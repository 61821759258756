import React, { Component, Fragment } from "react";
import "./gdi-home.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Form, Button } from "react-bootstrap/";
import Datascience from "../../assets/images/DATA-SCIENCE-TEAM.svg";
import GlobalInfrastructure from "../../assets/images/GLOBAL-INFRASTRUCTURE.svg";
import Databasemm from "../../assets/images/DATABASE.svg";
import Datacollection from "../../assets/images/DATA-COLLECTION.svg";
import Stattics from "../../assets/images/STATISTICs.svg";
import B2b from "../../assets/images/B2B.svg";
import Dashboardreport from "../../assets/images/DASHBOARD-REPORT.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";
import AMDealsIntelligence from "../../assets/images/deals_intelligence_logo.png";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class GdiHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      firstname: null,
      lastname: null,
      company_name: null,
      company_url: null,
      job_title: null,
      country: null,
      contact_number: null,
      free_trial_demonstration: null,
      result: "",
      is_email: true,
      is_firstname: true,
      is_lastname: true,
      is_company_name: true,
      is_company_url: true,
      is_job_title: true,
      is_country: true,
      is_contact_number: true,
      country_list: [],
      error: "",
      plan: "",
      planCost: null,
      succeeded: false,
      error: null,
      processing: '',
      disabled: true,
      clientSecret: '',
      freeTrial: false,
      email: "",
      rememberMe: true,
      response: "",
      token: "",
      user: "",
      action: true,
      id: "",
      result: "",
      refresh: "",
      login_flag: "True",
      logout_flag: "False",
    };
    this.onSubscribe = this.onSubscribe.bind(this);
    this.onSigninChange = this.onSigninChange.bind(this);
  }

  onChange(e) {
    console.log(e.target.name);
    if (e.target.name === "email") {
      if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(e.target.value)) {
        console.log(47);
        this.setState({
          is_email: false,
        });
      } else {
        this.setState({
          is_email: true,
        });
      }
    }

    if (e.target.name === "company_url") {
      if (
        !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
          e.target.value
        )
      ) {
        console.log("Url Is not valida");
        this.setState({
          is_company_url: false,
        });
      } else {
        this.setState({
          is_company_url: true,
        });
      }
    }

    if (e.target.name === "contact_number") {
      if (!/^\+\d{1,4}-[1-9]\d{0,9}$/.test(e.target.value)) {
        this.setState({
          is_contact_number: false,
        });
      } else {
        this.setState({
          is_contact_number: true,
        });
      }
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    console.log(
      /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/.test(
        this.state.email
      ),
      this.state.email
    );
    var bodyFormData = new FormData();

    if (
      /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/.test(
        this.state.email
      ) === false
    ) {
      this.setState({
        error: "Invalid Email Address",
      });
      return 0;
    }

    this.setState({
      error: "",
    });
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("firstname", this.state.firstname);
    bodyFormData.set("lastname", this.state.lastname);
    bodyFormData.set("company_name", this.state.company_name);
    bodyFormData.set("country", this.state.country);
    bodyFormData.set("contact_number", this.state.contact_number);
    bodyFormData.set("plan", this.state.plan);
    bodyFormData.set("planCost", this.state.planCost);
    bodyFormData.set("company_url", this.state.company_url);
    bodyFormData.set("job_title", this.state.job_title);
    if (this.state.freeTrial == true) {
      bodyFormData.set("free_trial_demonstration", true);
    } else {
      bodyFormData.set("free_trial_demonstration", false);
    }

    axios
      .post("https://api.analyzemarkets.com/subscriber_form/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log(30);
          this.setState({
            result:
              "Your request has been submitted. Our sales team will contact you shortly.",
          });
        } else {
          this.setState({ result: "You Are already subscribed!" });
        }
      })
      .catch((error) => {
        //on error
        // alert("There is an error in API call.");
      });

    this.setState({
      freeTrial: false
    });
  }
  handleDropdownChange(e) {
    this.setState({
      country: e.target.value,
    });
  }

  onSubscribe() {
    sessionStorage.setItem("plan", this.state.plan);
    sessionStorage.setItem("planCost", this.state.planCost);
    sessionStorage.setItem("planId", this.state.planId);
  }

  onSigninChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSignin(e) {
    console.log('sign in')
    e.preventDefault();
    const { email, rememberMe } = this.state;
    localStorage.setItem("rememberMe", true);
    console.log(this.state.email, this.state.password)

    var bodyFormData = new FormData();
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("password", this.state.password);
    bodyFormData.set("login_flag", this.state.login_flag);
    bodyFormData.set("logout_flag", this.state.logout_flag);

    axios
      .post("https://api.analyzemarkets.com/signin/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 201) {
          console.log("201 --> ", res.data)
          this.setState({
            hasError: res.data,
            error: true,
          });
          return;
        }
        if (res.status === 200) {
          console.log('200', res.data)
          console.log('200', res.data.subscription_feature)
          if (res.data.subscription_feature.length == 1) {
            console.log(res.data.subscription_feature[0])
            localStorage.setItem('subscription_feature', res.data.subscription_feature[0])
          } else {
            console.log(res.data.subscription_feature[1])
            localStorage.setItem('subscription_feature', res.data.subscription_feature[1])
          }

          if (res.data === "User does not exists") {
            this.setState({
              hasError: res.data,
              error: true,
            });
          } else {
            console.log(res.data.email, "Email Info");
            this.setState({
              response: res.data.email,
              token: res.data.token,
              user: res.data.name,
              action: res.data.isactive,
              id: res.data.id,
              result: true,
              refresh: true,
            });
            localStorage.setItem("response", this.state.response); //Email Login
            if (this.state.result === true) {
              localStorage.setItem("email", this.state.email); //Email Login
            }
            localStorage.setItem("token", this.state.token); //Token Login
            localStorage.setItem("user", this.state.user);
            localStorage.setItem("id", this.state.id);
            localStorage.setItem("action", this.state.action); //Flag

            window.location.href = "investment_wire";
          }
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const plan = sessionStorage.getItem("plan");
    const planCost = sessionStorage.getItem("planCost");
    this.setState({ plan: plan })
    this.setState({ planCost: planCost })
    const freeTrial = JSON.parse(sessionStorage.getItem("freeTrial"));
    if (freeTrial == true) {
      window.scrollTo(0, 500);
    }
    this.setState({ freeTrial: freeTrial })
    sessionStorage.removeItem("freeTrial")

    api({
      url: "https://api.analyzemarkets.com/country/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ country_list: res.data.results });
      }
    });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>AnalyzeMarkets Subscription</title>
            <meta
              name="description"
              content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
            />
            <meta property="og:title" content="AnalyzeMarkets Subscription" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="AnalyzeMarkets Subscription" />

            <meta
              itemprop="description"
              content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="AnalyzeMarkets Subscription" />

            <meta
              name="twitter:description"
              content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            {/* <Col md={12}>
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <CheckoutForm stripe={stripe} elements={elements} />
                  )}
                </ElementsConsumer>
              </Elements>
            </Col> */}

            <Col md={12} lg={6} sm={12}>
              <h5>Subscribe for Access to AnalyzeMarkets' Global Deals Intelligence Platform</h5>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="d-flex align-items-center mb-3 mt-2">
                    <img className="img-fluid mr-3" width="40" src="/static/media/DETERMINE-OBJECT.1cbf71fd.svg" alt="" srcset="" />
                    <span className="font-18">Global <b>M&A Deals</b> Intelligence, Data & Analysis</span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <img className="img-fluid mr-3" width="40" src="/static/media/BUSINESS-INTELLIGENT.20fce05c.svg" alt="" srcset="" />
                    <span className="font-18">Global <b>Venture Capital, IPO, Fundraising and Real Estate Deals</b> Intelligence, Data & Analysis</span>
                  </div>
                  <ul className="subscribe-points">
                    <li className="mt-2" style={{ fontWeight: "400", fontSize: "15px" }}>
                      Provides timely announced private and public M&A deals data with analysis & background information,
                      Deals in the Making, companies seeking buyers and investors seeking targets predictive intelligence
                    </li>
                    <li className="mt-2" style={{ fontWeight: "400", fontSize: "15px" }}>
                      Tracks private equity firms, sovereign wealth funds, institutional investors, and family offices activities
                    </li>
                    <li className="mt-2" style={{ fontWeight: "400", fontSize: "15px" }}>
                      Information about strategic & financial parties involved in transactions, shareholders & backers, multiples, and deal advisors
                    </li>
                    <li className="mt-2" style={{ fontWeight: "400", fontSize: "15px" }}>
                      Comprehensive searches by region, country, sector, parties, advisors, and others with alerts
                    </li>
                  </ul>
                  <div className="d-flex align-items-center flex-column mt-3">
                    <div className="d-flex" style={{ alignItems: "flex-end", justifyContent: "space-evenly", width: "100%" }}>
                      <div className="d-flex align-items-baseline">
                        <input type="radio" name="plan" id="" value="month" onChange={this.onPlanSelection} />
                        <p className="font-26 ml-1"><b>USD 99 / month*</b></p>
                      </div>
                      <div className="d-flex flex-column">
                        <div>
                          <strike className="font-26 ml-3" style={{ color: "rgba(171 171 171 / 73%)" }}> <b>USD 1,188</b> </strike>
                        </div>
                        <div className="d-flex align-items-baseline">
                          <input type="radio" name="plan" id="" value="year" onChange={this.onPlanSelection} />
                          <p className="font-26 ml-1"><b>USD 999 / Year*</b></p>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-2">
                      {
                        this.state.planId == undefined
                          ? <a href="" className="btn" style={{ background: "#1a529c", color: "white" }} onClick={this.onSubscribe}>Subscribe</a>
                          : <a href="/en/paywall" className="btn" style={{ background: "#1a529c", color: "white" }} onClick={this.onSubscribe}>Subscribe</a>
                      }
                    </div>
                    <div>
                      <i style={{ fontSize: "10px", color: "rgba(20,20,20,0.73)" }}>*per user</i>
                    </div>
                  </div>
                  <div className="mt-4">
                    <i className="font-14">For group subscription or a specific requirement, please email us at <u>subscribe@analyzemarkets.com</u></i>
                  </div>
                  <div onClick={this.onFreeTrial} className="d-flex justify-content-between align-items-center mt-4" style={{ backgroundColor: "#e2e2e2", padding: "12px", cursor: "pointer" }}>
                    <span className="font-15">Request for Free Trial and Demo</span>
                    <i class="fa fa-chevron-down" style={{ paddingRight: "10px" }} aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              {(this.state.result === "" && this.state.freeTrial == true)
                ? (
                  <div className="row">
                    <div className="col-12 mt-3">
                      <Form onSubmit={this.onSubmit.bind(this)}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="FirstName">
                            <Form.Control
                              type="text"
                              placeholder="First Name*"
                              name="firstname"
                              onChange={this.onChange.bind(this)}
                              required
                            />
                          </Form.Group>

                          <Form.Group as={Col} controlId="lastName">
                            <Form.Control
                              type="text"
                              placeholder="Last Name*"
                              name="lastname"
                              onChange={this.onChange.bind(this)}
                              required
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="ComapanyName">
                          <Form.Control
                            type="text"
                            placeholder="Company Name"
                            name="company_name"
                            onChange={this.onChange.bind(this)}
                          />
                        </Form.Group>

                        <Form.Row>
                          <Form.Group as={Col} controlId="ChooseCountry">
                            <Form.Control
                              as="select"
                              onChange={(e) => this.handleDropdownChange(e)}
                              required
                            >
                              <option value="">Select Country*</option>
                              {this.state.country_list.map((item, i) => (
                                <option key={i} value={item.country}>
                                  {item.country}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="WorkEmail">
                          <Form.Control
                            type="email"
                            placeholder="Email*"
                            name="email"
                            onChange={this.onChange.bind(this)}
                            required
                          />
                        </Form.Group>
                        <div className="text-center mb-5">
                          <Button variant="blue btn-lg" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                )
                : <div></div>
              }
              {this.state.result !== "" && (
                <div className="mt-3">
                  <h5 className="pb-4 font-weight-normal">{this.state.result}</h5>
                </div>
              )}
            </Col>

            <Col md={12} lg={6} sm={12}>
              <div className="bdrpdg">
                <div className="d-flex align-items-center mb-3">
                  <img src={AMDealsIntelligence} className="img-fluid" style={{ width: '32%' }} alt="" />
                  <h3 style={{ marginLeft: '12%' }}>Sign In</h3>
                </div>
                <Form onSubmit={this.onSignin.bind(this)}>
                  <Form.Group as={Col}>
                    <Form.Control
                      required
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Username"
                      value={this.state.email}
                      onChange={this.onSigninChange}
                    ></Form.Control>

                    <div style={{ color: "red" }} className="animationMode">
                      {" "}
                      {this.state.hasError}
                    </div>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Control
                      required
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.onSigninChange}
                    />
                    {this.state.hasErrors && (
                      <span className="colorError">Password Required</span>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicCheckbox" as={Col}>
                    <Form.Check
                      name="rememberMe"
                      checked={this.state.rememberMe}
                      type="checkbox"
                      onChange={this.onSigninChange}
                      label="Remember Me"
                    />
                  </Form.Group>

                  <Col className="text-right">
                    <Link to={"/en/forgot-password"} className="font600">
                      Forgot Password?
                    </Link>
                  </Col>
                  <Form.Group as={Col}>
                    <div className="capchaCode">
                      <div
                        className="g-recaptcha"
                        data-sitekey="6LcjIsQUAAAAAPYjdLFm1hP-EgdGAJFQrUxmRGkc"
                      ></div>
                    </div>
                    <Button
                      variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                      type="submit"
                      size="lg"
                    >
                      Sign In
                    </Button>
                    <div className="registration-terms">
                      <p>
                        By Signing in you agree to our{" "}
                        <Link to="terms-of-use" className="font600">
                          Terms of use
                        </Link>
                        ,{" "}
                        <Link to="privacy-policy" className="font600">
                          Privacy Policy
                        </Link>
                        .
                      </p>
                      <p>
                        For account/login help and support, please contact us at support@analyzemarkets.com
                      </p>

                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default GdiHome;
