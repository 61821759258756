import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap/";
import { Link } from "react-router-dom";
import { setupCache } from "axios-cache-adapter";
import "./investment-wire.css";
import axios from "axios";
import TimeAgo from "react-timeago";
import Moment from "react-moment";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000
});

const api = axios.create({
  adapter: cache.adapter
});

class Investwire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section_one: [],
      section_two: [],
      section_one_mostread: [],
      section_two_mostread: [],
      show: false,
      show_most_read: false
    };
  }

  showhide = () => {
    this.setState({ show: !this.state.show });
  };

  showhidemostread = () => {
    this.setState({ show_most_read: !this.state.show_most_read });
  };

  getData = () => {
    api({
      url: "https://api.analyzemarkets.com/just_posted_giwm/",
      method: "get"
    }).then(async res => {
      // Do something fantastic with response.data \o/
      console.log("Request response:", res);
      this.setState({
        section_one: res.data.section_one,
        section_two: res.data.section_two
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
    api({
      url: "https://api.analyzemarkets.com/read24hr_giwm/",
      method: "get"
    }).then(async res => {
      console.log(res, 65);
      if (res.data.is_success) {
        this.setState({
          section_one_mostread: res.data.section_one,
          section_two_mostread: res.data.section_two
        });
      }
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  componentDidMount = () => {
    this.getData();
  };

  render() {
    const calendarStrings = {
      lastDay: "[1 day Ago]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[3 days ago]",
      nextWeek: "[3 days ago]",
      sameElse: "L"
    };
    return (
      <div className="invesintell">
        <div className="textwidget mb-2">
          <h5>Investment Wire</h5>
        </div>
        <div className="greyborder2px">
          <Tabs defaultActiveKey="jp" id="uncontrolled-tab-example">
            <Tab eventKey="jp" title=" Latest">
              <ul>
                {this.state.section_one.map((item, index) => (
                  <li key={index}>
                    <Link to={"/en/wire/" + item.url}>{item.title}</Link>
                    <small>
                      {" "}
                      {/*{item.is_updated && <span>Updated</span>} */}
                      {<span>Posted</span>} &nbsp;
                      <Moment calendar={calendarStrings} >
                        {item.created_at}
                      </Moment>
                    </small>
                  </li>
                ))}
              </ul>

              {this.state.show && (
                <ul>
                  {this.state.section_two.map((item, index) => (
                    <li key={index}>
                      <Link to={"/en/wire/" + item.url}>{item.title}</Link>
                      <small>
                        {" "}
                        {<span>Posted</span>} &nbsp;
                        <Moment calendar={calendarStrings} >
                        {item.created_at}
                      </Moment>
                      </small>
                    </li>
                  ))}
                </ul>
              )}
              <div className="text-right pt-2">
                {!this.state.show && (
                  <a
                    className="btnLink-blue pt-1 pb-1 mr-auto"
                    onClick={this.showhide}
                  >
                    More >>
                  </a>
                )}
                {this.state.show && (
                  <a
                    className="btnLink-blue pt-1 pb-1 mr-auto"
                    onClick={this.showhide}
                  >
                    Less >>
                  </a>
                )}
              </div>
            </Tab>
            <Tab eventKey="mr" title="Trending">
              <ul>
                {this.state.section_one_mostread.map((item, index) => (
                  <li key={index}>
                    <Link to={"/en/wire/" + item.url}>{item.title}</Link>
                    <small>
                      {<span>Posted</span>} &nbsp;
                      <Moment fromNow>{item.created_at}</Moment>
                    </small>
                  </li>
                ))}
              </ul>

              {this.state.show_most_read && (
                <ul>
                  {this.state.section_two_mostread.map((item, index) => (
                    <li key={index}>
                      <Link to={"/en/wire/" + item.url}>{item.title}</Link>
                      <small>
                        {<span>Posted</span>} &nbsp;
                        <Moment fromNow>{item.created_at}</Moment>
                      </small>
                    </li>
                  ))}
                </ul>
              )}
              <div className="text-right pt-2">
                {!this.state.show_most_read && (
                  <a
                    className="btnLink-blue pt-1 pb-1 mr-auto"
                    onClick={this.showhidemostread}
                  >
                    More >>
                  </a>
                )}
                {this.state.show_most_read && (
                  <a
                    className="btnLink-blue pt-1 pb-1 mr-auto"
                    onClick={this.showhidemostread}
                  >
                    {" "}
                    Less >>
                  </a>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Investwire;
