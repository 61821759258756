import React, { Component } from "react";
import { Link, BrowserRouter } from "react-router-dom";
import "./usernav.css";
import axios from "axios";
import { Redirect } from "react-router";
import { Dropdown, DropdownButton } from "react-bootstrap";
import AMlogo from "../../../assets/images/am-logo-mini.png";

class Usernav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      rememberMe: false,
      result: "",
      results: "",
      token: "",
      response: "",
      user: "",
      key: "",
      action: "",
      logoutaction: "",
      login: [],
      postlogin: [],
      login_flag: "False",
      logout_flag: "True",
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");

    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");

    this.setState({
      email,
      rememberMe,
      response,
      token,
      user,
      action,
    });
  };

  render() {
    return (
      <div className="usernav d-flex align-items-center">

        {/* <a href="" className="d-flex align-items-center" style={{ padding: "6px 20px", backgroundColor: "white", borderRadius: "4px", textDecoration: "none" }}>
          <img src={AMlogo} className="img-fluid mr-2" width="30" alt="" />
          <span>
            <span style={{ color: "#1a529c", fontSize: "15px", fontWeight: "bold" }}>Deals</span>
            <span style={{ color: "#1ab6df", fontSize: "15px", fontWeight: "bold" }}>Marketplace</span>
          </span>
        </a> */}

        <span>
          {this.state.response === null ? (
            <a href="/en/signin" className="signin">
              Sign In
            </a>
          ) : (
            ""
          )}
        </span>

        <span>
          {this.state.action ? (
            <DropdownButton
              id="dropdown-basic-button"
              className="btn subscribe-btn userbutton"
              title={
                this.state.action && this.state.response === this.state.email
                  ? "Hello, " + this.state.user
                  : ""
              }
            >
              <Dropdown.Item>
                {" "}
                {this.state.action ? (
                  <Link to={"/en/investment_wire/"} className="black">
                    Home{" "}
                  </Link>
                ) : (
                  ""
                )}
              </Dropdown.Item>
              <Dropdown.Item>
                {" "}
                {this.state.action ? (
                  <Link to={"/en/my_profile/"} className="black">
                    My Profile
                  </Link>
                ) : (
                  ""
                )}
              </Dropdown.Item>

              {/* <Dropdown.Item>
                {" "}
                {this.state.action ? (
                  <Link
                    to={"/en/global_deals_marketplace-manage/"}
                    className="black"
                  >
                    Manage Global Deals MarketPlace{" "}
                  </Link>
                ) : (
                  ""
                )}
              </Dropdown.Item> */}
            </DropdownButton>
          ) : (
            ""
          )}
        </span>

        <a
          href="/en/subscribe_us"
          className="btn btn-blue subscribe-btn extraclass"
        >
          Subscribe
        </a>
        <a href="/en/subscribe_us" className="btn btn-blue subscribe-btn">
          Subscribe
        </a>
      </div>
    );
  }
}

export default Usernav;
