import React, { Fragment, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Row, Table, Col, Container, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
//import upload from "./upload.php";
import CreateSidebar from "../../component/global/debt-deals-QL/debt-deal-gmd-sidebar";
import $ from "jquery";
import { Link } from "react-router-dom";
//import Autocomplete from "material-ui/AutoComplete";
//import { makeStyles } from "material-ui/styles";
import Autocomplete from "react-autocomplete";
import {
  matchCompany,
  matchSector,
} from "../../component/global/investment-intellegence-screener/data";
//import window from "react-global";
import "../create-deal/create-deal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Signin from "../../pages/signin/signinIndex";
import Fetch from "../../component/global/CommonComponent/useFetch";

import Header from "../../component/global/headerAfterLogin/indexAfterLogin";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Jv_Gmd_Edit extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleState = this.handleState.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.onChange = (editorState) => this.setState({ editorState });
    this.getLabel = this.getLabel.bind(this);
    this.getLabel1 = this.getLabel1.bind(this);
    this.handleFilters = this.handleFilters.bind(this);

    this.state = {
      dealheadline: "",
      dealcodename: "",
      poentialjointventureregion: "",
      poentialjointventuresubindustry: "",
      jointventurepartnercompany: "",
      jointventurepartnercompanyURL: "",
      refresh: "",
      id: "",
      filterdata: "",
      //editorState: EditorState.createEmpty(),
      user: "",
      name_info: "",
      title: "",
      emailentry: "",
      email: "",
      contactnumber: "",
      youauthorizedcreatedealbehalfcompany: "",
      items: [],
      sector_data: "",
      result: false,
      country: [],
      sector: [],
      country_name: "",
      sector_name: "",
      jointventurepartnersector: "",
      jointventurepartnercountry: "",
      companysubindustry: "",
      filter: "",
      subIndustry: [],
      old_route: "",
      pageSize: 25,
      count: 0,
      file: "",
      regions: [],
      region: "",
      poentialjointventurecountry: "",
      dealbriefdescription: "",
      fileother: "",
      filepicture: "",
      reportedDate: new Date(),
      reported_date: "",

      details: [],
      sendkey: false,
      refresh: "",
      //editorState: EditorState.createEmpty(),
      user: "",
      items: [],
      result: false,
      labelitem: false,
      labelitem1: false,
      status: "",
      sector_name: "",
      filter: "",
      old_route: "",
      pageSize: 25,
      count: 0,
    };
  }
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };
  getLabel = () => {
    const { details } = this.state;
    this.setState({
      labelitem: true,
    });
  };
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/gdmjvsingle/" +
        this.props.match.params.dealheadline +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, "Join Venture Edit");
      this.setState({
        details: res.data,
        // file_teaser: res.data.teaserresentation,
        // acquirersector: res.data.acquirercompany,
        // acquirerURL: res.data.projectpicturestoupload,
        id: res.data.id,
      });

      console.log(this.state.id, "Global Id1");

      // Interacting with the store, see `localForage` API.
    });
  };

  getLabel1 = () => {
    const { details } = this.state;
    this.setState({
      labelitem1: true,
    });
  };

  refreshButton = () => {
    this.setState({
      refresh: true,
    });
  };

  handleState = () => {
    this.setState({
      result: false,
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      dealbriefdescription: e.target.getContent(),
    });

    console.log("Content was updated:", this.state.dealbriefdescription);
  };

  handleChange1 = (e) => {
    if (["SubIndustry", "Sector"].includes(e.target.name)) {
      let taskList = [...this.state.taskList];
      taskList[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ [e.target.name]: e.target.value });
      for (let i = 0; i < 2; i++) {
        console.log(taskList[e.target.dataset.id].length, "Sector");
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addNewRow = () => {
    this.setState((prevState) => ({
      taskList: [
        ...prevState.taskList,
        {
          index: Math.random(),
          SubIndustry: "",
          Sector: "",
        },
      ],
    }));
  };

  deteteRow = (index) => {
    this.setState({
      taskList: this.state.taskList.filter((s, sindex) => index !== sindex),
    });
    // const taskList1 = [...this.state.taskList];
    // taskList1.splice(index, 1);
    // this.setState({ taskList: taskList1 });
  };
  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      sendkey: true,
    });
    console.log(this.state.sendkey, "sendkey");
    console.log("Selected file:", this.state.file);

    //.log("Selected file:", event.target.files[0]);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.date === "" || this.state.description === "") {
      //   NotificationManager.warning(
      //     "Please Fill up  Field . Please check Task and Date Field"
      //   );
      return false;
    }
    for (var i = 0; i < this.state.taskList.length; i++) {
      if (
        this.state.taskList[i].projectName === "" ||
        this.state.taskList[i].task === ""
      ) {
        return false;
      }
    }
    let data = { formData: this.state, userData: localStorage.getItem("user") };
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "token"
    );
    axios
      .post("http://localhost:9000/api/task", data)
      .then(() => {
        // if (res.data.success) NotificationManager.success(res.data.msg);
      })
      .catch(() => {
        //     if (error.response.status && error.response.status === 400)
        //       NotificationManager.error("Bad Request");
        //     else NotificationManager.error("Something Went Wrong");
        //     this.setState({ errors: error });
      });
  };
  onSubmit(e) {
    e.preventDefault();
    const { dealheadline } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set("id", this.state.id);
    bodyFormData.set(
      "dealheadline",
      dealheadline == "" ? this.state.details.dealheadline : dealheadline
    );
    bodyFormData.set(
      "dealcodename",
      this.state.dealcodename === ""
        ? this.state.details.dealcodename
        : this.state.dealcodename
    );
    bodyFormData.set(
      "poentialjointventureregion",
      this.state.poentialjointventureregion === ""
        ? this.state.details.poentialjointventureregion
        : this.state.poentialjointventureregion
    );
    bodyFormData.set(
      "poentialjointventurecountry",
      this.state.poentialjointventurecountry === ""
        ? this.state.details.poentialjointventurecountry
        : this.state.poentialjointventurecountry
    );
    bodyFormData.set(
      "poentialjointventuresector",
      this.state.sector_name === ""
        ? this.state.details.poentialjointventuresector
        : this.state.sector_name
    );
    bodyFormData.set(
      "poentialjointventuresubindustry",
      this.state.companysubindustry === ""
        ? this.state.details.poentialjointventuresubindustry
        : this.state.companysubindustry
    );
    bodyFormData.set(
      "jointventurepartnercompany",
      this.state.jointventurepartnercompany === ""
        ? this.state.details.jointventurepartnercompany
        : this.state.jointventurepartnercompany
    );
    bodyFormData.set(
      "jointventurepartnersector",
      this.state.jointventurepartnersector === ""
        ? this.state.details.jointventurepartnersector
        : this.state.jointventurepartnersector
    );
    bodyFormData.set(
      "jointventurepartnercountry",
      this.state.jointventurepartnercountry === ""
        ? this.state.details.jointventurepartnercountry
        : this.state.jointventurepartnercountry
    );
    bodyFormData.set(
      "jointventurepartnercompanyURL",
      this.state.jointventurepartnercompanyURL === ""
        ? this.state.details.jointventurepartnercompanyURL
        : this.state.jointventurepartnercompanyURL
    );

    bodyFormData.set(
      "dealbriefdescription",
      this.state.dealbriefdescription === ""
        ? this.state.details.dealbriefdescription
        : this.state.dealbriefdescription
    );
    bodyFormData.set(
      "name",
      this.state.name_info === ""
        ? this.state.details.name
        : this.state.name_info
    );
    bodyFormData.set(
      "title",
      this.state.title === "" ? this.state.details.title : this.state.title
    );
    bodyFormData.set(
      "email",
      this.state.emailentry === ""
        ? this.state.details.email
        : this.state.emailentry
    );
    bodyFormData.set(
      "contactnumber",
      this.state.contactnumber === ""
        ? this.state.details.contactnumber
        : this.state.contactnumber
    );
    bodyFormData.set(
      "userdealstatus",
      this.state.status === ""
        ? this.state.details.userdealstatus
        : this.state.status
    );

    if (this.state.sendkey === true) {
      bodyFormData.set(
        "teaserpresentation",
        this.state.file === ""
          ? this.state.details.teaserpresentation
          : this.state.file
      );
      console.log(this.state.file, "file send key");
    }
    if (this.state.sendkey === true) {
      bodyFormData.set(
        "otherdocumentstoupload",
        this.state.fileother === ""
          ? this.state.details.otherdocumentstoupload
          : this.state.fileother
      );
      console.log(this.state.file, "file send key");
    }
    if (this.state.sendkey === true) {
      bodyFormData.set(
        "picturesvideotoupload",
        this.state.filepicture === ""
          ? this.state.details.picturesvideotoupload
          : this.state.filepicture
      );
      console.log(this.state.file, "file send key");
    }
    bodyFormData.set(
      "youauthorizedcreatedealbehalfcompany",
      this.state.youauthorizedcreatedealbehalfcompany === ""
        ? this.state.details.youauthorizedcreatedealbehalfcompany
        : this.state.youauthorizedcreatedealbehalfcompany
    );

    bodyFormData.set("posted_by", this.state.user);

    axios
      .post("https://api.analyzemarkets.com/gdmjvform/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          this.setState({
            result: true,
          });
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }
  componentDidMount = () => {
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });
    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch(() => {
        //on error
        console.log("Error !");
      });
    api({
      url: "https://api.analyzemarkets.com/regman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ regions: res.data.results });
      }
    });
    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ sector: res.data.results });
      }
    });
    api({
      url: "https://api.analyzemarkets.com/companysubindustry/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ subIndustry: res.data });
      }
    });
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };

  clickOnDelete(record) {
    this.setState({
      taskList: this.state.taskList.filter((r) => r !== record),
    });
  }
  render() {
    const data = this.state.details;

    let { taskList } = this.state; //let { notes, date, description, taskList } = this.state
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Navfilter />
              {this.state.result}
              <Row className="centerAlign">
                <Col className="">
                  <form onSubmit={this.onSubmit}>
                    <div className="iis greyborder2px">
                      <h4 className="text-left">Edit a Deal</h4>
                      <div className="pdg15">
                        <fieldset>
                          <legend>
                            <b className="upercase">
                              About Potential Joint Venture Deal
                            </b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="dealheadline"
                                defaultValue={data.dealheadline}
                                type="text"
                                placeholder="Deal Headline* (Max 100 characters)
                                  e.g. Health care company is looking for growth capital investment"
                                onChange={this.changeTerm}
                                maxLength="100"
                              />
                            </Col>
                          </Row>

                          <Row className="">
                            <Col>
                              <Form.Control
                                name="dealcodename"
                                defaultValue={data.dealcodename}
                                type="text"
                                placeholder="Deal Code Name *e.g. Project Sun"
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Form.Group as={Col} controlId="ChooseCountry">
                              <Form.Control
                                as="select"
                                name="poentialjointventureregion"
                                defaultValue={data.poentialjointventureregion}
                                onChange={this.changeTerm}
                                placeholder="Potential Joint Venture Region*"
                                rows="3"
                                required
                              >
                                <option
                                  value={data.poentialjointventureregion}
                                  className=""
                                >
                                  {data.poentialjointventureregion}
                                </option>

                                <option value="" className="">
                                  Potential Joint Venture Region*
                                </option>
                                {this.state.regions.map((item, i) => (
                                  <option key={i} value={item.region}>
                                    {item.region}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} controlId="ChooseCountry">
                              <Form.Control
                                as="select"
                                name="poentialjointventurecountry"
                                defaultValue={data.poentialjointventurecountry}
                                onChange={this.changeTerm}
                                placeholder="Potential Joint Venture Country*"
                                rows="3"
                                required
                              >
                                <option
                                  value={data.poentialjointventurecountry}
                                  className=""
                                >
                                  {data.poentialjointventurecountry}
                                </option>
                                {this.state.country.map((item, i) => (
                                  <option key={i} value={item.country}>
                                    {item.country}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Row>

                          <Row className="">
                            <Col>
                              {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                placeholder={<div>Select Sector*</div>}
                                name="sector_name"
                                onChange={this.changeTermMultiSelect}
                                isMulti
                                options={sec}
                              />
                              {console.log(this.state.rem, "rem")} */}
                              {this.state.labelitem === false && (
                                <div
                                  className="form-group autoc"
                                  //className={classes.root}
                                >
                                  <Form.Control
                                    as="select"
                                    name="sector_name"

                                    // placeholder="Are you Authorized to create this deal"
                                  >
                                    <option
                                      value={data.poentialjointventuresector}
                                      selected
                                    >
                                      {data.poentialjointventuresector}
                                    </option>
                                    <option value="">
                                      Potential Joint Venture Sector*
                                    </option>
                                    {this.state.sector.map((item, i) => (
                                      <option key={i} value={item.sector}>
                                        {item.sector}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </div>
                              )}
                              {this.state.labelitem && (
                                <div className="form-group autoc">
                                  <Autocomplete
                                    value={this.state.sector_name}
                                    //defaultValue={data.companysector}
                                    inputProps={{
                                      id: "sector-autocomplete",
                                      placeholder:
                                        "Potential Joint Venture Sector*",
                                    }}
                                    wrapperStyle={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                    items={this.state.sector}
                                    getItemValue={(item) => item.sector}
                                    shouldItemRender={matchSector}
                                    onChange={(event, sector_name) =>
                                      this.setState({
                                        sector_name,
                                        sector_data: null,
                                      })
                                    }
                                    onSelect={(sector_name) =>
                                      this.setState({
                                        sector_name,
                                        sector_data: sector_name,
                                      })
                                    }
                                    renderMenu={(children) => (
                                      <div className="menu c-dropdown">
                                        {children}
                                      </div>
                                    )}
                                    renderItem={(item, isHighlighted) => (
                                      <div
                                        className={`item ${
                                          isHighlighted
                                            ? "item-highlighted"
                                            : ""
                                        }`}
                                        key={item.sector}
                                      >
                                        {item.sector}
                                      </div>
                                    )}
                                  />
                                </div>
                              )}{" "}
                            </Col>

                            <Col>
                              {this.state.labelitem === false && (
                                <Form.Control
                                  as="select"
                                  name="companysubindustry"
                                  placeholder="Potential Joint Venture Sub Industry*"
                                >
                                  <option value="">
                                    Potential Joint Venture Sub Industry*
                                  </option>
                                  <option
                                    value={data.poentialjointventuresubindustry}
                                    selected
                                  >
                                    {data.poentialjointventuresubindustry}
                                  </option>
                                  {this.state.subIndustry.map((item, i) => (
                                    <option
                                      key={i}
                                      value={
                                        this.state.sector_data === item.sector
                                          ? item.companysubindustry
                                          : "null"
                                      }
                                    >
                                      {this.state.sector_data === item.sector
                                        ? item.companysubindustry
                                        : ""}
                                    </option>
                                  ))}
                                </Form.Control>
                              )}

                              {this.state.labelitem && (
                                <Form.Control
                                  as="select"
                                  name="companysubindustry"
                                  placeholder="Potential Joint Venture Sub Industry*"
                                  onChange={this.changeTerm}
                                >
                                  <option value="">
                                    Potential Joint Venture Sub Industry*
                                  </option>
                                  {this.state.subIndustry.map((item, i) => (
                                    <option
                                      key={i}
                                      value={
                                        this.state.sector_data === item.sector
                                          ? item.companysubindustry
                                          : "null"
                                      }
                                    >
                                      {this.state.sector_data === item.sector
                                        ? item.companysubindustry
                                        : ""}
                                    </option>
                                  ))}
                                </Form.Control>
                              )}
                            </Col>
                            <span onClick={this.getLabel}>
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                            </span>
                          </Row>
                          <div className="form-group">
                            <Row className="">
                              <Col>
                                <Editor
                                  initialValue={data.dealbriefdescription}
                                  value={data.dealbriefdescription}
                                  apiKey="l7d3ab4ybjfthcy2c4ya6vsj9rlub27d3sdqk433oyis6ild"
                                  init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                      "advlist autolink lists link image",
                                      "charmap print preview anchor help",
                                      "searchreplace visualblocks code",
                                      "insertdatetime media table paste wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help",
                                  }}
                                  onChange={this.handleEditorChange}
                                />

                                {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                  as="textarea"
                                  name="companydealbriefdescription"
                                  rows="7"
                                  maxLength="100"
                                  onChange={this.changeTerm}
                                  placeholder="Company/Deal Brief Description* (Maximum 100 words)"
                                />
                              </Form.Group> */}
                              </Col>{" "}
                            </Row>
                          </div>

                          <legend>
                            {" "}
                            <b className="upercase">Joint Venture Partner </b>
                          </legend>
                          <Row className="">
                            <Col>
                              <Form.Control
                                name="jointventurepartnercompany"
                                defaultValue={data.jointventurepartnercompany}
                                type="text"
                                placeholder="Joint Venture Partner Company"
                                onChange={this.changeTerm}
                              />
                            </Col>

                            {console.log(this.state.file, "Type of Audit")}
                            <Col>
                              <div
                                className="form-group autoc"
                                //className={classes.root}
                              >
                                <Form.Control
                                  as="select"
                                  name="jointventurepartnersector"
                                  onChange={this.changeTerm}
                                  // placeholder="Are you Authorized to create this deal"
                                >
                                  <option
                                    value={data.jointventurepartnersector}
                                    selected
                                  >
                                    {data.jointventurepartnersector}
                                  </option>
                                  <option value="">Borrower Sector*</option>
                                  {this.state.sector.map((item, i) => (
                                    <option key={i} value={item.sector}>
                                      {item.sector}
                                    </option>
                                  ))}
                                </Form.Control>
                              </div>
                            </Col>
                            <Form.Group as={Col} controlId="ChooseCountry">
                              <Form.Control
                                as="select"
                                name="jointventurepartnercountry"
                                defaultValue={data.jointventurepartnercountry}
                                onChange={this.changeTerm}
                                placeholder="Joint Venture Partner Country*"
                                rows="3"
                                required
                              >
                                <option
                                  value={data.jointventurepartnercountry}
                                  className=""
                                >
                                  {data.jointventurepartnercountry}
                                </option>
                                {this.state.country.map((item, i) => (
                                  <option key={i} value={item.country}>
                                    {item.country}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Col sm={4}>
                              <Form.Control
                                name="jointventurepartnercompanyURL"
                                defaultValue={
                                  data.jointventurepartnercompanyURL
                                }
                                type="text"
                                placeholder="Joint Venture Partner Company URL"
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>
                          <br></br>
                          <legend>
                            <b className="upercase">Requirement Documents</b>
                          </legend>
                          <Form.Group>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label> Teaser/Presentation</label>
                              )}

                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label> Teaser/Presentation</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="file"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.teaserpresentationname}
                                      </label>
                                    </div>{" "}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="file"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "File Name")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>
                            </div>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label>Other Documents to upload:</label>
                              )}
                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label>Other Documents to upload:</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="fileother"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.otherdocumentstouploadname}
                                      </label>
                                    </div>{" "}
                                    {console.log(
                                      " file count:",
                                      this.state.fileother
                                    )}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="fileother"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "Type of Audit")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>{" "}
                            </div>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label> Picture and video to upload</label>
                              )}{" "}
                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label> Picture and video to upload</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="filepicture"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.picturesvideotouploadname}
                                      </label>
                                    </div>{" "}
                                    {console.log(
                                      " file count:",
                                      this.state.file
                                    )}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="filepicture"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "Type of Audit")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>
                            </div>
                          </Form.Group>

                          <legend>
                            <b className="upercase">
                              Contact Information For Potential Investors
                            </b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="name_info"
                                defaultValue={data.name}
                                type="text"
                                placeholder="Name*"
                                required
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                name="title"
                                defaultValue={data.title}
                                type="text"
                                placeholder="Title*"
                                required
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="emailentry"
                                defaultValue={data.email}
                                type="email"
                                placeholder="Email*"
                                onChange={this.changeTerm}
                              />
                            </Col>

                            <Col>
                              <Form.Control
                                name="contactnumber"
                                type="tel"
                                defaultValue={data.contactnumber}
                                required
                                placeholder="Contact number*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col>
                              <label>
                                Are you authorized to create this deal on behalf
                                of the Company*:{" "}
                              </label>
                              <Form.Control
                                as="select"
                                name="youauthorizedcreatedealbehalfcompany"
                                onChange={this.changeTerm}
                                // placeholder="Are you Authorized to create this deal"
                              >
                                {data.youauthorizedcreatedealbehalfcompany !==
                                null ? (
                                  <option
                                    value={
                                      data.youauthorizedcreatedealbehalfcompany
                                    }
                                    className=""
                                  >
                                    {data.youauthorizedcreatedealbehalfcompany}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Are you authorized to create the deal
                                  </option>
                                )}
                                <option
                                  value={
                                    "Yes" ===
                                    data.youauthorizedcreatedealbehalfcompany
                                      ? "null"
                                      : "Yes"
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value={
                                    "No" ===
                                    data.youauthorizedcreatedealbehalfcompany
                                      ? "null"
                                      : "No"
                                  }
                                >
                                  No
                                </option>
                              </Form.Control>
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <label>Deal Status</label>

                              <Form.Control
                                as="select"
                                name="status"
                                onChange={this.changeTerm}
                              >
                                {data.userdealstatus !== null ? (
                                  <option
                                    value={data.userdealstatus}
                                    className=""
                                  >
                                    {data.userdealstatus}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Deal Status
                                  </option>
                                )}

                                <option
                                  value={
                                    "Active" === data.userdealstatus
                                      ? "null"
                                      : "Active"
                                  }
                                >
                                  Active
                                </option>
                                <option
                                  value={
                                    "Inactive" === data.userdealstatus
                                      ? "null"
                                      : "Inactive"
                                  }
                                >
                                  Inactive
                                </option>
                              </Form.Control>
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col sm={3}>
                              <Button
                                variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                type="submit"
                                size="sm"
                              >
                                Publish Deal
                              </Button>
                            </Col>
                            <Col>
                              <Link
                                className="btn btn-blue mt-4 mb-4 shadow"
                                onClick={this.refreshButton}
                              >
                                Reset
                              </Link>
                            </Col>

                            <Col></Col>
                          </Row>
                        </fieldset>
                        {this.state.refresh && window.location.reload()}

                        {this.state.result && (
                          <div className="borderColors1">
                            <span
                              className="buttonFloat"
                              onClick={this.handleState}
                            >
                              <i className="fa fa-times-circle"></i>
                            </span>
                            <span>
                              <i
                                class="far fa-check-circle"
                                style={{ color: "green" }}
                              ></i>
                            </span>
                            &nbsp;
                            <span>
                              <h4>
                                {" "}
                                <b className="colorError">
                                  Your Deal is Successfully Edited
                                </b>
                              </h4>
                              <br></br>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default Jv_Gmd_Edit;
