import React, { Component } from "react";
import { Button } from "react-bootstrap/";
import { Link } from "react-router-dom";
import "./followus.css";

class Followus extends Component {
  render() {
    return (
      <div className="socialwidget">
        <div className="textwidget mb-test text-uppercase">
          <h5>Follow Us</h5>
        </div>
        <ul className="social bigs">
          <li>
            <a
              href="https://www.linkedin.com/company/analyzemarkets"
              target="_blank"
            >
              <Button
                id="https://www.linkedin.com/me/"
                variant=""
                title="Linkedin"
              >
                &nbsp;
              </Button>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/AnalyzeMarkets" target="_blank">
              {" "}
              <Button
                id="https://www.twitter.com/me/"
                variant=""
                title="Twitter"
              >
                &nbsp;
              </Button>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/AnalyzeMarkets-107364323968644"
              target="_blank"
            >
              <Button
                id="https://www.facebook.com/me/"
                variant=""
                title="Facebook"
              >
                &nbsp;
              </Button>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Followus;
