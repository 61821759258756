import React, {Component} from 'react';
import './thumb-img.css';


class Audiopthumb extends Component { 
    render()
    { 
        return <div className="image-g">
            <img src={ this.props.image } className="img-fluid" alt=""/>
        </div>;
    }
} 

export default Audiopthumb;
