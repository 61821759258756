import React, { Component, Fragment } from "react";
import MA_SideBar from "../../component/global/M&A_Deals/M&A_SideBar";
import { Container, Row, Table, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import "./fund-raising-deals.css";
import imggraph from "../../../src/assets/images/graph.png";
import { Link } from "react-router-dom";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import GlobalCompanyDatabse from "../../component/global/FundRaising Sidebar/fundraising-sidebar";
import axios from "axios";
import Chart from "react-google-charts";
const data = [
  [
    "Region",
    "Count of Real Estate deals",
    { role: "style" },
    { role: "annotation" },
  ],
  ["Americas", 6486, "gold", "6,486"],
  ["Europe", 3476, "silver", " 3,476"], // English color name
  ["Asia & Pacific", 2636, "color: #a5a5a5", "2636"], // CSS-style declaration
  ["Middle East", 787, "color: #e5e4e2", "787"], // CSS-style declaration
  ["Africa", 191, "#b87333", "191"], // RGB value
];
const data2 = [];
class RealDealGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      count: 0,
      region: [],
      sector: [],
    };
  }
  componentDidMount = () => {
    axios
      .get("https://api.analyzemarkets.com/gdd_frdtitle/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ items: res.data.result, count: res.data.length });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/regioncount/")
      .then((res) => {
        console.log(res.data, 23);
        var array = [];
        var len = res.data.regioncount.length;
        console.log(len, "length");
        var data3 = [];
        for (let i = 0; i < len; i++) {
          data3 = [
            [
              "Region",
              "Count of Company",
              { role: "style" },
              { role: "annotation" },
            ],
            [
              res.data.regioncount[3].region,
              parseInt(res.data.regioncount[3].GCDcount, 10),
              "#9c499a",
              res.data.regioncount[3].GCDcount,
            ],
            [
              res.data.regioncount[2].region,
              parseInt(res.data.regioncount[2].GCDcount, 10),
              "#dc3d1f",
              res.data.regioncount[2].GCDcount,
            ],
            [
              res.data.regioncount[1].region,
              parseInt(res.data.regioncount[1].GCDcount, 10),
              "silver",
              res.data.regioncount[1].GCDcount,
            ],

            [
              res.data.regioncount[4].region,
              parseInt(res.data.regioncount[4].GCDcount, 10),
              "blue",
              res.data.regioncount[4].GCDcount,
            ],
            [
              res.data.regioncount[0].region,
              parseInt(res.data.regioncount[0].GCDcount, 10),
              "gold",
              res.data.regioncount[0].GCDcount,
            ],
          ];
          console.log(data3, "Data4");
        }

        console.log(data2, "Region");

        this.setState({ region: data3 });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/regioncount/")
      .then((res) => {
        console.log(res.data, 23);
        var array = [];
        var len = res.data.sectorcount.length;
        console.log(len, "length");
        var data4 = [];
        for (let i = 0; i < len; i++) {
          data4 = [
            ["Sector", "Count of Company"],
            [
              res.data.sectorcount[3].sector,
              parseInt(res.data.sectorcount[3].GCDcount, 10),
            ],
            [
              res.data.sectorcount[1].sector,
              parseInt(res.data.sectorcount[1].GCDcount, 10),
            ],
            [
              res.data.sectorcount[4].sector,
              parseInt(res.data.regioncount[4].GCDcount, 10),
            ],

            [
              res.data.sectorcount[0].sector,
              parseInt(res.data.regioncount[0].GCDcount, 10),
            ],
            [
              res.data.sectorcount[2].sector,
              parseInt(res.data.regioncount[2].GCDcount, 10),
            ],
          ];
          console.log(data4, "Data4");
        }

        console.log(data2, "Region");

        this.setState({ sector: data4 });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };
  render() {
    const con = this.state.count;
    console.log(con, "count");
    return (
      <Fragment>
        {console.log(this.state.region, "Data3")}
        <Header></Header>
        <Container>
          <Navfilter />

          <div>
            <Row>
              <Col className="w-30p">
                <div className="widget">
                  <GlobalCompanyDatabse />
                </div>
              </Col>

              <Col className="w-70p">
                <div>
                  <div>
                    <h3 className="head-title">
                      <i class="fa fa-check"></i> &nbsp; Total FundRaising Deals
                      in our Database: {con}
                    </h3>
                  </div>
                  {/* <div>
                    <h3 className="head-title">
                      <i class="fa fa-check"></i> &nbsp;Average Daily addition
                      of M&A Deals in our Database: 200{" "}
                    </h3>
                  </div> */}

                  <div className="">
                    <Row>
                      <Col>
                        {" "}
                        <div className="">
                          <Chart
                            chartType="ColumnChart"
                            width="auto"
                            height="auto"
                            data={data}
                          />{" "}
                        </div>
                      </Col>
                      <Col>
                        <Chart
                          width={"auto"}
                          height={"auto"}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={[
                            ["Pizza", "Popularity"],
                            ["Financials", 9],
                            ["IT", 9],
                            ["Energy", 9],
                            ["Health Care", 10], // Below limit.
                            ["Industry", 9], // Below limit.
                          ]}
                          options={{
                            title: "",
                            sliceVisibilityThreshold: 0.01, // 20%
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default RealDealGraph;
