import React, { Component, Fragment, useState } from "react";
import { Row, Table, Col, Container, Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import "../create-deal/create-deal.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import Autocomplete from "react-autocomplete";
import axios, { post } from "axios";
import "../create-deal/create-deal.css";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Popup from "reactjs-popup";
import { setupCache } from "axios-cache-adapter";
import srcicon from "../../assets/images/search.svg";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, Nav } from "react-bootstrap";

import UiLookup from "./uiLookup";

import {
  matchSubIndustry,
  matchCountry,
  matchSector,
  matchCompany,
  matchStatus,
  matchDeal,
  matchDealHeadline,
  matchRegion,
  matchGeography,
} from "../../component/global/investment-intellegence-screener/data";
import Pagination from "../../component/global/pagination/index";
import $ from "jquery";
import Signin from "../../pages/signin/signinIndex";

const animatedComponents = makeAnimated();
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class ManageAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: [],
      status1: false,
      companies: [],
      items: [],
      country: [],
      country_name: "",
      latestlist: [],
      company: [],
      id: "",
      emailfrequency: "",
      signinlink: "",
      show: false,
      alerts: "",
      setShow: false,
      companys: [],
      companyand_data: "",
      result: false,
      remove: false,
      comapany_name: "",
      geography_name: "",
      briefdescriptionproject: "",
      projecttimelinedays: "",
      file: "",
      name: "",
      is_filter: false,
      id: "",
      email: "",
      contactnumber: "",
      user: "",
      token: "",
      hasComp: "",
      hasselect: "",
      hasselects1: "",
      hasAlert: "",
      name: "",
      posted_by: "",
      exampleItems: [],
      selections: [],
      page: 0,
      current: 0,
      filter: "",
      old_route: "",
      pageSize: 25,
      company_name: "",
      count: 0,
      is_first: true,
      token: "",
      response: "",
      user: "",
      count: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeTermMultiSelect = this.changeTermMultiSelect.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeTermMultiSelects = this.changeTermMultiSelects.bind(this);
    this.handleState = this.handleState.bind(this);
    this.addCompany = this.addCompany.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
  }
  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  closeSearch = (company) => {
    console.log("Search News", company);
    this.setState({
      company_name: company,
    });
    console.log(company, "Close Search");
    this.getData(company, this.state.old_route, 0, true);
  };
  handleShow1 = (id) => {
    // id = id.indexOf("&") != -1 ? id.replace("&", "%26") : id;
    this.setState({
      comapany_name: id,
      companyand_data: id.indexOf("%26") != -1 ? id.replace("%26", "&") : id,
    });
  };
  keyPress = (e) => {
    if (e.keyCode == 13) {
      this.getData(e.target.value, "", 0, false);
      // put the login here
    }
  };

  handleShow = (id, company) => {
    this.setState({
      id: id,
    });
    var bodyFormData = new FormData();
    bodyFormData.set("id", id);
    if (window.confirm("Are you sure you want to delete " + company)) {
      axios
        .post(
          "https://api.analyzemarkets.com/managealertremovecmpny/",
          bodyFormData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          console.log(res.data, "Data");
          if (res.status === 200) {
            if (res.data === "done") {
              alert("Data Removed Succesfully");
            }
          } else {
            alert("Failed to remove");
          }
        })
        .catch((error) => {
          //on error
          alert(error);
        });
    }
  };

  handleClose() {
    this.setState({
      setShow: false,
    });
  }
  componentDidUpdate = () => {
    this.handleList(this.state.signinlink);
  };
  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      this.getData(this.state.company_name, this.state.old_route, page, false);
      console.log(55);
    }
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    var favorite = [];

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }
    this.setState({
      old_route: current_route,
    });
    this.getData("", current_route, 1, true);

    axios
      .get("https://api.analyzemarkets.com/regman/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ region: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/")
      .then((res) => {
        let tmpArray = [];
        console.log(res, "limited");
        for (var i = 0; i < res.data.length; i++) {
          tmpArray.push(res.data[i]);
        }
        console.log(tmpArray, "tmArray");
        this.setState({
          company: tmpArray,
        });
        console.log(this.state.company, "tmArray");
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    // api({
    //   url: "https://api.analyzemarkets.com/companymanagementprivate/",
    //   method: "get",
    // }).then((res) => {
    //   // Here you need to use an temporary array to store NeededInfo only
    //   let tmpArray = [];
    //   for (var i = 0; i < res.data.results.length; i++) {
    //     tmpArray.push({
    //       label: res.data.results[i].country,
    //       value: res.data.results[i].country,
    //     });
    //   }
    //   //console.log(tmpArray, "tmArray");
    //   this.setState({
    //     country: tmpArray,
    //   });
    //   //console.log(this.state.country, "tmArray");
    // });

    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const signinlink = localStorage.getItem("id");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, signinlink });
    this.handleList(signinlink);
  };

  getData = (company, route, page, is_first) => {
    api({
      url:
        "https://api.analyzemarkets.com/companymanagementprivatenew/?name=" +
        route +
        "&start=" +
        page +
        "&search=" +
        company,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Company Data");
      this.setState({
        items: res.data.result,
        page: res.data.count,
        current: page,
        is_first: false,
      });
      if (res.data.count != res.data.totalcount) {
        this.setState({
          status1: true,
        });
      }
      if (res.data.count === res.data.totalcount) {
        this.setState({
          status1: false,
        });
      }

      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
      }
      console.log(this.state.count + "Count Data");
    });
  };

  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleState = () => {
    this.setState({
      result: false,
      remove: false,
    });
    this.refreshButton();
  };

  changeTermMultiSelects(event) {
    const array1 = [];
    const array2 = [];
    const array3 = [];
    const value = event.target.value;

    const length = [event.target.value].length;
    console.log(length, "length");
    const checkeds = document.getElementsByName("selections");
    const checkedsalert = document.getElementsByName("alerts");
    for (let i = 0; i < length; i++) {
      array3.push(event.target.value[i]);
    }
    for (let i = 0; i < checkeds.length; i++) {
      if (checkeds[i].checked) {
        array1.push(checkeds[i].value);
      }
    }
    for (let i = 0; i < checkedsalert.length; i++) {
      if (checkedsalert[i].checked) {
        array2.push(checkedsalert[i].value);
      }
    }
    // console.log(length, "length");
    // for (let i = 0; i < length; i++) {
    //   array1.push(value);
    // }
    this.setState({
      selections: array1,
      alerts: array2,
      company_name: array3,
      sector_data: null,
    });
    console.log(this.state.selections);
    console.log(this.state.alerts);
  }
  changeTermMultiSelect = (comapany_names) => {
    const state = this.state;
    // state.comapany_name = [];
    // if (comapany_names !== null) {
    //   comapany_names.forEach((option) => {
    //     state.comapany_name.push(option.label);
    //   });
    // }
    // //const sub = "";
    //this.state.sub = state.companysubindustry;
    // this.setState({
    //   comapany_name: state.comapany_name,
    //   sector_data: null,
    // });
    console.log(comapany_names);
  };
  handleChanges = (e, index) => {
    this.state.companies[index] = e.target.value;
    this.setState({
      companies: this.state.companies,
    });
  };

  addCompany() {
    this.setState({
      companies: [...this.state.companies, ""],
    });
  }
  handleRemove = (index) => {
    this.state.companies.splice(index, 1);
    this.setState({
      companies: this.state.companies,
    });
    console.log(this.state.companies, "$$$");
  };

  handleList = (id) => {
    api({
      url: "https://api.analyzemarkets.com/signinnew/?id=" + id,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/

      this.setState({
        latestlist: res.data.data,
      });
    });
  };

  handleChange(event) {
    this.setState({ file: event.target.files });

    // const value = event.target.value;
    // const name = event.target.name;
    // const id = event.target.id;
    // const formData = new FormData();

    // const len = event.target.files.length;
    // const fileData = event.target.files;
    // const blob = new Blob([fileData]);
    // const reader = new FileReader();
    // var data_Array;
    // for (let i = 0; i < fileData.length; i++) {
    //   this.setState({
    //     [name]: fileData[i],
    //   });
    //   //console.log(fileData[i]);
    // }
    // console.log(formData.getAll("file"));
    // reader.readAsDataURL(blob);
  }
  // this.setState({
  //   [name]: event.target.files[0],
  //   file_teaser: event.target.files.name,
  //   file_other: event.target.files.name,
  // });

  //.log("Selected file:", event.target.files[0]);

  onSubmit(e) {
    const { comapany_name, selections, alerts, signinlink } = this.state;
    if (comapany_name !== "" && selections.length !== 0 && alerts != "") {
      var bodyFormData = new FormData();
      bodyFormData.set("companyselection", comapany_name);
      bodyFormData.set("selections", selections);
      bodyFormData.set("emailfrequency", alerts);
      bodyFormData.set("id", signinlink);
      console.log(this.state.comapany_name, "Company Name");

      console.log(this.state.alerts, "alert");
      axios
        .post("https://api.analyzemarkets.com/managealert/", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res.data, "Data");
          if (res.status === 200) {
            console.log(this.state.result, "result");
            this.setState({
              result: true,
            });
          } else {
            this.setState({
              result: (
                <div class="">
                  <hr />
                  <i class="fa fa-exclamation-triangle  btext"></i> You are
                  already Signed!
                </div>
              ),
            });
          }
        })
        .catch((error) => {
          //on error
          alert(error);
        });
    } else {
      if (comapany_name === "") {
        this.setState({
          hasComp: "Company Name not Filled",
        });
      }
      if (selections.length === 0) {
        this.setState({
          hasselect: "Selections has not been Selected",
        });
      }

      if (alerts === "") {
        this.setState({
          hasAlert: "Email Frequency has not been Selected",
        });
      }

      //alert("data not filled");
    }
  }

  refreshButton = () => {
    this.setState({
      comapany_name: "",
      alerts: "",
      selections: "",
    });
    $("input[type=checkbox]").prop("checked", false);
    $("input[type=radio]").prop("checked", false);
  };

  render() {
    const { show, setShow } = this.state;

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>

            <Container>
              <Navfilter />
              <div className="">
                <Row>
                  <Col></Col>
                </Row>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item className="nav-s">
                          <Nav.Link eventKey="first">Create Alert</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-s">
                          <Nav.Link
                            eventKey="second"
                            onClick={() =>
                              this.handleList(this.state.signinlink)
                            }
                          >
                            Manage Alert
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <Form className="form-style-6 width600">
                            <div className="iis greyborder2px">
                              <h4 className="text-left">Create Alert</h4>
                              <div className="pdg15">
                                <div className="form-group autoc">
                                  <Autocomplete
                                    value={this.state.comapany_name}
                                    inputProps={{
                                      id: "company-autocomplete",
                                      placeholder: "Select Company Name",
                                    }}
                                    required
                                    wrapperStyle={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                    items={this.state.company}
                                    getItemValue={(item) => item.name}
                                    onChange={(event, comapany_name) =>
                                      this.setState({ comapany_name })
                                    }
                                    shouldItemRender={matchCompany}
                                    onSelect={(comapany_name) =>
                                      this.setState({ comapany_name })
                                    }
                                    renderMenu={(children) => (
                                      <div className="menu c-dropdown">
                                        {children}
                                      </div>
                                    )}
                                    renderItem={(item, isHighlighted) => (
                                      <div
                                        className={`item ${
                                          isHighlighted
                                            ? "item-highlighted"
                                            : ""
                                        }`}
                                        key={item.name}
                                      >
                                        {item.name}
                                      </div>
                                    )}
                                  />
                                  {this.state.comapany_name == "" && (
                                    <div className="redColor">
                                      {this.state.hasComp}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label>Selections</label>

                                  <form>
                                    <Row className="padup10px">
                                      <Col sm={3}>
                                        <label>
                                          <input
                                            type="checkbox"
                                            value="News"
                                            name="selections"
                                            required
                                            onChange={
                                              this.changeTermMultiSelects
                                            }
                                          />
                                          &nbsp; News
                                        </label>
                                      </Col>
                                      <Col sm={3}>
                                        <label>
                                          <input
                                            type="checkbox"
                                            value="Deals"
                                            name="selections"
                                            required
                                            onChange={
                                              this.changeTermMultiSelects
                                            }
                                          />
                                          &nbsp; Deals
                                        </label>
                                      </Col>
                                      <Col sm={3}>
                                        <label>
                                          <input
                                            type="checkbox"
                                            value="Opportunities"
                                            name="selections"
                                            required
                                            onChange={
                                              this.changeTermMultiSelects
                                            }
                                          />
                                          &nbsp; Opportunities
                                        </label>
                                      </Col>
                                    </Row>
                                    {this.state.selections.length === 0 && (
                                      <div className="redColor">
                                        {this.state.hasselect}
                                      </div>
                                    )}
                                  </form>
                                </div>
                                <div className="form-group">
                                  <label>Email Frequency</label>

                                  <form>
                                    <Row className="padup10px">
                                      <Col>
                                        <label className="widthEffect">
                                          <input
                                            type="radio"
                                            value="single alert email in a day"
                                            name="alerts"
                                            required
                                            onChange={this.changeTerm}
                                          />
                                          &nbsp; single alert email in a day
                                        </label>
                                      </Col>
                                      <Col>
                                        <label>
                                          <input
                                            type="radio"
                                            value="immediately"
                                            name="alerts"
                                            required
                                            onChange={this.changeTerm}
                                          />
                                          &nbsp; Immediately
                                        </label>
                                      </Col>
                                    </Row>
                                    {this.state.alerts == "" && (
                                      <div className="redColor">
                                        {this.state.hasAlert}
                                      </div>
                                    )}
                                  </form>
                                </div>
                                <Row>
                                  <Col sm={4}>
                                    <Link
                                      className="btn btn-blue mt-4 mb-4 shadow"
                                      onClick={this.onSubmit}
                                    >
                                      Apply
                                    </Link>
                                  </Col>
                                  <Col sm={4}>
                                    <Link
                                      className="btn btn-blue mt-4 mb-4 shadow"
                                      onClick={this.refreshButton}
                                    >
                                      Clear
                                    </Link>
                                  </Col>
                                </Row>
                                {this.state.result && (
                                  <div className="borderColors1">
                                    <span
                                      className="buttonFloat"
                                      onClick={this.handleState}
                                    >
                                      <i className="fa fa-times-circle"></i>
                                    </span>
                                    <span>
                                      <i
                                        class="fa fa-check-circle"
                                        style={{
                                          color: "green",
                                          float: "right",
                                        }}
                                      ></i>
                                    </span>
                                    &nbsp;
                                    <span>
                                      <h4>
                                        <b className="colorError">
                                          Successfully Submitted. Thank you.
                                        </b>
                                      </h4>
                                      <br></br>
                                    </span>
                                  </div>
                                )}
                                {this.state.remove && (
                                  <div className="borderColors1">
                                    <span
                                      className="buttonFloat"
                                      onClick={this.handleState}
                                    >
                                      <i className="fa fa-times-circle"></i>
                                    </span>
                                    <span>
                                      <i
                                        class="fa fa-check-circle"
                                        style={{
                                          color: "green",
                                          float: "right",
                                        }}
                                      ></i>
                                    </span>
                                    &nbsp;
                                    <span>
                                      <h4>
                                        <b className="colorError">
                                          Data Deleted Successfully.
                                        </b>
                                      </h4>
                                      <br></br>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          {this.state.latestlist.map((item, key) => (
                            <Table size="sm" responsive="lg" bordered striped>
                              <thead>
                                {key === 0 && (
                                  <tr>
                                    <th className="colorWhite">Company Name</th>
                                    <th className="colorWhite">Selections</th>
                                    <th className="colorWhite">Frequency</th>
                                    <th className="colorWhite">Remove</th>
                                  </tr>
                                )}
                              </thead>
                              {item.signinlinkm.map((company, item) => (
                                <tbody key={item}>
                                  <tr key={item}>
                                    <td>{company.companyselection}</td>
                                    <td>{company.selections + " "}</td>
                                    <td>{company.emailfrequency}</td>
                                    <td>
                                      <Link
                                        className=""
                                        key={item}
                                        onClick={() =>
                                          this.handleShow(
                                            company.id,
                                            company.companyselection
                                          )
                                        }
                                      >
                                        Remove
                                      </Link>
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </Table>
                          ))}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default ManageAlert;
