import React, { Component } from "react";
import { Row, Table, Col } from "react-bootstrap";
import "./homeSidebar.css";
import { Link } from "react-router-dom";
class Home_Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
    };
  }
  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");

    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, user, action });
  };
  render() {
    return (
      <div>
        <Row className="show-grid">
          <Col className="borderstyle">
            <b className="ma-title uppercase">Whats New</b>
            <p className="border_bottom"></p>
            <div>
              <b className="subheading1">
                <Link
                  to={"#"}
                  // to={"/en/global-deals-database/" }
                  className="ma_sidebar_color"
                >
                  Global Deals Database
                </Link>
              </b>

              <p className="border_bottomstyle"></p>
            </div>

            <div>
              <b className="subheading1">Global Deals Marketplace</b>

              <p className="border_bottomstyle"></p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Home_Sidebar;
