import React, { Component, Fragment } from "react";
import { render } from "react-dom";

import matchSorter from "match-sorter";
import "./m&adealmonitor.css";
import { Row, Table, Col, Container } from "react-bootstrap";
import MA_SideBar from "../../component/global/M&A_Deals/M&A_SideBar";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "../../component/global/pagination/index";
import "../../pages/press-release/press-release.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Moment from "react-moment";
import { Signer } from "aws-amplify";
import Signin from "../../pages/signin/signinIndex";
import { Helmet } from "react-helmet";
import Fetch from "../../component/global/CommonComponent/useFetch";
import Login from "../../component/global/CommonComponent/passwordhash";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class MA_Deals_Monitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giw: [],
      madeals: [],
      items: [],
      tokenuser: "",
      exampleItems: [],
      company: "",
      counts: 0,
      page: 0,
      login: "",
      filter: "",
      filterdata: "",
      current: 0,
      country: [],
      action: "",
      response: "",
      old_route: "",
      pageSize: 25,
      count: 0,
      is_first: true,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }

  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.getData(this.state.old_route, this.state.filter, filterdata, 0, true);
  };
  handleLogin = (login, token) => {
    this.setState({
      login: login,
      tokenuser: token,
    });
    console.log(login, "login");
  };
  handleFilter = (filter) => {
    console.log(filter, 31);
    this.setState({
      filter: filter,
      is_filter: true,
    });
    //this.getData(this.state.old_route, filter, 0, true);
    this.getData(this.state.old_route, filter, this.state.filterdata, 0, true);
  };

  onChangePage = (pageOfItems, page) => {
    this.setState({
      page: page,
      current: page - 1,
    });
    console.log(this.state.current, page, pageOfItems);
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      // this.getData("", this.state.filter, page, false);
      // console.log(this.state.filterdata);
      // console.log(this.state.login);
      // console.log(this.state.tokenuser);
      // if (
      //   this.state.filterdata !== "" &&
      //   this.state.login !== "" &&
      //   this.state.tokenuser !== ""
      // ) {
      //   this.getData("", this.state.filter, this.state.filterdata, page, false);
      // }
      if (
        this.state.filterdata !== "") {
        console.log('asdfdsfdasfsafsafsaf')
        this.getData("", this.state.filter, this.state.filterdata, page, false);
      }
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    // this.getData(current_route, "", 0, true);
    if (
      this.state.filterdata !== "" &&
      this.state.login !== "" &&
      this.state.tokenuser !== ""
    ) {
      //this.getData1(this.state.filterdata);
      this.getData(current_route, "", this.state.filterdata, 0, false);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
    let headers = new Headers();
  };

  getData = (route, filter, filterdata, page, is_first) => {
    console.log(89, route);

    api({
      url:
        "https://api.analyzemarkets.com/gdd_ma_deals2/?name=" +
        route +
        "&start=" +
        page +
        "" +
        filter +
        "" +
        filterdata,

      method: "get",
    }).then(async (res) => {
      console.log(res, "data");
      if (res.status === 200) {
        this.setState({
          madeals: res.data.results,
          items: res.data.results,
          page: res.data.count,
          current: page,
          is_first: false,
        });
      }

      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
      }
      console.log(res.data.count + "Count Data");
    });
    api({
      url: "https://api.analyzemarkets.com/country/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        country: res.data.results,
        show_100: true,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  render() {
    const data = this.state.company;
    const count = this.state.count;
    return (
      <div>
        {" "}
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Helmet title="M&A Deals Monitor">
                <meta
                  name="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta property="og:title" content="M&A Deals Monitor" />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  name="og:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  itemprop="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Navfilter />

              <Row>
                <Col className="w-30p col-sm-3 p-4">
                  <div className="widget">
                    <MA_SideBar />
                  </div>
                </Col>
                <Col className="w-80p p-4">
                  <div>
                    <div className="container">
                      <div>
                        <Table
                          striped
                          bordered
                          hover
                          id="table-id"
                          className="tblnoline grid"
                          size="sm"
                          responsive="lg"
                        >
                          <thead>
                            <tr>
                              <th index="0" className="centreAlign">
                                <span className="table-header">Deal</span>{" "}
                                <div class=""></div>
                              </th>
                              <th index="1" width="150" className="centreAlign">
                                <span className="table-header">
                                  Announced Date
                                </span>

                                <div className=""></div>
                              </th>

                              <th index="2" className="centreAlign">
                                <span className="table-header">Acquirer</span>{" "}
                                <div className=""></div>
                              </th>

                              <th index="3" className="centreAlign">
                                <span className="table-header">Target </span>

                                <div className=""></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.items.map((item, key) => (
                              <tr>
                                <td>
                                  {item.api_url != null ? (
                                    <Link
                                      to={
                                        "/en/ma-deals-detail/" +
                                        item.api_url +
                                        "/"
                                      }
                                    >
                                      {item.deal}
                                    </Link>
                                  ) : (
                                    <Link>
                                      <u className="underline">{item.deal}</u>
                                    </Link>
                                  )}
                                </td>
                                <td>
                                  <Moment
                                    format="DD MMM YYYY"
                                    className="DateFont"
                                  >
                                    {item.announced_reported_date}
                                  </Moment>{" "}
                                </td>
                                <td>
                                  {item.GDDMandADealsAcquirer.map(
                                    (acquirer, key) => (
                                      <div>
                                        {acquirer.company_name.map(
                                          (ac, key) => (
                                            <div>
                                              {ac.api_url != null ? (
                                                <Link
                                                  to={
                                                    "/en/global-company-database-details/" +
                                                    ac.api_url +
                                                    "/"
                                                  }
                                                >
                                                  <u className="underline">
                                                    {ac.name}
                                                  </u>
                                                </Link>
                                              ) : (
                                                <Link>
                                                  <u className="underline">
                                                    {ac.name}
                                                  </u>
                                                </Link>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )
                                  )}
                                </td>
                                <td>
                                  {item.GDDMandADealsTarget.map(
                                    (target, key) => (
                                      <div>
                                        {target.company_name.map((tar, key) => (
                                          <div>
                                            {tar.api_url != null ? (
                                              <Link
                                                to={
                                                  "/en/global-company-database-details/" +
                                                  tar.api_url +
                                                  "/"
                                                }
                                              >
                                                <u className="underline">
                                                  {tar.name}
                                                </u>
                                              </Link>
                                            ) : (
                                              <Link>
                                                <u className="underline">
                                                  {tar.name}
                                                </u>
                                              </Link>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    )
                                  )}
                                </td>
                                {/*
                          <td>
                            {" "}
                            {item.GDDMandADealsTarget.map((acquirer, key) => (
                              <div>
                                {acquirer.company_name == null
                                  ? null
                                  : acquirer.company_name}
                              </div>
                            ))}
                          </td>*/}{" "}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                      <div className="products-view__options justify-content-between mt-4">
                        {/* {this.state.count > 10 && (
                  <div className="view-options__control">
                    <label>Results Per Page</label>
                    <div></div>
                  </div>
                )} */}
                        <Pagination
                          items={this.state.exampleItems}
                          onChangePage={this.onChangePage}
                          pageSize={this.state.pageSize}
                        ></Pagination>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default MA_Deals_Monitor;
