import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import capchaimg from "../../assets/images/captcha-img.jpg";
import { Container, Row, Col, Form, Button } from "react-bootstrap/";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";

import axios from "axios";
class Forgotpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      result: false,
      hasError: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    localStorage.clear()
  };
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { email, rememberMe } = this.state;
    localStorage.setItem("email_info", email);

    var bodyFormData = new FormData();
    bodyFormData.set("email", this.state.email);
    axios
      .post("https://api.analyzemarkets.com/forgetpassword/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          if (res.data === "otp send") {
            this.setState({
              result: true,
            });
          }
          if (res.data === "email not registered") {
            this.setState({
              result: false,
              hasError: true,
            });
          }
        } else {
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }

  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>
              AnalyzeMarkets - Global Investment Intelligence Platform
            </title>
            <meta
              property="og:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              itemprop="name"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              itemprop="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta
              name="twitter:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              name="twitter:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row className="justify-content-center">
            <Col xl={7} md={12}>
              <div className="registration-form-container">
                <div className="registration-form">
                  <h2 className="pb-4">Forgot Password</h2>
                  <h3 className="text-center pb-5">
                    Please enter your registered Email. We will send you a reset
                    password link.
                  </h3>
                  {this.state.hasError && (
                    <div className="borderColor">
                      <span>
                        <i class="fa fa-warning" style={{ color: "red" }}></i>
                      </span>
                      &nbsp;
                      <span>
                        <b className="colorError">There was a Problem</b>
                        <br></br>
                        Email Not Registered
                      </span>
                    </div>
                  )}
                  <form onSubmit={this.onSubmit}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email:</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        onChange={this.onChange}
                      />
                    </Form.Group>
                    {this.state.result && (
                      <Redirect to={"/en/confirm_password"} />
                    )}

                    <Button
                      variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                      type="submit"
                      size="lg"
                    >
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Forgotpass;
