import React, { Component, Fragment } from "react";
import { Row, Table, Col, Container } from "react-bootstrap";
import "./vc_deals_sidebar.css";
import { Link, NavLink } from "react-router-dom";
class Vc_deals_sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signin: [],
      email: "",
      token: "",
      response: "",
      user: "",
      count: "",
      login: [],
      results: false,

      hasError: false,
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  render() {
    return (
      <Fragment>
        <Container>
          <div>
            <Row className="show-grid">
              <Col className="borderstyle">
                <b className="ma-title uppercase">Quick Links</b>
                <p className="border_bottom"></p>
                <div>
                  <b className="subheading1">
                    <NavLink
                      activeClassName="navbar__link--active1"
                      to={"/en/vc-deals/"}
                      className="ma_sidebar_color"
                    >
                      VC Deals Monitor
                    </NavLink>
                  </b>

                  <p className="border_bottomstyle"></p>
                </div>

                <div>
                  <b className="subheading1">VC Opportunities</b>

                  <p className="font-16 suburls">
                    <NavLink
                      activeClassName="navbar__link--active1"
                      to={"/en/seeking_funding/"}
                      className="ma_sidebar_color"
                    >
                      Startup Seeking Funding
                    </NavLink>
                  </p>
                  <p className="font-16 suburls">
                    <NavLink
                      activeClassName="navbar__link--active1"
                      to={"/en/seeking_buyer/"}
                      className="ma_sidebar_color"
                    >
                      Startup Seeking Buyers
                    </NavLink>
                  </p>
                  <p className="border_bottomstyle"></p>
                </div>

                <div>
                  <b className="subheading1">Advanced Search</b>
                  <p className="font-16 suburls">
                    <NavLink
                      activeClassName="navbar__link--active1"
                      to={"/en/vc-deals-search/"}
                      className="ma_sidebar_color"
                    >
                      VC Deals Search
                    </NavLink>
                  </p>
                  <p className="font-16 suburls">
                    <NavLink
                      activeClassName="navbar__link--active1"
                      to={"/en/seeking_fund_search/"}
                      className="ma_sidebar_color"
                    >
                      Startups Seeking Funding Search
                    </NavLink>
                  </p>
                  <p className="font-16 suburls">
                    <NavLink
                      activeClassName="navbar__link--active1"
                      to={"/en/seeking_buyer_search/"}
                      className="ma_sidebar_color"
                    >
                      Startups Seeking Buyers Search
                    </NavLink>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default Vc_deals_sidebar;
