import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap/";
import { Link } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import Popup from "reactjs-popup";
import { setupCache } from "axios-cache-adapter";
import { Row, Table, Col, Container, Modal } from "react-bootstrap";
import srcicon from "../../../assets/images/search.svg";
import fernet from "fernet";

import {
  matchCountry,
  matchCompany,
  matchSector,
  matchTitle,
} from "../investment-intellegence-screener/data";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class useFetch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      title: [],
      status1: false,
      country: [],
      sector: [],
      category: [],
      categoryand_name: "",
      result: false,
      selected: "",
      title_name: "",
      titleand_data: "",
      comapany_name: "",
      items: [],
      company_name: "",
      companyand_data: "",
      country_name: "",
      email: "",
      sector_name: "",
      category_name: "",
      startDate: new Date(),
      endDate: new Date(),
      start_date: "",
      end_date: "",
      last_days: "",
      token: "",
      region: null,
      by_market: null,
    };
  }
  handleauth = (id) => {
    axios
      .get("https://api.analyzemarkets.com/signinnew/?id=" + id)
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ login: res.data.data });
        var doubleValue = this.state.login.map((number) => {
          return number.token;
        });
        var doubleValue2 = this.state.login.map((number) => {
          return number.token;
        });

        var doubleValue1 = this.state.login.map((number) => {
          return number.email;
        });
        var secret = new fernet.Secret(
          "EptTTVdKktUo2JcHMqLlQUwQi3_lvuCpiHcnXk2Iehk="
        );
        console.log(secret, "secret");
        var token = new fernet.Token({
          secret: secret,
          time: Date.parse(1),
          iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        });
        const tokendata = token.encode(doubleValue2.pop());
        const emaildata = doubleValue1.shift();
        // console.log(tokendata, "Encryption Data");
        var token = new fernet.Token({
          secret: secret,
          token: tokendata,
          tokendatattl: 0,
        });
        token.decode();

        //console.log(token.decode());

        // console.log(doubleValue2.pop(), "Poped");
        // console.log(doubleValue1, "Email Platform");
        this.props.applyFilters("&email=" + emaildata + "&auth=" + tokendata);
      })
      .catch((error) => {
        //on error
        console.log(error);
      });
  };

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    //const email = doubleValue1.shift();
    const response1 = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const tokendatas = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    const id = localStorage.getItem("id");

    this.setState({
      rememberMe,
      id,
      response1,

      user,
      action,
    });

    this.handleauth(id);
    // this.props.applyFilters("email=" + email + "&token=" + token);
  };

  render() {
    return <div className=""> </div>;
  }
}

export default useFetch;
