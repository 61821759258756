import React, { Component } from "react";
import { Form } from "react-bootstrap/";
import { Link } from "react-router-dom";
import "./investment-wire-screener.css";
import Autocomplete from "react-autocomplete";
import { Row, Table, Col, Container, Modal } from "react-bootstrap";
import { setupCache } from "axios-cache-adapter";

import {
  matchCountry,
  matchCompany,
  matchSector,
  matchTitle,
} from "../investment-intellegence-screener/data";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class UiLookupSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      title: [],
      company: [],
      country: [],
      sector: [],
      name: [],
      category: [],

      result: false,
      selected: "",
      comapany_name: "",
      country_name: "",
      sector_name: "",
      category_name: "",
      categoryand_name: "",
      companyand_data: "",
      startDate: new Date(),
      endDate: new Date(),
      start_date: "",
      end_date: "",
      last_days: "",
      region: null,
      region: [],
      items: [],
      country_name: "",
      company: [],
      emailfrequency: "",
      show: false,
      alerts: [],
      setShow: false,
      companys: [],
      companyand_data: "",
      result: false,
      is_filter: false,
      status: false,
      id: "",
      user: "",
      token: "",
      exampleItems: [],
      selections: [],
      page: 0,
      current: 0,
      filter: "",
      old_route: "",
      pageSize: 25,
      count: 0,
      is_first: true,
      token: "",
      response: "",
      user: "",
      count: "",

      by_market: null,

      country: [],
      sector: [],
      category: [],
      categoryand_name: "",
      result: false,
      selected: "",
      title_name: "",
      titleand_data: "",
      comapany_name: "",
      companyand_data: "",
      country_name: "",
      sector_name: "",
      category_name: "",
      startDate: new Date(),
      endDate: new Date(),
      start_date: "",
      end_date: "",
      last_days: "",
      token: "",
      region: null,
      by_market: null,
    };
    this.getCompanyData = this.getCompanyData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLastDate = this.handleLastDate.bind(this);
    this.handleLastDays = this.handleLastDays.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleFilter = this.handleFilter.bind(this);

    this.onChangePage = this.onChangePage.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
  }
  handleLastDays = (e) => {
    console.log(Number.isInteger(e), e);
    if (Number.isInteger(parseInt(e))) {
      this.setState({
        last_days: e,
        startDate: "",
        start_date: "",
        end_date: "",
        endDate: "",
      });
    }
  };
  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  handleShow(id) {
    this.setState({
      comapany_name: id,
    });
  }

  handleClose() {
    this.setState({
      setShow: false,
    });
  }

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };
  handleFilter = (filter) => {
    console.log(filter, 31);
    this.setState({
      filter: filter,
      is_filter: true,
    });
    this.getData(this.state.old_route, filter, 0, true);
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    var favorite = [];
    this.getCompanyData(this.props.byRegion, this.props.byMarkets);

    var current_route = "";
    if (current_route == undefined) {
      current_route = "";
    }
    this.setState({
      old_route: current_route,
    });
    this.getData(current_route, "", 0, true);

    axios
      .get("https://api.analyzemarkets.com/regman/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ region: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ company: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    this.setState({
      user,
      token,
    });
  };
  getData = (route, filter, page, is_first) => {
    api({
      url:
        "https://api.analyzemarkets.com/companymanagementprivate/?name=" +
        route +
        "&start=" +
        page +
        "" +
        filter,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, "Company Data");
      this.setState({
        items: res.data.result,
        page: res.data.count,
        current: page,
        is_first: false,
      });
      if (filter != "" && res.data.count != res.data.totalcount) {
        console.log(filter, "filter");
        this.setState({
          status: true,
        });
      }
      if (res.data.count === res.data.totalcount) {
        this.setState({
          status: false,
        });
      }

      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
      }
      console.log(res.data.length + "Count Data");
    });
  };

  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }

  // this.setState({
  //   [name]: event.target.files[0],
  //   file_teaser: event.target.files.name,
  //   file_other: event.target.files.name,
  // });

  //.log("Selected file:", event.target.files[0]);

  refreshButton = () => {
    this.setState({
      comapany_name: "",
      country_name: "",
      sector_name: "",
      category_name: "",
    });
  };
  handleChange = (date) => {
    if (date !== null) {
      console.log(
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        "Test"
      );
      this.setState({
        start_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        startDate: date,
        last_days: "",
      });
    } else {
      this.setState({
        start_date: "",
        startDate: "",
        last_days: "",
      });
    }
  };
  handleLastDate = (date) => {
    if (date !== null) {
      console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
      this.setState({
        last_days: "",
        end_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        endDate: date,
      });
    } else {
      this.setState({
        last_days: "",
        end_date: "",
        endDate: "",
      });
    }
  };
  handleClick = (item) => {
    console.log(item, 22, this.state.last_days, 32);

    this.props.applyFilters(
      "&company=" +
        this.state.comapany_name +
        "&country=" +
        this.state.country_name
    );
  };

  getCompanyData = (region, markets) => {
    var params = "";
    if (region != null) {
      params = "?region=" + region;
    }

    if (markets != null && params != "") {
      params += "&by_market=" + markets;
    }

    if (markets != null && params == "") {
      params = "?by_market=" + markets;
    }
    console.log(params, 91);
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ company: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/sectorman/", {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ sector: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/category/", {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ category: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };
  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.byRegion !== null &&
      this.state.region !== this.props.byRegion
    ) {
      this.setState({
        region: this.props.byRegion,
        company: [],
        country: [],
      });
      console.log(this.props.byRegion, 138);
      this.getCompanyData(this.props.byRegion, null);
    }
    if (
      this.props.byMarkets !== null &&
      this.state.by_market !== this.props.byMarkets
    ) {
      this.setState({
        by_market: this.props.byMarkets,
        company: [],
        country: [],
      });
      this.getCompanyData(null, this.props.byMarkets);
    }
  };
  render() {
    return (
      <div className="">
        {/* //  <h4>Investment Wire Screener</h4>*/}
        <div className="form-group ">
          <Row>
            <Col>
              {" "}
              <div className="form-group autoc">
                <Autocomplete
                  value={
                    this.state.comapany_name.indexOf("%26") != -1
                      ? this.state.companyand_data
                      : this.state.comapany_name
                  }
                  inputProps={{
                    id: "company-autocomplete",
                    placeholder: "Select Company Name",
                  }}
                  wrapperStyle={{
                    position: "relative",
                    display: "inline-block",
                  }}
                  shouldItemRender={matchCompany}
                  items={this.state.company}
                  getItemValue={(item) => item.name}
                  onChange={(event, comapany_name) =>
                    this.setState({ comapany_name })
                  }
                  onSelect={(comapany_name) =>
                    this.setState({
                      comapany_name:
                        comapany_name.indexOf("&") != -1
                          ? comapany_name.replace("&", "%26")
                          : comapany_name,
                      companyand_data:
                        comapany_name.indexOf("%26") != -1
                          ? comapany_name.replace("%26", "&")
                          : comapany_name,
                    })
                  }
                  renderMenu={(item) => (
                    <div className="menu c-dropdown">{item}</div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${
                        isHighlighted ? "item-highlighted" : ""
                      }`}
                      key={item.id}
                    >
                      {item.name}
                    </div>
                  )}
                />
              </div>
            </Col>
            <Col>
              {" "}
              <div className="form-group autoc">
                <Autocomplete
                  value={this.state.country_name}
                  inputProps={{
                    id: "country-autocomplete",
                    placeholder: "Select Country",
                  }}
                  wrapperStyle={{
                    position: "relative",
                    display: "inline-block",
                  }}
                  items={this.state.country}
                  getItemValue={(item) => item.country}
                  shouldItemRender={matchCountry}
                  onChange={(event, country_name) =>
                    this.setState({ country_name })
                  }
                  onSelect={(country_name) => this.setState({ country_name })}
                  renderMenu={(children) => (
                    <div className="menu c-dropdown">{children}</div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${
                        isHighlighted ? "item-highlighted" : ""
                      }`}
                      key={item.country}
                    >
                      {item.country}
                    </div>
                  )}
                />
              </div>
            </Col>
            {/* <Col>
              <div className="form-group autoc">
                <Autocomplete
                  value={this.state.sector_name}
                  inputProps={{
                    id: "sector-autocomplete",
                    placeholder: "Select Sector",
                  }}
                  wrapperStyle={{
                    position: "relative",
                    display: "inline-block",
                  }}
                  items={this.state.sector}
                  getItemValue={(item) => item.sector}
                  shouldItemRender={matchSector}
                  onChange={(event, sector_name) =>
                    this.setState({ sector_name })
                  }
                  onSelect={(sector_name) => this.setState({ sector_name })}
                  renderMenu={(children) => (
                    <div className="menu c-dropdown">{children}</div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${
                        isHighlighted ? "item-highlighted" : ""
                      }`}
                      key={item.sector}
                    >
                      {item.sector}
                    </div>
                  )}
                />
              </div>
            </Col> */}
          </Row>

          <div className="d-flex justify-content-between">
            <Link
              className="btn btn-blue"
              onClick={() => {
                this.handleClick("region");
              }}
            >
              Apply
            </Link>
            <Link className="btn btn-blue" onClick={this.refreshButton}>
              Reset
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default UiLookupSearch;
