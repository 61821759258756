import React, { Component } from "react";
import { Link, BrowserRouter } from "react-router-dom";
import "./navfilter.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter
});

class Navfilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: "",
      region: [1, 2, 3, 4, 5],
      category: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      regionActive: null,
      sectorActive: null,
      is_first: true
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.sectorActive = this.sectorActive.bind(this);
  }

  handleClick = item => {
    console.log(item, 22);
    this.setState({ regionActive: "region" + item.id });
  };
  sectorActive = id => {
    console.log(id, 27);
    this.setState({ sectorActive: "category" + id });
  };

  componentDidMount = () => {
    api({
      url: "https://api.analyzemarkets.com/navfilter/",
      method: "get"
    }).then(async res => {
      // Do something fantastic with response.data \o/
      console.log("Request response Nav Filter:", res);
      this.setState({
        category: res.data.category,
        region: res.data.region,
        is_first: false
      });
      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  handleDropdownChange(type, e) {
    console.log(JSON.parse(e.target.value), type);
    var data = JSON.parse(e.target.value);
    console.log(data);
    this.props.greetChild(data, type);
  }
  render() {
    var greetChild = this.props.greetChild;

    return (
      <div className=" mt-1 mb-5">
        {this.state.is_first && (
          <nav className="fn-nav d-none d-lg-block">
            <ul>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        )}
        {!this.state.is_first && (
          <nav className="fn-nav d-none d-lg-block">
            <ul>
              {this.state.region.map((item, i) => (
                <li
                  key={i}
                  onClick={() => {
                    greetChild(item, "region");
                    this.handleClick(item);
                  }}
                >
                  <BrowserRouter>
                    <Link
                      to="#"
                      className={
                        "region" + item.id === this.state.regionActive
                          ? " active"
                          : ""
                      }
                    >
                      {item.region}
                    </Link>
                  </BrowserRouter>
                </li>
              ))}
            </ul>
          </nav>
        )}

        {this.state.is_first && (
          <nav className="fn-nav d-none d-lg-block">
            <ul>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="ph-item w-100px mb-0">
                  <div class="ph-col-12">
                    <div class="ph-row mb-0">
                      <div class="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        )}
        {!this.state.is_first && (
          <nav className="fn-nav d-none d-lg-block">
            <ul>
              {this.state.category.map((item, i) => (
                <li
                  key={i}
                  onClick={() => {
                    greetChild(item, "category");
                    this.sectorActive(item.id);
                  }}
                >
                  <BrowserRouter>
                    <Link
                      to="#"
                      className={
                        "category" + item.id === this.state.sectorActive
                          ? " active"
                          : ""
                      }
                    >
                      {item.name}
                    </Link>
                  </BrowserRouter>
                </li>
              ))}
            </ul>
          </nav>
        )}
        <div className="fn-nav d-block d-lg-none sector-mobile">
          <select
            className="browser-default custom-select"
            id="dropdown"
            onChange={e => this.handleDropdownChange("region", e)}
          >
            <option value="">Select Region</option>
            {this.state.region.map((item, i) => (
              <option key={i} value={JSON.stringify(item)}>
                {item.region}
              </option>
            ))}
          </select>
        </div>
        <div className="fn-nav d-block d-lg-none sector-mobile">
          <select
            className="browser-default custom-select"
            id="dropdown"
            onChange={e => this.handleDropdownChange("category", e)}
          >
            <option value="">Select Category</option>
            {this.state.category.map((item, i) => (
              <option key={i} value={JSON.stringify(item)}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default Navfilter;
