import { Container, Row, Col } from "react-bootstrap/";
import { Helmet } from "react-helmet";
import React, { Component, Fragment } from "react";
import Header from "../../component/global/header/index";

class Trademarks extends Component {
  state = {
    item: [],
    section_one: [],
    section_two: [],
    section_three: [],
    news: {},
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>AnalyzeMarkets Trademarks</title>
            <meta
              name="description"
              content="AnalyzeMarkets is the trade name and trademark of AnalyzeMarkets ltd"
            />

            <meta property="og:title" content="AnalyzeMarkets Trademarks" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="AnalyzeMarkets is the trade name and trademark of AnalyzeMarkets ltd"
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="AnalyzeMarkets Trademarks" />

            <meta
              itemprop="description"
              content="AnalyzeMarkets is the trade name and trademark of AnalyzeMarkets ltd"
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="AnalyzeMarkets Trademarks" />

            <meta
              name="twitter:description"
              content="AnalyzeMarkets is the trade name and trademark of AnalyzeMarkets ltd"
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            <Col md={12}>
              <hr />
              <h2 className="text-uppercase mb-4">
                AnalyzeMarkets ltd Trademarks
              </h2>
              <p>
                AnalyzeMarkets is a tradename, trademark, and service marks of
                AnalyzeMarkets ltd. All rights reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Trademarks;
