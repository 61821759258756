import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "./v&c_dealmonitor.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import Linkify from "react-linkify";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class VC_Deals_Monitor_Details extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    details: [],
    acquirer: [],
    target: [],
    investor: [],
    other_investors: [],
    DealValuation: [],
    advisors: [],
    news: [],
    company: [],
  };
  handleBack = () => {
    this.props.history.goBack();
  };

  componentDidMount = () => {
    api({
      url:
        "https://api.analyzemarkets.com/gddvcdeal_single/" +
        this.props.match.params.deal +
        "/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        details: res.data,
        company: res.data.company,

        // acquirer: res.data.GDDMandADealsAcquirer,
        // target: res.data.GDDMandADealsTarget,
        // DealValuation: res.data.GDDMandADealsValidation,
        advisors: res.data.GDDvcadvisors,
        news: res.data.GDD_VC_News,
        investor: res.data.lead_investors,
        other_investors: res.data.other_participating_investors,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    // $(document).ready(function() {
    //   $("a[href*=#]").bind("click", function(e) {
    //     e.preventDefault(); // prevent hard jump, the default behavior

    //     var target = $(this).attr("href"); // Set the target as variable

    //     // perform animated scrolling by getting top-position of target-element and set it as scroll target
    //     $("html, body")
    //       .stop()
    //       .animate(
    //         {
    //           scrollTop: $(target).offset().top
    //         },
    //         600,
    //         function() {
    //           window.location.hash = target; //attach the hash (#jumptarget) to the pageurl
    //         }
    //       );

    //     return false;
    //   });
    // });

    // $(window)
    //   .scroll(function() {
    //     var scrollDistance = $(window).scrollTop();

    //     // Show/hide menu on scroll
    //     if (scrollDistance >= 1800) {
    //       $(".navigation").css({
    //         position: "fixed",
    //         bottom: 200
    //       });
    //     } else {
    //       $(".navigation").css({
    //         position: "fixed",
    //         bottom: 12
    //       });
    //     }

    //     // Assign active class to nav links while scolling
    //     $(".").each(function(i) {
    //       if ($(this).position().top <= scrollDistance) {
    //         $(".navigation a.active").removeClass("active");
    //         $(".navigation a")
    //           .eq(i)
    //           .addClass("active");
    //       }
    //     });
    //   })
    //   .scroll();
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  render() {
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    const data = this.state.details;

    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <Fragment>
        <Header></Header>
        <Navfilter id="sector-desktop"></Navfilter>
        <Container>
          <Button
            onClick={this.handleBack}
            variant="outline-light"
            className="MarginRight padDown"
          >
            <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
          </Button>
          <Row>
            <Col className="">
              <Row className="justify-content-md-center">
                <Col xs sm={12} class="">
                  <Row>
                    <Col sm={5}>
                      {" "}
                      <div class=" hero" id="2">
                        <h3 className="UpperCase">STARTUP</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            {/* <tr>
                              <td width="200">Company</td>
                              {this.state.company.map((item, key) => (
                                <td> {item.name}</td>
                              ))}
                              {/* <td> {data.company.name}</td>
                            </tr> */}
                            <tr>
                              <td width="250">Category</td>
                              <td>
                                {data.startup_category != null &&
                                  data.startup_category}
                              </td>
                            </tr>
                            <tr>
                              <td width="250">Sector</td>
                              <td>
                                {data.startup_sector != null &&
                                  data.startup_sector}
                              </td>
                            </tr>
                            <tr>
                              <td width="250">
                                Total Equity Amount Raised Since Established
                              </td>
                              <td>
                                {data.total_equity_funding_amount_established !=
                                  null &&
                                  data.total_equity_funding_amount_established}
                              </td>
                            </tr>
                            <tr>
                              <td width="250">
                                Total Funding Amount Raised Since Established
                              </td>
                              <td>
                                {data.total_funding_amount_established !=
                                  null && data.total_funding_amount_established}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <br></br>
                      <div class="" id="5">
                        <h3 className="UpperCase">Advisors</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            {this.state.advisors.map((advisor, index) => (
                              <tr>
                                <td width="250" className="space">
                                  <div>{advisor.title}</div>
                                </td>
                                <td>
                                  <div className="paddingRight">
                                    {advisor.companyname.map((adv, key) => (
                                      <div>
                                        {adv.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              adv.api_url +
                                              "/"
                                            }
                                          >
                                            <u className="underline1">
                                              {adv.name}
                                            </u>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline1">
                                              {adv.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <br></br>
                      <div class="" id="6">
                        <h3 className="UpperCase">News</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="250" className="space">
                                {this.state.news.map((item, index) => (
                                  <div>{item.news}</div>
                                ))}
                              </td>
                              <Linkify componentDecorator={componentDecorator}>
                                <td className="word-wrap">
                                  {this.state.news.map((item, index) => (
                                    <div>{item.value + " "}</div>
                                  ))}
                                </td>
                              </Linkify>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col sm={7}>
                      <div class=" hero" id="1">
                        <h3 className="UpperCase">Deal Information</h3>
                        <Table className="tblnoline" size="lg" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="150">Deal</td>

                              <td>{data.deal}</td>
                            </tr>

                            <tr>
                              <td>Deal Description</td>
                              <td>
                                <p
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: data.brief_description,
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td width="150" className="space">
                                Deal Official Announcement
                              </td>

                              <td>{data.deal_official_announcement}</td>
                            </tr>

                            <tr>
                              <td>Deal Announced Date</td>

                              <td>
                                <Moment format="DD MMM YYYY">
                                  {data.reported_date}
                                </Moment>
                              </td>
                            </tr>

                            <tr>
                              <td>Funding Round</td>

                              <td>{data.funding_round}</td>
                            </tr>

                            <tr>
                              <td>Total Amount Raised</td>
                              <td>{data.investment_amount_raised}</td>
                            </tr>
                            <tr>
                              <td>Lead Investors</td>
                              <td>
                                {this.state.investor.map((inv, index) => (
                                  <div>
                                    {" "}
                                    {inv.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/global-company-database-details/" +
                                          inv.api_url +
                                          "/"
                                        }
                                      >
                                        <u className="underline1">{inv.name}</u>
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline1">{inv.name}</u>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </td>
                            </tr>

                            <tr>
                              <td>Participating Investors</td>
                              <td>
                                {this.state.other_investors.map(
                                  (other, index) => (
                                    <div>
                                      {" "}
                                      {other.api_url != null ? (
                                        <Link
                                          to={
                                            "/en/global-company-database-details/" +
                                            other.api_url +
                                            "/"
                                          }
                                        >
                                          <u className="underline1">
                                            {other.name}
                                          </u>
                                        </Link>
                                      ) : (
                                        <Link>
                                          <u className="underline1">
                                            {other.name}
                                          </u>
                                        </Link>
                                      )}
                                    </div>
                                  )
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td>Deal Status</td>

                              <td>{data.deal_status}</td>
                            </tr>

                            <tr>
                              <td>Company Valuation</td>

                              <td>{data.company_valuation}</td>
                            </tr>

                            <tr>
                              <td>Use of Funds</td>
                              <td
                                   dangerouslySetInnerHTML={{__html:data.use_of_funds_raised}}>
                                  </td>
                            </tr>

                            <tr>
                              <td>Reference</td>
                              <td className="word-wrap">
                                <Linkify
                                  componentDecorator={componentDecorator}
                                >
                                  {data.reference}
                                </Linkify>
                              </td>
                            </tr>
                            <tr>
                              <td>Deal Reference Number</td>
                              <td>{data.dealinternalreferencenumber}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1>
                    </div>
                    */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default VC_Deals_Monitor_Details;
