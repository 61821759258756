import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "../create-deal/create-deal.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import Linkify from "react-linkify";
import NumericLabel from "react-pretty-numbers";
import Signin from "../../pages/signin/signinIndex";

import {
  Row,
  Table,
  Col,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class VC_Details extends Component {
  constructor(props) {
    super(props);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    details: [],
    acquirer: [],
    target: [],
    filterdata: "",
    DealValuation: [],
    advisors: [],
    news: [],
  };
  componentDidMount = () => {
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/gdmvcsingle/" +
        this.props.match.params.dealheadline +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Vc Single");
      this.setState({
        details: res.data,
        acquirer: res.data.GDDMandADealsBuyerAcquirer,
        target: res.data.GDDMandADealsBuyerTarget,
        DealValuation: res.data.GDDMandADealsbuyerValuation,
        advisors: res.data.GDDMADealsBuyerAdvisors,
        news: res.data.GDDMADealsBuyerNews,
      });

      // Interacting with the store, see `localForage` API.
    });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const data = this.state.details;
    let params = {
      justification: "L",
      commafy: true,
    };

    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Navfilter id="sector-desktop"></Navfilter>
            <Container>
              <Helmet title={data.dealheadline}>
                <meta
                  name="description"
                  content={data.companydealbriefdescription}
                />
                <meta property="og:title" content="M&A Deals Monitor" />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  name="og:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  itemprop="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Button
                onClick={this.handleBack}
                variant="outline-light"
                className="MarginRight padDown"
              >
                <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
              </Button>
              <Row>
                <Col className="">
                  <Row className="justify-content-md-center">
                    <Col xs sm={12} class="">
                      <Row>
                        <Col sm={5}>
                          {" "}
                          <div class="" id="6">
                            <h3 className="UpperCase">Key Information</h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td width="200">Startup Name</td>
                                  <td>{data.startupname}</td>
                                </tr>
                                <tr>
                                  <td>Funding Round</td>
                                  <td>{data.fundinground}</td>
                                </tr>
                                <tr>
                                  <td>Total Amount Seeking (USD mn)</td>
                                  <td>
                                    <NumericLabel params={params}>
                                      {data.totalamountseeking}
                                    </NumericLabel>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <div class="" id="3">
                            {data.teaserpresentation === null &&
                            data.otherdocumentstoupload === null &&
                            data.projectpicturevideotoupload === null ? (
                              ""
                            ) : (
                              <h3 className="UpperCase">
                                Requirement Documents
                              </h3>
                            )}

                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                {data.teaserpresentation !== null && (
                                  <tr>
                                    <td width="250">Teaser/Presentation:</td>
                                    <td>
                                      <a
                                        href={data.teaserpresentation}
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    </td>
                                  </tr>
                                )}
                                {data.otherdocumentstoupload !== null && (
                                  <tr>
                                    <td width="250">Other documents</td>
                                    <td>
                                      <a
                                        href={data.otherdocumentstoupload}
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    </td>
                                  </tr>
                                )}

                                {data.projectpicturevideotoupload !== null && (
                                  <tr>
                                    <td width="250">Deal Pictures/Videos:</td>
                                    <td>
                                      <a
                                        href={data.projectpicturevideotoupload}
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                          <div class="" id="6">
                            <h3 className="UpperCase">
                              Publisher's Contact Information
                            </h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td>Name</td>
                                  <td>{data.name}</td>
                                </tr>
                                <tr>
                                  <td>Title</td>
                                  <td>{data.title}</td>
                                </tr>
                                <tr>
                                  <td width="">Email</td>
                                  <td>{data.email}</td>
                                </tr>
                                <tr>
                                  <td>Contact Number</td>
                                  <td>{data.contactnumber}</td>
                                </tr>
                                <tr>
                                  <td>
                                    Is the Publisher of the Deal Authorized to
                                    Create this Deal
                                  </td>
                                  <td>
                                    {data.youauthorizedcreatedealbehalfcompany}
                                  </td>
                                </tr>

                                <tr>
                                  <td>Deal Status</td>
                                  <td>{data.userdealstatus}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col sm={7}>
                          <div class=" hero" id="1">
                            <h3 className="UpperCase">About Startup </h3>
                            <Table
                              className="tblnoline"
                              size="lg"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td width="250">Deal Headline</td>

                                  <td>{data.dealheadline}</td>
                                </tr>
                                <tr>
                                  <td width="200">Deal Code</td>

                                  <td>{data.dealcodename}</td>
                                </tr>
                                <tr>
                                  <td width="200">Deal Created date </td>

                                  <td>
                                    <Moment format="DD-MMM-YYYY">
                                      {data.created_at}
                                    </Moment>
                                    &nbsp;
                                    <Moment format="hh:mm a">
                                      {data.created_at}
                                    </Moment>{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td width="200">
                                    Startup Incorporated Country
                                  </td>

                                  <td>{data.startupincorporatedcountry}</td>
                                </tr>
                                <tr>
                                  <td width="200">Startup Category</td>

                                  <td>{data.selectcategory}</td>
                                </tr>
                                <tr>
                                  <td width="200">Startup Sector</td>

                                  <td>{data.startupsector}</td>
                                </tr>
                                <tr>
                                  <td width="200">Startup Established Date</td>

                                  <td>
                                    <Moment format="DD-MMM-YYYY">
                                      {data.startupestablisheddate}
                                    </Moment>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="200">Total Number of Employees</td>

                                  <td>
                                    {" "}
                                    <NumericLabel params={params}>
                                      {data.totalnoofemployees}
                                    </NumericLabel>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="200">Startup Founders</td>

                                  <td>{data.startupfounders}</td>
                                </tr>
                                <tr>
                                  <td width="200">
                                    Company/Deal Brief Description
                                  </td>

                                  <td className="word-wrap">
                                    {" "}
                                    <p
                                      className="unorder"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          data.companydealbriefdescription,
                                      }}
                                    />
                                  </td>
                                </tr>{" "}
                              </tbody>
                            </Table>
                            <legend>
                              {" "}
                              <b className="upercase">Key Financials</b>
                            </legend>

                            <Table
                              className="tblnoline"
                              size="lg"
                              responsive="lg"
                            >
                              <tr>
                                <td width="200">
                                  Historical Financial Statements of the Company
                                  Audited
                                </td>

                                <td>{data.financialstatementsaudited}</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td width="200">Fisical Year End</td>

                                <td>{data.fiscalyearend}</td>
                                <td></td>
                              </tr>

                              <tr>
                                <td width="200">Reporting Currency</td>

                                <td>{data.reportingcurrency}</td>
                                <td></td>
                              </tr>
                            </Table>
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th width="200"> </th>
                                  <th className="colorWhite" width="200">
                                    <b>Last Fiscal Year</b>
                                  </th>
                                  <th className="colorWhite" width="200">
                                    <b>Projected Fiscal Year</b>
                                  </th>
                                </tr>
                              </thead>
                              <tr>
                                <td>Revenue (USD mn)</td>
                                <td>
                                  {" "}
                                  <NumericLabel params={params}>
                                    {data.lastfiscalyearrevenue}
                                  </NumericLabel>
                                </td>
                                <td>
                                  <NumericLabel params={params}>
                                    {data.projectedfiscalyearrevenue}
                                  </NumericLabel>
                                </td>
                              </tr>
                              <tr>
                                <td>EBITDA (USD mn)</td>
                                <td>
                                  {" "}
                                  <NumericLabel params={params}>
                                    {data.lastfiscalyearEBITDA}
                                  </NumericLabel>
                                </td>
                                <td>
                                  {" "}
                                  <NumericLabel params={params}>
                                    {data.projectedfiscalyearEBITDA}
                                  </NumericLabel>
                                </td>
                              </tr>
                              <tr>
                                <td>Total Assets (USD mn)</td>
                                <td>
                                  {" "}
                                  <NumericLabel params={params}>
                                    {data.lastfiscalyeartotalassets}
                                  </NumericLabel>
                                </td>
                                <td>
                                  {" "}
                                  <NumericLabel params={params}>
                                    {data.projectedfiscalyeartotalassets}
                                  </NumericLabel>
                                </td>
                              </tr>
                            </Table>
                          </div>

                          {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                        </Col>
                      </Row>
                      <Row>
                        {" "}
                        <Col sm={7}>
                          {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default VC_Details;
