import React, { Component, Fragment } from "react";
import Followus from "../../component/global/followus/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import Screener from "../../component/global/screener";
import Audiopthumb from "../../component/global/audio-thumb";
import { Container, Row, Col } from "react-bootstrap/";
import axios from "axios";
import { Redirect } from "react-router";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import ReactHtml from "raw-html-react";
import Pagination from "../../component/global/pagination/index";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";

class SearchPressrelease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      result: false,
      is_show: false,
      type: "",
      is_first: true,
      selected: "",
      exampleItems: [],
      pageSize: 21,
      page: 1,
      old_page: 0,
      total_pages: 0,
      current: 1,
      offset: 21,
      previos_offset: 0,
    };
    this.greetHandler = this.greetHandler.bind(this);
    this.getData = this.getData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }
  greetHandler(childName, startDate, endDate) {
    console.log(childName, 4242, startDate, endDate);
    this.getData(startDate, endDate, this.state.page, false, childName);
  }

  onChangePage = (pageOfItems, page) => {
    console.log(pageOfItems, page, 46);
    this.setState({
      page: page,
      old_page: page,
    });
    console.log(47, page, this.state.current);
    // var current_route = this.props.match.params.id;
    if (page - 1 !== this.state.current) {
      console.log(555555);
      this.getData("", this.state.filter, page, false, this.state.type);
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    this.getData("", "", 1, true, this.props.match.params.type);
  };

  getData = (startDate, endDate, page, is_first, type) => {
    var offset = 21;
    console.log(this.props.match.params.type, 684);
    offset = offset * parseInt(page - 1);
    this.setState({
      previos_offset: parseInt(offset),
      offset: offset + 21,
    });
    this.setState({
      type: type,
    });

    page = page - 1;

    if (type === "global-investment-wire") {
      axios
        .get(
          "https://api.analyzemarkets.com/giwm_search/?search=" +
            this.props.match.params.search +
            "&page=" +
            page +
            "&startDate" +
            startDate +
            "&endDate" +
            endDate +
            "&limit=21&offset=" +
            offset,
          {}
        )
        .then((res) => {
          console.log(res.data.count, 61);

          this.setState({
            news: res.data.results,
            page: res.data.count,
            total_pages: res.data.count,
            current: page,
            is_show: true,
            is_first: false,
          });

          if (res.data.count <= offset + 21) {
            this.setState({
              offset: res.data.count,
            });
          }

          console.log(62626262, is_first, res.data.count);

          if (is_first) {
            this.setState({
              exampleItems: [...Array(res.data.count).keys()].map((i) => ({
                id: i + 1,
                name: "Item " + (i + 1),
              })),
            });
          }
        })
        .catch((error) => {
          //on error
          // alert("There is an error in API call.");
        });
    } else if (type === "global-investment-intelligence") {
      axios
        .get(
          "https://api.analyzemarkets.com/giim_search/?search=" +
            this.props.match.params.search +
            "&page=" +
            page +
            "&startDate" +
            startDate +
            "&endDate" +
            endDate +
            "&limit=21&offset=" +
            offset,
          {}
        )
        .then((res) => {
          console.log(res.data.count, 61);

          this.setState({
            news: res.data.results,
            page: res.data.count,
            total_pages: res.data.count,
            current: page,
            is_show: true,
            is_first: false,
          });

          if (res.data.count <= offset + 21) {
            this.setState({
              offset: res.data.count,
            });
          }

          if (is_first) {
            this.setState({
              exampleItems: [...Array(res.data.count).keys()].map((i) => ({
                id: i + 1,
                name: "Item " + (i + 1),
              })),
            });
          }
        })
        .catch((error) => {
          //on error
          // alert("There is an error in API call.");
        });
    } else {
      console.log(40);
      this.setState({ result: true });
    }
  };
  render() {
    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Helmet>
            <title>
              AnalyzeMarkets - Global Investment Intelligence Platform
            </title>
            <meta
              name="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              property="og:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              itemprop="name"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              itemprop="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta
              name="twitter:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              name="twitter:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <hr />

          <Row>
            <Col className="w-70p">
              <h2>Search results for "{this.props.match.params.search}"</h2>
              {this.state.is_show && (
                <small className="text-right w-100 d-block">
                  Showing {this.state.previos_offset}-{this.state.offset} of{" "}
                  {this.state.total_pages}
                </small>
              )}
            </Col>
          </Row>

          <Row>
            {!this.state.is_first && (
              <Fragment>
                <Col className="w-70p">
                  <hr className="mt-0" />
                  {this.state.news.map((item, index) => (
                    <article key={index}>
                      <div className="two-item ">
                        {this.state.type ==
                          "global-investment-intelligence" && (
                          <div className="two-image-holder">
                            <Audiopthumb image={item.image} />
                          </div>
                        )}

                        <div className="two-content-holder">
                          {this.state.type ==
                            "global-investment-intelligence" && (
                            <Fragment>
                              <span className="newstag mb-1">
                                {item.news_country_category_sector_details}
                              </span>
                              <Link to={"/en/intelligence/" + item.url}>
                                <h3> {item.title}</h3>
                              </Link>
                            </Fragment>
                          )}

                          {this.state.type == "global-investment-wire" && (
                            <Fragment>
                              <span className="newstag mb-1">
                                {item.news_country_category_sector_details}
                              </span>
                              <Link to={"/en/wire/" + item.url}>
                                <h3> {item.title}</h3>
                              </Link>
                            </Fragment>
                          )}
                          <h6 className="font-15 font700 mb-3">
                            <Moment format="DD-MMM-YYYY">
                              {item.created_at}
                            </Moment>
                            &nbsp;
                            <Moment format="hh:mm a">
                              {item.created_at}
                            </Moment>{" "}
                            GMT
                            <Moment format="Z">{item.created_at}</Moment>
                          </h6>
                          {this.state.type ==
                            "global-investment-intelligence" && (
                            <p>
                              <ReactHtml html={item.first_para} />
                            </p>
                          )}

                          {this.state.type == "global-investment-wire" && (
                            <Fragment>
                              <p>
                                <ReactHtml html={item.first_para} />
                              </p>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      <hr className="mt-0" />
                    </article>
                  ))}

                  <small className="text-right w-100 d-block">
                    Showing {this.state.previos_offset}-{this.state.offset} of{" "}
                    {this.state.total_pages}
                  </small>

                  <Col md="12" className="text-center">
                    <Pagination
                      items={this.state.exampleItems}
                      pageSize={this.pageSize}
                      onChangePage={this.onChangePage}
                    ></Pagination>
                  </Col>
                  <div></div>
                </Col>
                <Col className="w-30p">
                  <div className="widget">
                    <Screener
                      greetChild={this.greetHandler}
                      search={this.props.match.params.search}
                    />
                  </div>
                  <div className="widget">
                    <Followus />
                  </div>
                  <div className="widget">
                    <Downloadapp />
                  </div>
                  <div className="widget">
                    <SubscriberAccsss />
                  </div>
                </Col>
              </Fragment>
            )}

            {this.state.is_first && (
              <Fragment>
                <Col className="w-70p">
                  <Row>
                    <div className="ph-col-12">
                      <div className="ph-row mt-3">
                        <div className="ph-col-12"></div>

                        <div className="ph-col-12"></div>
                      </div>
                    </div>

                    <Col md="12">
                      <div className="ph-row bdrph">
                        <div className="ph-col-8"></div>
                        <br />
                        <div className="ph-col-8"></div>
                      </div>
                    </Col>
                    <div className="ph-row bdrph"></div>
                    <div className="ph-col-12">
                      <div className="ph-row bdrph"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>

                    <Col md="12">
                      <div className="ph-row bdrph">
                        <div className="ph-col-8"></div>
                        <br />
                        <div className="ph-col-8"></div>
                      </div>
                    </Col>
                    <div className="ph-row bdrph"></div>

                    <div className="ph-col-12">
                      <div className="ph-row bdrph"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>

                    <Col md="12">
                      <div className="ph-row bdrph">
                        <div className="ph-col-8"></div>
                        <br />
                        <div className="ph-col-8"></div>
                      </div>
                    </Col>
                    <div className="ph-row bdrph"></div>

                    <div className="ph-col-12">
                      <div className="ph-row bdrph"></div>
                      <div className="ph-row">
                        <div className="ph-col-10"></div>
                      </div>
                    </div>

                    <Col md="12">
                      <div className="ph-row bdrph">
                        <div className="ph-col-8"></div>
                        <br />
                        <div className="ph-col-8"></div>
                      </div>
                    </Col>
                    <div className="ph-row bdrph"></div>
                    <div className="ph-col-12">
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </Row>
                </Col>
              </Fragment>
            )}
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default SearchPressrelease;
