import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap/";
import { Link } from "react-router-dom";
import "./investment-wire-screener.css";
import Autocomplete from "react-autocomplete";
import Popup from "reactjs-popup";
import { setupCache } from "axios-cache-adapter";
import { Row, Table, Col, Container, Modal } from "react-bootstrap";
import srcicon from "../../../assets/images/search.svg";

import {
  matchCountry,
  matchCompany,
  matchSector,
  matchTitle,
} from "../investment-intellegence-screener/data";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Iws extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      title: [],
      status1: false,
      country: [],
      sector: [],
      category: [],
      categoryand_name: "",
      result: false,
      selected: "",
      title_name: "",
      titleand_data: "",
      comapany_name: "",
      items: [],
      company_name: "",
      companyand_data: "",
      country_name: "",
      sector_name: "",
      category_name: "",
      startDate: new Date(),
      endDate: new Date(),
      start_date: "",
      end_date: "",
      last_days: "",
      token: "",
      region: null,
      by_market: null,
    };
    this.getCompanyData = this.getCompanyData.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.changeTerm = this.changeTerm.bind(this);

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.handleLastDate = this.handleLastDate.bind(this);
    this.handleLastDays = this.handleLastDays.bind(this);
  }
  handleLastDays = (e) => {
    console.log(Number.isInteger(e), e);
    if (Number.isInteger(parseInt(e))) {
      this.setState({
        last_days: e,
        startDate: "",
        start_date: "",
        end_date: "",
        endDate: "",
      });
    }
  };
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }

  closeSearch = (company) => {
    console.log("Search News", company);
    this.setState({
      company_name: company,
    });
    this.getData(company, this.state.old_route, 0, true);
  };
  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      console.log(55);
      this.getData(this.state.company_name, "", page, false);
    }
  };

  handleShow(id) {
    id = id.indexOf("&") != -1 ? id.replace("&", "%26") : id;
    this.setState({
      comapany_name: id,
      companyand_data: id.indexOf("%26") != -1 ? id.replace("%26", "&") : id,
    });
  }

  handleChange = (date) => {
    console.log(
      date.getDate(),
      date.getMonth() + 1,
      date.getFullYear(),
      "Gii date"
    );
    this.setState({
      start_date: date.getFullYear() + "-" + +"-" + date.getDate(),
      startDate: date,
      last_days: "",
    });
  };
  keyPress(e) {
    if (e.keyCode == 13) {
      this.getData(e.target.value, "", 0, false);
      // put the login here
    }
  }
  handleLastDate = (date) => {
    if (date !== null) {
      console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
      this.setState({
        last_days: "",
        end_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        endDate: date,
      });
    } else {
      this.setState({
        last_days: "",
        end_date: "",
        endDate: "",
      });
    }
  };
  handleClick = (item) => {
    console.log(item, 22, this.state.last_days, 32);
    console.log(this.state.title_name)

    this.props.applyFilter(
      "&title=" +
      this.state.title_name +
      "&company_name=" +
      this.state.comapany_name +
      "&sector_name=" +
      this.state.sector_name +
      "&country_name=" +
      this.state.country_name +
      "&category_name=" +
      this.state.category_name +
      "&start_date=" +
      this.state.start_date +
      "&end_date=" +
      this.state.end_date +
      "&last_days=" +
      this.state.last_days
    );
  };

  refreshButton = () => {
    this.setState({
      comapany_name: "",
      country_name: "",
      sector_name: "",
      category_name: "",
    });
  };

  getCompanyData = (region, markets) => {
    var params = "";
    if (region != null) {
      params = "?region=" + region;
    }

    if (markets != null && params != "") {
      params += "&by_market=" + markets;
    }

    if (markets != null && params == "") {
      params = "?by_market=" + markets;
    }
    console.log(params, 91);
    axios
      .get("https://api.analyzemarkets.com/giwmtitle/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ title: res.data.result });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/sectorman/", {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ sector: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/category/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ category: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        const arrays = [];
        for (let i = 0; i < res.data.length; i++) {
          arrays.push(res.data[i]);
        }
        this.setState({ company: arrays });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    var favorite = [];
    this.getCompanyData(this.props.byRegion, this.props.byMarkets);

    var current_route = "";
    if (current_route == undefined) {
      current_route = "";
    }
    this.setState({
      old_route: current_route,
    });
    this.getData("", current_route, 1, true);
    const token = localStorage.getItem("token");
    this.setState({ token });
  };
  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.byRegion !== null &&
      this.state.region !== this.props.byRegion
    ) {
      this.setState({
        region: this.props.byRegion,
        company: [],
        country: [],
      });
      console.log(this.props.byRegion, 138);
      this.getCompanyData(this.props.byRegion, null);
    }
    if (
      this.props.byMarkets !== null &&
      this.state.by_market !== this.props.byMarkets
    ) {
      this.setState({
        by_market: this.props.byMarkets,
        company: [],
        country: [],
      });
      this.getCompanyData(null, this.props.byMarkets);
    }
  };
  getData = (company, route, page, is_first) => {
    api({
      url:
        "https://api.analyzemarkets.com/companymanagementprivatenew/?name=" +
        route +
        "&start=" +
        page +
        "&search=" +
        company,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Company Data");
      this.setState({
        items: res.data.result,
        page: res.data.count,
        current: page,
        is_first: false,
      });
      if (
        this.state.company_name != "" &&
        res.data.count != res.data.totalcount
      ) {
        this.setState({
          status1: true,
        });
      }
      if (res.data.count === res.data.totalcount) {
        this.setState({
          status1: false,
        });
      }

      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
        if (res.data.count === 0) {
          this.setState({
            nosearch: true,
            search: false,
          });
        }
        if (res.data.count != 0) {
          this.setState({
            search: true,
            nosearch: false,
          });
        }
      }
      console.log(this.state.count + "Count Data");
    });
  };

  render() {
    return (
      <div className="iis greyborder2px">
        <h4>Investment Wire Screener</h4>
        <div className="pdg15">
          <div className="form-group autoc">
            <Autocomplete
              value={
                this.state.title_name.indexOf("%26") != -1
                  ? this.state.titleand_data
                  : this.state.title_name.indexOf("%27") != -1
                    ? this.state.titleand_data
                    : this.state.title_name.indexOf("%2C") != -1
                      ? this.state.titleand_data
                      : this.state.title_name.indexOf("%2D") != -1
                        ? this.state.titleand_data
                        : this.state.title_name.indexOf("%3B") != -1
                          ? this.state.titleand_data
                          : this.state.title_name
              }
              inputProps={{
                id: "deal-autocomplete",
                placeholder: "Deal",
              }}
              wrapperStyle={{
                position: "relative",
                display: "inline-block",
              }}
              shouldItemRender={matchTitle}
              items={this.state.title}
              getItemValue={(item) => item.title}
              onChange={(event, title_name) => this.setState({ title_name })}
              onSelect={(title_name) =>
                this.setState({
                  title_name: title_name.replace("&", "%26").replace("'", "%27").replace(",", "%2C").replace(";", "%3B").replace("-", "%2D"),
                    // title_name.indexOf("&") != -1
                    //   ? title_name.replace("&", "%26")
                    //   : title_name.indexOf("'") != -1
                    //     ? title_name.replace("'", "%27")
                    //     : title_name.indexOf(",") != -1
                    //       ? title_name.replace(",", "%2C")
                    //       : title_name.indexOf(";") != -1
                    //         ? title_name.replace(";", "%3B")
                    //         : title_name.indexOf("-") != -1
                    //           ? title_name.replace("-", "%2D")
                    //           : title_name,
                  titleand_data: title_name.replace("%26", "&").replace("%2D", "-").replace("%27", "'").replace("%2C", ",").replace("%3B", ";")
                    // title_name.indexOf("%26") != -1
                    //   ? title_name.replace("%26", "&")
                    //   : title_name.indexOf("%2D") != -1
                    //     ? title_name.replace("%2D", "-")
                    //     : title_name.indexOf("%27") != -1
                    //       ? title_name.replace("%27", "'")
                    //       : title_name.indexOf("%2C") != -1
                    //         ? title_name.replace("%2C", ",")
                    //         : title_name.indexOf("%3B") != -1
                    //           ? title_name.replace("%3B", ";")
                    //           : title_name,
                })
              }
              renderMenu={(item) => (
                <div className="menu c-dropdown">{item}</div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.title}
                >
                  {item.title}
                </div>
              )}
            />
          </div>
          <div className="form-group autoc">
            <Autocomplete
              value={
                this.state.comapany_name.indexOf("%26") != -1
                  ? this.state.companyand_data
                  : this.state.comapany_name.indexOf("%27") != -1
                    ? this.state.companyand_data
                    : this.state.comapany_name.indexOf("%2C") != -1
                      ? this.state.companyand_data
                      : this.state.comapany_name.indexOf("%2D") != -1
                        ? this.state.companyand_data
                        : this.state.comapany_name.indexOf("%3B") != -1
                          ? this.state.companyand_data
                          : this.state.comapany_name
              }
              inputProps={{
                id: "company-autocomplete",
                placeholder: "Select Company",
              }}
              wrapperStyle={{
                position: "relative",
                display: "inline-block",
              }}
              onClick={() => {
                this.handleCompany("region");
              }}
              //applyCompanyFilters={this.handleFilter}
              items={this.state.company}
              shouldItemRender={matchCompany}
              getItemValue={(item) => item.name}
              onChange={(event, comapany_name) =>
                this.setState({ comapany_name })
              }
              onSelect={(comapany_name) =>
                this.setState({
                  comapany_name: comapany_name.replace("&", "%26").replace("-", "%2D").replace("'", "%27").replace(",", "%2C").replace(";", "%3B"),
                    // comapany_name.indexOf("&") != -1
                    //   ? comapany_name.replace("&", "%26")
                    //   : comapany_name.indexOf("-") != -1
                    //     ? comapany_name.replace("-", "%2D")
                    //     : comapany_name.indexOf("'") != -1
                    //       ? comapany_name.replace("'", "%27")
                    //       : comapany_name.indexOf(",") != -1
                    //         ? comapany_name.replace(",", "%2C")
                    //         : comapany_name.indexOf(";") != -1
                    //           ? comapany_name.replace(";", "%3B")
                    //           : comapany_name,
                  companyand_data: comapany_name.replace("%26", "&").replace("%2D", "-").replace("%27", "'").replace("%2C", ",").replace("%3B", ";")
                    // comapany_name.indexOf("%26") != -1
                    //   ? comapany_name.replace("%26", "&")
                    //   : comapany_name.indexOf("%2D") != -1
                    //     ? comapany_name.replace("%2D", "-")
                    //     : comapany_name.indexOf("%27") != -1
                    //       ? comapany_name.replace("%27", "'")
                    //       : comapany_name.indexOf("%2C") != -1
                    //         ? comapany_name.replace("%2C", ",")
                    //         : comapany_name.indexOf("%3B") != -1
                    //           ? comapany_name.replace("%3B", ";")
                    //           : comapany_name,
                })
              }
              renderMenu={(children) => (
                <div className="menu c-dropdown">{children}</div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.name}
                >
                  {item.name}
                </div>
              )}
            />
          </div>{" "}
          <div className="form-group autoc">
            <Autocomplete
              value={this.state.country_name}
              inputProps={{
                id: "country-autocomplete",
                placeholder: "Select Country",
              }}
              wrapperStyle={{
                position: "relative",
                display: "inline-block",
              }}
              items={this.state.country}
              getItemValue={(item) => item.country}
              shouldItemRender={matchCountry}
              onChange={(event, country_name) =>
                this.setState({ country_name })
              }
              onSelect={(country_name) => this.setState({ country_name })}
              renderMenu={(children) => (
                <div className="menu c-dropdown">{children}</div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.country}
                >
                  {item.country}
                </div>
              )}
            />
          </div>
          <div className="form-group autoc">
            <Autocomplete
              value={this.state.sector_name}
              inputProps={{
                id: "sector-autocomplete",
                placeholder: "Select Sector",
              }}
              wrapperStyle={{
                position: "relative",
                display: "inline-block",
              }}
              items={this.state.sector}
              getItemValue={(item) => item.sector}
              shouldItemRender={matchSector}
              onChange={(event, sector_name) => this.setState({ sector_name })}
              onSelect={(sector_name) => this.setState({ sector_name })}
              renderMenu={(children) => (
                <div className="menu c-dropdown">{children}</div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.sector}
                >
                  {item.sector}
                </div>
              )}
            />
          </div>
          <div className="form-group autoc">
            <Autocomplete
              value={
                this.state.category_name.indexOf("%26") != -1
                  ? this.state.categoryand_name
                  : this.state.category_name
              }
              inputProps={{
                id: "category-autocomplete",
                placeholder: "Select Category",
              }}
              wrapperStyle={{
                position: "relative",
                display: "inline-block",
              }}
              items={this.state.category}
              getItemValue={(item) => item.name}
              shouldItemRender={matchCompany}
              onChange={(event, category_name) =>
                this.setState({ category_name })
              }
              onSelect={(category_name) =>
                this.setState({
                  category_name:
                    category_name.indexOf("&") != -1
                      ? category_name.replace("&", "%26")
                      : category_name,
                  categoryand_name:
                    category_name.indexOf("%26") != -1
                      ? category_name.replace("%26", "&")
                      : category_name,
                })
              }
              renderMenu={(children) => (
                <div className="menu c-dropdown">{children}</div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.name}
                >
                  {item.name}
                </div>
              )}
            />
          </div>
          <Form.Group>
            <Form.Control
              as="select"
              onChange={(e) => {
                this.setState({ selected: e.target.value });
                this.handleLastDays(e.target.value);
              }}
            >
              <option value="">Select Date</option>
              <option value="3">Last 3 Days</option>
              <option value="7">Last 7 Days</option>
              <option value="30">Last 30 Days</option>
              <option value="custom_date">Select Custom Date</option>
            </Form.Control>
            {this.state.selected === "custom_date" && (
              <Form>
                <div className="d-flex justify-content-between date-range">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                  />
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleLastDate}
                  />
                </div>
              </Form>
            )}
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Link
              to={"/en/investment_wire/"}
              className="btn btn-blue"
              onClick={() => {
                this.handleClick("region");
              }}
            >
              Apply
            </Link>
            <Link
              to={"/en/investment_wire/"}
              className="btn btn-blue"
              onClick={this.refreshButton}
            >
              Reset
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Iws;
