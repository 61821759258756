import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import Autocomplete from "react-autocomplete";
import {
  Row,
  Table,
  Col,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
//import upload from "./upload.php";
import FundRaisingGmdSidebar from "../../component/global/FundRaising Sidebar/fund-raising-gmd_sidebar";
import $ from "jquery";
//import Autocomplete from "material-ui/AutoComplete";
//import { makeStyles } from "material-ui/styles";
import { Editor } from "@tinymce/tinymce-react";
//import window from "react-global";
import "../create-deal/create-deal.css";
import { Link } from "react-router-dom";

import {
  matchCompany,
  matchSector,
} from "../../component/global/investment-intellegence-screener/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Signin from "../../pages/signin/signinIndex";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
const animatedComponents = makeAnimated();

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});
class FundRaising_Create_Deal extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.onChangePage = this.onChangePage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.changeTermMultiSelect = this.changeTermMultiSelect.bind(this);
    this.changeTermMultiSelects = this.changeTermMultiSelects.bind(this);
    this.refreshButton = this.refreshButton.bind(this);

    this.state = {
      dealheadline: "",
      fundraisingstage: "",
      dealcodename: "",
      refresh: "",
      message: "",
      sub_type: [],
      prop_type: [],
      category: [],
      stage: [],
      //editorState: EditorState.createEmpty(),
      user: "",
      lease_type: [],
      itemcurrency: [],
      name_info: "",
      title: "",
      emailentry: "",
      email: "",
      contactnumber: "",
      youauthorizedcreatedealbehalfcompany: "",
      items: [],
      company: [],
      sector_data: "",
      result: false,
      company_details: [],
      country: [],
      sector: [],
      sector_name: "",
      filter: "",
      subIndustry: [],
      old_route: "",
      pageSize: 25,
      count: 0,
      file: "",
      fundname: "",
      funddomicile: "",
      fundcategory: "",
      fundtargetsize: "",
      fundtargetreturn: "",
      fundmanagername: "",
      fundmanagercountry: "",
      fundmanagerURL: "",
      dealbriefdescription: "",
      amounttoraise: "",
      fundgeographicfocus: "",
      fundlife: "",
      file_teaser: [],
      fileother: "",
      filepicture: "",
      file_other: [],
      reportedDate: new Date(),
      reported_date: "",
    };
    //this.onChange = (editorState) => this.setState({ editorState });
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  refreshButton = () => {
    this.setState({
      refresh: true,
    });
  };
  handleState = () => {
    this.setState({
      result: false,
    });
  };
  changeTerm(event) {
    const name = event.target.name;
    const { value, maxLength } = event.target;
    const message = value.slice(0, maxLength);
    this.setState({ [name]: event.target.value });
  }
  changeTermMultiSelect = (sector_name) => {
    const state = this.state;
    state.sector_name = [];
    sector_name.forEach((option) => {
      state.sector_name.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({
      sector_name: state.sector_name,
      sector_data: null,
    });
    console.log(sector_name);
  };
  changeTermMultiSelects = (companysubindustry) => {
    const state = this.state;
    state.companysubindustry = [];
    companysubindustry.forEach((option) => {
      state.companysubindustry.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({ companysubindustry: state.companysubindustry });
    console.log(companysubindustry);
  };
  handleEditorChange = (e) => {
    this.setState({
      dealbriefdescription: e.target.getContent(),
    });
    console.log("Content was updated:", this.state.dealbriefdescription);
  };
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      file_teaser: event.target.files.name,
      file_other: event.target.files.name,
    });
    console.log(len, "length");
    console.log("Selected file:", this.state.file);

    //.log("Selected file:", event.target.files[0]);
  }
  onSubmit(e) {
    e.preventDefault();
    const { dealheadline } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set("dealheadline", dealheadline);
    bodyFormData.set("dealcodename", this.state.dealcodename);
    bodyFormData.set("fundname", this.state.fundname);
    bodyFormData.set("funddomicile", this.state.funddomicile);
    bodyFormData.set("fundcategory", this.state.fundcategory);
    bodyFormData.set("fundraisingstage", this.state.fundraisingstage);
    bodyFormData.set("fundtargetsize", this.state.fundtargetsize);

    bodyFormData.set("fundtargetreturn", this.state.fundtargetreturn);
    bodyFormData.set("amounttoraise", this.state.amounttoraise);
    bodyFormData.set("fundmanagername", this.state.fundmanagername);
    bodyFormData.set("fundmanagercountry", this.state.fundmanagercountry);
    bodyFormData.set("fundmanagerURL", this.state.fundmanagerURL);
    bodyFormData.set("fundgeographicfocus", this.state.fundgeographicfocus);
    bodyFormData.set("fundlife", this.state.fundlife);
    bodyFormData.set("dealbriefdescription", this.state.dealbriefdescription);
    bodyFormData.set("name", this.state.name_info);
    bodyFormData.set("title", this.state.title);
    bodyFormData.set("email", this.state.emailentry);
    bodyFormData.set("contactnumber", this.state.contactnumber);
    bodyFormData.set(
      "youauthorizedcreatedealbehalfcompany",
      this.state.youauthorizedcreatedealbehalfcompany
    );
    bodyFormData.set("teaserresentation", this.state.file);
    bodyFormData.set("otherdocumentstoupload", this.state.fileother);
    bodyFormData.set("projectpicturesvideotoupload", this.state.filepicture);

    bodyFormData.set("posted_by", this.state.user);

    axios
      .post(
        "https://api.analyzemarkets.com/gdmfundraisingform/",
        bodyFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          this.setState({
            result: true,
          });
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };
  componentDidMount = () => {
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });
    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ sector: res.data.results });
      }
    });
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ company: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/fundcategory/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ category: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/fundraisingstage/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ stage: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/subfundcategory/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ sub_type: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/gdmleasetype/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ lease_type: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };

  render() {
    //const classes = useStyles();

    const { data } = this.state;
    const options = [
      { title: "Energy" },
      { title: "Material" },
      { title: "Industrail" },
      { title: "Type" },
    ];
    const sec = this.state.sector;
    const subIn = this.state.subIndustry;
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Navfilter />
              <Row>
                <Col className="w-30p col-sm-3 p-4">
                  <div className="widget">
                    <FundRaisingGmdSidebar />
                  </div>
                </Col>

                <Col className="w-80p p-4">
                  <div>
                    <div class="container">
                      <form onSubmit={this.onSubmit}>
                        <div className="iis greyborder2px">
                          <h4 className="text-left">Create a Deal</h4>
                          <div className="pdg15">
                            <fieldset>
                              <legend>
                                <b className="upercase">
                                  About Fundraising Deal{" "}
                                </b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="dealheadline"
                                    type="text"
                                    required
                                    placeholder="Deal Headline* (Max 100 characters)
                                  e.g An established private equity firm fundraising for its buyout fund"
                                    onChange={this.changeTerm}
                                    maxLength="100"
                                  />
                                </Col>
                              </Row>

                              <Row className="">
                                <Col>
                                  <Form.Control
                                    name="dealcodename"
                                    type="text"
                                    required
                                    placeholder="Deal Code Name *e.g. Project Sun"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    name="fundname"
                                    type="text"
                                    placeholder="Fund Name"
                                    onChange={this.changeTerm}
                                  />
                                </Form.Group>

                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    as="select"
                                    name="funddomicile"
                                    onChange={this.changeTerm}
                                    placeholder="Fund Domicile*"
                                    rows="3"
                                    required
                                  >
                                    <option value="" className="">
                                      Fund Domicile*{" "}
                                    </option>
                                    {this.state.country.map((item, i) => (
                                      <option key={i} value={item.country}>
                                        {item.country}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Row>

                              <Row className="">
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="fundcategory"
                                    onChange={this.changeTerm}
                                    placeholder="Fund Category*"
                                    rows="3"
                                    required
                                  >
                                    <option value="" className="">
                                      Fund Category*
                                    </option>
                                    {this.state.category.map((item, i) => (
                                      <option key={i} value={item.type}>
                                        {item.type}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    as="select"
                                    name="fundraisingstage"
                                    onChange={this.changeTerm}
                                    placeholder="Fundraising Stage*"
                                    rows="3"
                                    required
                                  >
                                    <option value="" className="">
                                      Fundraising Stage*
                                    </option>
                                    {this.state.stage.map((item, i) => (
                                      <option key={i} value={item.type}>
                                        {item.type}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Col>
                                  <Form.Control
                                    name="fundtargetsize"
                                    onChange={this.changeTerm}
                                    placeholder="Fund Target Size* (In USD million)"
                                  />{" "}
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="fundtargetreturn"
                                    type="text"
                                    onChange={this.changeTerm}
                                    placeholder="Fund Target Return (in %)"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="amounttoraise"
                                    type="text"
                                    onChange={this.changeTerm}
                                    placeholder="Amount to Raise* (in USD million)"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="fundmanagername"
                                    type="text"
                                    onChange={this.changeTerm}
                                    placeholder="Fund Manager Name*"
                                  />
                                </Col>
                              </Row>

                              <Row className="">
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="fundmanagercountry"
                                    onChange={this.changeTerm}
                                    placeholder="Fund Manager Country*"
                                    rows="3"
                                    required
                                  >
                                    <option value="" className="">
                                      Fund Manager Country*
                                    </option>
                                    {this.state.country.map((item, i) => (
                                      <option key={i} value={item.country}>
                                        {item.country}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    name="fundmanagerURL"
                                    onChange={this.changeTerm}
                                    placeholder="Fund Manager URL"
                                  />
                                </Form.Group>
                                <Col>
                                  <Form.Control
                                    name="fundlife"
                                    type="text"
                                    onChange={this.changeTerm}
                                    placeholder="Fund Life (in years)"
                                  />{" "}
                                </Col>
                              </Row>

                              <Row className="">
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    name="fundgeographicfocus"
                                    type="text"
                                    onChange={this.changeTerm}
                                    placeholder="Fund Geographic Focus* (write regions or countries)"
                                  />{" "}
                                </Form.Group>
                              </Row>

                              <div className="form-group">
                                <Row className="">
                                  <Col>
                                    <Editor
                                      initialValue=""
                                      apiKey="l7d3ab4ybjfthcy2c4ya6vsj9rlub27d3sdqk433oyis6ild"
                                      init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                          "advlist autolink lists link image",
                                          "charmap print preview anchor help",
                                          "searchreplace visualblocks code",
                                          "insertdatetime media table paste wordcount",
                                        ],
                                        toolbar:
                                          "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help",
                                      }}
                                      onChange={this.handleEditorChange}
                                    />

                                    {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                  as="textarea"
                                  name="companydealbriefdescription"
                                  rows="7"
                                  maxLength="100"
                                  onChange={this.changeTerm}
                                  placeholder="Company/Deal Brief Description* (Maximum 100 words)"
                                />
                              </Form.Group> */}
                                  </Col>{" "}
                                </Row>
                              </div>
                              <legend>
                                <b className="upercase">
                                  Requirement Documents
                                </b>
                              </legend>
                              <div className="form-group">
                                <label> Teaser/Presentation</label>

                                <Row>
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="file"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                </Row>
                              </div>
                              <div className="form-group">
                                <label> Other documents to upload:</label>

                                <Row>
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="fileother"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                </Row>
                              </div>
                              <div className="form-group">
                                <label>
                                  {" "}
                                  Project Pictures/Video to upload:
                                </label>

                                <Row>
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="filepicture"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                </Row>
                              </div>

                              <legend>
                                <b className="upercase">
                                  Contact Information For Potential Investors
                                </b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="name_info"
                                    type="text"
                                    placeholder="Name*"
                                    required
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="title"
                                    type="text"
                                    placeholder="Title*"
                                    required
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    name="emailentry"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                    required
                                    onChange={this.changeTerm}
                                  />
                                </Col>

                                <Col>
                                  <Form.Control
                                    name="contactnumber"
                                    type="tel"
                                    required
                                    placeholder="Contact number*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <label>
                                    Are you authorized to create this deal on
                                    behalf of the Company*:{" "}
                                  </label>
                                  <Form.Control
                                    as="select"
                                    name="youauthorizedcreatedealbehalfcompany"
                                    required
                                    onChange={this.changeTerm}
                                    // placeholder="Are you Authorized to create this deal"
                                  >
                                    <option value="">Select*</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col sm={3}>
                                  <Button
                                    variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                    type="submit"
                                    size="sm"
                                  >
                                    Publish Deal
                                  </Button>
                                </Col>
                                <Col>
                                  <Link
                                    className="btn btn-blue mt-4 mb-4 shadow"
                                    onClick={this.refreshButton}
                                  >
                                    Clear
                                  </Link>
                                </Col>
                              </Row>
                              {this.state.refresh && window.location.reload()}

                              {this.state.result && (
                                <div className="borderColors1">
                                  <span
                                    className="buttonFloat"
                                    onClick={this.handleState}
                                  >
                                    <i className="fa fa-times-circle"></i>
                                  </span>
                                  <span>
                                    <i
                                      class="fa fa-check-circle"
                                      style={{ color: "green" }}
                                    ></i>
                                  </span>
                                  &nbsp;
                                  <span>
                                    <h4>
                                      {" "}
                                      <b className="colorError">
                                        Your Deal is Successfully Submitted
                                      </b>
                                    </h4>
                                    <br></br>
                                  </span>
                                </div>
                              )}
                            </fieldset>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default FundRaising_Create_Deal;
