import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap/";
import { Link } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import Popup from "reactjs-popup";
import { setupCache } from "axios-cache-adapter";
import { Row, Table, Col, Container, Modal } from "react-bootstrap";
import srcicon from "../../../assets/images/search.svg";
import fernet from "fernet";

import {
  matchCountry,
  matchCompany,
  matchSector,
  matchTitle,
} from "../investment-intellegence-screener/data";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class passwordHash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      email: "",
      response: "",
      token: "",
    };
  }
  handleauth = (id) => {
    axios
      .get("https://api.analyzemarkets.com/signinnew/?id=" + id)
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ login: res.data.data });

        var email = this.state.login.map((number) => {
          return number.email;
        });
        var token = this.state.login.map((number) => {
          return number.token;
        });

        const emailflag = email.pop();
        const tokendata = token.pop();
        this.props.applyFilterdata(emailflag, tokendata);
      })
      .catch((error) => {
        //on error
        console.log(error);
      });
  };
  componentDidMount = () => {
    const id = localStorage.getItem("id");

    this.setState({
      id,
    });
    this.handleauth(id);
    // this.props.applyFilters("email=" + email + "&token=" + token);
  };

  render() {
    return <div className=""></div>;
  }
}

export default passwordHash;
