import React, { Component, Fragment } from "react";
import "./subscribe.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Form, Button } from "react-bootstrap/";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";
import mastercard from "../../assets/images/mastercard.svg";
import visa from "../../assets/images/visa.svg";
import amex from "../../assets/images/american-express.svg";
import diners from "../../assets/images/diners-club.svg";
import discover from "../../assets/images/discover.svg";


// import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, ElementsConsumer, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51IxVTKKgy0ncVZZZCLcoh66vtocjaGPELULYfxw2r3FWU6F4DO4KkE95haiNxythyJJGoThY8tILJxp4zOz5pzBK00eMCu0g1m');
// const stripePromise = loadStripe('pk_test_51IxVTKKgy0ncVZZZ5KCccBO0ajyI4g2NppufLNCptqOZToMpxvuUwnyGUlL6vA6Uy4H26gCy9S7To0jT2G5hj7YH00JTZFZZmw');
// const secretKey = "sk_test_51IutnrSG3EKNPf46Sgw6K1Q94zo3KWCrpDf7WjcZNS3KvkIS9rAfwnIdktAGu0F3JksHNOjAOgwfQmrBfXmcwLNE0070Xzbssl"
// const stripe = useStripe();
// const elements = useElements();

const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontSize: "16px",
            "::placeholder": {
                color: "#495057"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};

// Create `axios-cache-adapter` instance
const cache = setupCache({
    maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
    adapter: cache.adapter,
});

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            succeeded: false,
            error: null,
            processing: '',
            disabled: true,
            clientSecret: '',
            purchaseDisabled: true,
        }
    }

    handleSubmit = async ev => {
        ev.preventDefault();
        console.log('handle submit')
        const { stripe, elements, onSubmit } = this.props;
        if (!stripe || !elements) {
            return;
        }

        this.setState({
            processing: true
        })
        const cardElement = elements.getElement(CardElement);
        const result = await stripe.createToken(cardElement);
        if (result.error) {
            console.log(result.error.message);
            this.setState({
                error: result.error.message,
                processing: false
            })
        } else {
            console.log(result.token);
            sessionStorage.setItem('token', result.token['id'])
            onSubmit(ev);
            this.setState({
                error: null,
                processing: false,
                succeeded: true
            })
        }
        // this.onsubmit.bind(this);
        // const payload = await stripe.confirmCardPayment(secretKey, {
        //   payment_method: {
        //     card: elements.getElement(CardElement)
        //   }
        // });
        // if (payload.error) {
        //   this.state.error = `Payment failed ${payload.error.message}`
        //   this.state.processing = false;
        // } else {
        //   this.state.error = null
        //   this.state.processing = false;
        //   this.state.succeeded = true;
        // }
    };

    handleChange = async (event) => {
        console.log('handle change')
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        this.setState({
            disabled: event.empty,
            error: event.error ? event.error.message : ""
        })
        this.state.disabled = event.empty
        this.state.error = event.error ? event.error.message : ""
    };

    render() {
        return (
            <div className="d-flex flex-column align-items-center">
                <CardElement id="card-element" options={cardStyle} onChange={this.handleChange} />
                <div className="mb-3 font-14">Note: Your subscription will automatically renew unless you cancel.
                You can cancel anytime before your renewal date by contacting customer support at support@analyzemarkets.com.
                </div>
                <button className="btn purchase-subscription-button" disabled={this.state.processing || this.state.disabled || this.state.succeeded} onClick={this.handleSubmit} >
                    <span>
                        {this.state.processing
                            ? ("Processing Payment")
                            : ("Purchase Subscription")
                        }
                    </span>
                </button>
                {/* Show any error that happens when processing the payment */}
                {
                    this.state.error && (
                        <div role="alert">
                            {this.state.error}
                        </div>
                    )
                }
            </div >
        );
    }
}

class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            firstname: null,
            lastname: null,
            company_name: null,
            company_url: null,
            job_title: null,
            country: null,
            contact_number: null,
            free_trial_demonstration: null,
            offers_and_promotions: null,
            result: "",
            is_email: true,
            is_firstname: true,
            is_lastname: true,
            is_company_name: true,
            is_company_url: true,
            is_job_title: true,
            is_country: true,
            is_contact_number: true,
            country_list: [],
            error: "",
            plan: "",
            planName: "",
            succeeded: false,
            error: null,
            processing: '',
            disabled: true,
            clientSecret: '',
            freeTrial: false,
        };
    }

    onChange(e) {
        console.log(e.target.name);
        if (e.target.name === "email") {
            if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(e.target.value)) {
                console.log(47);
                this.setState({
                    is_email: false,
                });
            } else {
                this.setState({
                    is_email: true,
                });
            }
        }

        if (e.target.name === "company_url") {
            if (
                !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
                    e.target.value
                )
            ) {
                console.log("Url Is not valida");
                this.setState({
                    is_company_url: false,
                });
            } else {
                this.setState({
                    is_company_url: true,
                });
            }
        }

        if (e.target.name === "contact_number") {
            if (!/^\+\d{1,4}-[1-9]\d{0,9}$/.test(e.target.value)) {
                this.setState({
                    is_contact_number: false,
                });
            } else {
                this.setState({
                    is_contact_number: true,
                });
            }
        }
        // if(e.target.value.match(/^[w-.+]+@[a-zA-Z0-9.-]+.[a-zA-z0-9]{2,4}$/)){
        //     console.log("In valid");
        // }
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    onSubmit(e) {
        e.preventDefault();
        // this.handleSubmit.bind(this)
        console.log(
            /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/.test(
                this.state.email
            ),
            this.state.email
        );
        var bodyFormData = new FormData();

        if (
            /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/.test(
                this.state.email
            ) === false
        ) {
            this.setState({
                error: "Invalid Email Address",
            });
            return 0;
        }

        this.setState({
            error: "",
        });

        var tokenData = sessionStorage.getItem('token');
        console.log(tokenData)

        bodyFormData.set("email", this.state.email);
        bodyFormData.set("firstname", this.state.firstname);
        bodyFormData.set("lastname", this.state.lastname);
        bodyFormData.set("company_name", this.state.company_name);
        bodyFormData.set("country", this.state.country);
        bodyFormData.set("company_url", this.state.company_url);
        bodyFormData.set("job_title", this.state.job_title);
        bodyFormData.set("contact_number", this.state.contact_number);
        bodyFormData.set("offers_and_promotions", this.state.offers_and_promotions);
        bodyFormData.set("plan", this.state.plan);
        bodyFormData.set("tokenId", tokenData);
        // bodyFormData.set("tokenId", "tok_au");
        // bodyFormData.set("planCost", this.state.planCost);
        if (this.state.freeTrial == true) {
            bodyFormData.set("free_trial_demonstration", true);
        } else {
            bodyFormData.set("free_trial_demonstration", false);
        }

        axios
            .post("https://api.analyzemarkets.com/subscriber_form/", bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                console.log(res);

                if (res.status === 200 && res.data.result == 'Your subscriber form is submitted') {
                    console.log('11111111')
                    this.setState({
                        result:
                            `Thank you for subscribing. Your payment has been successfully processed. You will receive your account login credentials shortly. For any queries, please email us at support@analyzemarkets.com.`,
                    });
                } else if (res.status === 200 && res.data.result == 'Something went wrong please try again later') {
                    console.log('22222222')
                    this.setState({
                        result:
                            "Something went wrong please try again later.",
                    });
                } else if (res.status === 200 && res.data.result == 'payment fail') {
                    console.log('33333333')
                    this.setState({
                        result:
                            "Payment failed.",
                    });
                } else if (res.status === 200 && res.data.result == 'Request with this email already exists') {
                    console.log('44444444')
                    this.setState({
                        result:
                            "Request with this email already exists.",
                    });
                }
            })
            .catch((error) => {
                //on error
                // alert("There is an error in API call.");
            });
    }
    handleDropdownChange(e) {
        this.setState({
            country: e.target.value,
        });
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        const plan = sessionStorage.getItem("planId");
        const planCost = sessionStorage.getItem("planCost");
        const planName = sessionStorage.getItem("plan");
        this.setState({ plan: plan })
        this.setState({ planCost: planCost })
        this.setState({ planName: planName })
        const freeTrial = JSON.parse(sessionStorage.getItem("freeTrial"));
        if (freeTrial == true) {
            window.scrollTo(0, 500);
        }
        this.setState({ freeTrial: freeTrial })
        sessionStorage.removeItem("freeTrial")

        api({
            url: "https://api.analyzemarkets.com/country/",
            method: "get",
        }).then(async (res) => {
            console.log(res);
            if (res.status === 200) {
                this.setState({ country_list: res.data.results });
            }
        });
        const rememberMe = localStorage.getItem("rememberMe") === "true";
        const email = localStorage.getItem("email");
        const response = localStorage.getItem("response");
        const user = localStorage.getItem("email");
        const token = localStorage.getItem("token");
        const action = localStorage.getItem("action");
        this.setState({ email, rememberMe, response, token, action });
    };
    render() {
        return (
            <Fragment>
                <Header />
                <Container>
                    <Helmet>
                        <title>AnalyzeMarkets Subscription</title>
                        <meta
                            name="description"
                            content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
                        />
                        <meta property="og:title" content="AnalyzeMarkets Subscription" />
                        <meta
                            property="og:image"
                            content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                        />
                        <meta
                            name="og:description"
                            content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
                        />
                        <meta name="og:url" content="https://www.analyzemarkets.com" />
                        <meta name="og:site_name" content="AnalyzeMarkets" />
                        <meta name="og:locale" content="en" />
                        <meta name="og:type" content="website" />

                        <meta
                            name="image"
                            content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                        />
                        <meta itemprop="name" content="AnalyzeMarkets Subscription" />

                        <meta
                            itemprop="description"
                            content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
                        />
                        <meta
                            itemprop="image"
                            content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                        />

                        <meta name="twitter:card" content="summary_large_image" />

                        <meta name="twitter:title" content="AnalyzeMarkets Subscription" />

                        <meta
                            name="twitter:description"
                            content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
                        />

                        <meta name="twitter:site" content="@AnalyzeMarkets" />
                        <meta name="twitter:creator" content="@AnalyzeMarkets" />
                        <meta
                            name="twitter:image"
                            content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                        />
                    </Helmet>
                    <Row>
                        <Col md={12} lg={6} sm={12}>
                            {this.state.result === ""
                                ? (
                                    <div className="row">
                                        <div className="col-12 mt-3">
                                            <h5 className="pb-4 font-weight-normal">
                                                Please provide the below requested information as part of the subscription process.
                                            </h5>
                                            {/* <p><span className="text-danger">* </span>marked fields are mandatory</p> */}
                                            <Form onSubmit={this.onSubmit.bind(this)}>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="FirstName">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="First Name*"
                                                            name="firstname"
                                                            onChange={this.onChange.bind(this)}
                                                            required
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="lastName">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Last Name*"
                                                            name="lastname"
                                                            onChange={this.onChange.bind(this)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Group controlId="ComapanyName">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Company Name"
                                                        name="company_name"
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                </Form.Group>

                                                <Form.Group controlId="CompanyURL">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Company URL"
                                                        name="company_url"
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                </Form.Group>

                                                <Form.Group controlId="JobTitle">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Job Title"
                                                        name="job_title"
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                </Form.Group>

                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="ChooseCountry">
                                                        <Form.Control
                                                            as="select"
                                                            onChange={(e) => this.handleDropdownChange(e)}
                                                            required
                                                        >
                                                            <option value="">Select Country*</option>
                                                            {this.state.country_list.map((item, i) => (
                                                                <option key={i} value={item.country}>
                                                                    {item.country}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Group controlId="WorkEmail">
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Email*"
                                                        name="email"
                                                        onChange={this.onChange.bind(this)}
                                                        required
                                                    />
                                                </Form.Group>

                                                <Form.Group controlId="ContactNumber">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Contact Number with Country Code"
                                                        name="contact_number"
                                                        onChange={this.onChange.bind(this)}
                                                    />

                                                    <div className="capchaCode mt-3">
                                                        <div
                                                            className="g-recaptcha"
                                                            data-sitekey="6LcjIsQUAAAAAPYjdLFm1hP-EgdGAJFQrUxmRGkc"
                                                        ></div>
                                                    </div>

                                                </Form.Group>

                                                <Form.Group id="formGridCheckbox">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="AnalyzeMarkets can send me subscription-related offers and promotions."
                                                        name="offers_and_promotions"
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                </Form.Group>

                                                <p className="mb-4 mt-2" style={{ fontSize: "12px" }}>
                                                    By submitting the above required information you agree to our &nbsp;
                                                    <Link to="/en/terms-of-use">Terms of Use</Link>{" and "}
                                                    <Link to="/en/privacy-policy">Privacy Policy</Link>,&nbsp;
                                                </p>
                                                <div>
                                                    <div className="mt-4"><h5 className="font-weight-normal">Availabe Payment Methods: </h5></div>
                                                    <div className="d-flex">
                                                        <img src={visa} className="img-fluid" alt="" width="60" />
                                                        <img src={mastercard} className="img-fluid" alt="" width="60" style={{ marginLeft: "10px" }} />
                                                        <img src={amex} className="img-fluid" alt="" width="68" style={{ marginLeft: "5px" }} />
                                                        <img src={discover} className="img-fluid" alt="" width="70" />
                                                        <img src={diners} className="img-fluid" alt="" width="60" style={{ marginLeft: "8px" }} />
                                                    </div>
                                                </div>
                                                <Elements stripe={stripePromise}>
                                                    <ElementsConsumer>
                                                        {({ stripe, elements }) => (
                                                            <CheckoutForm stripe={stripe} elements={elements} onSubmit={this.onSubmit.bind(this)} />
                                                        )}
                                                    </ElementsConsumer>
                                                </Elements>
                                                {this.state.error !== "" && (
                                                    <h5 className="pb-4 font-weight-normal">
                                                        {this.state.error}
                                                    </h5>
                                                )}
                                                <div className="text-center mb-5">
                                                    {/* <Button variant="blue btn-lg" type="submit">
                                                        Submit
                                                    </Button> */}
                                                    {/* <button className="purchase-subscription-button" onClick={this.onSubmit} >
                                                        Purchase Subscription
                                                    </button> */}
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                )
                                : <div></div>
                            }
                            {this.state.result !== "" && (
                                <div>
                                    <h5 className="pb-4 font-weight-normal">{this.state.result}</h5>
                                </div>
                            )}
                        </Col>
                        <Col md={12} lg={5} sm={12} style={{ marginLeft: "auto" }}>
                            <div className="bdrpdg" >
                                <div className="d-flex justify-content-between">
                                    <h5 className="pb-4 font-weight-normal">Your Order</h5>
                                    <a href="/en/subscribe_us">Change</a>
                                </div>
                                <div>
                                    <span>Thank you for choosing AnalyzeMarkets' Global Deals Intelligence Platform subscription.</span>
                                </div>
                                <div className="mt-3">
                                    {
                                        this.state.planName == "Monthly"
                                            ? (
                                                <div className="d-flex justify-content-between">
                                                    <b>Monthly Subscription</b>
                                                    <b>Total USD 199 per month</b>
                                                </div>
                                            )
                                            : this.state.planName == "Yearly"
                                                ? (
                                                    <div className="d-flex justify-content-between">
                                                        <b>Yearly Subscription</b>
                                                        <b>Total USD 1,999 per year</b>
                                                    </div>
                                                )
                                                : <div></div>
                                    }
                                </div>
                            </div>
                        </Col>
                        {/* <Col md={12} lg={6} sm={12}>
                            <Elements stripe={stripePromise}>
                                <ElementsConsumer>
                                    {({ stripe, elements }) => (
                                        <CheckoutForm stripe={stripe} elements={elements} />
                                    )}
                                </ElementsConsumer>
                            </Elements>
                        </Col> */}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Subscribe;
