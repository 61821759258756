import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import capchaimg from "../../assets/images/captcha-img.jpg";
import { Container, Row, Col, Form, Button } from "react-bootstrap/";
import { Helmet } from "react-helmet";
import axios from "axios";
import OtpInput from "react-otp-input";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import "./confirmpassword.css";
class ConfirmPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      checkemail: "",
      password: "",
      otp: "",
      confirm_password: "",
      result: false,
      hasErrors: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleState = this.handleState.bind(this);
  }
  handleState = () => {
    this.setState({
      result: false,
    });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const checkemail = localStorage.getItem("email_info");
    this.setState({ checkemail });
  };
  handleChange = (otp) => {
    console.log('otp  -- ', otp)
    const onlyNumbers = /^[0-9\b]+$/;
    if (otp == '' || onlyNumbers.test(otp)) {
      this.setState({ otp })
    }
  };
  onChange(e) {
    console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleConfirmPassword = (event) => {
    if (event.target.value !== this.state.password) {
      this.setState({ hasErrors: "Password and Confirm Password dont match" });
      this.setState({ confirm_password: event.target.value });
    } else {
      this.setState({ hasErrors: "" });

      this.setState({ confirm_password: event.target.value });
    }
  };

  onSubmit(e) {
    e.preventDefault();
    const {
      checkemail,
      otp,
      password,
      confirm_password,
      hasErrors,
    } = this.state;

    var bodyFormData = new FormData();
    bodyFormData.set("checkemail", checkemail);
    bodyFormData.set("otp", otp);
    bodyFormData.set("password", password);
    if (password === confirm_password) {
      bodyFormData.set("confirmpassword", confirm_password);
      axios
        .post("https://api.analyzemarkets.com/forgetpassword/", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res.data, "Data");
          if (res.status === 200) {
            if (res.data === "success") {
              this.setState({
                result: true,
              });
            }
          } else {
            this.setState({});
          }
        })
        .catch((error) => {
          //on error
          alert(error);
        });
    } else {
      alert(hasErrors);
      this.setState({ hasErrors: "Password and Confirm Password dont match" });
    }
  }

  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>
              AnalyzeMarkets - Global Investment Intelligence Platform
            </title>
            <meta
              property="og:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              itemprop="name"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              itemprop="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta
              name="twitter:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              name="twitter:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row className="justify-content-center">
            <Col xl={7} md={12}>
              <div className="registration-form-container">
                <div className="registration-form">
                  <h2 className="pb-4">Forgot Password</h2>
                  <h3 className="text-center pb-5">
                    Please enter your otp & Confirm Your Password
                  </h3>
                  <form onSubmit={this.onSubmit}>
                    <label>Enter OTP</label>
                    <Row className="form-group">
                      <Col>
                        <OtpInput
                          onChange={this.handleChange}
                          value={this.state.otp}
                          inputStyle="InputContainer"
                          numInputs={6}
                          separator={<span>-</span>}
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <Col>
                        <Form.Control
                          required
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          required
                          type="password"
                          id="confirm_password"
                          name="confirm_password"
                          placeholder="Confirm Password"
                          value={this.state.confirm_password}
                          onChange={this.handleConfirmPassword}
                        />
                        <span className="redColor">{this.state.hasErrors}</span>
                      </Col>
                    </Row>
                    <Button
                      variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                      type="submit"
                      size="lg"
                    >
                      Submit
                    </Button>
                    {this.state.result && (
                      <div className="borderColors1 p-3" style={{ width: "500px" }}>
                        <span onClick={this.handleState}>
                          <i className="fa fa-times-circle"></i>
                        </span>
                        {/* <span>
                          <i
                            class="fa fa-check-circle"
                            style={{ color: "green", float: "right" }}
                          ></i>
                        </span> */}
                        <span className="mt-2">
                          <h5 className="text-center">
                            <b className="colorError font15">
                              Password has been changed succesfully
                            </b>
                          </h5>
                        </span>
                        <div className="w-100 d-flex justify-content-center mt-2">
                          <a href="/en/signin" className="btn btn-blue subscribe-btn">Okay</a>
                        </div>
                        <br />
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default ConfirmPassword;
