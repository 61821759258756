import React, { Component, Fragment } from "react";
import { render } from "react-dom";

import matchSorter from "match-sorter";
import "../v&c_dealmonitor/v&c_dealmonitor.css";
import { Row, Table, Col, Container } from "react-bootstrap";
import MA_SideBar from "../../component/global/vc_deals_sidebar/vc_deals_sidebar";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import Pagination from "../../component/global/pagination/index";
import "../../pages/press-release/press-release.css";
import Fetch from "../../component/global/CommonComponent/useFetch";
import Signin from "../../pages/signin/signinIndex";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import { Link } from "react-router-dom";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class VC_Startup_Seeking_Buyers extends Component {
  constructor(props) {
    super(props);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }
  state = {
    items: [],
    show_100: false,
    exampleItems: [],
    company: [],
    page: 0,
    current: 0,
    country: [],
    filter: "",
    filterdata: "",
    old_route: "",
    pageSize: 25,
    count: 0,
    is_first: true,
  };
  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.getData(this.state.old_route, filterdata, 0, true);
  };

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      // this.getData("", this.state.filter, page, false);
      if (this.state.filterdata !== "") {
        this.getData("", this.state.filterdata, page, false);
      }

      console.log(55);
    }
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });

    if (this.state.filterdata !== "") {
      //this.getData1(this.state.filterdata);
      this.getData(current_route, this.state.filterdata, 0, false);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  getData = (route, filterdata, page, is_first) => {
    api({
      url:
        "https://api.analyzemarkets.com/gdd_vc_ssb/?name=" +
        route +
        "&start=" +
        page +
        filterdata,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        items: res.data.result,
        page: res.data.count,
        current: page,
        is_first: false,
      });
      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
      }
    });
  };

  render() {
    const { data } = this.state;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header />

            <Container>
              <Fetch applyFilters={this.handleFilters}></Fetch>
              <Navfilter />
              <Row>
                <Col className="w-30p p-4">
                  <div className="widget">
                    <MA_SideBar />
                  </div>
                </Col>

                <Col className="w-70p p-4">
                  <div>
                    <div class="container">
                      <div>
                        <Table
                          striped
                          bordered
                          hover
                          id="table-id"
                          className="tblnoline grid"
                          size="sm"
                          responsive="lg"
                        >
                          <thead>
                            <tr>
                              <th className="table-header">Opportunity</th>
                              <th index="1">
                                <span className="table-header">
                                  Announced Date
                                </span>
                              </th>
                              <th index="2">
                                <span className="table-header"> Company</span>
                              </th>
                              <th index="2">
                                <span className="table-header"> Country</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.items.map((item, key) => (
                              <tr>
                                {" "}
                                <td className="word-wrap">
                                  {item.api_url != null ? (
                                    <Link
                                      to={
                                        "/en/seeking_buyer_detail/" +
                                        item.api_url +
                                        "/"
                                      }
                                    >
                                      {item.deal}
                                    </Link>
                                  ) : (
                                    <Link>
                                      <u className="underline">{item.deal}</u>
                                    </Link>
                                  )}
                                </td>
                                <td>{item.reported_date}</td>
                                <td>{item.company.name}</td>
                                <td>{item.company.country}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                      <div className="products-view__options justify-content-between mt-4">
                        {/* {this.state.count > 10 && (
                    <div className="view-options__control">
                      <label>Results Per Page</label>
                      <div></div>
                    </div>
                  )} */}
                        <Pagination
                          items={this.state.exampleItems}
                          onChangePage={this.onChangePage}
                          pageSize={this.state.pageSize}
                        ></Pagination>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default VC_Startup_Seeking_Buyers;
