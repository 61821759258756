import React, { Component } from "react";
import { Link, BrowserRouter, NavLink } from "react-router-dom";
import "./navfilterafterLogin.css";
import Fontawesome from "react-fontawesome";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Dropdown } from "react-bootstrap";
import { Redirect } from "react-router";

import {
  Button,
  Nav,
  Navbar,
  NavDropdown,
  MenuItem,
  NavItem,
} from "react-bootstrap";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class NavfilterAfterLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: "",
      animate: false,
      region: [1, 2, 3, 4, 5],
      category: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      regionActive: null,
      listOpen: false,
      headerTitle: this.props.title,
      feature: [
        {
          id: 0,
          title: "Home",
          link: "/en/investment_wire/",
          selected: false,
          key: "feature",
        },
        {
          id: 1,
          title: "Global Deals Intelligence",
          link: "/en/m_a_deals_monitor/",
          selected: false,
          key: "feature",
        },
        {
          id: 2,
          title: "Global Company Database",
          link: "/en/global-company-database/",
          selected: false,
          key: "feature",
        },
        {
          id: 3,
          title: "Global Deals MarketPlace",
          link: "",
          selected: false,
          key: "feature",
        },
        {
          id: 4,
          title: "Global Investors Database",
          selected: false,
          key: "feature",
        },
        {
          id: 5,
          title: "Global Research Report Database",
          selected: false,
          key: "feature",
        },
        // {
        //   id: 6,
        //   title: "Global Professional Database",
        //   link: "/en/gross-professional-database/",
        //   selected: false,
        //   key: "feature",
        // },
        // {
        //   id: 7,
        //   title: "ON-Demand Research And Analysis",
        //   link: "/en/on-demand_research/",
        //   selected: false,
        //   key: "feature",
        // },
        {
          id: 8,
          title: "Manage Alerts",
          link: "/en/manage_alert/",
          selected: false,
          key: "feature",
        },
      ],

      feature_subcategory: [
        {
          id: 0,
          title: "M & A Deals",
          link: "/en/m_a_deals_monitor/",
          selected: false,
          related_id: "Global Deals Intelligence",
          key: "subfeature",
        },
        {
          id: 1,
          title: "Venture Capital Deals",
          link: "/en/vc-deals/",
          selected: false,
          related_id: "Global Deals Intelligence",
          key: "subfeature",
        },
        {
          id: 2,
          title: "Real Estate Deals",
          link: "/en/real-estate-deals/",
          selected: false,
          related_id: "Global Deals Intelligence",
          key: "subfeature",
        },
        // {
        //   id: 3,
        //   title: "Join Venture Deals",
        //   link: "/en/jv-deals/",
        //   selected: false,
        //   related_id: "Global Deals Intelligence",
        //   key: "subfeature",
        // },
        // {
        //   id: 4,
        //   title: "Ipo Monitor",
        //   link: "/en/ipo-monitor/",
        //   selected: false,
        //   related_id: "Global Deals Intelligence",
        //   key: "subfeature",
        // },
        // {
        //   id: 5,
        //   title: "Debt Deals",
        //   link: "/en/debt-deals/",
        //   selected: false,
        //   related_id: "Global Deals Intelligence",
        //   key: "subfeature",
        // },
        {
          id: 6,
          title: "FundRaising Deals",
          link: "/en/fund-raising-deals/",
          selected: false,
          related_id: "Global Deals Intelligence",
          key: "subfeature",
        },
        {
          id: 7,
          title: "M & A Deals",
          link: "/en/seeking-buyers-monitor/",
          selected: false,
          related_id: "Global Deals MarketPlace",
          key: "subfeature",
        },
        {
          id: 8,
          title: "Real Estate Deals",
          link: "/en/real-estate-monitor/",
          selected: false,
          related_id: "Global Deals MarketPlace",
          key: "subfeature",
        },
        {
          id: 9,
          title: "Debt Deals",
          link: "/en/debt-deals-gmd-monitor/",
          selected: false,
          related_id: "Global Deals MarketPlace",
          key: "subfeature",
        },
        {
          id: 10,
          title: "Startup/Venture Capital Deals",
          link: "/en/vc-deals-gmd-monitor/",
          selected: false,
          related_id: "Global Deals MarketPlace",
          key: "subfeature",
        },

        {
          id: 11,
          title: "FundRaising Deals",
          link: "/en/fundraising_monitor/",
          selected: false,
          related_id: "Global Deals MarketPlace",
          key: "subfeature",
        },
        {
          id: 12,
          title: "Joint Venture Deals",
          link: "/en/joint-venture-gmd-monitor/",
          selected: false,
          related_id: "Global Deals MarketPlace",
          key: "subfeature",
        },
        {
          id: 6,
          title: "Global Professional Database",
          link: "/en/gross-professional-database/",
          selected: false,
          key: "feature",
        },
        // {
        //   id: 6,
        //   title: "ON-Demand Research And Analysis",
        //   link: "/en/on-demand_research/",
        //   selected: false,
        //   key: "feature",
        // },
      ],

      sectorActive: null,
      is_first: true,
      firstmenu: "",
      submenu: "",
      is_second: true,
      is_third: false,
      token: "",
      is_fourth: false,
      menu: false,
      url: "",
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleActive = this.handleActive.bind(this);
    this.sectorActive = this.sectorActive.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleToggle1 = this.handleToggle1.bind(this);
    this.handleToggle2 = this.handleToggle2.bind(this);
    this.handleToggle3 = this.handleToggle3.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
  }
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }

  handleClickOutside() {
    this.setState({
      listOpen: false,
    });
  }
  handleActive(e) {
    this.setState((prevState) => {
      return { animate: !prevState.animate };
    });
  }
  toggleList() {
    this.setState((prevState) => ({
      listOpen: !prevState.listOpen,
    }));
  }
  handleClick = (item) => {
    console.log(item, 22);
    this.setState({ regionActive: "region" + item.id });
  };
  handleToggle2 = () => {
    this.setState({
      is_fourth: !this.state.is_fourth,
      is_second: false,
      is_third: false,
    });
  };
  handleToggle3 = () => {
    this.setState({
      is_fourth: false,
      is_second: !this.state.is_second,
      is_third: false,
    });
  };
  handleToggle1 = () => {
    this.setState({
      is_third: !this.state.is_third,
      menu: false,
      is_fourth: false,
      is_second: false,
    });
  };

  handleToggle = () => {
    this.setState({
      is_second: !this.state.is_second,
      menu: true,
      is_fourth: false,
      is_third: false,
    });
  };
  sectorActive = (id) => {
    console.log(id, 27);
    this.setState({ sectorActive: "category" + id });
  };

  componentDidMount = () => {
    var subscriptionFeature = localStorage.getItem('subscription_feature')
    console.log(subscriptionFeature);

    this.setState({
      subscription_feature: subscriptionFeature
    })
    console.log(this.state.subscription_feature);

    api({
      url: "https://api.analyzemarkets.com/navfilter/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log("Request response Nav Filter:", res);
      this.setState({
        category: res.data.category,
        region: res.data.region,
        is_first: false,
      });
      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");

    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, user, action });
  };

  handleDropdownChange(type, e) {
    console.log(JSON.parse(e.target.value), type);
    var data = JSON.parse(e.target.value);
    console.log(data);
    this.props.greetChild(data, type);
  }
  render() {
    var greetChild = this.props.greetChild;
    let animationClasses = this.state.animate ? " active" : "";

    const { list } = this.props;
    const { listOpen, headerTitle } = this.state;
    return (
      <div className=" mt-1 mb-5 ">
        <div className="fn-nav d-block d-md-none sector-mobile">
          <select
            className="browser-default custom-select"
            id="dropdown"
            name="firstmenu"
            onChange={this.changeTerm}
          >
            {this.state.feature.map((item, i) => (
              <option key={i} value={item.title}>
                {item.title}
              </option>
            ))}
          </select>
        </div>

        <div className="fn-nav d-block d-md-none sector-mobile">
          <select
            className="browser-default custom-select"
            id="dropdown"
            name="submenu"
            onChange={this.changeTerm}
          >
            <option value="">Select</option>
            {this.state.feature_subcategory.map((item, i) => (
              <option
                key={i}
                value={
                  item.related_id === this.state.firstmenu ? item.title : "null"
                }
              >
                {item.title}
              </option>
            ))}
          </select>
        </div>

        {this.state.firstmenu === "Home" && (
          <Redirect to={"/en/investment_wire/"} />
        )}
        {this.state.firstmenu === "Global Company Database" && (
          <Redirect to={"/en/global-company-database/"} />
        )}
        {this.state.firstmenu === "Manage Alerts" && (
          <Redirect to={"/en/manage_alert/"} />
        )}

        {this.state.firstmenu === "Global Investors Database" && (
          <Redirect to={"/en/global-investors-database/"} />
        )}
        {this.state.firstmenu === "Global Research Report Database" && (
          <Redirect to={"/en/global-research-report-database/"} />
        )}

        {this.state.submenu === "M & A Deals" &&
          this.state.firstmenu === "Global Deals Intelligence" && (
            <Redirect to={"/en/m_a_deals_monitor/"} />
          )}
        {this.state.firstmenu === "Global Professional Database" && (
          <Redirect to={"/en/gross-professional-database/"} />
        )}

        {this.state.submenu === "Venture Capital Deals" &&
          this.state.firstmenu === "Global Deals Intelligence" && (
            <Redirect to={"/en/vc-deals/"} />
          )}
        {this.state.submenu === "Real Estate Deals" &&
          this.state.firstmenu === "Global Deals Intelligence" && (
            <Redirect to={"/en/real-estate-deals/"} />
          )}
        {this.state.submenu === "Join Venture Deals" &&
          this.state.firstmenu === "Global Deals Intelligence" && (
            <Redirect to={"/en/jv-deals/"} />
          )}
        {this.state.submenu === "Ipo Monitor" &&
          this.state.firstmenu === "Global Deals Intelligence" && (
            <Redirect to={"/en/ipo-monitor/"} />
          )}
        {this.state.submenu === "Debt Deals" &&
          this.state.firstmenu === "Global Deals Intelligence" && (
            <Redirect to={"/en/debt-deals/"} />
          )}
        {this.state.submenu === "FundRaising Deals" &&
          this.state.firstmenu === "Global Deals Intelligence" && (
            <Redirect to={"/en/fund-raising-deals/"} />
          )}
        {this.state.submenu === "M & A Deals" &&
          this.state.firstmenu === "Global Deals MarketPlace" && (
            <Redirect to={"/en/seeking-buyers-monitor/"} />
          )}
        {this.state.submenu === "Startup/Venture Capital Deals" &&
          this.state.firstmenu === "Global Deals MarketPlace" && (
            <Redirect to={"/en/vc-deals-gmd-monitor/"} />
          )}
        {this.state.submenu === "Real Estate Deals" &&
          this.state.firstmenu === "Global Deals MarketPlace" && (
            <Redirect to={"/en/real-estate-monitor/"} />
          )}
        {this.state.submenu === "Joint Venture Deals" &&
          this.state.firstmenu === "Global Deals MarketPlace" && (
            <Redirect to={"/en/joint-venture-gmd-monitor/"} />
          )}
        {this.state.firstmenu === "Global Investors Database" && (
          <Redirect to={"/en/global-investors-database/"} />
        )}
        {this.state.firstmenu === "ON-Demand Research And Analysis" && (
          <Redirect to={"/en/on-demand_research/"} />
        )}

        {this.state.submenu === "Debt Deals" &&
          this.state.firstmenu === "Global Deals MarketPlace" && (
            <Redirect to={"/en/debt-deals-gmd-monitor/"} />
          )}
        {this.state.submenu === "FundRaising Deals" &&
          this.state.firstmenu === "Global Deals MarketPlace" && (
            <Redirect to={"/en/fundraising_monitor/"} />
          )}

        {!this.state.is_first && (
          <nav className="navbar  justify-content-md-start" id="sector-desktops">
            <NavLink
              exact
              activeClassName="navbar__link--active"
              className="navbar__link"
              to={"/en/investment_wire/"}
            >
              <i class="fa fa-home blueIcon"></i>
            </NavLink>

            <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link activs" style={{ fontSize: '13px' }}
              onClick={this.handleToggle}
              to={"/en/m_a_deals_monitor/"}
            >
              GLOBAL DEALS INTELLIGENCE
            </NavLink>
            <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link uppercase" style={{ fontSize: '13px' }}
              onClick={this.handleToggle3}
              to={"/en/global-company-database/"}
            >
              GLOBAL COMPANY DATABASE
            </NavLink>
            {
              this.state.subscription_feature == "Global Deal Marketplace"
                ? <NavLink
                  activeClassName="navbar__link--active"
                  className="navbar__link uppercase" style={{ fontSize: '13px' }}
                  onClick={this.handleToggle1}
                  to={"/en/seeking-buyers-monitor/"}
                >
                  GLOBAL DEALS Marketplace
                </NavLink>
                : <></>
            }
            {/* {
              this.state.subscription_feature == "Global Deal Marketplace"
                ? <NavLink
                  activeClassName="navbar__link--active"
                  className="navbar__link uppercase" style={{ fontSize: '13px' }}
                  onClick={this.handleToggle3}
                  to={"/en/global-investors-database/"}
                >
                  Global Investors Database
                </NavLink>
                : <></>
            }
            {
              this.state.subscription_feature == "Global Deal Marketplace"
                ? <NavLink
                  activeClassName="navbar__link--active"
                  className="navbar__link uppercase" style={{ fontSize: '13px' }}
                  onClick={this.handleToggle3}
                  to={"/en/global-research-report-database/"}
                >
                  Global Research Report Database
                </NavLink>
                : <></>
            } */}
            {/* <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link uppercase" style={{fontSize: '13px'}}
              onClick={this.handleToggle3}
              to={"/en/gross-professional-database/"}
            >
              Global Professional Database
            </NavLink> */}
            {/* <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              onClick={this.handleToggle3}
              to={"/en/on-demand_research/"}
            >
              ON-Demand Research And Analysis
            </NavLink> */}
            <NavLink
              // activeClassName="navbar__link--active"
              className="navbar__link uppercase" style={{ fontSize: '13px' }}
              //onClick={this.handleToggle2}
              to={"/en/manage_alert/"}
            >
              Manage Alerts
            </NavLink>
          </nav>
        )}

        {this.state.is_first && (
          <nav className="fn-nav d-none d-lg-block">
            <ul>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        )}

        {this.state.is_second && (
          <nav className="navbar navbar1 bottom-nav pl-42 justify-content-md-start" id="sector-desktops">
            <NavLink
              exact
              activeClassName="navbar__link--active"
              className="navbar__link uppercase" style={{ fontSize: '12px' }}
              to={"/en/m_a_deals_monitor/"}
            >
              M&A DEALS
            </NavLink>
            <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link uppercase" style={{ fontSize: '12px' }}
              to={"/en/vc-deals/"}
            >
              Venture Capital Deals
            </NavLink>
            <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link uppercase" style={{ fontSize: '12px' }}
              to={"/en/real-estate-deals/"}
            >
              Real Estate Deals
            </NavLink>
            {/* <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"/en/jv-deals/"}
            >
              Join Venture Deals
            </NavLink>
            <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"/en/ipo-monitor/"}
            >
              Ipo Monitor
            </NavLink>
            <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"/en/debt-deals/"}
            >
              Debt Deals
            </NavLink> */}
            <NavLink
              activeClassName="navbar__link--active"
              className="navbar__link uppercase" style={{ fontSize: '12px' }}
              to={"/en/fund-raising-deals/"}
            >
              FundRaising Deals
            </NavLink>
          </nav>
        )}
        {this.state.is_third && (
          <nav className="navbar navbar1  justify-content-md-start" id="sector-desktops">
            <NavLink
              exact
              // activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              MA DEALS
            </NavLink>
            <NavLink
              // activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"/en/real-estate-target/"}
            >
              Real Estate Deals
            </NavLink>
            <NavLink
              //activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              Debt Deals
            </NavLink>

            <NavLink
              //activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              Startup/Venture Capital Deals
            </NavLink>
            <NavLink
              // activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              FundRaising Deals
            </NavLink>
            <NavLink
              //activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              Join Venture Deals
            </NavLink>
          </nav>
        )}

        {this.state.is_fourth && (
          <nav className="navbar navbar1  justify-content-md-start" id="sector-desktops">
            <NavLink
              exact
              // activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              Global Investment Intelligence
            </NavLink>
            <NavLink
              // activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              Global Investment Wire
            </NavLink>
            <NavLink
              //activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              Global Deals Intelligence
            </NavLink>

            <NavLink
              //activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              Global Company Database
            </NavLink>
            <NavLink
              // activeClassName="navbar__link--active"
              className="navbar__link uppercase"
              to={"#"}
            >
              Global Research Reports
            </NavLink>
          </nav>
        )}

        <div className="fn-nav d-block d-lg-none sector-mobile">
          <Dropdown title="Select location" list={this.state.location} />
        </div>
      </div>
    );
  }
}

export default NavfilterAfterLogin;
