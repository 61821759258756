import React, {Component} from 'react';
import { setupCache } from 'axios-cache-adapter';
import axios from "axios";


const cache = setupCache({
    maxAge: 15 * 60 * 1000
  })

  const api = axios.create({
    adapter: cache.adapter
  })
class Company extends Component{
    constructor(props) {
        super(props);
        this.state = {
            giw:[],
            exampleItems:[],
            page:0,
            current:0,
            filter:'',
            old_route:'',
            pageSize:10,
            count:0,
            is_first:true
        }
    }

  

    componentDidMount = () => {
        window.scrollTo(0, 0);


        

        console.log(this.props.match.params.id);
        var current_route = this.props.match.params.id;
         if(current_route ==undefined){
            current_route = '';
        }

        this.setState({
                old_route:current_route
            })
            this.getData(current_route, '',0, true)

    }

    getData =(route, filter, page, is_first)=>{
        console.log(89,this.state.current);

        api({
          url: 'https://api.analyzemarkets.com/giwm/?name='+route+'&start='+page+''+filter,
          method: 'get'
        }).then(async (res) => {
          console.log(res)
          if(res.status==200){
              this.setState({giw:res.data.results,
                  is_first:false});
          }

          if(is_first){
              
              this.setState({exampleItems:[...Array(res.data.count).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) })),
                  count:res.data.count
              });
          }
        })
    }

render(){
    
    return <div>
{this.state.giw.map((item, key) => (
<div>
{item.company_name.map((company, key) => (
    <img src={company.image} className="clogo-s" alt=""/>
 ))}
</div>
))}
    </div>;
}
}

export default Company;