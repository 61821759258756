import React, { Component, Fragment } from "react";

import "../investment-intellegence-screener/investment-Intelligence-screener.css";
import { Redirect } from "react-router";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Row, Table, Col, Container } from "react-bootstrap";
import Autocomplete from "react-autocomplete";
import Fetch from "../../global/CommonComponent/useFetch";

import {
  matchStocks,
  matchCountry,
  matchSector,
  matchCompany,
  matchOpportunity,
  matchStatus,
  matchDeal,
  matchRegion,
  matchGeography,
} from "../investment-intellegence-screener/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
class RealEstateScreener extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      comapany_name: "",
      companyand_data: "",
      reported_date: "",
      target_comapany_name: "",
      target_companyand_data: "",
      acquirer_parent: "",
      acquirer_parentand_data: "",
      target_seller: "",
      targetand_seller: "",
      filterdata: "",
      target_country: "",
      official_announcement: [],
      deal_official_announcement: "",
      sector_name: "",
      target_sector_name: "",
      deal_name: "",
      dealand_names: "",
      startDate: "",
      endDate: "",
      end_date: "",
      start_date: "",

      deal_stat: "",
      deal_status: [],
      deal: [],
      propertysubtype: "",
      propertytype: "",
      sub_type: [],
      prop_type: [],
      deal_type: "",
      type: [],
      geography_name: "",
      geography: [],
      country_name: "",
      country: [],
      result: false,
      sector: [],
      regions: [],
      region_name: "",
      region: null,
      by_market: null,
      reportedDate: new Date(),
    };
    this.getCompanyData = this.getCompanyData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.handleLastDate = this.handleLastDate.bind(this);
    this.handleLastDays = this.handleLastDays.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });
    this.handleDeal(filterdata);
  };

  handleDeal = (data) => {
    axios
      .get("https://api.analyzemarkets.com/realestatedealsSPtitle/?" + data)
      .then((res) => {
        //  console.log(res, "vc deal");
        this.setState({ deal: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };

  handleClick = (item) => {
    this.props.applyFilter(
      "&deal=" +
        this.state.deal_name +
        "&company_name=" +
        this.state.comapany_name +
        "&country=" +
        this.state.country_name +
        "&deal_status=" +
        this.state.deal_stat +
        "&target_company_name=" +
        this.state.target_comapany_name +
        "&target_country=" +
        this.state.target_country +
        "&deal_geography=" +
        this.state.geography_name +
        "&deal_type=" +
        this.state.deal_type +
        "&sector=" +
        this.state.sector_name +
        "&target_sector=" +
        this.state.target_sector_name +
        "&acquirer_parent=" +
        this.state.acquirer_parent +
        "&target_seller=" +
        this.state.target_seller +
        "&announced_reported=" +
        this.state.reported_date +
        "&region=" +
        this.state.region_name +
        "&deal_official_announcement=" +
        this.state.deal_official_announcement +
        "&propertysubtype=" +
        this.state.propertysubtype +
        "&propertytype=" +
        this.state.propertytype +
        "&start_date=" +
        this.state.start_date +
        "&end_date=" +
        this.state.end_date
    );
  };

  refreshButton = () => {
    this.setState({
      deal_name: "",
      comapany_name: "",
      country_name: "",
      deal_stat: "",
      target_comapany_name: "",
      target_country: "",
      geography_name: "",
      deal_type: "",
      sector_name: "",
      target_sector_name: "",
      acquirer_parent: "",
      target_seller: "",
      region_name: "",
      deal_official_announcement: "",
      propertysubtype: "",
      propertytype: "",
    });
  };

  onChange = (date) => this.setState({ date });
  handleLastDays = (e) => {
    console.log(Number.isInteger(e), e);
    if (Number.isInteger(parseInt(e))) {
      this.setState({
        last_days: e,
        startDate: "",
        start_date: "",
        end_date: "",
        endDate: "",
      });
    }
  };

  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };

  handleChange = (date) => {
    if (date !== null) {
      console.log(
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        "Test"
      );
      this.setState({
        start_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        startDate: date,
        last_days: "",
      });
    } else {
      this.setState({
        start_date: "",
        startDate: "",
        last_days: "",
      });
    }
  };
  handleLastDate = (date) => {
    if (date !== null) {
      console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
      this.setState({
        last_days: "",
        end_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        endDate: date,
      });
    } else {
      this.setState({
        last_days: "",
        end_date: "",
        endDate: "",
      });
    }
  };

  getCompanyData = (region, markets) => {
    var params = "";
    if (region != null) {
      params = "?region=" + region;
    }

    if (markets != null && params != "") {
      params += "&by_market=" + markets;
    }

    if (markets != null && params == "") {
      params = "?by_market=" + markets;
    }
    console.log(params, 91);

    axios
      .get("https://api.analyzemarkets.com/dealstatus/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ deal_status: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/regman/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ regions: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ company: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/category/", {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ category: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/doav/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ official_announcement: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/dealgeography/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ geography: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/subpropertytype/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ sub_type: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/propertytype/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ prop_type: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/dealtype/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ type: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/sectorman/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ sector: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };
  componentDidMount = () => {
    this.getCompanyData(this.props.byRegion, this.props.byMarkets);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
    if (this.state.filterdata !== "") {
      this.handleDeal(this.state.filterdata);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.byRegion !== null &&
      this.state.region !== this.props.byRegion
    ) {
      this.setState({
        region: this.props.byRegion,
        company: [],
        country: [],
        deal: [],
      });
      console.log(this.props.byRegion, 138);
      this.getCompanyData(this.props.byRegion, null);
    }
    if (
      this.props.byMarkets !== null &&
      this.state.by_market !== this.props.byMarkets
    ) {
      this.setState({
        by_market: this.props.byMarkets,
        company: [],
        country: [],
        deal: [],
      });
      this.getCompanyData(null, this.props.byMarkets);
    }
  };
  render() {
    return (
      <Fragment>
        <Container>
          <Form className="">
            <div className="iis greyborder2px">
              <h4>Real Estate Screener</h4>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <div className="pdg15">
                <Row>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={
                          this.state.deal_name.indexOf("%26") != -1
                            ? this.state.dealand_names
                            : this.state.deal_name
                        }
                        inputProps={{
                          id: "deal-autocomplete",
                          placeholder: "Deal",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.deal}
                        getItemValue={(item) => item.opportunity}
                        onChange={(event, deal_name) =>
                          this.setState({ deal_name })
                        }
                        shouldItemRender={matchOpportunity}
                        onSelect={(deal_name) =>
                          this.setState({
                            deal_name:
                              deal_name.indexOf("&") != -1
                                ? deal_name.replace("&", "%26")
                                : deal_name,
                            dealand_names:
                              deal_name.indexOf("%26") != -1
                                ? deal_name.replace("%26", "&")
                                : deal_name,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.opportunity}
                          >
                            {item.opportunity}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {" "}
                    <div className="form-group autoc">
                      <Autocomplete
                        value={
                          this.state.comapany_name.indexOf("%26") != -1
                            ? this.state.companyand_data
                            : this.state.comapany_name
                        }
                        inputProps={{
                          id: "company-autocomplete",
                          placeholder: "Acquirer",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.company}
                        shouldItemRender={matchCompany}
                        getItemValue={(item) => item.name}
                        onChange={(event, comapany_name) =>
                          this.setState({
                            comapany_name:
                              comapany_name !== null &&
                              comapany_name.indexOf("&") != -1
                                ? comapany_name.replace("&", "%26")
                                : comapany_name,
                            companyand_data:
                              comapany_name !== null &&
                              comapany_name.indexOf("%26") != -1
                                ? comapany_name.replace("%26", "&")
                                : comapany_name,
                          })
                        }
                        onSelect={(comapany_name) =>
                          this.setState({
                            comapany_name:
                              comapany_name.indexOf("&") != -1
                                ? comapany_name.replace("&", "%26")
                                : comapany_name,
                            companyand_data:
                              comapany_name.indexOf("%26") != -1
                                ? comapany_name.replace("%26", "&")
                                : comapany_name,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.name}
                          >
                            {item.name}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={
                          this.state.target_comapany_name.indexOf("%26") != -1
                            ? this.state.target_companyand_data
                            : this.state.target_comapany_name
                        }
                        inputProps={{
                          id: "company-autocomplete",
                          placeholder: "Target Company",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.company}
                        shouldItemRender={matchCompany}
                        getItemValue={(item) => item.name}
                        onChange={(event, target_comapany_name) =>
                          this.setState({
                            target_comapany_name:
                              target_comapany_name !== null &&
                              target_comapany_name.indexOf("&") != -1
                                ? target_comapany_name.replace("&", "%26")
                                : target_comapany_name,
                            target_companyand_data:
                              target_comapany_name !== null &&
                              target_comapany_name.indexOf("%26") != -1
                                ? target_comapany_name.replace("%26", "&")
                                : target_comapany_name,
                          })
                        }
                        onSelect={(target_comapany_name) =>
                          this.setState({
                            target_comapany_name:
                              target_comapany_name.indexOf("&") != -1
                                ? target_comapany_name.replace("&", "%26")
                                : target_comapany_name,
                            target_companyand_data:
                              target_comapany_name.indexOf("%26") != -1
                                ? target_comapany_name.replace("%26", "&")
                                : target_comapany_name,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.name}
                          >
                            {item.name}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {" "}
                    <div className="form-group autoc">
                      <Autocomplete
                        value={
                          this.state.acquirer_parent.indexOf("%26") != -1
                            ? this.state.acquirer_parentand_data
                            : this.state.acquirer_parent
                        }
                        inputProps={{
                          id: "company-autocomplete",
                          placeholder: "Acquirer Parent Company",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.company}
                        shouldItemRender={matchCompany}
                        getItemValue={(item) => item.name}
                        onChange={(event, acquirer_parent) =>
                          this.setState({ acquirer_parent })
                        }
                        onSelect={(acquirer_parent) =>
                          this.setState({
                            acquirer_parent:
                              acquirer_parent.indexOf("&") != -1
                                ? acquirer_parent.replace("&", "%26")
                                : acquirer_parent,
                            acquirer_parentand_data:
                              acquirer_parent.indexOf("%26") != -1
                                ? acquirer_parent.replace("%26", "&")
                                : acquirer_parent,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.name}
                          >
                            {item.name}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={
                          this.state.target_seller.indexOf("%26") != -1
                            ? this.state.targetand_seller
                            : this.state.target_seller
                        }
                        inputProps={{
                          id: "company-autocomplete",
                          placeholder: "Target Seller Company",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.company}
                        shouldItemRender={matchCompany}
                        getItemValue={(item) => item.name}
                        onChange={(event, target_seller) =>
                          this.setState({
                            target_seller:
                              target_seller !== null &&
                              target_seller.indexOf("&") != -1
                                ? target_seller.replace("&", "%26")
                                : target_seller,
                            targetand_seller:
                              target_seller !== null &&
                              target_seller.indexOf("%26") != -1
                                ? target_seller.replace("%26", "&")
                                : target_seller,
                          })
                        }
                        onSelect={(target_seller) =>
                          this.setState({
                            target_seller:
                              target_seller.indexOf("&") != -1
                                ? target_seller.replace("&", "%26")
                                : target_seller,
                            targetand_seller:
                              target_seller.indexOf("%26") != -1
                                ? target_seller.replace("%26", "&")
                                : target_seller,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.name}
                          >
                            {item.name}
                          </div>
                        )}
                      />
                    </div>
                  </Col>

                  <Col>
                    {" "}
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.deal_type}
                        inputProps={{
                          id: "country-autocomplete",
                          placeholder: "Select Deal Type",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.type}
                        getItemValue={(item) => item.type}
                        shouldItemRender={matchGeography}
                        onChange={(event, deal_type) =>
                          this.setState({ deal_type })
                        }
                        onSelect={(deal_type) => this.setState({ deal_type })}
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.type}
                          >
                            {item.type}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {" "}
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.country_name}
                        inputProps={{
                          id: "country-autocomplete",
                          placeholder: "Acquirer Country",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.country}
                        getItemValue={(item) => item.country}
                        shouldItemRender={matchCountry}
                        onChange={(event, country_name) =>
                          this.setState({ country_name })
                        }
                        onSelect={(country_name) =>
                          this.setState({ country_name })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.country}
                          >
                            {item.country}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.target_country}
                        inputProps={{
                          id: "country-autocomplete",
                          placeholder: "Target Country",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.country}
                        getItemValue={(item) => item.country}
                        shouldItemRender={matchCountry}
                        onChange={(event, target_country) =>
                          this.setState({ target_country })
                        }
                        onSelect={(target_country) =>
                          this.setState({ target_country })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.country}
                          >
                            {item.country}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.deal_official_announcement}
                        inputProps={{
                          id: "deal-autocomplete",
                          placeholder: "Deal Official Announcement",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.official_announcement}
                        getItemValue={(item) => item.type}
                        onChange={(event, deal_official_announcement) =>
                          this.setState({ deal_official_announcement })
                        }
                        shouldItemRender={matchGeography}
                        onSelect={(deal_official_announcement) =>
                          this.setState({ deal_official_announcement })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.type}
                          >
                            {item.type}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.target_sector_name}
                        inputProps={{
                          id: "company-autocomplete",
                          placeholder: "Target Sector",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.sector}
                        shouldItemRender={matchSector}
                        getItemValue={(item) => item.sector}
                        onChange={(event, target_sector_name) =>
                          this.setState({ target_sector_name })
                        }
                        onSelect={(target_sector_name) =>
                          this.setState({ target_sector_name })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.sector}
                          >
                            {item.sector}
                          </div>
                        )}
                      />
                    </div>
                  </Col>

                  <Col>
                    {" "}
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.geography_name}
                        inputProps={{
                          id: "country-autocomplete",
                          placeholder: "Select Geography",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.geography}
                        getItemValue={(item) => item.type}
                        shouldItemRender={matchGeography}
                        onChange={(event, geography_name) =>
                          this.setState({ geography_name })
                        }
                        onSelect={(geography_name) =>
                          this.setState({ geography_name })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.type}
                          >
                            {item.type}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.propertytype}
                        inputProps={{
                          id: "company-autocomplete",
                          placeholder: "Property Type",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.prop_type}
                        shouldItemRender={matchGeography}
                        getItemValue={(item) => item.type}
                        onChange={(event, propertytype) =>
                          this.setState({ propertytype })
                        }
                        onSelect={(propertytype) =>
                          this.setState({ propertytype })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.type}
                          >
                            {item.type}
                          </div>
                        )}
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.propertysubtype}
                        inputProps={{
                          id: "company-autocomplete",
                          placeholder: "Property Sub Type",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.sub_type}
                        shouldItemRender={matchGeography}
                        getItemValue={(item) => item.type}
                        onChange={(event, propertysubtype) =>
                          this.setState({ propertysubtype })
                        }
                        onSelect={(propertysubtype) =>
                          this.setState({ propertysubtype })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.type}
                          >
                            {item.type}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.region_name}
                        inputProps={{
                          id: "category-autocomplete",
                          placeholder: "Property Region",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.regions}
                        getItemValue={(item) => item.region}
                        onChange={(event, region_name) =>
                          this.setState({ region_name })
                        }
                        onSelect={(region_name) =>
                          this.setState({ region_name })
                        }
                        shouldItemRender={matchRegion}
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.region}
                          >
                            {item.region}
                          </div>
                        )}
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="d-flex justify-content-between date-range">
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleChange}
                        placeholderText="Start Announced Date"
                      />
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleLastDate}
                        placeholderText="End Announced Date"
                      />
                    </div>

                    {/* <Form>
                      <div className="d-flex justify-content-between date-range">
                        <DatePicker
                          selected={this.state.reportedDate}
                          onChange={this.handleReportedDate}
                        />
                      </div>
                    </Form>*/}
                  </Col>
                </Row>
                <div className="d-flex justify-content-between">
                  <Link
                    to={"/en/real-estate-seeking-properties-search/"}
                    className="btn btn-blue"
                    onClick={() => {
                      this.handleClick("region");
                    }}
                  >
                    Apply
                  </Link>
                  <Link
                    to={"/en/real-estate-seeking-properties-search/"}
                    className="btn btn-blue"
                    onClick={this.refreshButton}
                  >
                    Reset
                  </Link>
                </div>
              </div>
            </div>
          </Form>
        </Container>
      </Fragment>
    );
  }
}

export default RealEstateScreener;
