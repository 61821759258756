import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "../debt-deals.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import Linkify from "react-linkify";
import Signin from "../../signin/signinIndex";
import { Helmet } from "react-helmet";
import Fetch from "../../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class DE_Company_Seeking_Detail extends Component {
  constructor(props) {
    super(props);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    details: [],
    acquirer: [],
    jv_company: [],
    email: "",
    filterdata: "",
    DealValuation: [],
    advisors: [],
    news: [],
    lender: [],
    cgurantor: [],
  };

  componentDidMount = () => {
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/Companies_Seeking_DF_single/" +
        this.props.match.params.opportunity +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        details: res.data,
        acquirer: res.data.debtsseeekssAcquirer,
        //jv_company: res.data.company,
        advisors: res.data.debtsdseeklssoAdvisors,
        news: res.data.debtsseeksstyNews,
        // lender: res.data.debtsdeaslsstnersAcquirer,
        cgurantor: res.data.corproate_gurantor,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();
    });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const data = this.state.details;
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );

    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Fetch applyFilters={this.handleFilters}></Fetch>

            <Header></Header>
            <Navfilter id="sector-desktop"></Navfilter>
            <Container>
              <Helmet title={data.deal}>
                <meta name="description" content={data.description} />
                <meta property="og:title" content={data.deal} />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta name="og:description" content={data.description} />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta itemprop="description" content={data.description} />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>

              <Button
                onClick={this.handleBack}
                variant="outline-light"
                className="MarginRight padDown"
              >
                <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
              </Button>
              <Row>
                <Col className="">
                  <Row className="justify-content-md-center">
                    <Col xs sm={12} class="">
                      <Row>
                        <Col sm={5}>
                          {" "}
                          <div class=" hero" id="2">
                            {this.state.acquirer.length > 0 && (
                              <h3 className="UpperCase">BORROWER</h3>
                            )}
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                {this.state.acquirer.map((item, index) => (
                                  <tr>
                                    <td width="250" className="space">
                                      Company
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="paddingRight">
                                        {item.company_name.map(
                                          (acquirer, key) => (
                                            <div>
                                              {acquirer.api_url != null ? (
                                                <Link
                                                  to={
                                                    "/en/global-company-database-details/" +
                                                    acquirer.api_url +
                                                    "/"
                                                  }
                                                >
                                                  <u className="underline1">
                                                    {acquirer.name}
                                                  </u>
                                                </Link>
                                              ) : (
                                                <Link>
                                                  <u className="underline1">
                                                    {acquirer.company_name}
                                                  </u>
                                                </Link>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                                {this.state.acquirer.map((item, index) => (
                                  <tr>
                                    <td width="250" className="space">
                                      Parent
                                    </td>
                                    <td>
                                      <div className="paddingRight">
                                        {item.acquirer_parent.map(
                                          (acquirer_par, key) => (
                                            <div>
                                              {acquirer_par.api_url != null ? (
                                                <Link
                                                  to={
                                                    "/en/global-company-database-details/" +
                                                    acquirer_par.api_url +
                                                    "/"
                                                  }
                                                >
                                                  <u className="underline1">
                                                    {acquirer_par.name}
                                                  </u>
                                                </Link>
                                              ) : (
                                                <Link>
                                                  <u className="underline1">
                                                    {acquirer_par.name}
                                                  </u>
                                                </Link>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <br></br>
                          {/* <div class="" id="5">
                            <h3 className="UpperCase">LENDER</h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                            //   responsive="lg"
                            // >
                            //   <tbody>
                            //     {this.state.lender.map((lender, index) => (
                            //       <tr>
                            //         <td width="250" className="space">
                            //           <div>Company</div>
                            //         </td>
                            //         <td>
                            //           <div className="paddingRight">
                            //             {lender.company_name.map((adv, key) => (
                            //               <div>
                            //                 {adv.api_url != null ? (
                            //                   <Link
                            //                     to={
                            //                       "/en/global-company-database-details/" +
                            //                       adv.api_url +
                            //                       "/" +
                            //                       this.state.token
                            //                     }
                            //                   >
                            //                     <u className="underline1">
                            //                       {adv.name}
                            //                     </u>
                            //                   </Link>
                            //                 ) : (
                            //                   <Link>
                            //                     <u className="underline1">
                            //                       {adv.name}
                            //                     </u>
                            //                   </Link>
                            //                 )}
                            //               </div>
                            //             ))}
                            //           </div>
                            //         </td>
                            //       </tr>
                            //     ))}
                              </tbody>
                            </Table>
                          </div> */}
                          <br></br>
                          <div class="" id="5">
                            {this.state.advisors.length > 0 && (
                              <h3 className="UpperCase">Advisors</h3>
                            )}
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                {this.state.advisors.map((advisor, index) => (
                                  <tr>
                                    <td width="250">
                                      <div>{advisor.title}</div>
                                    </td>
                                    <td>
                                      <div className="paddingRight">
                                        {advisor.companyname.map((adv, key) => (
                                          <div>
                                            {adv.api_url != null ? (
                                              <Link
                                                to={
                                                  "/en/global-company-database-details/" +
                                                  adv.api_url +
                                                  "/"
                                                }
                                              >
                                                <u className="underline1">
                                                  {adv.name}
                                                </u>
                                              </Link>
                                            ) : (
                                              <Link>
                                                <u className="underline1">
                                                  {adv.name}
                                                </u>
                                              </Link>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <br></br>
                          <div class="" id="6">
                            {this.state.news.length > 0 && (
                              <h3 className="UpperCase">News</h3>
                            )}
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                {this.state.news.map((item, index) => (
                                  <tr>
                                    <td width="250" className="space">
                                      <div>{item.news}</div>
                                    </td>
                                    <Linkify
                                      properties={{
                                        target: "_blank",
                                        style: {
                                          color: "red",
                                          fontWeight: "bold",
                                        },
                                      }}
                                    >
                                      <td className="word-wrap">
                                        <div>{item.value + " "}</div>
                                      </td>
                                    </Linkify>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col sm={7}>
                          <div class=" hero" id="1">
                            <h3 className="UpperCase">Deal Information</h3>
                            <Table
                              className="tblnoline"
                              size="lg"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td width="150">Deal</td>

                                  <td>{data.opportunity}</td>
                                </tr>

                                <tr>
                                  <td>Deal Description</td>
                                  <td>
                                    <p
                                      className=""
                                      dangerouslySetInnerHTML={{
                                        __html: data.description,
                                      }}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td>Deal Official Announcement</td>

                                  <td>{data.deal_official_announcement}</td>
                                </tr>
                                <tr>
                                  <td>Deal Announced Date</td>

                                  <td>
                                    <Moment format="DD MMM YYYY">
                                      {data.announced_reported}
                                    </Moment>
                                  </td>
                                </tr>

                                <tr>
                                  <td width="150" className="space">
                                    Deal Value
                                  </td>

                                  <td>{data.deal_value}</td>
                                </tr>
                                <tr>
                                  <td>Deal Status</td>

                                  <td>{data.deal_status}</td>
                                </tr>
                                <tr>
                                  <td>Financing Type</td>
                                  <td>{data.financing_type}</td>
                                </tr>
                                <tr>
                                  <td>Shariah-compliant Financing</td>

                                  <td>{data.shariahcompliant_financing}</td>
                                </tr>

                                <tr>
                                  <td>Islamic Financing Structure</td>
                                  <td>{data.islamic_financing_structure}</td>
                                </tr>

                                <tr>
                                  <td>Deal Geography</td>

                                  <td>{data.deal_geography}</td>
                                </tr>

                                <tr>
                                  <td width="250" className="space">
                                    Corproate Gurantor
                                  </td>
                                  <td>
                                    <div className="paddingRight">
                                      {this.state.cgurantor.map((item, key) => (
                                        <div>
                                          {item.api_url != null ? (
                                            <Link
                                              to={
                                                "/en/global-company-database-details/" +
                                                item.api_url +
                                                "/" +
                                                this.state.token
                                              }
                                            >
                                              <u className="underline1">
                                                {item.name}
                                              </u>
                                            </Link>
                                          ) : (
                                            <Link>
                                              <u className="underline1">
                                                {item.name}
                                              </u>
                                            </Link>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Financing Tenure / Maturity</td>
                                  <td>{data.financing_tenure_maturity}</td>
                                </tr>
                                <tr>
                                  <td>Financing / Coupon Terms</td>
                                  <td>{data.financing_coupon_terms}</td>
                                </tr>
                                <tr>
                                  <td>Syndicated Deal</td>
                                  <td>{data.syndicated_deal}</td>
                                </tr>

                                <Linkify
                                  componentDecorator={componentDecorator}
                                >
                                  <tr>
                                    <td>Reference</td>
                                    <td className="word-wrap">
                                      {data.reference}
                                    </td>
                                  </tr>
                                </Linkify>

                                <tr>
                                  <td>Deal Internal Reference:</td>
                                  <td>{data.dealinternalreferencenumber}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>

                          {/* <div class="" id="6">
                    <h1>Section Six</h1>
                  </div>
                  <div class="" id="7">
                    <h1>Section Seven</h1> 
                  </div>
                  */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default DE_Company_Seeking_Detail;
