import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "./fund-raising-deals.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import Linkify from "react-linkify";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class Fund_Raising_details extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    details: [],
    fund_name: [],
    regions: [],
    subregions: [],
    countrys: [],
    fund_manager: [],
    fund_anchor_investors: [],
    fund_investors: [],
    acquirer: [],
    target: [],
    DealValuation: [],
    advisors: [],
    news: [],
  };
  handleBack = () => {
    this.props.history.goBack();
  };

  componentDidMount = () => {
    api({
      url:
        "https://api.analyzemarkets.com/gddfrd_single/" +
        this.props.match.params.deal +
        "/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        details: res.data,
        fund_name: res.data.fund_name,
        fund_anchor_investors: res.data.fund_anchor_investors,
        fund_investors: res.data.fund_investors,
        fund_manager: res.data.fund_manager,
        advisors: res.data.GDD_Fundraising_Deals_Advisors,
        news: res.data.GDD_Fundraising_Deals_News,
        subregions: res.data.sub_region,
        countrys: res.data.country,
        regions: res.data.region,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    // $(document).ready(function() {
    //   $("a[href*=#]").bind("click", function(e) {
    //     e.preventDefault(); // prevent hard jump, the default behavior

    //     var target = $(this).attr("href"); // Set the target as variable

    //     // perform animated scrolling by getting top-position of target-element and set it as scroll target
    //     $("html, body")
    //       .stop()
    //       .animate(
    //         {
    //           scrollTop: $(target).offset().top
    //         },
    //         600,
    //         function() {
    //           window.location.hash = target; //attach the hash (#jumptarget) to the pageurl
    //         }
    //       );

    //     return false;
    //   });
    // });

    // $(window)
    //   .scroll(function() {
    //     var scrollDistance = $(window).scrollTop();

    //     // Show/hide menu on scroll
    //     if (scrollDistance >= 1800) {
    //       $(".navigation").css({
    //         position: "fixed",
    //         bottom: 200
    //       });
    //     } else {
    //       $(".navigation").css({
    //         position: "fixed",
    //         bottom: 12
    //       });
    //     }

    //     // Assign active class to nav links while scolling
    //     $(".").each(function(i) {
    //       if ($(this).position().top <= scrollDistance) {
    //         $(".navigation a.active").removeClass("active");
    //         $(".navigation a")
    //           .eq(i)
    //           .addClass("active");
    //       }
    //     });
    //   })
    //   .scroll();
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  render() {
    const data = this.state.details;
    const sub_reg = this.state.subregions.filter((value) => {
      return value.sub_region;
    }).length;
    const coun = this.state.countrys.filter((ind) => {
      return ind.country;
    }).length;
    const reg_data = this.state.regions.filter((values) => {
      return values.region;
    }).length;

    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );

    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Navfilter id="sector-desktop"></Navfilter>

          <Button
            onClick={this.handleBack}
            variant="outline-light"
            className="MarginRight padDown"
          >
            <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
          </Button>
          <Row>
            <Col className="">
              <Row className="justify-content-md-center">
                <Col xs sm={12} class="">
                  <Row>
                    <Col sm={5}>
                      <div class=" hero" id="2">
                        <h3 className="UpperCase">FUND</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="250" className="space">
                                Fund Name
                              </td>
                              <td>
                                <div className="paddingRight">
                                  {data.fund_name}
                                  {/* {this.state.fund_name.map((fundname, key) => (
                                    <div>
                                      {fundname != null && (
                                        <Link
                                          to={
                                            "/en/global-company-database-details/" +
                                            fundname.api_url +
                                            "/" }
                                        >
                                          <u className="underline1">
                                            {fundname.name}
                                          </u>
                                        </Link>
                                      )}
                                    </div>
                                  ))}*/}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td width="250" className="space">
                                Fund Manager
                              </td>
                              <td>
                                <div className="paddingRight">
                                  {this.state.fund_manager.map(
                                    (fundmanager, key) => (
                                      <div>
                                        {fundmanager.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              fundmanager.api_url +
                                              "/"
                                            }
                                          >
                                            <u className="underline1">
                                              {fundmanager.name}
                                            </u>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline1">
                                              {fundmanager.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Fund Category</td>

                              <td>{data.fund_category}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div class="" id="5">
                        <h3 className="UpperCase">Advisors</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            {this.state.advisors.map((advisor, index) => (
                              <tr>
                                <td width="250" className="space">
                                  <div>{advisor.title}</div>
                                </td>
                                <td>
                                  <div className="paddingRight">
                                    {advisor.companyname.map((adv, key) => (
                                      <div>
                                        {adv.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              adv.api_url +
                                              "/"
                                            }
                                          >
                                            <u className="underline1">
                                              {adv.name}
                                            </u>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline1">
                                              {adv.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <div class="" id="6">
                        <h3 className="UpperCase">News</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            {this.state.news.map((item, index) => (
                              <tr>
                                <td width="250" className="space">
                                  <div>{item.news}</div>
                                </td>
                                <Linkify
                                  componentDecorator={componentDecorator}
                                >
                                  <td className="word-wrap">
                                    <div>{item.value + " "}</div>
                                  </td>
                                </Linkify>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col sm={7}>
                      <div class=" hero" id="1">
                        <h3 className="UpperCase">Deal Information</h3>
                        <Table className="tblnoline" size="lg" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="150">Deal</td>

                              <td>{data.deal}</td>
                            </tr>

                            <tr>
                              <td>Deal Description</td>
                              <td>
                                <p
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: data.brief_description,
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td width="150" className="space">
                                Deal Announced Date
                              </td>

                              <td>
                                <Moment
                                  format="DD MMM YYYY"
                                  className="DateFont"
                                >
                                  {data.announced_reported}
                                </Moment>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td width="150" className="space">
                                Deal Official Confirmation
                              </td>

                              <td> {data.dealofficialconfirmation}</td>
                            </tr>

                            <tr>
                              <td>Total Fund Target Size</td>

                              <td>{data.total_fund_size}</td>
                            </tr>
                            <tr>
                              <td>Total Amount Raised</td>

                              <td>{data.total_amount_raised}</td>
                            </tr>
                            <tr>
                              <td>Fundraising Stage</td>

                              <td>{data.fundraising_stage}</td>
                            </tr>
                            <tr>
                              <td>Deal Status</td>

                              <td>{data.deal_status}</td>
                            </tr>

                            <tr>
                              <td>Fund Closing Date</td>
                              <td>{data.fund_closing_date}</td>
                            </tr>

                            <tr>
                              <td>Fund Domicile</td>

                              <td>{data.fund_domicile}</td>
                            </tr>

                            <tr>
                              <td>Fund Anchor Investors</td>

                              <td>
                                <div className="paddingRight">
                                  {this.state.fund_anchor_investors.map(
                                    (fundancor, key) => (
                                      <div>
                                        {fundancor.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              fundancor.api_url +
                                              "/"
                                            }
                                          >
                                            <u className="underline1">
                                              {fundancor.name}
                                            </u>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline1">
                                              {fundancor.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Fund Investors</td>
                              <td>
                                <div className="paddingRight">
                                  {this.state.fund_investors.map(
                                    (fund_inv, key) => (
                                      <div>
                                        {fund_inv.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              fund_inv.api_url +
                                              "/"
                                            }
                                          >
                                            <u className="underline1">
                                              {fund_inv.name}
                                            </u>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline1">
                                              {fund_inv.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              </td>{" "}
                            </tr>

                            <tr>
                              <td>Fund Geograhic Focus by Region</td>
                              <td className="word-wrap">
                                {this.state.regions.map((reg, index) => (
                                  <span>
                                    {reg_data > 1
                                      ? reg.region + ",  "
                                      : reg.region}
                                  </span>
                                ))}
                              </td>
                            </tr>

                            <tr>
                              <td>Fund Geograhic Focus by Sub-Region</td>
                              <td className="word-wrap">
                                {this.state.subregions.map((subreg, index) => (
                                  <span>
                                    {sub_reg > 1
                                      ? subreg.sub_region + ",  "
                                      : subreg.sub_region}
                                    {console.log(sub_reg, "sub_region")}
                                  </span>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td>Fund Geograhic Focus by country</td>
                              <td className="word-wrap">
                                {this.state.countrys.map((country, index) => (
                                  <span>
                                    {coun > 1
                                      ? country.country + ",  "
                                      : country.country}
                                  </span>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td width="150" className="space">
                                Total Funds Deployed/Invested
                              </td>

                              <td> {data.total_funds_deployed}</td>
                            </tr>
                            <tr>
                              <td width="150" className="space">
                                GP Contribution to Fund{" "}
                              </td>

                              <td> {data.gp_contribution_to_fund}</td>
                            </tr>
                            <tr>
                              <td width="150" className="space">
                                Fund Target Return{" "}
                              </td>

                              <td> {data.fund_target_return}</td>
                            </tr>
                            <tr>
                              <td width="150" className="space">
                                Fund Investment Focus:
                              </td>

                              <td>
                                <p
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: data.fund_investment_focus,
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Reference</td>
                              <td className="word-wrap">
                                <Linkify
                                  componentDecorator={componentDecorator}
                                  className="word-wrap"
                                >
                                  {data.reference}
                                </Linkify>
                              </td>
                            </tr>

                            <tr>
                              <td>Deal Internal Reference:</td>
                              <td>{data.dealinternalreferencenumber}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Fund_Raising_details;
