import React, { Component } from "react";
import logo from "../../../assets/images/logo.svg";
import "./logo.css";

class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signin: [],
      email: "",
      token: "",
      response: "",
      user: "",
      count: "",
      login: [],
      results: false,

      hasError: false,
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <div className="brandlogo">
        <a href={"/en/investment_wire/"}>
          <img src={logo} className="am-logo" alt="AnalyzeMarkets" />
        </a>
      </div>
    );
  }
}

export default Logo;
