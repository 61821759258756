import React, { Component } from 'react'
import { useParams } from 'react-router'




class Pdf extends Component {

    
    
    emailCheck=''

   componentDidMount(){
    const email = localStorage.getItem("email");
    if(email){
        this.emailCheck = email;
        document.querySelector('body').style.overflow = 'hidden'
    }else{
        window.location.href = '/'
    }
     
    }

    componentWillUnmount(){
        document.querySelector('body').style.overflow = 'hidden'
    }

    render(){
       return this.emailCheck ? <div>
       <iframe style={{width:'100%',minHeight:'100vh'}} src={` https://zappa-anlyze-market.s3.amazonaws.com/${this.props.match.params.folder}/${this.props.match.params.file}`} />
       </div> : ''
    }
}

export default Pdf;