import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "./gpd.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import Linkify from "react-linkify";
import { Helmet } from "react-helmet";
import Signin from "../../pages/signin/signinIndex";
import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class GPD_Detail extends Component {
  constructor(props) {
    super(props);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    details: [],
    acquirer: [],
    target: [],
    count: 1,
    filterdata: "",
    people_details: [],
    DealValuation: [],
    advisors: [],
    news: [],
    company: [],
  };
  handleBack = () => {
    this.props.history.goBack();
  };
  componentDidMount = () => {
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/peoplesingle/" +
        this.props.match.params.name +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "People APi");
      this.setState({
        details: res.data,
        people_details: res.data.peopletitletab,

        // acquirer: res.data.GDDMandADealsAcquirer,
        // target: res.data.GDDMandADealsTarget,
        // DealValuation: res.data.GDDMandADealsValidation,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", this.state.count);
    });
  };

  render() {
    const data = this.state.details;
    const Title = "Title & Company";
    const Location = "Location";
    const Sector = "Sector";
    const countnum = this.state.count;
    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Navfilter id="sector-desktop"></Navfilter>
              <Helmet title={data.name}>
                <meta name="description" content={data.biography} />
                <meta property="og:title" content="M&A Deals Monitor" />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  name="og:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  itemprop="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>

              <Button
                onClick={this.handleBack}
                variant="outline-light"
                className="MarginRight padDown"
              >
                <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
              </Button>
              <Row>
                <Col className="">
                  <Row className="justify-content-md-center">
                    <Col xs sm={11} class="">
                      <Row>
                        <Col sm={3} className="pb-3">
                          <img
                            src={data.uploadphoto}
                            className="img-fluid w-100"
                            alt={data.name}
                          ></img>
                        </Col>
                        <Col md={9} className=" table-responsive-lg p4-test">
                          <h2 className="font-size">{data.name}</h2>
                          <br></br>
                          <div class="hero tblnoline1" id="1">
                            {this.state.people_details.map(
                              (peop_det, index) => (
                                <div>
                                  {peop_det.title.title !== null &&
                                    peop_det.company.name !== null && (
                                      <Row
                                        className="tr paddingSpace"
                                        key={index}
                                      >
                                        <Col sm={3}>{index === 0 && Title}</Col>

                                        <Col className="td">
                                          {" "}
                                          {peop_det.title.title !== null &&
                                            peop_det.company.name !== null && (
                                              <div>
                                                {peop_det.title.title + " | "}
                                                {peop_det.company.api_url !==
                                                  null && (
                                                  <Link
                                                    className="underline1"
                                                    to={
                                                      "/en/global-company-database-details/" +
                                                      peop_det.company.api_url +
                                                      "/" }
                                                  >
                                                    {peop_det.company.name}
                                                  </Link>
                                                )}
                                              </div>
                                            )}
                                        </Col>
                                      </Row>
                                    )}
                                </div>
                              )
                            )}
                            <Row className="tr1">
                              <Col sm={3} className="space">
                                Email
                              </Col>

                              <Col>{data.email}</Col>
                            </Row>
                            {this.state.people_details.map(
                              (peop_det, index) => (
                                <div>
                                  {peop_det.company.country !== null && (
                                    <Row className="tr paddingSpace">
                                      <Col sm={3}>
                                        {" "}
                                        {index === 0 && Location}
                                      </Col>

                                      <Col>
                                        {peop_det.company.country !== null && (
                                          <div>{peop_det.company.country}</div>
                                        )}
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              )
                            )}
                            {this.state.people_details.map(
                              (peop_det, index) => (
                                <div>
                                  {peop_det.company.sector !== null && (
                                    <Row className="tr1 paddingSpace">
                                      <Col sm={3}> {index === 0 && Sector}</Col>
                                      <Col>
                                        {peop_det.company.sector !== null && (
                                          <span>{peop_det.company.sector}</span>
                                        )}
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              )
                            )}
                            <Row className="tr2">
                              <Col sm={3}>Gender</Col>
                              <Col>{data.gender}</Col>
                            </Row>
                            <Row className="tr1">
                              <Col sm={3}>Biography</Col>

                              <Col>
                                <p
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: data.biography,
                                  }}
                                ></p>
                              </Col>
                            </Row>
                            <Row className="tr2">
                              <Col sm={3}>Internal Reference Number</Col>

                              <Col>{data.internalrefnumber + " "}</Col>
                            </Row>
                          </div>

                          {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default GPD_Detail;
