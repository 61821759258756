import React, { Component, Fragment } from "react";
import MA_SideBar from "../../component/global/M&A_Deals/M&A_SideBar";
import { Container, Row, Table, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import "./CompanyDatabase.css";
import imggraph from "../../../src/assets/images/graph.png";
import { Link } from "react-router-dom";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import GlobalCompanyDatabse from "../../component/global/companytable-data/gcd-sidebar";
import axios from "axios";
import Signin from "../../pages/signin/signinIndex";
import Fetch from "../../component/global/CommonComponent/useFetch";

import Chart from "react-google-charts";
const data = [
  ["Region", "Count of M&A deals", { role: "style" }, { role: "annotation" }],
  ["Americas", 6486, "gold", "6,486"],
  ["Europe", 3476, "silver", " 3,476"], // English color name
  ["Asia & Pacific", 2636, "color: #a5a5a5", "2636"], // CSS-style declaration
  ["Middle East", 787, "color: #e5e4e2", "787"], // CSS-style declaration
  ["Africa", 191, "#b87333", "191"], // RGB value
];
const data2 = [];
class CompanyDatabase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      count: 0,
      filterdata: "",
      region: [],
      sector: [],
    };
  }

  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    if (this.state.filterdata !== "") {
      this.handleData(this.state.filterdata);
    }
  };
  handleData = (filterdata) => {
    axios
      .get(
        "https://api.analyzemarkets.com/companymanagementprivate/?" + filterdata
      )
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ items: res.data.result, count: res.data.count });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };
  componentDidMount = () => {
    const arrayColumn = (arr, n) => arr.map((x) => x[n]);
    console.log(arrayColumn(data2, 2));
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
    if (this.state.filterdata !== "") {
      this.handleData(this.state.filterdata);
    }
    axios
      .get("https://api.analyzemarkets.com/regioncount/")
      .then((res) => {
        console.log(res.data, 23);
        var array = [];
        var len = res.data.regioncount.length;
        console.log(len, "length");
        var data3 = [];
        for (let i = 0; i < len; i++) {
          data3 = [
            [
              "Region",
              "Count of Company",
              { role: "style" },
              { role: "annotation" },
            ],
            [
              res.data.regioncount[0].region,
              parseInt(res.data.regioncount[0].GCDcount, 10),
              "gold",
              res.data.regioncount[0].GCDcount,
            ],
            [
              res.data.regioncount[1].region,
              parseInt(res.data.regioncount[1].GCDcount, 10),
              "blue",
              res.data.regioncount[1].GCDcount,
            ],
            [
              res.data.regioncount[2].region,
              parseInt(res.data.regioncount[2].GCDcount, 10),
              "silver",
              res.data.regioncount[2].GCDcount,
            ],
            [
              res.data.regioncount[3].region,
              parseInt(res.data.regioncount[3].GCDcount, 10),
              "#dc3d1f",
              res.data.regioncount[3].GCDcount,
            ],

            [
              res.data.regioncount[4].region,
              parseInt(res.data.regioncount[4].GCDcount, 10),
              "#9c499a",
              res.data.regioncount[4].GCDcount,
            ],
          ];
          console.log(data3, "Data4");
        }

        console.log(data2, "Region");

        this.setState({ region: data3 });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/regioncount/")
      .then((res) => {
        console.log(res.data, 23);
        var array = [];
        var len = res.data.sectorcount.length;
        console.log(len, "length");
        var data4 = [];
        for (let i = 0; i < len; i++) {
          data4 = [
            ["Sector", "Count of Company"],
            [
              res.data.sectorcount[3].sector,
              parseInt(res.data.sectorcount[3].GCDcount, 10),
            ],
            [
              res.data.sectorcount[1].sector,
              parseInt(res.data.sectorcount[1].GCDcount, 10),
            ],
            [
              res.data.sectorcount[4].sector,
              parseInt(res.data.sectorcount[4].GCDcount, 10),
            ],

            [
              res.data.sectorcount[0].sector,
              parseInt(res.data.sectorcount[0].GCDcount, 10),
            ],
            [
              res.data.sectorcount[2].sector,
              parseInt(res.data.sectorcount[2].GCDcount, 10),
            ],
          ];
          console.log(data4, "Data4");
        }

        console.log(data2, "Region");

        this.setState({ sector: data4 });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };
  render() {
    const con = this.state.count;
    console.log(con, "count");
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            {console.log(this.state.region, "Data3")}
            <Header></Header>
            <Container>
              <Navfilter />
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <div>
                <Row>
                  <Col className="w-30p">
                    <div className="widget">
                      <GlobalCompanyDatabse />
                    </div>
                  </Col>

                  <Col className="w-70p">
                    <div>
                      <div>
                        <h3 className="head-title">
                          <i class="fa fa-check"></i> &nbsp; Total Companies in
                          our Database: {con}
                        </h3>
                      </div>
                      {/* <div>
                    <h3 className="head-title">
                      <i class="fa fa-check"></i> &nbsp;Average Daily addition
                      of M&A Deals in our Database: 200{" "}
                    </h3>
                  </div> */}

                      <div className="">
                        <Row>
                          {this.state.region.length !== 0 && (
                            <Col>
                              {" "}
                              <div className="">
                                <Chart
                                  chartType="ColumnChart"
                                  width="auto"
                                  height="auto"
                                  loader={
                                    this.state.region.length === 0 && (
                                      <div>Loading Chart</div>
                                    )
                                  }
                                  options={{
                                    title: "Companies By Region",
                                    bubble: { textStyle: { fontSize: 18 } },
                                  }}
                                  data={this.state.region}
                                />{" "}
                              </div>
                            </Col>
                          )}
                          {this.state.sector.length !== 0 && (
                            <Col>
                              <Chart
                                width={"auto"}
                                height={"auto"}
                                chartType="PieChart"
                                loader={
                                  this.state.sector.length === 0 && (
                                    <div>Loading Chart</div>
                                  )
                                }
                                data={this.state.sector}
                                options={{
                                  title: "Companies By Sector",
                                  sliceVisibilityThreshold: 0.01, // 20%
                                  bubble: { textStyle: { fontSize: 18 } },
                                }}
                                rootProps={{ "data-testid": "1" }}
                              />
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default CompanyDatabase;
