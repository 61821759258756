import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "./re-deals.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import Linkify from "react-linkify";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class re_details extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    details: [],
    acquirer: [],
    jv_company: [],
    DealValuation: [],
    advisors: [],
    target: [],
    news: [],
  };
  handleBack = () => {
    this.props.history.goBack();
  };

  componentDidMount = () => {
    api({
      url:
        "https://api.analyzemarkets.com/realestatedeals_single/" +
        this.props.match.params.deal +
        "/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        details: res.data,
        acquirer: res.data.realestateacquirer,
        jv_company: res.data.company,
        target: res.data.realestatetarget,
        advisors: res.data.realestateadvisor,
        news: res.data.realestatenews,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
      const rememberMe = localStorage.getItem("rememberMe") === "true";
      const email = localStorage.getItem("email");
      const response = localStorage.getItem("response");
      const user = localStorage.getItem("email");
      const token = localStorage.getItem("token");
      const action = localStorage.getItem("action");
      this.setState({ email, rememberMe, response, token, action, user });
    });

    // $(document).ready(function() {
    //   $("a[href*=#]").bind("click", function(e) {
    //     e.preventDefault(); // prevent hard jump, the default behavior

    //     var target = $(this).attr("href"); // Set the target as variable

    //     // perform animated scrolling by getting top-position of target-element and set it as scroll target
    //     $("html, body")
    //       .stop()
    //       .animate(
    //         {
    //           scrollTop: $(target).offset().top
    //         },
    //         600,
    //         function() {
    //           window.location.hash = target; //attach the hash (#jumptarget) to the pageurl
    //         }
    //       );

    //     return false;
    //   });
    // });

    // $(window)
    //   .scroll(function() {
    //     var scrollDistance = $(window).scrollTop();

    //     // Show/hide menu on scroll
    //     if (scrollDistance >= 1800) {
    //       $(".navigation").css({
    //         position: "fixed",
    //         bottom: 200
    //       });
    //     } else {
    //       $(".navigation").css({
    //         position: "fixed",
    //         bottom: 12
    //       });
    //     }

    //     // Assign active class to nav links while scolling
    //     $(".").each(function(i) {
    //       if ($(this).position().top <= scrollDistance) {
    //         $(".navigation a.active").removeClass("active");
    //         $(".navigation a")
    //           .eq(i)
    //           .addClass("active");
    //       }
    //     });
    //   })
    //   .scroll();
  };

  render() {
    const data = this.state.details;
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );

    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <Fragment>
        <Header></Header>
        <Navfilter id="sector-desktop"></Navfilter>
        <Container>
          <Button
            onClick={this.handleBack}
            variant="outline-light"
            className="MarginRight padDown"
          >
            <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
          </Button>
          <Row>
            <Col className="">
              <Row className="justify-content-md-center">
                <Col xs sm={12} class="">
                  <Row>
                    <Col sm={5}>
                      <div class=" hero" id="2">
                        <h3 className="UpperCase">Acquirer</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="250">Acquirer Company</td>
                              <td>
                                {this.state.acquirer.map((item, index) => (
                                  <div className="paddingRight">
                                    {item.company_name.map((acquirer, key) => (
                                      <div>
                                        {acquirer.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              acquirer.api_url +
                                              "/"
                                            }
                                          >
                                            <u className="underline1">
                                              {acquirer.name}
                                            </u>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline1">
                                              {acquirer.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td width="250">Acquirer Parent</td>
                              <td>
                                {this.state.acquirer.map((item, index) => (
                                  <div className="paddingRight">
                                    {item.acquirer_parent.map(
                                      (acquirer_par, key) => (
                                        <div>
                                          {acquirer_par.api_url != null ? (
                                            <Link
                                              to={
                                                "/en/global-company-database-details/" +
                                                acquirer_par.api_url +
                                                "/" +
                                                this.state.token
                                              }
                                            >
                                              <u className="underline1">
                                                {acquirer_par.name}
                                              </u>
                                            </Link>
                                          ) : (
                                            <Link>
                                              <u className="underline1">
                                                {acquirer_par.name}
                                              </u>
                                            </Link>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <br></br>
                      <div class="" id="3">
                        <h3 className="UpperCase">Target</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="250">Target Company</td>
                              <td>
                                {this.state.target.map((item, index) => (
                                  <div className="paddingRight">
                                    {item.company_name.map((target, key) => (
                                      <div>
                                        {target.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              target.api_url +
                                              "/"
                                            }
                                          >
                                            <u className="underline1">
                                              {target.name}
                                            </u>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline1">
                                              {target.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                                {/* 
                                {this.state.target.map((targets, index) => (
                                  <span className="paddingRight">
                                    {targets.company_name}
                                  </span>
                                ))}
                                */}
                              </td>
                            </tr>

                            <tr>
                              <td width="250">Seller</td>
                              <td>
                                {this.state.target.map((item, index) => (
                                  <div className="paddingRight">
                                    {item.seller.map((target_sell, key) => (
                                      <div>
                                        {target_sell.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              target_sell.api_url +
                                              "/"
                                            }
                                          >
                                            <u className="underline1">
                                              {target_sell.name}
                                            </u>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline1">
                                              {target_sell.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                                {/* 
                                {this.state.target.map((targets, index) => (
                                  <span className="paddingRight">
                                    {targets.company_name}
                                  </span>
                                ))}
                                */}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <br></br>
                      <div class="" id="5">
                        <h3 className="UpperCase">Advisors</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            {this.state.advisors.map((advisor, index) => (
                              <tr>
                                <td width="250">
                                  <div>{advisor.title}</div>
                                </td>
                                <td>
                                  <div className="paddingRight">
                                    {advisor.companyname.map((adv, key) => (
                                      <div>
                                        {adv.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              adv.api_url +
                                              "/"
                                            }
                                          >
                                            <u className="underline1">
                                              {adv.name}
                                            </u>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline1">
                                              {adv.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <br></br>
                      <div class="" id="6">
                        <h3 className="UpperCase">News</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            {this.state.news.map((item, index) => (
                              <tr>
                                <td width="250" className="space">
                                  <div>{item.news}</div>
                                </td>
                                <Linkify
                                  componentDecorator={componentDecorator}
                                >
                                  <td className="word-wrap">
                                    <div>{item.value + " "}</div>
                                  </td>
                                </Linkify>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col sm={7}>
                      <div class=" hero" id="1">
                        <h3 className="UpperCase">Deal Information</h3>
                        <Table className="tblnoline" size="lg" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="150">Deal</td>

                              <td>{data.deal}</td>
                            </tr>

                            <tr>
                              <td>Deal Description</td>
                              <td>
                                <p
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: data.description,
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>Deal Announced Date</td>

                              <td>
                                <Moment format="DD MMM YYYY">
                                  {data.announced_reported}
                                </Moment>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" className="space">
                                Deal Official Confirmation
                              </td>

                              <td> {data.dealofficialconfirmation}</td>
                            </tr>

                            <tr>
                              <td>Deal Type</td>

                              <td>{data.deal_type}</td>
                            </tr>

                            <tr>
                              <td width="150" className="space">
                                Deal Value
                              </td>

                              <td>{data.deal_value}</td>
                            </tr>
                            <tr>
                              <td>Deal Status</td>

                              <td>{data.deal_status}</td>
                            </tr>
                            <tr>
                              <td className="space">Completion Date</td>

                              <td>{data.completion_date}</td>
                            </tr>
                            <tr>
                              <td className="space">Deal Geography</td>

                              <td>{data.deal_geography}</td>
                            </tr>
                            <tr>
                              <td className="space">Deal Funding Mode</td>

                              <td>{data.property_deal_funding_mode}</td>
                            </tr>
                            <tr>
                              <td className="space">Property Region</td>

                              <td>{data.region}</td>
                            </tr>
                            <tr>
                              <td className="space">Property Sub-Region</td>

                              <td>{data.sub_region}</td>
                            </tr>
                            <tr>
                              <td className="space">Property Country</td>

                              <td>{data.country}</td>
                            </tr>
                            <tr>
                              <td className="space">Property Address</td>

                              <td>{data.property_address}</td>
                            </tr>
                            <tr>
                              <td className="space">Property Type</td>

                              <td>{data.propertytype}</td>
                            </tr>
                            <tr>
                              <td className="space">Property Sub-Type</td>

                              <td>{data.propertysubtype}</td>
                            </tr>
                            <tr>
                              <td className="space">Portfolio Investment</td>

                              <td>{data.portfolio_investment}</td>
                            </tr>
                            <tr>
                              <td className="space">
                                No of Portfolio Properties
                              </td>

                              <td>{data.number_of_portfolio_properties}</td>
                            </tr>
                            <tr>
                              <td className="space"> Property Occupancy</td>

                              <td>{data.property_occupancy}</td>
                            </tr>
                            <tr>
                              <td className="space">Propert Return</td>

                              <td>{data.property_return}</td>
                            </tr>
                            <tr>
                              <td className="space">Property Size</td>

                              <td>{data.property_size}</td>
                            </tr>
                            <tr>
                              <td className="space">Sale Type</td>

                              <td>{data.sale_type}</td>
                            </tr>
                            <tr>
                              <td className="space">Tenants</td>

                              <td>{data.tenants}</td>
                            </tr>
                            <tr>
                              <td className="space">Property Lease Period</td>

                              <td>{data.property_lease}</td>
                            </tr>
                            <tr>
                              <td className="space">Reference</td>

                              <td className="word-wrap">
                                <Linkify
                                  componentDecorator={componentDecorator}
                                  className="word-wrap"
                                >
                                  {data.reference}
                                </Linkify>
                              </td>
                            </tr>
                            <tr>
                              <td className="space">Deal Reference Number</td>

                              <td>{data.dealinternalreferencenumber}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default re_details;
