import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import Autocomplete from "react-autocomplete";
import {
  Row,
  Table,
  Col,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
//import upload from "./upload.php";
import RealEstateSidebar from "../../component/global/create-deal-sidebar/real-estate-sidebar";
import $ from "jquery";
//import Autocomplete from "material-ui/AutoComplete";
//import { makeStyles } from "material-ui/styles";
import { Editor } from "@tinymce/tinymce-react";
//import window from "react-global";
import "../create-deal/create-deal.css";
import { Link } from "react-router-dom";

import {
  matchCompany,
  matchSector,
  matchGeography,
} from "../../component/global/investment-intellegence-screener/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Signin from "../../pages/signin/signinIndex";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
const animatedComponents = makeAnimated();

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});
class Real_Estate_Target extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.onChangePage = this.onChangePage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.changeTermMultiSelect = this.changeTermMultiSelect.bind(this);
    this.changeTermMultiSelects = this.changeTermMultiSelects.bind(this);
    this.refreshButton = this.refreshButton.bind(this);

    this.state = {
      dealheadline: "",
      propertycity: "",
      dealcodename: "",
      refresh: "",
      sub_type: [],
      prop_type: [],
      regions: [],
      //editorState: EditorState.createEmpty(),
      user: "",
      lease_type: [],
      itemcurrency: [],
      name_info: "",
      title: "",
      emailentry: "",
      email: "",
      contactnumber: "",
      youauthorizedcreatedealbehalfcompany: "",
      items: [],
      company: [],
      sector_data: "",
      result: false,
      company_details: [],
      country: [],
      sector: [],
      sector_name: "",
      filter: "",
      subIndustry: [],
      old_route: "",
      pageSize: 25,
      count: 0,
      file: "",
      propertyregion: "",
      propertycountry: "",
      propertytype: "",
      propertysubtype: "",
      propertyaddress: "",
      propertyURL: "",
      propertysize: "",
      propertydata: "",
      numberofbuildings: "",
      dealbriefdescription: "",
      askingprice: "",
      numberofunits: "",
      anchortenants: "",
      yearbuilt: "",
      WAULT: "",
      leasetype: "",
      currentoccupancy: "",
      grossyield: "",
      netinitialyieldcaprate: "",
      file_teaser: [],
      fileother: "",
      filepicture: "",
      file_other: [],
      reportedDate: new Date(),
      reported_date: "",
    };
    //this.onChange = (editorState) => this.setState({ editorState });
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  refreshButton = () => {
    this.setState({
      refresh: true,
    });
  };
  notify = () => {
    toast.info("Your Deal is Succesfully Submitted", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleState = () => {
    this.setState({
      result: false,
    });
  };
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  changeTermMultiSelect = (sector_name) => {
    const state = this.state;
    state.sector_name = [];
    sector_name.forEach((option) => {
      state.sector_name.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({
      sector_name: state.sector_name,
      sector_data: null,
    });
    console.log(sector_name);
  };
  changeTermMultiSelects = (companysubindustry) => {
    const state = this.state;
    state.companysubindustry = [];
    companysubindustry.forEach((option) => {
      state.companysubindustry.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({ companysubindustry: state.companysubindustry });
    console.log(companysubindustry);
  };
  handleEditorChange = (e) => {
    this.setState({
      dealbriefdescription: e.target.getContent(),
    });
    console.log("Content was updated:", this.state.dealbriefdescription);
  };
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      file_teaser: event.target.files.name,
      file_other: event.target.files.name,
    });
    console.log(len, "length");
    console.log("Selected file:", this.state.file);

    //.log("Selected file:", event.target.files[0]);
  }
  onSubmit(e) {
    e.preventDefault();
    const { dealheadline } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set("dealheadline", dealheadline);
    bodyFormData.set("dealcodename", this.state.dealcodename);
    bodyFormData.set("propertyregion", this.state.propertyregion);
    bodyFormData.set("propertycity", this.state.propertycity);
    bodyFormData.set("propertycountry", this.state.propertycountry);
    bodyFormData.set("propertytype", this.state.propertytype);
    bodyFormData.set("propertysubtype", this.state.propertysubtype);
    bodyFormData.set("propertyaddress", this.state.propertyaddress);
    bodyFormData.set("askingprice", this.state.askingprice);
    bodyFormData.set("propertyURL", this.state.propertyURL);
    bodyFormData.set("propertysize", this.state.propertysize);
    bodyFormData.set("numberofbuildings", this.state.numberofbuildings);
    bodyFormData.set("numberofunits", this.state.numberofunits);
    bodyFormData.set("anchortenants", this.state.anchortenants);
    bodyFormData.set("yearbuilt", this.state.yearbuilt);
    bodyFormData.set("WAULT", this.state.WAULT);
    bodyFormData.set("leasetype", this.state.leasetype);
    bodyFormData.set("currentoccupancy", this.state.currentoccupancy);
    bodyFormData.set("grossyield", this.state.grossyield);
    bodyFormData.set(
      "netinitialyieldcaprate",
      this.state.netinitialyieldcaprate
    );
    bodyFormData.set("dealbriefdescription", this.state.dealbriefdescription);
    bodyFormData.set("name", this.state.name_info);
    bodyFormData.set("title", this.state.title);
    bodyFormData.set("email", this.state.emailentry);
    bodyFormData.set("contactnumber", this.state.contactnumber);
    bodyFormData.set(
      "youauthorizedcreatedealbehalfcompany",
      this.state.youauthorizedcreatedealbehalfcompany
    );
    bodyFormData.set("teaserresentation", this.state.file);
    bodyFormData.set("otherdocumentstoupload", this.state.fileother);
    bodyFormData.set("projectpicturesvideotoupload", this.state.filepicture);

    bodyFormData.set("posted_by", this.state.user);

    axios
      .post("https://api.analyzemarkets.com/gdmrealestateform/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          this.notify();
          this.setState({
            result: true,
          });
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };
  componentDidMount = () => {
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });
    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ sector: res.data.results });
      }
    });
    api({
      url: "https://api.analyzemarkets.com/regman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ regions: res.data.results });
      }
    });
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ company: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/reportingcurrency/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ itemcurrency: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    api({
      url: "https://api.analyzemarkets.com/companysubindustry/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ subIndustry: res.data });
      }
    });
    api({
      url: "https://api.analyzemarkets.com/propertytype/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ prop_type: res.data });
      }
    });
    axios
      .get("https://api.analyzemarkets.com/subpropertytype/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ sub_type: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/gdmleasetype/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ lease_type: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };

  render() {
    //const classes = useStyles();

    const { data } = this.state;
    const options = [
      { title: "Energy" },
      { title: "Material" },
      { title: "Industrail" },
      { title: "Type" },
    ];
    const sec = this.state.sector;
    const subIn = this.state.subIndustry;
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Navfilter />
              <Row>
                <Col className="w-30p col-sm-3 p-4">
                  <div className="widget">
                    <RealEstateSidebar />
                  </div>
                </Col>

                <Col className="w-80p p-4">
                  <div>
                    <div class="container">
                      <form onSubmit={this.onSubmit}>
                        <div className="iis greyborder2px">
                          <h4 className="text-left">Create a Deal</h4>
                          <div className="pdg15">
                            <fieldset>
                              <legend>
                                <b className="upercase">About Deal</b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="dealheadline"
                                    type="text"
                                    required
                                    placeholder="Deal Headline* (Max 100 characters)
                                e.g. High Yielding Office Building Investment Opportunity in London"
                                    onChange={this.changeTerm}
                                    maxLength="100"
                                  />
                                </Col>
                              </Row>

                              <Row className="">
                                <Col>
                                  <Form.Control
                                    name="dealcodename"
                                    type="text"
                                    required
                                    placeholder="Deal Code Name *e.g. Project Sun"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="propertyregion"
                                    onChange={this.changeTerm}
                                    placeholder="Property Region*"
                                    rows="3"
                                    required
                                  >
                                    <option value="" className="">
                                      Property Region{" "}
                                    </option>
                                    {this.state.regions.map((item, i) => (
                                      <option key={i} value={item.region}>
                                        {item.region}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                <Col>
                                  <div className="form-group autoc">
                                    <Form.Control
                                      as="select"
                                      name="propertycountry"
                                      onChange={this.changeTerm}
                                      placeholder="Property Country*"
                                      rows="3"
                                      required
                                    >
                                      <option value="" className="">
                                        Property Country*{" "}
                                      </option>
                                      {this.state.country.map((item, i) => (
                                        <option key={i} value={item.country}>
                                          {item.country}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="">
                                <Col>
                                  <Form.Control
                                    name="propertycity"
                                    type="text"
                                    required
                                    placeholder="Property City"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Col>
                                  <div className="form-group autoc">
                                    <Autocomplete
                                      value={this.state.propertytype}
                                      inputProps={{
                                        id: "company-autocomplete",
                                        placeholder: "Property Type",
                                      }}
                                      wrapperStyle={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                      items={this.state.prop_type}
                                      shouldItemRender={matchGeography}
                                      getItemValue={(item) => item.type}
                                      onChange={(event, propertytype) =>
                                        this.setState({
                                          propertytype,
                                          propertydata: null,
                                        })
                                      }
                                      onSelect={(propertytype) =>
                                        this.setState({
                                          propertytype,
                                          propertydata: propertytype,
                                        })
                                      }
                                      renderMenu={(children) => (
                                        <div className="menu c-dropdown">
                                          {children}
                                        </div>
                                      )}
                                      renderItem={(item, isHighlighted) => (
                                        <div
                                          className={`item ${
                                            isHighlighted
                                              ? "item-highlighted"
                                              : ""
                                          }`}
                                          key={item.type}
                                        >
                                          {item.type}
                                        </div>
                                      )}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  {/* <Select
                                closeMenuOnSelect={false}
                                placeholder={<div>Select Sub-Industry*</div>}
                                components={animatedComponents}
                                name="companysubindustry"
                                onChange={this.changeTermMultiSelects}
                                isMulti
                                options={subIn}
                              /> */}

                                  <Form.Control
                                    as="select"
                                    name="propertysubtype"
                                    placeholder="Property Sub-type*"
                                    onChange={this.changeTerm}
                                    required
                                  >
                                    <option value="">
                                      {" "}
                                      Property Sub-type*
                                    </option>
                                    {this.state.sub_type.map((item, i) => (
                                      <option
                                        key={i}
                                        value={
                                          this.state.propertydata ===
                                          item.propertytype
                                            ? item.type
                                            : "null"
                                        }
                                      >
                                        {this.state.propertydata ===
                                        item.propertytype
                                          ? item.type
                                          : "null"}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="propertyaddress"
                                    onChange={this.changeTerm}
                                    placeholder="Property Address"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="askingprice"
                                    type="number"
                                    required
                                    onChange={this.changeTerm}
                                    placeholder="Property Asking Price (in USD mn)"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="propertyURL"
                                    onChange={this.changeTerm}
                                    placeholder="Property URL "
                                  />
                                </Col>
                              </Row>
                              <div className="form-group">
                                <Row className="">
                                  <Col>
                                    <Editor
                                      apiKey="l7d3ab4ybjfthcy2c4ya6vsj9rlub27d3sdqk433oyis6ild"
                                      init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                          "advlist autolink lists link image",
                                          "charmap print preview anchor help",
                                          "searchreplace visualblocks code",
                                          "insertdatetime media table paste wordcount",
                                        ],
                                        toolbar:
                                          "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help",
                                      }}
                                      onChange={this.handleEditorChange}
                                    />

                                    {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                  as="textarea"
                                  name="companydealbriefdescription"
                                  rows="7"
                                  maxLength="100"
                                  onChange={this.changeTerm}
                                  placeholder="Company/Deal Brief Description* (Maximum 100 words)"
                                />
                              </Form.Group> */}
                                  </Col>{" "}
                                </Row>
                              </div>
                              <legend>
                                {" "}
                                <b className="upercase">
                                  Key Investment Highlights{" "}
                                </b>
                              </legend>

                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="propertysize"
                                    onChange={this.changeTerm}
                                    placeholder="Property size (sq m)"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="numberofbuildings"
                                    onChange={this.changeTerm}
                                    placeholder="Number of Buildings"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="numberofunits"
                                    onChange={this.changeTerm}
                                    placeholder="Number of Units"
                                  />
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="anchortenants"
                                    onChange={this.changeTerm}
                                    placeholder="Anchor Tenants"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="leasetype"
                                    placeholder="Lease Type"
                                    onChange={this.changeTerm}
                                    required
                                  >
                                    <option value=""> Lease Type*</option>
                                    {this.state.lease_type.map((item, i) => (
                                      <option key={i} value={item.type}>
                                        {item.type}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="yearbuilt"
                                    onChange={this.changeTerm}
                                    placeholder="Year Built"
                                  />
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="WAULT"
                                    onChange={this.changeTerm}
                                    placeholder="WAULT"
                                  />
                                </Col>

                                <Col>
                                  <Form.Control
                                    name="currentoccupancy"
                                    onChange={this.changeTerm}
                                    placeholder="Current Occupancy"
                                  />
                                </Col>

                                <Col>
                                  <Form.Control
                                    name="grossyield"
                                    type="number"
                                    step="any"
                                    onChange={this.changeTerm}
                                    placeholder="Gross Yield (%)"
                                  />
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col sm={4}>
                                  <Form.Control
                                    name="netinitialyieldcaprate"
                                    type="number"
                                    step="any"
                                    onChange={this.changeTerm}
                                    placeholder="Net Initial Yield / Cap Rate (%)"
                                  />
                                </Col>
                              </Row>

                              <legend>
                                <b className="upercase">
                                  Requirement Documents
                                </b>
                              </legend>
                              <div className="form-group">
                                <label> Teaser/Presentation</label>

                                <Row>
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="file"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                </Row>
                              </div>
                              <div className="form-group">
                                <label> Other documents to upload:</label>

                                <Row>
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="fileother"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                </Row>
                              </div>
                              <div className="form-group">
                                <label>
                                  {" "}
                                  Project Pictures/Video to upload:
                                </label>

                                <Row>
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="filepicture"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                </Row>
                              </div>

                              <legend>
                                <b className="upercase">
                                  Contact Information For Potential Investors
                                </b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="name_info"
                                    type="text"
                                    placeholder="Name*"
                                    required
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="title"
                                    type="text"
                                    placeholder="Title*"
                                    required
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    name="emailentry"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                    required
                                    onChange={this.changeTerm}
                                  />
                                </Col>

                                <Col>
                                  <Form.Control
                                    name="contactnumber"
                                    type="tel"
                                    required
                                    placeholder="Contact number*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <label>
                                    Are you authorized to create this deal on
                                    behalf of the Company*:{" "}
                                  </label>
                                  <Form.Control
                                    as="select"
                                    name="youauthorizedcreatedealbehalfcompany"
                                    required
                                    onChange={this.changeTerm}
                                    // placeholder="Are you Authorized to create this deal"
                                  >
                                    <option value="">Select*</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col sm={3}>
                                  <Button
                                    variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                    type="submit"
                                    size="sm"
                                  >
                                    Publish Deal
                                  </Button>
                                </Col>
                                <Col>
                                  <Link
                                    className="btn btn-blue mt-4 mb-4 shadow"
                                    onClick={this.refreshButton}
                                  >
                                    Clear
                                  </Link>
                                </Col>
                              </Row>
                              {this.state.refresh && window.location.reload()}

                              <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                              />
                            </fieldset>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default Real_Estate_Target;
