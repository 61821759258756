import React, { Component } from "react";
import Logo from "../../component/global/logo/logo1";
import { Link } from "react-router-dom";
class Unsubscribe1 extends Component {
  render() {
    return (
      <div>
        <div className="posac">
          <Logo />
          <div class="jumbotron text-center">
            <h1 class="display-5">You have Already Unsubscribed Newsletter</h1>

            <hr />

            <p class="lead mt-4">
              <Link
                class="btn btn-primary btn-sm btn-blue"
                to="/"
                role="button"
              >
                Continue to homepage
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Unsubscribe1;
