import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap/";
import "./about.css";
import { Helmet } from "react-helmet";
import BusinesIntellent from "../../assets/images/BUSINESS-INTELLIGENT.svg";
import GlobalIntellent from "../../assets/images/GLOBAL-INFRASTRUCTURE.svg";
import DataMining from "../../assets/images/DATA-MINING.svg";
import Billionaire from "../../assets/images/BILLIONAIRE.svg";
import Saas from "../../assets/images/SAAS.svg";
import DeepLearning from "../../assets/images/DEEP-LEARNING.svg";
import Header from "../../component/global/header/index";
class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      result: false,
      action: true,
      is_open: false,
      company: [],
    };
  }

  componentDidMount = () => {
    console.log(111);
    console.log(101);
    window.scrollTo(0, 0);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>About AnalyzeMarkets</title>
            <meta
              name="description"
              content="AnalyzeMarkets is a financial data and technology company, enabling businesses, investors, and investment professionals to make timely, informed decisions."
            />
            <meta property="og:title" content="About AnalyzeMarkets" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="AnalyzeMarkets is a financial data and technology company, enabling businesses, investors, and investment professionals to make timely, informed decisions."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="About AnalyzeMarkets" />

            <meta
              itemprop="description"
              content="AnalyzeMarkets is a financial data and technology company, enabling businesses, investors, and investment professionals to make timely, informed decisions."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="About AnalyzeMarkets" />

            <meta
              name="twitter:description"
              content="AnalyzeMarkets is a financial data and technology company, enabling businesses, investors, and investment professionals to make timely, informed decisions."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>

          <Row>
            <Col md={12}>
              <hr />
              <h2 className="mb-4 font-22">
                AnalyzeMarkets is an AI Global Financial Data and Investment
                Intelligence Platform, <br></br> Creating an investment
                ecosystem for the next generation, helps navigate the rapidly
                changing financial markets, <br></br> Enabling businesses,
                investors, and investment professionals to make timely and
                informed decisions.
              </h2>
              <ul className="abtlist">
                <li>
                  <img src={BusinesIntellent} className="img-fluid" alt="" />
                  <p>
                    <span>Global Investment Intelligence Platform</span> for
                    investment companies, private equity firms, venture capital
                    funds, real estate investment firms, sovereign wealth funds,
                    family offices, pension funds, banks & insurance companies,
                    entrepreneurs, consulting & advisory firms, law firms,
                    corporates & SMEs, and academia
                  </p>
                </li>
                <li>
                  <img src={GlobalIntellent} className="img-fluid" alt="" />
                  <p>
                    <span>Global Geographical Coverage</span> including all
                    major developed, emerging, and frontier economies across the
                    world{" "}
                  </p>
                </li>
                <li>
                  <img src={DataMining} className="img-fluid" alt="" />
                  <p>
                    <span>Intelligence and Data</span> on public & private
                    companies, M&A deals, real estate deals, venture capital
                    deals, joint-venture deals, debt deals, fundraising
                    activities and IPOs
                  </p>
                </li>
                <li>
                  <img src={Billionaire} className="img-fluid" alt="" />
                  <p>
                    <span>
                      Access to Global Strategic & Financials Investors
                    </span>
                    , global professionals and deals marketplace for qualified
                    investors, entrepreneurs, lenders, financial institutions,
                    advisors, and businesses.
                  </p>
                </li>
                <li>
                  <img src={Saas} className="img-fluid" alt="" />
                  <p>
                    <span>Tech-enabled SaaS Platform</span> for investment
                    intelligence, deal origination, fundraising, business
                    development, due diligence, and on-demand research &
                    analysis among others
                  </p>
                </li>
                <li>
                  <img src={DeepLearning} className="img-fluid" alt="" />
                  <p>
                    <span>All Sectors Coverage</span> including consumer staple
                    & disc., industrials, financials, energy, health care, IT,
                    communication and real estate among others
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default About;
