import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "./jv-deals.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import Linkify from "react-linkify";
import Signin from "../../pages/signin/signinIndex";
import { Helmet } from "react-helmet";
import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class jv_details1 extends Component {
  constructor(props) {
    super(props);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    details: [],
    acquirer: [],
    email: "",
    filterdata: "",
    jv_company: [],
    DealValuation: [],
    advisors: [],
    news: [],
  };

  componentDidMount = () => {
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/gddjvdeals_single/" +
        this.props.match.params.deal +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, 63333);
      this.setState({
        details: res.data,
        acquirer: res.data.jvpartnersAcquirer,
        jv_company: res.data.company,
        advisors: res.data.JV_dealsAdvisors,
        news: res.data.JVdelasNews,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const data = this.state.details;
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );

    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Fetch applyFilters={this.handleFilters}></Fetch>

            <Navfilter id="sector-desktop"></Navfilter>
            <Container>
              <Helmet title={data.deal}>
                <meta
                  name="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta property="og:title" content={data.deal} />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta name="og:description" content={data.description} />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta itemprop="description" content={data.description} />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>

              <Button
                onClick={this.handleBack}
                variant="outline-light"
                className="MarginRight padDown"
              >
                <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
              </Button>
              <Row>
                <Col className="">
                  <Row className="justify-content-md-center">
                    <Col xs sm={12} class="">
                      <Row>
                        {this.state.acquirer.length !== 0 &&
                        this.state.advisors.length !== 0 &&
                        this.state.news.length !== 0 ? (
                          <Col sm={5}>
                            <div class=" hero" id="2">
                              {this.state.acquirer.map((item, index) => (
                                <h3 className="UpperCase">
                                  {index === 0 && "JV PARTNERS"}
                                </h3>
                              ))}
                              <Table
                                className="tblnoline"
                                size="sm"
                                responsive="lg"
                              >
                                <tbody>
                                  {this.state.acquirer.map((item, index) => (
                                    <tr>
                                      <td width="250" className="space">
                                        JV Partner
                                      </td>
                                      <td>
                                        <div className="paddingRight">
                                          {item.acquirer_parent.map(
                                            (acquirer, key) => (
                                              <div>
                                                {acquirer.api_url != null ? (
                                                  <Link
                                                    to={
                                                      "/en/global-company-database-details/" +
                                                      acquirer.api_url +
                                                      "/"
                                                    }
                                                  >
                                                    <u className="underline1">
                                                      {acquirer.name}
                                                    </u>
                                                  </Link>
                                                ) : (
                                                  <Link>
                                                    <u className="underline1">
                                                      {acquirer.name}
                                                    </u>
                                                  </Link>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                  {this.state.acquirer.map((item, index) => (
                                    <tr>
                                      <td width="250" className="space">
                                        JV Partner Parent
                                      </td>
                                      <td>
                                        <div className="paddingRight">
                                          {item.acquirer_parent.map(
                                            (acquirer_par, key) => (
                                              <div>
                                                {acquirer_par.api_url !=
                                                null ? (
                                                  <Link
                                                    to={
                                                      "/en/global-company-database-details/" +
                                                      acquirer_par.api_url +
                                                      "/"
                                                    }
                                                  >
                                                    <u className="underline1">
                                                      {acquirer_par.name}
                                                    </u>
                                                  </Link>
                                                ) : (
                                                  <Link>
                                                    <u className="underline1">
                                                      {acquirer_par.name}
                                                    </u>
                                                  </Link>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            <br></br>
                            <div class="" id="5">
                              {this.state.advisors.map((item, index) => (
                                <h3 className="UpperCase">
                                  {index === 0 && "Advisors"}
                                </h3>
                              ))}

                              <Table
                                className="tblnoline"
                                size="sm"
                                responsive="lg"
                              >
                                <tbody>
                                  {this.state.advisors.map((advisor, index) => (
                                    <tr>
                                      <td width="250" className="space">
                                        <div>{advisor.title}</div>
                                      </td>
                                      <td>
                                        <div className="paddingRight">
                                          {advisor.companyname.map(
                                            (adv, key) => (
                                              <div>
                                                {adv.api_url != null ? (
                                                  <Link
                                                    to={
                                                      "/en/global-company-database-details/" +
                                                      adv.api_url +
                                                      "/"
                                                    }
                                                  >
                                                    <u className="underline1">
                                                      {adv.name}
                                                    </u>
                                                  </Link>
                                                ) : (
                                                  <Link>
                                                    <u className="underline1">
                                                      {adv.name}
                                                    </u>
                                                  </Link>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            <br></br>
                            <div class="" id="6">
                              {this.state.news.map((item, index) => (
                                <h3 className="UpperCase">
                                  {index === 0 && "News"}
                                </h3>
                              ))}
                              <Table
                                className="tblnoline"
                                size="sm"
                                responsive="lg"
                              >
                                <tbody>
                                  {this.state.news.map((item, index) => (
                                    <tr>
                                      <td width="250" className="space">
                                        <div>{item.news}</div>
                                      </td>
                                      <Linkify
                                        componentDecorator={componentDecorator}
                                      >
                                        <td className="word-wrap">
                                          <div>{item.value + " "}</div>
                                        </td>
                                      </Linkify>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col sm={7}>
                          <div class=" hero" id="1">
                            <h3 className="UpperCase">Deal Information</h3>
                            <Table
                              className="tblnoline"
                              size="lg"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td width="150">Deal</td>

                                  <td>{data.deal}</td>
                                </tr>

                                <tr>
                                  <td>Deal Description</td>
                                  <td>
                                    <p
                                      className=""
                                      dangerouslySetInnerHTML={{
                                        __html: data.description,
                                      }}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td>Deal Announced Date</td>

                                  <td>
                                    <Moment format="DD MMM YYYY">
                                      {data.announced_reported}
                                    </Moment>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="150" className="space">
                                    JV Formation Date
                                  </td>

                                  <td>{data.formation_date}</td>
                                </tr>
                                <tr>
                                  <td>Deal Status</td>

                                  <td>{data.deal_status}</td>
                                </tr>
                                <tr>
                                  <td>JV Company</td>
                                  <td>
                                    {this.state.jv_company.map(
                                      (fundname, key) => (
                                        <div>
                                          {fundname.api_url != null ? (
                                            <Link
                                              to={
                                                "/en/global-company-database-details/" +
                                                fundname.api_url +
                                                "/" +
                                                this.state.token
                                              }
                                            >
                                              <u className="underline1">
                                                {fundname.name}
                                              </u>
                                            </Link>
                                          ) : (
                                            <Link>
                                              <u className="underline1">
                                                {fundname.name}
                                              </u>
                                            </Link>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>JV Region</td>

                                  <td>{data.region}</td>
                                </tr>

                                <tr>
                                  <td>JV Sub-region</td>
                                  <td>{data.sub_region}</td>
                                </tr>

                                <tr>
                                  <td>JV Country</td>

                                  <td>{data.country}</td>
                                </tr>

                                <tr>
                                  <td>JV Sector</td>

                                  <td>{data.sector}</td>
                                </tr>

                                <tr>
                                  <td>JV Shareholding</td>
                                  <td>{data.shareholding}</td>
                                </tr>
                                <tr>
                                  <td>JV Size </td>
                                  <td>{data.size}</td>
                                </tr>
                                <tr>
                                  <td>JV Completion Date </td>
                                  <td>{data.completion_date}</td>
                                </tr>

                                <Linkify>
                                  <tr>
                                    <td>Reference</td>
                                    <td className="word-wrap">
                                      <Linkify
                                        componentDecorator={componentDecorator}
                                        className="word-wrap"
                                      >
                                        {data.reference}
                                      </Linkify>
                                    </td>
                                  </tr>
                                </Linkify>

                                <tr>
                                  <td>Deal Internal Reference:</td>
                                  <td>{data.dealinternalreferencenumber}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>

                          {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default jv_details1;
