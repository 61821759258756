import React, { Component, Fragment } from "react";
import Followus from "../../component/global/Followus_wire/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import Real_Estate_Screener from "../../component/global/M&ADealsSearch/real-estate-screener";
import Iis from "../../component/global/investment-intellegence-screener/investment-Intelligence-screener";
import { Container, Grid, Row, Col, Table } from "react-bootstrap/";
import MA_SideBar from "../../component/global/realestate-deals-QL/realestate-deals-ql";
import CompanylogoS from "../../assets/images/clogo-s.jpg";
import CompanylogoB from "../../assets/images/clogo-b.jpg";
import { Link } from "react-router-dom";
import "../../pages/press-release/press-release.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import Moment from "react-moment";
import Pagination from "../../component/global/pagination/index";
import { Helmet } from "react-helmet";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Fetch from "../../component/global/CommonComponent/useFetch";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Real_Estate_Deals_Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giw: [],
      search: false,
      nosearch: false,
      status: false,
      madeals: [],
      items: [],
      exampleItems: [],
      company: [],
      counts: 0,
      page: 0,
      filter: "",
      filterdata: "",
      current: 0,
      country: [],
      old_route: "",
      pageSize: 25,
      count: 0,
      is_first: true,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }

  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.getData(this.state.old_route, this.state.filter, filterdata, 0, true);
  };

  handleFilter = (filter) => {
    console.log(filter, 31);
    this.setState({
      filter: filter,
      is_filter: true,
    });
    //this.getData(this.state.old_route, filter, 0, true);
    this.getData(this.state.old_route, filter, this.state.filterdata, 0, true);
  };

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      // this.getData("", this.state.filter, page, false);
      if (this.state.filterdata !== "") {
        this.getData("", this.state.filter, this.state.filterdata, page, false);
      }

      console.log(55);
    }
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    if (this.state.filterdata !== "") {
      //this.getData1(this.state.filterdata);
      this.getData(current_route, "", this.state.filterdata, 0, false);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  getData = (route, filter, filterdata, page, is_first) => {
    console.log(89, route);

    api({
      url:
        "https://api.analyzemarkets.com/realestatedeals/?name=" +
        route +
        "&start=" +
        page +
        "" +
        filter +
        "" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      console.log(res, "Real Estate Search");
      if (res.status === 200) {
        this.setState({
          madeals: res.data.results,
          items: res.data.results,
          page: res.data.count,
          current: page,
          is_first: false,
        });
      }
      if (filter != "" && res.data.count != res.data.totalcount) {
        console.log(filter, "filter");
        this.setState({
          status: true,
        });
      }
      if (res.data.count === res.data.totalcount) {
        this.setState({
          status: false,
        });
      }

      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
        if (res.data.count === 0) {
          this.setState({
            nosearch: true,
            search: false,
          });
        }
        if (res.data.count != 0) {
          this.setState({
            search: true,
            nosearch: false,
          });
        }
      }
      console.log(res.data.count + "Count Data");
    });
  };

  render() {
    const data = this.state.company;
    const count = this.state.count;
    console.log(count, "count m");
    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Navfilter />
          <Fetch applyFilters={this.handleFilters}></Fetch>

          {!this.state.is_first && (
            <div>
              <Row>
                <Col className="w-30p col-sm-3 p-4">
                  <div className="widget">
                    <MA_SideBar />
                  </div>
                </Col>

                <Col className="w-70p  p-4">
                  <Real_Estate_Screener applyFilter={this.handleFilter} />
                </Col>
              </Row>

              {this.state.status && (
                <Row>
                  <Col className="p-4">
                    <div>
                      <Row>
                        <Col sm={12} className="adapt" id="compdata">
                          {this.state.search && <h3>Search Result</h3>}

                          {this.state.nosearch && <h3>No Search Result</h3>}
                          {this.state.items.map((item, key) => (
                            <div className="greyborder1_4px placeholder scccc">
                              <Row className="show-grid">
                                <Col md={9}>
                                  {/* <div className="company-name"> {item.company_name.map((company, key) => (
                                     <Link to={'/en/global-company-database-details/'+company.api_url+'/'>
                                                        <u className="underline">{company.name}</u>
                                                        </Link>
                                                     ))}</div> */}

                                  <div className="company-name">
                                    {" "}
                                    <h6>
                                      <Link
                                        to={
                                          "/en/re-detail/" + item.api_url + "/"
                                        }
                                      >
                                        {item.deal}
                                      </Link>
                                    </h6>
                                  </div>

                                  <strong className="title">
                                    <Moment format="DD-MMM-YYYY">
                                      {item.announced_reported}
                                    </Moment>
                                  </strong>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </Col>
                      </Row>

                      <div className="products-view__options justify-content-between mt-4">
                        {/* {this.state.count > 10 && (
                    <div className="view-options__control">
                      <label>Results Per Page</label>
                      <div></div>
                    </div>
                  )} */}
                        <Pagination
                          items={this.state.exampleItems}
                          onChangePage={this.onChangePage}
                          pageSize={this.state.pageSize}
                        ></Pagination>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </Container>
      </Fragment>
    );
  }
}

export default Real_Estate_Deals_Search;
