export function matchCompany(state, value) {
  return state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchCountry(state, value) {
  return state.country.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchSector(state, value) {
  return state.sector.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchStatus(state, value) {
  return state.status.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchDeal(state, value) {
  return state.deal.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchPotentialDeal(state, value) {
  return state.potential_Deal.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchOpportunity(state, value) {
  return state.opportunity.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchGeography(state, value) {
  return state.type.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchRegion(state, value) {
  return state.region.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchTitle(state, value) {
  return state.title.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchGender(state, value) {
  return state.gender.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchAsset(state, value) {
  return state.asset_class.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchSubRegion(state, value) {
  return state.sub_region.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchSubIndustry(state, value) {
  return (
    state.companysubindustry.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}
export function matchDealHeadline(state, value) {
  return state.dealheadline.toLowerCase().indexOf(value.toLowerCase()) !== -1;
}
export function matchCurrency(state, value) {
  return (
    state.reportingcurrency.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}
