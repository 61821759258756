import React, { Component, Fragment, Suspense } from "react";

import logo from "./logo.svg";
import { BrowserRouter as Routers, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Footer from "./component/global/footer/index";
import Routes from "./routes";
import AppRoute from "./hoc";
import Router from "./router";
import Router1 from "./router1";
import Router2 from "./router2";
import Header from "./component/global/header/index";
import Login from "./component/global/CommonComponent/passwordhash";
import NotFound from "./pages/not-found/index";

const LoadingMessage = () => "<h1>Loading</h1>";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signin: [],
      email: "",
      token: "",
      login: "",
      tokenuser: "",
      response: "",
      user: "",
      count: "",
      results: false,

      hasError: false,
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <div className="App">
        <Suspense fallback={<LoadingMessage />}>
          <Switch>
            {Routes.map((route) => (
              <AppRoute key={route.path} {...route} />
            ))}
          </Switch>
          <Router></Router>

          <Router1></Router1>

          <Switch>
            <Router2></Router2>
          </Switch>

          <Footer />
        </Suspense>
      </div>
    );
  }
}

export default App;
