import React, { Fragment, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Row, Table, Col, Container, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
//import upload from "./upload.php";
import CreateSidebar from "../../component/global/debt-deals-QL/debt-deal-gmd-sidebar";
import $ from "jquery";
import { Link } from "react-router-dom";
//import Autocomplete from "material-ui/AutoComplete";
//import { makeStyles } from "material-ui/styles";
import Autocomplete from "react-autocomplete";
import {
  matchCompany,
  matchSector,
} from "../../component/global/investment-intellegence-screener/data";
//import window from "react-global";
import "../create-deal/create-deal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Signin from "../../pages/signin/signinIndex";
import Fetch from "../../component/global/CommonComponent/useFetch";
import loader from "../../assets/images/loader.gif";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Debt_Deals_Edit extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleState = this.handleState.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.onChange = (editorState) => this.setState({ editorState });
    this.getLabel = this.getLabel.bind(this);
    this.getLabel1 = this.getLabel1.bind(this);
    this.handleFilters = this.handleFilters.bind(this);

    this.state = {
      dealheadline: "",
      fundraisingstage: "",
      dealcodename: "",
      fiscalyearend: "",
      reportingcurrency: "",
      details: [],
      sendkey: false,
      filterdata: "",
      minimumtargetrevenuerange: "",
      maximumtargetrevenuerange: "",
      minimumtargetEBITDArange: "",
      maximumtargetEBITDArange: "",
      minimumtargettotalassetsrange: "",
      maximumtargettotalassetsrange: "",
      refresh: "",
      //editorState: EditorState.createEmpty(),
      user: "",
      financialstatementsaudited: "",
      financial_type: [],
      complete_financing: [],
      itemcurrency: [],
      name_info: "",
      title: "",
      emailentry: "",
      email: "",
      contactnumber: "",
      youauthorizedcreatedealbehalfcompany: "",
      items: [],
      sector_data: "",
      result: false,
      loading: "",
      country: [],
      sector: [],
      labelitem: false,
      labelitem1: false,
      status: "",
      sector_name: "",
      companysubindustry: "",
      dealbriefdescription: "",
      filter: "",
      subIndustry: [],
      old_route: "",
      pageSize: 25,
      count: 0,
      file: "",
      shariahcompliantfinancing: "",
      borrowerentity: "",
      totaldebtamountseeking: "",
      borrowercountryofincorporation: "",
      borrowertotalemployees: "",
      financingtype: "",
      dealbriefdescription: "",
      file_teaser: [],
      fileother: "",
      filepicture: "",
      file_other: [],
      reportedDate: new Date(),
      reported_date: "",
    };
  }
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };
  getLabel = () => {
    const { details } = this.state;
    this.setState({
      labelitem: true,
    });
  };
  getLabel1 = () => {
    const { details } = this.state;
    this.setState({
      labelitem1: true,
    });
  };

  refreshButton = () => {
    this.setState({
      refresh: true,
    });
  };

  handleState = () => {
    this.setState({
      result: false,
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      dealbriefdescription: e.target.getContent(),
    });

    console.log("Content was updated:", this.state.dealbriefdescription);
  };
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/gdmdebtsingle/" +
        this.props.match.params.dealheadline +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Debt Edit");
      this.setState({
        details: res.data,
        // file_teaser: res.data.teaserresentation,
        // acquirersector: res.data.acquirercompany,
        // acquirerURL: res.data.projectpicturestoupload,
        id: res.data.id,
      });

      console.log(this.state.id, "Global Id1");

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  handleChange1 = (e) => {
    if (["SubIndustry", "Sector"].includes(e.target.name)) {
      let taskList = [...this.state.taskList];
      taskList[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ [e.target.name]: e.target.value });
      for (let i = 0; i < 2; i++) {
        console.log(taskList[e.target.dataset.id].length, "Sector");
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addNewRow = () => {
    this.setState((prevState) => ({
      taskList: [
        ...prevState.taskList,
        {
          index: Math.random(),
          SubIndustry: "",
          Sector: "",
        },
      ],
    }));
  };

  deteteRow = (index) => {
    this.setState({
      taskList: this.state.taskList.filter((s, sindex) => index !== sindex),
    });
    // const taskList1 = [...this.state.taskList];
    // taskList1.splice(index, 1);
    // this.setState({ taskList: taskList1 });
  };
  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      sendkey: true,
    });
    console.log(this.state.sendkey, "sendkey");
    console.log("Selected file:", this.state.file);

    //.log("Selected file:", event.target.files[0]);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.date === "" || this.state.description === "") {
      //   NotificationManager.warning(
      //     "Please Fill up  Field . Please check Task and Date Field"
      //   );
      return false;
    }
    for (var i = 0; i < this.state.taskList.length; i++) {
      if (
        this.state.taskList[i].projectName === "" ||
        this.state.taskList[i].task === ""
      ) {
        return false;
      }
    }
    let data = { formData: this.state, userData: localStorage.getItem("user") };
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "token"
    );
    axios
      .post("http://localhost:9000/api/task", data)
      .then(() => {
        // if (res.data.success) NotificationManager.success(res.data.msg);
      })
      .catch(() => {
        //     if (error.response.status && error.response.status === 400)
        //       NotificationManager.error("Bad Request");
        //     else NotificationManager.error("Something Went Wrong");
        //     this.setState({ errors: error });
      });
  };
  onSubmit(e) {
    e.preventDefault();
    const { dealheadline } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set("id", this.state.id);
    bodyFormData.set(
      "dealheadline",
      dealheadline == "" ? this.state.details.dealheadline : dealheadline
    );
    bodyFormData.set(
      "dealcodename",
      this.state.dealcodename === ""
        ? this.state.details.dealcodename
        : this.state.dealcodename
    );
    bodyFormData.set(
      "borrowerentity",
      this.state.borrowerentity === ""
        ? this.state.details.borrowerentity
        : this.state.borrowerentity
    );
    bodyFormData.set(
      "borrowercountryofincorporation",
      this.state.borrowercountryofincorporation === ""
        ? this.state.details.borrowercountryofincorporation
        : this.state.borrowercountryofincorporation
    );
    bodyFormData.set(
      "borrowersector",
      this.state.sector_name === ""
        ? this.state.details.borrowersector
        : this.state.sector_name
    );
    bodyFormData.set(
      "borrowersubindustry",
      this.state.companysubindustry === ""
        ? this.state.details.borrowersubindustry
        : this.state.companysubindustry
    );
    bodyFormData.set(
      "borrowertotalemployees",
      this.state.borrowertotalemployees === ""
        ? this.state.details.borrowertotalemployees
        : this.state.borrowertotalemployees
    );
    bodyFormData.set(
      "financingtype",
      this.state.financingtype === ""
        ? this.state.details.financingtype
        : this.state.financingtype
    );
    bodyFormData.set(
      "shariahcompliantfinancing",
      this.state.shariahcompliantfinancing === ""
        ? this.state.details.shariahcompliantfinancing
        : this.state.shariahcompliantfinancing
    );
    bodyFormData.set(
      "borroweryearofestablishment",
      this.state.reported_date === ""
        ? this.state.details.borroweryearofestablishment
        : this.state.reported_date
    );

    bodyFormData.set(
      "totaldebtamountseeking",
      this.state.totaldebtamountseeking === ""
        ? this.state.details.totaldebtamountseeking
        : this.state.totaldebtamountseeking
    );
    bodyFormData.set(
      "financialstatementsaudited",
      this.state.financialstatementsaudited === ""
        ? this.state.details.financialstatementsaudited
        : this.state.financialstatementsaudited
    );
    bodyFormData.set(
      "fiscalyearend",
      this.state.fiscalyearend === ""
        ? this.state.details.fiscalyearend
        : this.state.fiscalyearend
    );

    bodyFormData.set(
      "reportingcurrency",
      this.state.reportingcurrency === ""
        ? this.state.details.reportingcurrency
        : this.state.reportingcurrency
    );
    bodyFormData.set(
      "dealbriefdescription",
      this.state.dealbriefdescription === ""
        ? this.state.details.dealbriefdescription
        : this.state.dealbriefdescription
    );

    bodyFormData.set(
      "historicalfinancialstatementscompanyaudited",
      this.state.historicalfinancialstatementscompanyaudited === ""
        ? this.state.details.historicalfinancialstatementscompanyaudited
        : this.state.historicalfinancialstatementscompanyaudited
    );
    bodyFormData.set(
      "fisicalyearend",
      this.state.fisicalyearend === ""
        ? this.state.details.fisicalyearend
        : this.state.fisicalyearend
    );
    bodyFormData.set(
      "minimumtargetrevenuerange",
      this.state.minimumtargetrevenuerange === ""
        ? this.state.details.minimumtargetrevenuerange
        : this.state.minimumtargetrevenuerange
    );
    bodyFormData.set(
      "minimumtargetEBITDArange",
      this.state.minimumtargetEBITDArange === ""
        ? this.state.details.minimumtargetEBITDArange
        : this.state.minimumtargetEBITDArange
    );
    bodyFormData.set(
      "minimumtargettotalassetsrange",
      this.state.minimumtargettotalassetsrange === ""
        ? this.state.details.minimumtargettotalassetsrange
        : this.state.minimumtargettotalassetsrange
    );
    bodyFormData.set(
      "maximumtargetrevenuerange",
      this.state.maximumtargetrevenuerange === ""
        ? this.state.details.maximumtargetrevenuerange
        : this.state.maximumtargetrevenuerange
    );
    bodyFormData.set(
      "maximumtargetEBITDArange",
      this.state.maximumtargetEBITDArange === ""
        ? this.state.details.maximumtargetEBITDArange
        : this.state.maximumtargetEBITDArange
    );
    bodyFormData.set(
      "maximumtargettotalassetsrange",
      this.state.maximumtargettotalassetsrange === ""
        ? this.state.details.maximumtargettotalassetsrange
        : this.state.maximumtargettotalassetsrange
    );

    bodyFormData.set(
      "name",
      this.state.name_info === ""
        ? this.state.details.name
        : this.state.name_info
    );
    bodyFormData.set(
      "title",
      this.state.title === "" ? this.state.details.title : this.state.title
    );
    bodyFormData.set(
      "email",
      this.state.emailentry === ""
        ? this.state.details.email
        : this.state.emailentry
    );
    bodyFormData.set(
      "contactnumber",
      this.state.contactnumber === ""
        ? this.state.details.contactnumber
        : this.state.contactnumber
    );
    bodyFormData.set(
      "userdealstatus",
      this.state.status === ""
        ? this.state.details.userdealstatus
        : this.state.status
    );

    if (this.state.sendkey === true) {
      bodyFormData.set(
        "teaserpresentation",
        this.state.file === ""
          ? this.state.details.teaserpresentation
          : this.state.file
      );
      console.log(this.state.file, "file send key");
    }
    if (this.state.sendkey === true) {
      bodyFormData.set(
        "otherdocumentstoupload",
        this.state.fileother === ""
          ? this.state.details.otherdocumentstoupload
          : this.state.fileother
      );
      console.log(this.state.file, "file send key");
    }
    if (this.state.sendkey === true) {
      bodyFormData.set(
        "projectpicturestoupload",
        this.state.filepicture === ""
          ? this.state.details.projectpicturestoupload
          : this.state.filepicture
      );
      console.log(this.state.file, "file send key");
    }

    bodyFormData.set(
      "acquirercompany",
      this.state.acquirercompany == ""
        ? this.state.details.acquirercompany
        : this.state.acquirercompany
    );
    bodyFormData.set(
      "acquirercountry",
      this.state.acquirercountry === ""
        ? this.state.details.acquirercountry
        : this.state.acquirercountry
    );
    bodyFormData.set(
      "acquirersector",
      this.state.acquirersector === ""
        ? this.state.details.acquirersector
        : this.state.acquirersector
    );
    bodyFormData.set(
      "acquirerURL",
      this.state.acquirerURL === ""
        ? this.state.details.acquirerURL
        : this.state.acquirerURL
    );
    bodyFormData.set(
      "youauthorizedcreatedealbehalfcompany",
      this.state.youauthorizedcreatedealbehalfcompany === ""
        ? this.state.details.youauthorizedcreatedealbehalfcompany
        : this.state.youauthorizedcreatedealbehalfcompany
    );

    bodyFormData.set("posted_by", this.state.user);

    axios
      .post("https://api.analyzemarkets.com/gdmdebtform/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.setState({
          loading: true,
        });

        console.log(res.data, "Data");
        if (res.status === 200) {
          this.setState({
            result: true,
            loading: false,
          });
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }
  componentDidMount = () => {
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }
    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch(() => {
        //on error
        console.log("Error !");
      });
    api({
      url: "https://api.analyzemarkets.com/financingtype/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ financial_type: res.data });
      }
    });
    api({
      url: "https://api.analyzemarkets.com/shariahcompliantfinancing/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ complete_financing: res.data });
      }
    });

    axios
      .get("https://api.analyzemarkets.com/reportingcurrency/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ itemcurrency: res.data });
      })
      .catch(() => {
        //on error
        console.log("Error !");
      });
    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ sector: res.data.results });
      }
    });
    api({
      url: "https://api.analyzemarkets.com/companysubindustry/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ subIndustry: res.data });
      }
    });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };

  clickOnDelete(record) {
    this.setState({
      taskList: this.state.taskList.filter((r) => r !== record),
    });
  }
  render() {
    const data = this.state.details;
    // var url = this.state.file_teaser;
    // var urls = this.state.acquirersector;
    // var urlss = this.state.acquirerURL;
    // var url1 = url !== null && url.replace(/^.*[\\\/]/, "");
    // var url2 = urls !== null && urls.replace(/^.*[\\\/]/, "");
    // var url3 = urlss !== null && urlss.replace(/^.*[\\\/]/, "");

    // const url2 = url1.replace("/%20/g", " ");

    //console.log(url, "Teaser");

    let { taskList } = this.state; //let { notes, date, description, taskList } = this.state
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Navfilter />
              {this.state.result}
              <Row className="centerAlign">
                {/* <Col className="w-30p col-sm-3 p-4">
              <div className="widget"></div>
            </Col> */}

                <Col className="">
                  <form onSubmit={this.onSubmit}>
                    <div className="iis greyborder2px">
                      <h4 className="text-left">Edit a Deal</h4>
                      <div className="pdg15">
                        <fieldset>
                          <legend>
                            <b className="upercase">About Deal</b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="dealheadline"
                                defaultValue={data.dealheadline}
                                type="text"
                                placeholder="Deal Headline* (Max 100 characters)
                            e.g.Contracting company seeking USD 10mn Debt Funding"
                                onChange={this.changeTerm}
                                maxLength="100"
                              />
                            </Col>
                          </Row>

                          <Row className="">
                            <Col>
                              <Form.Control
                                name="dealcodename"
                                defaultValue={data.dealcodename}
                                type="text"
                                placeholder="Deal Code Name *e.g. Project Sun"
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                name="borrowerentity"
                                type="text"
                                defaultValue={data.borrowerentity}
                                placeholder="Borrowing entity (optional)"
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Form.Group as={Col} controlId="ChooseCountry">
                              {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                name="country"
                                onChange={this.changeTerm}
                                isMulti
                                options={con}
                              /> */}
                              <Form.Control
                                as="select"
                                name="borrowercountryofincorporation"
                                defaultValue={
                                  data.borrowercountryofincorporation
                                }
                                onChange={this.changeTerm}
                                placeholder="Borrower Country of Incorporation*"
                                rows="3"
                              >
                                <option
                                  value={data.borrowercountryofincorporation}
                                  selected
                                >
                                  {data.borrowercountryofincorporation}
                                </option>

                                <option value="" className="">
                                  Borrower Country of Incorporation*{" "}
                                </option>
                                {this.state.country.map((item, i) => (
                                  <option key={i} value={item.country}>
                                    {item.country}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Row>
                          <Row className="">
                            <Col>
                              {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                placeholder={<div>Select Sector*</div>}
                                name="sector_name"
                                onChange={this.changeTermMultiSelect}
                                isMulti
                                options={sec}
                              />
                              {console.log(this.state.rem, "rem")} */}
                              {this.state.labelitem === false && (
                                <div
                                  className="form-group autoc"
                                  //className={classes.root}
                                >
                                  <Form.Control
                                    as="select"
                                    name="sector_name"

                                    // placeholder="Are you Authorized to create this deal"
                                  >
                                    <option
                                      value={data.borrowersector}
                                      selected
                                    >
                                      {data.borrowersector}
                                    </option>
                                    <option value="">Borrower Sector*</option>
                                    {this.state.sector.map((item, i) => (
                                      <option key={i} value={item.sector}>
                                        {item.sector}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </div>
                              )}
                              {this.state.labelitem && (
                                <div className="form-group autoc">
                                  <Autocomplete
                                    value={this.state.sector_name}
                                    //defaultValue={data.companysector}
                                    inputProps={{
                                      id: "sector-autocomplete",
                                      placeholder: "Borrower Sector*",
                                    }}
                                    wrapperStyle={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                    items={this.state.sector}
                                    getItemValue={(item) => item.sector}
                                    shouldItemRender={matchSector}
                                    onChange={(event, sector_name) =>
                                      this.setState({
                                        sector_name,
                                        sector_data: null,
                                      })
                                    }
                                    onSelect={(sector_name) =>
                                      this.setState({
                                        sector_name,
                                        sector_data: sector_name,
                                      })
                                    }
                                    renderMenu={(children) => (
                                      <div className="menu c-dropdown">
                                        {children}
                                      </div>
                                    )}
                                    renderItem={(item, isHighlighted) => (
                                      <div
                                        className={`item ${
                                          isHighlighted
                                            ? "item-highlighted"
                                            : ""
                                        }`}
                                        key={item.sector}
                                      >
                                        {item.sector}
                                      </div>
                                    )}
                                  />
                                </div>
                              )}{" "}
                            </Col>{" "}
                            <Col>
                              {/* <Select
                                closeMenuOnSelect={false}
                                placeholder={<div>Select Sub-Industry*</div>}
                                components={animatedComponents}
                                name="companysubindustry"
                                onChange={this.changeTermMultiSelects}
                                isMulti
                                options={subIn}
                              /> */}
                              {this.state.labelitem === false && (
                                <Form.Control
                                  as="select"
                                  name="companysubindustry"
                                  placeholder="Target Sub-Industry*"
                                >
                                  <option value="">
                                    Borrower Sub-Industry*
                                  </option>
                                  <option
                                    value={data.borrowersubindustry}
                                    selected
                                  >
                                    {data.borrowersubindustry}
                                  </option>
                                  {this.state.subIndustry.map((item, i) => (
                                    <option
                                      key={i}
                                      value={
                                        this.state.sector_data === item.sector
                                          ? item.companysubindustry
                                          : "null"
                                      }
                                    >
                                      {this.state.sector_data === item.sector
                                        ? item.companysubindustry
                                        : ""}
                                    </option>
                                  ))}
                                </Form.Control>
                              )}

                              {this.state.labelitem && (
                                <Form.Control
                                  as="select"
                                  name="companysubindustry"
                                  placeholder="Borrower Sub-Industry*"
                                  onChange={this.changeTerm}
                                >
                                  <option value="">Select Sub-Industry*</option>
                                  {this.state.subIndustry.map((item, i) => (
                                    <option
                                      key={i}
                                      value={
                                        this.state.sector_data === item.sector
                                          ? item.companysubindustry
                                          : "null"
                                      }
                                    >
                                      {this.state.sector_data === item.sector
                                        ? item.companysubindustry
                                        : ""}
                                    </option>
                                  ))}
                                </Form.Control>
                              )}
                            </Col>
                            <span onClick={this.getLabel}>
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                            </span>
                          </Row>

                          <Row className="">
                            <Col>
                              <Form.Control
                                name="borrowertotalemployees"
                                type="text"
                                defaultValue={data.borrowertotalemployees}
                                onChange={this.changeTerm}
                                placeholder="Borrower Total Employees (Optional)"
                              />
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <label className="colorWhite">
                                Financing Type*{" "}
                              </label>

                              <Form.Control
                                as="select"
                                name="financingtype"
                                onChange={this.changeTerm}
                                placeholder="Financing Type*"
                                rows="3"
                              >
                                <option value={data.financingtype}>
                                  {data.financingtype}
                                </option>
                                <option value="" className="">
                                  Financing Type*{" "}
                                </option>
                                {this.state.financial_type.map((item, i) => (
                                  <option key={i} value={item.type}>
                                    {item.type}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>

                            <Col>
                              <label className="colorWhite">
                                Shariah Compliant Financing*{" "}
                              </label>

                              <Form.Control
                                as="select"
                                name="shariahcompliantfinancing"
                                onChange={this.changeTerm}
                                placeholder="Shariah Compliant Financing*"
                                rows="3"
                              >
                                <option value={data.shariahcompliantfinancing}>
                                  {data.shariahcompliantfinancing}
                                </option>

                                <option value="" className="">
                                  Shariah Compliant Financing*{" "}
                                </option>
                                {this.state.complete_financing.map(
                                  (item, i) => (
                                    <option key={i} value={item.type}>
                                      {item.type}
                                    </option>
                                  )
                                )}
                              </Form.Control>
                            </Col>
                            <Col>
                              <Form>
                                <label>Borrower Year of Establishment*</label>

                                <div className="d-flex justify-content-between date-range">
                                  <DatePicker
                                    selected={this.state.reportedDate}
                                    onChange={this.handleReportedDate}
                                  />
                                </div>
                              </Form>
                            </Col>
                          </Row>

                          <Row className="">
                            <Form.Group as={Col} controlId="ChooseCountry">
                              <Form.Control
                                name="totaldebtamountseeking"
                                type="text"
                                defaultValue={data.totaldebtamountseeking}
                                onChange={this.changeTerm}
                                placeholder="Total Debt Amount Seeking (in USD)"
                              />
                            </Form.Group>
                          </Row>

                          <div className="form-group">
                            <Row className="">
                              <Col>
                                <Editor
                                  initialValue={data.dealbriefdescription}
                                  value={data.dealbriefdescription}
                                  apiKey="l7d3ab4ybjfthcy2c4ya6vsj9rlub27d3sdqk433oyis6ild"
                                  init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                      "advlist autolink lists link image",
                                      "charmap print preview anchor help",
                                      "searchreplace visualblocks code",
                                      "insertdatetime media table paste wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help | link | image",
                                  }}
                                  onChange={this.handleEditorChange}
                                />

                                {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                  as="textarea"
                                  name="dealbriefdescription"
                                  rows="7"
                                  maxLength="100"
                                  onChange={this.changeTerm}
                                  placeholder="Company/Deal Brief Description* (Maximum 100 words)"
                                />
                              </Form.Group> */}
                              </Col>{" "}
                            </Row>
                          </div>
                          <legend>
                            {" "}
                            <b className="upercase">Key Financials</b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                as="select"
                                name="financialstatementsaudited"
                                onChange={this.changeTerm}
                                // placeholder="Are you Authorized to create this deal"
                              >
                                <option value={data.financialstatementsaudited}>
                                  {data.financialstatementsaudited}
                                </option>
                                <option value="">
                                  Financial Statements Audited*
                                </option>
                                <option
                                  value={
                                    "Yes" === data.financialstatementsaudited
                                      ? "null"
                                      : "Yes"
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value={
                                    "No" === data.financialstatementsaudited
                                      ? "null"
                                      : "No"
                                  }
                                >
                                  No
                                </option>
                              </Form.Control>
                            </Col>

                            {console.log(this.state.file, "Type of Audit")}
                            <Col>
                              <Form.Control
                                as="select"
                                name="fiscalyearend"
                                onChange={this.changeTerm}
                                placeholder="Fiscal Year End*"
                                rows="3"
                              >
                                <option value={data.fiscalyearend} className="">
                                  {data.fiscalyearend}
                                </option>

                                <option value="" className="">
                                  Fiscal Year End*
                                </option>
                                <option value="Janaury">Janaury</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                              </Form.Control>
                            </Col>
                            <Col sm={4}>
                              <Form.Control
                                as="select"
                                name="reportingcurrency"
                                onChange={this.changeTerm}
                                placeholder="Financing Type*"
                                rows="3"
                              >
                                <option value={data.reportingcurrency} selected>
                                  {data.reportingcurrency}
                                </option>

                                <option value="" className="">
                                  Reporting Currency*
                                </option>
                                {this.state.itemcurrency.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.reportingcurrency}
                                  >
                                    {item.reportingcurrency}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr>
                                    <th width="200"> </th>
                                    <th className="colorWhite" width="200">
                                      Minimum
                                    </th>
                                    <th className="colorWhite" width="200">
                                      Maximum
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Target Revenue Range</td>
                                    <td>
                                      {" "}
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="minimumtargetrevenuerange"
                                            defaultValue={
                                              data.minimumtargetrevenuerange
                                            }
                                            type="text"
                                            placeholder=""
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      {" "}
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="maximumtargetrevenuerange"
                                            defaultValue={
                                              data.maximumtargetrevenuerange
                                            }
                                            type="text"
                                            placeholder="Optional"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Target EBITDA Range</td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="minimumtargetEBITDArange"
                                            defaultValue={
                                              data.minimumtargetEBITDArange
                                            }
                                            type="text"
                                            placeholder=""
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="maximumtargetEBITDArange"
                                            defaultValue={
                                              data.maximumtargetEBITDArange
                                            }
                                            type="text"
                                            placeholder="Optional"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Target Total Assets Range</td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="minimumtargettotalassetsrange"
                                            defaultValue={
                                              data.minimumtargettotalassetsrange
                                            }
                                            type="text"
                                            placeholder=""
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="maximumtargettotalassetsrange"
                                            defaultValue={
                                              data.maximumtargettotalassetsrange
                                            }
                                            type="text"
                                            placeholder="Optional"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>

                          <legend>
                            <b className="upercase">Documents</b>
                          </legend>

                          <Form.Group>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label> Teaser/Presentation</label>
                              )}

                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label> Teaser/Presentation</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="file"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.teaserpresentationname}
                                      </label>
                                    </div>{" "}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="file"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "File Name")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>
                            </div>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label>Other Documents to upload:</label>
                              )}
                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label>Other Documents to upload:</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="fileother"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.otherdocumentstouploadname}
                                      </label>
                                    </div>{" "}
                                    {console.log(
                                      " file count:",
                                      this.state.fileother
                                    )}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="fileother"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "Type of Audit")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>{" "}
                            </div>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label> Picture and video to upload</label>
                              )}{" "}
                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label> Picture and video to upload</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="filepicture"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.projectpicturestouploadname}
                                      </label>
                                    </div>{" "}
                                    {console.log(
                                      " file count:",
                                      this.state.file
                                    )}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="filepicture"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "Type of Audit")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>
                            </div>
                          </Form.Group>

                          <legend>
                            <b className="upercase">
                              Contact Information For Potential Investors
                            </b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="name_info"
                                type="text"
                                defaultValue={data.name}
                                placeholder="Name*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                name="title"
                                defaultValue={data.title}
                                type="text"
                                placeholder="Title*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="emailentry"
                                defaultValue={data.email}
                                type="email"
                                placeholder="Email*"
                                onChange={this.changeTerm}
                              />
                            </Col>

                            <Col>
                              <Form.Control
                                name="contactnumber"
                                type="tel"
                                defaultValue={data.contactnumber}
                                placeholder="Contact number*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col>
                              <label>
                                Are you authorized to create this deal on behalf
                                of the Company*:{" "}
                              </label>
                              <Form.Control
                                as="select"
                                name="youauthorizedcreatedealbehalfcompany"
                                defaultValue={
                                  data.youauthorizedcreatedealbehalfcompany
                                }
                                onChange={this.changeTerm}
                                // placeholder="Are you Authorized to create this deal"
                              >
                                {data.youauthorizedcreatedealbehalfcompany !==
                                null ? (
                                  <option
                                    value={
                                      data.youauthorizedcreatedealbehalfcompany
                                    }
                                    className=""
                                  >
                                    {data.youauthorizedcreatedealbehalfcompany}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Are you authorized to create the deal
                                  </option>
                                )}
                                <option
                                  value={
                                    "Yes" ===
                                    data.youauthorizedcreatedealbehalfcompany
                                      ? "null"
                                      : "Yes"
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value={
                                    "No" ===
                                    data.youauthorizedcreatedealbehalfcompany
                                      ? "null"
                                      : "No"
                                  }
                                >
                                  No
                                </option>
                              </Form.Control>
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <label>Deal Status</label>

                              <Form.Control
                                as="select"
                                name="status"
                                onChange={this.changeTerm}
                              >
                                {data.userdealstatus !== null ? (
                                  <option
                                    value={data.userdealstatus}
                                    className=""
                                  >
                                    {data.userdealstatus}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Deal Status
                                  </option>
                                )}

                                <option
                                  value={
                                    "Active" === data.userdealstatus
                                      ? "null"
                                      : "Active"
                                  }
                                >
                                  Active
                                </option>
                                <option
                                  value={
                                    "Inactive" === data.userdealstatus
                                      ? "null"
                                      : "Inactive"
                                  }
                                >
                                  Inactive
                                </option>
                              </Form.Control>
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col sm={3}>
                              <Button
                                variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                type="submit"
                                size="sm"
                              >
                                Publish Deal
                              </Button>
                            </Col>
                            <Col>
                              <Link
                                className="btn btn-blue mt-4 mb-4 shadow"
                                onClick={this.refreshButton}
                              >
                                Reset
                              </Link>
                            </Col>

                            <Col></Col>
                          </Row>
                        </fieldset>
                        {this.state.refresh && window.location.reload()}
                        {this.state.loading && (
                          <div className="borderColors1">
                            <img src={loader} className=""></img>
                          </div>
                        )}

                        {this.state.result && (
                          <div className="borderColors1">
                            <span
                              className="buttonFloat"
                              onClick={this.handleState}
                            >
                              <i className="fa fa-times-circle"></i>
                            </span>
                            <span>
                              <i
                                class="far fa-check-circle"
                                style={{ color: "green", float: "right" }}
                              ></i>
                            </span>
                            &nbsp;
                            <span>
                              <h4>
                                {" "}
                                <b className="colorError">
                                  Your Deal is Successfully Edited
                                </b>
                              </h4>
                              <br></br>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default Debt_Deals_Edit;
