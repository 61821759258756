import React, { Component } from "react";
import "./topnavafterlogin.css";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import imgSide from "../../../../src/assets/images/topnavAfterlogin.png";
import $ from "jquery";
class TopnavAfterLogin extends Component {
  constructor(props) {
    super(props);
    this.hideOnClick = this.hideOnClick.bind(this);
    this.showhide = this.showhide.bind(this);
    this.showhide = this.showhide.bind(this);
    this.state = {
      show: false,
      bymarkets: [],
      region: [],
      email: "",
      token: "",
      response: "",
      user: "",
      count: "",
      login: [],
      results: false,

      hasError: false,
    };
  }

  hideOnClick = () => {
    this.setState({
      show: false,
    });
  };

  showhide = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
    $("body").click(function () {
      $("#close2:visible").hide("fast");
    });
  };

  render() {
    return (
      <div className="menuIconBar">
        <button
          to="#"
          className="side-nav-toggler"
          id="side-nav-toggler"
          onClick={this.showhide}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        {this.state.show && (
          <div className="side-nav opened" id="close2">
            <nav>
              <ul>
                <li className="uppercase1">
                  <Link to={"/en/investment_wire/"} onClick={this.hideOnClick}>
                    Home
                  </Link>
                </li>
                <li className="uppercase1">
                  <Link
                    to={"/en/m_a_deals/"}
                    onClick={this.hideOnClick}
                    className="no-bg"
                  >
                    Global Deals Intelligence
                  </Link>
                </li>

                <li className="uppercase1">
                  <Link to={"/en/company-database/"}>
                    Global Company Database
                  </Link>
                </li>

                {/* <li className="uppercase1">
                  <Link
                    to={"/en/seeking-buyers-monitor/"}
                    onClick={this.hideOnClick}
                  >
                    Global Deals MarketPlace
                  </Link>
                </li>

                <li className="uppercase1">
                  <Link
                    to={"/en/global-investors-database/"}
                    onClick={this.hideOnClick}
                  >
                    Global Investors Database
                  </Link>
                </li>

                <li className="uppercase1">
                  <Link
                    to={"/en/global-research-report-database/"}
                    onClick={this.hideOnClick}
                  >
                    Global Research Database
                  </Link>
                </li> */}

                {/* <li>
                            
                            <Link to="/en/company" onClick={this.hideOnClick}>Company Page</Link>
                            
                        </li> */}

                {/* <li className="uppercase1">
                  <Link
                    to={"/en/gross-professional-database/"}
                    onClick={this.hideOnClick}
                  >
                    Global Professional Database
                  </Link>
                </li> */}

                {/* <li className="uppercase1">
                  <Link
                    to={"/en/on-demand_research/"}
                    onClick={this.hideOnClick}
                  >
                    On Demand Research And Analysis
                  </Link>
                </li> */}

                <li className="uppercase1">
                  <Link
                    to={"/en/on-demand_research/"}
                    onClick={this.hideOnClick}
                  >
                    Manage Alerts
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }
}

export default TopnavAfterLogin;
