import React, {Component} from 'react';
import ReactAudioPlayer from 'react-audio-player';
import './audio-player.css'; 

class Audioplayer extends Component {  
    render()
    {
        return <div className="pb-4 pt-3"> 
            <ReactAudioPlayer src={ this.props.url } controls/> 
        </div>;
    }
}

export default Audioplayer;
