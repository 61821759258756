import React, { Component } from "react";
import { Form } from "react-bootstrap/";
import { Link, NavLink } from "react-router-dom";
import "./screener.css";
import { Redirect } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Screener extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regionActive: "",
      url: "",
      startDate: new Date(),
      endDate: new Date(),
      start_date: "",
      end_date: "",
      name: "",
      is_submit: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (item) => {
    console.log(item, 22);
    this.setState({ regionActive: item });
  };

  handleSubmit = (item, startDate, endDate) => {
    console.log(item, this.state.start_date, 3244, this.state.end_date);
    this.props.greetChild(item, this.state.start_date, this.state.end_date);
    this.setState({ url: item, is_submit: true });
  };
  handleChange = (date) => {
    console.log();
    var local_date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    this.setState({ start_date: local_date, startDate: date });
  };

  handleEndDateChange = (date) => {
    console.log(date);
    this.setState({
      end_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      endDate: date,
    });
  };

  componentDidMount = () => {
    console.log(this.state.regionActive, 39);
    // this.setState({
    //     regionActive:this.props.search
    // })
  };

  render() {
    return (
      <div className="irs greyborder2px">
        <h4>Screener</h4>
        {this.state.url === "global-investment-intelligence" &&
          this.state.is_submit && (
            <Redirect
              to={
                "/en/search-news/" +
                this.props.search +
                "/global-investment-intelligence/"
              }
            />
          )}
        {this.state.url === "global-investment-wire" &&
          this.state.is_submit && (
            <Redirect
              to={
                "/en/search-press-release/" +
                this.props.search +
                "/global-investment-wire/"
              }
            />
          )}
        <div className="pdg15">
          <div className="l-sec">
            <label>Type</label>
            <ol>
              <li>
                <NavLink
                  to={
                    "/en/search-news/" +
                    this.props.search +
                    "/global-investment-intelligence/"
                  }
                  activeClassName="navbar__link--active2"
                  onClick={() => {
                    this.handleClick("global-investment-intelligence");
                  }}
                  className={
                    "global-investment-intelligence" === this.state.regionActive
                      ? "active"
                      : ""
                  }
                >
                  <h6 className="colorBlack">Global Investment Intelligence</h6>
                </NavLink>
              </li>

              {/* <li>
                <NavLink
                  to={
                    "/en/search-press-release/" +
                    this.props.search +
                    "/global-investment-wire/"
                  }
                  activeClassName="navbar__link--active2"
                  onClick={() => {
                    this.handleClick("global-investment-wire");
                  }}
                  className={
                    "global-investment-wire" === this.state.regionActive
                      ? "active"
                      : ""
                  }
                >
                  <h6 className="colorBlack">Global Investment Wire</h6>
                </NavLink>
              </li>*/}
            </ol>
          </div>
          <hr />
          <div className="l-sec">
            <label>Date Range</label>
            <Form>
              <div className="date-range">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleChange}
                />
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                />
              </div>
            </Form>
          </div>
          <div className="d-flex mt-4 justify-content-between">
            <Link
              className="btn btn-blue"
              onClick={() => {
                this.handleSubmit(
                  "global-investment-intelligence",
                  this.state.startDate,
                  this.state.endDate
                );
              }}
            >
              Apply
            </Link>
            <Link to="/" className="btn btn-blue">
              Reset
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Screener;
