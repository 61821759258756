import React, { Component, Fragment } from "react";
import "./subscribe.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Form, Button } from "react-bootstrap/";
import Datascience from "../../assets/images/DATA-SCIENCE-TEAM.svg";
import GlobalInfrastructure from "../../assets/images/GLOBAL-INFRASTRUCTURE.svg";
import Databasemm from "../../assets/images/DATABASE.svg";
import Datacollection from "../../assets/images/DATA-COLLECTION.svg";
import Stattics from "../../assets/images/STATISTICs.svg";
import B2b from "../../assets/images/B2B.svg";
import Dashboardreport from "../../assets/images/DASHBOARD-REPORT.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      firstname: null,
      lastname: null,
      company_name: null,
      company_url: null,
      job_title: null,
      country: null,
      contact_number: null,
      free_trial_demonstration: null,
      result: "",
      is_email: true,
      is_firstname: true,
      is_lastname: true,
      is_company_name: true,
      is_company_url: true,
      is_job_title: true,
      is_country: true,
      is_contact_number: true,
      country_list: [],
      error: "",
      plan: "",
      planCost: null,
      succeeded: false,
      error: null,
      processing: '',
      disabled: true,
      clientSecret: '',
      freeTrial: false,
      email: "",
      rememberMe: true,
      response: "",
      token: "",
      user: "",
      action: true,
      id: "",
      result: "",
      refresh: "",
      login_flag: "True",
      logout_flag: "False",
    };
    // const freeTrial = JSON.parse(sessionStorage.getItem("freeTrial"));
    // console.log('freeTrial', freeTrial)
    // if (freeTrial == true) {
    //   window.scrollTo(0, 500);
    // }
    // this.setState({ freeTrial: freeTrial })
    this.onPlanSelection = this.onPlanSelection.bind(this);
    this.onSubscribe = this.onSubscribe.bind(this);
    this.onFreeTrial = this.onFreeTrial.bind(this);
  }

  onChange(e) {
    console.log(e.target.name);
    if (e.target.name === "email") {
      if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(e.target.value)) {
        console.log(47);
        this.setState({
          is_email: false,
        });
      } else {
        this.setState({
          is_email: true,
        });
      }
    }

    if (e.target.name === "company_url") {
      if (
        !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
          e.target.value
        )
      ) {
        console.log("Url Is not valida");
        this.setState({
          is_company_url: false,
        });
      } else {
        this.setState({
          is_company_url: true,
        });
      }
    }

    if (e.target.name === "contact_number") {
      if (!/^\+\d{1,4}-[1-9]\d{0,9}$/.test(e.target.value)) {
        this.setState({
          is_contact_number: false,
        });
      } else {
        this.setState({
          is_contact_number: true,
        });
      }
    }
    // if(e.target.value.match(/^[w-.+]+@[a-zA-Z0-9.-]+.[a-zA-z0-9]{2,4}$/)){
    //     console.log("In valid");
    // }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    console.log(
      /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/.test(
        this.state.email
      ),
      this.state.email
    );
    var bodyFormData = new FormData();

    if (
      /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/.test(
        this.state.email
      ) === false
    ) {
      this.setState({
        error: "Invalid Email Address",
      });
      return 0;
    }

    this.setState({
      error: "",
    });
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("firstname", this.state.firstname);
    bodyFormData.set("lastname", this.state.lastname);
    bodyFormData.set("company_name", this.state.company_name);
    bodyFormData.set("country", this.state.country);
    // bodyFormData.set("contact_number", this.state.contact_number);
    // bodyFormData.set("plan", this.state.plan);
    // bodyFormData.set("planCost", this.state.planCost);
    // bodyFormData.set("company_url", this.state.company_url);
    // bodyFormData.set("job_title", this.state.job_title);
    if (this.state.freeTrial == true) {
      bodyFormData.set("free_trial_demonstration", true);
    } else {
      bodyFormData.set("free_trial_demonstration", false);
    }

    axios
      .post("https://api.analyzemarkets.com/subscriber_form/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log(30);
          this.setState({
            result:
              "Your request has been submitted. Our sales team will contact you shortly.",
          });
        } else {
          this.setState({ result: "You Are already subscribed!" });
        }
      })
      .catch((error) => {
        //on error
        // alert("There is an error in API call.");
      });

    this.setState({
      freeTrial: false
    });
  }
  handleDropdownChange(e) {
    this.setState({
      country: e.target.value,
    });
  }

  onPlanSelection(event) {
    console.log(event.target.value);
    if (event.target.value == 'month') {
      this.setState({
        plan: "Monthly",
        planCost: this.state.monthlyPlanCost,
        planId: this.state.monthlyPlanId
      })
    } else if (event.target.value == 'year') {
      this.setState({
        plan: "Yearly",
        planCost: this.state.yearlyPlanCost,
        planId: this.state.yearlyPlanId
      })
    }
  }

  onSubscribe() {
    sessionStorage.setItem("plan", this.state.plan);
    sessionStorage.setItem("planCost", this.state.planCost);
    sessionStorage.setItem("planId", this.state.planId);
  }

  onFreeTrial() {
    console.log('free trial')
    this.setState({
      freeTrial: !this.state.freeTrial
    })
  }


  componentDidMount = () => {
    window.scrollTo(0, 0);
    const plan = sessionStorage.getItem("plan");
    const planCost = sessionStorage.getItem("planCost");
    this.setState({ plan: plan })
    this.setState({ planCost: planCost })
    const freeTrial = JSON.parse(sessionStorage.getItem("freeTrial"));
    if (freeTrial == true) {
      window.scrollTo(0, 500);
    }
    this.setState({ freeTrial: freeTrial })
    sessionStorage.removeItem("freeTrial")

    api({
      url: "https://api.analyzemarkets.com/country/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ country_list: res.data.results });
      }
    });

    api({
      url:
        "https://api.analyzemarkets.com/subscription_plan/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log("Subscription response:", res);
      let yearString = res.data[0]['cost'].toString()
      let yearStart = yearString.slice(0,1)
      let yearRemain = yearString.slice(1,)
      let yearCost = yearStart +','+yearRemain;
      this.setState({
        monthlyPlanCost: res.data[1]['cost'],
        monthlyPlanId: res.data[1]['id'],

        yearlyPlanCost: yearCost,
        yearlyPlanId: res.data[0]['id'],
      });
    });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>AnalyzeMarkets Subscription</title>
            <meta
              name="description"
              content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
            />
            <meta property="og:title" content="AnalyzeMarkets Subscription" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="AnalyzeMarkets Subscription" />

            <meta
              itemprop="description"
              content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="AnalyzeMarkets Subscription" />

            <meta
              name="twitter:description"
              content="One subscription for unlimited access to global deals, companies, investors, research & professionals databases; global deals market place; on-demand research & analysis"
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            {/* <Col md={12}>
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <CheckoutForm stripe={stripe} elements={elements} />
                  )}
                </ElementsConsumer>
              </Elements>
            </Col> */}

            <Col md={12}>
              <hr />
              <h2 className="text-uppercase mb-4">
                Thank you for your interest in AnalyzeMarkets
              </h2>
            </Col>

            <Col md={12} lg={6} sm={12}>
              <h5>Subscribe for Access to AnalyzeMarkets' Global Deals Intelligence Platform</h5>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="d-flex align-items-center mb-3 mt-2">
                    <img className="img-fluid mr-3" width="40" src="/static/media/DETERMINE-OBJECT.1cbf71fd.svg" alt="" srcset="" />
                    <span className="font-18">Global <b>M&A Deals</b> Intelligence, Data & Analysis</span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <img className="img-fluid mr-3" width="40" src="/static/media/BUSINESS-INTELLIGENT.20fce05c.svg" alt="" srcset="" />
                    <span className="font-18">Global <b>Venture Capital, IPO, Fundraising and Real Estate Deals</b> Intelligence, Data & Analysis</span>
                  </div>
                  <ul className="subscribe-points">
                    <li className="mt-2" style={{ fontWeight: "400", fontSize: "15px" }}>
                      Provides timely announced private and public M&A deals data with analysis & background information,
                      Deals in the Making, companies seeking buyers and investors seeking targets predictive intelligence
                    </li>
                    <li className="mt-2" style={{ fontWeight: "400", fontSize: "15px" }}>
                      Tracks private equity firms, sovereign wealth funds, institutional investors, and family offices activities
                    </li>
                    <li className="mt-2" style={{ fontWeight: "400", fontSize: "15px" }}>
                      Information about strategic & financial parties involved in transactions, shareholders & backers, multiples, and deal advisors
                    </li>
                    <li className="mt-2" style={{ fontWeight: "400", fontSize: "15px" }}>
                      Comprehensive searches by region, country, sector, parties, advisors, and others with alerts
                    </li>
                  </ul>
                  <div className="d-flex align-items-center flex-column mt-3">
                    <div className="d-flex" style={{ alignItems: "flex-end", justifyContent: "space-evenly", width: "100%" }}>
                      <div className="d-flex align-items-baseline">
                        <input type="radio" name="plan" id="" value="month" onChange={this.onPlanSelection} />
                        <p className="font-26 ml-1"><b>USD {this.state.monthlyPlanCost} / month*</b></p>
                      </div>
                      <div className="d-flex flex-column">
                        <div>
                          <strike className="font-26 ml-3" style={{ color: "rgba(171 171 171 / 73%)" }}> <b>USD 2,388</b> </strike>
                        </div>
                        <div className="d-flex align-items-baseline">
                          <input type="radio" name="plan" id="" value="year" onChange={this.onPlanSelection} />
                          <p className="font-26 ml-1"><b>USD {this.state.yearlyPlanCost} / Year*</b></p>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-2">
                      {
                        this.state.planId == undefined
                          ? <a href="" className="btn" style={{ background: "#1a529c", color: "white" }} onClick={this.onSubscribe}>Subscribe</a>
                          : <a href="/en/paywall" className="btn" style={{ background: "#1a529c", color: "white" }} onClick={this.onSubscribe}>Subscribe</a>
                      }
                    </div>
                    <div>
                      <i style={{ fontSize: "10px", color: "rgba(20,20,20,0.73)" }}>*per user</i>
                    </div>
                  </div>
                  <div className="mt-4">
                    <i className="font-14">For group subscription or a specific requirement, please email us at <u>subscribe@analyzemarkets.com</u></i>
                  </div>
                 {/* <div onClick={this.onFreeTrial} className="d-flex justify-content-between align-items-center mt-4" style={{ backgroundColor: "#e2e2e2", padding: "12px", cursor: "pointer" }}>
                    <span className="font-15">Request for Free Trial and Demo</span>
                    <i class="fa fa-chevron-down" style={{ paddingRight: "10px" }} aria-hidden="true"></i>
                  </div>
                  */}
                </div>
              </div>
              {/* (this.state.result === "" && this.state.freeTrial == true)
                ? (
                  <div className="row">
                    <div className="col-12 mt-3">
                       <h5 className="pb-4 font-weight-normal">
                        Please provide the below requested information as part of the
                        subscription process and to serve you efficiently.</h5> 
                      <p><span className="text-danger">* </span>marked fields are mandatory</p> 
                      <Form onSubmit={this.onSubmit.bind(this)}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="FirstName">
                            <Form.Control
                              type="text"
                              placeholder="First Name*"
                              name="firstname"
                              onChange={this.onChange.bind(this)}
                              required
                            />
                          </Form.Group>

                          <Form.Group as={Col} controlId="lastName">
                            <Form.Control
                              type="text"
                              placeholder="Last Name*"
                              name="lastname"
                              onChange={this.onChange.bind(this)}
                              required
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="ComapanyName">
                          <Form.Control
                            type="text"
                            placeholder="Company Name"
                            name="company_name"
                            onChange={this.onChange.bind(this)}
                          />
                        </Form.Group>

                        {/* <Form.Group controlId="CompanyURL">
                    <Form.Control
                      type="text"
                      placeholder="Company URL*"
                      name="company_url"
                      onChange={this.onChange.bind(this)}
                      required
                    />
                  </Form.Group> 

                        {/* <Form.Group controlId="JobTitle">
                    <Form.Control
                      type="text"
                      placeholder="Job Title*"
                      name="job_title"
                      onChange={this.onChange.bind(this)}
                      required
                    />
                  </Form.Group> 

                        <Form.Row>
                          <Form.Group as={Col} controlId="ChooseCountry">
                            <Form.Control
                              as="select"
                              onChange={(e) => this.handleDropdownChange(e)}
                              required
                            >
                              <option value="">Select Country*</option>
                              {this.state.country_list.map((item, i) => (
                                <option key={i} value={item.country}>
                                  {item.country}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="WorkEmail">
                          <Form.Control
                            type="email"
                            placeholder="Email*"
                            name="email"
                            onChange={this.onChange.bind(this)}
                            required
                          />
                        </Form.Group>

                        {/* <Form.Group controlId="ContactNumber">
                          <Form.Control
                            type="text"
                            placeholder="Contact Number with Country Code*"
                            name="contact_number"
                            onChange={this.onChange.bind(this)}
                          />
                        </Form.Group>

                        {/* <div className="d-flex align-items-baseline">
                    <input type="radio" name="plan" id="" value="month" checked={this.state.plan == "month"} onChange={this.onPlanSelection} />
                    <p className="font-18 ml-1">USD 49 / month*</p>
                  </div>
                  <div className="d-flex align-items-baseline mb-2">
                    <input type="radio" name="plan" id="" value="year" checked={this.state.plan == "year"} onChange={this.onPlanSelection} />
                    <p className="font-18 ml-1">USD 500 / Year*</p>
                  </div> 

                        {/* <Form.Group id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="I want a free trial and demonstration"
                      name="free_trial_demonstration"
                      onChange={this.onChange.bind(this)}
                    />
                  </Form.Group> 

                        {/* <div className="capchaCode">
                    <div
                      className="g-recaptcha"
                      data-sitekey="6LcjIsQUAAAAAPYjdLFm1hP-EgdGAJFQrUxmRGkc"
                    ></div>
                  </div> 

                        {/* <p className="mb-4 mt-2">
                    By submitting the above required information you agree to
                    our <Link to="/en/terms-of-use">Terms of Use</Link>,{" "}
                    <Link to="/en/privacy-policy">Privacy Policy</Link>,&nbsp;
                    <Link to="/en/trademarks">Trademark</Link> and{" "}
                    <Link to="/en/disclaimer">Disclaimer</Link>.
                  </p>
                  {this.state.error !== "" && (
                    <h5 className="pb-4 font-weight-normal">
                      {this.state.error}
                    </h5>
                  )} 
                        <div className="text-center mb-5">
                          <Button variant="blue btn-lg" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                )
                : <div></div>
                  */}
              {this.state.result !== "" && (
                <div className="mt-3">
                  <h5 className="pb-4 font-weight-normal">{this.state.result}</h5>
                </div>
              )}
            </Col>

            <Col md={12} lg={6} sm={12}>
              <div className="bdrpdg">
                <ul className="subscribelist">
                  <h5 className="mb-4">
                    AnalyzeMarkets helps businesses and professionals by providing:
                  </h5>
                  <li className="pt-0">
                    <img src={Datascience} className="img-fluid" alt="" />
                    <p>
                      <span>Global Deals Intelligence:</span> Covering M&A,
                      Venture Capital, Real Estate, IPO, Joint Venture, Fixed
                      Income, Fundraising deals.
                    </p>
                  </li>
                  <li>
                    <img src={GlobalInfrastructure} className="img-fluid" alt="" />
                    <p>
                      <span>Global Deals Marketplace:</span> It provides a
                      platform to Qualified Investors, Entrepreneurs, Lenders,
                      Financial Institutions, Advisors and Businesses for source
                      & share investment opportunities across different asset
                      classes; raise funds from qualified investors; and offer
                      tailored financing solutions.
                    </p>
                  </li>
                  <li>
                    <img src={Databasemm} className="img-fluid" alt="" />
                    <p>
                      <span>Global Companies Intelligence:</span> one of the largest
                      and comprehensive datasets available for each company in
                      the database.
                    </p>
                  </li>
                  <li>
                    <img src={Datacollection} className="img-fluid" alt="" />
                    <p>
                      <span>Global Investors Database:</span> Access to one of
                      the largest strategic and financial investors database.
                      You can find and contact investors by region, sub-region,
                      country, markets, asset class and sectors.
                    </p>
                  </li>
                  <li>
                    <img src={B2b} className="img-fluid" alt="" />
                    <p>
                      <span>Global Professionals Database:</span> Access and
                      contact c-level management and decision makers available
                      by region, country, sector, and company.
                    </p>
                  </li>
                  <li>
                    <img src={Stattics} className="img-fluid" alt="" />
                    <p>
                      <span>Global Research Reports:</span> Access to
                      third-party research reports related to economy, sector,
                      and company.
                    </p>
                  </li>
                  <li className="pb-0">
                    <img src={Dashboardreport} className="img-fluid" alt="" />
                    <p>
                      <span>On-Demand Research and Analysis:</span> Our
                      qualified and experienced analysts can assist subscribers
                      in any of their research and analysis work.
                    </p>
                  </li>
                </ul>
              </div>
              <p className="mt-4">
                For your any subscription related query or a specific
                requirement, please email us at{" "}
                <Link to="mailto:subscribe@analyzemarkets.com">
                  subscribe@analyzemarkets.com
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Subscribe;
