import React, {Component} from 'react';
import './subscription.css';
import axios from "axios";

class Freesubs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            result:'' 
        }
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit(e) {
        e.preventDefault();
        console.log(this.state.email);
        var bodyFormData = new FormData();
        bodyFormData.set('email', this.state.email);

        axios.post('https://api.analyzemarkets.com/verify_email_link/',bodyFormData,{ headers: {'Content-Type': 'multipart/form-data'}}).then((res)=>{
           console.log(res);
           if(res.status===200){
               console.log(30);
               this.setState({result:<div><hr/><i class="fa fa-check btext"></i> Thank you for subscribing.<br/><small class="text-muted">Please check your inbox and confirm email address.</small></div>});
           }else{
               this.setState({result:<div class=""><hr/><i class="fa fa-exclamation-triangle  btext"></i> You are already subscribed!</div>});
           }
           
        }).catch((error)=>{
        //on error
        alert('There is an error in API call.'); 
        });
    }
    render()
    {
        return <div className="fsubs greyborder2px"> 
            <p>

                <strong>Free Subscription</strong> to our Daily Global Investment Intelligence Brief, delivered directly to your
                inbox</p>

                { this.state.result } 

                {this.state.result==='' && 

                    <form onSubmit={this.onSubmit.bind(this)}>
                        <div className="group">
                            <input type="email" name="email" onChange={this.onChange.bind(this)} required/>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Enter Your Email</label>
                        </div>

                        <button type="submit" className="btn btn-blue"> Sign Up </button>
                    </form>
                }
        </div>;
    }
}

export default Freesubs;
