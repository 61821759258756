import React, { Component } from "react";
import Watchicon from "../../../assets/images/time-icon.svg";
import Playicon from "../../../assets/images/play-icon.svg";
import { Link, BrowserRouter } from "react-router-dom";
import "./news-post.css";
import TimeAgo from "react-timeago";
import ReactAudioPlayer from "react-audio-player";

class Newspost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      lenght: "",
      loggedIn: false,
      duration: "00:00",
    };
  }
  showhide = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  showAudioLength = (length) => {
    this.setState({
      duration: this.convertTime(length),
    });
  };

  formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    console.log(s.toFixed(1), 36);
    return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
      .filter((a) => a)
      .join(":");
  };

  componentDidMount() {
    var media = new Audio();
    media.src = this.props.item.audio;
    console.log(media.src);
    media.onloadedmetadata = () => {
      this.setState({
        loggedIn: true,
        duration: this.formatTime(media.duration.toFixed(0)),
      });
    };
  }

  render() {
    return (
      <article className="news-module">
        <span className="newstag mb-1">
          {this.props.item.news_country_category_sector_details}
        </span>
        <div className="news-image">
          <Link to={"/en/intelligence/" + this.props.item.url}>
            {this.props.item.image_421X237 == null ? (
              <img
                src={this.props.item.image}
                className="img-fluid"
                alt={this.props.item.title}
                loading="lazy"
              />
            ) : (
              <img
                src={this.props.item.image_421X237}
                className="img-fluid"
                alt={this.props.item.title}
                loading="lazy"
              />
            )}
          </Link>
          <span className="timeupdated text-white">
            <img
              src="https://analyze-website-assets.s3-eu-west-1.amazonaws.com/time-icon.svg"
              className="fs16"
              alt={this.props.item.title}
            />{" "}
            {!this.props.item.is_updated && "Posted"}{" "}
            {this.props.item.is_updated && "Updated"}&nbsp;
            <TimeAgo date={this.props.item.modified_at} />
          </span>
        </div>
        <div className="news-content">
          <h3 className="mr-1 ml-1">
            <Link to={"/en/intelligence/" + this.props.item.url}>
              {this.props.item.title}
            </Link>
          </h3>
          <small className="w-100 text-right d-block pr-1">1 min read</small>
          {/* {!this.state.show && (
            <BrowserRouter>
              <Link className="btnLink-blue" onClick={this.showhide}>
                <img src={Playicon} alt={this.props.item.title} />{" "}
                {this.state.duration}
              </Link>
            </BrowserRouter>
          )}
          {this.state.show && (
            <ReactAudioPlayer src={this.props.item.audio} controls />
          )} */}
        </div>
      </article>
    );
  }
}

export default Newspost;
