import React, { Component, Fragment } from "react";
import { Row, Table, Col, Container } from "react-bootstrap";
import "../create-deal-sidebar/create-deal-sidebar.css";
import { Link, NavLink } from "react-router-dom";
class Vc_Deals_Gmd_Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      chartData: [],
      dataLoadingStatus: "loading",
    };
  }
  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");

    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, user, action });
  };
  render() {
    return (
      <Fragment>
        <Container>
          <div>
            <Row className="show-grid">
              <Col className="borderstyle">
                <b className="ma-title uppercase">Quick Links</b>
                <p className="border_bottom"></p>
                <div>
                  <b className="subheading1">Deal Monitor</b>
                  <p className="font-16 suburls">
                    <NavLink
                      to={"/en/vc-deals-gmd-monitor/"}
                      activeClassName="navbar__link--active1"
                      className="ma_sidebar_color "
                    >
                      Startup/VC Deals Monitor
                    </NavLink>
                  </p>

                  <p className="border_bottomstyle"></p>
                </div>

                <div>
                  <b className="subheading1">Create a Deal</b>
                  <p className="font-16 suburls">
                    <NavLink
                      to={"/en/vc-deals-gmd-create/"}
                      activeClassName="navbar__link--active1"
                      className="ma_sidebar_color"
                    >
                      Create a Startup/VC Deal
                    </NavLink>
                  </p>

                  <p className="border_bottomstyle"></p>
                </div>

                <div>
                  <b className="subheading1">Advanced Search</b>
                  <p className="font-16 suburls">
                    <NavLink
                      to={"/en/vc-deals-gmd-search/"}
                      activeClassName="navbar__link--active1"
                      className="ma_sidebar_color"
                    >
                      Advanced Search
                    </NavLink>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default Vc_Deals_Gmd_Sidebar;
