import React, { Component } from "react";
import { Form } from "react-bootstrap/";
import { Link } from "react-router-dom";
import "./investment-Intelligence-screener.css";
import Autocomplete from "react-autocomplete";
import { matchCountry, matchSector, matchCompany } from "./data";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Iis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      country: [],
      companyand_data: "",
      sector: [],
      category: [],
      result: false,
      selected: "",
      comapany_name: "",
      country_name: "",
      sector_name: "",
      category_name: "",
      startDate: new Date(),
      endDate: new Date(),
      start_date: "",
      categoryand_name: "",
      end_date: "",
      last_days: "",
      region: null,
      by_market: null,
    };
    this.getCompanyData = this.getCompanyData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLastDate = this.handleLastDate.bind(this);
    this.handleLastDays = this.handleLastDays.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
  }
  handleLastDays = (e) => {
    console.log(Number.isInteger(e), e);
    if (Number.isInteger(parseInt(e))) {
      this.setState({
        last_days: e,
        startDate: "",
        start_date: "",
        end_date: "",
        endDate: "",
      });
    }
  };

  refreshButton = () => {
    this.setState({
      comapany_name: "",
      country_name: "",
      sector_name: "",
      category_name: "",
    });
  };
  handleChange = (date) => {
    if (date !== null) {
      console.log(
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        "Test"
      );
      this.setState({
        start_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        startDate: date,
        last_days: "",
      });
    } else {
      this.setState({
        start_date: "",
        startDate: "",
        last_days: "",
      });
    }
  };
  handleLastDate = (date) => {
    if (date !== null) {
      console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
      this.setState({
        last_days: "",
        end_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        endDate: date,
      });
    } else {
      this.setState({
        last_days: "",
        end_date: "",
        endDate: "",
      });
    }
  };
  handleClick = (item) => {
    console.log(item, 22, this.state.last_days, 32);

    this.props.applyFilter(
      "&company_name=" +
        this.state.comapany_name +
        "&sector_name=" +
        this.state.sector_name +
        "&country_name=" +
        this.state.country_name +
        "&category_name=" +
        this.state.category_name +
        "&start_date=" +
        this.state.start_date +
        "&end_date=" +
        this.state.end_date +
        "&last_days=" +
        this.state.last_days
    );
  };

  getCompanyData = (region, markets) => {
    var params = "";
    if (region != null) {
      params = "?region=" + region;
    }

    if (markets != null && params != "") {
      params += "&by_market=" + markets;
    }

    if (markets != null && params == "") {
      params = "?by_market=" + markets;
    }
    console.log(params, 91);
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/" + params, {})
      .then((res) => {
        console.log(res, "company limited");
        const arrays = [];
        for (let i = 0; i < res.data.length; i++) {
          arrays.push(res.data[i]);
        }
        this.setState({ company: arrays });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/" + params, {})
      .then((res) => {
        console.log(res, "country");
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/sectorman/", {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ sector: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/category/", {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ category: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };
  componentDidMount = () => {
    this.getCompanyData(this.props.byRegion, this.props.byMarkets);
  };
  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.byRegion !== null &&
      this.state.region !== this.props.byRegion
    ) {
      this.setState({
        region: this.props.byRegion,
        company: [],
        country: [],
      });
      console.log(this.props.byRegion, 138);
      this.getCompanyData(this.props.byRegion, null);
    }
    if (
      this.props.byMarkets !== null &&
      this.state.by_market !== this.props.byMarkets
    ) {
      this.setState({
        by_market: this.props.byMarkets,
        company: [],
        country: [],
      });
      this.getCompanyData(null, this.props.byMarkets);
    }
  };
  render() {
    return (
      <div className="iis greyborder2px">
        <h4>Investment Intellegence Screener</h4>
        <div className="pdg15">
          <div className="form-group autoc">
            <Autocomplete
              value={
                this.state.comapany_name.indexOf("%26") != -1
                  ? this.state.companyand_data
                  : this.state.comapany_name
              }
              inputProps={{
                id: "company-autocomplete",
                placeholder: "Select Company",
              }}
              wrapperStyle={{
                position: "relative",
                display: "inline-block",
              }}
              onClick={() => {
                this.handleCompany("region");
              }}
              //applyCompanyFilters={this.handleFilter}
              items={this.state.company}
              shouldItemRender={matchCompany}
              getItemValue={(item) => item.name}
              onChange={(event, comapany_name) =>
                this.setState({ comapany_name })
              }
              onSelect={(comapany_name) =>
                this.setState({
                  comapany_name:
                    comapany_name.indexOf("&") != -1
                      ? comapany_name.replace("&", "%26")
                      : comapany_name,
                  companyand_data:
                    comapany_name.indexOf("%26") != -1
                      ? comapany_name.replace("%26", "&")
                      : comapany_name,
                })
              }
              renderMenu={(children) => (
                <div className="menu c-dropdown">{children}</div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.name}
                >
                  {item.name}
                </div>
              )}
            />
          </div>{" "}
          <div className="form-group autoc">
            <Autocomplete
              value={this.state.country_name}
              inputProps={{
                id: "country-autocomplete",
                placeholder: "Select Country",
              }}
              wrapperStyle={{
                position: "relative",
                display: "inline-block",
              }}
              items={this.state.country}
              getItemValue={(item) => item.country}
              shouldItemRender={matchCountry}
              onChange={(event, country_name) =>
                this.setState({ country_name })
              }
              onSelect={(country_name) => this.setState({ country_name })}
              renderMenu={(children) => (
                <div className="menu c-dropdown">{children}</div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.country}
                >
                  {item.country}
                </div>
              )}
            />
          </div>
          <div className="form-group autoc">
            <Autocomplete
              value={this.state.sector_name}
              inputProps={{
                id: "sector-autocomplete",
                placeholder: "Select Sector",
              }}
              wrapperStyle={{
                position: "relative",
                display: "inline-block",
              }}
              items={this.state.sector}
              getItemValue={(item) => item.sector}
              shouldItemRender={matchSector}
              onChange={(event, sector_name) => this.setState({ sector_name })}
              onSelect={(sector_name) => this.setState({ sector_name })}
              renderMenu={(children) => (
                <div className="menu c-dropdown">{children}</div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.sector}
                >
                  {item.sector}
                </div>
              )}
            />
          </div>
          <div className="form-group autoc">
            <Autocomplete
              value={
                this.state.category_name.indexOf("%26") != -1
                  ? this.state.categoryand_name
                  : this.state.category_name
              }
              inputProps={{
                id: "category-autocomplete",
                placeholder: "Select Category",
              }}
              wrapperStyle={{
                position: "relative",
                display: "inline-block",
              }}
              items={this.state.category}
              getItemValue={(item) => item.name}
              shouldItemRender={matchCompany}
              onChange={(event, category_name) =>
                this.setState({ category_name })
              }
              onSelect={(category_name) =>
                this.setState({
                  category_name:
                    category_name.indexOf("&") != -1
                      ? category_name.replace("&", "%26")
                      : category_name,
                  categoryand_name:
                    category_name.indexOf("%26") != -1
                      ? category_name.replace("%26", "&")
                      : category_name,
                })
              }
              renderMenu={(children) => (
                <div className="menu c-dropdown">{children}</div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.name}
                >
                  {item.name}
                </div>
              )}
            />
          </div>
          <Form.Group>
            <Form.Control
              as="select"
              onChange={(e) => {
                this.setState({ selected: e.target.value });
                this.handleLastDays(e.target.value);
              }}
            >
              <option>Select Date</option>
              <option value="3">Last 3 Days</option>
              <option value="7">Last 7 Days</option>
              <option value="30">Last 30 Days</option>
              <option value="custom_date">Select Custom Date</option>
            </Form.Control>
            {this.state.selected === "custom_date" && (
              <Form>
                <div className="d-flex justify-content-between date-range">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                  />
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleLastDate}
                  />
                </div>
              </Form>
            )}
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Link
              className="btn btn-blue"
              onClick={() => {
                this.handleClick("region");
              }}
            >
              Apply
            </Link>
            <Link
              to="/en/intelligence"
              className="btn btn-blue"
              onClick={this.refreshButton}
            >
              Reset
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Iis;
