import React, { Component, Fragment } from "react";
import Followus from "../../component/global/followus/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import Iis from "../../component/global/investment-intellegence-screener/investment-Intelligence-screener";
import Newspost from "../../component/global/news-post/index";
import Offerwidget from "../../component/global/offers-widget/offers-widget";
import Freesubs from "../../component/global/free-subscription/subscription";
import Bnrwidget from "../../component/global/banner-widget";
import { Container, Row, Col } from "react-bootstrap/";
import "./global-investment-intelligence.css";
import axios from "axios";
import Pagination from "../../component/global/pagination/index";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";
class Globalii extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  constructor(props) {
    super(props);
    this.state = {
      item: [],
      news: {},
      id: "",
      pageSize: 21,
      section_one: [],
      section_two: [],
      section_three: [],
      old_route: "",
      filter: "",
      exampleItems: [],
      page: 0,
      current: 0,
      is_filter: false,
      is_first: true,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }

  handleFilter = (filter) => {
    console.log(filter, 31);
    this.setState({
      filter: filter,
      is_filter: true,
    });
    this.getData(this.state.old_route, filter, 0, true);
  };
  onChangePage = (pageOfItems, page) => {
    console.log(47, page, this.state.page, this.state.current);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page !== this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };
  componentDidMount = () => {
    // const token = localStorage.getItem("token");
    //
    // if(token == null) {
    //   window.location = '/en/signin';
    //   // console.log(window.location);
    // }

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route === undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    this.getData(current_route, "", 0, true);

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  componentWillReceiveProps = (nextProps) => {
    var current_route = nextProps.match.params.id;
    if (current_route === undefined) {
      current_route = "";
    }
    console.log(nextProps.match.params.id, 22);
    if (this.state.old_route !== "") {
      this.setState({
        old_route: current_route,
      });
      this.getData(current_route, "", 0, true);
    } else {
      if (current_route !== this.state.old_route && current_route !== "") {
        this.getData(current_route, "", 0, true);
      }
    }
  };

  getData = (route, filter, page, is_first) => {
    console.log(89, this.state.current);
    axios
      .get(
        "https://api.analyzemarkets.com/giim/?name=" +
          route +
          "&start=" +
          page +
          "" +
          filter,
        {}
      )
      .then((res) => {
        console.log(res, 96);
        this.setState({
          section_one: res.data.section_one,
          section_two: res.data.section_two,
          section_three: res.data.section_three,
          page: res.data.count,
          current: page,
          is_first: false,
        });

        if (is_first) {
          this.setState({
            exampleItems: [...Array(res.data.count).keys()].map((i) => ({
              id: i + 1,
              name: "Item " + (i + 1),
            })),
          });
        }
      })
      .catch((error) => {
        //on error
        // alert("There is an error in API call.");
      });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>All Global Investment Intelligence | AnalyzeMarkets</title>
            <meta
              name="description"
              content="Current and historical world-wide investment intelligence covering M&A, real estate, venture capital, joint venture, private equity, fundraising, IPOs, fixed income, and SWF transactions"
            />
            <meta
              property="og:title"
              content="All Global Investment Intelligence | AnalyzeMarkets"
            />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Current and historical world-wide investment intelligence covering M&A, real estate, venture capital, joint venture, private equity, fundraising, IPOs, fixed income, and SWF transactions"
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              itemprop="name"
              content="All Global Investment Intelligence | AnalyzeMarkets"
            />

            <meta
              itemprop="description"
              content="Current and historical world-wide investment intelligence covering M&A, real estate, venture capital, joint venture, private equity, fundraising, IPOs, fixed income, and SWF transactions"
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta
              name="twitter:title"
              content="All Global Investment Intelligence | AnalyzeMarkets"
            />

            <meta
              name="twitter:description"
              content="Current and historical world-wide investment intelligence covering M&A, real estate, venture capital, joint venture, private equity, fundraising, IPOs, fixed income, and SWF transactions"
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            {console.log(this.state.loggedIn, "Logged In")}
            <Col className="w-70p">
              <hr />
              <h2 className="text-uppercase mb-4">
                All Global Investment Intelligence:
              </h2>
            </Col>
          </Row>
          <Row>
            {!this.state.is_first && (
              <Col className="w-70p">
                <Row>
                  {this.state.section_one.map((item, index) => (
                    <Col md="4">
                      <Newspost
                        key={index}
                        item={item}
                        loggedIn={this.state.loggedIn}
                      />
                    </Col>
                  ))}
                </Row>
                {!this.state.is_filter && (
                  <Row>
                    <Col md={12}>
                      <Bnrwidget />
                    </Col>
                  </Row>
                )}

                <Row>
                  {this.state.section_two.map((item, index) => (
                    <Col md="4">
                      <Newspost key={index} item={item} />
                    </Col>
                  ))}
                </Row>
                {!this.state.is_filter && (
                  <Row>
                    <Col md={12}>
                      <Bnrwidget />
                    </Col>
                  </Row>
                )}
                <Row>
                  {this.state.section_three.map((item, index) => (
                    <Col md="4">
                      <Newspost key={index} item={item} />
                    </Col>
                  ))}
                  <Col md="12" className="text-center">
                    <Pagination
                      items={this.state.exampleItems}
                      onChangePage={this.onChangePage}
                      pageSize={this.state.pageSize}
                    ></Pagination>
                  </Col>
                </Row>
              </Col>
            )}
            {this.state.is_first && (
              <Fragment>
                <Col className="w-70p">
                  <Row>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Fragment>
            )}
            <Col className="w-30p">
              <div className="widget  mt-22">
                <Iis applyFilter={this.handleFilter} />
              </div>
              <div className="widget">
                <Freesubs />
              </div>
              <div className="widget">
                <Followus />
              </div>
              <div className="widget">
                <Downloadapp />
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Globalii;
