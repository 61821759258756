import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Applepod from '../../../assets/images/apod.png';
import Googlepod from '../../../assets/images/google-podcast.png';
import Soundcloudpod from '../../../assets/images/soundcloud-podcast.png';
import Spotifypod from '../../../assets/images/spotify-podcast.png';
import './podcast-btn.css'

class Podcastbtn extends Component {
    render()
    {
        return <div className="btn-group d-flex justify-content-end">
            <Link to="#">
                <img src={Applepod} className="img-fluid" alt=""/>
            </Link>
            <Link to="#">
            <img src={Googlepod} className="img-fluid" alt=""/>
            </Link>
            <Link to="#">
            <img src={Soundcloudpod} className="img-fluid" alt=""/>
            </Link>
            <Link to="#">
            <img src={Spotifypod} className="img-fluid" alt=""/>
            </Link>
        </div>;
    }
}

export default Podcastbtn;
