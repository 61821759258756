import React, { Component } from "react";
import gpbtn from "../../../assets/images/google-play-md.png";
import iosbtn from "../../../assets/images/app-store-md.png";
import amazonebtn from "../../../assets/images/amazon-appstore.png";
import Appscreen from "../../../assets/images/appscreen-img.png";
import "./downloadapp.css";
import { Container, Row, Col } from "react-bootstrap/";
import { Link } from "react-router-dom";

class Downloadapp extends Component {
  render() {
    return (
      <div className="blew-box">
        <h3>Download AnalyzeMarkets App</h3>
        <Row>
          <Col md={8}>
            <p>
              {" "}
              Global Investment Intelligence and Global Investment Wire in the
              palm of your hand
            </p>
          </Col>

          <Col md={5} className="col-wx">
            <img
              src={Appscreen}
              className="img-fluid wct120"
              alt="App Screen"
            />
          </Col>
          <div className="playstoer-btn">
            {" "}
            <img src={iosbtn} className="mt-1 mb-1" alt="ios play" />
            <img src={gpbtn} className="mt-1 mb-1" alt="google play" />
            <img src={amazonebtn} className="mt-1 mb-1" alt="amazone play" />
          </div>
        </Row>

        {/* <div className="d-flex pt-3 pb-3">
          <p>
            Global Investment Intelligence and Global Investment Wire in the
            palm of your hand
          </p>

          <img src={Appscreen} className="mt-2 mb-2 wct119" alt="app screen" />

          {/* <img
            src="https://analyze-website-assets.s3-eu-west-1.amazonaws.com/appscreen-img.png"
            className="mt-2 mb-2 wct119"
            alt="appscreen" 
          />
        </div>*/}
      </div>
    );
  }
}

export default Downloadapp;
