import React, { Component, Fragment } from "react";
import "react-tabs/style/react-tabs.css";
import { Table, Row, Col, Container, Tab, Tabs, Nav } from "react-bootstrap";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import Datasort from "react-data-sort";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import "./global-company.database.css";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import Signin from "../../pages/signin/signinIndex";
import Fetch from "../../component/global/CommonComponent/useFetch";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class Basic_Infomation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      detail: [],
      real_estate: [],
      giim: [],
      peercount: 0,
      giwm: [],
      news: [],
      filterdata: "",
      real_estate_property: [],
      real_estate_buyer: [],
      jv_patners: [],
      debt_company_seeking: [],
      vc_deals: [],
      debt_deals: [],
      fund_deals: [],
      ipo_monitor: [],
      jv_deals: [],
      targets: [],
      buyer: [],
      itemcount: 0,
      deals_in_making: [],
      startup_fund: [],
      startup_buyer: [],
      acquirer_company: [],
      region_name: "",
      token: "",
      det: [],
      dets: [],
      det1: [],
      regions: "",
      subregions: "",
      det2: [],
      det3: [],
      det4: [],
      det5: [],
      det6: [],
      det7: [],
      det8: [],
      subregion_name: "",
      annual: [],
      quater: [],
      det9: [],
      det10: [],
      det11: [],
      det14: [],
      det15: [],
      det16: [],
      fund_round: [],
      id: "",
      industry: "",
      country_name: "",
      researchcount: 0,
      counts: 0,
      page: 0,
      filter: "",
      current: 0,
      country: [],
      items: [],
      fundcount: 0,
      research: [],
      old_route: "",
      oppcount: 0,
      pageSize: 50,
      count: 0,
      newscount: 0,
      is_first: true,
      filterdata: "",
    };
    this.handleData = this.handleData.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.handleOpp = this.handleOpp.bind(this);
    this.handleNews = this.handleNews.bind(this);
    this.handleResearch = this.handleResearch.bind(this);
    this.handlePeer = this.handlePeer.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleRegion = this.handleRegion.bind(this);
    this.handleSubRegion = this.handleSubRegion.bind(this);

    this.handleFund = this.handleFund.bind(this);
  }
  componentDidMount = () => {
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtsingleprivate/" +
        this.props.match.params.name +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, "Compnay Single Private");
      this.setState({
        details: res.data.companymngmntlinkbi,
        det: res.data.companymngmntlinksh,
        detail: res.data,
        dets: res.data.companymngmntlinkbrd,
        det1: res.data.companymngmntlinkmngmt,
        det2: res.data.GID,
        det3: res.data.companymngmntlinkadbrd,
        det4: res.data.companymngmntlinkmnginvstsubdiaries,
        det5: res.data.companymngmntlinksistercm,
        det6: res.data.companymngmntlinkprojct,
        det7: res.data.companymngmntlinkfinc,
        det8: res.data.companymngmntlinkadvis,
        id: res.data.id,
        country_name:
          res.data.country !== null && res.data.country.indexOf("&") != -1
            ? res.data.country.replace("&", "%26")
            : res.data.country,

        industry:
          res.data.subindustry !== null &&
            res.data.subindustry.indexOf("&") != -1
            ? res.data.subindustry.replace("&", "%26")
            : res.data.subindustry,

        regions:
          res.data.region !== null && res.data.region.indexOf("&") != -1
            ? res.data.region.replace("&", "%26")
            : res.data.region,

        subregions:
          res.data.sub_region !== null && res.data.sub_region.indexOf("&") != -1
            ? res.data.sub_region.replace("&", "%26")
            : res.data.sub_region,

        // acquirer: res.data.GDDMandADealsAcquirer,
        // target: res.data.GDDMandADealsTarget,
        // DealValuation: res.data.GDDMandADealsValidation,
      });
      this.handleData(res.data.id, filterdata);
      this.handleNews(res.data.id, filterdata);
      this.handleFund(res.data.id, filterdata);
      this.handleResearch(res.data.id, filterdata);
      this.handleOpp(res.data.id, filterdata);
      this.handlePeer(res.data.id, this.state.industry, filterdata);

      this.handleRegion(
        res.data.id,
        this.state.industry,
        this.state.regions,
        filterdata
      );

      this.handleSubRegion(
        res.data.id,
        this.state.industry,
        this.state.subregions,
        filterdata
      );

      this.handleCountry(
        res.data.id,
        this.state.industry,
        this.state.country_name,
        filterdata
      );

      console.log(this.state.id, "company id items");
      console.log(this.state.industry, "Sub Industry");
    });
  };
  handleData(id, filterdata) {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtdealsection/a/?id=" +
        id +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(this.state.itemcount, "length");
      console.log(res, "Deals Id");

      this.setState({
        items: res.data.MandA,
        itemcount: res.data.count,
        real_estate: res.data.RealEstateDeals,
        jv_deals: res.data.JVDeals,
        vc_deals: res.data.GDDVCDeals,
        debt_deals: res.data.DebtDeals,
        fund_deals: res.data.FundraisingDeals,
        ipo_monitor: res.data.IPOMonitor,
      });
    });
  }
  handleNews(id, filterdata) {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtnewssection/a/?id=" +
        id +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, "data items");

      this.setState({
        newscount: res.data.count,
        giim: res.data.GIIM,
        giwm: res.data.GIWM,
        news: res.data.CMS,
      });
    });
  }
  handleFund(id, filterdata) {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtfundingrounds/?id=" +
        id +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "data Fund");

      this.setState({
        det11: res.data.GDDVCDeals,
        fundcount: res.data.count,
        fund_round: res.data.totalfundingamount,
      });
    });
  }
  handleResearch(id, filterdata) {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtresearchsection/a/?id=" +
        id +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, "data items");

      this.setState({
        research: res.data.result,
        researchcount: res.data.count,
      });
    });
  }
  handleOpp(id, filterdata) {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtopportunitysection/a/?id=" +
        id +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, "data items");

      this.setState({
        targets: res.data.COMPANIES_SEEKING_TARGETS,
        buyer: res.data.COMPANIES_SEEKING_BUYERS,
        deals_in_making: res.data.DEALS_IN_MAKING,
        startup_fund: res.data.STARTUPS_SEEKING_FUNDING,
        startup_buyer: res.data.STARTUPS_SEEKING_BUYERS,
        real_estate_property: res.data.REAL_ESTATE_COMPANIES_SEEKING_PROPERTIES,
        real_estate_buyer: res.data.REAL_ESTATE_COMPANIES_SEEKING_BUYERS,
        jv_patners: res.data.COMPANIES_SEEKING_JV_PARTERS,
        debt_company_seeking: res.data.COMPANIES_SEEKING_DEBT_FINANCING,
        oppcount: res.data.count,
      });
    });
  }
  handlePeer(id, subindustry, filterdata) {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtpeercompanies/?subindustry=" +
        subindustry +
        "&id=" +
        id +
        filterdata,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "data Peer");

      this.setState({
        det10: res.data.data,
        peercount: res.data.count,
      });
    });
  }
  handleRegion(id, subindustry, region, filterdata) {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtpeercompanies/?subindustry=" +
        subindustry +
        "&id=" +
        id +
        "&region=" +
        region +
        filterdata,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Region Name");

      this.setState({
        det14: res.data.data,
      });
    });
  }
  handleSubRegion(id, subindustry, subregion, filterdata) {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtpeercompanies/?subindustry=" +
        subindustry +
        "&id=" +
        id +
        "&subregion=" +
        subregion +
        filterdata,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "data Country");

      this.setState({
        det15: res.data.data,
      });
    });
  }
  handleCountry(id, subindustry, country, filterdata) {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmtpeercompanies/?subindustry=" +
        subindustry +
        "&id=" +
        id +
        "&country=" +
        country +
        filterdata,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "data Country");

      this.setState({
        det16: res.data.data,
      });
    });
  }

  render() {
    const data1 = this.state.detail;
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            {console.log(this.state.giim.length, "length")}
            <Container>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Helmet title={data1.name}>
                <meta
                  name="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta property="og:title" content={data1.name} />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta name="og:description" content={data1.description} />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta itemprop="description" content={data1.description} />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>

              <Navfilter id="sector-desktop" className="d-lg-none"></Navfilter>
              <Row>
                <Col sm={3}> </Col>
                <Col sm={6}>
                  <h3 className="company-title">{data1.name}</h3>
                  <div className="newstagd">
                    {"Also Known as: " + data1.known_as_name}
                  </div>
                  <div className="newstagd padddown">
                    {"Previous Name: " + data1.old_name}
                  </div>
                </Col>
                <Col sm={3}>
                  <img
                    src={data1.image}
                    alt={data1.name}
                    className="img-fluid clogo-d"
                  ></img>
                </Col>
              </Row>

              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="borderback1">
                  <Col sm={10}></Col>
                  <Col sm={3} className="navss-item">
                    <Nav variant="pills" className="flex-column">
                      {this.state.detail.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Basic Infomation
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}

                      {this.state.det.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Shareholders
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {this.state.dets.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="third"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Board
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {this.state.det1.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="fourth"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Management
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {this.state.det4.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="fifth"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Investment Subsidaries
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {this.state.itemcount !== 0 && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="sixth"
                            onClick={() =>
                              this.handleData(
                                this.state.id,
                                this.state.filterdata
                              )
                            }
                          >
                            Deals
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {this.state.oppcount !== 0 && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="eight"
                            onClick={() =>
                              this.handleOpp(
                                this.state.id,
                                this.state.filterdata
                              )
                            }
                          >
                            Opportunities
                          </Nav.Link>
                        </Nav.Item>
                      )}

                      {this.state.det5.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="ninth"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Sister Companies
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {this.state.det6.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="tenth"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Projects
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {this.state.det7.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="eleventh"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Financials
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {this.state.newscount !== 0 && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="thirteen"
                            onClick={() =>
                              this.handleNews(
                                this.state.id,
                                this.state.filterdata
                              )
                            }
                          >
                            Investment Wire
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {this.state.researchcount !== 0 && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="fourteen"
                            onClick={() =>
                              this.handleResearch(
                                this.state.id,
                                this.state.filterdata
                              )
                            }
                          >
                            Research
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {this.state.det8.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="fifteen"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Advisors and Services Providers
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {this.state.peercount !== 0 && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="sixteen"
                            onClick={() =>
                              this.handlePeer(
                                this.state.id,
                                this.state.industry,
                                this.state.filterdata
                              )
                            }
                          >
                            Peer Companies
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {this.state.fundcount !== 0 && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="seventeen"
                            onClick={() =>
                              this.handleFund(
                                this.state.id,
                                this.state.filterdata
                              )
                            }
                          >
                            Funding Rounds
                          </Nav.Link>
                        </Nav.Item>
                      )}

                      {/*
                  <Nav.Item >
                    <Nav.Link eventKey="seventh">Funds</Nav.Link>
                  </Nav.Item>
                    <Nav.Link eventKey="ninth">Sister Companies</Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="tenth">Projects</Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="eleventh">Financials</Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="twelth">Investment Exits</Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="thirteen">News/PR </Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="fourteen">Research</Nav.Link>
                  </Nav.Item>

                  <Nav.Item >
                    <Nav.Link eventKey="fifteen">
                      Advisors and Services Providers
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="sixteen">Peer Companies </Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="seventeen">Fundring Rounds </Nav.Link>
                  </Nav.Item>
                  <Nav.Item >
                    <Nav.Link eventKey="eighteen">VC Investors</Nav.Link>
                  </Nav.Item>
                  */}
                      {this.state.det2.length !== 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="nineteen"
                            onClick={() =>
                              this.handleDetail(this.state.filterdata)
                            }
                          >
                            Investor Profile{" "}
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        {/* <span className="companyimage">
                      {<img src={data.image} alt="" className="img-logo"></img>}
                    </span>*/}
                        <div className="borderback"></div>

                        <Table size="sm" responsive="lg">
                          <tbody>
                            {data1.description !== "" && (
                              <tr className="padup">
                                <td>
                                  <b>Description</b>
                                </td>
                                <td className="word-wrap">
                                  {data1.description}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                        <Table size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              {data1.country !== null && (
                                <td width="200">
                                  <b>Country (Head Office)</b>
                                </td>
                              )}
                              {data1.country !== null && (
                                <td width="250">{data1.country}</td>
                              )}
                              {data1.status !== null && (
                                <td width="200" className="showInDesktop">
                                  <b>Company Status</b>
                                </td>
                              )}
                              {data1.status !== null && (
                                <td className="showInDesktop">{data1.status === true ? "Active" : ""}</td>
                              )}
                            </tr>
                            <tr className="showInMobile">
                              {data1.status !== null && (
                                <td width="200">
                                  <b>Company Status</b>
                                </td>
                              )}
                              {data1.status !== null && (
                                <td>{data1.status === true ? "Active" : ""}</td>
                              )}
                            </tr>
                            {this.state.details.map((item, key) => (
                              <tr>
                                {item.yearofestablishment !== null && (
                                  <td>
                                    <b>Year of Establishment</b>
                                  </td>
                                )}
                                {item.yearofestablishment !== null && (
                                  <td>{item.yearofestablishment}</td>
                                )}
                                {item.foundersname_gpd.length !== 0 && (
                                  <td>
                                    <b>Founders Name</b>
                                  </td>
                                )}
                                {item.foundersname_gpd.length !== 0 && (
                                  <td>
                                    <div>
                                      {item.foundersname_gpd.map(
                                        (company, key) => (
                                          <Link
                                            to={
                                              "/en/gross-professional-database-detail/" +
                                              company.api_url +
                                              "/"
                                            }
                                          >
                                            {company.name}
                                            {key === 0 && ", "}
                                          </Link>
                                        )
                                      )}

                                      {item.foundersname_cdb.map(
                                        (company_cdb, index) => (
                                          <Link
                                            to={
                                              "/en/global-company-database-details/" +
                                              company_cdb.api_url +
                                              "/"
                                            }
                                            target="_blank"
                                          >
                                            {index === 0 && " , "}
                                            {company_cdb.name}
                                          </Link>
                                        )
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            ))}
                            <tr>
                              {data1.sector !== null && (
                                <td>
                                  <b>Sector</b>
                                </td>
                              )}
                              {data1.sector !== null && <td>{data1.sector}</td>}
                              {this.state.details.map(
                                (item, key) =>
                                  item.shariahcompliant !== null && (
                                    <td>
                                      <b>Shariah Compliant</b>
                                    </td>
                                  )
                              )}
                              {this.state.details.map(
                                (item, key) =>
                                  item.shariahcompliant !== null && (
                                    <td>
                                      <div>{item.shariahcompliant}</div>
                                    </td>
                                  )
                              )}
                            </tr>
                            <tr>
                              {data1.company_legal_type !== null && (
                                <td>
                                  <b>Company Legal Type</b>
                                </td>
                              )}
                              {data1.company_legal_type !== null && (
                                <td>
                                  <div>{data1.company_legal_type}</div>
                                </td>
                              )}
                              {this.state.details.map(
                                (item, key) =>
                                  item.creditrating !== null && (
                                    <td>
                                      <b>Credit Rating</b>
                                    </td>
                                  )
                              )}
                              {this.state.details.map(
                                (item, key) =>
                                  item.creditrating !== null && (
                                    <td>
                                      <div>{item.creditrating}</div>
                                    </td>
                                  )
                              )}
                            </tr>
                            <tr>
                              {data1.region !== null && (
                                <td>
                                  <b>Region</b>
                                </td>
                              )}
                              {data1.region !== null && (
                                <td>
                                  <div>{data1.region}</div>
                                </td>
                              )}

                              {data1.subindustry !== null && (
                                <td className="showInDesktop">
                                  <b>Industry</b>
                                </td>
                              )}
                              {data1.subindustry !== null && (
                                <td className="showInDesktop">
                                  <div>{data1.subindustry}</div>
                                </td>
                              )}
                            </tr>

                            <tr className="showInMobile">
                            {data1.subindustry !== null && (
                                <td>
                                  <b>Industry</b>
                                </td>
                              )}
                              {data1.subindustry !== null && (
                                <td>
                                  <div>{data1.subindustry}</div>
                                </td>
                              )}
                            </tr>

                            <tr>
                              {data1.sub_region !== null && (
                                <td>
                                  <b>Sub-Region</b>
                                </td>
                              )}
                              {data1.sub_region !== null && (
                                <td>
                                  <div>{data1.sub_region}</div>
                                </td>
                              )}
                            </tr>
                            {this.state.details.map(
                              (item, key) =>
                                item.totalemployees !== null && (
                                  <tr>
                                    <td>
                                      <b>Total Employees</b>
                                    </td>
                                    <td>
                                      <div>{item.totalemployees}</div>
                                    </td>
                                  </tr>
                                )
                            )}
                            {this.state.details.map(
                              (item, key) =>
                                item.headofficeaddress !== null && (
                                  <tr>
                                    <td>
                                      <b>Head Office Address</b>
                                    </td>
                                    <td>
                                      <div>{item.headofficeaddress}</div>
                                    </td>
                                  </tr>
                                )
                            )}
                            {this.state.details.map(
                              (item, key) =>
                                item.headofficecity !== null && (
                                  <tr>
                                    <td>
                                      <b>Head Office City</b>
                                    </td>
                                    <td>
                                      {this.state.details.map((item, key) => (
                                        <div>{item.headofficecity}</div>
                                      ))}
                                    </td>
                                  </tr>
                                )
                            )}
                            {data1.url !== null && (
                              <tr>
                                <td>
                                  <b>Website</b>
                                </td>
                                <td>{data1.url}</td>
                              </tr>
                            )}
                            {this.state.details.map(
                              (item, key) =>
                                item.officetel !== null && (
                                  <tr>
                                    <td>
                                      <b>Office Tel</b>
                                    </td>

                                    <td>
                                      {this.state.details.map((item, key) => (
                                        <div>{item.officetel}</div>
                                      ))}
                                    </td>
                                  </tr>
                                )
                            )}
                            {this.state.details.map(
                              (item, key) =>
                                item.contactEmail !== null && (
                                  <tr>
                                    <td>
                                      <b>Contact E-mail</b>
                                    </td>
                                    <td>
                                      <div>{item.contactEmail}</div>
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </Table>
                      </Tab.Pane>

                      <Tab.Pane eventKey="second">
                        <div className="borderback"></div>
                        <br></br>
                        <Table size="sm" responsive="lg" bordered striped>
                          <thead>
                            <tr>
                              <th className="colorWhite">Shareholders Name</th>
                              <th className="colorWhite">% Stake</th>
                              <th className="colorWhite">Type</th>
                              <th className="colorWhite">Country</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.det.map((item, key) => (
                              <tr>
                                {/* {item.shareholdersname_gpd !== null && ( */}
                                <td>
                                  <div>
                                    {item.shareholdersname_gpd !== null && (
                                      <Link
                                        to={
                                          "/en/gross-professional-database-detail/" +
                                          item.shareholdersname_gpd.api_url +
                                          "/"
                                        }
                                      >
                                        {item.shareholdersname_gpd.name}
                                      </Link>
                                    )}
                                    {item.shareholdersname_cdb !== null && (
                                      <Link
                                        to={
                                          "/en/global-company-database-details/" +
                                          item.shareholdersname_cdb.api_url +
                                          "/"
                                        }
                                        target="_blank"
                                      >
                                        {item.shareholdersname_cdb.name}
                                      </Link>
                                    )}
                                  </div>
                                </td>
                                {item.per_stake !== null && (
                                  <td>{item.per_stake}</td>
                                )}
                                {item.type !== null && <td>{item.type}</td>}
                                {item.country !== null && (
                                  <td>
                                    <div>{item.country}</div>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Tab.Pane>

                      <Tab.Pane eventKey="third">
                        <div className="borderback"></div>
                        <br></br>

                        <h3>Board of Directors</h3>

                        <Table size="sm" responsive="lg" bordered striped>
                          <thead>
                            <tr>
                              <th className="colorWhite">Name</th>

                              <th className="colorWhite">Title</th>

                              <th className="colorWhite">Email</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.dets.map((item, key) => (
                              <tr>
                                {item.name !== null && (
                                  <td>
                                    <div>
                                      {item.name.api_url != null ? (
                                        <Link
                                          to={
                                            "/en/gross-professional-database-detail/" +
                                            item.name.api_url +
                                            "/"
                                          }
                                        >
                                          {item.name.name}
                                        </Link>
                                      ) : (
                                        <Link>
                                          <u className="underline">
                                            {item.name.name}
                                          </u>
                                        </Link>
                                      )}
                                    </div>
                                  </td>
                                )}
                                {item.title !== null && <td> {item.title}</td>}
                                <td> {item.email !== null && item.email}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <br></br>
                        {this.state.det3.length !== 0 && (
                          <h3>Advisory Board</h3>
                        )}
                        {this.state.det3.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Name</th>
                                <th className="colorWhite">Title</th>
                                <th className="colorWhite">Email</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.det3.map((item, key) => (
                                <tr>
                                  {item.name !== null && (
                                    <td>
                                      <div>
                                        {item.name.api_url != null ? (
                                          <Link
                                            to={
                                              "/en/gross-professional-database-detail/" +
                                              item.name.api_url +
                                              "/"
                                            }
                                          >
                                            {item.name.name}
                                          </Link>
                                        ) : (
                                          <Link>
                                            <u className="underline">
                                              {item.name.name}
                                            </u>
                                          </Link>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                  {item.title !== null && <td>{item.title}</td>}
                                  {item.email !== null && <td>{item.email}</td>}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </Tab.Pane>

                      <Tab.Pane eventKey="fourth">
                        <br></br>
                        <Table size="sm" responsive="lg" bordered striped>
                          <thead>
                            <tr>
                              <th className="colorWhite">Name</th>
                              <th className="colorWhite">Title</th>
                              <th className="colorWhite">Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.det1.map((item, key) => (
                              <tr>
                                {item.name !== null && (
                                  <td>
                                    <div>
                                      {item.name.api_url != null ? (
                                        <Link
                                          to={
                                            "/en/gross-professional-database-detail/" +
                                            item.name.api_url +
                                            "/"
                                          }
                                        >
                                          {item.name.name}
                                        </Link>
                                      ) : (
                                        <Link>
                                          <u className="underline">
                                            {item.name.name}
                                          </u>
                                        </Link>
                                      )}
                                    </div>
                                  </td>
                                )}
                                {item.title !== null && (
                                  <td>
                                    <div>{item.title}</div>
                                  </td>
                                )}
                                {item.email !== null && (
                                  <td>
                                    <div>{item.email}</div>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Tab.Pane>

                      <Tab.Pane eventKey="fifth">
                        <br></br>
                        <Table size="sm" responsive="lg" bordered striped>
                          {this.state.det4.map(
                            (item, key) =>
                              key === 0 && (
                                <thead>
                                  <tr>
                                    {item.company !== null && (
                                      <th className="colorWhite">Company</th>
                                    )}
                                    {item.country !== null && (
                                      <th className="colorWhite">Country</th>
                                    )}
                                    {item.sector !== null && (
                                      <th className="colorWhite">Sector</th>
                                    )}
                                    {item.type !== null && (
                                      <th className="colorWhite">Type</th>
                                    )}
                                    {item.yearofinvestment !== null && (
                                      <th className="colorWhite">
                                        Year of Investment
                                      </th>
                                    )}
                                    {item.per_Stake !== null && (
                                      <th className="colorWhite">% Stake</th>
                                    )}
                                  </tr>
                                </thead>
                              )
                          )}
                          <tbody>
                            {this.state.det4.map((item, key) => (
                              <tr>
                                {item.company !== null && (
                                  <td>
                                    <div>
                                      {item.company.api_url != null ? (
                                        <Link
                                          to={
                                            "/en/global-company-database-details/" +
                                            item.company.api_url +
                                            "/"
                                          }
                                          target="_blank"
                                        >
                                          {item.company.name}
                                        </Link>
                                      ) : (
                                        <Link>
                                          <u className="underline">
                                            {item.company.name}
                                          </u>
                                        </Link>
                                      )}
                                    </div>
                                  </td>
                                )}
                                {item.country !== null &&
                                  this.state.det4.length < 2 ? (
                                  <td>{item.country}</td>
                                ) : (
                                  item.country !== null && (
                                    <td>{item.country}</td>
                                  )
                                )}
                                {item.sector !== null &&
                                  this.state.det4.length < 2 ? (
                                  <td>{item.sector}</td>
                                ) : (
                                  item.sector !== null && <td>{item.sector}</td>
                                )}

                                {item.type !== null &&
                                  this.state.det4.length < 2 ? (
                                  <td>{item.type}</td>
                                ) : (
                                  item.type !== null && <td>{item.type}</td>
                                )}

                                {item.yearofinvestment !== null &&
                                  this.state.det4.length < 2 ? (
                                  <td>{item.yearofinvestment}</td>
                                ) : (
                                  item.yearofinvestment !== null && (
                                    <td>{item.yearofinvestment}</td>
                                  )
                                )}
                                {item.per_Stake !== null &&
                                  this.state.det4.length < 2 ? (
                                  <td>{item.per_Stake}</td>
                                ) : (
                                  item.per_Stake !== null && (
                                    <td>{item.per_Stake}</td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Tab.Pane>
                      <Tab.Pane eventKey="sixth" className="Minheight">
                        {this.state.items.length !== 0 && (
                          <h3 className="padTopDown">M & A Deals</h3>
                        )}

                        {this.state.items.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Deal</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Deal Value (Reporting Currency)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.items.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/ma-deals-detail/" +
                                          item.api_url +
                                          "/"
                                        }
                                      >
                                        {item.deal}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">{item.deal}</u>
                                      </Link>
                                    )}
                                  </td>

                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.announced_reported_date}
                                    </Moment>
                                  </td>
                                  <td>{item.deal_value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        {this.state.real_estate.length !== 0 && (
                          <h3 className="padTopDown">Real Estate Deals</h3>
                        )}
                        {this.state.real_estate.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Deal</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Deal Value (Reporting Currency)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.real_estate.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/re-detail/" + item.api_url + "/"
                                        }
                                      >
                                        {item.deal}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">{item.deal}</u>
                                      </Link>
                                    )}
                                  </td>

                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.announced_reported}
                                    </Moment>
                                  </td>
                                  <td>{item.deal_value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        {this.state.jv_deals.length !== 0 && (
                          <h3 className="padTopDown">Joint Venture</h3>
                        )}

                        {this.state.jv_deals.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Deal</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">Company Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.jv_deals.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/jv-detail/" + item.api_url + "/"
                                        }
                                      >
                                        {item.deal}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">{item.deal}</u>
                                      </Link>
                                    )}
                                  </td>

                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.announced_reported}
                                    </Moment>
                                  </td>
                                  <td>{item.company}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        {this.state.vc_deals.length !== 0 && (
                          <h3 className="padTopDown">Venture Capital</h3>
                        )}

                        {this.state.vc_deals.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Deal</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Investment Amount (Reporting Currency)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.vc_deals.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/vc-deals-detail/" +
                                          item.api_url +
                                          "/"
                                        }
                                      >
                                        {item.deal}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">{item.deal}</u>
                                      </Link>
                                    )}
                                  </td>

                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.reported_date}
                                    </Moment>
                                  </td>
                                  <td>{item.investment_amount_raised}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        {this.state.debt_deals.length !== 0 && (
                          <h3 className="padTopDown">Debt Deals</h3>
                        )}
                        {this.state.debt_deals.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Deal</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">Deal Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.debt_deals.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/debt-detail/" +
                                          item.api_url +
                                          "/"
                                        }
                                      >
                                        {item.deal}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">{item.deal}</u>
                                      </Link>
                                    )}
                                  </td>

                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.announced_reported}
                                    </Moment>
                                  </td>
                                  <td>{item.deal_value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        <br></br>
                        {this.state.fund_deals.length !== 0 && (
                          <h3 className="padTopDown">FundRaising Deals</h3>
                        )}
                        {this.state.fund_deals.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Deal</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Total Amount Raised (Reporting Currency)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.fund_deals.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/fund-raising-detail/" +
                                          item.api_url +
                                          "/"
                                        }
                                      >
                                        {item.deal}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">{item.deal}</u>
                                      </Link>
                                    )}
                                  </td>

                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.announced_reported}
                                    </Moment>
                                  </td>
                                  <td>{item.total_amount_raised}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        {this.state.ipo_monitor.length !== 0 && (
                          <h3 className="padTopDown">Potential Ipo</h3>
                        )}
                        {this.state.ipo_monitor.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Company Name</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">IPO Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.ipo_monitor.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/global-company-database-details/" +
                                          item.api_url +
                                          "/"
                                        }
                                        target="_blank"
                                      >
                                        {item.company}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">
                                          {item.company}
                                        </u>
                                      </Link>
                                    )}
                                  </td>

                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.reported_date}
                                    </Moment>
                                  </td>
                                  <td>{item.ipo_status}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="seventh">
                        <br></br>
                        <Table>
                          <thead>
                            <tr>
                              <th>Fund Name</th>
                              <th>Fund Start Date</th>
                              <th>Fund Size (reporting currency)</th>
                              <th>Fund Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab.Pane>
                      <Tab.Pane eventKey="eight">
                        {this.state.deals_in_making.length !== 0 && (
                          <h3 className="padTopDown">Deals In Making</h3>
                        )}
                        {this.state.deals_in_making.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Potential Deal</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Potential Deal Value (Reporting Currency)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.deals_in_making.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/deals-in-making-detail/" +
                                          item.api_url +
                                          "/"
                                        }
                                      >
                                        {item.potential_Deal}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">
                                          {item.potential_Deal}
                                        </u>
                                      </Link>
                                    )}
                                  </td>
                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.potential_reported_date}
                                    </Moment>
                                  </td>
                                  <td>{item.potential_deal_value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        {this.state.targets.length !== 0 && (
                          <h3 className="padTopDown">
                            Companies Seeking Targets
                          </h3>
                        )}
                        {this.state.targets.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Opportunity</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Potential Deal Value (Reporting Currency)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.targets.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/ma-company-seeking-target-detail/" +
                                          item.api_url +
                                          "/"
                                        }
                                      >
                                        {item.opportunity}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">
                                          {item.opportunity}
                                        </u>
                                      </Link>
                                    )}
                                  </td>
                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.announced_reported_date}
                                    </Moment>
                                  </td>
                                  <td>{item.deal_value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        {this.state.buyer.length !== 0 && (
                          <h3 className="padTopDown">
                            Companies Seeking Buyers
                          </h3>
                        )}
                        {this.state.buyer.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Opportunity</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Potential Deal Value (Reporting Currency)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.buyer.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/ma-company-seeking-buyers-detail/" +
                                          item.api_url +
                                          "/"
                                        }
                                      >
                                        {item.opportunity}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">
                                          {item.opportunity}
                                        </u>
                                      </Link>
                                    )}
                                  </td>
                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.announced_reported_date}
                                    </Moment>
                                  </td>
                                  <td>{item.deal_value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        {this.state.startup_fund.length !== 0 && (
                          <h3 className="padTopDown">
                            Startups Seeking Funding{" "}
                          </h3>
                        )}
                        {this.state.startup_fund.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Opportunity</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">Country</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.startup_fund.map((item, key) => (
                                <tr>
                                  <td>
                                    {/* {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/ma-company-seeking-buyers-details/" +
                                      item.api_url +
                                      "/"  }
                                  >
                                    {item.deal}
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">{item.deal}</u>
                                  </Link>
                                )} */}
                                    {item.deal}
                                  </td>
                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.reported_date}
                                    </Moment>
                                  </td>
                                  <td>{item.country}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        {this.state.startup_buyer.length !== 0 && (
                          <h3 className="padTopDown">
                            Startups Seeking Buyers{" "}
                          </h3>
                        )}
                        {this.state.startup_buyer.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Opportunity</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.startup_buyer.map((item, key) => (
                                <tr>
                                  <td>
                                    {/* {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/ma-company-seeking-buyers-details/" +
                                      item.api_url +
                                      "/"  }
                                  >
                                    {item.deal}
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">{item.deal}</u>
                                  </Link>
                                )}*/}
                                    {item.deal}
                                  </td>
                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.reported_date}
                                    </Moment>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        {this.state.real_estate_property.length !== 0 && (
                          <h3 className="padTopDown">
                            Real Estate-Companies Seeking Properties
                          </h3>
                        )}
                        {this.state.real_estate_property.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Opportunity</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Potential Deal Value (Reporting Currency)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.real_estate_property.map(
                                (item, key) => (
                                  <tr>
                                    <td>
                                      {/* {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/ma-company-seeking-buyers-details/" +
                                      item.api_url +
                                      "/"  }
                                  >
                                    {item.deal}
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">{item.deal}</u>
                                  </Link>
                                )} */}
                                      {item.opportunity}
                                    </td>
                                    <td>
                                      <Moment
                                        format="DD MMM YYYY"
                                        className="DateFont"
                                      >
                                        {item.announced_reported}
                                      </Moment>
                                    </td>
                                    <td>{item.deal_value}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        )}
                        {this.state.real_estate_buyer.length !== 0 && (
                          <h3 className="padTopDown">
                            Real Estate-Companies Seeking Buyers
                          </h3>
                        )}
                        {this.state.real_estate_buyer.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Opportunity</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Potential Deal Value (Reporting Currency)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.real_estate_buyer.map((item, key) => (
                                <tr>
                                  <td>
                                    {/* {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/ma-company-seeking-buyers-details/" +
                                      item.api_url +
                                      "/"  }
                                  >
                                    {item.deal}
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">{item.deal}</u>
                                  </Link>
                                )} */}
                                    {item.opportunity}
                                  </td>
                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.announced_reported}
                                    </Moment>
                                  </td>
                                  <td>{item.deal_value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        {this.state.jv_patners.length !== 0 && (
                          <h3 className="padTopDown">
                            Companies Seeking JV Patner
                          </h3>
                        )}
                        {this.state.jv_patners.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Opportunity</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">
                                  Potential JV Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.jv_patners.map((item, key) => (
                                <tr>
                                  <td>
                                    {/* {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/ma-company-seeking-buyers-details/" +
                                      item.api_url +
                                      "/"  }
                                  >
                                    {item.deal}
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">{item.deal}</u>
                                  </Link>
                                )} */}
                                    {item.opportunity}
                                  </td>
                                  <td>
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.announced_reported}
                                    </Moment>
                                  </td>
                                  <td>{item.deal_status}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        {this.state.debt_company_seeking.length !== 0 && (
                          <h3 className="padTopDown">
                            Companies Seeking Debt Financing
                          </h3>
                        )}

                        {this.state.debt_company_seeking.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Opportunity</th>
                                <th className="colorWhite">
                                  Announced/Reported Date
                                </th>
                                <th className="colorWhite">Deal Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.debt_company_seeking.map(
                                (item, key) => (
                                  <tr>
                                    <td>
                                      {/* {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/ma-company-seeking-buyers-details/" +
                                      item.api_url +
                                      "/"  }
                                  >
                                    {item.deal}
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">{item.deal}</u>
                                  </Link>
                                )} */}
                                      {item.opportunity}
                                    </td>
                                    <td>
                                      <Moment
                                        format="DD MMM YYYY"
                                        className="DateFont"
                                      >
                                        {item.announced_reported}
                                      </Moment>
                                    </td>
                                    <td>{item.deal_status}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="ninth">
                        <br></br>
                        <Table size="sm" responsive="lg" bordered striped>
                          <thead>
                            <tr>
                              <th className="colorWhite">Company</th>
                              <th className="colorWhite">Country</th>
                              <th className="colorWhite">Sector</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.det5.map((item, key) => (
                              <tr>
                                {item.company !== null && (
                                  <td>
                                    <div>
                                      {item.company.api_url != null ? (
                                        <Link
                                          to={
                                            "/en/global-company-database-details/" +
                                            item.company.api_url +
                                            "/"
                                          }
                                          target="_blank"
                                        >
                                          {item.company.name}
                                        </Link>
                                      ) : (
                                        <Link>
                                          <u className="underline">
                                            {item.company.name}
                                          </u>
                                        </Link>
                                      )}
                                    </div>
                                  </td>
                                )}

                                <td>{item.country}</td>
                                <td>{item.sector}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tenth">
                        <br></br>
                        <Table size="sm" responsive="lg" bordered striped>
                          <thead>
                            <tr>
                              <th className="colorWhite">Project Name</th>
                              <th className="colorWhite">Country</th>
                              <th className="colorWhite">Sector</th>
                              <th className="colorWhite">Project Value </th>
                              <th className="colorWhite">Status</th>
                              <th className="colorWhite">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.det6.map((item, key) => (
                              <tr>
                                <td className="word-wrap">
                                  {item.projectname}
                                </td>
                                <td>{item.country}</td>
                                <td>{item.sector}</td>
                                <td>{item.projectvalue}</td>
                                <td>{item.status}</td>
                                <td className="word-wrap" width="400">
                                  <p
                                    className=""
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Tab.Pane>
                      <Tab.Pane eventKey="eleventh">
                        <Table size="sm" responsive="lg">
                          {this.state.det7.map((item, key) => (
                            <tbody>
                              {key === 0 && (
                                <tr>
                                  <td width="150">
                                    <b>Comment</b>
                                  </td>
                                  <td className="word-wrap">
                                    <p
                                      className=""
                                      dangerouslySetInnerHTML={{
                                        __html: item.comment,
                                      }}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          ))}
                        </Table>
                        <br></br>
                        <Table size="sm" responsive="lg" bordered striped>
                          {this.state.det7.map((item, key) => (
                            <thead>
                              {key === 0 && (
                                <tr>
                                  <th className="colorWhite">Title</th>
                                  <th className="colorWhite">For the Period</th>
                                  <th className="colorWhite">Download</th>
                                </tr>
                              )}
                            </thead>
                          ))}
                          {this.state.det7.map((item, key) => (
                            <tbody>
                              <tr>
                                <td>{item.title}</td>
                                <td>{item.fortheperiod}</td>
                                <td>
                                  <span className="spaceRight">
                                    {item.uploadfile !== null && (
                                      <a
                                        href={item.uploadfile}
                                        target="_blank"
                                        download={item.uploadfile}
                                      >
                                        <i
                                          class="fa fa-file-pdf-o"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                        <br></br>
                      </Tab.Pane>
                      <Tab.Pane eventKey="thirteen" className="Minheight">
                        <Tabs
                          defaultActiveKey="home"
                          id="uncontrolled-tab-example"
                        >
                          {/* {this.state.giim.length !== 0 && (
                            <Tab
                              eventKey="home"
                              className=""
                              title="Global Investment Intelligence"
                            >
                              {this.state.giim.map((item, key) => (
                                <Row>
                                  <Col className="padDowns padup ">
                                    <Link
                                      to={"/en/intelligence/" + item.url + "/"}
                                    >
                                      {item.title}
                                    </Link>

                                    <div>
                                      <Moment
                                        format="DD MMM YYYY"
                                        className="font14"
                                      >
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                            </Tab>
                          )} */}
                          {this.state.giwm.length !== 0 && (
                            <Tab
                              // eventKey={
                              //   this.state.giim.length === 0
                              //     ? "home"
                              //     : "profile"
                              // }
                              eventKey="home"
                              title="Global Investment Wire"
                            >
                              {this.state.giwm.map((item, key) => (
                                <Row>
                                  <Col className="padDowns padup ">
                                    <Link to={"/en/wire/" + item.url + "/"}>
                                      {item.title}
                                    </Link>

                                    <div>
                                      <Moment
                                        format="DD MMM YYYY"
                                        className="font14"
                                      >
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                            </Tab>
                          )}
                          {this.state.news.length !== 0 && (
                            <Tab
                              eventKey={
                                this.state.giim.length === 0 &&
                                  this.state.giwm.length === 0
                                  ? "home"
                                  : "news"
                              }
                              title="News"
                            >
                              {this.state.news.map((item, key) => (
                                <Row>
                                  <Col className="padDowns padup ">
                                    <a href={item.url} target="_blank">
                                      {item.title}
                                    </a>

                                    <div>
                                      <Moment
                                        format="DD MMM YYYY"
                                        className="font14"
                                      >
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                            </Tab>
                          )}
                        </Tabs>
                      </Tab.Pane>

                      <Tab.Pane eventKey="fourteen">
                        <br></br>
                        {this.state.research.length !== 0 && (
                          <Table size="sm" responsive="lg" bordered striped>
                            <thead>
                              <tr>
                                <th className="colorWhite">Report Title</th>
                                <th className="colorWhite">Publication Date</th>
                                <th className="colorWhite">
                                  Report Contributor
                                </th>
                                <th className="colorWhite">No. of Pages</th>
                                <th className="colorWhite">Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.research.map((item, key) => (
                                <tr>
                                  <td>
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/global-research-report-details/" +
                                          item.api_url +
                                          "/"
                                        }
                                      >
                                        {item.title}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">
                                          {item.title}
                                        </u>
                                      </Link>
                                    )}
                                  </td>
                                  <td>{item.publicationdate}</td>
                                  {item.reportcontributor.map(
                                    (company, key) => (
                                      <td>
                                        <div>
                                          {company.api_url != null ? (
                                            <Link
                                              to={
                                                "/en/global-company-database-details/" +
                                                company.api_url +
                                                "/"
                                              }
                                            >
                                              <u className="underline">
                                                {company.name}
                                              </u>
                                            </Link>
                                          ) : (
                                            <Link>
                                              <u className="underline">
                                                {company.name}
                                              </u>
                                            </Link>
                                          )}
                                        </div>
                                      </td>
                                    )
                                  )}
                                  <td>{item.noofpages}</td>
                                  <td>
                                    <a
                                      href={item.uploadfile}
                                      target="_blank"
                                      download={item.uploadfile}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </Tab.Pane>

                      <Tab.Pane eventKey="fifteen">
                        {this.state.det8.map((item, key) => (
                          <Row className="">
                            <Col>
                              {key == 0 && (
                                <h3 className="padTopDown">Auditors:</h3>
                              )}
                              {item.auditors !== null ? (
                                <div>
                                  <Link
                                    to={
                                      "/en/global-company-database-details/" +
                                      item.auditors.api_url +
                                      "/"
                                    }
                                    target="_blank"
                                  >
                                    {item.auditors.name}
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        ))}

                        {this.state.det8.map((item, key) => (
                          <Row>
                            <Col className="">
                              {key == 0 && item.banks !== null && (
                                <h3 className="padTopsDown">Banks:</h3>
                              )}
                              {item.banks !== null && (
                                <div>
                                  <Link
                                    to={
                                      "/en/global-company-database-details/" +
                                      item.banks.api_url +
                                      "/"
                                    }
                                    target="_blank"
                                  >
                                    {item.banks.name}
                                  </Link>
                                </div>
                              )}
                            </Col>
                          </Row>
                        ))}

                        {this.state.det8.map((item, key) => (
                          <Row>
                            <Col className="">
                              {key == 0 && item.financialadvisors !== null && (
                                <h3 className="padTopsDown">
                                  Financial Advisors:
                                </h3>
                              )}
                              {item.financialadvisors !== null && (
                                <div>
                                  <Link
                                    to={
                                      "/en/global-company-database-details/" +
                                      item.financialadvisors.api_url +
                                      "/"
                                    }
                                    target="_blank"
                                  >
                                    {item.financialadvisors.name}
                                  </Link>
                                </div>
                              )}
                            </Col>
                          </Row>
                        ))}

                        {this.state.det8.map((item, key) => (
                          <Row>
                            {item.legaladvisors !== null && (
                              <Col className="">
                                {key == 0 &&
                                  item.legaladvisors.name !== null ? (
                                  <h3 className="padTopsDown">
                                    Legal Advisors:
                                  </h3>
                                ) : (
                                  ""
                                )}

                                <div>
                                  <Link
                                    to={
                                      "/en/global-company-database-details/" +
                                      item.legaladvisors.api_url +
                                      "/"
                                    }
                                    target="_blank"
                                  >
                                    {item.legaladvisors.name}
                                  </Link>
                                </div>
                              </Col>
                            )}
                          </Row>
                        ))}
                        {this.state.det8.map((item, key) => (
                          <Row>
                            <Col className="">
                              {key == 0 && item.taxadvisors !== null && (
                                <h3 className="padTopsDown">Tax Advisors:</h3>
                              )}
                              {item.taxadvisors !== null && (
                                <div>
                                  <Link
                                    to={
                                      "/en/global-company-database-details/" +
                                      item.taxadvisors.api_url +
                                      "/"
                                    }
                                    target="_blank"
                                  >
                                    {item.taxadvisors.name}
                                  </Link>
                                </div>
                              )}
                            </Col>
                          </Row>
                        ))}

                        {this.state.det8.map((item, key) => (
                          <Row>
                            <Col className="">
                              {key == 0 && item.consultants !== null && (
                                <h3 className="padTopDown">Consultants:</h3>
                              )}
                              {item.consultants !== null && (
                                <div>
                                  <Link
                                    to={
                                      "/en/global-company-database-details/" +
                                      item.consultants.api_url +
                                      "/"
                                    }
                                    target="_blank"
                                  >
                                    {item.consultants.name}
                                  </Link>
                                </div>
                              )}
                            </Col>
                          </Row>
                        ))}

                        {this.state.det8.map((item, key) => (
                          <Row>
                            <Col className="">
                              {key == 0 && item.pr !== null && (
                                <h3 className="padTopsDown">PR:</h3>
                              )}
                              {item.pr !== null && (
                                <div>
                                  <Link
                                    to={
                                      "/en/global-company-database-details/" +
                                      item.pr.api_url +
                                      "/"
                                    }
                                    target="_blank"
                                  >
                                    {item.pr.name}
                                  </Link>
                                </div>
                              )}
                            </Col>
                          </Row>
                        ))}

                        {this.state.det8.map((item, key) => (
                          <Row>
                            <Col className="">
                              {key == 0 && item.placementagent !== null && (
                                <h3 className="padTopsDown">
                                  Placement Agent:
                                </h3>
                              )}
                              {item.placementagent !== null && (
                                <div>
                                  <Link
                                    to={
                                      "/en/global-company-database-details/" +
                                      item.placementagent.api_url +
                                      "/"
                                    }
                                    target="_blank"
                                  >
                                    {item.placementagent.name}
                                  </Link>
                                </div>
                              )}
                            </Col>
                          </Row>
                        ))}

                        {this.state.det8.map((item, key) => (
                          <Row>
                            <Col className="">
                              {key == 0 &&
                                item.propertyagentbroker !== null && (
                                  <h3 className="padTopsDown">
                                    Property Agent/Broker:
                                  </h3>
                                )}
                              {item.propertyagentbroker !== null && (
                                <div>
                                  <Link
                                    to={
                                      "/en/global-company-database-details/" +
                                      item.propertyagentbroker.api_url +
                                      "/"
                                    }
                                    target="_blank"
                                  >
                                    {item.propertyagentbroker.name}
                                  </Link>
                                </div>
                              )}
                            </Col>
                          </Row>
                        ))}
                        <br></br>
                      </Tab.Pane>

                      <Tab.Pane eventKey="sixteen">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="first"
                        >
                          <br></br>
                          <Row>
                            <Col sm={12}>
                              {this.state.det10.length !== 0 && (
                                <Nav variant="pills" className="flex-columns">
                                  <Col sm={3.5}>
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="first"
                                        onClick={() =>
                                          this.handlePeer(
                                            this.state.id,
                                            this.state.industry,
                                            this.state.filterdata
                                          )
                                        }
                                      >
                                        Global Peer Companies
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Col>
                                  <Col sm={3.5}>
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="second"
                                        onClick={() =>
                                          this.handleRegion(
                                            this.state.id,
                                            this.state.industry,
                                            this.state.regions,
                                            this.state.filterdata
                                          )
                                        }
                                      >
                                        Regional Peer Companies
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Col>
                                  <Col sm={3.5}>
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="third"
                                        onClick={() =>
                                          this.handleSubRegion(
                                            this.state.id,
                                            this.state.industry,
                                            this.state.subregions,
                                            this.state.filterdata
                                          )
                                        }
                                      >
                                        Sub-Regional Peer Companies
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Col>
                                  <Col sm={3.5}>
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="fourth"
                                        onClick={() =>
                                          this.handleCountry(
                                            this.state.id,
                                            this.state.industry,
                                            this.state.country_name,
                                            this.state.filterdata
                                          )
                                        }
                                      >
                                        Country Peer Companies
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Col>
                                </Nav>
                              )}
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Col sm={12}>
                              <Tab.Content>
                                <Tab.Pane eventKey="first">
                                  {" "}
                                  {this.state.det10.length !== 0 && (
                                    <Table
                                      size="sm"
                                      responsive="lg"
                                      striped
                                      bordered
                                    >
                                      {this.state.det10.map((item, key) => (
                                        <thead>
                                          {key === 0 && (
                                            <tr>
                                              <th className="colorWhite">
                                                Peer Company
                                              </th>
                                              {item.company_legal_type !=
                                                null ? (
                                                <th className="colorWhite">
                                                  Company Legal Type
                                                </th>
                                              ) : (
                                                ""
                                              )}

                                              <th className="colorWhite">
                                                Sub-Industry
                                              </th>
                                              <th className="colorWhite">
                                                Country
                                              </th>
                                            </tr>
                                          )}
                                        </thead>
                                      ))}
                                      <tbody>
                                        {this.state.det10.map((item, key) => (
                                          <tr>
                                            <td>
                                              {item.api_url !== null && (
                                                <div>
                                                  <Link
                                                    to={
                                                      "/en/global-company-database-details/" +
                                                      item.api_url +
                                                      "/"
                                                    }
                                                    target="_blank"
                                                  >
                                                    {item.name}
                                                  </Link>
                                                </div>
                                              )}
                                            </td>
                                            {item.company_legal_type != null ? (
                                              <td>{item.company_legal_type}</td>
                                            ) : (
                                              ""
                                            )}
                                            {item.subindustry != null ? (
                                              <td>{item.subindustry}</td>
                                            ) : (
                                              ""
                                            )}
                                            {item.country != null ? (
                                              <td>{item.country}</td>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  )}
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                  {this.state.det14.length !== 0 && (
                                    <Table
                                      size="sm"
                                      responsive="lg"
                                      striped
                                      bordered
                                    >
                                      {this.state.det14.map((item, key) => (
                                        <thead>
                                          {key === 0 && (
                                            <tr>
                                              <th className="colorWhite">
                                                Peer Company
                                              </th>
                                              {item.company_legal_type !=
                                                null ? (
                                                <th className="colorWhite">
                                                  Company Legal Type
                                                </th>
                                              ) : (
                                                ""
                                              )}

                                              <th className="colorWhite">
                                                Sub-Industry
                                              </th>
                                              <th className="colorWhite">
                                                Country
                                              </th>
                                            </tr>
                                          )}
                                        </thead>
                                      ))}
                                      <tbody>
                                        {this.state.det14.map((item, key) => (
                                          <tr>
                                            <td>
                                              {item.api_url !== null && (
                                                <div>
                                                  <Link
                                                    to={
                                                      "/en/global-company-database-details/" +
                                                      item.api_url +
                                                      "/"
                                                    }
                                                    target="_blank"
                                                  >
                                                    {item.name}
                                                  </Link>
                                                </div>
                                              )}
                                            </td>
                                            {item.company_legal_type != null ? (
                                              <td>{item.company_legal_type}</td>
                                            ) : (
                                              ""
                                            )}
                                            {item.subindustry != null ? (
                                              <td>{item.subindustry}</td>
                                            ) : (
                                              ""
                                            )}
                                            {item.country != null ? (
                                              <td>{item.country}</td>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  )}
                                </Tab.Pane>

                                <Tab.Pane eventKey="third">
                                  {this.state.det15.length !== 0 && (
                                    <Table
                                      size="sm"
                                      responsive="lg"
                                      striped
                                      bordered
                                    >
                                      {this.state.det15.map((item, key) => (
                                        <thead>
                                          {key === 0 && (
                                            <tr>
                                              <th className="colorWhite">
                                                Peer Company
                                              </th>
                                              {item.company_legal_type !=
                                                null ? (
                                                <th className="colorWhite">
                                                  Company Legal Type
                                                </th>
                                              ) : (
                                                ""
                                              )}

                                              <th className="colorWhite">
                                                Sub-Industry
                                              </th>
                                              <th className="colorWhite">
                                                Country
                                              </th>
                                            </tr>
                                          )}
                                        </thead>
                                      ))}
                                      <tbody>
                                        {this.state.det15.map((item, key) => (
                                          <tr>
                                            <td>
                                              {item.api_url !== null && (
                                                <div>
                                                  <Link
                                                    to={
                                                      "/en/global-company-database-details/" +
                                                      item.api_url +
                                                      "/"
                                                    }
                                                    target="_blank"
                                                  >
                                                    {item.name}
                                                  </Link>
                                                </div>
                                              )}
                                            </td>
                                            {item.company_legal_type != null ? (
                                              <td>{item.company_legal_type}</td>
                                            ) : (
                                              ""
                                            )}
                                            {item.subindustry != null ? (
                                              <td>{item.subindustry}</td>
                                            ) : (
                                              ""
                                            )}
                                            {item.country != null ? (
                                              <td>{item.country}</td>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  )}
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                  {this.state.det16.length !== 0 && (
                                    <Table
                                      size="sm"
                                      responsive="lg"
                                      striped
                                      bordered
                                    >
                                      {this.state.det16.map((item, key) => (
                                        <thead>
                                          {key === 0 && (
                                            <tr>
                                              <th className="colorWhite">
                                                Peer Company
                                              </th>
                                              {item.company_legal_type !=
                                                null ? (
                                                <th className="colorWhite">
                                                  Company Legal Type
                                                </th>
                                              ) : (
                                                ""
                                              )}

                                              <th className="colorWhite">
                                                Sub-Industry
                                              </th>
                                              <th className="colorWhite">
                                                Country
                                              </th>
                                            </tr>
                                          )}
                                        </thead>
                                      ))}
                                      <tbody>
                                        {this.state.det16.map((item, key) => (
                                          <tr>
                                            <td>
                                              {item.api_url !== null && (
                                                <div>
                                                  <Link
                                                    to={
                                                      "/en/global-company-database-details/" +
                                                      item.api_url +
                                                      "/"
                                                    }
                                                    target="_blank"
                                                  >
                                                    {item.name}
                                                  </Link>
                                                </div>
                                              )}
                                            </td>
                                            {item.company_legal_type != null ? (
                                              <td>{item.company_legal_type}</td>
                                            ) : (
                                              ""
                                            )}
                                            {item.subindustry != null ? (
                                              <td>{item.subindustry}</td>
                                            ) : (
                                              ""
                                            )}
                                            {item.country != null ? (
                                              <td>{item.country}</td>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  )}
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        <br></br>
                      </Tab.Pane>

                      <Tab.Pane eventKey="seventeen">
                        <Table size="sm" responsive="lg">
                          <tbody className="padTopDown">
                            {this.state.fund_round.map((item, key) => (
                              <tr>
                                {this.state.fund_round.length !== 0 &&
                                  item.totalfundingamount !== null && (
                                    <td>Total Funding Amount</td>
                                  )}
                                {this.state.fund_round.length !== 0 &&
                                  item.totalfundingamount !== null && (
                                    <td>{item.totalfundingamount}</td>
                                  )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <br></br>
                        <Table size="sm" responsive="lg" striped bordered>
                          {this.state.det11.map((item, key) => (
                            <thead>
                              {key == 0 && (
                                <tr>
                                  <th className="colorWhite word-warp">Deal</th>
                                  <th className="colorWhite">Announced Date</th>
                                  <th className="colorWhite">Funding Round</th>
                                  <th className="colorWhite">Amount Raised </th>
                                  <th className="word-warp colorWhite">
                                    Lead Investors
                                  </th>
                                  <th className="word-warp colorWhite">
                                    Participating Investors
                                  </th>
                                </tr>
                              )}
                            </thead>
                          ))}
                          <tbody>
                            {this.state.det11.map((item, key) => (
                              <tr>
                                {this.state.det11.length !== 0 && (
                                  <td width="250">
                                    {item.api_url != null ? (
                                      <Link
                                        to={
                                          "/en/vc-deals-detail/" +
                                          item.api_url +
                                          "/"
                                        }
                                      >
                                        {item.deal}
                                      </Link>
                                    ) : (
                                      <Link>
                                        <u className="underline">{item.deal}</u>
                                      </Link>
                                    )}
                                  </td>
                                )}
                                {this.state.det11.length !== 0 && (
                                  <td width="100">
                                    <Moment
                                      format="DD MMM YYYY"
                                      className="DateFont"
                                    >
                                      {item.reported_date}
                                    </Moment>
                                  </td>
                                )}
                                {this.state.det11.length !== 0 && (
                                  <td>{item.funding_round}</td>
                                )}
                                {this.state.det11.length !== 0 && (
                                  <td>{item.investment_amount_raised}</td>
                                )}
                                {this.state.det11.length !== 0 && (
                                  <td>
                                    <span>
                                      {item.lead_investors.map(
                                        (company, key) => (
                                          <span>
                                            {item.lead_investors !== null && (
                                              <Link
                                                to={
                                                  "/en/global-company-database-details/" +
                                                  company.api_url +
                                                  "/"
                                                }
                                                target="_blank"
                                              >
                                                {company.name}
                                                <span className="colorBlack">
                                                  {item.lead_investors.length >
                                                    1 && ", "}
                                                </span>
                                              </Link>
                                            )}
                                          </span>
                                        )
                                      )}
                                    </span>
                                  </td>
                                )}
                                {this.state.det11.length !== 0 && (
                                  <td>
                                    <span>
                                      {item.other_participating_investors.map(
                                        (other_company, key) => (
                                          <span>
                                            {item.other_participating_investors !==
                                              null && (
                                                <Link
                                                  to={
                                                    "/en/global-company-database-details/" +
                                                    other_company.api_url +
                                                    "/"
                                                  }
                                                  target="_blank"
                                                >
                                                  {other_company.name}
                                                  <span className="colorBlack">
                                                    {" "}
                                                    {item
                                                      .other_participating_investors
                                                      .length > 0 && ", "}
                                                  </span>
                                                </Link>
                                              )}
                                          </span>
                                        )
                                      )}
                                    </span>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Tab.Pane>

                      <Tab.Pane eventKey="nineteen">
                        <br></br>
                        <Table>
                          <tbody>
                            <tr>
                              <td>Investor Type</td>
                              <td>
                                {this.state.det2.map((item, key) => (
                                  <div>{item.investortype}</div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td>Investor Country</td>
                              <td>
                                {this.state.det2.map((item, key) => (
                                  <div>{item.investor.country}</div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Investor Sector</td>
                              <td>
                                {this.state.det2.map((item, key) => (
                                  <div>{item.investor.sector}</div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              {" "}
                              <td width="150px">Total AUM</td>
                              <td>
                                {this.state.det2.map((item, key) => (
                                  <div>{item.totalaum}</div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td>Investor Focus by Asset Class</td>
                              <td>
                                {this.state.det2.map((item, key) => (
                                  <div>
                                    {item.investorfocusbyassetclass.length >
                                      0 &&
                                      item.investorfocusbyassetclass.join(", ")}
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td width="150px">Investor Focus By Region</td>
                              <td>
                                {this.state.det2.map((item, key) => (
                                  <div>
                                    {item.investorfocusbyregion.length > 0 &&
                                      item.investorfocusbyregion.join(", ")}
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td>Investor Focus By Sub-Region</td>
                              <td class="word-wrap">
                                {this.state.det2.map((item, key) => (
                                  <div>
                                    {item.investorfocusbysubregion.length > 0 &&
                                      item.investorfocusbysubregion.join(", ")}
                                  </div>
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        <Table size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="150px">Investor Focus by Country</td>
                              <td class="word-wrap">
                                {this.state.det2.map((item, key) => (
                                  <div>
                                    {item.investorfocusbycountry.length > 0 &&
                                      item.investorfocusbycountry.join(", ")}
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td>Investor Focus by Sector</td>
                              <td class="word-wrap">
                                {this.state.det2.map((item, key) => (
                                  <div>
                                    {item.investorfocusbysector.length > 0 &&
                                      item.investorfocusbysector.join(", ")}
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td>Investor Focus by Market</td>
                              <td>
                                {this.state.det2.map((item, key) => (
                                  <div>
                                    {item.investorfocusbymarkets.length > 0 &&
                                      item.investorfocusbymarkets.join(", ")}
                                  </div>
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <Table size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="150px">Investment Criteria</td>
                              <td className="word-wrap">
                                {this.state.det2.map((item, key) => (
                                  <p
                                    className=""
                                    dangerouslySetInnerHTML={{
                                      __html: item.investmentcriteria,
                                    }}
                                  />
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
              <Row>
                <Col sm={3}></Col>
                <Col sm={9}>
                  <div className="borderback"></div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default Basic_Infomation;
