import React, { Component } from "react";
import { Link } from "react-router-dom";
import srcicon from "../../../assets/images/search.svg";
import { Redirect } from "react-router";
import axios from "axios";
import Autocomplete from "react-autocomplete";
import {
  matchStocks,
  matchCountry,
  matchSector,
} from "../investment-intellegence-screener/data";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      result: false,
      isLoading: false,
      is_open: false,
      company: [],
    };

    this.getCompanyData = this.getCompanyData.bind(this);
    this.submit = this.submit.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
  }
  closeSearch = () => {
    console.log("Search News", 21);
    this.setState({
      is_open: !this.state.is_open,
    });
  };
  changeTerm(event) {
    console.log(event.target.value);
    this.setState({ term: event.target.value });
  }
  submit(event) {
    this.setState({ result: true });
  }

  getCompanyData = () => {};

  componentDidMount = () => {
    // this.getCompanyData()
  };

  render() {
    return (
      <div className="form-row align-items-center">
        <div className="">
          <label className="sr-only" htmlFor="topSerach">
            Username
          </label>
          <form onSubmit={this.submit}>
            <div className="input-group tsdrop">
              <div className="input-group-prepend w-auto">
                <div className="input-group-text">
                  <img src={srcicon} alt="search" onClick={this.closeSearch} />
                </div>
              </div>

              <input
                type="text"
                className="form-control"
                id="topSerach"
                placeholder="Search AM"
                onChange={this.changeTerm}
              ></input>
            </div>
          </form>
          {this.state.result && (
            <Redirect
              to={
                "/en/search-news/" +
                this.state.term +
                "/global-investment-intelligence/"
              }
            />
          )}
        </div>
        <div className="src-mobile">
          {this.state.is_open && (
            <form onSubmit={this.submit}>
              <input
                type="text"
                className="form-control"
                id="topSerach"
                placeholder="Search AM"
                onChange={this.changeTerm}
              ></input>
              <Link className="close-sec" onClick={this.closeSearch}>
                X
              </Link>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default Search;
