import React, { Component, Fragment } from "react";
import { render } from "react-dom";

import matchSorter from "match-sorter";
import "./Company-Seeking-Targets.css";
import { Row, Table, Col, Container } from "react-bootstrap";
import MA_SideBar from "../../component/global/M&A_Deals/M&A_SideBar";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import $ from "jquery";
const cache = setupCache({
  maxAge: 15 * 60 * 1000
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter
});

class Company_Seeking_Targets extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    items: [],
    show_100: false
  };
  componentDidMount = () => {
    api({
      url: "https://api.analyzemarkets.com/gdd_m_and_a/",
      method: "get"
    }).then(async res => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        items: res.data.results,
        show_100: true
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    $(document).ready(function() {
      $(".grid thead th").click(function() {
        showFilterOption(this);
      });
    });

    var arrayMap = {};

    function showFilterOption(tdObject) {
      var filterGrid = $(tdObject).find(".filter");

      if (filterGrid.is(":visible")) {
        filterGrid.hide();
        return;
      }

      $(".filter").hide();

      var index = 0;
      filterGrid.empty();
      var allSelected = true;
      filterGrid.append(
        '<div><input id="all" type="checkbox" checked>Select All</div>'
      );

      var $rows = $(tdObject)
        .parents("table")
        .find("tbody tr");

      $rows.each(function(ind, ele) {
        var currentTd = $(ele).children()[$(tdObject).attr("index")];
        var div = document.createElement("div");
        div.classList.add("grid-item");
        var str = $(ele).is(":visible") ? "checked" : "";
        if ($(ele).is(":hidden")) {
          allSelected = false;
        }
        div.innerHTML =
          '<input type="checkbox" ' + str + " >" + currentTd.innerHTML;
        filterGrid.append(div);
        arrayMap[index] = ele;
        index++;
      });

      if (!allSelected) {
        filterGrid.find("#all").removeAttr("checked");
      }

      filterGrid.append(
        '<div><input id="close" type="button" value="Close"/><input id="ok" type="button" value="Ok"/></div>'
      );
      filterGrid.show();

      var $closeBtn = filterGrid.find("#close");
      var $okBtn = filterGrid.find("#ok");
      var $checkElems = filterGrid.find("input[type='checkbox']");
      var $gridItems = filterGrid.find(".grid-item");
      var $all = filterGrid.find("#all");

      $closeBtn.click(function() {
        filterGrid.hide();
        return false;
      });

      $okBtn.click(function() {
        filterGrid.find(".grid-item").each(function(ind, ele) {
          if (
            $(ele)
              .find("input")
              .is(":checked")
          ) {
            $(arrayMap[ind]).show();
          } else {
            $(arrayMap[ind]).hide();
          }
        });
        filterGrid.hide();
        return false;
      });

      $checkElems.click(function(event) {
        event.stopPropagation();
      });

      $gridItems.click(function(event) {
        var chk = $(this).find("input[type='checkbox']");
        $(chk).prop("checked", !$(chk).is(":checked"));
      });

      $all.change(function() {
        var chked = $(this).is(":checked");
        filterGrid.find(".grid-item [type='checkbox']").prop("checked", chked);
      });

      filterGrid.click(function(event) {
        event.stopPropagation();
      });

      return filterGrid;
    }

    //get Pagination
    $(document).ready(function() {
      getPagination("#table-id");
    });
    //getPagination('.table-class');
    //getPagination('table');

    /*					PAGINATION 
- on change max rows select options fade out all rows gt option value mx = 5
- append pagination list as per numbers of rows / max rows option (20row/5= 4pages )
- each pagination li on click -> fade out all tr gt max rows * li num and (5*pagenum 2 = 10 rows)
- fade out all tr lt max rows * li num - max rows ((5*pagenum 2 = 10) - 5)
- fade in all tr between (maxRows*PageNum) and (maxRows*pageNum)- MaxRows 
*/

    function getPagination(table) {
      var lastPage = 1;

      $("#maxRows")
        .on("change", function(evt) {
          //$('.paginationprev').html('');						// reset pagination

          lastPage = 1;
          $(".pagination")
            .find("li")
            .slice(1, -1)
            .remove();
          var trnum = 0; // reset tr counter
          var maxRows = parseInt($(this).val()); // get Max Rows from select option

          if (maxRows == 5000) {
            $(".pagination").hide();
          } else {
            $(".pagination").show();
          }

          var totalRows = $(table + " tbody tr").length; // numbers of rows
          $(table + " tr:gt(0)").each(function() {
            // each TR in  table and not the header
            trnum++; // Start Counter
            if (trnum > maxRows) {
              // if tr number gt maxRows

              $(this).hide(); // fade it out
            }
            if (trnum <= maxRows) {
              $(this).show();
            } // else fade in Important in case if it ..
          }); //  was fade out to fade it in
          if (totalRows > maxRows) {
            // if tr total rows gt max rows option
            var pagenum = Math.ceil(totalRows / maxRows); // ceil total(rows/maxrows) to get ..
            //	numbers of pages
            for (var i = 1; i <= pagenum; ) {
              // for each page append pagination li
              $(".pagination #prev")
                .before(
                  '<li data-page="' +
                    i +
                    '">\
            <span>' +
                    i++ +
                    '<span class="sr-only">(current)</span></span>\
          </li>'
                )
                .show();
            } // end for i
          } // end if row count > max rows
          $('.pagination [data-page="1"]').addClass("active"); // add active class to the first li
          $(".pagination li").on("click", function(evt) {
            // on click each page
            evt.stopImmediatePropagation();
            evt.preventDefault();
            var pageNum = $(this).attr("data-page"); // get it's number

            var maxRows = parseInt($("#maxRows").val()); // get Max Rows from select option

            if (pageNum == "prev") {
              if (lastPage == 1) {
                return;
              }
              pageNum = --lastPage;
            }
            if (pageNum == "next") {
              if (lastPage == $(".pagination li").length - 2) {
                return;
              }
              pageNum = ++lastPage;
            }

            lastPage = pageNum;
            var trIndex = 0; // reset tr counter
            $(".pagination li").removeClass("active"); // remove active class from all li
            $('.pagination [data-page="' + lastPage + '"]').addClass("active"); // add active class to the clicked
            // $(this).addClass('active');					// add active class to the clicked
            limitPagging();
            $(table + " tr:gt(0)").each(function() {
              // each tr in table not the header
              trIndex++; // tr index counter
              // if tr index gt maxRows*pageNum or lt maxRows*pageNum-maxRows fade if out
              if (
                trIndex > maxRows * pageNum ||
                trIndex <= maxRows * pageNum - maxRows
              ) {
                $(this).hide();
              } else {
                $(this).show();
              } //else fade in
            }); // end of for each tr in table
          }); // end of on click pagination list
          limitPagging();
        })
        .val(25)
        .change();

      // end of on select change

      // END OF PAGINATION
    }

    function limitPagging() {
      // alert($('.pagination li').length)

      if ($(".pagination li").length > 7) {
        if ($(".pagination li.active").attr("data-page") <= 3) {
          $(".pagination li:gt(5)").hide();
          $(".pagination li:lt(5)").show();
          $('.pagination [data-page="next"]').show();
        }
        if ($(".pagination li.active").attr("data-page") > 3) {
          $(".pagination li:gt(0)").hide();
          $('.pagination [data-page="next"]').show();
          for (
            let i = parseInt($(".pagination li.active").attr("data-page")) - 2;
            i <= parseInt($(".pagination li.active").attr("data-page")) + 2;
            i++
          ) {
            $('.pagination [data-page="' + i + '"]').show();
          }
        }
      }
    }

    $(function() {
      // Just to append id number for each row
      // $("table tr:eq(0)").prepend("<th> ID </th>");
      // var id = 0;
      // $("table tr:gt(0)").each(function() {
      //   id++;
      //   $(this).prepend("<td>" + id + "</td>");
      // });
    });

    //  Developed By Yasser Mas
    // yasser.mas2@gmail.com
  };

  render() {
    const { data } = this.state;

    return (
      <Fragment>
        <Container>
          <Navfilter />
          <Row>
            <Col className="w-30p p-4">
              <div className="widget">
                <MA_SideBar />
              </div>
            </Col>

            <Col className="w-70p p-4">
              <div>
                <div class="container">
                  <div>
                    <Table
                      striped
                      bordered
                      hover
                      id="table-id"
                      className="tblnoline grid"
                      size="sm"
                      responsive="lg"
                    >
                      <thead>
                        <tr>
                          <th index="0">
                            <span className="table-header">Deal</span>{" "}
                            <div class=""></div>
                          </th>
                          <th index="1">
                            <span className="table-header">
                              Announced / Reported Date{" "}
                            </span>

                            <div class=""></div>
                          </th>
                          <th index="2">
                            <span className="table-header">
                              {" "}
                              Deal Value Reporting{" "}
                            </span>

                            <div class=""></div>
                          </th>

                          <th index="3">
                            <span className="table-header"> Deal Status</span>

                            <div class=""></div>
                          </th>
                          <th index="4">
                            <span className="table-header">Target </span>

                            <div class=""></div>
                          </th>
                          <th index="5">
                            <span className="table-header"> Deal Type </span>

                            <div class=""></div>
                          </th>
                          <th index="6">
                            <span className="table-header">
                              Deal Internal Refrence
                            </span>{" "}
                            <div class=""></div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.items.map((item, key) => (
                          <tr>
                            <td>{item.deal}</td>
                            <td>{item.announced_reported_date}</td>
                            <td>
                              {item.deal_value == null || 0
                                ? ""
                                : item.deal_value}
                            </td>
                            <td>{item.target_acquired}</td>
                            <td>{item.deal_status}</td>

                            <td>{item.deal_type}</td>

                            <td>{item.dealinternalreferencenumber}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <b className="fontSize">Select Pagination</b>
                  <div class="form-group" className="SelectSize">
                    <select class="form-control" name="state" id="maxRows">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="70">70</option>
                      <option value="100">100</option>
                    </select>
                  </div>

                  {/* <!--		Start Pagination --> */}
                  <div className="pagination-container">
                    <nav>
                      <ul className="pagination">
                        <li data-page="prev">
                          <span>
                            {" "}
                            Prev <span className="sr-only">(current)</span>{" "}
                            &nbsp;
                          </span>
                        </li>
                        {/* <!--	Here the JS Function Will Add the Rows --> */}
                        <li data-page="next" id="prev">
                          <span>
                            {" "}
                            Next &nbsp;
                            <span className="sr-only">(current)</span>
                          </span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default Company_Seeking_Targets;
