import React, { Component, Fragment } from "react";

import "../investment-intellegence-screener/investment-Intelligence-screener.css";
import { Redirect } from "react-router";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Row, Table, Col, Container } from "react-bootstrap";
import Autocomplete from "react-autocomplete";
import Fetch from "../../global/CommonComponent/useFetch";

import {
  matchStocks,
  matchCountry,
  matchSector,
  matchCompany,
  matchStatus,
  matchDeal,
  matchGeography,
  matchOpportunity,
} from "../investment-intellegence-screener/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
class Seeking_buyer_Screener extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      comapany_name: "",
      filterdata: "",
      participating_investors: "",
      participatingand_investors: "",
      reported_date: "",
      funding_round: [],
      fund_round: "",
      startDate: "",
      endDate: "",
      end_date: "",
      start_date: "",

      sector: [],
      sector_name: "",
      deal_name: "",
      dealand_names: "",
      companyand_data: "",
      deal: [],
      country_name: "",
      country: [],
      result: false,
      region: null,
      by_market: null,
      official_announcement: [],
      deal_official_announcement: "",
      reportedDate: new Date(),
      lead_investors: "",
      leadand_investors: "",
    };
    this.getCompanyData = this.getCompanyData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.handleLastDate = this.handleLastDate.bind(this);
    this.handleLastDays = this.handleLastDays.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });
    this.handleDeal(filterdata);
  };

  handleDeal = (data) => {
    axios
      .get("https://api.analyzemarkets.com/gdd_vc_ssbtitle/?" + data)
      .then((res) => {
        //  console.log(res, "vc deal");
        this.setState({ deal: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };

  handleClick = (item) => {
    console.log(item, 22, this.state.last_days, 32);

    this.props.applyFilter(
      "&deal=" +
        this.state.deal_name +
        "&company=" +
        this.state.comapany_name +
        "&country=" +
        this.state.country_name +
        "&sector=" +
        this.state.sector_name +
        "&deal_official_announcement=" +
        this.state.deal_official_announcement +
        "&funding_round=" +
        this.state.fund_round +
        "&reported_date=" +
        this.state.reported_date +
        "&start_date=" +
        this.state.start_date +
        "&end_date=" +
        this.state.end_date
    );
  };

  refreshButton = () => {
    this.setState({
      deal_name: "",
      comapany_name: "",
      country_name: "",
      sector_name: "",
      deal_official_announcement: "",
      fund_round: "",
      lead_investors: "",
      participating_investors: "",
    });
  };

  onChange = (date) => this.setState({ date });
  handleLastDays = (e) => {
    console.log(Number.isInteger(e), e);
    if (Number.isInteger(parseInt(e))) {
      this.setState({
        last_days: e,
        startDate: "",
        start_date: "",
        end_date: "",
        endDate: "",
      });
    }
  };

  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };

  handleChange = (date) => {
    if (date !== null) {
      console.log(
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        "Test"
      );
      this.setState({
        start_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        startDate: date,
        last_days: "",
      });
    } else {
      this.setState({
        start_date: "",
        startDate: "",
        last_days: "",
      });
    }
  };
  handleLastDate = (date) => {
    if (date !== null) {
      console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
      this.setState({
        last_days: "",
        end_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        endDate: date,
      });
    } else {
      this.setState({
        last_days: "",
        end_date: "",
        endDate: "",
      });
    }
  };

  getCompanyData = (region, markets) => {
    var params = "";
    if (region != null) {
      params = "?region=" + region;
    }

    if (markets != null && params != "") {
      params += "&by_market=" + markets;
    }

    if (markets != null && params == "") {
      params = "?by_market=" + markets;
    }
    console.log(params, 91);
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ company: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/fundinground/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ funding_round: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/sectorman/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ sector: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/doav/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ official_announcement: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };
  componentDidMount = () => {
    this.getCompanyData(this.props.byRegion, this.props.byMarkets);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
    if (this.state.filterdata !== "") {
      this.handleDeal(this.state.filterdata);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.byRegion !== null &&
      this.state.region !== this.props.byRegion
    ) {
      this.setState({
        region: this.props.byRegion,
        company: [],
        country: [],
        deal: [],
      });
      console.log(this.props.byRegion, 138);
      this.getCompanyData(this.props.byRegion, null);
    }
    if (
      this.props.byMarkets !== null &&
      this.state.by_market !== this.props.byMarkets
    ) {
      this.setState({
        by_market: this.props.byMarkets,
        company: [],
        country: [],
        deal: [],
      });
      this.getCompanyData(null, this.props.byMarkets);
    }
  };
  render() {
    return (
      <Fragment>
        <Container>
          <Form className="">
            <div className="iis greyborder2px">
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <h4>V & C Deals Screener</h4>

              <div className="pdg15">
                <Row>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={
                          this.state.deal_name.indexOf("%26") != -1
                            ? this.state.dealand_names
                            : this.state.deal_name
                        }
                        inputProps={{
                          id: "deal-autocomplete",
                          placeholder: "Deal",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.deal}
                        getItemValue={(item) => item.deal}
                        onChange={(event, deal_name) =>
                          this.setState({ deal_name })
                        }
                        shouldItemRender={matchDeal}
                        onSelect={(deal_name) =>
                          this.setState({
                            deal_name:
                              deal_name.indexOf("&") != -1
                                ? deal_name.replace("&", "%26")
                                : deal_name,
                            dealand_names:
                              deal_name.indexOf("%26") != -1
                                ? deal_name.replace("%26", "&")
                                : deal_name,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.deal}
                          >
                            {item.deal}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={
                          this.state.comapany_name.indexOf("%26") != -1
                            ? this.state.companyand_data
                            : this.state.comapany_name
                        }
                        inputProps={{
                          id: "company-autocomplete",
                          placeholder: "Select Startup",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.company}
                        shouldItemRender={matchCompany}
                        getItemValue={(item) => item.name}
                        onChange={(event, comapany_name) =>
                          this.setState({ comapany_name })
                        }
                        onSelect={(comapany_name) =>
                          this.setState({
                            comapany_name:
                              comapany_name.indexOf("&") != -1
                                ? comapany_name.replace("&", "%26")
                                : comapany_name,
                            companyand_data:
                              comapany_name.indexOf("%26") != -1
                                ? comapany_name.replace("%26", "&")
                                : comapany_name,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.name}
                          >
                            {item.name}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.sector_name}
                        inputProps={{
                          id: "sector-autocomplete",
                          placeholder: "Select Sector",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.sector}
                        getItemValue={(item) => item.sector}
                        shouldItemRender={matchSector}
                        onChange={(event, sector_name) =>
                          this.setState({ sector_name })
                        }
                        onSelect={(sector_name) =>
                          this.setState({ sector_name })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.sector}
                          >
                            {item.sector}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.country_name}
                        inputProps={{
                          id: "country-autocomplete",
                          placeholder: "Select Country",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.country}
                        getItemValue={(item) => item.country}
                        shouldItemRender={matchCountry}
                        onChange={(event, country_name) =>
                          this.setState({ country_name })
                        }
                        onSelect={(country_name) =>
                          this.setState({ country_name })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.country}
                          >
                            {item.country}
                          </div>
                        )}
                      />
                    </div>
                  </Col>

                  <Col>
                    {" "}
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.fund_round}
                        inputProps={{
                          id: "country-autocomplete",
                          placeholder: "Funding Round",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.funding_round}
                        getItemValue={(item) => item.name}
                        shouldItemRender={matchCompany}
                        onChange={(event, fund_round) =>
                          this.setState({ fund_round })
                        }
                        onSelect={(fund_round) => this.setState({ fund_round })}
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.name}
                          >
                            {item.name}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.deal_official_announcement}
                        inputProps={{
                          id: "deal-autocomplete",
                          placeholder: "Deal Official Announcement",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.official_announcement}
                        getItemValue={(item) => item.type}
                        onChange={(event, deal_official_announcement) =>
                          this.setState({ deal_official_announcement })
                        }
                        shouldItemRender={matchGeography}
                        onSelect={(deal_official_announcement) =>
                          this.setState({ deal_official_announcement })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.type}
                          >
                            {item.type}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-between date-range">
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleChange}
                        placeholderText="Start Announced Date"
                      />
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleLastDate}
                        placeholderText="End Announced Date"
                      />
                    </div>

                    {/* <Form>
                      <div className="d-flex justify-content-between date-range">
                        <DatePicker
                          selected={this.state.reportedDate}
                          onChange={this.handleReportedDate}
                        />
                      </div>
                    </Form>*/}
                  </Col>
                </Row>

                <div className="d-flex justify-content-between">
                  <Link
                    className="btn btn-blue"
                    to={"/en/seeking_buyer_search/"}
                    onClick={() => {
                      this.handleClick("region");
                    }}
                  >
                    Apply
                  </Link>
                  <Link
                    to={"/en/seeking_buyer_search/"}
                    className="btn btn-blue"
                    onClick={this.refreshButton}
                  >
                    Reset
                  </Link>
                </div>
              </div>
            </div>
          </Form>
        </Container>
      </Fragment>
    );
  }
}

export default Seeking_buyer_Screener;
