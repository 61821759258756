import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import Autocomplete from "react-autocomplete";

import matchSorter from "match-sorter";
import "./gpd.css";
import { Row, Table, Col, Container, Form, Button } from "react-bootstrap";
import { Tab, Tabs, Nav } from "react-bootstrap";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";

import {
  matchStocks,
  matchCountry,
  matchCurrency,
  matchCompany,
  matchAsset,
  matchSubRegion,
  matchRegion,
  matchGeography,
} from "../../component/global/investment-intellegence-screener/data";

import GPD_Sidebar from "../../component/global/gpd-sidebar/gpd-sidebar";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "../../component/global/pagination/index";
import "../../pages/press-release/press-release.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Moment from "react-moment";
import Popup from "reactjs-popup";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Edit_People_Development extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      image: "",
      exampleItems: [],
      details: [],
      company: [],
      counts: 0,
      user: localStorage.getItem("user"),
      page: 0,
      reportingcurrency: "",
      profileimage_and: "",
      filter: "",
      sendkey: false,
      country_name: "",
      current: 0,
      country: [],
      itemcurrency: [],
      old_route: "",
      pageSize: 50,
      count: 0,
      is_first: true,
      id: localStorage.getItem("id"),
      name: "",
      result: "",
      contact_number: "",
      company_name: "",
      reportingcurrency: "",
      company_url: "",
      job_title: "",
      name_and: "",
      contact_number_and: "",
      company_name_and: "",
      country_name_and: "",
      reportingcurrency_and: "",
      company_url_and: "",
      job_title_and: "",
      logoutaction: "",
      login_flag: "False",
      logout_flag: "True",
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.getData = this.getData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleState = this.handleState.bind(this);
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  handleDrop = (dropped) => {
    this.setState({ image: dropped[0], sendkey: true });
    console.log(this.state.image, "Image");
  };

  onSubmit(e) {
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.set("id", this.state.id);
    bodyFormData.set(
      "name",
      this.state.name == "" ? this.state.name_and : this.state.name
    );
    bodyFormData.set(
      "company_url",
      this.state.company_url === ""
        ? this.state.company_url_and
        : this.state.company_url
    );
    bodyFormData.set(
      "job_title",
      this.state.job_title === ""
        ? this.state.job_title_and
        : this.state.job_title
    );
    console.log(this.state.job_title_and, "Job Title");

    bodyFormData.set(
      "country",
      this.state.country_name === ""
        ? this.state.country_name_and
        : this.state.country_name
    );
    bodyFormData.set(
      "contact_number",
      this.state.contact_number === ""
        ? this.state.contact_number_and
        : this.state.contact_number
    );
    bodyFormData.set(
      "company_name",
      this.state.company_name === ""
        ? this.state.company_name_and
        : this.state.company_name
    );
    bodyFormData.set(
      "currency",
      this.state.reportingcurrency === ""
        ? this.state.reportingcurrency_and
        : this.state.reportingcurrency
    );
    console.log(this.state.id, "file send key");

    if (this.state.sendkey === true) {
      bodyFormData.set(
        "profileimage",
        this.state.image === "" ? this.state.profileimage_and : this.state.image
      );
    }
    axios
      .post("https://api.analyzemarkets.com/signinnew/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          this.setState({
            result: true,
          });
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }

  onClickSave = () => {
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.editor.getImage();

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = this.editor.getImageScaledToCanvas();
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleFilter = (filter) => {
    console.log(filter, 31);
    this.setState({
      filter: filter,
      is_filter: true,
    });
    //this.getData(this.state.old_route, filter, 0, true);
  };
  handleState = () => {
    this.setState({
      result: false,
    });
  };

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      // this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    //this.getData(current_route, "", 0, true);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };
  componentWillMount = () => {
    this.getData();
  };

  getData = () => {
    const user = this.state.user;
    api({
      url: "https://api.analyzemarkets.com/signinnew/?name=" + user,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, 63333);
      this.setState({
        details: res.data.data,

        // acquirer: res.data.GDDMandADealsAcquirer,
        // target: res.data.GDDMandADealsTarget,
        // DealValuation: res.data.GDDMandADealsValidation,
      });
    });
    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch(() => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/reportingcurrency/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ itemcurrency: res.data });
      });
  };
  render() {
    const data1 = this.state.details.map(
      (index) => index.name !== null && index.name
    );
    this.state.name_and = data1.shift();

    const data2 = this.state.details.map(
      (index) => index.company_name !== null && index.company_name
    );
    this.state.company_name_and = data2.shift();

    const data3 = this.state.details.map(
      (index) => index.company_url !== null && index.company_url
    );
    this.state.company_url_and = data3.shift();

    const data4 = this.state.details.map(
      (index) => index.contact_number !== null && index.contact_number
    );
    this.state.contact_number_and = data4.shift();
    const data5 = this.state.details.map(
      (index) => index.currency !== null && index.currency
    );
    this.state.reportingcurrency_and = data5.shift();
    const data6 = this.state.details.map(
      (index) => index.country !== null && index.country
    );
    this.state.country_name_and = data6.shift();
    const data7 = this.state.details.map(
      (index) => index.job_title !== null && index.job_title
    );
    this.state.job_title_and = data7.shift();

    const data8 = this.state.details.map(
      (index) => index.profileimage !== null && index.profileimage
    );
    this.state.profileimage_and = data8.shift();

    console.log(this.state.profileimage_and, "Id");
    const data = this.state.details;
    const count = this.state.count;
    console.log(count, "count m");
    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Navfilter />
          <Row>
            <Col className="centerAlign" sm={10}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="">
                  <Col sm={10}></Col>
                  <Col sm={7} className="">
                    <form onSubmit={this.onSubmit}>
                      <div className="iis greyborder2px">
                        <h4 className="text-left">Profile</h4>
                        <div className="pdg15">
                          <fieldset>
                            {this.state.details.map((item, key) => (
                              <Row className="padTopDown">
                                <Col sm={11}>
                                  <Dropzone
                                    noClick
                                    noKeyboard
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div {...getRootProps()}>
                                        <AvatarEditor
                                          width={100}
                                          height={100}
                                          image={
                                            this.state.image == ""
                                              ? this.state.profileimage_and
                                              : this.state.image
                                          }
                                          className={"profile"}
                                        />

                                        <input {...getInputProps()} />
                                      </div>
                                    )}
                                  </Dropzone>
                                </Col>
                              </Row>
                            ))}
                            <br></br>
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                {this.state.user === item.name && (
                                  <Col sm={5}>
                                    <lable>Name</lable>
                                  </Col>
                                )}
                                <Col sm={5}>{item.name}</Col>
                                {console.log(this.state.details.name, "NAme")}
                                <Col className="iis">
                                  <Popup
                                    trigger={<a> Edit</a>}
                                    position="right center"
                                    className="popup_Width"
                                  >
                                    {(close) => (
                                      <div className="">
                                        <h4 className="text-left">Edit</h4>

                                        {this.state.details.map((item, key) => (
                                          <Row className="padTopDown">
                                            <Col sm={11}>
                                              <Dropzone
                                                onDrop={this.handleDrop}
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  borderRadius: "50%",
                                                }}
                                              >
                                                {({
                                                  getRootProps,
                                                  getInputProps,
                                                }) => (
                                                  <div {...getRootProps()}>
                                                    <AvatarEditor
                                                      width={100}
                                                      height={100}
                                                      image={
                                                        this.state.image == ""
                                                          ? this.state
                                                            .profileimage_and
                                                          : this.state.image
                                                      }
                                                      className={"profile"}
                                                    />

                                                    <input
                                                      {...getInputProps()}
                                                    />
                                                  </div>
                                                )}
                                              </Dropzone>
                                            </Col>
                                          </Row>
                                        ))}
                                        <br></br>
                                        {this.state.details.map((item, key) => (
                                          <Row className="form-group">
                                            <Col sm={11}>
                                              <Form.Control
                                                name="name"
                                                type="text"
                                                defaultValue={item.name}
                                                placeholder="Name"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                            <span
                                              className="buttonFloat"
                                              onClick={close}
                                            >
                                              <i className="fa fa-times-circle"></i>
                                            </span>
                                          </Row>
                                        ))}
                                        {this.state.details.map((item, key) => (
                                          <Row className="form-group">
                                            <Col sm={11}>
                                              <Form.Control
                                                name="company_name"
                                                type="text"
                                                defaultValue={item.company_name}
                                                placeholder="Company Name"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        ))}

                                        {this.state.details.map((item, key) => (
                                          <Row className="form-group">
                                            <Col sm={11}>
                                              <Form.Control
                                                name="company_url"
                                                type="text"
                                                defaultValue={item.company_url}
                                                placeholder="Company URL"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        ))}
                                        {this.state.details.map((item, key) => (
                                          <Row className="form-group">
                                            <Col sm={11}>
                                              <Form.Control
                                                name="job_title"
                                                type="text"
                                                defaultValue={item.job_title}
                                                placeholder="Job Title"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        ))}
                                        {this.state.details.map((item, key) => (
                                          <Row>
                                            <Col sm={11}>
                                              <div className="form-group autoc">
                                                <Autocomplete
                                                  value={
                                                    this.state.country_name
                                                  }
                                                  defaultValue={item.country}
                                                  inputProps={{
                                                    id: "deal-autocomplete",
                                                    placeholder:
                                                      this.state.country_name ==
                                                        ""
                                                        ? this.state
                                                          .country_name_and
                                                        : "Country Name",
                                                  }}
                                                  wrapperStyle={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                  }}
                                                  items={this.state.country}
                                                  getItemValue={(item) =>
                                                    item.country
                                                  }
                                                  onChange={(
                                                    event,
                                                    country_name
                                                  ) =>
                                                    this.setState({
                                                      country_name,
                                                    })
                                                  }
                                                  shouldItemRender={
                                                    matchCountry
                                                  }
                                                  onSelect={(country_name) =>
                                                    this.setState({
                                                      country_name,
                                                    })
                                                  }
                                                  renderMenu={(children) => (
                                                    <div className="menu c-dropdown">
                                                      {children}
                                                    </div>
                                                  )}
                                                  renderItem={(
                                                    item,
                                                    isHighlighted
                                                  ) => (
                                                    <div
                                                      className={`item ${isHighlighted
                                                          ? "item-highlighted"
                                                          : ""
                                                        }`}
                                                      key={item.country}
                                                    >
                                                      {item.country}
                                                    </div>
                                                  )}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        ))}
                                        {this.state.details.map((item, key) => (
                                          <Row className="form-group">
                                            <Col sm={11}>
                                              <Form.Control
                                                name="contact_number"
                                                type="text"
                                                defaultValue={
                                                  item.contact_number
                                                }
                                                placeholder="Contact Number"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        ))}

                                        {this.state.details.map((item, key) => (
                                          <Row>
                                            <Col sm={11}>
                                              <div
                                                className="form-group autoc"
                                              //className={classes.root}
                                              >
                                                <Autocomplete
                                                  required
                                                  value={
                                                    this.state
                                                      .reportingcurrency === ""
                                                      ? item.currency
                                                      : this.state
                                                        .reportingcurrency
                                                  }
                                                  inputProps={{
                                                    id: "sector-autocomplete",
                                                    placeholder:
                                                      "Select Your Currency",
                                                  }}
                                                  wrapperStyle={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                  }}
                                                  items={
                                                    this.state.itemcurrency
                                                  }
                                                  getItemValue={(item) =>
                                                    item.reportingcurrency
                                                  }
                                                  shouldItemRender={
                                                    matchCurrency
                                                  }
                                                  onChange={(
                                                    event,
                                                    reportingcurrency
                                                  ) =>
                                                    this.setState({
                                                      reportingcurrency,
                                                    })
                                                  }
                                                  onSelect={(
                                                    reportingcurrency
                                                  ) =>
                                                    this.setState({
                                                      reportingcurrency,
                                                    })
                                                  }
                                                  renderMenu={(children) => (
                                                    <div className="menu c-dropdown">
                                                      {children}
                                                    </div>
                                                  )}
                                                  renderItem={(
                                                    item,
                                                    isHighlighted
                                                  ) => (
                                                    <div
                                                      className={`item ${isHighlighted
                                                          ? "item-highlighted"
                                                          : ""
                                                        }`}
                                                      key={
                                                        item.reportingcurrency
                                                      }
                                                    >
                                                      {item.reportingcurrency}
                                                    </div>
                                                  )}
                                                />
                                              </div>{" "}
                                            </Col>
                                          </Row>
                                        ))}

                                        <Row className="">
                                          <Col sm={6}>
                                            <Button
                                              variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                              type="submit"
                                              size="sm"
                                            >
                                              Update Profile{" "}
                                            </Button>
                                          </Col>
                                          {/* <Col>
                              <Link
                                className="btn btn-blue mt-4 mb-4 shadow"
                                onClick={this.refreshButton}
                              >
                                Reset
                              </Link>
                            </Col> */}
                                        </Row>
                                      </div>
                                    )}
                                  </Popup>
                                </Col>
                              </Row>
                            ))}
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Company Name</lable>
                                </Col>
                                <Col sm={5}>{item.company_name}</Col>
                              </Row>
                            ))}

                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Company URL</lable>
                                </Col>
                                <Col sm={5}>{item.company_url}</Col>
                                <Col></Col>
                              </Row>
                            ))}
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Job Title</lable>
                                </Col>
                                <Col sm={5}>{item.job_title}</Col>
                                <Col></Col>
                              </Row>
                            ))}
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Country</lable>
                                </Col>
                                <Col sm={5}>{item.country}</Col>
                                <Col></Col>
                              </Row>
                            ))}
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Work Email</lable>
                                </Col>
                                <Col sm={5}>{item.email}</Col>
                                <Col></Col>
                              </Row>
                            ))}
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Contact Number</lable>
                                </Col>
                                <Col sm={5}>{item.contact_number}</Col>
                                <Col></Col>
                              </Row>
                            ))}
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Currency</lable>
                                </Col>
                                <Col sm={5}>{item.currency}</Col>
                                <Col></Col>
                              </Row>
                            ))}
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Subscription Start Date</lable>
                                </Col>
                                <Col sm={5}>{item.subscription_start_date}</Col>
                                <Col></Col>
                              </Row>
                            ))}
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Subscription End Date</lable>
                                </Col>
                                <Col sm={5}>{item.subscription_end_date}</Col>
                                <Col></Col>
                              </Row>
                            ))}
                            {this.state.details.map((item, key) => (
                              <Row className="form-group">
                                <Col sm={5}>
                                  <lable>Plan Selected</lable>
                                </Col>
                                <Col sm={5}>{item.plan_selected && item.plan_selected.plan_name}</Col>
                                <Col></Col>
                              </Row>
                            ))}
                            <div class="text-right col"><a class="font600" href="/en/forgot-password">Forgot Password?</a></div>
                          </fieldset>
                          {this.state.refresh && window.location.reload()}

                          {this.state.result && (
                            <div className="borderColors1">
                              <span
                                className="buttonFloat"
                                onClick={this.handleState}
                              >
                                <i className="fa fa-times-circle"></i>
                              </span>
                              <span>
                                <i
                                  class="fa fa-check-circle"
                                  style={{ color: "green", float: "right" }}
                                ></i>
                              </span>
                              &nbsp;
                              <span>
                                <h4>
                                  <b className="colorError">
                                    Profile is Updated
                                  </b>
                                </h4>
                                <br></br>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default Edit_People_Development;
