import React, { Component } from "react";
import logo from "../../../assets/images/logo.svg";
import "./logo.css";

class Logo extends Component {
  render() {
    return (
      <div className="brandlogo">
        <a href="/">
          <img
            src="https://analyze-website-assets.s3-eu-west-1.amazonaws.com/logo.svg"
            className="am-logo"
            alt="AnalyzeMarkets"
          />
        </a>
      </div>
    );
  }
}

export default Logo;
