import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap/";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";

class Termsuse extends Component {
  state = {
    item: [],
    section_one: [],
    section_two: [],
    section_three: [],
    news: {},
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>AnalyzeMarkets Terms of Use</title>
            <meta
              name="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta property="og:title" content="AnalyzeMarkets Terms of Use" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="AnalyzeMarkets Terms of Use" />

            <meta
              itemprop="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="AnalyzeMarkets Terms of Use" />

            <meta
              name="twitter:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            <Col md={12}>
              <hr />
              <h2 className="text-uppercase mb-4">Terms of Use</h2>
              <p>
                These Terms of Use shall govern your use of the
                analyzemarkets.com website, any AnalyzeMarkets applications for
                mobile devices you download, AnalyzeMarkets online Subscription
                service you access, including any updates, new versions or new
                releases thereof (any and all of the foregoing are referred to
                collectively and individually as the “Service”). The Service is
                provided by AnalyzeMarkets ltd (“We” or “AnalyzeMarkets”).
              </p>
              <h3 className="text-uppercase mb-2">Agreement</h3>
              <ul>
                <li>
                  If you wish to use analyzemarkets.com (the Site), you must
                  first read these terms of use (Terms) carefully and agree to
                  them. You may not use the site if you do not agree to these
                  terms.
                </li>
                <li>
                  By using the Site, you will be deemed to have accepted the
                  Terms. The Terms constitute a binding legal agreement between
                  you and us, and incorporate our privacy policy and our
                  disclaimer. Where you are accessing the Site and/or Service
                  during the course of your employment by an organisation which
                  has validly entered into an agreement with us for the use of
                  the Service (Service Agreement), you will also be deemed to
                  have accepted, and be bound by, these Terms.
                </li>
                <li>
                  You agree that we may modify the Terms without notice to you
                  as and when we determine. We will post any changes to the
                  Terms on the Site and you should check for any changes on
                  subsequent visits. Any use by you of the Site will be deemed
                  acceptance of the Terms in force at that time
                </li>
              </ul>
              <br></br>
              <h3 className="text-uppercase mb-2">Registration</h3>
              <ul>
                <li>
                  You represent and warrant to us that any and all information
                  provided by you (including any information you provide on
                  registering for use of the Service) is true, accurate and
                  complete. You undertake to notify us promptly of any changes
                  to such information. You agree not to impersonate any other
                  person or entity or to use a false name or a name that you are
                  not authorised to use. We reserve the right to decline any
                  application from you to register as a user of the Service in
                  our sole discretion and/or to suspend your use of the Site
                  and/or the Service where we in our sole discretion believe
                  that you are in beach of this clause
                </li>
              </ul>
              <br></br>
              <h3 className="text-uppercase mb-2">Access</h3>
              <ul>
                <li>
                  In order to enhance user experience, you agree that we may
                  change the structure, content and nature of the Site at any
                  time without notice to you
                </li>
                <li>
                  In particular, you agree that we may cease providing any or
                  all of the Site and/or the Service to you. if we (in our sole
                  discretion) determine that your bandwidth usage exceeds a
                  reasonable level we may control your use of the site by
                  reducing or preventing your access
                </li>
              </ul>
              <br></br>
              <h3 className="text-uppercase mb-2">Privacy</h3>
              <ul>
                <li>
                  Your use of the Site, including any registration process, may
                  involve your disclosure to us of personal data (which term
                  shall include sensitive personal data) relating to data
                  subjects (personal data, sensitive personal data and data
                  subjects each as defined by the Data Protection Legislation).
                  “Data Protection Legislation” means as applicable: (i) the
                  Data Protection Act 1998, until the effective date of its
                  repeal; (ii) the General Data Protection Regulation ((EU)
                  2016/679) (GDPR) and any national implementing laws,
                  regulations and secondary legislation, for so long as the GDPR
                  is effective in the UK, and (iii) any successor legislation to
                  the Data Protection Act 1998 and the GDPR, in particular the
                  Data Protection Bill 2017-2019, once it becomes law.
                </li>
                <li>
                  In the event that you do so disclose such personal data you:
                  1) warrant and represent to us that you have secured all
                  consents and permissions, and have taken all actions
                  necessary, as may be required by applicable law for the
                  purposes of disclosure to us and subsequent use by us of any
                  such data relating to third parties in the provision of the
                  Site in accordance with our privacy policy; and 2) in relation
                  to any personal data relating to you, you consent to the use
                  of such personal data in accordance with our privacy policy
                </li>
              </ul>
              <br></br>
              <h3 className="text-uppercase mb-2">Restrictions on Use</h3>
              <ul>
                <li>
                  You may only use the Site in accordance with these Terms, and
                  as may be permitted by any relevant law and/or decree of any
                  relevant regulatory body. You agree to use the Site solely for
                  your own non-commercial use and benefit.
                </li>
                <li>
                  You agree not to access (or attempt to access), monitor and/or
                  copy any of the Site through any automated means, including
                  use of agents, robots, spiders, scripts or web crawlers
                </li>
                <li>
                  You agree that you will not engage in any activity that
                  interferes with or disrupts the Site, or the servers and
                  networks which are connected to the Site
                </li>
                <li>
                  You agree that you will not engage in any activity that
                  interferes with or disrupts the Site, or the servers and
                  networks which are connected to the Site
                </li>
                <li>
                  You shall only use the Site, the Service, and any database
                  accessed through the Site (each such a Database) for your own
                  private purposes (save to the extent that we otherwise agree
                  in writing). By way of example, you shall not copy all or part
                  of the contents of the site or any Database for the purpose of
                  making those contents available to third parties without our
                  prior written consent. You acknowledge that each Database has
                  been created through the expenditure of substantial time,
                  effort and money and constitutes valuable intellectual
                  property of us or our affiliate organizations. Where you
                  access the Service and/or any Database pursuant to the terms
                  of a valid Service Agreement, you may use any copies of search
                  output in accordance with the terms of such Service Agreement
                  and solely for your internal business purposes.
                </li>
              </ul>
              <br></br>
              <h3 className="text-uppercase mb-2">Intellectual Property</h3>
              <ul>
                <li>
                  The Site contains a range of information (including text, data
                  files, video, audio and graphics and other content) (Content)
                  which is protected by copyrights, trademarks and other forms
                  of Intellectual Property Rights recognized and protected by
                  national and international laws. You agree to comply with all
                  such laws as are applicable to you.
                </li>
                <li>
                  {" "}
                  Except as permitted by these Terms, you may not copy, or make
                  any use of any part of the Site (or the Service and/or of any
                  Database) or use any Content for any purposes. In particular
                  you may not modify, rent, lease, loan, sell, distribute or
                  create derivative works based on the Content (either in whole
                  or in part) unless you have been specifically permitted to do
                  so by us, or by the owners of that Content, in a separate
                  agreement.
                </li>
                <li>
                  You may not use any of the trademarks, trade names, service
                  marks, copyrights, logos, domain names and other distinctive
                  brand features belonging to us or any third party unless you
                  have valid written permission to do so. You agree not to
                  alter, remove or obscure any proprietary rights notices
                  (including copyright and trade mark notices) which may appear
                  in or be held within the Site
                </li>
                <li>
                  You agree that you are solely responsible for any Content that
                  you create, transmit or display while using the Site and for
                  the consequences of your actions (including any loss or damage
                  which we may suffer) by doing so. You agree that we have no
                  responsibility for policing how such Content is used: you are
                  responsible for protecting and enforcing any such right
                </li>
                <li>
                  You grant us a perpetual, irrevocable, royalty–free and
                  non-exclusive licence to use any Content submitted by you in
                  any manner we in our sole discretion think fit on the Site
                  (including the option not to use it, or to use only part, to
                  delete or archive it or to make such changes to such Content
                  as we in our sole discretion may determine). You agree that we
                  may transmit or distribute your Content over various public
                  networks and in various media, as part of the technical
                  process of providing the Site. Finally, you represent and
                  warrant to us that you have all necessary powers, rights and
                  authority to grant the licence contained in this clause
                </li>
                <li>
                  In these Terms Intellectual Property Rights shall mean all
                  intellectual property rights wherever in the world arising,
                  whether registered or unregistered (and including any
                  application), including copyright, database rights, patents,
                  patent applications, patent rights, rights in designs,
                  trademarks, trademark applications, trademark registrations,
                  trademark rights, trade secrets and all other intellectual
                  property and proprietary information rights as may exist now
                  or hereafter come into existence, all modifications,
                  continuations, renewals and extensions of the foregoing, and
                  all claims, actions, causes of action, damages, costs,
                  expenses, profits, penalties, recoveries and remedies relating
                  to any past, present or future infringement of any of the
                  foregoing, arising under the laws of any country, state or
                  jurisdiction in the world.
                </li>
              </ul>
              <br></br>
              <h3 className="text-uppercase mb-2">Term and Termination</h3>
              <ul>
                <li>
                  These Terms commence on the date you access the Site, and last
                  for the duration of your use whenever you use the Site
                </li>
                <li>
                  We may terminate our relationship with you immediately on
                  giving written notice to you if any of the following occurs:
                  1) you commit a breach of any of these Terms; (where you are a
                  body corporate or a partnership), if you present a petition or
                  has a petition presented by a creditor for your winding up, or
                  convene a meeting to pass a resolution for voluntary winding
                  up, or enter into any liquidation (other than for the purposes
                  of a bona fide reconstruction or amalgamation), or have a
                  receiver or administrator appointed over all or any of your
                  undertaking or assets; (where you are an individual), if you
                  commit any act of bankruptcy or insolvency, or make or
                  negotiate any composition or arrangement for the benefit of
                  your creditors; or 2) where we believe we are required to
                  terminate our relationship with you by law (including where
                  the provision of the Site and/or the Service is, or becomes,
                  unlawful); 3) where the provision of the Site and/or the
                  Service is in our opinion no longer commercially viable; 4) in
                  the event that you are accessing the Service and/or any
                  Database pursuant to the terms of a Service Agreement, where
                  such Service Agreement is terminated.{" "}
                </li>
                <li>
                  Termination shall not affect any accrued rights of either you
                  or us existing as at the date of termination.
                </li>
                <li>
                  You acknowledge that we shall have the right to disable any
                  password used by you in your use of the Site immediately upon
                  termination of these Terms or where we in our sole discretion
                  and without notice to you believe that our interests or the
                  Site are jeopardised.
                </li>
              </ul>
              <br></br>
              <h3 className="text-uppercase mb-2">
                Exclusion and Limitation of Liability
              </h3>
              <ul>
                <li>
                  Nothing in these Terms, including this clause, shall exclude
                  or limit any warranty or liability which may not be lawfully
                  excluded or limited by applicable law, including liability for
                  fraud or for death or personal injury caused by its
                  negligence.
                </li>
                <li>
                  Because we are allowing you to use the Site free of charge,
                  there are no conditions, warranties, representations or other
                  terms, express or implied, that are binding on us except as
                  specifically stated in these Terms (including implied
                  warranties and conditions of merchantability, fitness for a
                  particular purpose and non-infringement). Any condition,
                  warranty, representation or other term concerning the supply
                  of the Site which might otherwise be implied into or
                  incorporated in these Terms, or any collateral contract,
                  whether by statute, common law or otherwise, is hereby
                  excluded to the fullest extent permitted by law.
                </li>
                <li>
                  You expressly understand and agree that your use of the Site
                  is at your sole risk and that the Site is provided as-is and
                  as-available
                </li>
                <li>
                  Any material downloaded or otherwise obtained or accessed
                  through the use of the Site is done at your own discretion and
                  risk, and you will be solely responsible for any damage, loss,
                  or prejudice to your computer system or other device or loss
                  of data that results from the download or access of any such
                  material.
                </li>
                <li>
                  No advice or information, whether oral or written, obtained by
                  you from us or any of our subsidiaries, affiliates, officials,
                  employees, or personnel, or through or from the Site shall
                  create any warranty not expressly stated in the Terms. Whilst
                  we obtain information for inclusion in the Site in good faith
                  from sources which we consider to be reliable, you acknowledge
                  that the contents of the Site are based on information
                  supplied to us by third parties and are not independently
                  verified by us. The Site is not intended to provide tax, legal
                  or investment advice. We shall not be under any liability
                  whatsoever in respect of any inaccuracies or omissions in the
                  Site, nor for any delays in updating the same. All such
                  liability is excluded by us to the fullest extent permitted by
                  law.
                </li>
              </ul>
              <br></br>
              <h6 className="mb-2">
                <b>Audit</b>
              </h6>
              <ul>
                <li>
                  You grant us the right to audit your use of the Site to ensure
                  compliance by you with these Terms
                </li>
              </ul>
              <br></br>
              <h6 className="mb-2">
                <b>Confidentiality </b>
              </h6>
              <ul>
                <li>
                  You shall keep confidential, shall not use for any purpose not
                  permitted in these Terms and shall not disclose any password
                  employed by you in your use of the Site{" "}
                </li>
              </ul>
              <br></br>
              <h6 className="mb-2">
                <b>General </b>
              </h6>
              <ul>
                <li>
                  You shall not assign, transfer or sub-license any of your
                  rights or obligations under these Terms. We may at any time
                  assign all or any of our rights and transfer all or any of our
                  obligations under these Terms{" "}
                </li>
                <li>
                  Failure or neglect by us to enforce any of the provisions of
                  these Terms at any time shall not be construed or deemed to be
                  a waiver of our rights nor shall this in any way affect the
                  validity of the whole or any part of these Terms, nor
                  prejudice our rights to take subsequent action
                </li>
                <li>
                  If any part of any provision of these Terms shall be or become
                  invalid, unlawful or unenforceable to any extent, then the
                  remainder of such provision and all other provisions of these
                  Terms shall continue to be valid and enforceable to the
                  fullest extent permitted by law
                </li>
                <li>
                  These Terms represent the entire agreement of you and us in
                  relation to the subject matter of these Terms and supersedes
                  any previous agreement between you and us in relation to the
                  Site. Neither of you and us have relied upon any statement or
                  representation made by the other in agreeing to enter these
                  Terms.
                </li>
              </ul>
              <br></br>
              <h6 className="mb-2">
                <b>Law and Jurisdiction</b>
              </h6>
              <ul>
                <li>
                  These Terms shall be construed in accordance with English law
                  and the parties hereby submit to the exclusive jurisdiction of
                  the English Courts to settle any disputes which may arise in
                  connection with these Terms.
                </li>
              </ul>
              <br></br>
              <h6 className="mb-2">
                <b>Contact</b>
              </h6>
              <ul>
                <li>
                  Questions, comments and requests regarding these Terms are
                  welcomed and should be addressed to info@analyzemarkets.com
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Termsuse;
