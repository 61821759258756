import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "../create-deal/create-deal.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import Linkify from "react-linkify";
import NumericLabel from "react-pretty-numbers";

import {
  Row,
  Table,
  Col,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class Create_Deal_Target_Details extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    details: [],
    acquirer: [],
    target: [],
    DealValuation: [],
    advisors: [],
    news: [],
  };

  componentDidMount = () => {
    api({
      url:
        "https://api.analyzemarkets.com/globaldealsmarketplaceSTsingle/" +
        this.props.match.params.dealheadline +
        "/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        details: res.data,
        acquirer: res.data.GDDMandADealsBuyerAcquirer,
        target: res.data.GDDMandADealsBuyerTarget,
        DealValuation: res.data.GDDMandADealsbuyerValuation,
        advisors: res.data.GDDMADealsBuyerAdvisors,
        news: res.data.GDDMADealsBuyerNews,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const data = this.state.details;
    let params = {
      justification: "L",
      commafy: true,
    };

    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <Fragment>
        <Header></Header>
        <Navfilter id="sector-desktop"></Navfilter>
        <Container>
          <Helmet title={data.dealheadline}>
            <meta name="description" content={data.dealbriefdescription} />
            <meta property="og:title" content="M&A Deals Monitor" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              itemprop="name"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              itemprop="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta
              name="twitter:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              name="twitter:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>

          <Button
            onClick={this.handleBack}
            variant="outline-light"
            className="MarginRight padDown"
          >
            <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
          </Button>
          <Row>
            <Col className="">
              <Row className="justify-content-md-center">
                <Col xs sm={12} class="">
                  <Row>
                    <Col sm={5}>
                      {" "}
                      <div class="" id="3">
                        <h3 className="UpperCase">Requirement Documents</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="250">Teaser/Presentation</td>
                              <td>
                                <a
                                  href={data.teaserresentation}
                                  target="_blank"
                                >
                                  Download
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div class="" id="6">
                        <h3 className="UpperCase">Potential Acquirer</h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="200">Acquirer Company</td>
                              <td>{data.acquirercompany}</td>
                            </tr>
                            <tr>
                              <td>Acquirer Country</td>
                              <td>{data.acquirercountry}</td>
                            </tr>
                            <tr>
                              <td>Acquirer Sector</td>
                              <td>{data.acquirersector}</td>
                            </tr>
                            <tr>
                              <td>Acquirer URL</td>
                              <td>{data.acquirerURL}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div class="" id="6">
                        <h3 className="UpperCase">
                          Publisher's Contact Information
                        </h3>
                        <Table className="tblnoline" size="sm" responsive="lg">
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>{data.name}</td>
                            </tr>
                            <tr>
                              <td>Title</td>
                              <td>{data.title}</td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>{data.email}</td>
                            </tr>
                            <tr>
                              <td>Contact Number</td>
                              <td>{data.contactnumber}</td>
                            </tr>
                            <tr>
                              <td>
                                Is the Publisher of the Deal Authorized to
                                Create this Deal
                              </td>
                              <td>
                                {data.youauthorizedcreatedealbehalfcompany}
                              </td>
                            </tr>

                            <tr>
                              <td>Deal Status</td>
                              <td>{data.userdealstatus}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col sm={7}>
                      <div class=" hero" id="1">
                        <h3 className="UpperCase">About Potential Target</h3>
                        <Table className="tblnoline" size="lg" responsive="lg">
                          <tbody>
                            <tr>
                              <td width="250">Deal Headline</td>

                              <td className="word-wrap">{data.dealheadline}</td>
                            </tr>
                            <tr>
                              <td width="200">Deal Code</td>

                              <td>{data.dealcodename}</td>
                            </tr>
                            <tr>
                              <td width="200">Deal Created date </td>

                              <td>
                                <Moment format="DD-MMM-YYYY">
                                  {data.created_at}
                                </Moment>
                                &nbsp;
                                <Moment format="hh:mm a">
                                  {data.created_at}
                                </Moment>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td width="200">Target Region</td>

                              <td>{data.targetregion}</td>
                            </tr>
                            <tr>
                              <td width="200"> Target Country</td>

                              <td>{data.targetcountry}</td>
                            </tr>
                            <tr>
                              <td width="200">Target Sector</td>

                              <td>{data.targetsector}</td>
                            </tr>
                            <tr>
                              <td width="200">Target Sub-Industry</td>

                              <td>{data.targetsubindustry}</td>
                            </tr>
                            <tr>
                              <td width="200">Target Total Employees</td>

                              <td>
                                {" "}
                                <NumericLabel params={params}>
                                  {data.targettotalemployees}
                                </NumericLabel>
                              </td>
                            </tr>
                            <tr>
                              <td width="200">Deal Brief Description:</td>

                              <td className="word-wrap">
                                {" "}
                                <p
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: data.dealbriefdescription,
                                  }}
                                />
                              </td>
                            </tr>{" "}
                          </tbody>
                        </Table>
                        <h3 className="UpperCase">Key Financials</h3>

                        <Table className="tblnoline" size="lg" responsive="lg">
                          <tr>
                            <td width="200">Reporting Currency</td>

                            <td>{data.reportingcurrency}</td>
                            <td></td>
                          </tr>
                        </Table>
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th width="200"> </th>
                              <th className="colorWhite" width="200">
                                <b>Minimum</b>
                              </th>
                              <th className="colorWhite" width="200">
                                <b>Maximum</b>
                              </th>
                            </tr>
                          </thead>
                          <tr>
                            <td>Target Revenue Range (USD mn)</td>
                            <td>
                              {" "}
                              <NumericLabel params={params}>
                                {data.minimumrtargetrevenuerange}
                              </NumericLabel>
                            </td>
                            <td>
                              {" "}
                              <NumericLabel params={params}>
                                {data.Maximumtargetrevenuerange}
                              </NumericLabel>
                            </td>
                          </tr>
                          <tr>
                            <td>Target EBITDA Range (USD mn)</td>
                            <td>
                              {" "}
                              <NumericLabel params={params}>
                                {data.minimumtargetEBITDArange}
                              </NumericLabel>
                            </td>
                            <td>
                              {" "}
                              <NumericLabel params={params}>
                                {data.MaximumtargetEBITDArange}
                              </NumericLabel>
                            </td>
                          </tr>
                          <tr>
                            <td>Target Total Assets Range (USD mn)</td>
                            <td>
                              {" "}
                              <NumericLabel params={params}>
                                {data.minimumtargettotalassetsrange}
                              </NumericLabel>
                            </td>
                            <td>
                              {" "}
                              <NumericLabel params={params}>
                                {data.Maximumtargettotalassetsrange}
                              </NumericLabel>
                            </td>
                          </tr>
                        </Table>
                      </div>

                      {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                    </Col>
                  </Row>
                  <Row>
                    {" "}
                    <Col sm={7}>
                      {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Create_Deal_Target_Details;
