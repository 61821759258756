import React, { Component, Fragment, Suspense } from "react";
import { Route, Switch, NavLink, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Newsdetails from "./pages/news-details/news-details";
import ProfileEdit from "./pages/gross-people-development/profile-edit";
import Gmd_Investment_Wire from "./pages/press-release/press-release-data";
import VC_Deals_Detail from "./pages/Gdm-Vcdeals/gdm-vc-deals-detail";
import GiwDetails from "./pages/giw-details";
import VcGraph from "./pages/v&c_dealmonitor/v&cgraph";
import RealGraph from "./pages/re-deals/real-deals-graph";
import Jvgraph from "./pages/jv-deals/jv-deals-graph";
import FundGraph from "./pages/fund-raising-deals/fund-Graph";

const LoadingMessage = () => "<h1>Loading</h1>";

class Router1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signin: [],
      email: "",
      token: "",
      response: "",
      user: "",
      count: "",
      login: [],
      results: false,

      hasError: false,
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <div className="Router">
        {this.state.action && this.state.response === this.state.email ? (
          <Switch>
            <Route path={"/:lan/my_profile/"} component={ProfileEdit}></Route>

            <Route
              path={"/:lan/venture_capital_deals/"}
              component={VcGraph}
              exact
            ></Route>
            <Route
              path={"/:lan/real_estate_deals/"}
              component={RealGraph}
              exact
            ></Route>

            <Route
              path={"/:lan/real_estate_deals/"}
              component={RealGraph}
              exact
            ></Route>

            <Route
              path={"/:lan/joint_venture_deals/"}
              component={Jvgraph}
              exact
            ></Route>

            <Route
              path={"/:lan/fundraising_deals/"}
              component={FundGraph}
              exact
            ></Route>
          </Switch>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Router1;
