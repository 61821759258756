import React, { Component } from "react";
import { Link, BrowserRouter } from "react-router-dom";
import "./navfilter.css";

class Navfilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className=" mt-1 mb-5">
        <nav className="fn-nav d-none d-lg-block">
          <ul>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>

        <nav className="fn-nav d-none d-lg-block">
          <ul>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="ph-item w-100px mb-0">
                <div className="ph-col-12">
                  <div className="ph-row mb-0">
                    <div className="ph-col-12 filterph"></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Navfilter;
