import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap/'; 
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
class Cookie extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }
    render()
    {
        return <Container>

            <Helmet>
               <title>About AnalyzeMarkets</title>
               <meta name="description" content="AnalyzeMarkets is a financial data and technology company, enabling businesses, investors, and investment professionals to make timely, informed decisions."/>
            <meta property="og:title" content="About AnalyzeMarkets" />
            <meta property="og:image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
            <meta name="og:description" content="AnalyzeMarkets is a financial data and technology company, enabling businesses, investors, and investment professionals to make timely, informed decisions."/>
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website"/>

            <meta name="image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
            <meta itemprop="name" content="About AnalyzeMarkets" />

            <meta itemprop="description" content="AnalyzeMarkets is a financial data and technology company, enabling businesses, investors, and investment professionals to make timely, informed decisions." />
            <meta itemprop="image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="About AnalyzeMarkets" />

            <meta name="twitter:description" content="AnalyzeMarkets is a financial data and technology company, enabling businesses, investors, and investment professionals to make timely, informed decisions." />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta name="twitter:image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
            </Helmet>
            
            <Row>
                <Col md={12}>
                    <hr/>
                    <h3>This Cookie policy is effective from 1st November 2019. </h3>
                    <p>Any changes we may make to this cookie policy will be posted on this page. If changes are significant, we may choose to clearly indicate on our home page that the policy has been updated. </p>	
                    <h2>Cookies</h2>
                    <p>AnalyzeMarkets ltd (or analyzemarkets.com or AnalyzeMarkets) uses cookies to keep our sites reliable, secure and personalised. When you accept cookies, this helps make the site better by remembering your preferences, customising content or ads, and enhancing your experience of our global investment intelligence, insight and analysis.</p>
                    <p>This Cookie Policy applies to all websites and web applications which link to this policy and are published by AnalyzeMarkets ltd (“Sites”).</p>
                    <p>This policy should be read alongside our Privacy Policy given on our website, which explains how we use personal information.</p>
                    <p>If you have any queries about the way we use your personal information or cookies, please contact our Data Protection Officer at privacy@analyzemarkets.com</p>
                    <h2>What are cookies?</h2>
                    <p>Cookies are small text files which a website may place on your computer or device when you visit a site. The cookie will help the website, or another website, to recognise your device the next time you visit. Web beacons, pixels or other similar files can also do the same thing. We use the term “cookies” in this policy to refer to all files that collect information in this way.</p>
                    <p>Cookies serve many functions. For example, they can help us to remember your username and preferences, analyse how well our Sites are performing, or allow us to recommend content we believe will be most relevant to you.</p>
                    <p>The majority of websites use cookies to collect and retain personal information about their visitors. Most cookies collect general information, such as how visitors arrive at and use our Sites, the device they are using, their internet protocol address (IP address), what pages they are viewing and their approximate location (for example, we would be able to recognise that you are visiting our Sites from New York).</p>
                    <h2>Purpose of cookies</h2>
                    <p>We group our cookies into the below categories.</p>
                    <h5>Operating our Sites</h5>
                    <p>Some cookies are essential for the operation of our Sites. This includes cookies for the following purposes:</p>
                    <ul class="cokielist">
                    <li><strong>Access</strong> – we use these cookies to identify subscribers and ensure access to subscription only pages, including subscriber access.</li>
                    <li><strong>Billing</strong> – if your subscription is provided by your employer under a group subscription, we use cookies for the purposes of measuring activity and billing.</li>
					<li><strong>Performance</strong> – we use these cookies to monitor Site performance. This allows us to provide a high quality experience by quickly identifying and fixing any issues that arise. For example, we might use performance cookies to track error messages, and to determine improvements to our Sites.</li>
					<li><strong>Functionality</strong> – we use functionality cookies to allow us to remember your preferences. For example, we use functionality cookies to provide you with enhanced services, such as allowing you to listen our podcast online.</li> 
					<li><strong>Analytics</strong> – we use cookies to help us understand our audience and the success of advertising campaigns. For example, we count investment intelligence views to present ‘Trending’ content. We also use analytics cookies to keep track of how many advertising impressions have been served for a campaign and to manage the frequency at which you see the same adverts.</li>
					<li><strong>Fraud prevention</strong> – we use cookies to monitor and prevent suspicious activity and fraudulent traffic.</li>
                    </ul>
                    <h5>Personalisation </h5>
                    <p>We use cookies to improve your experience of our products by personalising our Sites. For example, we help you to find new content and recommend email alerts based on the topics you read.</p>
                    <h5>Advertising</h5>
                    <p>We use cookies to serve you with advertisements that we believe are relevant to you and your interests. You may see these advertisements on our Sites and on other sites that you visit. For example, if you read a number of articles on motoring on analyzemarkets.com or on other sites, you would see more car advertisements as we have inferred that you are interested in this topic. You may also see advertisements for our products and services on other websites if you have previously visited our Sites.</p>
                    <h2>Third party cookies on our Sites</h2>
                    <p>We also use third party cookies on our Sites that fall into the categories above (“third party cookies”) for the following reasons:</p>
                    <ul class="cokielist">
                    <li>to help us monitor traffic on our Sites (like many companies, we use Google Analytics to do this);</li>
                    <li>to improve Site functionality;</li>
                    <li>to identify fraudulent or non-human traffic;</li>
                    <li>to assist with market research;</li>
                    <li>to monitor compliance with our terms and conditions and copyright policy.</li>
                    </ul>
                    <p>Advertisers sometimes use their own cookies to measure performance and to identify fraudulent or non-human traffic. To the extent that you have given them your consent to do so, advertisers may also provide you with targeted advertising based on their data.</p>
                    <p>Our standard advertising terms and conditions do not permit our advertisers to drop their own cookies for profiling or retargeting without your consent.</p>
                    <p>For more information about cookies and how to manage them, please visit:</p>
                    <p><Link to="www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</Link></p>
                </Col>
            </Row>
        </Container>;
    }
}

export default Cookie;
