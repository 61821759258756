import React, { Fragment, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Row, Table, Col, Container, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
//import upload from "./upload.php";
import CreateSidebar from "../../component/global/create-deal-sidebar/create-deal-sidebar";
import $ from "jquery";
import { Link } from "react-router-dom";
//import Autocomplete from "material-ui/AutoComplete";
//import { makeStyles } from "material-ui/styles";
import Autocomplete from "react-autocomplete";
import {
  matchCompany,
  matchSector,
} from "../../component/global/investment-intellegence-screener/data";
//import window from "react-global";
import "./create-deal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import TaskList from "./taskList";
import Signin from "../../pages/signin/signinIndex";
import Fetch from "../../component/global/CommonComponent/useFetch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class CreateDealEdit extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.handleState = this.handleState.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.onChange = (editorState) => this.setState({ editorState });
    this.getLabel = this.getLabel.bind(this);
    this.getLabel1 = this.getLabel1.bind(this);
    this.handleFilters = this.handleFilters.bind(this);

    this.state = {
      taskList: [
        {
          index: Math.random(),
          SubIndustry: "",
          Sector: "",
        },
      ],
      dealheadline: "",
      dealcodename: "",
      companyname: "",
      filevideo: "",
      labelitem: false,
      labelitem1: false,
      file_picture: "",
      companydealbriefdescription: "",
      token: "",
      filterdata: "",
      //editorState: EditorState.createEmpty(),
      companyheadofficecountry: "",
      companysector: "",
      companysubindustry: "",
      yearofestablishment: "",
      youauthorizedcreatedealbehalfcompany: "",
      companysub: [],
      totalnumberofemployees: "",
      companydealbriefdescription: "",
      projectedfiscalyearrevenue: "",
      projectedfiscalyearEBITDA: "",
      projectedfiscalyeartotalassets: "",
      lastfiscalyearrevenue: "",
      lastfiscalyearEBITDA: "",
      refresh: false,
      lastfiscalyeartotalassets: "",
      historicalfinancialstatementscompanyaudited: "",
      fisicalyearend: "",
      reportingcurrency: "",
      itemcurrency: [],
      name_info: "",
      id: "",
      title: "",
      email_info: "",
      user: "",
      items: [],
      sector_data: "",
      result: false,
      contactnumber: "",
      status: "",
      details: [],
      approve_rejected_by: "",
      company_details: [],
      country: [],
      sector: [],
      Sector: [],
      keyValue: false,
      keyValue1: false,
      keyValue2: false,
      sector_name: "",
      id: "",
      country_name: "",
      filter: "",
      typeofAudit: "",
      subIndustry: [],
      SubIndustry: "",
      filepicture: "",
      // file_picture: "",
      file_name: "",
      old_route: "",
      pageSize: 25,
      count: 0,
      file: "",
      file_teaser: "",
      fileother: "",
      file_other: "",
      reportedDate: new Date(),
      reported_date: "",
    };
  }

  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };
  notify = () => {
    toast.info("Your Deal is Succesfully Edited", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/globaldealsmarketplacesingle/" +
        this.props.match.params.dealheadline +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, "Buyer Edit");
      this.setState({
        details: res.data,
        file_teaser: res.data.teaserpresentation,
        file_other: res.data.otherdocumentstoupload,
        file_picture: res.data.projectpicturestoupload,
        id: res.data.id,
      });

      console.log(this.state.id, "Global Id1");

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };
  getLabel = () => {
    const { details } = this.state;
    this.setState({
      labelitem: true,
    });
  };
  getLabel1 = () => {
    const { details } = this.state;
    this.setState({
      labelitem1: true,
    });
  };

  refreshButton = () => {
    this.setState({
      refresh: true,
      dealheadline: "",
      result: false,
      companyname: "",
      companyheadofficecountry: "",
    });
  };

  handleState = () => {
    this.setState({
      result: false,
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      companydealbriefdescription: e.target.getContent(),
    });

    console.log("Content was updated:", this.state.companydealbriefdescription);
  };

  handleChange1 = (e) => {
    if (["SubIndustry", "Sector"].includes(e.target.name)) {
      let taskList = [...this.state.taskList];
      taskList[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ [e.target.name]: e.target.value });
      for (let i = 0; i < 2; i++) {
        console.log(taskList[e.target.dataset.id].length, "Sector");
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addNewRow = () => {
    this.setState((prevState) => ({
      taskList: [
        ...prevState.taskList,
        {
          index: Math.random(),
          SubIndustry: "",
          Sector: "",
        },
      ],
    }));
  };

  deteteRow = (index) => {
    this.setState({
      taskList: this.state.taskList.filter((s, sindex) => index !== sindex),
    });
    // const taskList1 = [...this.state.taskList];
    // taskList1.splice(index, 1);
    // this.setState({ taskList: taskList1 });
  };
  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      keyValue: true,
    });
    console.log(len, "length");
    console.log("Selected file:", event.target.files[0]);

    //.log("Selected file:", event.target.files[0]);
  }
  handleChange1(event) {
    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      keyValue1: true,
    });
    console.log(len, "length");
    console.log("other file:", this.state.fileother);

    //.log("Selected file:", event.target.files[0]);
  }
  handleChange2(event) {
    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      keyValue2: true,
    });
    console.log(len, "length");
    console.log("Other file:", this.state.fileother);

    //.log("Selected file:", event.target.files[0]);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.date === "" || this.state.description === "") {
      //   NotificationManager.warning(
      //     "Please Fill up Required Field . Please check Task and Date Field"
      //   );
      return false;
    }
    for (var i = 0; i < this.state.taskList.length; i++) {
      if (
        this.state.taskList[i].projectName === "" ||
        this.state.taskList[i].task === ""
      ) {
        return false;
      }
    }
    let data = { formData: this.state, userData: localStorage.getItem("user") };
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "token"
    );
    axios
      .post("http://localhost:9000/api/task", data)
      .then(() => {
        // if (res.data.success) NotificationManager.success(res.data.msg);
      })
      .catch(() => {
        //     if (error.response.status && error.response.status === 400)
        //       NotificationManager.error("Bad Request");
        //     else NotificationManager.error("Something Went Wrong");
        //     this.setState({ errors: error });
      });
  };
  onSubmit(e) {
    e.preventDefault();
    const { dealheadline } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set("id", this.state.id);
    bodyFormData.set(
      "dealheadline",
      dealheadline == "" ? this.state.details.dealheadline : dealheadline
    );
    bodyFormData.set(
      "dealcodename",
      this.state.dealcodename === ""
        ? this.state.details.dealcodename
        : this.state.dealcodename
    );
    bodyFormData.set(
      "companyname",
      this.state.companyname === ""
        ? this.state.details.companyname
        : this.state.companyname
    );
    bodyFormData.set(
      "companyheadofficecountry",
      this.state.companyheadofficecountry === ""
        ? this.state.details.companyheadofficecountry
        : this.state.companyheadofficecountry
    );
    bodyFormData.set(
      "companysector",
      this.state.sector_name === ""
        ? this.state.details.companysector
        : this.state.sector_name
    );
    bodyFormData.set(
      "companysubindustry",
      this.state.companysubindustry === ""
        ? this.state.details.companysubindustry
        : this.state.companysubindustry
    );
    bodyFormData.set(
      "yearofestablishment",
      this.state.reported_date === ""
        ? this.state.details.yearofestablishment
        : this.state.reported_date
    );
    bodyFormData.set(
      "totalnumberofemployees",
      this.state.totalnumberofemployees === ""
        ? this.state.details.totalnumberofemployees
        : this.state.totalnumberofemployees
    );
    bodyFormData.set(
      "reportingcurrency",
      this.state.reportingcurrency === ""
        ? this.state.details.reportingcurrency
        : this.state.reportingcurrency
    );
    bodyFormData.set(
      "companydealbriefdescription",
      this.state.companydealbriefdescription === ""
        ? this.state.details.companydealbriefdescription
        : this.state.companydealbriefdescription
    );

    bodyFormData.set(
      "historicalfinancialstatementscompanyaudited",
      this.state.historicalfinancialstatementscompanyaudited === ""
        ? this.state.details.historicalfinancialstatementscompanyaudited
        : this.state.historicalfinancialstatementscompanyaudited
    );
    bodyFormData.set(
      "fisicalyearend",
      this.state.fisicalyearend === ""
        ? this.state.details.fisicalyearend
        : this.state.fisicalyearend
    );
    bodyFormData.set(
      "lastfiscalyearrevenue",
      this.state.lastfiscalyearrevenue === ""
        ? this.state.details.lastfiscalyearrevenue
        : this.state.lastfiscalyearrevenue
    );
    bodyFormData.set(
      "lastfiscalyearEBITDA",
      this.state.lastfiscalyearEBITDA === ""
        ? this.state.details.lastfiscalyearEBITDA
        : this.state.lastfiscalyearEBITDA
    );
    bodyFormData.set(
      "lastfiscalyeartotalassets",
      this.state.lastfiscalyeartotalassets === ""
        ? this.state.details.lastfiscalyeartotalassets
        : this.state.lastfiscalyeartotalassets
    );
    bodyFormData.set(
      "projectedfiscalyearrevenue",
      this.state.projectedfiscalyearrevenue === ""
        ? this.state.details.projectedfiscalyearrevenue
        : this.state.projectedfiscalyearrevenue
    );
    bodyFormData.set(
      "projectedfiscalyearEBITDA",
      this.state.projectedfiscalyearEBITDA === ""
        ? this.state.details.projectedfiscalyearEBITDA
        : this.state.projectedfiscalyearEBITDA
    );
    bodyFormData.set(
      "projectedfiscalyeartotalassets",
      this.state.projectedfiscalyeartotalassets === ""
        ? this.state.details.projectedfiscalyeartotalassets
        : this.state.projectedfiscalyeartotalassets
    );

    bodyFormData.set(
      "name",
      this.state.name_info === ""
        ? this.state.details.name
        : this.state.name_info
    );
    bodyFormData.set(
      "title",
      this.state.title === "" ? this.state.details.title : this.state.title
    );
    bodyFormData.set(
      "email",
      this.state.email_info === ""
        ? this.state.details.email
        : this.state.email_info
    );
    bodyFormData.set(
      "contactnumber",
      this.state.contactnumber === ""
        ? this.state.details.contactnumber
        : this.state.contactnumber
    );
    bodyFormData.set(
      "userdealstatus",
      this.state.status === ""
        ? this.state.details.userdealstatus
        : this.state.status
    );
    if (this.state.keyValue === true) {
      bodyFormData.set(
        "teaserpresentation",
        this.state.file === ""
          ? this.state.details.teaserpresentation
          : this.state.file
      );
      console.log(this.state.keyValue, "key Value");
    }
    if (this.state.keyValue === true) {
      bodyFormData.set(
        "otherdocumentstoupload",
        this.state.fileother == ""
          ? this.state.details.otherdocumentstoupload
          : this.state.fileother
      );
    }
    if (this.state.keyValue === true) {
      bodyFormData.set(
        "projectpicturestoupload",
        this.state.filepicture === ""
          ? this.state.details.projectpicturestoupload
          : this.state.filepicture
      );
    }
    bodyFormData.set(
      "youauthorizedcreatedealbehalfcompany",
      this.state.youauthorizedcreatedealbehalfcompany === ""
        ? this.state.details.youauthorizedcreatedealbehalfcompany
        : this.state.youauthorizedcreatedealbehalfcompany
    );

    bodyFormData.set("posted_by", this.state.user);

    axios
      .post(
        "https://api.analyzemarkets.com/globaldealsmarketplace/",
        bodyFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          this.notify();

          this.setState({
            result: true,
          });
        } else {
          this.setState({
            result: (
              <div className="">
                <hr />
                <i className="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }
  componentDidMount = () => {
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });
    // api({
    //   url: "https://api.analyzemarkets.com/country/",
    //   method: "get",
    // }).then((res) => {
    //   // Here you need to use an temporary array to store NeededInfo only
    //   let tmpArray = [];
    //   for (var i = 0; i < res.data.results.length; i++) {
    //     tmpArray.push({
    //       label: res.data.results[i].country,
    //       value: res.data.results[i].country,
    //     });
    //   }
    //   //console.log(tmpArray, "tmArray");
    //   this.setState({
    //     country: tmpArray,
    //   });
    //   //console.log(this.state.country, "tmArray");
    // });
    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch(() => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/reportingcurrency/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ itemcurrency: res.data });
      })
      .catch(() => {
        //on error
        console.log("Error !");
      });
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }

    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ sector: res.data.results });
      }
    });
    api({
      url: "https://api.analyzemarkets.com/companysubindustry/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ subIndustry: res.data });
      }
    });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };

  clickOnDelete(record) {
    this.setState({
      taskList: this.state.taskList.filter((r) => r !== record),
    });
  }
  render() {
    const data = this.state.details;
    // var url = this.state.file_teaser;
    // var urls = this.state.file_other;
    // var urlss = this.state.file_picture;
    // var url1 = url !== null && url.replace(/^.*[\\\/]/, "");
    // var url2 = urls !== null && urls.replace(/^.*[\\\/]/, "");
    // var url3 = urlss !== null && urlss.replace(/^.*[\\\/]/, "");

    // const url2 = url1.replace("/%20/g", " ");

    //console.log(url, "Teaser");

    let { taskList } = this.state; //let { notes, date, description, taskList } = this.state
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Navfilter />
              {this.state.result}
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Row className="centerAlign">
                {/* <Col className="w-30p col-sm-3 p-4">
              <div className="widget">
                <CreateSidebar />
              </div>
            </Col> */}

                <Col className="">
                  <form onSubmit={this.onSubmit}>
                    <div className="iis greyborder2px">
                      <h4 className="text-left">Edit a Deal</h4>
                      <div className="pdg15">
                        <fieldset>
                          <legend>
                            <b className="upercase">About Company</b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="dealheadline"
                                defaultValue={data.dealheadline}
                                type="text"
                                placeholder="Deal Headline* (Max 100 characters)
                            Healthcare company exploring investment opportunities in cross border healthcare sector"
                                onChange={this.changeTerm}
                                maxLength="100"
                              />
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="dealcodename"
                                defaultValue={data.dealcodename}
                                type="text"
                                placeholder="Deal Code Name *e.g. Project Sun"
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                name="companyname"
                                type="text"
                                defaultValue={data.companyname}
                                placeholder="Company Name (Optional)"
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Form.Group as={Col} controlId="ChooseCountry">
                              {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                name="country"
                                onChange={this.changeTerm}
                                isMulti
                                options={con}
                              /> */}
                              <Form.Control
                                as="select"
                                name="companyheadofficecountry"
                                defaultValue={data.companyheadofficecountry}
                                onChange={this.changeTerm}
                                placeholder="Select Company HQ Country*"
                                rows="3"
                              >
                                <option
                                  value={data.companyheadofficecountry}
                                  selected
                                >
                                  {data.companyheadofficecountry}
                                </option>

                                <option value="" className="">
                                  Select Company HQ Country
                                </option>
                                {this.state.country.map((item, i) => (
                                  <option key={i} value={item.country}>
                                    {item.country}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                placeholder={<div>Select Sector*</div>}
                                name="sector_name"
                                onChange={this.changeTermMultiSelect}
                                isMulti
                                options={sec}
                              />
                              {console.log(this.state.rem, "rem")} */}
                              {this.state.labelitem === false && (
                                <div
                                  className="form-group autoc"
                                  //className={classes.root}
                                >
                                  <Form.Control
                                    as="select"
                                    name="sector_name"
                                    onChange={this.changeTerm}
                                    // placeholder="Are you Authorized to create this deal"
                                  >
                                    <option value={data.companysector} selected>
                                      {data.companysector}
                                    </option>
                                    {this.state.sector.map((item, i) => (
                                      <option key={i} value={item.country}>
                                        {item.sector}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </div>
                              )}
                              {this.state.labelitem && (
                                <div className="form-group autoc">
                                  <Autocomplete
                                    value={this.state.sector_name}
                                    //defaultValue={data.companysector}
                                    inputProps={{
                                      id: "sector-autocomplete",
                                      placeholder: "Select Sector*",
                                    }}
                                    wrapperStyle={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                    items={this.state.sector}
                                    getItemValue={(item) => item.sector}
                                    shouldItemRender={matchSector}
                                    onChange={(event, sector_name) =>
                                      this.setState({
                                        sector_name,
                                        sector_data: null,
                                      })
                                    }
                                    onSelect={(sector_name) =>
                                      this.setState({
                                        sector_name,
                                        sector_data: sector_name,
                                      })
                                    }
                                    renderMenu={(children) => (
                                      <div className="menu c-dropdown">
                                        {children}
                                      </div>
                                    )}
                                    renderItem={(item, isHighlighted) => (
                                      <div
                                        className={`item ${
                                          isHighlighted
                                            ? "item-highlighted"
                                            : ""
                                        }`}
                                        key={item.sector}
                                      >
                                        {item.sector}
                                      </div>
                                    )}
                                  />
                                </div>
                              )}{" "}
                            </Col>{" "}
                            <Col>
                              {/* <Select
                                closeMenuOnSelect={false}
                                placeholder={<div>Select Sub-Industry*</div>}
                                components={animatedComponents}
                                name="companysubindustry"
                                onChange={this.changeTermMultiSelects}
                                isMulti
                                options={subIn}
                              /> */}
                              {this.state.labelitem === false && (
                                <Form.Control
                                  as="select"
                                  name="companysubindustry"
                                  placeholder="Select Sub-Industry*"
                                  onChange={this.changeTerm}
                                >
                                  <option value="">Select Sub-Industry*</option>
                                  <option
                                    value={data.companysubindustry}
                                    selected
                                  >
                                    {data.companysubindustry}
                                  </option>
                                  {this.state.subIndustry.map((item, i) => (
                                    <option
                                      key={i}
                                      value={
                                        this.state.sector_data === item.sector
                                          ? item.companysubindustry
                                          : "null"
                                      }
                                    >
                                      {this.state.sector_data === item.sector
                                        ? item.companysubindustry
                                        : ""}
                                    </option>
                                  ))}
                                </Form.Control>
                              )}

                              {this.state.labelitem && (
                                <Form.Control
                                  as="select"
                                  name="companysubindustry"
                                  placeholder="Select Sub-Industry*"
                                  onChange={this.changeTerm}
                                >
                                  <option value="">Select Sub-Industry*</option>
                                  {this.state.subIndustry.map((item, i) => (
                                    <option
                                      key={i}
                                      value={
                                        this.state.sector_data === item.sector
                                          ? item.companysubindustry
                                          : "null"
                                      }
                                    >
                                      {this.state.sector_data === item.sector
                                        ? item.companysubindustry
                                        : ""}
                                    </option>
                                  ))}
                                </Form.Control>
                              )}
                            </Col>
                            <span onClick={this.getLabel}>
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                            </span>
                          </Row>

                          <Row className="form-group">
                            <Col>
                              <Form>
                                <label>Company Established Date*</label>

                                <div className="d-flex justify-content-between date-range">
                                  <DatePicker
                                    selected={this.state.reportedDate}
                                    onChange={this.handleReportedDate}
                                  />
                                </div>
                              </Form>
                            </Col>

                            <Col>
                              <label>Total No. of Employees </label>
                              <Form.Control
                                name="totalnumberofemployees"
                                defaultValue={data.totalnumberofemployees}
                                onChange={this.changeTerm}
                                placeholder="Total No. of Employees*"
                              />
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <Editor
                                initialValue={data.companydealbriefdescription}
                                value={data.companydealbriefdescription}
                                apiKey="l7d3ab4ybjfthcy2c4ya6vsj9rlub27d3sdqk433oyis6ild"
                                init={{
                                  height: 300,
                                  menubar: false,
                                  plugins: [
                                    "advlist autolink lists link image",
                                    "charmap print preview anchor help",
                                    "searchreplace visualblocks code",
                                    "insertdatetime media table paste wordcount",
                                  ],
                                  toolbar:
                                    "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help",
                                }}
                                maxLength="200"
                                onChange={this.handleEditorChange}
                              />

                              {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                  as="textarea"
                                  name="companydealbriefdescription"
                                  rows="7"
                                  maxLength="100"
                                  onChange={this.changeTerm}
                                  placeholder="Company/Deal Brief Description* (Maximum 100 words)"
                                />
                              </Form.Group> */}
                            </Col>{" "}
                          </Row>
                          <legend>
                            {" "}
                            <b className="upercase">Key Financials</b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                as="select"
                                name="historicalfinancialstatementscompanyaudited"
                                onChange={this.changeTerm}
                                // placeholder="Are you Authorized to create this deal"
                              >
                                {data.historicalfinancialstatementscompanyaudited !==
                                null ? (
                                  <option
                                    value={
                                      data.historicalfinancialstatementscompanyaudited
                                    }
                                    className=""
                                  >
                                    {
                                      data.historicalfinancialstatementscompanyaudited
                                    }
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Select
                                  </option>
                                )}

                                <option
                                  value={
                                    "Yes" ===
                                    data.historicalfinancialstatementscompanyaudited
                                      ? "null"
                                      : "Yes"
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value={
                                    "No" ===
                                    data.historicalfinancialstatementscompanyaudited
                                      ? "null"
                                      : "No"
                                  }
                                >
                                  No
                                </option>
                              </Form.Control>
                            </Col>

                            {console.log(
                              this.state.typeofAudit,
                              "Type of Audit"
                            )}
                            <Col>
                              <Form.Control
                                as="select"
                                name="fisicalyearend"
                                defaultValue={data.fisicalyearend}
                                onChange={this.changeTerm}
                                placeholder="Fiscal Year End*"
                                rows="3"
                              >
                                <option
                                  value={data.fisicalyearend}
                                  selected
                                  className=""
                                >
                                  {data.fisicalyearend}
                                </option>

                                <option value="" className="">
                                  Fiscal Year End*
                                </option>
                                <option value="Janaury">Janaury</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                              </Form.Control>
                            </Col>
                            <Col>
                              <Form.Control
                                name="reportingcurrency"
                                type="text"
                                defaultValue={data.reportingcurrency}
                                placeholder="Reporting Currency*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr>
                                    <th width="200"></th>
                                    <th className="colorWhite" width="200">
                                      Last Fiscal Year
                                    </th>
                                    <th className="colorWhite" width="200">
                                      Projected Fiscal Year
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Revenue (USD mn)</td>
                                    <td>
                                      {" "}
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="lastfiscalyearrevenue"
                                            type="text"
                                            placeholder="Required"
                                            defaultValue={
                                              data.lastfiscalyearrevenue
                                            }
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      {" "}
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="projectedfiscalyearrevenue"
                                            type="text"
                                            defaultValue={
                                              data.projectedfiscalyearrevenue
                                            }
                                            placeholder="Optional"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>EBITDA (USD mn)</td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="lastfiscalyearEBITDA"
                                            type="text"
                                            defaultValue={
                                              data.lastfiscalyearEBITDA
                                            }
                                            placeholder="Required"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="projectedfiscalyearEBITDA"
                                            defaultValue={
                                              data.projectedfiscalyearEBITDA
                                            }
                                            type="text"
                                            placeholder="Optional"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Total Assests (USD mn)</td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="lastfiscalyeartotalassets"
                                            defaultValue={
                                              data.lastfiscalyeartotalassets
                                            }
                                            type="text"
                                            placeholder="Required"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        {" "}
                                        <Col sm={12}>
                                          {" "}
                                          <Form.Control
                                            name="projectedfiscalyeartotalassets"
                                            defaultValue={
                                              data.projectedfiscalyeartotalassets
                                            }
                                            type="text"
                                            placeholder="Optional"
                                            onChange={this.changeTerm}
                                          />
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                          <br></br>
                          <legend>
                            <b className="upercase">Documents</b>
                          </legend>

                          <Form.Group>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label> Teaser/Presentation</label>
                              )}

                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label> Teaser/Presentation</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="file"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.teaserpresentationname}
                                      </label>
                                    </div>{" "}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="file"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "File Name")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>
                            </div>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label>Other Documents to upload:</label>
                              )}
                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label>Other Documents to upload:</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="fileother"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.otherdocumentstouploadname}
                                      </label>
                                    </div>{" "}
                                    {console.log(
                                      " file count:",
                                      this.state.fileother
                                    )}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="fileother"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "Type of Audit")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>{" "}
                            </div>
                            <div className="form-group">
                              {this.state.labelitem1 && (
                                <label> Picture and video to upload</label>
                              )}{" "}
                              <Row>
                                {this.state.labelitem1 === false && (
                                  <Col>
                                    <label> Picture and video to upload</label>
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        multiple
                                        class="custom-file-input"
                                        id="inputGroupFile01"
                                        name="filepicture"
                                        aria-describedby="inputGroupFileAddon01"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                      >
                                        {data.projectpicturestouploadname}
                                      </label>
                                    </div>{" "}
                                    {console.log(
                                      " file count:",
                                      this.state.file
                                    )}
                                  </Col>
                                )}
                                {this.state.labelitem1 && (
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="filepicture"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                )}
                                {console.log(this.state.file, "Type of Audit")}
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil pencil1"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>
                            </div>
                          </Form.Group>
                          <br></br>
                          <legend>
                            <b className="upercase">
                              Contact Information For Potential Investors
                            </b>
                          </legend>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="name_info"
                                type="text"
                                defaultValue={data.name}
                                placeholder="Name*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                name="title"
                                type="text"
                                defaultValue={data.title}
                                placeholder="Title*"
                                onChange={this.changeTerm}
                              />
                            </Col>

                            <Col>
                              <Form.Control
                                name="email_info"
                                type="email"
                                defaultValue={data.email}
                                placeholder="Email*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <Form.Control
                                name="contactnumber"
                                type="tel"
                                defaultValue={data.contactnumber}
                                placeholder="Contact number*"
                                onChange={this.changeTerm}
                              />
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col>
                              <label>
                                Are you Authorized to create this deal
                              </label>
                              <Form.Control
                                as="select"
                                defaultValue={
                                  data.youauthorizedcreatedealbehalfcompany
                                }
                                name="youauthorizedcreatedealbehalfcompany"
                                onChange={this.changeTerm}
                                // placeholder="Are you Authorized to create this deal"
                              >
                                {data.youauthorizedcreatedealbehalfcompany !==
                                null ? (
                                  <option
                                    value={
                                      data.youauthorizedcreatedealbehalfcompany
                                    }
                                    className=""
                                  >
                                    {data.youauthorizedcreatedealbehalfcompany}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Select
                                  </option>
                                )}

                                <option
                                  value={
                                    "Yes" ===
                                    data.youauthorizedcreatedealbehalfcompany
                                      ? "null"
                                      : "Yes"
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value={
                                    "No" ===
                                    data.youauthorizedcreatedealbehalfcompany
                                      ? "null"
                                      : "No"
                                  }
                                >
                                  No
                                </option>
                              </Form.Control>
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col>
                              <label>Deal Status</label>

                              <Form.Control
                                as="select"
                                name="status"
                                onChange={this.changeTerm}
                              >
                                {data.userdealstatus !== null ? (
                                  <option
                                    value={data.userdealstatus}
                                    className=""
                                  >
                                    {data.userdealstatus}
                                  </option>
                                ) : (
                                  <option value={""} className="">
                                    Deal Status
                                  </option>
                                )}

                                <option
                                  value={
                                    "Active" === data.userdealstatus
                                      ? "null"
                                      : "Active"
                                  }
                                >
                                  Active
                                </option>
                                <option
                                  value={
                                    "Inactive" === data.userdealstatus
                                      ? "null"
                                      : "Inactive"
                                  }
                                >
                                  Inactive
                                </option>
                              </Form.Control>
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col sm={3}>
                              <Button
                                variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                type="submit"
                                size="sm"
                              >
                                Publish Deal
                              </Button>
                            </Col>
                            <Col>
                              <Link
                                to={"/en/seeking-buyers-monitor/"}
                                className="btn btn-blue mt-4 mb-4 shadow"
                                onClick={this.refreshButton}
                              >
                                Reset
                              </Link>
                            </Col>

                            <Col></Col>
                          </Row>
                          <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                        </fieldset>

                        {this.state.refresh && window.location.reload()}
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default CreateDealEdit;
