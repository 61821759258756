import React, { Component, Fragment } from "react";
import Followus from "../../component/global/followus/followus";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import Freesubs from "../../component/global/free-subscription/subscription";
import { Container, Row, Col } from "react-bootstrap/";
import { Link } from "react-router-dom";
import "./app.css";
import Appscreen from "../../assets/images/appscreen-img.png";
import AppStored from "../../assets/images/app-store-md.png";
import GooglrPlay from "../../assets/images/google-play-md.png";
import AmazonPlay from "../../assets/images/amazon-appstore.png";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      result: false,
      action: true,
      is_open: false,
      company: [],
    };
  }

  componentDidMount = () => {
    console.log(111);
    console.log(101);
    window.scrollTo(0, 0);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>AnalyzeMarkets - App for Android and iOS</title>
            <meta
              name="description"
              content="Explore a seamless stream of global investment intelligence and global investment wire"
            />
            <meta
              property="og:title"
              content="AnalyzeMarkets - App for Android and iOS"
            />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Explore a seamless stream of global investment intelligence and global investment wire"
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              itemprop="name"
              content="AnalyzeMarkets - App for Android and iOS"
            />

            <meta
              itemprop="description"
              content="Explore a seamless stream of global investment intelligence and global investment wire"
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta
              name="twitter:title"
              content="AnalyzeMarkets - App for Android and iOS"
            />

            <meta
              name="twitter:description"
              content="Explore a seamless stream of global investment intelligence and global investment wire"
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            <Col className="w-70p">
              <hr />
              <div className="appsec">
                <h2 className="text-uppercase mb-4">
                  OUR FREE APP FOR PHONE AND TABLET
                </h2>
                <Row>
                  <Col md={4}>
                    {/* <img
                      src="https://analyze-website-assets.s3-eu-west-1.amazonaws.com/appscreen-img.png"
                      className="img-fluid"
                      alt="App Screen"
                    /> */}
                    <img
                      src={Appscreen}
                      className="mt-2 mb-2 img-fluid"
                      alt=""
                    />
                  </Col>
                  <Col md={8}>
                    <ul class="ticklist pt-2 pb-3">
                      <li>
                        Explore a seamless stream of Global Investment
                        Intelligence and Global Investment Wire
                      </li>
                      <li>Read anywhere, even when you’re offline</li>
                      <li>
                        Start your day with our Daily Briefing and Daily Podcast
                      </li>
                      <li>Important alerts delivered daily to your device</li>
                      <li>
                        Search by region, sub-region, country, sector, deal
                        type, advanced & emerging/frontier markets
                      </li>
                    </ul>
                    <div className="playstoer-btnsmall">
                      <Link to="#">
                        <img
                          src={AppStored}
                          className="mt-1 mb-1"
                          alt="ios play"
                        />
                      </Link>
                      <Link to="#">
                        <img
                          src={GooglrPlay}
                          className="mt-1 mb-1"
                          alt="google play"
                        />
                      </Link>
                      <Link to="#">
                        <img
                          src={AmazonPlay}
                          className="mt-1 mb-1"
                          alt="amazone play"
                        />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="w-30p">
              <div className="widget  mt-16">
                <Freesubs />
              </div>
              <div className="widget">
                <Followus />
              </div>
              <div className="widget">
                <SubscriberAccsss />
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default App;
