import React, { Component, Fragment, useState } from "react";
import {
  Row,
  Table,
  Col,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
class Task extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sector: [],
      sector_name: "",
      sector_data: "123",
    };
  }
}
const TaskList = (props) => {
  const [sector_data] = useState("");
  return props.taskList.map((val, idx) => {
    let SubIndustry = `SubIndustry-${idx}`,
      Sector = `Sector-${idx}`;
    return (
      <Row key={val.index}>
        <Col className="form-group">
          <select
            name="Sector"
            id={Sector}
            data-id={idx}
            className="form-control"
          >
            <option value="Energy">Energy</option>
            <option value="Material">Material</option>
            <option value="Industry">Industry</option>
            <option value="Hold">Hold</option>
          </select>
        </Col>

        <Col className="form-group">
          <select
            name="SubIndustry"
            id={SubIndustry}
            data-id={idx}
            className="form-control"
          >
            <option value="Test">Test</option>
            <option value="Test1">Test1</option>
          </select>
        </Col>

        <Col>
          {idx === 0 ? (
            <button
              onClick={() => props.add()}
              type="button"
              className="btn btn-primary text-center"
            >
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </button>
          ) : (
            <button
              className="btn btn-danger"
              onClick={() => props.delete(val)}
            >
              <i className="fa fa-minus" aria-hidden="true"></i>
            </button>
          )}
        </Col>
      </Row>
    );
  });
};
export default TaskList;
