import React, { Component } from "react";
import { Form } from "react-bootstrap/";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Podcastscreener extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      country: [],
      sector: [],
      category: [],
      result: false,
      selected: "",
      comapany_name: "",
      country_name: "",
      sector_name: "",
      category_name: "",
      startDate: new Date(),
      endDate: new Date(),
      start_date: "",
      end_date: "",
      last_days: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleType = this.handleType.bind(this);
    this.handleLastDate = this.handleLastDate.bind(this);
    this.handleLastDays = this.handleLastDays.bind(this);
  }
  handleLastDays = (e) => {
    console.log(e);
    if (Number.isInteger(parseInt(e))) {
      this.setState({
        last_days: e,
        startDate: "",
        start_date: "",
        end_date: "",
        endDate: "",
        type: "",
      });
    }
  };
  handleType = (e) => {
    console.log(e);
    this.setState({
      type: e,
    });
  };
  handleChange = (date) => {
    if (date !== null) {
      console.log(
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        "Test"
      );
      this.setState({
        start_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        startDate: date,
        last_days: "",
      });
    } else {
      this.setState({
        start_date: "",
        startDate: "",
        last_days: "",
      });
    }
  };
  handleLastDate = (date) => {
    if (date !== null) {
      console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
      this.setState({
        last_days: "",
        end_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        endDate: date,
      });
    } else {
      this.setState({
        last_days: "",
        end_date: "",
        endDate: "",
      });
    }
  };
  handleClick = (item) => {
    console.log(item, 22, this.state.selected, 32);

    this.props.applyFilter(
      "&type=" +
        this.state.type +
        "&start_date=" +
        this.state.start_date +
        "&end_date=" +
        this.state.end_date +
        "&last_days=" +
        this.state.last_days
    );
  };

  render() {
    return (
      <div className="irs greyborder2px">
        <h4>Podcasts Screener</h4>
        <div className="pdg15">
          <Form>
            <Form.Group>
              <Form.Control
                as="select"
                onChange={(e) => {
                  this.setState({ selected: e.target.value });
                  this.handleType(e.target.value);
                }}
              >
                <option value="">Select Podcasts Type</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Control
                as="select"
                onChange={(e) => {
                  this.setState({ selected: e.target.value });
                  this.handleLastDays(e.target.value);
                }}
              >
                <option>Select Date</option>
                <option value="3">Last 3 Days</option>
                <option value="7">Last 7 Days</option>
                <option value="30">Last 30 Days</option>
                <option value="custom_date">Select Custom Date</option>
              </Form.Control>
              {this.state.selected === "custom_date" && (
                <Form>
                  <div className="d-flex justify-content-between date-range">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleChange}
                    />
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={this.handleLastDate}
                    />
                  </div>
                </Form>
              )}
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Link
                className="btn btn-blue"
                onClick={() => {
                  this.handleClick("region");
                }}
              >
                Apply
              </Link>
              <Link to="/" className="btn btn-blue">
                Reset
              </Link>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Podcastscreener;
