import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap/";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";

class PrivacyPolicy extends Component {
  state = {
    item: [],
    section_one: [],
    section_two: [],
    section_three: [],
    news: {},
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>AnalyzeMarkets Privacy Policy</title>
            <meta
              name="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta property="og:title" content="AnalyzeMarkets Privacy Policy" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="AnalyzeMarkets Privacy Policy" />

            <meta
              itemprop="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta
              name="twitter:title"
              content="AnalyzeMarkets Privacy Policy"
            />

            <meta
              name="twitter:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            <Col md={12}>
              <hr />
              <h2 className="text-uppercase mb-4">
                AnalyzeMarkets ltd Privacy Policy
              </h2>
              <p>
                <b>Effective Date: 1st November 2019</b>
              </p>
              <h3 className="text-uppercase mb-2">Introduction</h3>
              <p>
                AnalyzeMarkets ltd (AnalyzeMarkets) want you to be familiar with
                how we collect, use and disclose information from and about you
                because we respect your concerns about privacy, and we want to
                maintain your trust.
              </p>
              <p>
                This Privacy Policy applies to information we collect from all
                sources, including in connection with our website, mobile site,
                applications ("apps"), conferences, events, and electronic
                newsletters or communications (collectively the "AnalyzeMarkets
                Services") that link to this Privacy Policy. If there is a
                conflict between this Policy and AnalyzeMarkets subscription
                agreement, AnalyzeMarkets subscription agreement will take
                precedence
              </p>
              <p>
                This Policy describes the types of Personal Data we collect, how
                we use it, and with whom we share it. We also describe the
                measures we take to protect the information, your options for
                choice and access, and how you can contact us about privacy
                practices. By using the AnalyzeMarkets Services, you acknowledge
                you have read and accept this Privacy Policy
              </p>
              <p>
                Your decision to use the AnalyzeMarkets and provide your
                Personal Data is voluntary. We indicate on our online, mobile,
                and other registration forms what types of Personal Data are
                requested. You may choose not to submit requested information
                online, or may choose to restrict the use of cookies (please
                check our cookies policy, for more information), but that may
                limit the services AnalyzeMarkets is able to provide to you.
                AnalyzeMarkets site is not targeted to, and do not knowingly
                collect Personal Data from, minors under 16 years of age. We
                request that these individuals do not provide Personal Data to
                AnalyzeMarkets
              </p>

              <h3 className="text-uppercase mb-2">Visitors To Our Websites</h3>
              <p>
                If you visit our websites, we will use Cookies to help our
                website perform correct. Cookies are small files that your web
                browsers store on your electronic device. For further details,
                please see our Cookie Notice
              </p>

              <h3 className="text-uppercase mb-2">Newsletters</h3>
              <p>
                If you subscribe to any of our newsletters, we will retain your
                contact details so that we can send you newsletters, which will
                contain information that we believe will be of use to you. Every
                email will have an unsubscribe link that can be used to stop
                future messages.
              </p>
              <h3 className="text-uppercase mb-2">Subscription Services</h3>
              <p>
                To fulfil the contract, when you subscribe to one of our
                services as a corporate subscriber, we will process your user
                id, password and contact details. We use automated tools to
                monitor website access using your account details, to identify
                and manage potential security issues and to keep your account
                safe. As a legitimate interest, we will send additional
                information that we believe will be of interest, which you can
                unsubscribe from at any time using the links at the bottom of
                emails. When you terminate your subscription, we will keep your
                record for two years, in case you want to reactive your account
              </p>
              <h3 className="text-uppercase mb-2">Information Databases</h3>
              <p>
                To provide our news and financial crime prevention services, we
                store details about people.
              </p>
              <p>
                For our financial crime prevention services, we store personal
                details (including identity, contact details, appearance on PEPs
                or Sanctions lists, and adverse media) as a public interest, to
                help our clients prevent crime. We work hard to maintain the
                quality of that information and delete information if we believe
                if to be incorrect or no longer relevant
              </p>
              <h3 className="text-uppercase mb-2">People We Work With</h3>
              <p>
                For business reasons, we partner and work with many
                organisations. We retain contact details of people we speak to
                in those organisations as a legitimate interest. We will delete
                or restrict use those details if requested, or if we believe the
                contact details are no longer of use.
              </p>
              <h3 className="text-uppercase mb-2">Job Applicants</h3>
              <p>
                Data that you provide to us will be processed as a legitimate
                interest, to assess your suitability for the role and progress
                your application. Data we will require includes contact details,
                your curriculum vitae, your previous experience, education and
                answers to questions relevant to the role you have applied for.
                Our HR department and the hiring manager for the role will have
                access to this data
              </p>
              <p>
                We will also ask about equal opportunities data. We use this
                data to produce and monitor equal opportunities statistics for
                legal reasons. There is no obligation to provide this data and
                it will not affect your application if it is not provided. This
                data will not be made available to any staff outside of our HR
                dept, including hiring managers.
              </p>
              <p>
                We will never sell your data or use it for marketing purposes.
                If we need to securely process the data in another country, we
                will let you know before the transfer happens.
              </p>
              <p>
                If your application is not successful, unless you ask us to
                retain your data for other opportunities in the future, we will
                store your details for six months to help with any questions,
                before securely deleting or anonymising the data
              </p>
              <p>
                If your application is successful, we will need to complete
                employment checks for legal reasons, to verify your right to
                work in the country and to verify your previous employment
                references
              </p>
              <h3 className="text-uppercase mb-2">Employees</h3>
              <p>
                We store your employee data within our HR systems. For
                contractual reasons, we will store details of your salary, tax,
                bank account and benefits. As a legitimate interest, we will
                store data about your performance, training, leave, absence, and
                disciplinary action and grievances.
              </p>
              <p>
                Apart from data required to provide future employment references
                with your consent, we will delete all employee data seven years
                after the contract is terminated, unless there are legal reasons
                to retain the stored data for longer
              </p>
              <br></br>

              <h3 className="text-uppercase mb-2">Protecting Personal Data</h3>
              <br></br>
              <h6 className="mb-2">
                <b>Security</b>
              </h6>
              <p>
                Security of personal data is very important to us. We use a wide
                range of organisational, technical, physical and operational
                controls, which are assessed for effectiveness on a regular
                basis
              </p>
              <p>
                <b>Individual’s Personal Data Rights</b>
              </p>
              <p>
                We fully respect your rights to request that we:
                <ol>
                  <li>
                    Allow you to opt-out of any process that you previously
                    consented to, at any time
                  </li>
                  <li>
                    Provide a copy of data we hold on you, or to pass it to a
                    third party on your behalf
                  </li>
                  <li>Amend, delete or restrict processing of your data</li>
                  <li>
                    Explain and review any automated decision making or
                    profiling
                  </li>
                  <li>
                    Provide further information about our processing activities
                  </li>
                </ol>
              </p>
              <h6 className="mb-2">
                <b>Transfers</b>
              </h6>
              <p>
                Your Personal Data may be stored, transferred to, and processed
                in any country where we have facilities or in which we engage
                service providers. These countries may be outside your country
                of residence, and may have different data protection laws than
                in your country
              </p>
              <p>
                <b>
                  Collection of Personal Financial Information by a Payment
                  Service
                </b>
              </p>
              <p>
                In some cases, we may use an unaffiliated payment service to
                allow you to purchase a product or make payments (“Payment
                Service”). If you wish to purchase a product or make a payment
                in such a case, you will be directed to a Payment Service
                webpage. Any information that you provide to a Payment Service
                will be subject to the applicable Payment Service’s privacy
                policy, rather than this Privacy Policy. We have no control
                over, and are not responsible for, any Payment Service’s use of
                information collected through any Payment Service
              </p>
              <p>
                <b>Our Privacy Notice and Updates to Privacy Policy</b>
              </p>
              <p>
                AnalyzeMarkets may periodically update this Privacy Policy
                without prior notice to reflect changes in our practices. Please
                look at the Effective Date at the top of this Privacy Policy to
                see when this Privacy Policy was last revised. Any changes to
                this Privacy Policy will become effective when we post the
                revised Privacy Policy on AnalyzeMarkets. We will notify you by
                posting a prominent notice on AnalyzeMarkets site indicating we
                have updated this Privacy Policy.
              </p>
              <p>
                Our website provides links to other websites, which are beyond
                our control. We encourage you to read the privacy statements on
                the other websites you visit.
              </p>
              <p>
                This privacy notice was drafted with brevity and clarity in
                mind. If you would like more details, please let us know
              </p>
              <h3 className="text-uppercase mb-2">CONTACT US</h3>
              <p>
                If you have any queries about the way we use your personal
                information or cookies, please contact our Data Protection
                Officer at privacy@analyzemarkets.com
              </p>
              <p>
                We will respond to your questions and complaints about our
                processing of your Personal Data. If you are not satisfied with
                our response, you can contact a supervisory authority. Please do
                not disclose any sensitive Personal Data (e.g., information
                related to racial or ethnic origin, political opinions, religion
                or other beliefs, health, or trade union membership), social
                security numbers, or criminal background information when
                contacting us.
              </p>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default PrivacyPolicy;
