import React, { Component, Fragment } from "react";
import { render } from "react-dom";

import matchSorter from "match-sorter";
import "../m&a_dealmonitor/m&adealmonitor.css";
import { Row, Table, Col, Container } from "react-bootstrap";
import GID_Sididebar from "../../component/global/gid-sidebar/gid-sidebar";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "../../component/global/pagination/index";
import "../../pages/press-release/press-release.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import IPO_Monitor_Sidebar from "../../component/global/ipo-QL/ipo-ql";
import Signin from "../../pages/signin/signinIndex";

import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Global_Investor_Database extends Component {
  constructor(props) {
    super(props);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    items: [],
    show_100: false,
    exampleItems: [],
    company: [],
    page: 0,
    current: 0,
    company_details: [],
    country: [],
    filterdata: "",
    filter: "",
    old_route: "",
    pageSize: 25,
    count: 0,
    is_first: true,
  };
  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.getData(this.state.old_route, filterdata, 0, true);
  };
  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      // this.getData("", this.state.filter, page, false);
      if (this.state.filterdata !== "") {
        this.getData("", this.state.filterdata, page, false);
      }

      console.log(55);
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    if (this.state.filterdata !== "") {
      //this.getData1(this.state.filterdata);
      this.getData(current_route, this.state.filterdata, 0, false);
    }

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  getData = (route, filterdata, page, is_first) => {
    api({
      url:
        "https://api.analyzemarkets.com/globalinvestorsdatabase/?name=" +
        route +
        "&start=" +
        page +
        filterdata,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, 63333);
      this.setState({
        items: res.data.results,
        company_details: res.data.GDDMandADealsAcquirer,
        page: res.data.count,
        current: page,
        is_first: false,
      });
      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
      }
      console.log(res.data.length + "Count Data");
    });
  };

  render() {
    const { data } = this.state;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Fetch applyFilters={this.handleFilters}></Fetch>

            <Header></Header>
            <Container>
              <Navfilter />
              <Row>
                <Col className="w-30p col-sm-3 p-4">
                  <div className="widget">
                    <GID_Sididebar />
                  </div>
                </Col>

                <Col className="w-80p p-4">
                  <div>
                    <div class="container">
                      <div>
                        <Table
                          striped
                          bordered
                          hover
                          id="table-id"
                          className="tblnoline grid"
                          size="sm"
                          responsive="lg"
                        >
                          <thead>
                            <tr>
                              <th index="0" className="centreAlign">
                                <span className="table-header">Investor</span>{" "}
                                <div class=""></div>
                              </th>
                              <th index="0" className="centreAlign">
                                <span className="table-header">
                                  Investor Type
                                </span>{" "}
                                <div class=""></div>
                              </th>
                              <th index="1" width="150" className="centreAlign">
                                <span className="table-header">Total AUM</span>

                                <div class=""></div>
                              </th>

                              <th index="2" className="centreAlign">
                                <span className="table-header">Country</span>{" "}
                                <div class=""></div>
                              </th>

                              {/* <th index="3" className="centreAlign">
                            <span className="table-header">Sector </span>

                            <div class=""></div>
                          </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.items.map((item, key) => (
                              <tr>
                                <td>
                                  {item.investor.api_url != null ? (
                                    <Link
                                      to={
                                        "/en/global-company-database-details/" +
                                        item.investor.api_url +
                                        "/"
                                      }
                                    >
                                      {item.investor.name}
                                    </Link>
                                  ) : (
                                    <Link>
                                      <u className="underline">
                                        {item.investor.name}
                                      </u>
                                    </Link>
                                  )}
                                </td>
                                <td>{item.investortype} </td>
                                <td>{item.totalaum}</td>
                                <td key={key}>{item.investor.country}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                      <div className="products-view__options justify-content-between mt-4">
                        {/* {this.state.count > 10 && (
<div className="view-options__control">
<label>Results Per Page</label>
<div></div>
</div>
)} */}
                        <Pagination
                          items={this.state.exampleItems}
                          onChangePage={this.onChangePage}
                          pageSize={this.state.pageSize}
                        ></Pagination>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default Global_Investor_Database;
