import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import Autocomplete from "react-autocomplete";
import {
  Row,
  Table,
  Col,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "../create-deal/create-deal.css";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
//import upload from "./upload.php";
import DebtGmdSidebar from "../../component/global/debt-deals-QL/debt-deal-gmd-sidebar";
import $ from "jquery";
//import Autocomplete from "material-ui/AutoComplete";
//import { makeStyles } from "material-ui/styles";
import { Editor } from "@tinymce/tinymce-react";
//import window from "react-global";
import "../create-deal/create-deal.css";
import { Link } from "react-router-dom";

import {
  matchCompany,
  matchSector,
  matchCountry,
  matchCurrency,
} from "../../component/global/investment-intellegence-screener/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Signin from "../../pages/signin/signinIndex";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
const animatedComponents = makeAnimated();

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});
class DebtDeals_Create_Deal extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.onChangePage = this.onChangePage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.changeTermMultiSelect = this.changeTermMultiSelect.bind(this);
    this.changeTermMultiSelects = this.changeTermMultiSelects.bind(this);
    this.refreshButton = this.refreshButton.bind(this);

    this.state = {
      dealheadline: "",
      fundraisingstage: "",
      dealcodename: "",
      fiscalyearend: "",
      reportingcurrency: "",
      financialstatementsaudited: "",
      minimumtargetrevenuerange: "",
      maximumtargetrevenuerange: "",
      minimumtargetEBITDArange: "",
      maximumtargetEBITDArange: "",
      minimumtargettotalassetsrange: "",
      maximumtargettotalassetsrange: "",
      refresh: "",
      //editorState: EditorState.createEmpty(),
      user: "",
      financial_type: [],
      complete_financing: [],
      itemcurrency: [],
      name_info: "",
      title: "",
      emailentry: "",
      contactnumber: "",
      youauthorizedcreatedealbehalfcompany: "",
      items: [],
      sector_data: "",
      result: false,
      country: [],
      sector: [],
      sector_name: "",
      companysubindustry: "",
      filter: "",
      subIndustry: [],
      old_route: "",
      pageSize: 25,
      count: 0,
      file: "",
      shariahcompliantfinancing: "",
      borrowerentity: "",
      totaldebtamountseeking: "",
      borrowercountryofincorporation: "",
      borrowertotalemployees: "",
      financingtype: "",
      dealbriefdescription: "",
      file_teaser: [],
      fileother: "",
      filepicture: "",
      file_other: [],
      reportedDate: new Date(),
      reported_date: "",
    };
    //this.onChange = (editorState) => this.setState({ editorState });
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  refreshButton = () => {
    this.setState({
      refresh: true,
    });
  };
  handleState = () => {
    this.setState({
      result: false,
    });
  };
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  changeTermMultiSelect = (sector_name) => {
    const state = this.state;
    state.sector_name = [];
    sector_name.forEach((option) => {
      state.sector_name.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({
      sector_name: state.sector_name,
      sector_data: null,
    });
    console.log(sector_name);
  };
  changeTermMultiSelects = (companysubindustry) => {
    const state = this.state;
    state.companysubindustry = [];
    companysubindustry.forEach((option) => {
      state.companysubindustry.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({ companysubindustry: state.companysubindustry });
    console.log(companysubindustry);
  };
  handleEditorChange = (e) => {
    this.setState({
      dealbriefdescription: e.target.getContent(),
    });
    console.log("Content was updated:", this.state.dealbriefdescription);
  };
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const id = event.target.id;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      file_teaser: event.target.files.name,
      file_other: event.target.files.name,
    });
    console.log(len, "length");
    console.log("Selected file:", this.state.file);

    //.log("Selected file:", event.target.files[0]);
  }
  onSubmit(e) {
    e.preventDefault();
    const { dealheadline } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set("dealheadline", dealheadline);
    bodyFormData.set("dealcodename", this.state.dealcodename);
    bodyFormData.set("borrowerentity", this.state.borrowerentity);
    bodyFormData.set(
      "borrowercountryofincorporation",
      this.state.borrowercountryofincorporation
    );
    bodyFormData.set("borrowersector", this.state.sector_name);
    bodyFormData.set("borrowersubindustry", this.state.companysubindustry);

    bodyFormData.set(
      "borrowertotalemployees",
      this.state.borrowertotalemployees
    );
    bodyFormData.set("financingtype", this.state.financingtype);

    bodyFormData.set(
      "shariahcompliantfinancing",
      this.state.shariahcompliantfinancing
    );
    bodyFormData.set("borroweryearofestablishment", this.state.reported_date);
    bodyFormData.set(
      "totaldebtamountseeking",
      this.state.totaldebtamountseeking
    );
    bodyFormData.set("fiscalyearend", this.state.fiscalyearend);
    bodyFormData.set("reportingcurrency", this.state.reportingcurrency);
    bodyFormData.set(
      "minimumtargetrevenuerange",
      this.state.minimumtargetrevenuerange
    );
    bodyFormData.set(
      "maximumtargetrevenuerange",
      this.state.maximumtargetrevenuerange
    );
    bodyFormData.set(
      "minimumtargetEBITDArange",
      this.state.minimumtargetEBITDArange
    );
    bodyFormData.set(
      "maximumtargetEBITDArange",
      this.state.maximumtargetEBITDArange
    );

    bodyFormData.set(
      "minimumtargettotalassetsrange",
      this.state.minimumtargettotalassetsrange
    );
    bodyFormData.set(
      "maximumtargettotalassetsrange",
      this.state.maximumtargettotalassetsrange
    );

    bodyFormData.set("fundlife", this.state.fundlife);
    bodyFormData.set("dealbriefdescription", this.state.dealbriefdescription);
    bodyFormData.set("name", this.state.name_info);
    bodyFormData.set("title", this.state.title);
    bodyFormData.set("email", this.state.emailentry);
    bodyFormData.set("contactnumber", this.state.contactnumber);
    bodyFormData.set(
      "financialstatementsaudited",
      this.state.financialstatementsaudited
    );

    bodyFormData.set(
      "youauthorizedcreatedealbehalfcompany",
      this.state.youauthorizedcreatedealbehalfcompany
    );
    bodyFormData.set("teaserpresentation", this.state.file);
    bodyFormData.set("otherdocumentstoupload", this.state.fileother);
    bodyFormData.set("projectpicturestoupload", this.state.filepicture);

    bodyFormData.set("posted_by", this.state.user);

    axios
      .post("https://api.analyzemarkets.com/gdmdebtform/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          this.setState({
            result: true,
          });
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };
  componentDidMount = () => {
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });
    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ sector: res.data.results });
      }
    });
    api({
      url: "https://api.analyzemarkets.com/financingtype/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ financial_type: res.data });
      }
    });
    axios
      .get("https://api.analyzemarkets.com/reportingcurrency/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ itemcurrency: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    api({
      url: "https://api.analyzemarkets.com/shariahcompliantfinancing/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ complete_financing: res.data });
      }
    });

    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    api({
      url: "https://api.analyzemarkets.com/companysubindustry/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ subIndustry: res.data });
      }
    });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };

  render() {
    //const classes = useStyles();

    const { data } = this.state;
    const options = [
      { title: "Energy" },
      { title: "Material" },
      { title: "Industrail" },
      { title: "Type" },
    ];
    const sec = this.state.sector;
    const subIn = this.state.subIndustry;
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Navfilter />
              <Row>
                <Col className="w-30p col-sm-3 p-4">
                  <div className="widget">
                    <DebtGmdSidebar />
                  </div>
                </Col>

                <Col className="w-80p p-4">
                  <div>
                    <div class="container">
                      <form onSubmit={this.onSubmit}>
                        <div className="iis greyborder2px">
                          <h4 className="text-left">Create a Deal</h4>
                          <div className="pdg15">
                            <fieldset>
                              <legend>
                                <b className="upercase">About Deal</b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="dealheadline"
                                    type="text"
                                    required
                                    placeholder="Deal Headline* (Max 100 characters)
                                  e.g.Contracting company seeking USD 10mn Debt Funding"
                                    onChange={this.changeTerm}
                                    maxLength="100"
                                  />
                                </Col>
                              </Row>

                              <Row className="">
                                <Col>
                                  <Form.Control
                                    name="dealcodename"
                                    type="text"
                                    required
                                    placeholder="Deal Code Name *e.g. Project Sun"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    name="borrowerentity"
                                    type="text"
                                    placeholder="Borrowing entity (optional)"
                                    onChange={this.changeTerm}
                                  />
                                </Form.Group>
                                <Col>
                                  <div
                                    className="form-group autoc"
                                    //className={classes.root}
                                  >
                                    <Autocomplete
                                      required
                                      value={
                                        this.state
                                          .borrowercountryofincorporation
                                      }
                                      inputProps={{
                                        id: "sector-autocomplete",
                                        placeholder:
                                          "Borrower Country of Incorporation*",
                                      }}
                                      wrapperStyle={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                      items={this.state.country}
                                      getItemValue={(item) => item.country}
                                      shouldItemRender={matchCountry}
                                      onChange={(
                                        event,
                                        borrowercountryofincorporation
                                      ) =>
                                        this.setState({
                                          borrowercountryofincorporation,
                                        })
                                      }
                                      onSelect={(
                                        borrowercountryofincorporation
                                      ) =>
                                        this.setState({
                                          borrowercountryofincorporation,
                                        })
                                      }
                                      renderMenu={(children) => (
                                        <div className="menu c-dropdown">
                                          {children}
                                        </div>
                                      )}
                                      renderItem={(item, isHighlighted) => (
                                        <div
                                          className={`item ${
                                            isHighlighted
                                              ? "item-highlighted"
                                              : ""
                                          }`}
                                          key={item.country}
                                        >
                                          {item.country}
                                        </div>
                                      )}
                                    />
                                  </div>{" "}
                                </Col>
                              </Row>

                              <Row className="">
                                <Col>
                                  {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                placeholder={<div>Select Sector*</div>}
                                name="sector_name"
                                onChange={this.changeTermMultiSelect}
                                isMulti
                                options={sec}
                              />
                              {console.log(this.state.rem, "rem")} */}
                                  <div
                                    className="form-group autoc"
                                    //className={classes.root}
                                  >
                                    <Autocomplete
                                      required
                                      value={this.state.sector_name}
                                      inputProps={{
                                        id: "sector-autocomplete",
                                        placeholder: "Borrower Sector*",
                                      }}
                                      wrapperStyle={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                      items={this.state.sector}
                                      getItemValue={(item) => item.sector}
                                      shouldItemRender={matchSector}
                                      onChange={(event, sector_name) =>
                                        this.setState({
                                          sector_name,
                                          sector_data: null,
                                        })
                                      }
                                      onSelect={(sector_name) =>
                                        this.setState({
                                          sector_name,
                                          sector_data: sector_name,
                                        })
                                      }
                                      renderMenu={(children) => (
                                        <div className="menu c-dropdown">
                                          {children}
                                        </div>
                                      )}
                                      renderItem={(item, isHighlighted) => (
                                        <div
                                          className={`item ${
                                            isHighlighted
                                              ? "item-highlighted"
                                              : ""
                                          }`}
                                          key={item.sector}
                                        >
                                          {item.sector}
                                        </div>
                                      )}
                                    />
                                  </div>{" "}
                                </Col>{" "}
                                <Col>
                                  {/* <Select
                                closeMenuOnSelect={false}
                                placeholder={<div>Select Sub-Industry*</div>}
                                components={animatedComponents}
                                name="companysubindustry"
                                onChange={this.changeTermMultiSelects}
                                isMulti
                                options={subIn}
                              /> */}

                                  <Form.Control
                                    as="select"
                                    name="companysubindustry"
                                    placeholder="Borrower Sub-Industry*"
                                    required
                                    className="textFont"
                                    onChange={this.changeTerm}
                                  >
                                    <option value="">
                                      Borrower Sub-Industry*
                                    </option>
                                    {this.state.subIndustry.map((item, i) => (
                                      <option
                                        key={i}
                                        value={
                                          this.state.sector_data === item.sector
                                            ? item.companysubindustry
                                            : "null"
                                        }
                                      >
                                        {this.state.sector_data === item.sector
                                          ? item.companysubindustry
                                          : ""}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="borrowertotalemployees"
                                    type="text"
                                    onChange={this.changeTerm}
                                    placeholder="Borrower Total Employees (Optional)"
                                  />
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <label className="colorWhite">
                                    Financing Type*{" "}
                                  </label>

                                  <Form.Control
                                    as="select"
                                    name="financingtype"
                                    onChange={this.changeTerm}
                                    placeholder="Financing Type*"
                                    className="textFont"
                                    rows="3"
                                    required
                                  >
                                    <option value="" className="">
                                      Financing Type*{" "}
                                    </option>
                                    {this.state.financial_type.map(
                                      (item, i) => (
                                        <option key={i} value={item.type}>
                                          {item.type}
                                        </option>
                                      )
                                    )}
                                  </Form.Control>
                                </Col>

                                <Col>
                                  <label className="colorWhite">
                                    Shariah Compliant Financing*{" "}
                                  </label>

                                  <Form.Control
                                    as="select"
                                    name="shariahcompliantfinancing"
                                    onChange={this.changeTerm}
                                    className="textFont"
                                    placeholder="Shariah Compliant Financing*"
                                    rows="3"
                                  >
                                    <option value="" className="">
                                      Shariah Compliant Financing*{" "}
                                    </option>
                                    {this.state.complete_financing.map(
                                      (item, i) => (
                                        <option key={i} value={item.type}>
                                          {item.type}
                                        </option>
                                      )
                                    )}
                                  </Form.Control>
                                </Col>
                                <Col>
                                  <Form>
                                    <label>
                                      Borrower Year of Establishment*
                                    </label>

                                    <div className="d-flex justify-content-between date-range">
                                      <DatePicker
                                        selected={this.state.reportedDate}
                                        onChange={this.handleReportedDate}
                                      />
                                    </div>
                                  </Form>
                                </Col>
                              </Row>

                              <Row className="">
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    name="totaldebtamountseeking"
                                    type="text"
                                    onChange={this.changeTerm}
                                    placeholder="Total Debt Amount Seeking (USD mn)"
                                  />
                                </Form.Group>
                              </Row>

                              <div className="form-group">
                                <Row className="">
                                  <Col>
                                    <Editor
                                      initialValue=""
                                      apiKey="l7d3ab4ybjfthcy2c4ya6vsj9rlub27d3sdqk433oyis6ild"
                                      init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                          "advlist autolink lists link image",
                                          "charmap print preview anchor help",
                                          "searchreplace visualblocks code",
                                          "insertdatetime media table paste wordcount",
                                        ],
                                        toolbar:
                                          "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help | link | image",
                                      }}
                                      onChange={this.handleEditorChange}
                                    />

                                    {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                  as="textarea"
                                  name="companydealbriefdescription"
                                  rows="7"
                                  maxLength="100"
                                  onChange={this.changeTerm}
                                  placeholder="Company/Deal Brief Description* (Maximum 100 words)"
                                />
                              </Form.Group> */}
                                  </Col>{" "}
                                </Row>
                              </div>
                              <legend>
                                {" "}
                                <b className="upercase">Key Financials</b>
                              </legend>
                              <Row className="">
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="financialstatementsaudited"
                                    required
                                    className="textFont"
                                    onChange={this.changeTerm}
                                    // placeholder="Are you Authorized to create this deal"
                                  >
                                    <option value="">
                                      Financial Statement Audited*
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>

                                {console.log(this.state.file, "Type of Audit")}
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="fiscalyearend"
                                    onChange={this.changeTerm}
                                    placeholder="Fiscal Year End*"
                                    rows="3"
                                    className="textFont"
                                    required
                                  >
                                    <option value="" className="">
                                      Fiscal Year End*
                                    </option>
                                    <option value="Janaury">Janaury</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                  </Form.Control>
                                </Col>
                                <Col>
                                  <div
                                    className="form-group autoc"
                                    //className={classes.root}
                                  >
                                    <Autocomplete
                                      required
                                      value={this.state.reportingcurrency}
                                      inputProps={{
                                        id: "sector-autocomplete",
                                        placeholder: "Reporting Currency*",
                                      }}
                                      wrapperStyle={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                      items={this.state.itemcurrency}
                                      getItemValue={(item) =>
                                        item.reportingcurrency
                                      }
                                      shouldItemRender={matchCurrency}
                                      onChange={(event, reportingcurrency) =>
                                        this.setState({
                                          reportingcurrency,
                                        })
                                      }
                                      onSelect={(reportingcurrency) =>
                                        this.setState({
                                          reportingcurrency,
                                        })
                                      }
                                      renderMenu={(children) => (
                                        <div className="menu c-dropdown">
                                          {children}
                                        </div>
                                      )}
                                      renderItem={(item, isHighlighted) => (
                                        <div
                                          className={`item ${
                                            isHighlighted
                                              ? "item-highlighted"
                                              : ""
                                          }`}
                                          key={item.reportingcurrency}
                                        >
                                          {item.reportingcurrency}
                                        </div>
                                      )}
                                    />
                                  </div>{" "}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr>
                                        <th width="200"> </th>
                                        <th className="colorWhite" width="200">
                                          Minimum
                                        </th>
                                        <th className="colorWhite" width="200">
                                          Maximum
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Target Revenue Range</td>
                                        <td>
                                          {" "}
                                          <Row>
                                            {" "}
                                            <Col sm={12}>
                                              {" "}
                                              <Form.Control
                                                name="minimumtargetrevenuerange"
                                                type="text"
                                                required
                                                placeholder="Required"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        </td>
                                        <td>
                                          {" "}
                                          <Row>
                                            {" "}
                                            <Col sm={12}>
                                              {" "}
                                              <Form.Control
                                                name="maximumtargetrevenuerange"
                                                type="text"
                                                placeholder="Optional"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Target EBITDA Range</td>
                                        <td>
                                          <Row>
                                            {" "}
                                            <Col sm={12}>
                                              {" "}
                                              <Form.Control
                                                name="minimumtargetEBITDArange"
                                                type="text"
                                                required
                                                placeholder="Required"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        </td>
                                        <td>
                                          <Row>
                                            {" "}
                                            <Col sm={12}>
                                              {" "}
                                              <Form.Control
                                                name="maximumtargetEBITDArange"
                                                type="text"
                                                placeholder="Optional"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Target Total Assets Range</td>
                                        <td>
                                          <Row>
                                            {" "}
                                            <Col sm={12}>
                                              {" "}
                                              <Form.Control
                                                name="minimumtargettotalassetsrange"
                                                type="text"
                                                required
                                                placeholder="Required"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        </td>
                                        <td>
                                          <Row>
                                            {" "}
                                            <Col sm={12}>
                                              {" "}
                                              <Form.Control
                                                name="maximumtargettotalassetsrange"
                                                type="text"
                                                placeholder="Optional"
                                                onChange={this.changeTerm}
                                              />
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>

                              <legend>
                                <b className="upercase">
                                  Requirement Documents
                                </b>
                              </legend>
                              <div className="form-group">
                                <label> Teaser/Presentation</label>

                                <Row>
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="file"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                </Row>
                              </div>
                              <div className="form-group">
                                <label> Other documents to upload:</label>

                                <Row>
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="fileother"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                </Row>
                              </div>
                              <div className="form-group">
                                <label>
                                  {" "}
                                  Project Pictures/Video to upload:
                                </label>

                                <Row>
                                  <Col>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="filepicture"
                                      onChange={this.handleChange}
                                    />{" "}
                                  </Col>
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                </Row>
                              </div>

                              <legend>
                                <b className="upercase">
                                  Contact Information For Potential Investors
                                </b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="name_info"
                                    type="text"
                                    placeholder="Name*"
                                    required
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="title"
                                    type="text"
                                    placeholder="Title*"
                                    required
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    name="emailentry"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                    required
                                    onChange={this.changeTerm}
                                  />
                                </Col>

                                <Col>
                                  <Form.Control
                                    name="contactnumber"
                                    type="tel"
                                    required
                                    placeholder="Contact number*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <label>
                                    Are you authorized to create this deal on
                                    behalf of the Company*:{" "}
                                  </label>
                                  <Form.Control
                                    as="select"
                                    name="youauthorizedcreatedealbehalfcompany"
                                    required
                                    className="textFont"
                                    onChange={this.changeTerm}
                                    // placeholder="Are you Authorized to create this deal"
                                  >
                                    <option value="">Select*</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col sm={3}>
                                  <Button
                                    variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                    type="submit"
                                    size="sm"
                                  >
                                    Publish Deal
                                  </Button>
                                </Col>
                                <Col>
                                  <Link
                                    className="btn btn-blue mt-4 mb-4 shadow"
                                    onClick={this.refreshButton}
                                  >
                                    Clear
                                  </Link>
                                </Col>
                              </Row>
                              {this.state.refresh && window.location.reload()}

                              {this.state.result && (
                                <div className="borderColors2">
                                  <span
                                    className="buttonFloat"
                                    onClick={this.handleState}
                                  >
                                    <i className="fa fa-times-circle"></i>
                                  </span>
                                  <span>
                                    <i
                                      class="fa fa-check-circle"
                                      style={{ color: "green" }}
                                    ></i>
                                  </span>
                                  &nbsp;
                                  <span>
                                    <h4>
                                      {" "}
                                      <b className="colorError">
                                        Your Deal is Successfully Submitted
                                      </b>
                                    </h4>
                                    <br></br>
                                  </span>
                                </div>
                              )}
                            </fieldset>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default DebtDeals_Create_Deal;
