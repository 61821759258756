import React, { Component, Fragment } from "react";
import Navfilter from "../../component/global/navfilter/navfilter";
import NavfilterPlaceholder from "../../component/global/navfilter/navfilter-static";
import Newspost from "../../component/global/news-post/index";
import Freesubs from "../../component/global/free-subscription/subscription";
import InvestmentIn from "../../component/global/investment-intelligence/investment-intelligence";
import Investwire from "../../component/global/investment-wire/investment-wire";
import Followus from "../../component/global/followus/followus";
import Offerwidget from "../../component/global/offers-widget/offers-widget";
import Bnrwidget from "../../component/global/banner-widget";
import { Container, Row, Col } from "react-bootstrap/";
import "./home.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";
import WeeklyInfo from "../../component/global/weekly-info/weekly-info";
// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section_one: [1, 2, 3, 4, 5, 6],
      section_two: [],
      section_three: [],
      region: "",
      sector: "",
      is_first: true,
    };
    this.greetHandler = this.greetHandler.bind(this);
  }

  greetHandler(childName, name) {
    console.log(35, childName);
    let region = "";
    let sector = "";
    let _self = this;
    if (name === "region") {
      region = childName.id;
      _self.setState({
        region: region,
      });
    } else {
      sector = childName.id;
      _self.setState({
        sector: sector,
      });
    }
    if (region === "") {
      region = _self.state.region;
    }

    if (sector === "") {
      sector = _self.state.sector;
    }
    _self.getData(region, sector);
  }

  getData = (region, sector) => {
    console.log(this.state.region, 51, this.state.sector);

    api({
      url:
        "https://api.analyzemarkets.com/giim_home/?region=" +
        region +
        "&sector=" +
        sector,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log("Request response:", res);
      this.setState({
        section_one: res.data.section_one,
        section_two: res.data.section_two,
        section_three: res.data.section_three,
        is_first: false,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  componentDidMount = () => {
    this.getData("", "");
    console.log(111);
    console.log(101);
    window.scrollTo(0, 0);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
    if(token) {
      window.location.href = '/en/investment_wire/'
    }
  };
  render() {
    var greetHandler = this.greetHandler;
    return (
      <Fragment>
        <Header />
        <Container>
          <div>
            {" "}
            <Helmet>
              <title>
                AnalyzeMarkets - Global Investment Intelligence Platform
              </title>
              <meta
                name="description"
                content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
              />

              <meta
                property="og:title"
                content="AnalyzeMarkets - Global Investment Intelligence Platform"
              />
              <meta
                property="og:image"
                content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
              />
              <meta
                name="og:description"
                content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
              />
              <meta name="og:url" content="https://www.analyzemarkets.com" />
              <meta name="og:site_name" content="AnalyzeMarkets" />
              <meta name="og:locale" content="en" />
              <meta name="og:type" content="website" />

              <meta
                name="image"
                content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
              />
              <meta
                itemprop="name"
                content="AnalyzeMarkets - Global Investment Intelligence Platform"
              />

              <meta
                itemprop="description"
                content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
              />
              <meta
                itemprop="image"
                content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
              />

              <meta name="twitter:card" content="summary_large_image" />

              <meta
                name="twitter:title"
                content="AnalyzeMarkets - Global Investment Intelligence Platform"
              />

              <meta
                name="twitter:description"
                content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
              />

              <meta name="twitter:site" content="@AnalyzeMarkets" />
              <meta name="twitter:creator" content="@AnalyzeMarkets" />
              <meta
                name="twitter:image"
                content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
              />
            </Helmet>
            {!this.state.is_first && !this.props.loading && (
              <Fragment>
                <Navfilter greetChild={greetHandler.bind(this)} />

                <Row>
                  <Col className="w-70p">
                    <Row>
                      {!this.state.is_first &&
                        this.state.section_one.map((item, key) => (
                          <Col md="4">
                            <Newspost key={item.id} item={item} />
                          </Col>
                        ))}
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Bnrwidget />
                      </Col>
                    </Row>
                    <Row>
                      {this.state.section_two.map((item, key) => (
                        <Col md="4">
                          <Newspost key={item.id} item={item} />
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Bnrwidget />
                      </Col>
                    </Row>
                    <Row>
                      {this.state.section_three.map((item, key) => (
                        <Col md="4">
                          <Newspost key={item.id} item={item} />
                        </Col>
                      ))}
                    </Row>
                    <br></br>
                    <Row>
                      {" "}
                      <a
                        href="/en/intelligence_index"
                        className="btn btn-blue subscribe-btn centerAlign"
                      >
                        Read More
                      </a>
                    </Row>
                  </Col>

                  <Col className="w-30p">
                    <div className="widget mt-18">
                      <WeeklyInfo />
                    </div>
                    <div className="widget mt-22">
                      <Freesubs />
                    </div>
                    <div className="widget">
                      <InvestmentIn />
                    </div>
                    <div className="widget">
                      <Followus />
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
            {this.state.is_first && (
              <Fragment>
                <NavfilterPlaceholder />
                <Row>
                  <Col className="w-70p">
                    <Row>
                      <Col md="4">
                        <div class="ph-item">
                          <div class="ph-col-12">
                            <div class="ph-row">
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                            </div>
                            <div class="ph-picture"></div>
                            <div class="ph-row bdrph">
                              <div class="ph-col-4"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-6 big"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-4 big"></div>
                              <div class="ph-col-2 big"></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div class="ph-item">
                          <div class="ph-col-12">
                            <div class="ph-row">
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                            </div>
                            <div class="ph-picture"></div>
                            <div class="ph-row bdrph">
                              <div class="ph-col-4"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-6 big"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-4 big"></div>
                              <div class="ph-col-2 big"></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div class="ph-item">
                          <div class="ph-col-12">
                            <div class="ph-row">
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                            </div>
                            <div class="ph-picture"></div>
                            <div class="ph-row bdrph">
                              <div class="ph-col-4"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-6 big"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-4 big"></div>
                              <div class="ph-col-2 big"></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div class="ph-item">
                          <div class="ph-col-12">
                            <div class="ph-row">
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                            </div>
                            <div class="ph-picture"></div>
                            <div class="ph-row bdrph">
                              <div class="ph-col-4"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-6 big"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-4 big"></div>
                              <div class="ph-col-2 big"></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div class="ph-item">
                          <div class="ph-col-12">
                            <div class="ph-row">
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                            </div>
                            <div class="ph-picture"></div>
                            <div class="ph-row bdrph">
                              <div class="ph-col-4"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-6 big"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-4 big"></div>
                              <div class="ph-col-2 big"></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div class="ph-item">
                          <div class="ph-col-12">
                            <div class="ph-row">
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                            </div>
                            <div class="ph-picture"></div>
                            <div class="ph-row bdrph">
                              <div class="ph-col-4"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-6 empty"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-6 big"></div>
                              <div class="ph-col-8 empty"></div>
                              <div class="ph-col-4 big"></div>
                              <div class="ph-col-2 big"></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Fragment>
            )}
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default Home;
