import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const AppRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={data => (<Component {...data} />)} />
);

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.func,
};

AppRoute.displayName = 'AppRoute';

export default AppRoute;
