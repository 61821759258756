import React, { Component } from "react";
import { Card } from "react-bootstrap/";
import gdd from "../../../assets/images/DATA-SCIENCE-TEAM.svg";
import gdm2 from "../../../assets/images/DETERMINE-OBJECT.svg";
import gcd from "../../../assets/images/DATABASE.svg";
import gid from "../../../assets/images/DATA-COLLECTION.svg";
import gpd from "../../../assets/images/B2B.svg";
import grrd from "../../../assets/images/STATISTICs.svg";
import odraa from "../../../assets/images/DASHBOARD-REPORT.svg";
import "./banner-widget.css";

class Bnrwidget extends Component {
  render() {
    return (
      <Card className="offers-sec grad-bg mb-4">
        <Card.Body>
          <Card.Title className="pb-3">
            AnalyzeMarkets' subscription-based AI Global SaaS Platform provides
            access to all Databases, Deal Flow, Opportunities, and Deals
            Intelligence across multiple asset classes.
          </Card.Title>
          <div className="row-1">
            <div className="bnr2blk">
              <img src={gdd} className="img-fluid" alt="" />
              <h6>GLOBAL DEALS INTELLIGENCE</h6>
            </div>
            <div className="bnr2blk">
              <img src={gdm2} className="img-fluid" alt="" />
              <h6>GLOBAL DEALS MARKETPLACE</h6>
            </div>
            <div className="bnr2blk">
              <img src={gcd} className="img-fluid" alt="" />
              <h6>GLOBAL COMPANIES DATABASE</h6>
            </div>
          </div>
          <div className="row-2">
            <div className="bnr2blk">
              <img src={gid} className="img-fluid" alt="" />
              <h6>GLOBAL INVESTORS DATABASE</h6>
            </div>
            <div className="bnr2blk">
              <img src={gpd} className="img-fluid" alt="" />
              <h6>GLOBAL PROFESSIONALS DATABASE</h6>
            </div>
            <div className="bnr2blk">
              <img src={grrd} className="img-fluid" alt="" />
              <h6>GLOBAL RESEARCH REPORTS</h6>
            </div>
            <div className="bnr2blk">
              <img src={odraa} className="img-fluid" alt="" />
              <h6>ON-DEMAND RESEARCH AND ANALYSIS</h6>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default Bnrwidget;
