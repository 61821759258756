import React, { Component, Fragment } from "react";
import Followus from "../../component/global/Followus_wire/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import VcDealsScrenner from "../../component/global/M&ADealsSearch/vc-seeking-fund-screener";
import Iis from "../../component/global/investment-intellegence-screener/investment-Intelligence-screener";
import { Container, Grid, Row, Col, Table } from "react-bootstrap/";
import VC_SideBar from "../../component/global/vc_deals_sidebar/vc_deals_sidebar";
import CompanylogoS from "../../assets/images/clogo-s.jpg";
import CompanylogoB from "../../assets/images/clogo-b.jpg";
import { Link } from "react-router-dom";
import "../../pages/press-release/press-release.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import Moment from "react-moment";
import Pagination from "../../component/global/pagination/index";
import { Helmet } from "react-helmet";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Fetch from "../../component/global/CommonComponent/useFetch";
import Signin from "../../pages/signin/signinIndex";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Seeking_Fund_Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giw: [],
      madeals: [],
      items: [],
      search: false,
      nosearch: false,
      status: false,
      exampleItems: [],
      company: [],
      counts: 0,
      page: 0,
      filter: "",
      filterdata: "",
      current: 0,
      country: [],
      old_route: "",
      pageSize: 25,
      count: 0,
      is_first: true,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }

  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.getData(this.state.old_route, this.state.filter, filterdata, 0, true);
  };

  handleFilter = (filter) => {
    console.log(filter, 31);
    this.setState({
      filter: filter,
      is_filter: true,
    });
    //this.getData(this.state.old_route, filter, 0, true);
    this.getData(this.state.old_route, filter, this.state.filterdata, 0, true);
  };

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      // this.getData("", this.state.filter, page, false);
      if (this.state.filterdata !== "") {
        this.getData("", this.state.filter, this.state.filterdata, page, false);
      }

      console.log(55);
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    if (this.state.filterdata !== "") {
      //this.getData1(this.state.filterdata);
      this.getData(current_route, "", this.state.filterdata, 0, false);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  getData = (route, filter, filterdata, page, is_first) => {
    console.log(89, route);

    api({
      url:
        "https://api.analyzemarkets.com/gdd_vc_ssf/?name=" +
        route +
        "&start=" +
        page +
        "" +
        filter +
        "" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      console.log(res, "Vc Deals Search");
      if (res.status === 200) {
        this.setState({
          madeals: res.data.results,
          items: res.data.results,
          page: res.data.count,
          current: page,
          is_first: false,
        });
      }
      if (filter != "" && res.data.count != res.data.totalcount) {
        console.log(filter, "filter");
        this.setState({
          status: true,
        });
      }
      if (filter == "") {
        this.setState({
          status: true,
        });
      }
      if (res.data.count === res.data.totalcount) {
        this.setState({
          status: false,
        });
      }

      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
        if (res.data.count === 0) {
          this.setState({
            nosearch: true,
            search: false,
          });
        }
        if (res.data.count != 0) {
          this.setState({
            search: true,
            nosearch: false,
          });
        }
      }
      console.log(res.data.count + "Count Data");
    });
  };

  render() {
    const data = this.state.company;
    const count = this.state.count;
    console.log(count, "count m");
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Helmet title="Venture Capital Deals Search">
                <meta
                  name="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta property="og:title" content="M&A Deals Monitor" />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  name="og:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  itemprop="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Navfilter />
              {!this.state.is_first && (
                <div>
                  <Row>
                    <Col className="w-30p col-sm-3 p-4">
                      <div className="widget">
                        <VC_SideBar />
                      </div>
                    </Col>

                    <Col className="w-70p  p-4">
                      <VcDealsScrenner applyFilter={this.handleFilter} />
                    </Col>
                  </Row>
                  {this.state.status == true && (
                    <Row>
                      <Col className="p-4">
                        <div>
                          <Row>
                            <Col sm={12} className="adapt" id="compdata">
                              {this.state.search && <h3>Search Result</h3>}

                              {this.state.nosearch && <h3>No Search Result</h3>}
                              {this.state.items.map((item, key) => (
                                <div className="greyborder1_4px placeholder scccc">
                                  <Row className="show-grid">
                                    <Col md={9}>
                                      {/* <div className="company-name"> {item.company_name.map((company, key) => (
                                     <Link to={'/en/global-company-database-details/'+company.api_url+'/'>
                                                        <u className="underline">{company.name}</u>
                                                        </Link>
                                                     ))}</div> */}

                                      <div className="company-name">
                                        {" "}
                                        <h6>
                                          <Link
                                            to={
                                              "/en/seeking_funding_detail/" +
                                              item.api_url +
                                              "/"
                                            }
                                          >
                                            {item.deal}
                                          </Link>
                                        </h6>
                                      </div>

                                      <strong className="title">
                                        <Moment format="DD-MMM-YYYY">
                                          {item.reported_date}
                                        </Moment>
                                      </strong>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </Col>
                          </Row>

                          <div className="products-view__options justify-content-between mt-4">
                            {/* {this.state.count > 10 && (
                    <div className="view-options__control">
                      <label>Results Per Page</label>
                      <div></div>
                    </div>
                  )} */}
                            <Pagination
                              items={this.state.exampleItems}
                              onChangePage={this.onChangePage}
                              pageSize={this.state.pageSize}
                            ></Pagination>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              )}
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default Seeking_Fund_Search;
