import React, { Component, Fragment } from "react";
import Followus from "../../component/global/followus/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import Podcastscreener from "../../component/global/podcasts-screener";
import Audioplayer from "../../component/global/audio-player";
import Podcastbtn from "../../component/global/podcast-btn-group";
import { Container, Row, Col } from "react-bootstrap/";
import Pagination from "../../component/global/pagination/index";
import PodactsThumb from "../../assets/images/podcast.jpg";
import PodactsDailyThumb from "../../assets/images/daily-podcast.jpg";
import PodactsWeeklyThumb from "../../assets/images/weekly-podcast.jpg";
import "./podcasts.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";

class Podcasts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      podcast: [],
      items: [],
      show_100: false,
      exampleItems: [],
      company: [],
      page: 0,
      current: 0,
      company_details: [],
      country: [],
      filter: "",
      old_route: "",
      pageSize: 25,
      count: 0,
      is_first: true,
    };
    this.onChangePage = this.onChangePage.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }

  handleFilter = (filter) => {
    console.log(filter, 39);
    console.log(filter, 31);
    this.setState({
      filter: filter,
    });
    this.getData(this.state.old_route, filter, 0, true);
  };

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };
  getData = (route, filter, page, is_first) => {
    console.log(48);
    axios
      .get(
        "https://api.analyzemarkets.com/podcast/?name=" +
          route +
          "&start=" +
          page +
          "" +
          filter
      )
      .then((res) => {
        console.log(res);
        this.setState({
          podcast: res.data.results,
          page: res.data.count,
          current: page,

          is_first: false,
        });

        if (is_first) {
          this.setState({
            exampleItems: [...Array(res.data.count).keys()].map((i) => ({
              id: i + 1,
              name: "Item " + (i + 1),
            })),
            count: res.data.count,
          });
        }
      })
      .catch((error) => {
        //on error
        // alert("There is an error in API call.");
      });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    this.getData(current_route, "", 0, true);
  };

  render() {
    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Helmet>
            <title>AnalyzeMarkets - Podcasts</title>
            <meta
              name="description"
              content="Discover daily and weekly important and trending global investment intelligence in podcast form"
            />

            <meta property="og:title" content="AnalyzeMarkets - Podcasts" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Discover daily and weekly important and trending global investment intelligence in podcast form"
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="AnalyzeMarkets - Podcasts" />

            <meta
              itemprop="description"
              content="Discover daily and weekly important and trending global investment intelligence in podcast form"
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="AnalyzeMarkets - Podcasts" />

            <meta
              name="twitter:description"
              content="Discover daily and weekly important and trending global investment intelligence in podcast form"
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            <Col className="w-70p">
              <hr />
              <h2 className="text-uppercase mb-4">Podcasts</h2>
            </Col>
          </Row>
          <Row>
            {!this.state.is_first && (
              <Fragment>
                <Col className="w-70p">
                  {this.state.podcast.map((item, index) => (
                    <article key={index} className="mb-5">
                      <div className="two-item">
                        <div className="two-image-holder">
                          {item.type == "Daily" && (
                            <img
                              src={PodactsDailyThumb}
                              className="img-fluid"
                              alt=""
                            />
                          )}

                          {item.type == "Weekly" && (
                            <img
                              src={PodactsWeeklyThumb}
                              className="img-fluid"
                              alt=""
                            />
                          )}
                        </div>
                        <div className="two-content-holder">
                          <h5>{item.name}</h5>
                          <p>{item.company}</p>
                          <Audioplayer url={item.audio} />
                          <Podcastbtn />
                        </div>
                      </div>
                      {/* <hr/> */}
                    </article>
                  ))}

                  <div className="products-view__options justify-content-between mt-4">
                    {/* {this.state.count > 10 && (
<div className="view-options__control">
<label>Results Per Page</label>
<div></div>
</div>
)} */}
                    <Pagination
                      items={this.state.exampleItems}
                      onChangePage={this.onChangePage}
                      pageSize={this.state.pageSize}
                    ></Pagination>
                  </div>
                </Col>
                <Col className="w-30p">
                  <div className="widget">
                    <Podcastscreener applyFilter={this.handleFilter} />
                  </div>
                  <div className="widget">
                    <Followus />
                  </div>
                  <div className="widget">
                    <Downloadapp />
                  </div>
                  <div className="widget">
                    <SubscriberAccsss />
                  </div>
                </Col>
              </Fragment>
            )}

            {this.state.is_first && (
              <Fragment>
                <Col className="w-73p">
                  <Row>
                    <Col md={10}>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-12"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-8"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-12"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-10"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-12"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-8"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-10"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-12"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-12"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-8"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-10"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-12"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-8"></div>
                        </div>
                      </div>
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-10"></div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Fragment>
            )}
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Podcasts;
