import React, { Component, Fragment } from "react";
import GID_Sidebar from "../../component/global/gid-sidebar/gid-sidebar";
import { Container, Row, Table, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import imggraph from "../../../src/assets/images/graph.png";
import { Link } from "react-router-dom";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class GID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      show_100: false,
      exampleItems: [],
      company: [],
      page: 0,
      current: 0,
      company_details: [],
      country: [],
      filter: "",
      old_route: "",
      pageSize: 25,
      count: 0,
      is_first: true,
    };
  }
  componentDidMount = () => {
    api({
      url: "https://api.analyzemarkets.com/globalinvestorsdatabase/?name=",

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, 63333);
      this.setState({
        items: res.data.results,
        count: res.data.count,
      });

      console.log(res.data.count + "Count Data");
    });
  };
  render() {
    const con = this.state.count;
    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Navfilter />

          <div>
            <Row>
              <Col className="w-30p">
                <div className="widget">
                  <Fragment>
                    <Container>
                      <div>
                        <Row className="show-grid">
                          <Col>
                            <GID_Sidebar />
                          </Col>
                        </Row>
                      </div>
                    </Container>
                  </Fragment>
                </div>
              </Col>

              <Col className="w-70p">
                <div>
                  <div>
                    <h3 className="head-title">
                      <i class="fa fa-check"></i> &nbsp; Total Investors in our
                      database : {con}
                    </h3>
                  </div>

                  <div className="">
                    <img src={imggraph} className="img-fluid" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default GID;
