import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "./company.css";
import Iws from "../../component/global/investment-wire-screener/investment-wire-screener";
import Followus from "../../component/global/followus/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import escapeStringRegexp from "escape-string-regexp";
import Datasort from "react-data-sort";
import Header from "../../component/global/header/index";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class company extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    company: [],
    country: [],
    giw: [],
    company_type: [],
    sector: [],
    show_100: false,
    item: [],
    news: [],
    count: 0,
    id: null,
    section_one: [],
    section_two: [],
    section_three: [],
    section_group: [],
    is_first: true,
    is_first: false,
    section_one_mostread: [],
    section_two_mostread: [],
    section_three_mostread: [],
    show: false,
    show_most_read: true,
  };

  showhide = () => {
    this.setState({ show: !this.state.show });
  };

  showhidemostread = () => {
    this.setState({ show_most_read: !this.state.show_most_read });
  };

  componentDidMount = () => {
    api({
      url:
        "https://api.analyzemarkets.com/compmgmt-single/" +
        this.props.match.params.name +
        "/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        company: res.data,
        show_100: true,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    api({
      url: "https://api.analyzemarkets.com/country/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        country: res.data.results,
        show_100: true,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    api({
      url: "https://api.analyzemarkets.com/companylegaltype/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        company_type: res.data.results,
        show_100: true,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    api({
      url: "https://api.analyzemarkets.com/giwm/?name=",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        giw: res.data.results,
        show_100: true,
        section_one_mostread: res.data.results,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        sector: res.data.results,
        show_100: true,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    api({
      url: "https://api.analyzemarkets.com/giim/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        section_one: res.data.section_one,
        section_two: res.data.section_two,
        section_three: res.data.section_three,
        section_group:
          res.data.section_one &&
          res.data.section_two &&
          res.data.section_three,
        section_one_mostread: res.data.section_one,
        section_two_mostread: res.data.section_two,
        section_three_mostread: res.data.section_two,
        show_100: true,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  render() {
    const data = this.state.company;
    const det = this.state.giw;
    const details = this.state.news;

    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Row>
            <Col className="w-70p">
              <Row className="justify-content-md-center">
                <Col xs sm={11}>
                  <hr />
                  <span className="companyimage">
                    {<img src={data.image} alt="" className="img-logo"></img>}
                  </span>
                  <h2 class="company-title">{data.name}</h2>

                  <div className="newstagd">
                    {"Also Known as: " + data.known_as_name}
                  </div>

                  <div className="">
                    {data.description !== null ? <hr /> : ""}
                  </div>
                  <div className="">{data.description}</div>
                  <div className="">
                    {data.description !== null ? <hr /> : ""}
                  </div>

                  {data.api_url == null ? (
                    ""
                  ) : (
                    <div>
                      <h2 className="invest-intell">
                        INVESTMENT INTELLIGENCE:
                      </h2>
                    </div>
                  )}
                  <Row>
                    <Col sm={12}>
                      {this.state.section_one.map((items, index) => (
                        <div>
                          {items.company_management.map((companyies, key) => (
                            <div>
                              {companyies.id == data.id && (
                                <Row className="show-grid greyborder1_4px_bottom placeholder">
                                  <Col md={3}>
                                    {/* section 1      */}

                                    <span>
                                      {companyies.id == data.id && (
                                        <img
                                          src={items.image}
                                          className="clogo-t"
                                          alt=""
                                        />
                                      )}
                                    </span>
                                  </Col>
                                  <Col md={9}>
                                    {/* section 1      */}

                                    <div>
                                      <div className="title">
                                        {companyies.id == data.id && (
                                          <div className="newstagd news-country">
                                            {
                                              items.news_country_category_sector_details
                                            }
                                          </div>
                                        )}

                                        {companyies.id == data.id && (
                                          <Link
                                            to={
                                              "/en/intelligence/" +
                                              items.url +
                                              "/"
                                            }
                                          >
                                            <h3 className="title-wire">
                                              {items.title}
                                            </h3>
                                          </Link>
                                        )}
                                        {console.log(
                                          companyies.id == data.id,
                                          "Meaning12345"
                                        )}
                                        <p>
                                          {companyies.id == data.id && (
                                            <Moment
                                              format="DD-MMM-YYYY"
                                              className="space"
                                            >
                                              {items.modified_at}
                                            </Moment>
                                          )}
                                          {companyies.id == data.id && (
                                            <Moment
                                              format="hh:mm a"
                                              className="space"
                                            >
                                              {items.modified_at}
                                            </Moment>
                                          )}
                                          {companyies.id == data.id && "GMT"}
                                          {companyies.id == data.id && (
                                            <Moment format="Z">
                                              {items.modified_at}
                                            </Moment>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      {this.state.section_two.map((items, index) => (
                        <div>
                          {items.company_management.map((companyies, key) => (
                            <div>
                              {companyies.id == data.id && (
                                <Row className="show-grid greyborder1_4px_bottom placeholder">
                                  <Col md={3}>
                                    {/* section 1      */}

                                    <span>
                                      {companyies.id == data.id && (
                                        <img
                                          src={items.image}
                                          className="clogo-t"
                                          alt=""
                                        />
                                      )}
                                    </span>
                                  </Col>
                                  <Col md={9}>
                                    {/* section 1      */}

                                    <div>
                                      <div className="title">
                                        {companyies.id == data.id && (
                                          <div className="newstagd news-country">
                                            {
                                              items.news_country_category_sector_details
                                            }
                                          </div>
                                        )}

                                        {companyies.id == data.id && (
                                          <Link
                                            to={
                                              "/en/intelligence/" +
                                              items.url +
                                              "/"
                                            }
                                          >
                                            <h3 className="title-wire">
                                              {items.title}
                                            </h3>
                                          </Link>
                                        )}
                                        {console.log(
                                          companyies.id == data.id,
                                          "Meaning12345"
                                        )}
                                        <p>
                                          {companyies.id == data.id && (
                                            <Moment
                                              format="DD-MMM-YYYY"
                                              className="space"
                                            >
                                              {items.modified_at}
                                            </Moment>
                                          )}
                                          {companyies.id == data.id && (
                                            <Moment
                                              format="hh:mm a"
                                              className="space"
                                            >
                                              {items.modified_at}
                                            </Moment>
                                          )}
                                          {companyies.id == data.id && "GMT"}
                                          {companyies.id == data.id && (
                                            <Moment format="Z">
                                              {items.modified_at}
                                            </Moment>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </Col>
                  </Row>

                  {this.state.show_most_read && (
                    <Row>
                      <Col sm={12}>
                        {this.state.section_three.map((items, index) => (
                          <div>
                            {items.company_management.map((companyies, key) => (
                              <div>
                                {companyies.id == data.id && (
                                  <Row className="show-grid greyborder1_4px_bottom placeholder">
                                    <Col md={3}>
                                      {/* section 1      */}

                                      <span>
                                        {companyies.id == data.id && (
                                          <img
                                            src={items.image}
                                            className="clogo-t"
                                            alt=""
                                          />
                                        )}
                                      </span>
                                    </Col>
                                    <Col md={9}>
                                      {/* section 1      */}

                                      <div>
                                        <div className="title">
                                          {companyies.id == data.id && (
                                            <div className="newstagd news-country">
                                              {
                                                items.news_country_category_sector_details
                                              }
                                            </div>
                                          )}

                                          {companyies.id == data.id && (
                                            <Link
                                              to={
                                                "/en/intelligence/" +
                                                items.url +
                                                "/"
                                              }
                                            >
                                              <h3 className="title-wire">
                                                {items.title}
                                              </h3>
                                            </Link>
                                          )}
                                          {console.log(
                                            companyies.id == data.id,
                                            "Meaning12345"
                                          )}
                                          <p>
                                            {companyies.id == data.id && (
                                              <Moment
                                                format="DD-MMM-YYYY"
                                                className="space"
                                              >
                                                {items.modified_at}
                                              </Moment>
                                            )}
                                            {companyies.id == data.id && (
                                              <Moment
                                                format="hh:mm a"
                                                className="space"
                                              >
                                                {items.modified_at}
                                              </Moment>
                                            )}
                                            {companyies.id == data.id && "GMT"}
                                            {companyies.id == data.id && (
                                              <Moment format="Z">
                                                {items.modified_at}
                                              </Moment>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                      </Col>
                    </Row>
                  )}
                  <div className="text-right pt-2">
                    {!this.state.show_most_read && (
                      <a
                        className="btnLink-blue pt-1 pb-1 mr-auto"
                        onClick={this.showhidemostread}
                      >
                        More >>
                      </a>
                    )}
                    {this.state.show_most_read && (
                      <a
                        className="btnLink-blue pt-1 pb-1 mr-auto"
                        onClick={this.showhidemostread}
                      >
                        {" "}
                        Less >>
                      </a>
                    )}
                  </div>

                  <hr />

                  <h2 className="news-country invest-wire">INVESTMENT WIRE:</h2>
                  {this.state.giw.map((item, key) => (
                    <div>
                      {item.wire_provider.map((company, key) => (
                        <p>
                          {/* <div>
                          {data.id == company.id ? (
                            // <h2 className="news-country invest-wire">
                            //   INVESTMENT WIRE:
                            // </h2>
                          ) : (
                            ""
                          )}
                     rr    </div> */}
                          <Link to={"/en/wire/" + item.url + "/"}>
                            <h3 className="title-wire">
                              {" "}
                              {data.id == company.id ? item.title : ""}
                            </h3>
                          </Link>

                          <p>
                            {data.id == company.id ? (
                              <Moment format="DD-MMM-YYYY" className="space">
                                {item.modified_at}
                              </Moment>
                            ) : (
                              ""
                            )}
                            {/* {data.id == company.id ? (
                            <Moment format="hh:mm a" className="space">
                              {item.modified_at}
                            </Moment>
                          ) : (
                            ""
                          )}
                          {data.id == company.id ? "GMT" : ""}
                          {data.id == company.id ? (
                            <Moment format="Z">{item.modified_at}</Moment>
                          ) : (
                            ""
                          )} */}
                          </p>
                          {data.id == company.id ? <hr /> : ""}
                        </p>
                      ))}
                    </div>
                  ))}
                </Col>
              </Row>
            </Col>
            <Col className="w-30p p-3">
              <div className="widget">
                <Table striped bordered hover className="tblnoline" size="sm">
                  <tr>
                    <td>Type</td>
                    <td>
                      {this.state.company_type.map((item, key) => (
                        <span>
                          {data.company_legal_type == item.id && item.name}
                        </span>
                      ))}
                    </td>
                  </tr>

                  <tr>
                    <td>Country</td>
                    <td>
                      {" "}
                      <span>{data.country}</span>
                    </td>
                  </tr>

                  <tr>
                    <td>Sector</td>
                    <td>
                      {" "}
                      <span>{data.sector}</span>
                    </td>
                  </tr>

                  <tr>
                    <td>URL</td>
                    <td class="">
                      <a href={data.url} className="breakword" target="blank">
                        <span>{data.url}</span>
                      </a>
                    </td>
                  </tr>
                </Table>
              </div>
              <div className="widget p-3test">
                <Followus />
              </div>
              <div className="widget">
                <Downloadapp />
              </div>
              <div className="widget">
                <SubscriberAccsss />
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default company;
