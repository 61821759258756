import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import "../v&c_dealmonitor/v&c_dealmonitor.css";
import { Row, Table, Col, Container } from "react-bootstrap";
import MA_SideBar from "../../component/global/vc_deals_sidebar/vc_deals_sidebar";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import Pagination from "../../component/global/pagination/index";
import "../../pages/press-release/press-release.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Moment from "react-moment";
import VcGmdSidebar from "../../component/global/vc-deals-gdm/vc-deals-gdm-sidebar";
import Signin from "../../pages/signin/signinIndex";
import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Gdm_Vc_deals_Monitor extends Component {
  constructor(props) {
    super(props);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    items: [],
    show_100: false,
    exampleItems: [],
    company: [],
    page: 0,
    current: 0,
    country: [],
    filter: "",
    filterdata: "",
    old_route: "",
    pageSize: 25,
    count: 0,
    is_first: true,
    token: "",
    response: "",
    user: "",
    count: "",
  };
  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.getData(this.state.old_route, filterdata, 0, true);
  };
  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      // this.getData("", this.state.filter, page, false);
      if (this.state.filterdata !== "") {
        this.getData("", this.state.filterdata, page, false);
      }

      console.log(55);
    }
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    if (this.state.filterdata !== "") {
      //this.getData1(this.state.filterdata);
      this.getData(current_route, this.state.filterdata, 0, false);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  getData = (route, filterdata, page, is_first) => {
    api({
      url:
        "https://api.analyzemarkets.com/gdmvclist/?name=" +
        route +
        "&start=" +
        page +
        filterdata,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Vc deals list");
      this.setState({
        items: res.data.results,
        page: res.data.count,
        current: page,
        is_first: false,
      });
      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
      }
      console.log(res.data.length + "Count Data");
    });

    //     $(document).ready(function() {
    //       $(".grid thead th").click(function() {
    //         showFilterOption(this);
    //       });
    //     });

    //     var arrayMap = {};

    //     function showFilterOption(tdObject) {
    //       var filterGrid = $(tdObject).find(".filter");

    //       if (filterGrid.is(":visible")) {
    //         filterGrid.hide();
    //         return;
    //       }

    //       $(".filter").hide();

    //       var index = 0;
    //       filterGrid.empty();
    //       var allSelected = true;
    //       filterGrid.append(
    //         '<div><input id="all" type="checkbox" checked>Select All</div>'
    //       );

    //       var $rows = $(tdObject)
    //         .parents("table")
    //         .find("tbody tr");

    //       $rows.each(function(ind, ele) {
    //         var currentTd = $(ele).children()[$(tdObject).attr("index")];
    //         var div = document.createElement("div");
    //         div.classList.add("grid-item");
    //         var str = $(ele).is(":visible") ? "checked" : "";
    //         if ($(ele).is(":hidden")) {
    //           allSelected = false;
    //         }
    //         div.innerHTML =
    //           '<input type="checkbox" ' + str + " >" + currentTd.innerHTML;
    //         filterGrid.append(div);
    //         arrayMap[index] = ele;
    //         index++;
    //       });

    //       if (!allSelected) {
    //         filterGrid.find("#all").removeAttr("checked");
    //       }

    //       filterGrid.append(
    //         '<div><input id="close" type="button" value="Close"/><input id="ok" type="button" value="Ok"/></div>'
    //       );
    //       filterGrid.show();

    //       var $closeBtn = filterGrid.find("#close");
    //       var $okBtn = filterGrid.find("#ok");
    //       var $checkElems = filterGrid.find("input[type='checkbox']");
    //       var $gridItems = filterGrid.find(".grid-item");
    //       var $all = filterGrid.find("#all");

    //       $closeBtn.click(function() {
    //         filterGrid.hide();
    //         return false;
    //       });

    //       $okBtn.click(function() {
    //         filterGrid.find(".grid-item").each(function(ind, ele) {
    //           if (
    //             $(ele)
    //               .find("input")
    //               .is(":checked")
    //           ) {
    //             $(arrayMap[ind]).show();
    //           } else {
    //             $(arrayMap[ind]).hide();
    //           }
    //         });
    //         filterGrid.hide();
    //         return false;
    //       });

    //       $checkElems.click(function(event) {
    //         event.stopPropagation();
    //       });

    //       $gridItems.click(function(event) {
    //         var chk = $(this).find("input[type='checkbox']");
    //         $(chk).prop("checked", !$(chk).is(":checked"));
    //       });

    //       $all.change(function() {
    //         var chked = $(this).is(":checked");
    //         filterGrid.find(".grid-item [type='checkbox']").prop("checked", chked);
    //       });

    //       filterGrid.click(function(event) {
    //         event.stopPropagation();
    //       });

    //       return filterGrid;
    //     }

    //     //get Pagination
    //     $(document).ready(function() {
    //       getPagination("#table-id");
    //     });
    //     //getPagination('.table-class');
    //     //getPagination('table');

    //     /*					PAGINATION
    // - on change max rows select options fade out all rows gt option value mx = 5
    // - append pagination list as per numbers of rows / max rows option (20row/5= 4pages )
    // - each pagination li on click -> fade out all tr gt max rows * li num and (5*pagenum 2 = 10 rows)
    // - fade out all tr lt max rows * li num - max rows ((5*pagenum 2 = 10) - 5)
    // - fade in all tr between (maxRows*PageNum) and (maxRows*pageNum)- MaxRows
    // */

    //     function getPagination(table) {
    //       var lastPage = 1;

    //       $("#maxRows")
    //         .on("change", function(evt) {
    //           //$('.paginationprev').html('');						// reset pagination

    //           lastPage = 1;
    //           $(".pagination")
    //             .find("li")
    //             .slice(1, -1)
    //             .remove();
    //           var trnum = 0; // reset tr counter
    //           var maxRows = parseInt($(this).val()); // get Max Rows from select option

    //           if (maxRows == 5000) {
    //             $(".pagination").hide();
    //           } else {
    //             $(".pagination").show();
    //           }

    //           var totalRows = $(table + " tbody tr").length; // numbers of rows
    //           $(table + " tr:gt(0)").each(function() {
    //             // each TR in  table and not the header
    //             trnum++; // Start Counter
    //             if (trnum > maxRows) {
    //               // if tr number gt maxRows

    //               $(this).hide(); // fade it out
    //             }
    //             if (trnum <= maxRows) {
    //               $(this).show();
    //             } // else fade in Important in case if it ..
    //           }); //  was fade out to fade it in
    //           if (totalRows > maxRows) {
    //             // if tr total rows gt max rows option
    //             var pagenum = Math.ceil(totalRows / maxRows); // ceil total(rows/maxrows) to get ..
    //             //	numbers of pages
    //             for (var i = 1; i <= pagenum; ) {
    //               // for each page append pagination li
    //               $(".pagination #prev")
    //                 .before(
    //                   '<li data-page="' +
    //                     i +
    //                     '">\
    //             <span>' +
    //                     i++ +
    //                     '<span class="sr-only">(current)</span></span>\
    //           </li>'
    //                 )
    //                 .show();
    //             } // end for i
    //           } // end if row count > max rows
    //           $('.pagination [data-page="1"]').addClass("active"); // add active class to the first li
    //           $(".pagination li").on("click", function(evt) {
    //             // on click each page
    //             evt.stopImmediatePropagation();
    //             evt.preventDefault();
    //             var pageNum = $(this).attr("data-page"); // get it's number

    //             var maxRows = parseInt($("#maxRows").val()); // get Max Rows from select option

    //             if (pageNum == "prev") {
    //               if (lastPage == 1) {
    //                 return;
    //               }
    //               pageNum = --lastPage;
    //             }
    //             if (pageNum == "next") {
    //               if (lastPage == $(".pagination li").length - 2) {
    //                 return;
    //               }
    //               pageNum = ++lastPage;
    //             }

    //             lastPage = pageNum;
    //             var trIndex = 0; // reset tr counter
    //             $(".pagination li").removeClass("active"); // remove active class from all li
    //             $('.pagination [data-page="' + lastPage + '"]').addClass("active"); // add active class to the clicked
    //             // $(this).addClass('active');					// add active class to the clicked
    //             limitPagging();
    //             $(table + " tr:gt(0)").each(function() {
    //               // each tr in table not the header
    //               trIndex++; // tr index counter
    //               // if tr index gt maxRows*pageNum or lt maxRows*pageNum-maxRows fade if out
    //               if (
    //                 trIndex > maxRows * pageNum ||
    //                 trIndex <= maxRows * pageNum - maxRows
    //               ) {
    //                 $(this).hide();
    //               } else {
    //                 $(this).show();
    //               } //else fade in
    //             }); // end of for each tr in table
    //           }); // end of on click pagination list
    //           limitPagging();
    //         })
    //         .val(25)
    //         .change();

    //       // end of on select change

    //       // END OF PAGINATION
    //     }

    //     function limitPagging() {
    //       // alert($('.pagination li').length)

    //       if ($(".pagination li").length > 7) {
    //         if ($(".pagination li.active").attr("data-page") <= 3) {
    //           $(".pagination li:gt(5)").hide();
    //           $(".pagination li:lt(5)").show();
    //           $('.pagination [data-page="next"]').show();
    //         }
    //         if ($(".pagination li.active").attr("data-page") > 3) {
    //           $(".pagination li:gt(0)").hide();
    //           $('.pagination [data-page="next"]').show();
    //           for (
    //             let i = parseInt($(".pagination li.active").attr("data-page")) - 2;
    //             i <= parseInt($(".pagination li.active").attr("data-page")) + 2;
    //             i++
    //           ) {
    //             $('.pagination [data-page="' + i + '"]').show();
    //           }
    //         }
    //       }
    //     }

    //     $(function() {
    //       // Just to append id number for each row
    //       // $("table tr:eq(0)").prepend("<th> ID </th>");
    //       // var id = 0;
    //       // $("table tr:gt(0)").each(function() {
    //       //   id++;
    //       //   $(this).prepend("<td>" + id + "</td>");
    //       // });
    //     });

    //  Developed By Yasser Mas
    // yasser.mas2@gmail.com
  };

  render() {
    const { data } = this.state;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header />
            <Container>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Navfilter />
              <Row>
                <Col className="w-30p p-4">
                  <div className="widget">
                    <VcGmdSidebar />
                  </div>
                </Col>

                <Col className="w-70p p-4">
                  <div>
                    <div class="container">
                      <div>
                        <Table
                          striped
                          bordered
                          hover
                          id="table-id"
                          className="tblnoline grid"
                          size="sm"
                          responsive="lg"
                        >
                          <thead>
                            <tr>
                              <th index="0">
                                <span className="table-header">
                                  Deal Headline
                                </span>{" "}
                                <div class=""></div>
                              </th>
                              <th index="1" width="150">
                                <span className="table-header">
                                  Deal Code Name
                                </span>

                                <div class=""></div>
                              </th>
                              <th index="2">
                                <span className="table-header">
                                  {" "}
                                  Startup Name
                                </span>

                                <div class=""></div>
                              </th>

                              <th index="4">
                                <span className="table-header">
                                  Startup Incorporated Country
                                </span>

                                <div class=""></div>
                              </th>
                              <th index="4">
                                <span className="table-header">
                                  Funding Round
                                </span>

                                <div class=""></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.items.map((item, key) => (
                              <tr>
                                <td>
                                  {item.api_url != null ? (
                                    <Link
                                      to={
                                        "/en/vc-deals-gmd-details/" +
                                        item.api_url +
                                        "/"
                                      }
                                    >
                                      {item.dealheadline}
                                    </Link>
                                  ) : (
                                    <Link>
                                      <u className="underline">
                                        {item.dealheadline}
                                      </u>
                                    </Link>
                                  )}
                                </td>
                                <td>{item.dealcodename}</td>

                                <td>{item.startupname}</td>
                                <td>{item.startupincorporatedcountry}</td>

                                <td>{item.fundinground}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                      <div className="products-view__options justify-content-between mt-4">
                        {/* {this.state.count > 10 && (
                    <div className="view-options__control">
                      <label>Results Per Page</label>
                      <div></div>
                    </div>
                  )} */}
                        <Pagination
                          items={this.state.exampleItems}
                          onChangePage={this.onChangePage}
                          pageSize={this.state.pageSize}
                        ></Pagination>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default Gdm_Vc_deals_Monitor;
