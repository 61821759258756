import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import "./weekly-info.css";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class WeeklyInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }
  componentDidMount = () => {
    api({
      url: "https://api.analyzemarkets.com/update_info/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, 63333);
      this.setState({
        items: res.data,
      });
    });
  };
  render() {
    return (
      <div className="invesintell">
        <div className="textwidget mb-2">
          <h5>The Week on AnalyzeMarkets Global Deals Database </h5>
        </div>{" "}
        <div className="greyborder2px container-sub">
          {this.state.items.map((item, i) => (
            <div className="fsubs1" key={i}>
              <h5 className="alignLeft">
                <b className="fontWeight">M&A*</b>
              </h5>

              <Row className="border-bottom">
                <Col>
                  <p className="details"> {item.matotaldeals}</p>
                  <p className="fonts-14"> Deals</p>
                </Col>
                <Col>
                  <p className="details"> {item.matotalvalue}</p>
                  <p className="fonts-14"> Value (USD bn)</p>
                </Col>
              </Row>
            </div>
          ))}
          {this.state.items.map((item, i) => (
            <div className="fsubs1" key={i}>
              <h5 className="alignLeft">
                <b className="fontWeight">Venture Capital</b>
              </h5>

              <Row className="border-bottom">
                <Col>
                  <p className="details"> {item.venturecapitaltotaldeals}</p>
                  <p className="fonts-14"> Funding Rounds</p>
                </Col>
                <Col>
                  <p className="details"> {item.venturecapitaltotalvalue}</p>
                  <p className="fonts-14"> Value (USD bn)</p>
                </Col>
              </Row>
            </div>
          ))}
          {this.state.items.map((item, i) => (
            <div className="fsubs1" key={i}>
              <h5 className="alignLeft">
                <b className="fontWeight">Real Estate</b>
              </h5>

              <Row className="border-bottom">
                <Col>
                  <p className="details"> {item.realestatetotaldeals}</p>
                  <p className="fonts-14"> Transactions</p>
                </Col>
                <Col>
                  <p className="details"> {item.realestatetotalvalue}</p>
                  <p className="fonts-14"> Value (USD bn)</p>
                </Col>
              </Row>
            </div>
          ))}
          {this.state.items.map((item, k) => (
            <div className="fsubs1" key={k}>
              <h5 className="alignLeft">
                <b className="fontWeight">Fundraising</b>
              </h5>

              <Row>
                <Col>
                  <p className="details"> {item.fundraisingtotaldeals}</p>
                  <p className="fonts-14"> Funds Closed</p>
                </Col>
                <Col>
                  <p className="details"> {item.fundraisingtotalvalue}</p>
                  <p className="fonts-14">Capital Raised</p>
                  <p className="fonts-14">(USD bn)</p>
                </Col>
              </Row>
              <br></br>
              <p className="border-bottom-data"></p>
              <p className="font14">
                *only announced deals <br></br>
                The week covers the period {item.start_date + " to "}
                {item.end_date}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default WeeklyInfo;
