import React, { Component } from "react";
import logo from "../../../assets/images/logo.svg";
import "./logo.css";

class Logo extends Component {
  render() {
    return (
      <div className="brandlogo">
        <a href="/">
          <img src={logo} className="am-logo1" alt="AnalyzeMarkets" />
        </a>
      </div>
    );
  }
}

export default Logo;
