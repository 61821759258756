import React, { Component, Fragment } from "react";
import MA_SideBar from "../../component/global/M&A_Deals/M&A_SideBar";
import { Container, Row, Table, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import axios from "axios";
import imggraph from "../../../src/assets/images/graph.png";
import { setupCache } from "axios-cache-adapter";
import { Bar } from "react-chartjs-2";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Chart from "react-google-charts";
import Grd_sidebar from "../../component/global/global-research-report-sidebar/report-sidebar";
import Fetch from "../../component/global/CommonComponent/useFetch";

//import zingchart from "../../../src/zingchart.min";
// import CanvasJSReact from "../../../src/canvasjs.react";
// import document from "jquery";
// // import { setupCache } from "axios-cache-adapter";
// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});
const data = [
  ["Region", "Count of M&A deals", { role: "style" }, { role: "annotation" }],
  ["Africa", 244124, "#b87333", "244,124"], // RGB value
  ["Europe", 124666, "silver", " 124,666"], // English color name
  ["Americas", 43632, "gold", "43,632"],
  ["Middle East", 23423, "color: #e5e4e2", "23,423"], // CSS-style declaration
  ["Asia & Pacific", 4646, "color: #a5a5a5", "4,646"], // CSS-style declaration
];
const data2 = [
  ["Region", "Count of M&A deals", { role: "style" }, { role: "annotation" }],
  ["Africa", 244124, "#b87333", "244,124"], // RGB value
  ["Europe", 124666, "silver", " 124,666"], // English color name
  ["Americas", 43632, "gold", "43,632"],
  ["Middle East", 23423, "color: #e5e4e2", "23,423"], // CSS-style declaration
  ["Asia & Pacific", 4646, "color: #a5a5a5", "4,646"], // CSS-style declaration
];

class MA_Deals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: [],
      region: [],
      sector: [],
      regions_name: [],
      Data: {},
      filterdata: "",
      items: [],
      count: 0,
    };
  }
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    if (this.state.filterdata !== "") {
      this.handleData(this.state.filterdata);
    }
  };

  handleData = (filterdata) => {
    axios
      .get("https://api.analyzemarkets.com/globalresearchreport/?" + filterdata)
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ items: res.data.result, count: res.data.count });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };

  componentDidMount = () => {
    if (this.state.filterdata !== "") {
      this.handleData(this.state.filterdata);
    }

    axios
      .get("https://api.analyzemarkets.com/regman/")
      .then((res) => {
        console.log(res.data, 23);
        var array = [];
        var len = res.data.count;
        for (let i = 0; i < len; i++) {
          array.push([
            res.data.results[i].id,
            res.data.results[i].region,
            res.data.results[i].link,
          ]);
        }
        console.log(array, "Region");
        for (let i = 1; i < data.length; i++) {
          data.push();
        }
        this.setState({ regions: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/regioncount/")
      .then((res) => {
        console.log(res.data, 23);
        var array = [];
        var len = res.data.regioncount.length;
        var len1 = res.data.sectorcount.length;
        console.log(len, "length");
        var data3 = [];
        var data4 = [];
        for (let i = 0; i < len; i++) {
          data3 = [
            [
              "Region",
              "Count of Company",
              { role: "style" },
              { role: "annotation" },
            ],
            [
              res.data.regioncount[0].region,
              parseInt(res.data.regioncount[0].MandAcount, 10),
              "gold",
              res.data.regioncount[0].MandAcount,
            ],
            [
              res.data.regioncount[1].region,
              parseInt(res.data.regioncount[1].MandAcount, 10),
              "blue",
              res.data.regioncount[1].MandAcount,
            ],
            [
              res.data.regioncount[2].region,
              parseInt(res.data.regioncount[2].MandAcount, 10),
              "silver",
              res.data.regioncount[2].MandAcount,
            ],
            [
              res.data.regioncount[3].region,
              parseInt(res.data.regioncount[3].MandAcount, 10),
              "#dc3d1f",
              res.data.regioncount[3].MandAcount,
            ],

            [
              res.data.regioncount[4].region,
              parseInt(res.data.regioncount[4].MandAcount, 10),
              "#9c499a",
              res.data.regioncount[4].MandAcount,
            ],
          ];
          console.log(data3, "Data4");
        }
        for (let i = 0; i < len1; i++) {
          data4 = [
            ["Sector", "Count of Company"],
            [
              res.data.sectorcount[3].sector,
              parseInt(res.data.sectorcount[3].MandAcount, 10),
            ],
            [
              res.data.sectorcount[1].sector,
              parseInt(res.data.sectorcount[1].MandAcount, 10),
            ],
            [
              res.data.sectorcount[4].sector,
              parseInt(res.data.sectorcount[4].MandAcount, 10),
            ],

            [
              res.data.sectorcount[0].sector,
              parseInt(res.data.sectorcount[0].MandAcount, 10),
            ],
            [
              res.data.sectorcount[2].sector,
              parseInt(res.data.sectorcount[2].MandAcount, 10),
            ],
          ];
          console.log(data4, "Data4");
        }

        console.log(data2, "Region");

        this.setState({ region: data3 });
        this.setState({ sector: data4 });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };

  render() {
    return (
      <Fragment>
        {console.log(data, "Data")}
        <Header></Header>
        <Container>
          <Navfilter id="sector-desktop" className="d-lg-none"></Navfilter>
          <Fetch applyFilters={this.handleFilters}></Fetch>

          <Row>
            <Col className="w-30p col-sm-3">
              <div className="widget">
                <Grd_sidebar />
              </div>
            </Col>

            <Col className="w-70p">
              <div>
                <div>
                  <h3 className="head-title">
                    <i class="fa fa-check"></i> &nbsp; Total Research Report
                    Deals in our database: {this.state.count}
                  </h3>
                </div>
                {/* <div>
                    <h3 className="head-title">
                      <i class="fa fa-check"></i> &nbsp;Average Daily addition
                      of M&A Deals in our Database: 200{" "}
                    </h3>
                  </div> */}

                <div className="">
                  <Row>
                    {this.state.region.length !== 0 && (
                      <Col>
                        {" "}
                        <div className="">
                          <Chart
                            chartType="ColumnChart"
                            width="auto"
                            height="auto"
                            loader={
                              this.state.region.length === 0 && (
                                <div>Loading Chart</div>
                              )
                            }
                            options={{
                              title: "Deals By Region",
                              bubble: { textStyle: { fontSize: 18 } },
                            }}
                            data={this.state.region}
                          />{" "}
                        </div>
                      </Col>
                    )}
                    {this.state.sector.length !== 0 && (
                      <Col>
                        <Chart
                          width={"auto"}
                          height={"auto"}
                          chartType="PieChart"
                          loader={
                            this.state.sector.length === 0 && (
                              <div>Loading Chart</div>
                            )
                          }
                          data={this.state.sector}
                          options={{
                            title: "Deals By Sector",
                            sliceVisibilityThreshold: 0.01, // 20%
                            bubble: { textStyle: { fontSize: 18 } },
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          {/* <div>
                    <h3 className="head-title">
                      <i class="fa fa-check"></i> &nbsp;Average Daily addition
                      of M&A Deals in our Database: 200{" "}
                    </h3>
                  </div> */}

          {/* <div className="">
                    <Chart
                      chartType="ScatterChart"
                      data={data}
                      options={options}
                      width="80%"
                      height="400px"
                      legendToggle
                    />
                    
                  </div> */}
          {/* <div className="">
                    <Bar
                      data={this.state.Data}
                      options={{ maintainAspectRatio: false }}
                    ></Bar>
                  </div> */}
        </Container>
      </Fragment>
    );
  }
}

export default MA_Deals;
