import Home from "./pages/home/home";
import Signin from "./pages/signin/index";
import SignInIndex from "./pages/signin/signinIndex";
import Newsdetails from "./pages/news-details/news-details";
import CompanyPage from "./pages/company-page/company";
import Pressrelease from "./pages/press-release/press-release";
import Globalii from "./pages/global-investment-intelligence/global-investment-intelligence";
import AfricaR from "./pages/global-investment-intelligence/by-regions/africa-regions";
import AdvancedMar from "./pages/global-investment-intelligence/by-markets/advanced-market";
import Podcasts from "./pages/podcasts";
import Newsletters from "./pages/newsletters";
import Contact from "./pages/contact/index";
import Global_Report_Research from "./pages/global-research-report/global-resesarch-report";
import MaDealsDetails from "./pages/m&a_dealmonitor/m&a_deals_details1";
import App from "./pages/app";
import MA_Deals_Monitor from "./pages/m&a_dealmonitor/m&a_dealmonitor";
import Gmd_Investment_Wire from "./pages/press-release/press-release";
import GiwDetails from "./pages/giw-details";
import Trademarks from "./pages/trademarks";
import PrivacyPolicy from "./pages/privacy-policy";
import Subscribe from "./pages/subscribe";
import Paywall from "./pages/subscribe/paywall";
import Termsuse from "./pages/terms-use";
import Advertise from "./pages/advertise";
import Disclaimer from "./pages/Disclaimer/disclaimer";
import About from "./pages/about-us";
import Un_subscribe from "./pages/unsubscribe/unsubscribe";
import Un_subscribe1 from "./pages/unsubscribe/unsubscribe1";
import Forgotpass from "./pages/forgot-password";
import Careers from "./pages/careers";
import Searchnews from "./pages/search-news";
import Searchpressrelease from "./pages/search-press-release";
import Submitwire from "./pages/submit-wire";
import Otp from "./pages/otp";
import Gmd_Debt_Search from "./pages/madealsearch/gmd-dept-search";
import Cookie from "./pages/cookie";
import ConfirmPassword from "./pages/forgot-password/confirmpassword";
import CompanySeeking from "./pages/Company-Seeking-Targets/Company-Seeking-Targets";
import CompanyDatabase from "./pages/GlobalCompanyDatabase/CompanyDatabase";
import GlobalMarketDatabase from "./pages/global-deals-marketplace/global-deals-market";
import VC_Startup_Seeking_Fund from "./pages/v&c_dealmonitor/v&c_startupseekingfund";
import VC_Startup_Seeking_Buyers from "./pages/v&c_dealmonitor/v&c_startupseekingbuyers";
import GlobalIndex from "./pages/global-investment-intelligence/global-intelligence-index";
import Comingsoon from "./pages/coming-soon";
import Contact_US from "./pages/contact/index";
import DealsInMaking from "./pages/m&a_dealmonitor/deals-in-making";
import DealsInMakingeDetails from "./pages/m&a_dealmonitor/deals-in-making-details1";
import MACompanySeekingTarget from "./pages/m&a_dealmonitor/ma-seeking-targets";
import MACompanySeekingTargetDetails from "./pages/m&a_dealmonitor/ma-seeking-target-details";
import MACompanySeekingBuyersDetails from "./pages/m&a_dealmonitor/ma-seeking-buyers-details";
import MACompanySeekingBuyers from "./pages/m&a_dealmonitor/ma-seeking-buyers";
import MADealSearch from "./pages/madealsearch/MADealSearch";
import DealsInMakingSearch from "./pages/madealsearch/Deals-In-Making-Search";
import MACompanySeekingTargetSearch from "./pages/madealsearch/company-seeking-target-search";
import MACompanySeekingBuyerSearch from "./pages/madealsearch/company-seeking-buyers-search";
import Gcd_Company_Database from "./pages/global-company-database/global-company-monitor";
import Gcd_Details from "./pages/global-company-database/global-company-basic";
import Global_Company_Databse_Search from "./pages/madealsearch/gcd-company-search";
import Create_Deal_monitor from "./pages/create-deal/creal-deal-monitor";
import CreateDealMonitor from "./pages/seeking-targets-gmd/create-deal-monitor-target";
import CreateDeal from "./pages/create-deal/create-deal";
import CreateDealTarget from "./pages/seeking-targets-gmd/create-deal-targets";
import SeekingBuyerSearch from "./pages/madealsearch/seeking-buyer-gmd-search";
import TargetGmdSearch from "./pages/madealsearch/target-search-gmd";
import RealEstateMonitor from "./pages/real-estate-deal/real-estate-monitor";
import RealEstateGmdDetail from "./pages/real-estate-deal/real-estate-deal-detail";
import Gmd_Debt_Monitor from "./pages/debt-deal-gdm/debt-deal-monitor";
import Gmd_Debt_Deal_create from "./pages/debt-deal-gdm/debt-deal-create";
import VC_Deals_Gmd_Monitor from "./pages/Gdm-Vcdeals/vc_deals_gdm_monitor";
import Vc_Deals_Gmd_Create from "./pages/Gdm-Vcdeals/gdm-vc-deals-create";
import VC_Deals_Search from "./pages/madealsearch/gmd-vc-deals-search";
import FundRaising_Monitor from "../src/pages/fund-raising-gdm/fund-raising-monitor";
import FundRaising_Create_Deal from "../src/pages/fund-raising-gdm/fund-raising-create";
import Gmd_fundraiaing_search from "./pages/madealsearch/gmd_fund_search";
import Gmd_JV_Monitor from "./pages/joint-venture/joint-venture-monitor";
import Gmd_JV_Deal from "./pages/joint-venture/joint-venture-createdeal";
import Gmd_JV_Search from "./pages/madealsearch/gmd_jv_search";
import GidSearch from "./pages/madealsearch/gid-search";
import CreateManage from "./pages/seeking-targets-gmd/seeking-target-manange";
import GID from "./pages/global-investor-database/gid";
import GRD from "./pages/global-research-report/grd";
import Edit_Seeking_Buyer_detail from "./pages/create-deal/seeking-buyer-detail";
import Global_Investor_Databse from "./pages/global-investor-database/global-investor-database";
import GlobalProfessionalDatabase from "./pages/gross-people-development/gpd";
import Edit_Real_Estate_View from "./pages/real-estate-deal/real-estate-deal-detail";
import Create_Deal_Edit from "./pages/create-deal/create-deal-edit";
import TargetEdit from "./pages/seeking-targets-gmd/seeking-target-edit";
import RealEstateEdit from "./pages/real-estate-deal/real-estate-edit-deal";
import ManageAlert from "./pages/ManageAlert/manage-alert";
import OnDemand from "./pages/on-demand-research-analysis/on-demand";
import Global_investtor from "./pages/madealsearch/gid-search";
import GdmHome from "./pages/gdm-home";
import GdiHome from "./pages/gdi-home";
import SubscribeGdm from "./pages/subscribe/subscribe-gdm";
import Pdf from './pages/pdf'

export default [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/:lan/vc-deals-gmd-monitor/",
    component: VC_Deals_Gmd_Monitor,
    exact: true,
  },
  {
    path: "/:lan/global-investors-database-search/",
    component: Global_investtor,
    exact: true,
  },

  {
    path: "/:lan/on-demand_research/",
    component: OnDemand,
    exact: true,
  },

  {
    path: "/:lan/manage_alert/",
    component: ManageAlert,
    exact: true,
  },

  {
    path: "/:lan/real-estate-manage-edit/:dealheadline/",
    component: RealEstateEdit,
    exact: true,
  },

  {
    path: "/:lan/create-deal-manage-target-edit/:dealheadline/",
    component: TargetEdit,
    exact: true,
  },

  {
    path: "/:lan/create-deal-manage-edit/:dealheadline/",
    component: Create_Deal_Edit,
    exact: true,
  },

  {
    path: "/:lan/real_estate_detail_view/:dealheadline/",
    component: Edit_Real_Estate_View,
    exact: true,
  },

  {
    path: "/:lan/gross-professional-database/",
    component: GlobalProfessionalDatabase,
    exact: true,
  },

  {
    path: "/:lan/global-investors-monitor/",
    component: Global_Investor_Databse,
    exact: true,
  },

  {
    path: "/:lan/seeking_buyers_details_view/:dealheadline/",
    component: Edit_Seeking_Buyer_detail,
    exact: true,
  },

  {
    path: "/:lan/global_deals_marketplace-manage/",
    component: CreateManage,
    exact: true,
  },

  {
    path: "/:lan/global-investors-database/",
    component: Global_Investor_Databse,
    exact: true,
  },
  {
    path: "/:lan/global-research-report-database/",
    component: Global_Report_Research,
    exact: true,
  },

  {
    path: "/:lan/joint-venture-gmd_search/",
    component: Gmd_JV_Search,
    exact: true,
  },

  {
    path: "/:lan/joint-venture-gmd-create/",
    component: Gmd_JV_Deal,
    exact: true,
  },

  {
    path: "/:lan/joint-venture-gmd-monitor/",
    component: Gmd_JV_Monitor,
    exact: true,
  },

  {
    path: "/:lan/fundraising_gmd_search/",
    component: Gmd_fundraiaing_search,
    exact: true,
  },

  {
    path: "/:lan/fundraising_create_deal/",
    component: FundRaising_Create_Deal,
    exact: true,
  },

  {
    path: "/:lan/fundraising_monitor/",
    component: FundRaising_Monitor,
    exact: true,
  },

  {
    path: "/:lan/vc-deals-gmd-search/",
    component: VC_Deals_Search,
    exact: true,
  },

  {
    path: "/:lan/vc-deals-gmd-create/",
    component: Vc_Deals_Gmd_Create,
    exact: true,
  },

  {
    path: "/:lan/debt-deals-gmd-monitor/",
    component: Gmd_Debt_Monitor,
    exact: true,
  },
  {
    path: "/:lan/debt-deals-gmd-search/",
    component: Gmd_Debt_Search,
    exact: true,
  },

  {
    path: "/:lan/debt-deals-create/",
    component: Gmd_Debt_Deal_create,
    exact: true,
  },

  // {
  //   path: "/:lan/signin/",
  //   component: Signin,
  //   exact: true,
  // },
  // {
  //   path: "/:lan/signIn/",
  //   component: Signin,
  //   exact: true,
  // },

  {
    path: "/:lan/debt-deals-gmd-monitor/",
    component: Gmd_Debt_Monitor,
    exact: true,
  },

  {
    path: "/:lan/seeking-buyers-monitor/",
    component: Create_Deal_monitor,
    exact: true,
  },
  {
    path: "/:lan/realestate-target-details/:dealheadline/",
    component: RealEstateGmdDetail,
    exact: true,
  },

  {
    path: "/:lan/real-estate-monitor/",
    component: RealEstateMonitor,
    exact: true,
  },

  {
    path: "/:lan/target-gmd-search/",
    component: TargetGmdSearch,
    exact: true,
  },

  {
    path: "/:lan/seeking-buyers-search/",
    component: SeekingBuyerSearch,
    exact: true,
  },

  {
    path: "/:lan/marketplace-seeking-buyers/",
    component: CreateDeal,
    exact: true,
  },
  {
    path: "/:lan/create-deal-targets/",
    component: CreateDealTarget,
    exact: true,
  },

  {
    path: "/:lan/deals-target-monitor/",
    component: CreateDealMonitor,
    exact: true,
  },

  {
    path: "/:lan/ma-company-seeking-buyer-search/",
    component: MACompanySeekingBuyerSearch,
    exact: true,
  },
  {
    path: "/:lan/global_company_search/",
    component: Global_Company_Databse_Search,
    exact: true,
  },

  {
    path: "/:lan/global-company-database-details/:name/",
    component: Gcd_Details,
    exact: true,
  },

  {
    path: "/:lan/global-company-database/",
    component: Gcd_Company_Database,
    exact: true,
  },

  {
    path: "/:lan/ma-company-seeking-target-search/",
    component: MACompanySeekingTargetSearch,
    exact: true,
  },

  {
    path: "/:lan/deals-in-making-search/",
    component: DealsInMakingSearch,
    exact: true,
  },

  {
    path: "/:lan/ma-deals-search/",
    component: MADealSearch,
    exact: true,
  },

  {
    path: "/:lan/ma-company-seeking-target/",
    component: MACompanySeekingTarget,
    exact: true,
  },
  {
    path: "/:lan/ma-company-seeking-buyers/",
    component: MACompanySeekingBuyers,
    exact: true,
  },
  {
    path: "/:lan/ma-company-seeking-buyers-detail/:opportunity/",
    component: MACompanySeekingBuyersDetails,
    exact: true,
  },

  {
    path: "/:lan/ma-company-seeking-target-detail/:opportunity/",
    component: MACompanySeekingTargetDetails,
    exact: true,
  },

  {
    path: "/:lan/deals-in-making-detail/:potential_Deal/",
    component: DealsInMakingeDetails,
    exact: true,
  },

  {
    path: "/:lan/deals-in-making/",
    component: DealsInMaking,
    exact: true,
  },

  {
    path: "/:lan/unsubscribe_user/",
    component: Un_subscribe,
    exact: true,
  },
  {
    path: "/:lan/m_a_deals_monitor/",
    component: MA_Deals_Monitor,
    exact: true,
  },
  {
    path: "/:lan/ma-deals-detail/:deal/",
    component: MaDealsDetails,
    exact: true,
  },

  {
    path: "/:lan/wire/:name/",
    component: GiwDetails,
    exact: true,
  },

  {
    path: "/:lan/investment_wire/",
    component: Gmd_Investment_Wire,
    exact: true,
  },
  {
    path: "/pdf/:folder/:file/",
    component: Pdf,
    exact: true,
  },

  {
    path: "/:lan/unsubscribe_users/",
    component: Un_subscribe1,
    exact: true,
  },

  {
    path: "/:lan/intelligence/:name",
    component: Newsdetails,
    exact: true,
  },

  // {
  //   path: "/:lan/global-investment-wire",
  //   component: Pressrelease,
  //   exact: true,
  // }

  {
    path: "/:lan/intelligence",
    component: Globalii,
    exact: true,
  },
  {
    path: "/:lan/intelligence_index",
    component: GlobalIndex,
    exact: true,
  },

  {
    path: "/:lan/intelligence/region/:region",
    component: AfricaR,
    exact: true,
  },
  {
    path: "/:lan/intelligence/markets/:markets",
    component: AdvancedMar,
    exact: true,
  },
  // {
  //   path: "/:lan/podcasts",
  //   component: Podcasts,
  //   exact: true,
  // },
  {
    path: "/:lan/newsletters",
    component: Newsletters,
    exact: true,
  },
  {
    path: "/:lan/app",
    component: App,
    exact: true,
  },

  {
    path: "/:lan/contact_us",
    component: Contact_US,
    exact: true,
  },
  {
    path: "/:lan/subscribe",
    component: Subscribe,
    exact: true,
  },
  {
    path: "/:lan/subscribe_us",
    component: Subscribe,
    exact: true,
  },
  {
    path: "/:lan/paywall",
    component: Paywall,
    exact: true,
  },
  {
    path: "/:lan/subscribe-gdm",
    component: SubscribeGdm,
    exact: true,
  },
  {
    path: "/:lan/gdm-home",
    component: GdmHome,
    exact: true,
  },
  {
    path: "/:lan/gdi-home",
    component: GdiHome,
    exact: true,
  },

  {
    path: "/:lan/trademarks",
    component: Trademarks,
    exact: true,
  },
  {
    path: "/:lan/privacy-policy",
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: "/:lan/terms-of-use",
    component: Termsuse,
    exact: true,
  },
  {
    path: "/:lan/disclaimer",
    component: Disclaimer,
    exact: true,
  },
  {
    path: "/:lan/advertise",
    component: Advertise,
    exact: true,
  },
  {
    path: "/:lan/about-us",
    component: About,
    exact: true,
  },
  {
    path: "/:lan/careers",
    component: Careers,
    exact: true,
  },

  {
    path: "/:lan/forgot-password",
    component: Forgotpass,
    exact: true,
  },
  {
    path: "/:lan/search-news/:search/:type/",
    component: Searchnews,
    exact: true,
  },
  {
    path: "/:lan/search-press-release/:search/:type/",
    component: Searchpressrelease,
    exact: true,
  },
  {
    path: "/:lan/submit-wire",
    component: Submitwire,
    exact: true,
  },
  {
    path: "/otp",
    component: Otp,
    exact: true,
  },
  {
    path: "/:lan/coming-soon",
    component: Comingsoon,
    exact: true,
  },
  {
    path: "/:lan/company/:name",
    component: CompanyPage,
    exact: true,
  },

  {
    path: "/:lan/CompanySeeking",
    component: CompanySeeking,
    exact: true,
  },
  {
    path: "/:lan/CompanyDatabase",
    component: CompanyDatabase,
    exact: true,
  },
  {
    path: "/:lan/GlobalMarketDatabase",
    component: GlobalMarketDatabase,
    exact: true,
  },

  {
    path: "/:lan/vc-startup-seeking-fund",
    component: VC_Startup_Seeking_Fund,
    exact: true,
  },

  {
    path: "/:lan/vc-startup-seeking-buyers",
    component: VC_Startup_Seeking_Buyers,
    exact: true,
  },
  {
    path: "/:lan/cookie",
    component: Cookie,
    exact: true,
  },
  {
    path: "/:lan/confirm_password",
    component: ConfirmPassword,
    exact: true,
  },
];
