import React, { Component, Fragment } from "react";
import { Row, Table, Col, Container } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import "./on-demand.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import Autocomplete from "react-autocomplete";
import axios, { post } from "axios";
import "../create-deal/create-deal.css";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import Signin from "../../pages/signin/signinIndex";

import {
  matchSubIndustry,
  matchCountry,
  matchSector,
  matchCompany,
  matchStatus,
  matchDeal,
  matchDealHeadline,
  matchRegion,
  matchGeography,
} from "../../component/global/investment-intellegence-screener/data";
class OnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: [],
      country: [],
      country_name: "",
      result: false,
      geography_name: "",
      briefdescriptionproject: "",
      projecttimelinedays: "",
      file: "",
      name: "",
      email: "",
      contactnumber: "",
      user: "",
      token: "",
      posted_by: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);

    this.handleState = this.handleState.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
  }
  componentDidMount() {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });

    axios
      .get("https://api.analyzemarkets.com/regman/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ region: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  }
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleState = () => {
    this.setState({
      result: false,
    });
  };
  fileUpload(file) {
    const url = "https://www.analyzemarkets.com/";
    const formData = new FormData();
    formData.append("file[]", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return post(url, formData, config);
  }

  handleChange(event) {
    this.setState({ file: event.target.files });

    // const value = event.target.value;
    // const name = event.target.name;
    // const id = event.target.id;
    // const formData = new FormData();

    // const len = event.target.files.length;
    // const fileData = event.target.files;
    // const blob = new Blob([fileData]);
    // const reader = new FileReader();
    // var data_Array;
    // for (let i = 0; i < fileData.length; i++) {
    //   this.setState({
    //     [name]: fileData[i],
    //   });
    //   //console.log(fileData[i]);
    // }
    // console.log(formData.getAll("file"));
    // reader.readAsDataURL(blob);
  }
  // this.setState({
  //   [name]: event.target.files[0],
  //   file_teaser: event.target.files.name,
  //   file_other: event.target.files.name,
  // });

  //.log("Selected file:", event.target.files[0]);

  onSubmit(e) {
    e.preventDefault();
    const { dealheadline, briefdescriptionproject } = this.state;
    this.fileUpload(this.state.file).then((response) => {
      console.log(response.data);
    });

    var bodyFormData = new FormData();
    bodyFormData.set("briefdescriptionproject", briefdescriptionproject);
    bodyFormData.set("projectgeography", this.state.geography_name);
    bodyFormData.set("projectcountry", this.state.country_name);
    bodyFormData.set("projecttimelinedays", this.state.projecttimelinedays);
    for (const files of this.state.file) {
      bodyFormData.append("pleaseuploadprojectdocument", files);
      console.log(files);
    }

    // bodyFormData.set("pleaseuploadprojectdocument", this.state.file);
    bodyFormData.set("name", this.state.name_info);
    bodyFormData.set("title", this.state.title);
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("contactnumber", this.state.contactnumber);
    bodyFormData.set("posted_by", this.state.user);

    axios
      .post("https://api.analyzemarkets.com/ondemandresearch/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          console.log(this.state.result, "result");
          if (res.data === "Successfully Submitted. Thank you.") {
            this.setState({
              result: true,
            });
          }
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }
  refreshButton = () => {
    this.setState({
      country_name: "",
      geography_name: "",
      briefdescriptionproject: "",
      projecttimelinedays: "",
      file: "",
      name: "",
      email: "",
      contactnumber: "",
      posted_by: "",
    });
  };

  render() {
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>

            <Container>
              <Navfilter />
              <div className="w-70p">
                <Row>
                  <Col>
                    <hr />
                    <h6 className="text-uppercase mb-4">
                      <b>
                        Our qualified and experienced team can assist in your
                        research and analysis work.<br></br> Please provide the
                        following information related to the work:
                      </b>{" "}
                    </h6>
                  </Col>
                </Row>

                <Form className="form-style-6" onSubmit={this.onSubmit}>
                  <div className="iis greyborder2px">
                    <h4 className="text-left">
                      On Demand Reasearch & Analysis
                    </h4>
                    <div className="pdg15">
                      <fieldset>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control
                            as="textarea"
                            rows="9"
                            name="briefdescriptionproject"
                            required
                            placeholder="Brief description of your project"
                            onChange={this.changeTerm}
                          />
                        </Form.Group>

                        <Row className="form-group">
                          <Col>
                            {" "}
                            <div className="form-group autoc">
                              <Autocomplete
                                required
                                value={this.state.geography_name}
                                inputProps={{
                                  id: "country-autocomplete",
                                  placeholder: "Project Geography",
                                }}
                                wrapperStyle={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                                items={this.state.region}
                                getItemValue={(item) => item.region}
                                shouldItemRender={matchRegion}
                                onChange={(event, geography_name) =>
                                  this.setState({ geography_name })
                                }
                                onSelect={(geography_name) =>
                                  this.setState({ geography_name })
                                }
                                renderMenu={(children) => (
                                  <div className="menu c-dropdown">
                                    {children}
                                  </div>
                                )}
                                renderItem={(item, isHighlighted) => (
                                  <div
                                    className={`item ${
                                      isHighlighted ? "item-highlighted" : ""
                                    }`}
                                    key={item.region}
                                  >
                                    {item.region}
                                  </div>
                                )}
                              />
                            </div>
                          </Col>

                          <Col>
                            <div className="form-group autoc">
                              <Autocomplete
                                required
                                value={this.state.country_name}
                                inputProps={{
                                  id: "deal-autocomplete",
                                  placeholder: "Project Country",
                                }}
                                wrapperStyle={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                                items={this.state.country}
                                getItemValue={(item) => item.country}
                                onChange={(event, country_name) =>
                                  this.setState({ country_name })
                                }
                                shouldItemRender={matchCountry}
                                onSelect={(country_name) =>
                                  this.setState({ country_name })
                                }
                                renderMenu={(children) => (
                                  <div className="menu c-dropdown">
                                    {children}
                                  </div>
                                )}
                                renderItem={(item, isHighlighted) => (
                                  <div
                                    className={`item ${
                                      isHighlighted ? "item-highlighted" : ""
                                    }`}
                                    key={item.country}
                                  >
                                    {item.country}
                                  </div>
                                )}
                              />
                            </div>
                          </Col>

                          <Col>
                            <Form.Control
                              name="projecttimelinedays"
                              type="text"
                              required
                              placeholder="Project Timeline (Days)"
                              onChange={this.changeTerm}
                            />
                          </Col>
                        </Row>

                        <div className="form-group">
                          <label>
                            {" "}
                            Please upload project related document{" "}
                          </label>

                          <Row>
                            <Col>
                              <input
                                type="file"
                                id="myfile"
                                name="file"
                                multiple
                                onChange={this.handleChange}
                              />{" "}
                            </Col>
                            {console.log(this.state.file, "Type of Audit")}
                          </Row>
                        </div>

                        <label>
                          Your Contact Information for our team to revert back
                        </label>
                        <Row>
                          <Col>
                            <Form.Control
                              name="name_info"
                              required
                              type="text"
                              placeholder="Name"
                              onChange={this.changeTerm}
                            />
                          </Col>

                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Control
                              type="email"
                              required
                              placeholder="Email"
                              name="email"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                              onChange={this.changeTerm}
                            />
                          </Form.Group>

                          <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Control
                              type="tel"
                              name="contactnumber"
                              required
                              placeholder="Contact Number"
                              //pattern="^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{10}\s*,?$"
                              onChange={this.changeTerm}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Col sm={2}>
                            <Button
                              variant="btn btn-blue  mt-4 mb-4 shadow"
                              type="submit"
                              size="sm"
                            >
                              Submit
                            </Button>
                          </Col>
                          <Col sm={2}>
                            <Link
                              className="btn btn-blue mt-4 mb-4 shadow"
                              onClick={this.refreshButton}
                            >
                              Clear
                            </Link>
                          </Col>
                        </Row>
                        {this.state.result && (
                          <div className="borderColors1">
                            <span
                              className="buttonFloat"
                              onClick={this.handleState}
                            >
                              <i className="fa fa-times-circle"></i>
                            </span>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                style={{ color: "green" }}
                              ></i>
                            </span>
                            &nbsp;
                            <span>
                              <h4>
                                {" "}
                                <b className="colorError">
                                  Successfully Submitted. Thank you.
                                </b>
                              </h4>
                              <br></br>
                            </span>
                          </div>
                        )}
                      </fieldset>
                    </div>
                  </div>
                </Form>
              </div>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default OnDemand;
