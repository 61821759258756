import React, { Component, Fragment } from "react";
import Followus from "../../../../component/global/followus/followus";
import Downloadapp from "../../../../component/global/download-app/downloadapp";
import Newspost from "../../../../component/global/news-post/index";
import Offerwidget from "../../../../component/global/offers-widget/offers-widget";
import Freesubs from "../../../../component/global/free-subscription/subscription";
import { Container, Row, Col } from "react-bootstrap/";
import Bnrwidget from "../../../../component/global/banner-widget";
import axios from "axios";
import Pagination from "../../../../component/global/pagination/index";
import Iis from "../../../../component/global/investment-intellegence-screener/investment-Intelligence-screener";
import { Helmet } from "react-helmet";
import Header from "../../../../component/global/header/index";

class AdvancedMar extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    item: [],
    section_one: [],
    filter: "",
    section_two: [],
    section_three: [],
    news: {},
  };

  onChangePage = (pageOfItems, page) => {
    console.log(this.props.match.params.markets);
    var current_route = this.props.match.params.markets;

    console.log(pageOfItems, page);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.markets;
    if (page !== this.state.current) {
      this.getData(current_route, this.state.filter, page, false);
      console.log(55);
    }
  };

  handleFilter = (filter) => {
    console.log(filter, 31);
    this.setState({
      filter: filter,
      is_filter: true,
    });

    this.getData(this.state.old_route, filter, this.state.page, true);
  };
  componentDidMount = () => {
    console.log(this.props.match.params.markets);
    var current_route = this.props.match.params.markets;
    if (current_route === undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    this.getData(current_route, "", 0, true);

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  componentWillReceiveProps = (nextProps) => {
    var current_route = nextProps.match.params.markets;
    if (current_route === undefined) {
      current_route = "";
    }
    console.log(nextProps.match.params.markets, 22);
    if (this.state.old_route === "") {
      this.setState({
        old_route: current_route,
      });
      this.getData(current_route, "", 0, true);
    } else {
      if (current_route !== this.state.old_route) {
        this.setState({
          old_route: current_route,
        });

        this.getData(current_route, "", 0, true);
      }
    }
  };

  getData = (route, filter, page, is_first) => {
    axios
      .get(
        "https://api.analyzemarkets.com/giim_mgmt_market_region/?by_markets=" +
          route +
          "&start=" +
          page +
          "" +
          filter,
        {}
      )
      .then((res) => {
        console.log(res.data);
        this.setState({
          section_one: res.data.section_one,
          section_two: res.data.section_two,
          section_three: res.data.section_three,
          page: res.data.count,
          current: page,
          is_first: false,
        });

        if (is_first) {
          this.setState({
            exampleItems: [...Array(res.data.count).keys()].map((i) => ({
              id: i + 1,
              name: "Item " + (i + 1),
            })),
          });
        }
      })
      .catch((error) => {
        //on error
        // alert("There is an error in API call.");
      });

    console.log(route, 89, this.state.old_page);
    axios
      .get("https://api.analyzemarkets.com/bymarket/" + route, {})
      .then((res) => {
        console.log(res.data);
        this.setState({
          name: res.data.name,
          id: res.data.id,
        });
      })
      .catch((error) => {
        //on error
        // alert("There is an error in API call.");
      });
  };

  render() {
    const name =
      this.state.name +
      " Markets Global Investment Intelligence | AnalyzeMarkets";
    const description =
      "Current and historical " +
      this.state.name +
      " investment intelligence covering M&A, real estate, venture capital, joint venture, private equity, fundraising, IPOs, fixed income, and SWF transactions";

    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>{name}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content="{name}" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta name="og:description" content={description} />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="{name}" />

            <meta itemprop="description" content={description} />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="{name}" />

            <meta name="twitter:description" content={description} />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            <Col className="w-70p">
              <hr />
              <h2 className="text-uppercase mb-4">
                Global Investment Intelligence: {this.state.name}{" "}
              </h2>
            </Col>
          </Row>
          <Row>
            {!this.state.is_first && (
              <Fragment>
                <Col className="w-70p">
                  <Row>
                    {this.state.section_one.map((item, index) => (
                      <Col md="4">
                        <Newspost key={index} item={item} />
                      </Col>
                    ))}
                  </Row>
                  {!this.state.is_filter && (
                    <Row>
                      <Col md={12}>
                        <Bnrwidget />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    {this.state.section_two.map((item, index) => (
                      <Col md="4">
                        <Newspost key={index} item={item} />
                      </Col>
                    ))}
                  </Row>
                  {!this.state.is_filter && (
                    <Row>
                      <Col md={12}>
                        <Bnrwidget />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    {this.state.section_three.map((item, index) => (
                      <Col md="4">
                        <Newspost key={index} item={item} />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <Col md="12" className="text-center">
                      <Pagination
                        items={this.state.exampleItems}
                        onChangePage={this.onChangePage}
                      ></Pagination>
                    </Col>
                  </Row>
                </Col>
                <Col className="w-30p">
                  <div className="widget">
                    <Iis
                      applyFilter={this.handleFilter}
                      byMarkets={this.state.id}
                      byRegion={this.state.by_region}
                    />
                  </div>
                  <div className="widget">
                    <Freesubs />
                  </div>
                  <div className="widget">
                    <Followus />
                  </div>
                  <div className="widget">
                    <Downloadapp />
                  </div>
                </Col>
              </Fragment>
            )}

            {this.state.is_first && (
              <Fragment>
                <Col className="w-70p">
                  <Row>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                          </div>
                          <div class="ph-picture"></div>
                          <div class="ph-row bdrph">
                            <div class="ph-col-4"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-6"></div>
                            <div class="ph-col-6 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-6 big"></div>
                            <div class="ph-col-8 empty"></div>
                            <div class="ph-col-4 big"></div>
                            <div class="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Fragment>
            )}
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default AdvancedMar;
