import React, {Component} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap/';
import {Helmet} from "react-helmet";

class Otp extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }
    render()
    {
        return <Container>
            <Helmet>
                <title>AnalyzeMarkets - Global Investment Intelligence Platform</title>
                <meta property="og:title" content="AnalyzeMarkets - Global Investment Intelligence Platform" />
                <meta property="og:image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
                <meta name="og:description" content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."/>
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website"/>

                <meta name="image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
                <meta itemprop="name" content="AnalyzeMarkets - Global Investment Intelligence Platform" />

                <meta itemprop="description" content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database." />
                <meta itemprop="image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />

                <meta name="twitter:card" content="summary_large_image" />

                <meta name="twitter:title" content="AnalyzeMarkets - Global Investment Intelligence Platform" />

                <meta name="twitter:description" content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database." />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta name="twitter:image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
            </Helmet>
            <Row className="justify-content-center">
                <Col xl={7} md={6}>
                    <div className="registration-form-container">
                        <div className="registration-form">
                            <h2 className="pb-4">Validate OTP</h2>
                            <h3 className="text-center pb-3">Please enter the OTP below.</h3>
                            <form>
                                <Form.Group controlId="formBasicOtp">
                                    <Form.Control type="number" placeholder="Enter OTP"/>
                                </Form.Group>
                                
                                <Button variant="btn btn-blue w-100 mt-4 mb-4 shadow" type="submit" size="lg">
                                    Validate OTP
                                </Button>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>;
    }
}

export default Otp;
