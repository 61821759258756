import React, { Component, Fragment } from "react";
import { Container, Row, Table, Col } from "react-bootstrap";
import "./globaldeals-database.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import TimeAgo from "react-timeago";
import NavFilterLogin from "../../component/global/DealsDatabase-NavFilter/NavFilter";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});
class GlobalDealsDatabase extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    items: [],
    show_100: false,
    counts: 0,
  };

  componentDidMount = () => {
    api({
      url: "https://api.analyzemarkets.com/gdd_m_and_a/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data.length, "length");
      this.setState({
        items: res.data,
        show_100: true,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();
      const counts = res.data.length;
      console.log("Cache count length:", counts);
    });
  };
  render() {
    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Row>
            <NavFilterLogin />
            <Col className="w-100p">
              <Row className="show-grid">
                <Col md={4}>
                  <div className="borderdesign">
                    <b>Latest M & A Deals</b>
                  </div>
                  {this.state.items.map((item, key) => (
                    <div className="borderdesignbody">
                      <p>Acquirer:{item.deal}</p>
                      <p>Target:{item.reference}</p>
                      <p>
                        <a href="#">View Details</a>
                      </p>
                      <p>
                        Posted By:{" "}
                        <TimeAgo date={item.created_at} maxPeriod="1" />
                      </p>
                    </div>
                  ))}
                </Col>

                <Col md={4}>
                  <div className="borderdesign">
                    <b>Latest VC Deals</b>
                  </div>
                  {this.state.items.map((item, key) => (
                    <div className="borderdesignbody">
                      <p>Acquirer:{item.deal}</p>
                      <p>Target:{item.reference}</p>
                      <p>
                        <a href="#">View Details</a>
                      </p>
                      <p>
                        Posted By:{" "}
                        <TimeAgo date={item.created_at} maxPeriod="1" />
                      </p>
                    </div>
                  ))}
                </Col>

                <Col md={4}>
                  <div className="borderdesign">
                    <b>Latest Real Estate Deals</b>
                  </div>
                  {this.state.items.map((item, key) => (
                    <div className="borderdesignbody">
                      <p>Acquirer:{item.deal}</p>
                      <p>Target:{item.reference}</p>
                      <p>
                        <a href="#">View Details</a>
                      </p>
                      <p>
                        Posted By:{" "}
                        <TimeAgo date={item.created_at} maxPeriod="1" />
                      </p>
                    </div>
                  ))}
                </Col>
              </Row>

              <br></br>

              <Row className="show-grid ">
                <Col md={4}>
                  <div className="borderdesign">
                    <b>Latest Debt Deals</b>
                  </div>
                  {this.state.items.map((item, key) => (
                    <div className="borderdesignbody">
                      <p>Acquirer:{item.deal}</p>
                      <p>Target:{item.reference}</p>
                      <p>
                        <a href="#">View Details</a>
                      </p>
                      <p>
                        Posted By:{" "}
                        <TimeAgo date={item.created_at} maxPeriod="1" />
                      </p>
                    </div>
                  ))}
                </Col>

                <Col md={4}>
                  <div className="borderdesign">
                    <b>Latest JV Deals</b>
                  </div>
                  {this.state.items.map((item, key) => (
                    <div className="borderdesignbody">
                      <p>Acquirer:{item.deal}</p>
                      <p>Target:{item.reference}</p>
                      <p>
                        <a href="#">View Details</a>
                      </p>
                      <p>
                        Posted By:{" "}
                        <TimeAgo date={item.created_at} maxPeriod="1" />
                      </p>
                    </div>
                  ))}
                </Col>

                <Col md={4}>
                  <div className="borderdesign">
                    <b>Latest IPO Deals</b>
                  </div>
                  {this.state.items.map((item, key) => (
                    <div className="borderdesignbody">
                      <p>Acquirer:{item.deal}</p>
                      <p>Target:{item.reference}</p>
                      <p>
                        <a href="#">View Details</a>
                      </p>
                      <p>
                        Posted By:{" "}
                        <TimeAgo date={item.created_at} maxPeriod="1" />
                      </p>
                    </div>
                  ))}
                </Col>
              </Row>
              <br />
              <Row className="show-grid">
                <Col md={4}>
                  <div className="borderdesign">
                    <b>Latest Fundraising Deals</b>
                  </div>
                  {this.state.items.map((item, key) => (
                    <div className="borderdesignbody">
                      <p>Acquirer:{item.deal}</p>
                      <p>Target:{item.reference}</p>
                      <p>
                        <a href="#">View Details</a>
                      </p>
                      <p>
                        Posted By:{" "}
                        <TimeAgo date={item.created_at} maxPeriod="1" />
                      </p>
                    </div>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default GlobalDealsDatabase;
