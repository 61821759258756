import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "./m&adealmonitor.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import Linkify from "react-linkify";
import Signin from "../../pages/signin/signinIndex";
import { Helmet } from "react-helmet";
import Fetch from "../../component/global/CommonComponent/useFetch";
import Login from "../../component/global/CommonComponent/passwordhash";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class Ma_details1 extends Component {
  constructor(props) {
    super(props);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    details: [],
    acquirer: [],
    target: [],
    is_first: true,
    filterdata: "",
    DealValuation: [],
    action: "",
    email: "",
    response: "",
    advisors: [],
    news: [],
  };

  componentDidMount = () => {
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/gddmadeals-single/" +
        this.props.match.params.deal +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "m&a single");
      this.setState({
        details: res.data,
        acquirer: res.data.GDDMandADealsAcquirer,
        target: res.data.GDDMandADealsTarget,
        DealValuation: res.data.GDDMandADealsValidation,
        advisors: res.data.GDDMADealsAdvisors,
        news: res.data.GDDMADealsNews,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const data = this.state.details;
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Fetch applyFilters={this.handleFilters}></Fetch>

            <Navfilter id="sector-desktop"></Navfilter>

            <Container>
              <Helmet title={data.deal}>
                <meta
                  name="description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />
                <meta property="og:title" content={data.deal} />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta name="og:description" content={data.deal_description} />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta itemprop="description" content={data.description} />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>

              <Button
                onClick={this.handleBack}
                variant="outline-light"
                className="MarginRight padDown"
              >
                <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
              </Button>

              <Row>
                <Col className="">
                  <Row className="justify-content-md-center">
                    <Col xs sm={12} class="">
                      <Row>
                        <Col sm={5}>
                          {" "}
                          <div class=" hero" id="2">
                            <h3 className="UpperCase">Acquirer</h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td width="200">Acquirer Company</td>
                                  <td>
                                    {" "}
                                    {this.state.acquirer.map((item, index) => (
                                      <div className="paddingRight">
                                        {item.company_name.map(
                                          (acquirer, key) => (
                                            <div>
                                              {acquirer.api_url != null ? (
                                                <Link
                                                  to={
                                                    "/en/global-company-database-details/" +
                                                    acquirer.api_url +
                                                    "/"
                                                  }
                                                >
                                                  <u className="underline1">
                                                    {acquirer.name}
                                                  </u>
                                                </Link>
                                              ) : (
                                                <Link>
                                                  <u className="underline1">
                                                    {acquirer.name}
                                                  </u>
                                                </Link>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ))}
                                  </td>
                                </tr>
                                <tr>
                                  <td width="200">Acquirer Parent</td>
                                  <td>
                                    {this.state.acquirer.map((item, index) => (
                                      <div className="paddingRight">
                                        {item.acquirer_parent.map(
                                          (acquirer_par, key) => (
                                            <div>
                                              {acquirer_par.api_url != null ? (
                                                <Link
                                                  to={
                                                    "/en/global-company-database-details/" +
                                                    acquirer_par.api_url +
                                                    "/"
                                                  }
                                                >
                                                  <u className="underline1">
                                                    {acquirer_par.name}
                                                  </u>
                                                </Link>
                                              ) : (
                                                <Link>
                                                  <u className="underline1">
                                                    {acquirer_par.name}
                                                  </u>
                                                </Link>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ))}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <br></br>
                          <div class="" id="3">
                            <h3 className="UpperCase">Target</h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td width="200">Target Company</td>
                                  <td>
                                    {this.state.target.map((item, index) => (
                                      <div className="paddingRight">
                                        {item.company_name.map(
                                          (target, key) => (
                                            <div>
                                              {target.api_url != null ? (
                                                <Link
                                                  to={
                                                    "/en/global-company-database-details/" +
                                                    target.api_url +
                                                    "/"
                                                  }
                                                >
                                                  <u className="underline1">
                                                    {target.name}
                                                  </u>
                                                </Link>
                                              ) : (
                                                <Link>
                                                  <u className="underline1">
                                                    {target.name}
                                                  </u>
                                                </Link>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ))}
                                    {/* 
                                {this.state.target.map((targets, index) => (
                                  <span className="paddingRight">
                                    {targets.company_name}
                                  </span>
                                ))}
                                */}
                                  </td>
                                </tr>

                                <tr>
                                  <td width="200">Seller</td>
                                  <td>
                                    {this.state.target.map((item, index) => (
                                      <div className="paddingRight">
                                        {item.seller.map((target_sell, key) => (
                                          <div>
                                            {target_sell.api_url != null ? (
                                              <Link
                                                to={
                                                  "/en/global-company-database-details/" +
                                                  target_sell.api_url +
                                                  "/"
                                                }
                                              >
                                                <u className="underline1">
                                                  {target_sell.name}
                                                </u>
                                              </Link>
                                            ) : (
                                              <Link>
                                                <u className="underline1">
                                                  {target_sell.name}
                                                </u>
                                              </Link>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                    {/* 
                                {this.state.target.map((targets, index) => (
                                  <span className="paddingRight">
                                    {targets.company_name}
                                  </span>
                                ))}
                                */}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <br></br>
                          <div class="" id="4">
                            <h3 className="UpperCase">Deal Valuation</h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                {this.state.DealValuation.map(
                                  (valuation, index) => (
                                    <tr>
                                      <td width="200">{valuation.title} </td>
                                      <td className="word-wrap">
                                        {valuation.value}{" "}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                          <br></br>
                          <div class="" id="5">
                            <h3 className="UpperCase">Advisors</h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                {this.state.advisors.map((advisor, index) => (
                                  <tr>
                                    <td width="250">
                                      <div>{advisor.title}</div>
                                    </td>
                                    <td>
                                      <div className="paddingRight">
                                        {advisor.companyname.map((adv, key) => (
                                          <div>
                                            {adv.api_url != null ? (
                                              <Link
                                                to={
                                                  "/en/global-company-database-details/" +
                                                  adv.api_url +
                                                  "/"
                                                }
                                              >
                                                <u className="underline1">
                                                  {adv.name}
                                                </u>
                                              </Link>
                                            ) : (
                                              <Link>
                                                <u className="underline1">
                                                  {adv.name}
                                                </u>
                                              </Link>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <br></br>
                          <div class="" id="6">
                            <h3 className="UpperCase">News</h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                {this.state.news.map((item, index) => (
                                  <tr>
                                    <td width="250" className="space">
                                      <div>{item.news}</div>
                                    </td>
                                    <Linkify
                                      componentDecorator={componentDecorator}
                                    >
                                      <td className="word-wrap">
                                        <div>{item.value + " "}</div>
                                      </td>
                                    </Linkify>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col sm={7}>
                          <div class=" hero" id="1">
                            <h3 className="UpperCase">Deal Information</h3>
                            <Table
                              className="tblnoline"
                              size="lg"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td width="150">Deal</td>

                                  <td>{data.deal}</td>
                                </tr>

                                <tr>
                                  <td>Deal Description</td>
                                  <td>
                                    <p
                                      className=""
                                      dangerouslySetInnerHTML={{
                                        __html: data.deal_description,
                                      }}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td width="150" className="space">
                                    Deal Official Announcement
                                  </td>

                                  <td>{data.deal_official_announement}</td>
                                </tr>

                                <tr>
                                  <td>Deal Announced Date</td>

                                  <td>
                                    <Moment format="DD MMM YYYY">
                                      {data.announced_reported_date}
                                    </Moment>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Deal Type</td>

                                  <td>{data.deal_type}</td>
                                </tr>

                                <tr>
                                  <td>% Of Target Acquired</td>
                                  <td>{data.target_acquired}</td>
                                </tr>

                                <tr>
                                  <td>Deal Value</td>

                                  <td>{data.deal_value}</td>
                                </tr>

                                <tr>
                                  <td>Deal Status</td>

                                  <td>{data.deal_status}</td>
                                </tr>

                                <tr>
                                  <td>Deal Completion Date</td>

                                  <td>{data.completion_date}</td>
                                </tr>

                                <tr>
                                  <td>Deal Nature</td>

                                  <td>{data.deal_nature}</td>
                                </tr>

                                <tr>
                                  <td>Deal Geography</td>
                                  <td>{data.deal_geography}</td>
                                </tr>
                                <tr>
                                  <td>Deal Payment Mode</td>
                                  <td>{data.deal_payment_mode}</td>
                                </tr>
                                <tr>
                                  <td>Deal Funding Mode</td>
                                  <td>{data.deal_funding_mode}</td>
                                </tr>
                                <Linkify
                                  componentDecorator={componentDecorator}
                                >
                                  <tr>
                                    <td>Reference</td>
                                    <td className="word-wrap">
                                      {data.reference}
                                    </td>
                                  </tr>
                                </Linkify>
                                <tr>
                                  <td>Deal Reference Number</td>
                                  <td>{data.dealinternalreferencenumber}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>

                          {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default Ma_details1;
