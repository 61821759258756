import React, { Component, Fragment } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tab, Nav } from "react-bootstrap";
import "./v&c_dealmonitor.css";
import Markdown from "react-markdown";
import $ from "jquery";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Navfilter from "../../component/global/NavFilterAfterLogin/navfilterafterLogin";
import Linkify from "react-linkify";
import Signin from "../../pages/signin/signinIndex";
import { Helmet } from "react-helmet";
import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

class Startup_Seeking_Fund extends Component {
  constructor(props) {
    super(props);
    this.handleFilters = this.handleFilters.bind(this);
  }
  state = {
    details: [],
    investor_gpd: [],
    other_investors_gpd: [],
    acquirer: [],
    target: [],
    investor: [],
    email: "",
    filterdata: "",
    other_investors: [],
    DealValuation: [],
    advisors: [],
    news: [],
    company: [],
  };

  componentDidMount = () => {
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/gddvcssf_single/" +
        this.props.match.params.deal +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "VC detail");
      this.setState({
        details: res.data,
        company: res.data.company,

        // acquirer: res.data.GDDMandADealsAcquirer,
        // target: res.data.GDDMandADealsTarget,
        // DealValuation: res.data.GDDMandADealsValidation,
        advisors: res.data.GDDvcseekingfundingadvisors,
        news: res.data.GDD_VC_seekingfundingNews,
        investor: res.data.lead_investors,
        investor_gpd: res.data.lead_investors_gpd,
        other_investors_gpd: res.data.other_participating_investors_gpd,
        other_investors: res.data.other_participating_investors,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    const data = this.state.details;

    const url =
      "https://www.analyzemarkets.com/en/global-company-database-details/" +
      data.url;

    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Navfilter id="sector-desktop"></Navfilter>
            <Fetch applyFilters={this.handleFilters}></Fetch>

            <Container>
              <Helmet title={data.deal}>
                <meta name="description" content={data.brief_description} />
                <meta property="og:title" content={data.deal} />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta name="og:description" content={data.brief_description} />
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website" />

                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="name"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta itemprop="description" content={data.brief_description} />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />

                <meta name="twitter:card" content="summary_large_image" />

                <meta
                  name="twitter:title"
                  content="AnalyzeMarkets - Global Investment Intelligence Platform"
                />

                <meta
                  name="twitter:description"
                  content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
                />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta
                  name="twitter:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
              </Helmet>

              <Button
                onClick={this.handleBack}
                variant="outline-light"
                className="MarginRight padDown"
              >
                <i className="fal fa-chevron-left Icon">&nbsp;GO BACK</i>
              </Button>
              <Row>
                <Col className="">
                  <Row className="justify-content-md-center">
                    <Col xs sm={12} class="">
                      <Row>
                        <Col sm={5}>
                          {" "}
                          <div class=" hero" id="2">
                            <h3 className="UpperCase">STARTUP</h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                <td>Company</td>
                                <td>
                                  {data.company !== undefined && (
                                    <div>
                                      {" "}
                                      <Link
                                        to={
                                          "/en/global-company-database-details/" +
                                          data.company.api_url +
                                          "/"
                                        }
                                      >
                                        {data.company.name}
                                      </Link>
                                    </div>
                                  )}
                                </td>
                                <tr>
                                  <td width="250">Category</td>
                                  <td>
                                    {data.startup_category != null &&
                                      data.startup_category}
                                  </td>
                                </tr>
                                <tr>
                                  <td width="250">Sector</td>
                                  <td>
                                    {data.startup_sector != null &&
                                      data.startup_sector}
                                  </td>
                                </tr>
                                <tr>
                                  <td width="250">
                                    Total Equity Amount Raised Since Established
                                  </td>
                                  <td>
                                    {data.total_equity_funding_amount_established !=
                                      null &&
                                      data.total_equity_funding_amount_established}
                                  </td>
                                </tr>
                                {data.total_funding_amount_established !==
                                  null && (
                                  <tr>
                                    <td width="250">
                                      Total Funding Amount Raised Since
                                      Established
                                    </td>
                                    <td>
                                      {data.total_funding_amount_established !=
                                        null &&
                                        data.total_funding_amount_established}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                          <br></br>
                          <div class="" id="5">
                            <h3 className="UpperCase">Advisors</h3>
                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                {this.state.advisors.map((advisor, index) => (
                                  <tr>
                                    {advisor.title !== null && (
                                      <td width="250" className="space">
                                        <div>{advisor.title}</div>
                                      </td>
                                    )}
                                    <td>
                                      <div className="paddingRight">
                                        {advisor.companyname.map((adv, key) => (
                                          <div>
                                            {adv.api_url != null ? (
                                              <Link
                                                to={
                                                  "/en/global-company-database-details/" +
                                                  adv.api_url +
                                                  "/"
                                                }
                                              >
                                                <u className="underline1">
                                                  {adv.name}
                                                </u>
                                              </Link>
                                            ) : (
                                              <Link>
                                                <u className="underline1">
                                                  {adv.name}
                                                </u>
                                              </Link>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <br></br>
                          <div class="" id="6">
                            {this.state.news.length !== 0 && (
                              <h3 className="UpperCase">News</h3>
                            )}

                            <Table
                              className="tblnoline"
                              size="sm"
                              responsive="lg"
                            >
                              <tbody>
                                {this.state.news.length !== 0 && (
                                  <tr>
                                    <td width="250" className="space">
                                      {this.state.news.map((item, index) => (
                                        <div>{item.news}</div>
                                      ))}
                                    </td>
                                    <Linkify
                                      componentDecorator={componentDecorator}
                                    >
                                      <td className="word-wrap">
                                        {this.state.news.map((item, index) => (
                                          <div>{item.value + " "}</div>
                                        ))}
                                      </td>
                                    </Linkify>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col sm={7}>
                          <div class=" hero" id="1">
                            <h3 className="UpperCase">Deal Information</h3>
                            <Table
                              className="tblnoline"
                              size="lg"
                              responsive="lg"
                            >
                              <tbody>
                                <tr>
                                  <td width="150">Deal</td>

                                  <td>{data.deal}</td>
                                </tr>

                                <tr>
                                  <td>Deal Description</td>
                                  <td className="word-wrap" 
                                  dangerouslySetInnerHTML={{ __html: data.brief_description }}>                                   
                                  </td>
                                </tr>

                                <tr>
                                  <td width="150" className="space">
                                    Deal Official Announcement
                                  </td>

                                  <td>{data.deal_official_announcement}</td>
                                </tr>

                                <tr>
                                  <td>Deal Announced Date</td>

                                  <td>
                                    <Moment format="DD MMM YYYY">
                                      {data.reported_date}
                                    </Moment>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Funding Round</td>

                                  <td>{data.last_funding_round}</td>
                                </tr>

                                <tr>
                                  <td>Total Amount Raised</td>
                                  <td>{data.total_investment_amount_raised}</td>
                                </tr>
                                <tr>
                                  <td>Last Amount Raised</td>
                                  <td>{data.last_investment_amount}</td>
                                </tr>

                                {/* <tr>
                                  <td>Lead Investors</td>
                                  <td>
                                    <div className="word-wrap">
                                      {this.state.investor_gpd.map(
                                        (company, key) => (
                                          <span>
                                            {company !== null && (
                                              <Link
                                                to={
                                                  "/en/gross-professional-database-detail/" +
                                                  company.api_url +
                                                  "/"
                                                }
                                                className="underline1"
                                              >
                                                {company.name}
                                                {this.state.investor_gpd
                                                  .length > 0 && ", "}
                                              </Link>
                                            )}
                                          </span>
                                        )
                                      )}

                                      {this.state.investor.map(
                                        (company_cdb, index) => (
                                          <span>
                                            {company_cdb !== null && (
                                              <Link
                                                to={
                                                  "/en/global-company-database-details/" +
                                                  company_cdb.api_url +
                                                  "/"
                                                }
                                                className="underline1"
                                              >
                                                {company_cdb.name}
                                                {this.state.investor.length >
                                                  1 && " , "}
                                              </Link>
                                            )}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Participating Investors</td>
                                  <td>
                                    <div className="word-wrap">
                                      {this.state.other_investors_gpd.map(
                                        (company, key) => (
                                          <span>
                                            {company !== null && (
                                              <Link
                                                to={
                                                  "/en/gross-professional-database-detail/" +
                                                  company.api_url +
                                                  "/"
                                                }
                                                className="underline1"
                                              >
                                                {company.name}
                                                {this.state.other_investors_gpd
                                                  .length > 0 && ", "}
                                              </Link>
                                            )}
                                          </span>
                                        )
                                      )}

                                      {this.state.other_investors.map(
                                        (company_cdb, index) => (
                                          <span>
                                            {company_cdb !== null && (
                                              <Link
                                                to={
                                                  "/en/global-company-database-details/" +
                                                  company_cdb.api_url +
                                                  "/"
                                                }
                                                className="underline1"
                                              >
                                                {company_cdb.name}
                                                {this.state.other_investors
                                                  .length > 1 && " , "}
                                              </Link>
                                            )}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </td>
                                </tr> */}

                                <tr>
                                  <td>Deal Status</td>

                                  <td>{data.deal_status}</td>
                                </tr>

                                <tr>
                                  <td>Company Valuation</td>

                                  <td>{data.last_company_valuation}</td>
                                </tr>

                                {data.reference !== "" && (
                                  <tr>
                                    <td>Reference</td>
                                    <td className="word-wrap">
                                      <Linkify
                                        componentDecorator={componentDecorator}
                                      >
                                        {data.reference}
                                      </Linkify>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>Deal Reference Number</td>
                                  <td>{data.dealinternalreferencenumber}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>

                          {/* <div class="" id="6">
                      <h1>Section Six</h1>
                    </div>
                    <div class="" id="7">
                      <h1>Section Seven</h1> 
                    </div>
                    */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default Startup_Seeking_Fund;
