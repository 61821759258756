import React, {Component} from 'react';
import {Link, BrowserRouter} from 'react-router-dom';
import './language.css';

class Language extends Component {
    render()
    {
        return <div className="lang">
            	<Link to="/en/coming-soon">العربية</Link>
            <span>|</span> 
            	<Link to="/en/coming-soon">汉语</Link>
        </div>;
    }
}

export default Language;
