import React, { Component } from "react";
import "./topnav.css";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";
class Topnav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      bymarkets: [],
      region: [],
    };
    this.hideOnClick = this.hideOnClick.bind(this);
    this.showhide = this.showhide.bind(this);
    this.showhide = this.showhide.bind(this);
  }
  hideOnClick = () => {
    this.setState({
      show: false,
    });
  };

  showhide = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  componentDidMount = () => {
    axios
      .get("https://api.analyzemarkets.com/bymarkets/", {})
      .then((res) => {
        console.log(res.data);
        this.setState({
          bymarkets: res.data.results,
        });
      })
      .catch((error) => {
        //on error
        // alert("There is an error in API call.");
      });

    axios
      .get("https://api.analyzemarkets.com/regman/", {})
      .then((res) => {
        console.log(32, res.data.results);
        this.setState({
          region: res.data.results,
        });
      })
      .catch((error) => {
        //on error
        // alert("There is an error in API call.");
      });
    $("body").click(function () {
      $("#close1:visible").hide("fast");
    });
  };

  render() {
    return (
      <div className="menuIconBar">
        <button
          to="#"
          className="side-nav-toggler"
          id="side-nav-toggler"
          onClick={this.showhide}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        {this.state.show && (
          <div className="side-nav opened" id="close1">
            <nav>
              <ul>
                <li>
                  <Link to="/" onClick={this.hideOnClick}>
                    Home
                  </Link>
                </li>
                <li className="menuinit">
                  <Link
                    to="/en/intelligence"
                    onClick={this.hideOnClick}
                    className="no-bg"
                  >
                    Global Investment Intelligence
                  </Link>
                  <ul>
                    <li className="dropdown">
                      <Link to="#">By Regions</Link>

                      <ul className="dropdown-content">
                        {this.state.region.map((item, i) => (
                          <li key={i}>
                            <Link
                              to={"/en/intelligence/region/" + item.link}
                              onClick={this.hideOnClick}
                            >
                              {item.region}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="dropdown">
                      <Link to="#">By Markets</Link>
                      <ul className="dropdown-content">
                        {this.state.bymarkets.map((item, i) => (
                          <li key={i}>
                            <Link
                              to={"/en/intelligence/markets/" + item.link}
                              onClick={this.hideOnClick}
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </li>

                {/* <li>
                            
                            <Link to="/en/company" onClick={this.hideOnClick}>Company Page</Link>
                            
                        </li> */}

                {/* <li>
                  <Link to="/en/podcasts" onClick={this.hideOnClick}>
                    Podcasts
                  </Link>
                </li>*/}

                <li>
                  {" "}
                  <Link to="/en/newsletters" onClick={this.hideOnClick}>
                    Newsletters
                  </Link>
                </li>
                <li>
                  <Link to="/en/app" onClick={this.hideOnClick}>
                    App
                  </Link>
                </li>
                <li className="dropdown">
                  <Link to="#" onClick={this.hideOnClick}>
                    Follow Us
                  </Link>

                  <ul className="dropdown-content">
                    <li className="dropdown">
                      <a
                        href="https://twitter.com/AnalyzeMarkets"
                        target="_blank"
                      >
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/analyzemarkets"
                        target="_blank"
                      >
                        Linkedin
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/AnalyzeMarkets-107364323968644"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/analyzemarkets/"
                        target="_blank"
                      >
                        Instagram
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/en/subscribe" onClick={this.hideOnClick}>
                    Subscribe
                  </Link>
                </li>
                <li>
                  <Link to="/en/contact_us">Contact US</Link>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }
}

export default Topnav;
