import React, { Component } from "react";
import { Link, BrowserRouter } from "react-router-dom";
import "./usernav.css";
import axios from "axios";
import { Redirect } from "react-router";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Popup from "reactjs-popup";
import Login from "../CommonComponent/passwordhash";
class Usernav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      email: "",
      emaildata: "",
      isLoading: false,
      logindata: "",
      login: "",
      tokenuser: "",
      emailresponse: "",
      rememberMe: false,
      result: "",
      results: "",
      token: "",
      response: "",
      response1: "",
      user: "",
      key: "",
      action: "",
      logoutaction: "",
      postlogin: [],
      login_flag: "False",
      logout_flag: "True",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  handleLogin = (email, token) => {
    this.setState({
      emaildata: email,
      tokenuser: token,
    });
    this.handleDetail(email, token);
  };
  handleDetail = (email, token) => {
    if (email === this.state.email && token === this.state.token) {
    } else {
      this.setState({
        result: true,
      });

      localStorage.clear();
      window.location.reload();
    }
  };

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response1 = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    const id = localStorage.getItem("id");

    this.setState({
      email,
      rememberMe,
      id,
      response1,
      token,
      user,
      action,
    });
    if (this.state.tokenuser !== "" && this.state.emaildata !== "") {
      this.handleDetail(this.state.emaildata, this.state.tokenuser);
    }
  };
  componentDidUpdate() {
    if (this.state.tokenuser !== "" && this.state.emaildata !== "") {
      this.handleDetail(this.state.emaildata, this.state.tokenuser);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const id = localStorage.getItem("id");
    const response1 = localStorage.getItem("response");
    this.setState({ email, rememberMe, response1, id });
    var bodyFormData = new FormData();
    bodyFormData.set("email", this.state.response);
    bodyFormData.set("login_flag", this.state.login_flag);
    bodyFormData.set("logout_flag", this.state.logout_flag);

    axios
      .post("https://api.analyzemarkets.com/signin/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data === "Logout Failed") {
            if (this.state.respose1 !== this.state.emailresponse) {
              this.setState({
                result: true,
              });
              window.location.reload();
            }
          } else {
            console.log(this.state.emai);
            this.setState({
              result: true,
            });

            window.location.reload();
            localStorage.setItem("logoutaction", this.state.logoutaction);
            const logoutaction = localStorage.getItem("logoutaction");

            this.setState({ logoutaction });
          }
        } else {
          alert("Api Call Error");
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }

  render() {
    return (
      <div className="usernav">
        <Login applyFilterdata={this.handleLogin}></Login>

        {this.state.results}
        {this.state.result && <Redirect to={"/en/signin"} />}
        {this.state.result && localStorage.clear()}

        <span>
          {this.state.response1 === null ? (
            <a href="/en/signin" className="signin">
              Sign In
            </a>
          ) : (
            ""
          )}
        </span>
        <span>
          {" "}
          {this.state.action && this.state.response1 === null && (
            <i className="fa fa-bell"></i>
          )}
        </span>
        <span>
          {this.state.action ? (
            <DropdownButton
              id="dropdown-basic-button"
              className="btn subscribe-btn userbutton"
              title={this.state.action ? "Hello, " + this.state.user : ""}
            >
              <Dropdown.Item>
                {" "}
                {this.state.action ? (
                  <Link to={"/en/investment_wire/"} className="black">
                    Home{" "}
                  </Link>
                ) : (
                  ""
                )}
              </Dropdown.Item>
              <Dropdown.Item>
                {" "}
                {this.state.action ? (
                  <Link to={"/en/my_profile/"} className="black">
                    My Profile
                  </Link>
                ) : (
                  ""
                )}
              </Dropdown.Item>

              {/* <Dropdown.Item>
                {" "}
                {this.state.action ? (
                  <Link
                    to={"/en/global_deals_marketplace-manage/"}
                    className="black"
                  >
                    Manage Global Deals MarketPlace{" "}
                  </Link>
                ) : (
                  ""
                )}
              </Dropdown.Item> */}

              <Dropdown.Item href="" onClick={this.onSubmit}>
                {" "}
                {this.state.action ? <span>Sign Out </span> : ""}
              </Dropdown.Item>
            </DropdownButton>
          ) : (
            ""
          )}
        </span>
      </div>
    );
  }
}

export default Usernav;
