import React, { Component, Fragment, useLayoutEffect } from "react";
import Freesubs from "../../component/global/free-subscription/subscription";
import Followus from "../../component/global/followus/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import Newspost from "../../component/global/news-post/index";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap/";
import "./news-details.css";
import axios from "axios";
import Moment from "react-moment";
import ReactAudioPlayer from "react-audio-player";
import { FacebookProvider, Share } from "react-facebook";
import { Root, Routes, Head } from "react-static";

import { setupCache } from "axios-cache-adapter";
import Linkify from "react-linkify";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";
import WeeklyInfo from "../../component/global/weekly-info/weekly-info";
import MetaTags from "react-meta-tags";

var isPaid = true;

// import renderHTML from 'react-render-html';

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Newsdetails extends React.Component {
  state = {
    item: [],
    news: { global_investment_management: [], giim_tableview: [] },
    first_row: [],
    second_row: [],
    selected: "custom_date",
    show_100: false,
    dataLoaded: false,
    title: "",
    is_first: true,
    secondPara: "",
    plan: "",
    planId: undefined,
    planCost: null,
    monthlyPlanCost: null,
    monthlyPlanId: null,
    yearlyPlanCost: null,
    yearlyPlanId: null,
  };

  constructor(props) {
    super(props);
    this.onPlanSelection = this.onPlanSelection.bind(this);
    this.onSubscribe = this.onSubscribe.bind(this);
    this.onFreeTrial = this.onFreeTrial.bind(this);
  }
  handleBack = () => {
    this.props.history.goBack();
  };

  componentDidMount = () => {
    console.log(this.props.match.params.name, 444);
    window.scrollTo(0, 0);
    // this.getRepos();
    api({
      url:
        "https://api.analyzemarkets.com/giimsingle/" +
        this.props.match.params.name +
        "/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(
        "Request response:",
        res,
        res.data.single_news.global_investment_management.length
      );
      if (res.data.single_news.global_investment_management.length === 0) {
        console.log(34, "0----");
        this.setState({ show_100: true });
      }
      this.setState({
        news: res.data.single_news,
        secondPara: res.data.single_news.second_para.replace(/"/g, "'"),
        first_row: res.data.read_more_first,
        second_row: res.data.read_more_second,
        dataLoaded: true,
        is_first: false,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();
      console.log("Cache store length:", length);
    }).catch(err => {
      console.log(err)
    });

    api({
      url:
        "https://api.analyzemarkets.com/subscription_plan/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log("Subscription response:", res);
      let yearString = res.data[0]['cost'].toString()
      let yearStart = yearString.slice(0,1)
      let yearRemain = yearString.slice(1,)
      let yearCost = yearStart +','+yearRemain;
      this.setState({
        monthlyPlanCost: res.data[1]['cost'],
        monthlyPlanId: res.data[1]['id'],

        yearlyPlanCost: yearCost,
        yearlyPlanId: res.data[0]['id'],
      });
    }).catch(err => {
      console.log(err)
    });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
    if(token) {
      window.location.href = '/en/investment_wire/'
    }
  };

  componentWillReceiveProps = (nextProps) => {
    var current_route = nextProps.match.params.name;
    window.scrollTo(0, 0);
    this.setState({
      dataLoaded: false,
    });

    api({
      url: "https://api.analyzemarkets.com/giimsingle/" + current_route + "/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log("Request response:", res);
      if (res.data.single_news.global_investment_management.length === 0) {
        console.log(34, "0----");
        this.setState({ show_100: true });
      }
      this.setState({
        news: res.data.single_news,
        secondPara: res.data.single_news.second_para.replace(/"/g, "'"),
        first_row: res.data.read_more_first,
        second_row: res.data.read_more_second,
        dataLoaded: true,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();
      console.log("Cache store length:", length);
    }).catch(err => {
      console.log(err)
    });
  };

  onPlanSelection(event) {
    console.log(event.target.value);

    if (event.target.value == 'month') {
      this.setState({
        plan: "Monthly",
        planCost: this.state.monthlyPlanCost,
        planId: this.state.monthlyPlanId
      })
    } else if (event.target.value == 'year') {
      this.setState({
        plan: "Yearly",
        planCost: this.state.yearlyPlanCost,
        planId: this.state.yearlyPlanId
      })
    }
  }

  onSubscribe() {
    sessionStorage.setItem("plan", this.state.plan);
    sessionStorage.setItem("planCost", this.state.planCost);
    sessionStorage.setItem("planId", this.state.planId);
  }
  onFreeTrial() {
    console.log('free trials')
    sessionStorage.setItem("freeTrial", JSON.stringify(true));
  }

  render() {
    const data = this.state.news;
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );

    const name = this.state.title;
    const url = "https://www.analyzemarkets.com/en/intelligence/" + data.url;
    return (
      <Fragment>
        <Header />
        <Container>
          <MetaTags>
            <title>{data.title}</title>
            <meta name="description" content={this.state.secondPara} />
            <meta property="og:image" content={data.image} itemprop="image" />
            <meta itemprop="image" content={data.image} />
          </MetaTags>
          <Helmet>
            <title>{data.title}</title>
            <meta name="description" content={this.state.secondPara} />
            <meta property="og:title" content={data.title} />
            <meta property="og:description" content={this.state.secondPara} />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content="AnalyzeMarkets" />
            <meta property="og:locale" content="en" />
            <meta property="og:type" content="website" />

            <meta property="og:image:type" content="image/jpeg"></meta>
            <meta property="og:image:width" content="300" />
            <meta property="og:image:height" content="300" />

            <meta property="og:image" content={data.image} itemprop="image" />
            <meta itemprop="name" content={data.title} />
            <meta name="image" content={data.image} />
            <meta itemprop="description" content={this.state.secondPara} />
            <meta itemprop="image" content={data.image} />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={data.title} />
            <meta name="twitter:description" content={this.state.secondPara} />
            <meta name="twitter:image" content={data.image} />
            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />

            <meta name="twitter:widgets:new-embed-design" content="on" />
            <meta name="twitter:widgets:csp" content="on" />

          </Helmet>

          {!this.state.is_first && (
            <Fragment>
              {/* <Elements stripe={stripePromise}>
                <MyCheckoutForm />
              </Elements> */}
              <Row>
                <Col className="w-70p">
                  <hr className="mb-1" />
                  <span className="newstagd mb-2 d-block">
                    {" "}
                    {data.news_country_category_sector_details}
                  </span>
                  <h2 className="h2">{data.title}</h2>

                  <div className="d-flex justify-content-between mt-1 flex-wrap">
                    <small className="font-14 basec-opa85">
                      By AnalyzeMarkets |
                      <Moment format="DD-MMM-YYYY">{data.created_at}</Moment>{" "}
                      &nbsp;<Moment format="hh:mm a">{data.created_at}</Moment>
                      &nbsp; GMT <Moment format="Z">
                        {data.created_at}
                      </Moment>{" "}
                      {data.is_updated && (
                        <span>
                          {" "}
                          | Updated on:{" "}
                          <Moment format="DD-MMM-YYYY">
                            {data.modified_at}
                          </Moment>
                          &nbsp;{" "}
                          <Moment format="hh:mm a">{data.modified_at}</Moment>
                          &nbsp; GMT{" "}
                          <Moment format="Z">{data.modified_at}</Moment>
                        </span>
                      )}
                    </small>
                    <small className="d-flex flex-row-reverse font-14 basec-opa85">
                      1 min read
                    </small>
                  </div>
                  <hr />
                  {
                    data && data.tagline
                      ? <div>
                        <p className="font-18">{data.tagline}</p>
                        <hr />
                      </div>
                      : <div></div>
                  }
                  <figure>
                    <div className="position-relative">
                      <img
                        src={data.image}
                        className="img-fluid w-100"
                        alt={data.title}
                        loading="lazy"
                      />
                      {
                        isPaid == false
                          ? <div></div>
                          : <div className="position-absolute banner-container">
                            <div className="pb-2 mb-2 para-text banner-border-bottom">Already a subscriber? Please <a href="/en/signin"><u>Sign In</u></a></div>
                            {/* <hr /> */}
                            <b className="heading-text-bold">Subscribe for access to AnalyzeMarkets' Global Deals Intelligence Platform</b>
                            <div className="row margin-top-banner">
                              <div className="col-8">
                                <div className="para-text">
                                  <span>Key offerings to businesses and professionals:</span>
                                </div>
                                <div className="d-flex align-items-center margin-bottom-banner">
                                  <img className="img-fluid mr-3 banner-icon" src="/static/media/DETERMINE-OBJECT.1cbf71fd.svg" alt="" srcset="" />
                                  <span className="para-text">Global <b>M&A Deals</b> Intelligence, Data & Analysis</span>
                                </div>
                                <div className="d-flex align-items-center margin-bottom-banner">
                                  <img className="img-fluid mr-3 banner-icon" src="/static/media/BUSINESS-INTELLIGENT.20fce05c.svg" alt="" srcset="" />
                                  <span className="para-text">Global <b>Venture Capital, IPO, Fundraising and Real Estate Deals</b> Intelligence, Data & Analysis</span>
                                </div>
                                <ul className="subscribe-list">
                                  <li className="mt-2" style={{ fontWeight: "400", fontSize: "13px" }}>
                                    Provides timely announced private and public M&A deals data with analysis & background information,
                                    Deals in the Making, companies seeking buyers and investors seeking targets predictive intelligence
                                  </li>
                                  <li className="mt-2" style={{ fontWeight: "400", fontSize: "13px" }}>
                                    Tracks private equity firms, sovereign wealth funds, institutional investors, and family offices activities
                                  </li>
                                  <li className="mt-2" style={{ fontWeight: "400", fontSize: "13px" }}>
                                    Information about strategic & financial parties involved in transactions, shareholders & backers, multiples, and deal advisors
                                  </li>
                                  <li className="mt-2" style={{ fontWeight: "400", fontSize: "13px" }}>
                                    Comprehensive searches by region, country, sector, parties, advisors, and others with alerts
                                  </li>
                                </ul>
                                <div className="subscription-demo-right">
                                  <i className="small-text-gray">For group subscription or a specific requirement, please email us at <u>subscribe@analyzemarkets.com</u></i>
                                </div>
                                {/* <div className="subscription-demo-right">
                                  <u className="small-text-gray">Request a free trial and demonstration</u>
                                </div> */}
                              </div>
                              <div className="col-4 d-flex flex-column justify-content-between">
                                <div className="d-flex align-items-start flex-column">
                                  <div className="d-flex align-items-baseline">
                                    <input type="radio" name="plan" id="" value="month" onChange={this.onPlanSelection} />
                                    <p className="price-text-bold ml-1"><b>USD {this.state.monthlyPlanCost} / month*</b></p>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <div>
                                      <strike className="price-text-bold ml-3" style={{ color: "rgba(171 171 171 / 73%)" }}> <b>USD 2,388</b> </strike>
                                    </div>
                                    <div className="d-flex align-items-baseline">
                                      <input type="radio" name="plan" id="" value="year" onChange={this.onPlanSelection} />
                                      <p className="price-text-bold ml-1"><b>USD {this.state.yearlyPlanCost} / Year*</b></p>
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    {
                                      this.state.planId == undefined
                                        ? <a href="" className="btn banner-btn" style={{ background: "#1a529c", color: "white" }} onClick={this.onSubscribe}>Subscribe</a>
                                        : <a href="/en/paywall" className="btn banner-btn" style={{ background: "#1a529c", color: "white" }} onClick={this.onSubscribe}>Subscribe</a>
                                    }
                                  </div>
                                  <div>
                                    <i className="limited-text-gray">*limited time offer per user</i>
                                  </div>
                                  <div className="subscription-demo-left mt-3">
                                    <i className="small-text-gray">For group subscription or a specific requirement, please email us at <u>subscribe@analyzemarkets.com</u></i>
                                  </div>
                                </div>
                                {/* <div className="subscription-demo-left">
                                  <b><a href="/en/subscribe_us" className="small-text-gray" onClick={this.onFreeTrial}><u>Request for Free Trial and Demo</u></a></b>
                                </div> */}
                              </div>
                            </div>
                          </div>
                      }
                      {
                        isPaid == true
                          ? <div></div>
                          : <div className="position-absolute fixed-bottom">
                            <ReactAudioPlayer src={data.audio} controls />
                          </div>
                      }
                    </div>
                    {
                      isPaid == true
                        ? <div></div>
                        : <figcaption>
                          <small>
                            <i>{data.imagetagline}</i>
                          </small>
                        </figcaption>
                    }
                  </figure>
                  <Row>
                    <Col
                      lg="7"
                      className={
                        "font18 " + (this.state.show_100 ? "wdt100p" : "")
                      }
                    >
                      <p
                        className="w786 unorder"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                      <p
                        className="w786 unorder"
                        dangerouslySetInnerHTML={{ __html: data.information }}
                      />
                    </Col>

                    <Col lg="5">
                      <Table className="tblnoline" size="sm">
                        <tbody>
                          {
                            // Fore New Tableview
                            // {this.state.news.giim_tableview.map((item, index) => (
                            //    <tr>
                            //         <td width="150">{ item.tabletype.name} </td>
                            //         <td>{ item.value} </td>
                            //     </tr>
                            // ))}
                          }

                          {this.state.news.global_investment_management.map(
                            (item, i) => (
                              <Linkify
                                componentDecorator={componentDecorator}
                                key={i}
                              >
                                <tr>
                                  <td width="150">{item.tabletype} </td>
                                  <td className="word-wrap">{item.value} </td>
                                </tr>
                              </Linkify>
                            )
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between align-items-end mt-3 flex-wrap">
                    <p className="m-0 floatHide">© {new Date().getFullYear()} AnalyzeMarkets ltd</p>
                    <div>
                      <h6 className="font600">Share:</h6>
                      <ul className="social smalls">
                        <li>
                          <TwitterShareButton
                            url={
                              "https://www.analyzemarkets.com/en/intelligence/" +
                              data.url
                            }
                          >
                            <Button
                              id="https://www.twitter.com/me/"
                              title="Twitter"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </TwitterShareButton>
                        </li>
                        <li>
                          <LinkedinShareButton
                            url={
                              "https://www.analyzemarkets.com/en/intelligence/" +
                              data.url
                            }
                          >
                            <Button
                              id="https://www.linkedin.com/me/"
                              title="Linkedin"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </LinkedinShareButton>
                        </li>
                        <li>
                          <FacebookShareButton
                            url={
                              "https://www.analyzemarkets.com/en/intelligence/" +
                              data.url
                            }
                          >
                            <Button
                              id="https://www.facebook.com/me/"
                              title="Facebook"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </FacebookShareButton>
                        </li>
                        {/* <li>
                                            <Button id="https://www.instagram.com/" title="Instagram" variant="">&nbsp;</Button>
                                        </li> */}
                        <li>
                          <WhatsappShareButton
                            url={
                              "https://www.analyzemarkets.com/en/intelligence/" +
                              data.url
                            }
                          >
                            <Button
                              id="https://web.whatsapp.com/"
                              title="Whatsapp"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </WhatsappShareButton>
                        </li>
                        <li>
                          <EmailShareButton
                            subject={data.title}
                            body={data.description}
                          >
                            <Button
                              id="https://envelope.com/"
                              title="Message"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </EmailShareButton>
                        </li>
                      </ul>
                    </div>
                    <p className="m-0 floatShow">© {new Date().getFullYear()} AnalyzeMarkets ltd</p>
                  </div>
                  <hr />
                  <Row>
                    <div className="rpanyrpany  col">
                      <div className="section-title mb-3">
                        <h3 className="cathead">Also Read:</h3>
                      </div>
                      <Row>
                        {this.state.first_row.map((item, f) => (
                          <Col md="4">
                            <Newspost key={f} item={item} />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {this.state.second_row.map((item, s) => (
                          <Col md="4">
                            <Newspost key={s} item={item} />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Row>
                </Col>
                <Col className="w-30p">
                  <div className="widget mt-18">
                    <WeeklyInfo />
                  </div>

                  <div className="widget mt-2">
                    <Freesubs />
                  </div>
                  <div className="widget">
                    <Followus />
                  </div>
                  <div className="widget">
                    <Downloadapp />
                  </div>
                  <div className="widget">
                    <SubscriberAccsss />
                  </div>
                </Col>
              </Row>
            </Fragment>
          )}
          <Row>
            {this.state.is_first && (
              <Fragment>
                <Container>
                  <Row>
                    <div className="w-70p col">
                      <hr className="mb-2 mt-2" />
                      <div className="ph-item m-0 p-0">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-4 mb-3 mt-2"></div>
                            <div className="ph-col-12 mb-2 news-big"></div>
                            <div className="ph-col-8 news-big mb-2"></div>

                            <div className="ph-col-6 mb-0 mt-3"></div>
                            <div className="ph-col-4  mt-3 mb-0 empty"></div>
                            <div className="ph-col-2 mt-3 mb-0"></div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                        <div className="ph-col-6"></div>
                        <div className="ph-col-6 empty"></div>
                      </div>
                      <hr />
                      <div className="ph-picture-NB"></div>
                      <div className="ph-row">
                        <div className="ph-col-4 mt-2"></div>
                        <div className="ph-col-10 big"></div>
                        <div className="ph-col-2 empty big"></div>
                        <div className="ph-col-4"></div>
                        <div className="ph-col-8 empty"></div>
                        <div className="ph-col-6"></div>
                        <div className="ph-col-6 empty"></div>
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </Row>
                </Container>
              </Fragment>
            )}
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Newsdetails;
