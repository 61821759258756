import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './newsletter-subscription.css';

class Newslettersubs extends Component {  
    constructor(props)
    {
        super(props);
        this.state = {
            email: '',
        };

        this.handleClick = this.handleClick.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleClick = item => {
       console.log(this.state.email);
       this.props.applyFilter(this.state.email);
  };


    render()
    {
        return <div className="subsbription_section_alt pt-5">
            <div className="input-group input-group-rounded newsletter-form">
                <input
                   className="form-control form-control-lg"
                   placeholder="Email Address"
                   type="email" 
                   name="email" onChange={this.onChange.bind(this)} required/>
                <span className="input-group-append">
                    <button className="btn btn-blue" type="submit" onClick={()=>{this.handleClick('region');}}>Sign Up</button>
                </span>
                <p className="pt-4 w-100">By submitting my information I agree to AnalyzeMarkets <Link to="/en/privacy-policy">Privacy Policy</Link></p>
            </div>
        </div>;
    }
}

export default Newslettersubs;
