import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./subscribers-access.css";

class SubscriberAccsss extends Component {
  render() {
    return (
      <div className="blew-box">
        <h3>AnalyzeMarkets - Subscribers Get Access to:</h3>
        <ul className="ticklist pt-2 pb-3">
          <li>
            Global <u>Deals Intelligence</u>
          </li>
          <li>
            Global Deals <u>Marketplace</u>
          </li>
          <li>
            Global <u>Investors</u> Database
          </li>
          <li>
            Global <u>Companies</u> Database
          </li>
          <li>
            Global <u>Professionals</u> Database
          </li>
          <li>
            Global <u>Research Reports</u>
          </li>
          <li>
            <u>On-Demand</u> Research & Analysis
          </li>
        </ul>
        <div className="text-center">
          <a href="/en/subscribe" className="btn btn-blue">
            Subscribe
          </a>
        </div>
      </div>
    );
  }
}

export default SubscriberAccsss;
