import React, { Component } from "react";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});
class GlobalDealsMarketPlace extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    gloabalmarket: [],
  };

  componentDidMount = () => {};

  render() {
    return (
      <div>
        {this.state.gloabalmarket.map((item, key) => (
          <div>{item.id == 2733 ? item.description : ""}</div>
        ))}
      </div>
    );
  }
}
export default GlobalDealsMarketPlace;
