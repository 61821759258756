import React, { Component, Fragment, Suspense } from "react";
import { Route, Switch, NavLink, Link, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import RE_Deals from "./pages/re-deals/re-deals";
import JV_Deals from "./pages/jv-deals/jv-deals";
import IpoMonitor from "./pages/m&a_dealmonitor/ipomonitor";
import VC_Seeking_Fund_Search from "./pages/madealsearch/seeking-fund-search";
import VC_Seeking_Buyer_Search from "./pages/madealsearch/vc-seeking-buyer-search";
import MA_Deals from "./pages/m-aDeals/m-aDeals";
import Gmd_JV_Detail from "./pages/joint-venture/joint-venture-detail";
import SeekingBuyerDetail from "./pages/create-deal/seeking-buyer-detail";
import Gdm_Debt_Edit from "./pages/debt-deal-gdm/debt-deal-edit";
import Gmd_JV_Edit from "./pages/joint-venture/joint-venture-edit";
import CompanyDatabase from "./pages/GlobalCompanyDatabase/CompanyDatabase";
import VC_Seeking_Fund from "./pages/v&c_dealmonitor/v&c_startupseekingfund";
import VC_Seeking_Buyers from "./pages/v&c_dealmonitor/v&c_startupseekingbuyers";
import VC_Seeking_Buyer_Detail from "./pages/v&c_dealmonitor/seeking_sb_vc_detail";
import VC_Seeking_Fund_Detail from "./pages/v&c_dealmonitor/seeking_fund_vc_detail";
import VC_Deals_Monitor_Details from "./pages/v&c_dealmonitor/v&c_dealmonitor_details1";
import re_details from "./pages/re-deals/re-details1";
import GRR_Search from "./pages/madealsearch/grr-search";
import FundRaising_Edit from "../src/pages/fund-raising-gdm/fund-raising-edit-deal";
import VC_Deals_Gmd_Edit from "./pages/Gdm-Vcdeals/gdm-vc-deals-edit";
import JV_Details from "./pages/jv-deals/jv-details1";
import debt_details from "./pages/debt-deals/debt-details1";
import Fund_Raising_Details from "./pages/fund-raising-deals/fund-raising-deals-detail1";
import ipoMDetails from "./pages/m&a_dealmonitor/ipomonitor-detail1";
import MACompanySeekingTarget from "./pages/m&a_dealmonitor/ma-seeking-targets";
import MACompanySeekingTargetDetails from "./pages/m&a_dealmonitor/ma-seeking-target-details";
import VC_Deals_Monitor from "./pages/v&c_dealmonitor/v&c_dealmonitor";
import debt_Deals from "./pages/debt-deals/debt-deals";
import Gmd_Investment_Wire from "./pages/press-release/press-release";
import GiwDetails from "./pages/giw-details/index-detail";
import RealCreateDeal from "./pages/real-estate-deal/real-estate-creat-deal";
import RealEstateGmdSearch from "./pages/madealsearch/real-estate-gmd-search";
import Gdm_Debt_Detail from "./pages/debt-deal-gdm/debt-deal-gdm-detail";
import VC_Deals_Detail from "./pages/Gdm-Vcdeals/gdm-vc-deals-detail";
import FundRaising_Detail from "../src/pages/fund-raising-gdm/fund-raising-detail";
import Global_Report_Research from "./pages/global-research-report/global-resesarch-report";
import GRR_Details from "./pages/global-research-report/global-research-detail";
import Gpd_Detail from "./pages/gross-people-development/gpd-detail";
import Gpd_Search from "./pages/madealsearch/gpd-search";
import RealEstateSB from "./pages/re-deals/re-deals-sb/re-deals-sb-monitor";
import RealEstateSBDetail from "./pages/re-deals/re-deals-sb/re-deals-sb-detail";
import RealEstateSP from "./pages/re-deals/re-deals-sp/re-deals-sp-monitor";
import RealEstateSPDetail from "./pages/re-deals/re-deals-sp/re-deals-sp-detail";
import ReSeekingBuyerSearch from "./pages/madealsearch/reestatesb-search";
import ReSeekingPropertiesSearch from "./pages/madealsearch/realestatespsearch";
import DebtDealsSeekingBuyer from "./pages/debt-deals/company-seeking-debt/company-seeking-debt";
import DebtDealsSeekingBuyerDetail from "./pages/debt-deals/company-seeking-debt/company-seeking-debt-detail";

import Login from "./component/global/CommonComponent/passwordhash";
import { Redirect } from "react-router";
import NotFound from "./pages/not-found";

const LoadingMessage = () => "<h1>Loading</h1>";

class Router2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signin: [],
      email: "",
      token: "",
      response: "",
      user: "",
      count: "",
      login: "",
      tokenuser: "",
      results: false,

      hasError: false,
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <div className="Router">
        {console.log("Test123")}

        <Route
          path={"/:lan/company-database/"}
          component={CompanyDatabase}
          exact
        ></Route>
        <Route
          path={"/:lan/seeking_funding/"}
          component={VC_Seeking_Fund}
          exact
        ></Route>
        <Route
          path={"/:lan/seeking_buyer/"}
          component={VC_Seeking_Buyers}
          exact
        ></Route>
        <Route
          path={"/:lan/seeking_funding_detail/:deal/"}
          component={VC_Seeking_Fund_Detail}
          exact
        ></Route>
        <Route
          path={"/:lan/debt_dealseeking_buyer/"}
          component={DebtDealsSeekingBuyer}
          exact
        ></Route>
        <Route
          path={"/:lan/debt_dealseeking_buyer_detail/:opportunity/"}
          component={DebtDealsSeekingBuyerDetail}
          exact
        ></Route>

        <Route
          path={"/:lan/seeking_fund_search/"}
          component={VC_Seeking_Fund_Search}
          exact
        ></Route>
        <Route
          path={"/:lan/seeking_buyer_search/"}
          component={VC_Seeking_Buyer_Search}
          exact
        ></Route>

        <Route
          path={"/:lan/seeking_buyer_detail/:deal/"}
          component={VC_Seeking_Buyer_Detail}
          exact
        ></Route>

        <Route
          path={"/:lan/debt-deals-gmd-edit/:dealheadline/"}
          component={Gdm_Debt_Edit}
          exact
        ></Route>
        <Route path={"/:lan/m_a_deals/"} component={MA_Deals}></Route>
        <Route
          path={"/:lan/professional-monitor-search/"}
          component={Gpd_Search}
          exact
        ></Route>
        <Route
          path={"/:lan/fundraising_edit/:dealheadline/"}
          component={FundRaising_Edit}
          exact
        ></Route>
        <Route
          path={"/:lan/global-research-report-monitor/"}
          component={Global_Report_Research}
          exact
        ></Route>
        <Route
          path={"/:lan/global-research-report-details/:title/"}
          component={GRR_Details}
          exact
        ></Route>
        <Route
          path={"/:lan/global-research-report-search/"}
          component={GRR_Search}
          exact
        ></Route>

        <Route
          path={"/:lan/joint-venture-gmd-detail/:dealheadline/"}
          component={Gmd_JV_Detail}
          exact
        ></Route>

        <Route
          path={"/:lan/debt-deals-gmd-detail/:dealheadline/"}
          component={Gdm_Debt_Detail}
          exact
        ></Route>
        <Route
          path={"/:lan/real-estate-seeking-buyer/"}
          component={RealEstateSB}
          exact
        ></Route>
        <Route
          path={"/:lan/real-estate-seeking-buyer-detail/:opportunity/"}
          component={RealEstateSBDetail}
          exact
        ></Route>
        <Route
          path={"/:lan/real-estate-seeking-properties/"}
          component={RealEstateSP}
          exact
        ></Route>
        <Route
          path={"/:lan/real-estate-seeking-properties-search/"}
          component={ReSeekingPropertiesSearch}
          exact
        ></Route>
        <Route
          path={"/:lan/real-estate-seeking-buyers-search/"}
          component={ReSeekingBuyerSearch}
          exact
        ></Route>

        <Route
          path={"/:lan/real-estate-seeking-properties-detail/:opportunity/"}
          component={RealEstateSPDetail}
          exact
        ></Route>

        <Route path={"/:lan/vc-deals/"} component={VC_Deals_Monitor}></Route>
        <Route
          path={"/:lan/seeking-buyers-details/:dealheadline/"}
          component={SeekingBuyerDetail}
          exact
        ></Route>
        <Route
          path={"/:lan/real-estate-target/"}
          component={RealCreateDeal}
          exact
        ></Route>
        <Route
          path={"/:lan/real-estate-gmd-search/"}
          component={RealEstateGmdSearch}
          exact
        ></Route>
        <Route
          path={"/:lan/gross-professional-database-detail/:name/"}
          component={Gpd_Detail}
          exact
        ></Route>

        <Route
          path={"/:lan/vc-deals-detail/:deal/"}
          component={VC_Deals_Monitor_Details}
          exact
        ></Route>
        <Route path={"/:lan/re-detail/:deal/"} component={re_details}></Route>
        <Route path={"/:lan/jv-detail/:deal/"} component={JV_Details}></Route>
        <Route
          path={"/:lan/debt_detail/:deal/"}
          component={debt_details}
          exact
        ></Route>
        <Route path={"/:lan/jv-deals/"} component={JV_Deals}></Route>
        <Route path={"/:lan/debt-deals/"} component={debt_Deals}></Route>
        <Route path={"/:lan/real-estate-deals/"} component={RE_Deals}></Route>

        <Route
          path={"/:lan/fund-raising-detail/:deal/"}
          component={Fund_Raising_Details}
          exact
        ></Route>
        <Route
          path={"/:lan/vc-deals-gmd-details/:dealheadline/"}
          component={VC_Deals_Detail}
          exact
        ></Route>
        <Route
          path={"/:lan/fundraising_detail/:dealheadline/"}
          component={FundRaising_Detail}
          exact
        ></Route>

        <Route
          path={"/:lan/ipo-monitor-detail/:deal/"}
          component={ipoMDetails}
          exact
        ></Route>
        <Route path={"/:lan/ipo-monitor/"} component={IpoMonitor}></Route>
        <Route
          path={"/:lan/joint-venture-gmd-edit/:dealheadline/"}
          component={Gmd_JV_Edit}
          exact
        ></Route>
        <Route
          path={"/:lan/vc-deals-gmd-edit/:dealheadline/"}
          component={VC_Deals_Gmd_Edit}
          exact
        ></Route>
      </div>
    );
  }
}

export default Router2;
