import React, {Component} from 'react';
import Freesubs from '../../component/global/free-subscription/subscription';
import Followus from '../../component/global/followus/followus';
import Downloadapp from '../../component/global/download-app/downloadapp';
import SubscriberAccsss from '../../component/global/subscribers-access/subscribers-access';
import capchaimg from '../../assets/images/captcha-img.jpg';
import {Container, Row, Col, Form, Button} from 'react-bootstrap/';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import axios from "axios";
import { setupCache } from 'axios-cache-adapter';


// Create `axios-cache-adapter` instance 
const cache = setupCache({ 
  maxAge: 15 * 60 * 1000 
})

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter
})

class Submitwire extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: null,
            firstname: null,
            lastname: null,
            company_name: null,
            company_url: null,
            job_title: null,
            country: null,
            contact_number: null,
            free_trial_demonstration: null,
            result:'',
            is_email:true,
            is_firstname:true,
            is_lastname:true,
            is_company_name:true,
            is_company_url:true,
            is_job_title:true,
            is_country:true,
            is_contact_number:true,
            country_list:[],
            sector_list:[],
            address:'',
            sector:null,
            error:'',
        }
    }

    onChange(e) {
        console.log(e.target.name);
        if(e.target.name==='email'){
            if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(e.target.value)){
                console.log(47)
                this.setState({
                    is_email:false
                })
            }else{
                 this.setState({
                    is_email:true
                })
            }
        }

        if(e.target.name==='company_url'){
            if(!/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(e.target.value)){
                console.log("Url Is not valida");
                this.setState({
                    is_company_url:false
                })
            }else{
                this.setState({
                    is_company_url:true
                })
            }
        }
        
        if(e.target.name==='contact_number'){
            if(!/^\+\d{1,4}-[1-9]\d{0,9}$/.test(e.target.value)){
                this.setState({
                    is_contact_number:false
                })
            }else{
                this.setState({
                    is_contact_number:true
                })
            }
        }
        // if(e.target.value.match(/^[w-.+]+@[a-zA-Z0-9.-]+.[a-zA-z0-9]{2,4}$/)){
        //     console.log("In valid");
        // }
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    onSubmit(e) {
        e.preventDefault();
        var bodyFormData = new FormData();

        if ((/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/.test(this.state.email))===false){
            this.setState({
                error:'Invalid Email Address'
            })
            return 0
        }

         if (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(this.state.email)===false){
            this.setState({
                error:'Invalid Url'
            })
            return 0
        }
        this.setState({
                error:''
            })
        bodyFormData.set('email', this.state.email);
        bodyFormData.set('firstname', this.state.firstname);
        bodyFormData.set('lastname', this.state.lastname);
        bodyFormData.set('company_name', this.state.company_name);
        bodyFormData.set('company_url', this.state.company_url);
        bodyFormData.set('job_title', this.state.job_title);
        bodyFormData.set('country', this.state.country);
        bodyFormData.set('contact_number', this.state.contact_number);
        bodyFormData.set('address', this.state.address);
        bodyFormData.set('sector', this.state.sector);


        console.log(this.state);

        if(this.state.free_trial_demonstration==='on'){

            bodyFormData.set('free_trial_demonstration',true);
        }else{
            bodyFormData.set('free_trial_demonstration',false);
        }

        axios.post('https://api.analyzemarkets.com/submit_wire/',bodyFormData,{ headers: {'Content-Type': 'multipart/form-data'}}).then((res)=>{
           console.log(res);
           if(res.status===200){
               console.log(30);
               this.setState({result:"Thank you for wire submission!"});
           }else{
               this.setState({result:"Something Went Wrong"});
           }
           
        }).catch((error)=>{
        //on error
        alert('There is an error in API call.');
        });
    }
      handleDropdownChange(e, type) {
          if(type==='country'){
            this.setState({
                country: e.target.value
            })
          }else{
              this.setState({
                  sector:e.target.value
              })
          }
        
  }


    componentDidMount = () => {
        window.scrollTo(0, 0);

        api({
          url: 'https://api.analyzemarkets.com/country/',
          method: 'get'
        }).then(async (res) => {
          console.log(res)
          if(res.status===200){
              this.setState({country_list:res.data.results});
          }
        })

        api({
          url: 'https://api.analyzemarkets.com/sectorman/',
          method: 'get'
        }).then(async (res) => {
          console.log(res)
          if(res.status===200){
              this.setState({sector_list:res.data.results});
          }
        })

    }
    render()
    {
        return <Container>
            <Helmet>
                <title>AnalyzeMarkets - Global Investment Intelligence Platform</title>
                <meta name="description" content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database." />
                <meta property="og:title" content="AnalyzeMarkets - Global Investment Intelligence Platform" />
                <meta property="og:image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
                <meta name="og:description" content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."/>
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website"/>

                <meta name="image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
                <meta itemprop="name" content="AnalyzeMarkets - Global Investment Intelligence Platform" />

                <meta itemprop="description" content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database." />
                <meta itemprop="image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />

                <meta name="twitter:card" content="summary_large_image" />

                <meta name="twitter:title" content="AnalyzeMarkets - Global Investment Intelligence Platform" />

                <meta name="twitter:description" content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database." />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta name="twitter:image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
            </Helmet>
            <Row>
                <Col md={12}>
                    <hr/>
                    <h2 className="text-uppercase mb-4">Submit a wire</h2>
                </Col>


                { this.state.result!=='' &&
                    <Col md={6}>
                    <h5 className="pb-4 font-weight-normal">{this.state.result}</h5>
                     </Col>
                }
                {this.state.result==='' && 
                <Col className="w-70p">
                    <div className="pdg100">
                    <h5 className="pb-4 font-weight-normal">Enter the below information to submit a wire:</h5>
                    <p><span className="text-danger">* </span>marked fields are mandatory</p>

                    <Form onSubmit={this.onSubmit.bind(this)}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="FirstName">
                                <Form.Control type="text" placeholder="First Name*" name="firstname" onChange={this.onChange.bind(this)} required/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="lastName">
                                <Form.Control type="text" placeholder="Last Name*" name="lastname" onChange={this.onChange.bind(this)} required/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="ComapanyName">
                            <Form.Control type="text" placeholder="Company Name*" name="company_name" onChange={this.onChange.bind(this)} required/>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col} controlId="ChooseCountry">
                               <Form.Control as="select" onChange={(e) => this.handleDropdownChange(e, 'country')}>
                                    <option value="Select Country">Select Country</option>
                                    { this.state.country_list.map( (item, i) => (
                                      <option key={i} value={item.country}>{item.country }</option>
                                    ))}  
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} controlId="ChooseCountry">
                                <Form.Control as="select" onChange={(e) => this.handleDropdownChange(e, 'sector')}>
                                    <option value="Select Sector">Select Sector</option>
                                    { this.state.sector_list.map( (item, i) => (
                                      <option key={i} value={item.sector}>{item.sector }</option>
                                    ))}  
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="CompanyURL">
                            <Form.Control type="text" placeholder="Company URL*" name="company_url" onChange={this.onChange.bind(this)} required/>
                        </Form.Group>

                        <Form.Group controlId="JobTitle">
                            <Form.Control type="text" placeholder="Job Title*" name="job_title" onChange={this.onChange.bind(this)} required/>
                        </Form.Group>

                        <Form.Group controlId="WorkEmail">
                            <Form.Control type="email" placeholder="Work Email*" name="email" onChange={this.onChange.bind(this)} required/>
                        </Form.Group>

                        <Form.Group controlId="ContactNumber">
                            <Form.Control type="text" placeholder="Address*" name="address" onChange={this.onChange.bind(this)} required/>
                        </Form.Group>

                        <Form.Group controlId="ContactNumber">
                            <Form.Control type="text" placeholder="Contact Number*" name="contact_number" onChange={this.onChange.bind(this)} required/>
                        </Form.Group>

                        <div className="capchaCode">
                            <div className="g-recaptcha" data-sitekey="6LcjIsQUAAAAAPYjdLFm1hP-EgdGAJFQrUxmRGkc"></div>
                        </div>
                        <p className="mb-4 mt-2">By submitting the above required information you agree to our <Link to="#">Terms of Use</Link>, <Link to="#">Privacy Policy</Link> and <Link to="#">Disclaimer</Link>.</p>
                        
                            <div className="text-center mb-5">
                                <Button variant="blue btn-lg" type="submit">
                                    Submit
                                </Button>
                            </div>
                    </Form>
                    </div>
                </Col>
                }
                <Col className="w-30p">
                    <div className="widget">
                        <Freesubs/>
                    </div>
                    <div className="widget">
                        <Followus/>
                    </div>
                    <div className="widget">
                        <Downloadapp/>
                    </div>
                    <div className="widget">
                        <SubscriberAccsss/>
                    </div>
                </Col>
            </Row>
        </Container>;
    }
}

export default Submitwire;
