import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap/";
import { Link } from "react-router-dom";
import Logo from "../../global/logo/logo";
import "./footer.css";
import CookieConsent, { Cookies } from "react-cookie-consent";

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="footer-top-holder">
          <Container>
            <Row className="align-items-center">
              <Col lg="3">
                <div className="brandlogo-footer">
                  <Logo />
                </div>
              </Col>
              <Col lg="9">
                <div className="menu--dsah text-right">
                  <ul>
                    <li>
                      <Link to="/en/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/en/contact_us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/en/terms-of-use">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/en/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/en/trademarks">Trademarks</Link>
                    </li>
                    <li>
                      <Link to="/en/disclaimer">Disclaimer</Link>
                    </li>
                  </ul>
                </div>
                <div className="menu--dsah1 text-right">
                  <ul>
                    <li>
                      <Link to="/en/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/en/contact_us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/en/terms-of-use">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/en/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/en/trademarks">Trademarks</Link>
                    </li>
                    <li>
                      <Link to="/en/disclaimer">Disclaimer</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer-bottom-holder text-white">
          <Container>
            &copy; {new Date().getFullYear()} AnalyzeMarkets Ltd. All rights reserved. AnalyzeMarkets
            is the trade name of AnalyzeMarkets ltd
          </Container>
        </div>
        <CookieConsent
          buttonText="I agree and continue"
          style={{ background: "rgba(31,65,155,.9)", color: "#ffffff" }}
          buttonStyle={{
            background: "#1ab6df",
            color: "#ffffff",
            fontSize: "13px",
          }}
          location="bottom"
          buttonClasses="cc-btn cc-dismiss"
        >
          This website uses cookies to ensure you get the best experience on our
          website. <Link to="/en/cookie">Learn more</Link>
        </CookieConsent>
      </footer>
    );
  }
}

export default Footer;
