import React, { Component, Fragment } from "react";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import NavfilterPlaceholder from "../../component/global/navfilter/navfilter-static";
import Newspost from "../../component/global/news-post/index";
import Freesubs from "../../component/global/free-subscription/subscription";
import InvestmentIn from "../../component/global/investment-intelligence/investment-intelligence";
import Investwire from "../../component/global/investment-wire/investment-wire";
import Followus from "../../component/global/followus/followus";
import Offerwidget from "../../component/global/offers-widget/offers-widget";
import Bnrwidget from "../../component/global/banner-widget";
import { Container, Row, Col } from "react-bootstrap/";
import "./homeafterLogin.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Helmet } from "react-helmet";
import { Link, BrowserRouter } from "react-router-dom";
import imgsource1 from "../../../src/assets/images/DATA-SCIENCE-TEAM.svg";
import imgsource2 from "../../../src/assets/images/DETERMINE-OBJECT.svg";
import imgsource3 from "../../../src/assets/images/DATABASE.svg";
import imgsource4 from "../../../src/assets/images/DATA-COLLECTION.svg";
import imgsource5 from "../../../src/assets/images/STATISTICs.svg";
import imgsource6 from "../../../src/assets/images/B2B.svg";
import imgsource7 from "../../../src/assets/images/DASHBOARD-REPORT.svg";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import HomeSidebar from "../../component/global/HomeSidebar/homeSidebar";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class HomeAfterLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section_one: [1, 2, 3, 4, 5, 6],
      section_two: [],
      section_three: [],
      region: "",
      sector: "",
      feature: "",
      subfeature: "",
      is_first: true,
    };
    this.greetHandler = this.greetHandler.bind(this);
  }

  greetHandler(childName, name) {
    console.log(35, childName);
    let feature = "";
    let subfeature = "";
    let _self = this;
    if (name === "feature") {
      feature = childName.id;
      _self.setState({
        feature: feature,
      });
    } else {
      subfeature = childName.id;
      _self.setState({
        subfeature: subfeature,
      });
    }
    if (feature === "") {
      feature = _self.state.feature;
    }

    if (subfeature === "") {
      subfeature = _self.state.subfeature;
    }
    _self.getData(feature, subfeature);
  }

  getData = (region, sector) => {
    console.log(this.state.region, 51, this.state.sector);

    api({
      url:
        "https://api.analyzemarkets.com/giim_home/?region=" +
        region +
        "&sector=" +
        sector,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log("Request response:", res);
      this.setState({
        section_one: res.data.section_one,
        section_two: res.data.section_two,
        section_three: res.data.section_three,
        is_first: false,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  componentDidMount = () => {
    this.getData("", "");
    console.log(111);
    console.log(101);
    window.scrollTo(0, 0);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    var greetHandler = this.greetHandler;
    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Helmet>
            <title>
              AnalyzeMarkets - Global Investment Intelligence Platform
            </title>
            <meta
              name="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta
              property="og:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              itemprop="name"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              itemprop="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta
              name="twitter:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              name="twitter:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>

          {!this.state.is_first && !this.props.loading && (
            <Fragment>
              <Navfilter greetChild={greetHandler.bind(this)} />
              <Container>
                <Row>
                  <Col className="w-30p ">
                    <HomeSidebar />
                  </Col>

                  <Col className="w-70p">
                    <Row>
                      <Col md={6} className="paddingDown">
                        <div className="bordersize">
                          <div className=" ">
                            <Row>
                              <Col md={3}>
                                <img src={imgsource1} className="img-fluid" />
                              </Col>

                              <Col md={8} className="AlignMiddle">
                                <h5 className="OpenSans paddingTop">
                                  Global Deals Database
                                </h5>
                              </Col>
                            </Row>

                            <Row className="paddingTop">
                              <Col>
                                <ul className="ticklist">
                                  <li className="paddingBottom">
                                    Covering M&A, VC, JV, IPO, Fundraising, Real
                                    Estate, Fixed Income
                                  </li>

                                  <li className="paddingBottom">
                                    Covering major developed and emerging
                                    economies and all sectors
                                  </li>

                                  <li className="paddingBottom">
                                    Over 1 million deals
                                  </li>

                                  <li className="paddingBottom">
                                    Comprehensive search tools
                                  </li>

                                  <li className="paddingBottom">
                                    Along with histroical useful information,
                                    certain business development opportunities
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>

                      <Col sm={6} className="paddingDown">
                        <div className="bordersize">
                          <div className="">
                            <Row>
                              <Col md={3} className="img-fluid">
                                <img src={imgsource2} className="img-fluid" />
                              </Col>
                              <Col md={8} className="AlignMiddle">
                                <h5 className="OpenSans">
                                  Global Deals MarketPlace
                                </h5>
                              </Col>
                            </Row>
                            <Row className="paddingTop">
                              <Col>
                                <ul className="ticklist">
                                  <li className="paddingBottom">
                                    Covering M&A, VC, JV, IPO, Fundraising, Real
                                    Estate, Fixed Income
                                  </li>

                                  <li className="paddingBottom">
                                    Covering major developed and emerging
                                    economies and all sectors
                                  </li>

                                  <li className="paddingBottom">
                                    Over 1 million deals
                                  </li>

                                  <li className="paddingBottom">
                                    Comprehensive search tools
                                  </li>

                                  <li className="paddingBottom">
                                    Along with histroical useful information,
                                    certain business development opportunities
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col md={6}>
                        <div className="bordersize">
                          <div className="">
                            <Row>
                              <Col md={3}>
                                <img src={imgsource3} className="img-fluid" />
                              </Col>
                              <Col md={8} className="AlignMiddle">
                                <h5 className="OpenSans ">
                                  Global Company Database
                                </h5>
                              </Col>
                            </Row>
                            <Row className="paddingTop">
                              <Col>
                                <ul className="ticklist">
                                  <li className="paddingBottom">
                                    Covering M&A, VC, JV, IPO, Fundraising, Real
                                    Estate, Fixed Income
                                  </li>

                                  <li className="paddingBottom">
                                    Covering major developed and emerging
                                    economies and all sectors
                                  </li>

                                  <li className="paddingBottom">
                                    Over 1 million deals
                                  </li>

                                  <li className="paddingBottom">
                                    Comprehensive search tools
                                  </li>

                                  <li className="paddingBottom">
                                    Along with histroical useful information,
                                    certain business development opportunities
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>

                      <Col sm={6} className="paddingDown">
                        <div className="bordersize">
                          <div className="">
                            <Row>
                              <Col md={3}>
                                <img src={imgsource4} className="img-fluid" />
                              </Col>
                              <Col md={8} className="AlignMiddle">
                                <h5 className="OpenSans ">
                                  Global Investors Database
                                </h5>
                              </Col>
                            </Row>
                            <Row className="paddingTop">
                              <Col>
                                <ul className="ticklist">
                                  <li className="paddingBottom">
                                    Covering M&A, VC, JV, IPO, Fundraising, Real
                                    Estate, Fixed Income
                                  </li>

                                  <li className="paddingBottom">
                                    Covering major developed and emerging
                                    economies and all sectors
                                  </li>

                                  <li className="paddingBottom">
                                    Over 1 million deals
                                  </li>

                                  <li className="paddingBottom">
                                    Comprehensive search tools
                                  </li>

                                  <li className="paddingBottom">
                                    Along with histroical useful information,
                                    certain business development opportunities
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="bordersize">
                          <div className="">
                            <Row>
                              <Col md={3}>
                                <img src={imgsource5} className="img-fluid" />
                              </Col>
                              <Col md={8} className="AlignMiddle">
                                <h5 className="OpenSans ">
                                  Global Research Report Database
                                </h5>
                              </Col>
                            </Row>
                            <Row className="paddingTop">
                              <Col>
                                <ul className="ticklist">
                                  <li className="paddingBottom">
                                    Covering M&A, VC, JV, IPO, Fundraising, Real
                                    Estate, Fixed Income
                                  </li>

                                  <li className="paddingBottom">
                                    Covering major developed and emerging
                                    economies and all sectors
                                  </li>

                                  <li className="paddingBottom">
                                    Over 1 million deals
                                  </li>

                                  <li className="paddingBottom">
                                    Comprehensive search tools
                                  </li>

                                  <li className="paddingBottom">
                                    Along with histroical useful information,
                                    certain business development opportunities
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>

                      <Col sm={6} className="paddingDown">
                        <div className="bordersize">
                          <div className="">
                            <Row>
                              <Col md={3}>
                                <img src={imgsource6} className="img-fluid" />
                              </Col>
                              <Col md={8} className="AlignMiddle">
                                <h5 className="OpenSans ">
                                  Global Professional Database
                                </h5>
                              </Col>
                            </Row>
                            <Row className="paddingTop">
                              <Col>
                                <ul className="ticklist">
                                  <li className="paddingBottom">
                                    Covering M&A, VC, JV, IPO, Fundraising, Real
                                    Estate, Fixed Income
                                  </li>

                                  <li className="paddingBottom">
                                    Covering major developed and emerging
                                    economies and all sectors
                                  </li>

                                  <li className="paddingBottom">
                                    Over 1 million deals
                                  </li>

                                  <li className="paddingBottom">
                                    Comprehensive search tools
                                  </li>

                                  <li className="paddingBottom">
                                    Along with histroical useful information,
                                    certain business development opportunities
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col md={6}>
                        <div className="bordersize">
                          <div className="">
                            <Row>
                              <Col md={3}>
                                <img src={imgsource7} className="img-fluid" />
                              </Col>
                              <Col md={8} className="AlignMiddle">
                                <h5 className="OpenSans ">
                                  On-Demand Research And Analysis
                                </h5>
                              </Col>
                            </Row>
                            <Row className="paddingTop">
                              <Col>
                                <ul className="ticklist">
                                  <li className="paddingBottom">
                                    Covering M&A, VC, JV, IPO, Fundraising, Real
                                    Estate, Fixed Income
                                  </li>

                                  <li className="paddingBottom">
                                    Covering major developed and emerging
                                    economies and all sectors
                                  </li>

                                  <li className="paddingBottom">
                                    Over 1 million deals
                                  </li>

                                  <li className="paddingBottom">
                                    Comprehensive search tools
                                  </li>

                                  <li className="paddingBottom">
                                    Along with histroical useful information,
                                    certain business development opportunities
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Fragment>
          )}
          {this.state.is_first && (
            <Fragment>
              <Row>
                <Col className="w-70p">
                  <Row>
                    <Col md="4">
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                          </div>
                          <div className="ph-picture"></div>
                          <div className="ph-row bdrph">
                            <div className="ph-col-4"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-6 big"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-4 big"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                          </div>
                          <div className="ph-picture"></div>
                          <div className="ph-row bdrph">
                            <div className="ph-col-4"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-6 big"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-4 big"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                          </div>
                          <div className="ph-picture"></div>
                          <div className="ph-row bdrph">
                            <div className="ph-col-4"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-6 big"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-4 big"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                          </div>
                          <div className="ph-picture"></div>
                          <div className="ph-row bdrph">
                            <div className="ph-col-4"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-6 big"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-4 big"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                          </div>
                          <div className="ph-picture"></div>
                          <div className="ph-row bdrph">
                            <div className="ph-col-4"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-6 big"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-4 big"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                          </div>
                          <div className="ph-picture"></div>
                          <div className="ph-row bdrph">
                            <div className="ph-col-4"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-6"></div>
                            <div className="ph-col-6 empty"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-6 big"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-4 big"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Fragment>
          )}
        </Container>
      </Fragment>
    );
  }
}

export default HomeAfterLogin;
