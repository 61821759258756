import React, { Component, Fragment } from "react";

import "../investment-intellegence-screener/investment-Intelligence-screener.css";
import { Redirect } from "react-router";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Row, Table, Col, Container } from "react-bootstrap";
import Autocomplete from "react-autocomplete";
import Popup from "reactjs-popup";
import srcicon from "../../../assets/images/search.svg";

import {
  matchStocks,
  matchCountry,
  matchSector,
  matchCompany,
  matchSubIndustry,
  matchSubRegion,
  matchRegion,
  matchGeography,
} from "../investment-intellegence-screener/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Gcd_Company_Screener extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      company_status: "",
      head_office_city: "",
      companyand_data: "",
      items: [],
      country: [],
      sector_data: "",
      country_name: "",
      sector: [],
      sector_name: "",
      regions: [],
      region: "",
      region_name: "",
      region_and: "",
      subregion: [],
      sub_region: "",
      subindustry: "",
      comapany_name: "",
      shariahcompliantfinancing: "",
      complete_financing: [],
      sub_industry: [],
    };
    this.getCompanyData = this.getCompanyData.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.handleLastDate = this.handleLastDate.bind(this);
    this.handleLastDays = this.handleLastDays.bind(this);
    this.onChange = this.onChange.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
  }
  closeSearch = (company) => {
    console.log("Search News", company);
    this.setState({
      company_name: company,
    });
    this.getData(company, this.state.old_route, 0, true);
  };
  handleShow = (id) => {
    id = id.indexOf("&") != -1 ? id.replace("&", "%26") : id;
    this.setState({
      comapany_name: id,
      companyand_data: id.indexOf("%26") != -1 ? id.replace("%26", "&") : id,
    });
  };
  keyPress(e) {
    if (e.keyCode == 13) {
      this.getData(e.target.value, "", 0, false);
      console.log(e.target.value, "Target Value");
    }
  }

  handleClick = (item) => {
    this.props.applyFilter(
      "&company=" +
        this.state.comapany_name +
        "&country=" +
        this.state.country_name +
        "&sector=" +
        this.state.sector_name +
        "&headofficecity=" +
        this.state.head_office_city +
        "&subindustry=" +
        this.state.subindustry +
        "&region=" +
        this.state.region_name +
        "&sub_region=" +
        this.state.sub_region +
        "&shariahcompliant=" +
        this.state.shariahcompliantfinancing +
        "&status=" +
        this.state.company_status
    );
  };

  refreshButton = () => {
    this.setState({
      title_name: "",
      comapany_name: "",
      country_name: "",
      sector_name: "",
      gender_name: "",
      email_data: "",
      name: "",
    });
  };

  onChange = (date) => this.setState({ date });
  handleLastDays = (e) => {
    console.log(Number.isInteger(e), e);
    if (Number.isInteger(parseInt(e))) {
      this.setState({
        last_days: e,
        startDate: "",
        start_date: "",
        end_date: "",
        endDate: "",
      });
    }
  };

  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };

  handleChange = (date) => {
    if (date !== null) {
      console.log(
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        "Test"
      );
      this.setState({
        start_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        startDate: date,
        last_days: "",
      });
    } else {
      this.setState({
        start_date: "",
        startDate: "",
        last_days: "",
      });
    }
  };
  handleLastDate = (date) => {
    if (date !== null) {
      console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
      this.setState({
        last_days: "",
        end_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        endDate: date,
      });
    } else {
      this.setState({
        last_days: "",
        end_date: "",
        endDate: "",
      });
    }
  };

  getCompanyData = (region, markets) => {
    var params = "";
    if (region != null) {
      params = "?region=" + region;
    }

    if (markets != null && params != "") {
      params += "&by_market=" + markets;
    }

    if (markets != null && params == "") {
      params = "?by_market=" + markets;
    }
    console.log(params, 91);
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ company: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/bymarkets/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ by_markets: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/shariahcompliantfinancing/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ complete_financing: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/subregman/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ subregion: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/" + params, {})
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/regman/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ regions: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/sectorman/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ sector: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/companymanagementprivatenew/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ items: res.data.result });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/companysubindustry/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ sub_industry: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
  };

  componentDidMount = () => {
    this.getCompanyData(this.props.byRegion, this.props.byMarkets);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
    window.scrollTo(0, 0);

    var current_route = "";
    if (current_route == undefined) {
      current_route = "";
    }
    this.setState({
      old_route: current_route,
    });

    this.getData("", current_route, 1, true);
  };
  getData = (company, route, page, is_first) => {
    api({
      url:
        "https://api.analyzemarkets.com/companymanagementprivatenew/?name=" +
        route +
        "&start=" +
        page +
        "&search=" +
        company,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Company Data");
      this.setState({
        items: res.data.result,
        page: res.data.count,
        current: page,
        is_first: false,
      });
      if (
        this.state.company_name != "" &&
        res.data.count != res.data.totalcount
      ) {
        this.setState({
          status1: true,
        });
      }
      if (res.data.count === res.data.totalcount) {
        this.setState({
          status1: false,
        });
      }

      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
        if (res.data.count === 0) {
          this.setState({
            nosearch: true,
            search: false,
          });
        }
        if (res.data.count != 0) {
          this.setState({
            search: true,
            nosearch: false,
          });
        }
      }
      console.log(this.state.count + "Count Data");
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.byRegion !== null &&
      this.state.region !== this.props.byRegion
    ) {
      this.setState({
        region: this.props.byRegion,
        company: [],
        country: [],
        deal: [],
      });
      console.log(this.props.byRegion, 138);
      this.getCompanyData(this.props.byRegion, null);
    }
    if (
      this.props.byMarkets !== null &&
      this.state.by_market !== this.props.byMarkets
    ) {
      this.setState({
        by_market: this.props.byMarkets,
        company: [],
        country: [],
        deal: [],
      });
      this.getCompanyData(null, this.props.byMarkets);
    }
  };
  render() {
    return (
      <Fragment>
        <Container>
          <Form className="">
            <div className="iis greyborder2px">
              <h4>Global Company Database Screener</h4>

              <div className="pdg15">
                <div className="form-group autoc">
                  <Autocomplete
                    value={
                      this.state.comapany_name.indexOf("%26") != -1
                        ? this.state.companyand_data
                        : this.state.comapany_name
                    }
                    inputProps={{
                      id: "company-autocomplete",
                      placeholder: "Select Company",
                    }}
                    wrapperStyle={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onClick={() => {
                      this.handleCompany("region");
                    }}
                    //applyCompanyFilters={this.handleFilter}
                    items={this.state.company}
                    shouldItemRender={matchCompany}
                    getItemValue={(item) => item.name}
                    onChange={(event, comapany_name) =>
                      this.setState({ comapany_name })
                    }
                    onSelect={(comapany_name) =>
                      this.setState({
                        comapany_name:
                          comapany_name.indexOf("&") != -1
                            ? comapany_name.replace("&", "%26")
                            : comapany_name,
                        companyand_data:
                          comapany_name.indexOf("%26") != -1
                            ? comapany_name.replace("%26", "&")
                            : comapany_name,
                      })
                    }
                    renderMenu={(children) => (
                      <div className="menu c-dropdown">{children}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "item-highlighted" : ""
                        }`}
                        key={item.name}
                      >
                        {item.name}
                      </div>
                    )}
                  />
                </div>{" "}
                <Row>
                  {" "}
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={
                          this.state.region_name.indexOf("%26") != -1
                            ? this.state.region_and
                            : this.state.region_name
                        }
                        inputProps={{
                          id: "region-autocomplete",
                          placeholder: "By Region",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.regions}
                        getItemValue={(item) => item.region}
                        onChange={(event, region_name) =>
                          this.setState({ region_name })
                        }
                        shouldItemRender={matchRegion}
                        onSelect={(region_name) =>
                          this.setState({
                            region_name:
                              region_name.indexOf("&") != -1
                                ? region_name.replace("&", "%26")
                                : region_name,
                            region_and:
                              region_name.indexOf("%26") != -1
                                ? region_name.replace("%26", "&")
                                : region_name,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.region}
                          >
                            {item.region}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.sub_region}
                        inputProps={{
                          id: "subregion-autocomplete",
                          placeholder: "By Sub-Region",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.subregion}
                        getItemValue={(item) => item.sub_region}
                        shouldItemRender={matchSubRegion}
                        onChange={(event, sub_region) =>
                          this.setState({ sub_region })
                        }
                        onSelect={(sub_region) => this.setState({ sub_region })}
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.sub_region}
                          >
                            {item.sub_region}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group autoc">
                      <Autocomplete
                        value={this.state.country_name}
                        inputProps={{
                          id: "country-autocomplete",
                          placeholder: "By country",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.country}
                        getItemValue={(item) => item.country}
                        onChange={(event, country_name) =>
                          this.setState({ country_name })
                        }
                        shouldItemRender={matchCountry}
                        onSelect={(country_name) =>
                          this.setState({ country_name })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.country}
                          >
                            {item.country}
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="form-group autoc">
                      <Form.Control
                        name="head_office_city"
                        type="text"
                        placeholder="By Head office city"
                        onChange={this.changeTerm}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      className="form-group autoc"
                      //className={classes.root}
                    >
                      <Autocomplete
                        required
                        value={this.state.sector_name}
                        inputProps={{
                          id: "sector-autocomplete",
                          placeholder: "By Sector",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.sector}
                        getItemValue={(item) => item.sector}
                        shouldItemRender={matchSector}
                        onChange={(event, sector_name) =>
                          this.setState({
                            sector_name,
                            sector_data: null,
                          })
                        }
                        onSelect={(sector_name) =>
                          this.setState({
                            sector_name,
                            sector_data: sector_name,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.sector}
                          >
                            {item.sector}
                          </div>
                        )}
                      />
                    </div>{" "}
                  </Col>{" "}
                  <Col>
                    {/* <Select
                                closeMenuOnSelect={false}
                                placeholder={<div>Select Sub-Industry*</div>}
                                components={animatedComponents}
                                name="companysubindustry"
                                onChange={this.changeTermMultiSelects}
                                isMulti
                                options={subIn}
                              /> */}

                    <Form.Control
                      as="select"
                      name="subindustry"
                      placeholder="By Industry*"
                      required
                      onChange={this.changeTerm}
                    >
                      <option value="">By Industry</option>
                      {this.state.sub_industry.map((item, i) => (
                        <option
                          key={i}
                          value={
                            this.state.sector_data === item.sector
                              ? item.companysubindustry
                              : "null"
                          }
                        >
                          {this.state.sector_data === item.sector
                            ? item.companysubindustry
                            : ""}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      className="form-group autoc"
                      //className={classes.root}
                    >
                      <Autocomplete
                        required
                        value={this.state.shariahcompliantfinancing}
                        inputProps={{
                          id: "Shariah-autocomplete",
                          placeholder: "By Shariah-compliant",
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={this.state.complete_financing}
                        getItemValue={(item) => item.type}
                        shouldItemRender={matchGeography}
                        onChange={(event, shariahcompliantfinancing) =>
                          this.setState({
                            shariahcompliantfinancing,
                          })
                        }
                        onSelect={(shariahcompliantfinancing) =>
                          this.setState({
                            shariahcompliantfinancing,
                          })
                        }
                        renderMenu={(children) => (
                          <div className="menu c-dropdown">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.type}
                          >
                            {item.type}
                          </div>
                        )}
                      />
                    </div>{" "}
                  </Col>

                  <Col>
                    <Form.Control
                      as="select"
                      name="company_status"
                      onChange={this.changeTerm}
                      // placeholder="Are you Authorized to create this deal"
                    >
                      <option value="">By company status</option>
                      <option value="True">Active</option>
                      <option value="False">Inactive</option>
                    </Form.Control>
                  </Col>
                </Row>
                <div className="d-flex justify-content-between">
                  <Link
                    className="btn btn-blue"
                    to={"/en/global_company_search/"}
                    onClick={() => {
                      this.handleClick("region");
                    }}
                  >
                    Apply
                  </Link>
                  <Link
                    to={"/en/global_company_search/"}
                    className="btn btn-blue"
                    onClick={this.refreshButton}
                  >
                    Reset
                  </Link>
                </div>
              </div>
            </div>
          </Form>
        </Container>
      </Fragment>
    );
  }
}

export default Gcd_Company_Screener;
