import React, { Component } from "react";
import Logo from "../logo/logo";
import Topnav from "../../global/topnav/topnav";
import Language from "../../global/language/language";
import Search from "../../global/searchtop/search";
import SearchNew from "../../global/searchtop/searchnew";
import Usernav from "../../global/usernav/usernav";
import "./header.css";
import axios from "axios";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onLoad: false,
      id: "",
      email: "",
      isLoading: false,
      logindata: "",
      emailresponse: "",
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response1 = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    const id = localStorage.getItem("id");
    this.setState({
      email,
      rememberMe,
      id,
      response1,
      isLoading: true,
      token,
      user,
      action,
    });
  };

  render() {
    return (
      <header>
        <div className="top--bar">
          <div className="container">
            <div className="d-flex align-items-center justify-content-end">
              <Topnav />

              <ul className="navbar-nav mr-lg-2">
                <li className="nav-item nav-lang d-lg-block"></li>
              </ul>
              {this.state.isLoading && (
                <ul className="navbar-nav navbar-nav-right">
                  <li className="nav-item nav-search d-lg-block">
                    <SearchNew />
                  </li>
                </ul>
              )}

              <ul className="navbar-nav ml-auto">
                <li className="nav-item d-lg-block">
                  <Usernav />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Logo />
      </header>
    );
  }
}

export default Header;
