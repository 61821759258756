import React, { Component } from "react";
import { Card } from "react-bootstrap/";
import "./offers-widget.css";

class Offerwidget extends Component {
  render() {
    return (
      <Card className="offers-sec mb-4">
        <Card.Body>
          <Card.Title>
            AnalyzeMarkets - one of a kind investment intelligence platform that
            offers:
          </Card.Title>
          <ul className="ticklist">
            <li>
              <strong>Free Global Investment Intelligence</strong>
              <ul>
                <li>
                  <i>
                    Covering major Developed and Emerging/Frontier economies
                    (over 100 countries)
                  </i>
                </li>
                <li>
                  <i>
                    Covering all Regions (Americas, Europe, Asia & Pacific,
                    Middle East, and Africa)
                  </i>
                </li>
                <li>
                  <i>
                    Covering all Sectors (including Industrials, Consumer,
                    Financials, IT, Communication, Real Estate, and others)
                  </i>
                </li>
                <li>
                  <i>
                    Covering major Categories (including M&A, Venture Capital,
                    IPO, Real Estate, Private Equity, Fundraising, and others)
                  </i>
                </li>
              </ul>
            </li>
            <li>Free Daily, Weekly Briefs and Podcasts</li>
            <li>Free App (iOS and Android)</li>
          </ul>
        </Card.Body>
      </Card>
    );
  }
}

export default Offerwidget;
