import React, {Component} from 'react';
import {
    Container,
    Row,
    Col,
    Accordion,
    Card,
    Button
} from 'react-bootstrap/';
import './careers.css';
import {Helmet} from "react-helmet";

class Careers extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }
    render()
    {
        return <Container>
            <Helmet>
                <title>Careers at AnalyzeMarkets</title>
                <meta name="description" content="We are an equal opportunity employer and value diversity at our company. Apply if you think we're a good match." />
                <meta property="og:title" content="Careers at AnalyzeMarkets" />
                <meta property="og:image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
                <meta name="og:description" content="We are an equal opportunity employer and value diversity at our company. Apply if you think we're a good match."/>
                <meta name="og:url" content="https://www.analyzemarkets.com" />
                <meta name="og:site_name" content="AnalyzeMarkets" />
                <meta name="og:locale" content="en" />
                <meta name="og:type" content="website"/>

                <meta name="image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
                <meta itemprop="name" content="Careers at AnalyzeMarkets" />

                <meta itemprop="description" content="We are an equal opportunity employer and value diversity at our company. Apply if you think we're a good match." />
                <meta itemprop="image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />

                <meta name="twitter:card" content="summary_large_image" />

                <meta name="twitter:title" content="Careers at AnalyzeMarkets" />

                <meta name="twitter:description" content="We are an equal opportunity employer and value diversity at our company. Apply if you think we're a good match." />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta name="twitter:image" content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg" />
            </Helmet>
            <Row>
                <Col md={12}>
                    <hr/>
                    <h2 className="text-uppercase mb-2">Careers at Finance Arabia</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                        bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                        commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                        pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.Lorem
                        ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
                        laoreet. Proin gravida</p>
                    <Accordion className="ecollasp" defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <div className="jd-overview">
                                    <h4>Market Data Analyst</h4>
                                    <span className="location">UAE</span>
                                    <span className="experience">3-5 yrs experience</span>
                                    <span className="posteddt">Posted 1 day ago</span>
                                </div>
                                <div className="jobact">
                                    <div className="noopening mr-4">
                                        <span>2</span>
                                        Openings
                                    </div>
                                    <div>
                                        <Accordion.Toggle as={Button} variant="blue btn-lg mr-4" eventKey="0">
                                            View
                                        </Accordion.Toggle>
                                        <Button variant="blue btn-lg">Apply Now</Button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <h3>Job Description</h3>
                                    <div className="bdrpdg">
                                        <ul className="jdpoints">
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                        </ul>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <div className="jd-overview">
                                    <h4>Market Data Analyst</h4>
                                    <span className="location">UAE</span>
                                    <span className="experience">3-5 yrs experience</span>
                                    <span className="posteddt">Posted 1 day ago</span>
                                </div>
                                <div className="jobact">
                                    <div className="noopening mr-4">
                                        <span>2</span>
                                        Openings
                                    </div>
                                    <div>
                                        <Accordion.Toggle as={Button} variant="blue btn-lg mr-4" eventKey="1">
                                            View
                                        </Accordion.Toggle>
                                        <Button variant="blue btn-lg">Apply Now</Button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <h3>Job Description</h3>
                                    <div className="bdrpdg">
                                        <ul className="jdpoints">
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                        </ul>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <div className="jd-overview">
                                    <h4>Market Data Analyst</h4>
                                    <span className="location">UAE</span>
                                    <span className="experience">3-5 yrs experience</span>
                                    <span className="posteddt">Posted 1 day ago</span>
                                </div>
                                <div className="jobact">
                                    <div className="noopening mr-4">
                                        <span>2</span>
                                        Openings
                                    </div>
                                    <div>
                                        <Accordion.Toggle as={Button} variant="blue btn-lg mr-4" eventKey="3">
                                            View
                                        </Accordion.Toggle>
                                        <Button variant="blue btn-lg">Apply Now</Button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <h3>Job Description</h3>
                                    <div className="bdrpdg">
                                        <ul className="jdpoints">
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
                                                bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
                                                commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
                                                dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
                                                pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                        </ul>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </Container>;
    }
}

export default Careers;
