import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import Autocomplete from "react-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

import {
  Row,
  Table,
  Col,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
//import upload from "./upload.php";
import RealEstateSidebar from "../../component/global/create-deal-sidebar/real-estate-sidebar";
import $ from "jquery";
//import Autocomplete from "material-ui/AutoComplete";
//import { makeStyles } from "material-ui/styles";
import { Editor } from "@tinymce/tinymce-react";
//import window from "react-global";
import "../create-deal/create-deal.css";
import { Link } from "react-router-dom";
import Fetch from "../../component/global/CommonComponent/useFetch";

import {
  matchCompany,
  matchSector,
  matchGeography,
} from "../../component/global/investment-intellegence-screener/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Signin from "../../pages/signin/signinIndex";
import { css } from "@emotion/core";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
const animatedComponents = makeAnimated();
const override = css`
  display: table;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
  position: fixed;
  right: 40%;
  top: 20%;
  z-index: 10000;
`;

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});
class Real_Target_Edit extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.onChangePage = this.onChangePage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.changeTermMultiSelect = this.changeTermMultiSelect.bind(this);
    this.changeTermMultiSelects = this.changeTermMultiSelects.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getLabel1 = this.getLabel1.bind(this);
    this.handleFilters = this.handleFilters.bind(this);

    this.state = {
      dealheadline: "",
      propertycity: "",
      dealcodename: "",
      refresh: "",
      status: "",
      filterdata: "",
      sub_type: [],
      labelTabel: false,
      prop_type: [],
      regions: [],
      //editorState: EditorState.createEmpty(),
      user: "",
      lease_type: [],
      itemcurrency: [],
      name_info: "",
      title: "",
      emailentry: "",
      email: "",
      contactnumber: "",
      labelItem: false,
      labelitem1: false,
      youauthorizedcreatedealbehalfcompany: "",
      items: [],
      keyValue: false,
      company: [],
      sector_data: "",
      result: false,
      company_details: [],
      details: [],
      country: [],
      sector: [],
      sector_name: "",
      filter: "",
      descrition: "",
      subIndustry: [],
      old_route: "",
      pageSize: 25,
      count: 0,
      file: "",
      id: "",
      propertyregion: "",
      propertycountry: "",
      propertytype: "",
      propertysubtype: "",
      propertyaddress: "",
      propertyURL: "",
      propertysize: "",
      numberofbuildings: "",
      companydealbriefdescription: "",
      askingprice: "",
      numberofunits: "",
      anchortenants: "",
      yearbuilt: "",
      WAULT: "",
      leasetype: "",
      currentoccupancy: "",
      grossyield: "",
      netinitialyieldcaprate: "",
      file_teaser: [],
      fileother: "",
      filepicture: "",
      file_other: [],
      reportedDate: new Date(),
      reported_date: "",
      loading: false,
    };
    //this.onChange = (editorState) => this.setState({ editorState });
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  notify = () => {
    toast.info("Your Deal is Succesfully Edited", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/gdmrealestatesingle/" +
        this.props.match.params.dealheadline +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, this.state.details.dealbriefdescription, "Real Edit");
      this.setState({
        details: res.data,
        id: res.data.id,
      });
    });
  };
  refreshButton = () => {
    this.setState({
      refresh: true,
    });
  };
  getLabel = () => {
    const { details } = this.state;
    this.setState({
      labelitem1: true,
      labelitem: true,
    });
  };
  getLabel1 = () => {
    const { details } = this.state;
    this.setState({
      labelTabel: true,
    });
  };

  handleState = () => {
    this.setState({
      result: false,
    });
  };
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  changeTermMultiSelect = (sector_name) => {
    const state = this.state;
    state.sector_name = [];
    sector_name.forEach((option) => {
      state.sector_name.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({
      sector_name: state.sector_name,
      sector_data: null,
    });
    console.log(sector_name);
  };
  changeTermMultiSelects = (companysubindustry) => {
    const state = this.state;
    state.companysubindustry = [];
    companysubindustry.forEach((option) => {
      state.companysubindustry.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({ companysubindustry: state.companysubindustry });
    console.log(companysubindustry);
  };
  handleEditorChange = (e) => {
    this.setState({
      companydealbriefdescription: e.target.getContent(),
    });
    console.log("Content was updated:", this.state.companydealbriefdescription);
  };
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      keyValue: true,
    });
    console.log(len, "length");
    console.log(" file:", this.state.file);

    //.log(" file:", event.target.files[0]);
  }
  onSubmit(e) {
    e.preventDefault();
    const { dealheadline } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set("id", this.state.id);

    bodyFormData.set(
      "dealheadline",
      dealheadline == "" ? this.state.details.dealheadline : dealheadline
    );
    bodyFormData.set(
      "dealcodename",
      this.state.dealcodename == ""
        ? this.state.details.dealcodename
        : this.state.dealcodename
    );
    bodyFormData.set(
      "propertyregion",
      this.state.propertyregion == ""
        ? this.state.details.propertyregion
        : this.state.propertyregion
    );
    bodyFormData.set(
      "propertycity",
      this.state.propertycity == ""
        ? this.state.details.propertycity
        : this.state.propertycity
    );
    bodyFormData.set(
      "userdealstatus",
      this.state.status == ""
        ? this.state.details.userdealstatus
        : this.state.status
    );

    bodyFormData.set(
      "propertycountry",
      this.state.propertycountry == ""
        ? this.state.details.propertycountry
        : this.state.propertycountry
    );
    bodyFormData.set(
      "propertytype",
      this.state.propertytype == ""
        ? this.state.details.propertytype
        : this.state.propertytype
    );
    bodyFormData.set(
      "propertysubtype",
      this.state.propertysubtype == ""
        ? this.state.details.propertysubtype
        : this.state.propertysubtype
    );
    bodyFormData.set(
      "propertyaddress",
      this.state.propertyaddress == ""
        ? this.state.details.propertyaddress
        : this.state.propertyaddress
    );
    bodyFormData.set(
      "askingprice",
      this.state.askingprice == ""
        ? this.state.details.askingprice
        : this.state.askingprice
    );
    bodyFormData.set(
      "propertyURL",
      this.state.propertyURL == ""
        ? this.state.details.propertyURL
        : this.state.propertyURL
    );
    bodyFormData.set(
      "propertysize",
      this.state.propertysize == ""
        ? this.state.details.propertysize
        : this.state.propertysize
    );
    bodyFormData.set(
      "numberofbuildings",
      this.state.numberofbuildings == ""
        ? this.state.details.numberofbuildings
        : this.state.numberofbuildings
    );
    bodyFormData.set(
      "numberofunits",
      this.state.numberofunits == ""
        ? this.state.details.numberofunits
        : this.state.numberofunits
    );
    bodyFormData.set(
      "anchortenants",
      this.state.anchortenants == ""
        ? this.state.details.anchortenants
        : this.state.anchortenants
    );
    bodyFormData.set(
      "yearbuilt",
      this.state.yearbuilt == ""
        ? this.state.details.yearbuilt
        : this.state.yearbuilt
    );
    bodyFormData.set(
      "WAULT",
      this.state.WAULT == "" ? this.state.details.WAULT : this.state.WAULT
    );
    bodyFormData.set(
      "leasetype",
      this.state.leasetype == ""
        ? this.state.details.leasetype
        : this.state.leasetype
    );
    bodyFormData.set(
      "currentoccupancy",
      this.state.currentoccupancy == ""
        ? this.state.details.currentoccupancy
        : this.state.currentoccupancy
    );
    bodyFormData.set(
      "grossyield",
      this.state.grossyield == ""
        ? this.state.details.grossyield
        : this.state.grossyield
    );
    bodyFormData.set(
      "netinitialyieldcaprate",
      this.state.netinitialyieldcaprate == ""
        ? this.state.details.netinitialyieldcaprate
        : this.state.netinitialyieldcaprate
    );
    bodyFormData.set(
      "dealbriefdescription",
      this.state.companydealbriefdescription == ""
        ? this.state.details.dealbriefdescription
        : this.state.companydealbriefdescription
    );
    bodyFormData.set(
      "name",
      this.state.name_info == ""
        ? this.state.details.name
        : this.state.name_info
    );
    bodyFormData.set(
      "title",
      this.state.title == "" ? this.state.details.title : this.state.title
    );
    bodyFormData.set(
      "email",
      this.state.emailentry == ""
        ? this.state.details.email
        : this.state.emailentry
    );
    bodyFormData.set(
      "contactnumber",
      this.state.contactnumber == ""
        ? this.state.details.contactnumber
        : this.state.contactnumber
    );
    bodyFormData.set(
      "youauthorizedcreatedealbehalfcompany",
      this.state.youauthorizedcreatedealbehalfcompany == ""
        ? this.state.details.youauthorizedcreatedealbehalfcompany
        : this.state.youauthorizedcreatedealbehalfcompany
    );
    if (this.state.keyValue === true) {
      bodyFormData.set(
        "teaserresentation",
        this.state.file == ""
          ? this.state.details.teaserresentation
          : this.state.file
      );
    }
    if (this.state.keyValue === true) {
      bodyFormData.set(
        "otherdocumentstoupload",
        this.state.fileother == ""
          ? this.state.details.otherdocumentstoupload
          : this.state.fileother
      );
    }
    if (this.state.keyValue === true) {
      bodyFormData.set(
        "projectpicturesvideotoupload",
        this.state.filepicture == ""
          ? this.state.details.projectpicturesvideotoupload
          : this.state.filepicture
      );
    }

    bodyFormData.set("posted_by", this.state.user);

    axios
      .post("https://api.analyzemarkets.com/gdmrealestateform/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.setState({
          loading: true,
        });
        console.log(res.data, "Data");
        if (res.status === 200) {
          this.notify();
          this.setState({
            result: true,
            loading: false,
          });
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };

  componentDidMount = () => {
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });

    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ sector: res.data.results });
      }
    });

    api({
      url: "https://api.analyzemarkets.com/regman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ regions: res.data.results });
      }
    });
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ company: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/reportingcurrency/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ itemcurrency: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }

    api({
      url: "https://api.analyzemarkets.com/companysubindustry/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ subIndustry: res.data });
      }
    });
    api({
      url: "https://api.analyzemarkets.com/propertytype/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ prop_type: res.data });
      }
    });
    axios
      .get("https://api.analyzemarkets.com/subpropertytype/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ sub_type: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/gdmleasetype/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ lease_type: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };

  render() {
    //const classes = useStyles();
    const data = this.state.details;
    const options = [
      { title: "Energy" },
      { title: "Material" },
      { title: "Industrail" },
      { title: "Type" },
    ];
    const sec = this.state.sector;
    const subIn = this.state.subIndustry;
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Fetch applyFilters={this.handleFilters}></Fetch>
              {console.log(data.dealbriefdescription)}
              <Navfilter />
              <Row className="centerAlign">
                <Col className="">
                  <div>
                    <div class="container">
                      <form onSubmit={this.onSubmit}>
                        <div className="iis greyborder2px">
                          <h4 className="text-left">Create a Deal</h4>
                          <div className="pdg15">
                            <fieldset>
                              <legend>
                                <b className="upercase">About Deal</b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="dealheadline"
                                    type="text"
                                    defaultValue={data.dealheadline}
                                    placeholder="Deal Headline* (Max 100 characters)
                                  e.g. Health care company is looking for growth capital investment"
                                    onChange={this.changeTerm}
                                    maxLength="100"
                                  />
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="dealcodename"
                                    type="text"
                                    defaultValue={data.dealcodename}
                                    placeholder="Deal Code Name *e.g. Project Sun"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="propertyregion"
                                    onChange={this.changeTerm}
                                    placeholder="Property Region*"
                                    rows="3"
                                  >
                                    {data.propertyregion !== null ? (
                                      <option
                                        value={data.propertyregion}
                                        className=""
                                      >
                                        {data.propertyregion}
                                      </option>
                                    ) : (
                                      <option value={""} className="">
                                        Property Region *{" "}
                                      </option>
                                    )}
                                    {this.state.regions.map((item, i) => (
                                      <option
                                        key={i}
                                        value={
                                          item.region === data.propertyregion
                                            ? "null"
                                            : item.region
                                        }
                                      >
                                        {item.region}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>

                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="propertycountry"
                                    onChange={this.changeTerm}
                                    placeholder="Property Country*"
                                    rows="3"
                                  >
                                    <option value={data.propertycountry}>
                                      {data.propertycountry}
                                    </option>

                                    <option value="" className="">
                                      Property Country*{" "}
                                    </option>
                                    {this.state.country.map((item, i) => (
                                      <option key={i} value={item.country}>
                                        {item.country}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="propertycity"
                                    type="text"
                                    defaultValue={data.propertycity}
                                    placeholder="Property City"
                                    onChange={this.changeTerm}
                                  />
                                </Col>

                                <Col>
                                  {this.state.labelTabel === false && (
                                    <Form.Control
                                      as="select"
                                      name="propertytype"
                                      placeholder="Property Type*"
                                      rows="3"
                                    >
                                      {data.propertytype !== null ? (
                                        <option
                                          value={data.propertytype}
                                          className=""
                                        >
                                          {data.propertytype}
                                        </option>
                                      ) : (
                                        <option value={""} className="">
                                          Property Type *{" "}
                                        </option>
                                      )}
                                      {this.state.prop_type.map((item, i) => (
                                        <option
                                          key={i}
                                          value={
                                            item.type === data.propertytype
                                              ? "null"
                                              : item.type
                                          }
                                        >
                                          {item.type}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  )}
                                  {this.state.labelTabel && (
                                    <div className="form-group autoc">
                                      <Autocomplete
                                        value={this.state.propertytype}
                                        inputProps={{
                                          id: "company-autocomplete",
                                          placeholder: "Property Type",
                                        }}
                                        wrapperStyle={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                        items={this.state.prop_type}
                                        shouldItemRender={matchGeography}
                                        getItemValue={(item) => item.type}
                                        onChange={(event, propertytype) =>
                                          this.setState({
                                            propertytype,
                                            propertydata: null,
                                          })
                                        }
                                        onSelect={(propertytype) =>
                                          this.setState({
                                            propertytype,
                                            propertydata: propertytype,
                                          })
                                        }
                                        renderMenu={(children) => (
                                          <div className="menu c-dropdown">
                                            {children}
                                          </div>
                                        )}
                                        renderItem={(item, isHighlighted) => (
                                          <div
                                            className={`item ${
                                              isHighlighted
                                                ? "item-highlighted"
                                                : ""
                                            }`}
                                            key={item.type}
                                          >
                                            {item.type}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  )}
                                </Col>
                                <Col>
                                  {/* <Select
                                closeMenuOnSelect={false}
                                placeholder={<div>Select Sub-Industry*</div>}
                                components={animatedComponents}
                                name="companysubindustry"
                                onChange={this.changeTermMultiSelects}
                                isMulti
                                options={subIn}
                              /> */}
                                  {this.state.labelTabel === false && (
                                    <Form.Control
                                      as="select"
                                      name="propertysubtype"
                                      placeholder="Property Sub-type*"
                                    >
                                      <option value={data.propertysubtype}>
                                        {data.propertysubtype}
                                      </option>

                                      <option value="">
                                        {" "}
                                        Property Sub-type*
                                      </option>
                                      {this.state.sub_type.map((item, i) => (
                                        <option key={i} value={item.type}>
                                          {item.type}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  )}

                                  {this.state.labelTabel && (
                                    <Form.Control
                                      as="select"
                                      name="propertysubtype"
                                      placeholder="Property Sub-type*"
                                      onChange={this.changeTerm}
                                    >
                                      <option value="">
                                        {" "}
                                        Property Sub-type*
                                      </option>
                                      {this.state.sub_type.map((item, i) => (
                                        <option
                                          key={i}
                                          value={
                                            this.state.propertydata ===
                                            item.propertytype
                                              ? item.type
                                              : "null"
                                          }
                                        >
                                          {this.state.propertydata ===
                                          item.propertytype
                                            ? item.type
                                            : "null"}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  )}
                                </Col>
                                <span onClick={this.getLabel1}>
                                  <i
                                    class="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="propertyaddress"
                                    defaultValue={data.propertyaddress}
                                    onChange={this.changeTerm}
                                    placeholder="Property Address"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="askingprice"
                                    type="number"
                                    defaultValue={data.askingprice}
                                    onChange={this.changeTerm}
                                    placeholder="Property Asking Price (in USD mn)"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="propertyURL"
                                    defaultValue={data.propertyURL}
                                    onChange={this.changeTerm}
                                    placeholder="Property URL"
                                  />
                                </Col>
                              </Row>
                              <div className="form-group">
                                <Row className="">
                                  <Col>
                                    <Editor
                                      initialValue={data.dealbriefdescription}
                                      value={data.dealbriefdescription}
                                      apiKey="l7d3ab4ybjfthcy2c4ya6vsj9rlub27d3sdqk433oyis6ild"
                                      init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                          "advlist autolink lists link image",
                                          "charmap print preview anchor help",
                                          "searchreplace visualblocks code",
                                          "insertdatetime media table paste wordcount",
                                        ],
                                        toolbar:
                                          "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help",
                                      }}
                                      maxLength="200"
                                      onChange={this.handleEditorChange}
                                    />

                                    {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                  as="textarea"
                                  name="companydealbriefdescription"
                                  rows="7"
                                  maxLength="100"
                                  onChange={this.changeTerm}
                                  placeholder="Company/Deal Brief Description* (Maximum 100 words)"
                                />
                              </Form.Group> */}
                                  </Col>{" "}
                                </Row>
                              </div>
                              <legend>
                                {" "}
                                <b className="upercase">
                                  Key Investment Highlights{" "}
                                </b>
                              </legend>

                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="propertysize"
                                    onChange={this.changeTerm}
                                    defaultValue={data.propertysize}
                                    placeholder="Property size (sq m)"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="numberofbuildings"
                                    defaultValue={data.numberofbuildings}
                                    onChange={this.changeTerm}
                                    placeholder="Number of Buildings"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="numberofunits"
                                    defaultValue={data.numberofunits}
                                    onChange={this.changeTerm}
                                    placeholder="Number of Units"
                                  />
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="anchortenants"
                                    defaultValue={data.anchortenants}
                                    onChange={this.changeTerm}
                                    placeholder="Anchor Tenants"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="leasetype"
                                    placeholder="Lease Type"
                                    onChange={this.changeTerm}
                                  >
                                    <option value={data.leasetype}>
                                      {data.leasetype}
                                    </option>

                                    <option value=""> Lease Type*</option>
                                    {this.state.lease_type.map((item, i) => (
                                      <option key={i} value={item.type}>
                                        {item.type}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="yearbuilt"
                                    defaultValue={data.yearbuilt}
                                    onChange={this.changeTerm}
                                    placeholder="Year Built"
                                  />
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="WAULT"
                                    defaultValue={data.WAULT}
                                    onChange={this.changeTerm}
                                    placeholder="WAULT"
                                  />
                                </Col>

                                <Col>
                                  <Form.Control
                                    name="currentoccupancy"
                                    defaultValue={data.currentoccupancy}
                                    placeholder="Current Occupancy"
                                  />
                                </Col>

                                <Col>
                                  <Form.Control
                                    name="grossyield"
                                    defaultValue={data.grossyield}
                                    type="number"
                                    step="any"
                                    onChange={this.changeTerm}
                                    placeholder="Gross Yield (%)"
                                  />
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="netinitialyieldcaprate"
                                    type="number"
                                    step="any"
                                    defaultValue={data.netinitialyieldcaprate}
                                    onChange={this.changeTerm}
                                    placeholder="Net Initial Yield / Cap rate (%)"
                                  />
                                </Col>
                              </Row>

                              <legend>
                                <b className="upercase">
                                  Requirement Documents
                                </b>
                              </legend>
                              <div className="form-group">
                                {this.state.labelitem1 && (
                                  <label> Teaser/Presentation</label>
                                )}

                                <Row>
                                  {this.state.labelitem1 === false && (
                                    <Col>
                                      <label> Teaser/Presentation</label>
                                      <div class="custom-file">
                                        <input
                                          type="file"
                                          multiple
                                          class="custom-file-input"
                                          id="inputGroupFile01"
                                          name="file"
                                          aria-describedby="inputGroupFileAddon01"
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="inputGroupFile01"
                                        >
                                          {data.teaserresentationname}
                                        </label>
                                      </div>{" "}
                                    </Col>
                                  )}
                                  {this.state.labelitem1 && (
                                    <Col>
                                      <input
                                        type="file"
                                        id="myfile"
                                        name="file"
                                        onChange={this.handleChange}
                                      />{" "}
                                    </Col>
                                  )}
                                  {console.log(this.state.file, "File Name")}
                                  <span onClick={this.getLabel}>
                                    <i
                                      class="fa fa-pencil pencil1"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Row>
                              </div>
                              <div className="form-group">
                                {this.state.labelitem1 && (
                                  <label>Other Documents to upload:</label>
                                )}
                                <Row>
                                  {this.state.labelitem1 === false && (
                                    <Col>
                                      <label>Other Documents to upload:</label>
                                      <div class="custom-file">
                                        <input
                                          type="file"
                                          multiple
                                          class="custom-file-input"
                                          id="inputGroupFile01"
                                          name="fileother"
                                          aria-describedby="inputGroupFileAddon01"
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="inputGroupFile01"
                                        >
                                          {data.otherdocumentstouploadname}
                                        </label>
                                      </div>{" "}
                                      {console.log(
                                        " file count:",
                                        this.state.fileother
                                      )}
                                    </Col>
                                  )}
                                  {this.state.labelitem1 && (
                                    <Col>
                                      <input
                                        type="file"
                                        id="myfile"
                                        name="fileother"
                                        onChange={this.handleChange}
                                      />{" "}
                                    </Col>
                                  )}
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                  <span onClick={this.getLabel}>
                                    <i
                                      class="fa fa-pencil pencil1"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Row>{" "}
                              </div>
                              <div className="form-group">
                                {this.state.labelitem1 && (
                                  <label> Picture and video to upload</label>
                                )}{" "}
                                <Row>
                                  {this.state.labelitem1 === false && (
                                    <Col>
                                      <label>
                                        {" "}
                                        Picture and video to upload
                                      </label>
                                      <div class="custom-file">
                                        <input
                                          type="file"
                                          multiple
                                          class="custom-file-input"
                                          id="inputGroupFile01"
                                          name="filepicture"
                                          aria-describedby="inputGroupFileAddon01"
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="inputGroupFile01"
                                        >
                                          {
                                            data.projectpicturesvideotouploadname
                                          }
                                        </label>
                                      </div>{" "}
                                      {console.log(
                                        " file count:",
                                        this.state.file
                                      )}
                                    </Col>
                                  )}
                                  {this.state.labelitem1 && (
                                    <Col>
                                      <input
                                        type="file"
                                        id="myfile"
                                        name="filepicture"
                                        onChange={this.handleChange}
                                      />{" "}
                                    </Col>
                                  )}
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                  <span onClick={this.getLabel}>
                                    <i
                                      class="fa fa-pencil pencil1"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Row>
                              </div>

                              <legend>
                                <b className="upercase">
                                  Contact Information For Potential Investors
                                </b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="name_info"
                                    type="text"
                                    defaultValue={data.name}
                                    placeholder="Name*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="title"
                                    defaultValue={data.title}
                                    type="text"
                                    placeholder="Title*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="emailentry"
                                    type="email"
                                    defaultValue={data.email}
                                    placeholder="Email*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>

                                <Col>
                                  <Form.Control
                                    name="contactnumber"
                                    type="tel"
                                    defaultValue={data.contactnumber}
                                    placeholder="Contact number*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <label>
                                    Are you authorized to create this deal on
                                    behalf of the Company*:{" "}
                                  </label>
                                  <Form.Control
                                    as="select"
                                    name="youauthorizedcreatedealbehalfcompany"
                                    defaultValue={
                                      data.youauthorizedcreatedealbehalfcompany
                                    }
                                    onChange={this.changeTerm}
                                    // placeholder="Are you Authorized to create this deal"
                                  >
                                    {data.youauthorizedcreatedealbehalfcompany !==
                                    null ? (
                                      <option
                                        value={
                                          data.youauthorizedcreatedealbehalfcompany
                                        }
                                        className=""
                                      >
                                        {
                                          data.youauthorizedcreatedealbehalfcompany
                                        }
                                      </option>
                                    ) : (
                                      <option value={""} className="">
                                        Are you authorized to create the deal
                                      </option>
                                    )}
                                    <option
                                      value={
                                        "Yes" ===
                                        data.youauthorizedcreatedealbehalfcompany
                                          ? "null"
                                          : "Yes"
                                      }
                                    >
                                      Yes
                                    </option>
                                    <option
                                      value={
                                        "No" ===
                                        data.youauthorizedcreatedealbehalfcompany
                                          ? "null"
                                          : "No"
                                      }
                                    >
                                      No
                                    </option>
                                  </Form.Control>
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <label>Deal Status</label>

                                  <Form.Control
                                    as="select"
                                    name="status"
                                    onChange={this.changeTerm}
                                  >
                                    {data.userdealstatus !== null ? (
                                      <option
                                        value={data.userdealstatus}
                                        className=""
                                      >
                                        {data.userdealstatus}
                                      </option>
                                    ) : (
                                      <option value={""} className="">
                                        Deal Status
                                      </option>
                                    )}

                                    <option
                                      value={
                                        "Active" === data.userdealstatus
                                          ? "null"
                                          : "Active"
                                      }
                                    >
                                      Active
                                    </option>
                                    <option
                                      value={
                                        "Inactive" === data.userdealstatus
                                          ? "null"
                                          : "Inactive"
                                      }
                                    >
                                      Inactive
                                    </option>
                                  </Form.Control>
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col sm={3}>
                                  <Button
                                    variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                    type="submit"
                                    size="sm"
                                  >
                                    Publish Deal
                                  </Button>
                                </Col>
                                <Col>
                                  <Link
                                    className="btn btn-blue mt-4 mb-4 shadow"
                                    onClick={this.refreshButton}
                                  >
                                    Clear
                                  </Link>
                                </Col>
                              </Row>
                              {this.state.refresh && window.location.reload()}
                              <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                              />
                            </fieldset>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default Real_Target_Edit;
