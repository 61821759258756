import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import "../v&c_dealmonitor/v&c_dealmonitor.css";
import { Row, Table, Col, Container } from "react-bootstrap";
import MA_SideBar from "../../component/global/vc_deals_sidebar/vc_deals_sidebar";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
import Pagination from "../../component/global/pagination/index";
import "../../pages/press-release/press-release.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Moment from "react-moment";
import CreateSidebar from "../../component/global/create-deal-sidebar/create-deal-sidebar";
import Fetch from "../../component/global/CommonComponent/useFetch";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Create_Deals_Manage extends Component {
  constructor(props) {
    super(props);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleFilters = this.handleFilters.bind(this);

    this.state = {
      items: [],
      items3: [],
      items4: [],
      items5: [],
      items6: [],
      items2: [],
      items1: [],
      show_100: false,
      exampleItems: [],
      company: [],
      page: 0,
      current: 0,
      country: [],
      filter: "",
      old_route: "",
      pageSize: 3,
      count: 0,
      filterdata: "",
      is_first: true,
      token: "",
      response: "",
      user: "",
      count: "",
    };
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.getData(this.state.old_route, filterdata, 1, true, this.state.user);
  };
  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      // this.getData("", this.state.filter, page, false);
      if (this.state.filterdata !== "") {
        this.getData("", this.state.filterdata, page, false, this.state.user);
      }

      console.log(55);
    }
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
    if (this.state.filterdata !== "") {
      this.getData(current_route, this.state.filterdata, 1, true, user);
    }
  };

  getData = (route, filterdata, page, is_first, user) => {
    api({
      url:
        "https://api.analyzemarkets.com/globaldealsmarketplacealllist/?posted_by=" +
        user +
        "&start=" +
        page +
        "" +
        filterdata,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res, "Seeking Edit List");
      this.setState({
        items: res.data.GDM_Seeking_Buyer,
        items1: res.data.GDM_Seeking_Target,
        items2: res.data.GDM_Real_Estate,
        items3: res.data.GDM_Fundraising,
        items4: res.data.GDM_Debt,
        items5: res.data.GDM_JV,
        items6: res.data.GDM_VC,
        page: res.data.totalcount,
        user: user,
        current: page,
        is_first: false,
      });
      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
      }
      console.log(res.data.length + "Count Data");
    });
  };

  render() {
    const { data } = this.state;

    return (
      <Fragment>
        <Header />
        <Container>
          <Navfilter />
          <Fetch applyFilters={this.handleFilters}></Fetch>

          <Row>
            {/* <Col className="w-30p p-4">
              <div className="widget"></div>
            </Col> */}

            <Col sm={12}>
              <div>
                <div class="container">
                  <div>
                    <Table
                      striped
                      bordered
                      hover
                      id="table-id"
                      className="tblnoline grid"
                      size="sm"
                      responsive="lg"
                    >
                      <thead>
                        <tr>
                          <th index="0">
                            <span className="table-header">Deal Detail</span>{" "}
                            <div class=""></div>
                          </th>
                          <th index="0">
                            <span className="table-header" width="100">
                              Deal Type
                            </span>{" "}
                            <div class=""></div>
                          </th>

                          <th index="0">
                            <span className="table-header">Deal Headline</span>{" "}
                            <div class=""></div>
                          </th>

                          <th index="1" width="150">
                            <span className="table-header">Deal Code Name</span>

                            <div class=""></div>
                          </th>
                          <th index="2">
                            <span className="table-header"> Company</span>

                            <div class=""></div>
                          </th>

                          <th index="4">
                            <span className="table-header">
                              Internal Reference No.
                            </span>

                            <div class=""></div>
                          </th>
                          <th index="4">
                            <span className="table-header">Status</span>

                            <div class=""></div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.items.map((item, key) => (
                          <tr>
                            <td>
                              <span>
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/seeking_buyers_details_view/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    View
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">View </u>
                                  </Link>
                                )}
                              </span>
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/create-deal-manage-edit/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    Edit
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">Edit </u>
                                  </Link>
                                )}
                              </span>
                            </td>
                            <td>{item.dealtype}</td>

                            <td className="word-wrap">{item.dealheadline}</td>
                            <td>{item.dealcodename}</td>
                            <td>
                              {/* {item.companyname !== null ? (
                                <Link
                                  to={
                                    "/en/global-company-database-details/" + item.company.api_url + "/"
                                  }
                                >
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.companyname}
                                  </u>
                                </Link>
                              ) : (
                                <Link>
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.company.name}
                                  </u>
                                </Link>
                              )} */}
                              {item.companyname}
                            </td>
                            <td>{item.dealinternalreferencenumber}</td>

                            <td>{item.status}</td>

                            {/* <td>
                              {item.GDDvcadvisors.map(
                                (acquirer, key) => (
                                  <div>
                                    {acquirer.acquirer_parent == null
                                      ? null
                                      : acquirer.acquirer_parent}
                                  </div>
                                )
                              )}
                            </td> */}
                          </tr>
                        ))}
                        {this.state.items1.map((item, key) => (
                          <tr>
                            <td>
                              <span>
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/seeking_target_details_view/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    View
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">View </u>
                                  </Link>
                                )}
                              </span>
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/create-deal-manage-target-edit/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    Edit
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">Edit </u>
                                  </Link>
                                )}
                              </span>
                            </td>
                            <td>{item.dealtype}</td>

                            <td className="word-wrap">{item.dealheadline}</td>
                            <td>{item.dealcodename}</td>
                            <td>
                              {/* {item.companyname !== null ? (
                                <Link
                                  to={
                                    "/en/global-company-database-details/" + item.company.api_url + "/"
                                  }
                                >
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.companyname}
                                  </u>
                                </Link>
                              ) : (
                                <Link>
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.company.name}
                                  </u>
                                </Link>
                              )} */}
                              {item.acquirercompany}
                            </td>
                            <td>{item.dealinternalreferencenumber}</td>

                            <td>{item.status}</td>

                            {/* <td>
                              {item.GDDvcadvisors.map(
                                (acquirer, key) => (
                                  <div>
                                    {acquirer.acquirer_parent == null
                                      ? null
                                      : acquirer.acquirer_parent}
                                  </div>
                                )
                              )}
                            </td> */}
                          </tr>
                        ))}
                        {this.state.items2.map((item, key) => (
                          <tr>
                            <td>
                              <span>
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/real_estate_detail_view/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    View
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">View </u>
                                  </Link>
                                )}
                              </span>
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/real-estate-manage-edit/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    Edit
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">Edit </u>
                                  </Link>
                                )}
                              </span>
                            </td>
                            <td>{item.dealtype}</td>

                            <td className="word-wrap">{item.dealheadline}</td>
                            <td>{item.dealcodename}</td>
                            <td>
                              {/* {item.companyname !== null ? (
                                <Link
                                  to={
                                    "/en/global-company-database-details/" + item.company.api_url + "/"
                                  }
                                >
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.companyname}
                                  </u>
                                </Link>
                              ) : (
                                <Link>
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.company.name}
                                  </u>
                                </Link>
                              )} */}
                              {item.acquirercompany}
                            </td>
                            <td>{item.dealinternalreferencenumber}</td>

                            <td>{item.status}</td>
                          </tr>
                        ))}
                        {this.state.items3.map((item, key) => (
                          <tr>
                            <td>
                              <span>
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/fundraising_detail_view/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    View
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">View </u>
                                  </Link>
                                )}
                              </span>
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/fundraising_edit/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    Edit
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">Edit </u>
                                  </Link>
                                )}
                              </span>
                            </td>
                            <td>{item.dealtype}</td>

                            <td className="word-wrap">{item.dealheadline}</td>
                            <td>{item.dealcodename}</td>
                            <td>
                              {/* {item.companyname !== null ? (
                                <Link
                                  to={
                                    "/en/global-company-database-details/" + item.company.api_url + "/"
                                  }
                                >
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.companyname}
                                  </u>
                                </Link>
                              ) : (
                                <Link>
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.company.name}
                                  </u>
                                </Link>
                              )} */}
                              {item.acquirercompany}
                            </td>
                            <td>{item.dealinternalreferencenumber}</td>

                            <td>{item.status}</td>
                          </tr>
                        ))}
                        {this.state.items4.map((item, key) => (
                          <tr>
                            <td>
                              <span>
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/debt_detail_view/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    View
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">View </u>
                                  </Link>
                                )}
                              </span>
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/debt-deals-gmd-edit/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    Edit
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">Edit </u>
                                  </Link>
                                )}
                              </span>
                            </td>
                            <td>{item.dealtype}</td>

                            <td className="word-wrap">{item.dealheadline}</td>
                            <td>{item.dealcodename}</td>
                            <td>
                              {/* {item.companyname !== null ? (
                                <Link
                                  to={
                                    "/en/global-company-database-details/" + item.company.api_url + "/"
                                  }
                                >
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.companyname}
                                  </u>
                                </Link>
                              ) : (
                                <Link>
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.company.name}
                                  </u>
                                </Link>
                              )} */}
                              {item.acquirercompany}
                            </td>
                            <td>{item.dealinternalreferencenumber}</td>

                            <td>{item.status}</td>
                          </tr>
                        ))}
                        {this.state.items5.map((item, key) => (
                          <tr>
                            <td>
                              <span>
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/joint-venture-detail-view/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    View
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">View </u>
                                  </Link>
                                )}
                              </span>
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/joint-venture-gmd-edit/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    Edit
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">Edit </u>
                                  </Link>
                                )}
                              </span>
                            </td>
                            <td>{item.dealtype}</td>

                            <td className="word-wrap">{item.dealheadline}</td>
                            <td>{item.dealcodename}</td>
                            <td>
                              {/* {item.companyname !== null ? (
                                <Link
                                  to={
                                    "/en/global-company-database-details/" + item.company.api_url + "/"
                                  }
                                >
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.companyname}
                                  </u>
                                </Link>
                              ) : (
                                <Link>
                                  <u className="underline">
                                    {" "}
                                    {item.company !== null && item.company.name}
                                  </u>
                                </Link>
                              )} */}
                              {item.jointventurepartnercompany}
                            </td>
                            <td>{item.dealinternalreferencenumber}</td>

                            <td>{item.status}</td>
                          </tr>
                        ))}
                        {this.state.items6.map((item, key) => (
                          <tr>
                            <td>
                              <span>
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/vc-deals-view/" + item.api_url + "/"
                                    }
                                  >
                                    View
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">View </u>
                                  </Link>
                                )}
                              </span>
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                {item.api_url != null ? (
                                  <Link
                                    to={
                                      "/en/vc-deals-gmd-edit/" +
                                      item.api_url +
                                      "/"
                                    }
                                  >
                                    Edit
                                  </Link>
                                ) : (
                                  <Link>
                                    <u className="underline">Edit </u>
                                  </Link>
                                )}
                              </span>
                            </td>
                            <td>{item.dealtype}</td>

                            <td className="word-wrap">{item.dealheadline}</td>
                            <td>{item.dealcodename}</td>
                            <td>{}</td>
                            <td>{item.dealinternalreferencenumber}</td>

                            <td>{item.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <div className="products-view__options justify-content-between mt-4">
                    {/* {this.state.count > 10 && (
                    <div className="view-options__control">
                      <label>Results Per Page</label>
                      <div></div>
                    </div>
                  )} */}
                    <Pagination
                      items={this.state.exampleItems}
                      onChangePage={this.onChangePage}
                      pageSize={this.state.pageSize}
                    ></Pagination>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default Create_Deals_Manage;
