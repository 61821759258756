import React, { Component, Fragment, Suspense } from "react";
import { Route, Switch, NavLink, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import HomeAfterLogin from "./pages/homeAfterLogin/homeafterLogin";
import VC_Deals_Monitor_Details from "./pages/v&c_dealmonitor/v&c_dealmonitor_details";
import GlobalDealsDatabase from "./pages/globaldeals-database/globaldeals-database";
import DealsInMakingeDetails from "./pages/m&a_dealmonitor/deals-in-making-detail";
import Fund_Raising_Monitor from "./pages/fund-raising-deals/fund-raising-monitor";
import Fund_Raising_Details from "./pages/fund-raising-deals/fund-raising-deals-detail";
import debt_details from "./pages/debt-deals/debt-details";
import Signin from "./pages/signin/index";
import SignInIndex from "./pages/signin/signinIndex";
import VcDealsSearch from "./pages/madealsearch/vc-deals-search";
import JV_Details from "./pages/jv-deals/jv-details";
import re_details from "./pages/re-deals/re-details";
import FundRaisingSearch from "./pages/madealsearch/fund-raising-search";
import RealEstateSearch from "./pages/madealsearch/real-estate-deals-search";
import ipoMDetails from "./pages/m&a_dealmonitor/ipomonitor_details";
import CreateDealDetail from "./pages/seeking-targets-gmd/create-deal-detail";
import Edit_Seeking_Target_detail from "./pages/seeking-targets-gmd/create-deal-detail";
import Edit_Real_Estate_View from "./pages/real-estate-deal/real-estate-deal-detail";
import Edit_FundRaising_View from "./pages/fund-raising-gdm/fund-raising-detail";
import Edit_DebtDeal_View from "./pages/debt-deal-gdm/debt-deal-gdm-detail";
import Edit_Jv_Deals_View from "./pages/joint-venture/joint-venture-detail";
import Edit_Vc_Deals_View from "./pages/Gdm-Vcdeals/gdm-vc-deals-detail";
import NotFound from "./pages/not-found";

const LoadingMessage = () => "<h1>Loading</h1>";

class Router extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signin: [],
      email: "",
      token: "",
      response: "",
      user: "",
      count: "",
      login: [],
      results: false,

      hasError: false,
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <div className="Router">
        <Switch>
          {this.state.response === null ? (
            <Route path="/:lan/signin" exact="true" component={Signin}></Route>
          ) : (
            ""
          )}
          {this.state.response === null ? (
            <Route
              path="/:lan/signIn/"
              exact="true"
              component={SignInIndex}
            ></Route>
          ) : (
            ""
          )}
        </Switch>

        {this.state.action && this.state.response === this.state.email ? (
          <Switch>
            <Route
              path={"/:lan/seeking_target_details_view/:dealheadline/"}
              exact
              component={Edit_Seeking_Target_detail}
            ></Route>
            <Route
              path={"/:lan/joint-venture-detail-view/:dealheadline/"}
              exact
              component={Edit_Jv_Deals_View}
            ></Route>

            <Route
              path={"/:lan/fundraising_detail_view/:dealheadline/"}
              exact
              component={Edit_FundRaising_View}
            ></Route>
            <Route
              path={"/:lan/vc-deals-view/:dealheadline/"}
              exact
              component={Edit_Vc_Deals_View}
            ></Route>

            <Route
              path={"/:lan/debt_detail_view/:dealheadline/"}
              exact
              component={Edit_DebtDeal_View}
            ></Route>

            <Route
              path={"/:lan/debt-details/:deal/"}
              exact
              component={debt_details}
            ></Route>
            <Route
              path={"/:lan/global-deals-database/"}
              exact
              component={GlobalDealsDatabase}
            ></Route>
            <Route
              path={"/:lan/vc-deals-details/:deal/"}
              exact
              component={VC_Deals_Monitor_Details}
            ></Route>
            <Route
              path={"/:lan/fund-raising-deals/"}
              exact
              component={Fund_Raising_Monitor}
            ></Route>
            <Route
              path={"/:lan/fund-raising-details/:deal/"}
              exact
              component={Fund_Raising_Details}
            ></Route>
            <Route
              path={"/:lan/jv-details/:deal/"}
              component={JV_Details}
              exact
            ></Route>
            <Route
              path={"/:lan/vc-deals-search/"}
              component={VcDealsSearch}
              exact
            ></Route>
            <Route
              path={"/:lan/re-details/:deal/"}
              component={re_details}
              exact
            ></Route>
            <Route
              path={"/:lan/fundraising-search/"}
              component={FundRaisingSearch}
              exact
            ></Route>
            <Route
              path={"/:lan/real-estate-search/"}
              component={RealEstateSearch}
              exact
            ></Route>
            <Route
              path={"/:lan/ipo-monitor-details/:deal/"}
              component={ipoMDetails}
              exact
            ></Route>

            <Route
              path={"/:lan/deals-target-details/:dealheadline/"}
              component={CreateDealDetail}
              exact
            ></Route>
          </Switch>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Router;
