import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap/";
import { Helmet } from "react-helmet";
import Header from "../../component/global/header/index";

class Discliamer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      result: false,
      action: true,
      is_open: false,
      company: [],
    };
  }

  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>AnalyzeMarkets Disclaimer</title>
            <meta
              name="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta property="og:title" content="AnalyzeMarkets Terms of Use" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="AnalyzeMarkets Terms of Use" />

            <meta
              itemprop="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="AnalyzeMarkets Terms of Use" />

            <meta
              name="twitter:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            <Col md={12}>
              <hr />
              <h2 className="text-uppercase mb-4">
                AnalyzeMarkets ltd Disclaimer
              </h2>
              <p>
                <b>Updated Date: 1st November 2019</b>
              </p>
              <p>
                AnalyzeMarkets ltd (AnalyzeMarkets or We) has obtained the
                information provided on analyzemarkets.com (the Site) from
                sources which We believe to be reliable, and We make reasonable
                efforts to ensure that it is accurate. However, the information
                is not intended to provide tax, legal or investment advice. We
                make no representations or warranties in regard to the contents
                of and materials provided on this Site and exclude all
                representations, conditions, and warranties, express or implied
                arising by operation of law or otherwise, to the extent that
                these may not be excluded by law.
              </p>
              <p>
                We shall not be liable in contract, tort (including negligence)
                or otherwise for indirect, special, incidental, punitive or
                consequential losses or damages, or loss of profits, revenue,
                goodwill or anticipated savings or for any financial loss
                whatsoever, regardless of whether any such loss or damage would
                arise in the ordinary course of events or otherwise, or is
                reasonably foreseeable or is otherwise in the contemplation of
                the parties in connection with this Site. No liability is
                excluded to the extent such liability may not be excluded or
                limited by law. Nothing in this statement shall limit or exclude
                our liability for death or personal injury caused by our
                negligence.
              </p>
              <p>
                Whilst We make reasonable attempts to exclude viruses from this
                Site, We cannot guarantee such exclusion and no liability is
                accepted for viruses. You are recommended to take all
                appropriate safeguards before downloading information from this
                Site.
              </p>
              <p>
                No liability is accepted for the contents of any site operated
                by a third party which may be accessed via links from this Site.
                Such links are provided for your convenience only and do not
                imply that We approve or recommend the content of such sites.
              </p>
              <p>
                The information contained in this Site is not intended for
                distribution to, or use by, any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                additional registration requirement within such jurisdiction or
                country
              </p>
              <p>
                We reserve the right to change or update the information on this
                Site, including this statement, at any time by posting revisions
                on this Site.
              </p>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default Discliamer;
