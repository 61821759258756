import React, { Component, Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap/";
import { Link } from "react-router-dom";
import "./contact.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Helmet } from "react-helmet";
import CallImage from "../../assets/images/call-icon.png";
import F1 from "../../assets/images/f1.jpg";
import F2 from "../../assets/images/f2.jpg";
import F3 from "../../assets/images/f3.jpg";
import Header from "../../component/global/header/index";
// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      firstname: null,
      lastname: null,
      company_name: null,
      company_url: null,
      job_title: null,
      country: null,
      contact_number: null,
      free_trial_demonstration: null,
      subject: null,
      message: null,
      result: "",
      country_list: [],
      error: "",
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDropdownChange(e) {
    this.setState({
      country: e.target.value,
    });
  }
  handleSubjectChange(e) {
    this.setState({
      subject: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      error: "",
    });

    var bodyFormData = new FormData();
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("firstname", this.state.firstname);
    bodyFormData.set("lastname", this.state.lastname);
    bodyFormData.set("company_name", this.state.company_name);
    bodyFormData.set("company_url", this.state.company_url);
    bodyFormData.set("job_title", this.state.job_title);
    bodyFormData.set("country", this.state.country);
    bodyFormData.set("contact_number", this.state.contact_number);
    bodyFormData.set("message", this.state.message);
    bodyFormData.set("subject", this.state.subject);
    if (this.state.free_trial_demonstration === "on") {
      bodyFormData.set("free_trial_demonstration", true);
    } else {
      bodyFormData.set("free_trial_demonstration", false);
    }

    axios
      .post("https://api.analyzemarkets.com/contact_us/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log(30);
          this.setState({
            result:
              "Thank you for contacting us. We will respond to you at the earliest. Have a good day.",
          });
        } else {
          this.setState({ result: "Something Went Wrong" });
        }
      })
      .catch((error) => {
        //on error
        // alert("There is an error in API call.");
      });
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);

    api({
      url: "https://api.analyzemarkets.com/country/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ country_list: res.data.results });
      }
    });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>Contact AnalyzeMarkets</title>
            <meta
              name="description"
              content="Please select a subject and send us your message. You can also directly reach us through given contact numbers or send an email directly to the specific department."
            />
            <meta property="og:title" content="Contact AnalyzeMarkets" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Please select a subject and send us your message. You can also directly reach us through given contact numbers or send an email directly to the specific department."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="Contact AnalyzeMarkets" />

            <meta
              itemprop="description"
              content="Please select a subject and send us your message. You can also directly reach us through given contact numbers or send an email directly to the specific department."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="Contact AnalyzeMarkets" />

            <meta
              name="twitter:description"
              content="Please select a subject and send us your message. You can also directly reach us through given contact numbers or send an email directly to the specific department."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>
          <Row className="row-eq-height mt-4">
            <Col lg={6}>
              {this.state.result !== "" && (
                <div className="bdrpdg h-100">
                  <p className=" mb-4 fs12rem">{this.state.result}</p>
                </div>
              )}
              {this.state.result === "" && (
                <div className="bdrpdg h-100">
                  <h2 className=" mb-4">Contact Us</h2>
                  <p>
                    <span className="text-danger">* </span>marked fields are
                    mandatory
                  </p>
                  <Form onSubmit={this.onSubmit.bind(this)}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="FirstName">
                        <Form.Control
                          type="text"
                          placeholder="First Name*"
                          name="firstname"
                          onChange={this.onChange.bind(this)}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="lastName">
                        <Form.Control
                          type="text"
                          placeholder="Last Name*"
                          name="lastname"
                          onChange={this.onChange.bind(this)}
                          required
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="ComapanyName">
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        name="company_name"
                        onChange={this.onChange.bind(this)}
                      />
                    </Form.Group>

                    <Form.Group controlId="JobTitle">
                      <Form.Control
                        type="text"
                        placeholder="Job Title"
                        name="job_title"
                        onChange={this.onChange.bind(this)}
                      />
                    </Form.Group>

                    <Form.Row>
                      <Form.Group as={Col} controlId="ChooseCountry">
                        <Form.Control
                          as="select"
                          onChange={(e) => this.handleDropdownChange(e)}
                          required
                        >
                          <option value="">Select Country*</option>
                          {this.state.country_list.map((item, i) => (
                            <option key={i} value={item.country}>
                              {item.country}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="WorkEmail">
                      <Form.Control
                        type="email"
                        placeholder="Email*"
                        name="email"
                        onChange={this.onChange.bind(this)}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="ContactNumber">
                      <Form.Control
                        type="text"
                        placeholder="Contact Number"
                        name="contact_number"
                        onChange={this.onChange.bind(this)}
                      />
                    </Form.Group>

                    <Form.Row>
                      <Form.Group as={Col} controlId="ChooseCountry">
                        <Form.Control
                          as="select"
                          onChange={(e) => this.handleSubjectChange(e)}
                          required
                        >
                          <option value="">Select Subject*</option>
                          <option value="Sales & Subscription">
                            Sales & Subscription
                          </option>
                          <option value="Client Support">Client Support</option>
                          <option value="Accounts & Billing">
                            Accounts & Billing
                          </option>
                          <option value="Press & Media Enquiries">
                            Press & Media Enquiries
                          </option>
                          <option value="General">General</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Write Message*</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="message"
                        onChange={this.onChange.bind(this)}
                        required
                      />
                    </Form.Group>

                    <div className="capchaCode">
                      <div
                        className="g-recaptcha"
                        data-sitekey="6LcjIsQUAAAAAPYjdLFm1hP-EgdGAJFQrUxmRGkc"
                      ></div>
                    </div>
                    <p className="mb-4 mt-2">
                      By submitting the above required information you agree to
                      our <Link to="/en/terms-of-use">Terms of Use</Link>,{" "}
                      <Link to="/en/privacy-policy">Privacy Policy</Link>,{" "}
                      <Link to="/en/trademarks">Trademark</Link> and{" "}
                      <Link to="/en/disclaimer">Disclaimer</Link>.
                    </p>

                    {this.state.error !== "" && (
                      <h5 className="pb-4 font-weight-normal">
                        {this.state.error}
                      </h5>
                    )}

                    <div className="text-center mb-2">
                      <Button variant="blue btn-lg" type="submit">
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </Col>
            <Col lg={6}>
              <div className="bdrpdg h-100">
                <h2 className="mb-4 font-28">Reach us at</h2>
                <ol className="reachus">
                  {/* <li className="phoneno">
                    <span>
                      <strong>Americas (US):</strong>
                      <img src={CallImage} className="img-fluid" alt="" />
                      +1 212 739 0842
                    </span>
                    <span>
                      <strong>Europe (UK):</strong>
                      <img src={CallImage} className="img-fluid" alt="" />
                      +44 203 753 5581
                    </span>
                    <span>
                      <strong>MENA (UAE):</strong>
                      <img src={CallImage} className="img-fluid" alt="" />
                      +971 2 654 4633
                    </span>
                  </li> */}
                  <li>
                    <strong>Client support (24/7)</strong>
                    <Link to="mailto:support@analyzemarkets.com">
                      support@analyzemarkets.com
                    </Link>
                  </li>
                  <li>
                    <strong>Sales & subscription</strong>
                    <Link to="mailto:sales@analyzemarkets.com">
                      subscribe@analyzemarkets.com
                    </Link>
                  </li>
                  <li>
                    <strong>Press & media enquiries</strong>
                    <Link to="mailto:media@analyzemarkets.com">
                      media@analyzemarkets.com
                    </Link>
                  </li>
                  <li>
                    <strong>Accounts & billing</strong>
                    <Link to="mailto:accounts@analyzemarkets.com">
                      accounts@analyzemarkets.com
                    </Link>
                  </li>
                  <li>
                    <strong>General</strong>
                    <Link to="mailto:info@analyzemarkets.com">
                      info@analyzemarkets.com
                    </Link>
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
          <h2 className="text-uppercase mb-5 mt-5 text-center">
            OUR REGIONAL PRESENCE
          </h2>
          <Row className="row-eq-height">
            <Col lg={4}>
              <div className="addrss">
                <h4>Americas</h4>
                <hr />
                <img src={F1} className="img-fluid" alt="" />
                <ol>
                  <li>17 State Street, Suite 4000, New York, NY 10004, US</li>
                  {/* <li>Office: +1 212 739 0842</li> */}
                  <li>info.us@analyzemarkets.com</li>
                  {/* <li>
                    Working Hours: Monday to Friday and 8:30 am to 5:30 pm
                  </li> */}
                </ol>
              </div>
            </Col>
            <Col lg={4}>
              <div className="addrss">
                <h4>Europe</h4>
                <hr />
                <img src={F2} className="img-fluid" alt="" />
                <ol>
                  <li>
                    Level 30, The Leadenhall Building, 122 Leadenhall Street,
                    London EC3V 4AB, UK
                  </li>
                  {/* <li>Office: +44 203 753 5581</li> */}
                  <li>info.uk@analyzemarkets.com</li>
                  {/* <li>
                    Working Hours: Monday to Friday and 8:30 am to 5:30 pm
                  </li> */}
                </ol>
              </div>
            </Col>
            <Col lg={4}>
              <div className="addrss">
                <h4>MENA</h4>
                <hr />
                <img src={F3} className="img-fluid" alt="" />
                <ol>
                  <li>
                    14th floor, Al Khatem Tower WeWork Hub71, Abu Dhabi Global
                    Market Square, Abu Dhabi, UAE
                  </li>
                  {/* <li>Office: +971 2 654 4633</li> */}
                  <li>info.uae@analyzemarkets.com</li>
                  {/* <li>
                    Working Hours: Sunday to Thursday and 8:30 am to 5:30 pm
                  </li> */}
                </ol>
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Contact;
