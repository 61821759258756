import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import Autocomplete from "react-autocomplete";
import {
  Row,
  Table,
  Col,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterMarket";
import "react-table-filter/lib/styles.css";
import { setupCache } from "axios-cache-adapter";
import axios from "axios";
//import upload from "./upload.php";
import FundRaisingGmdSidebar from "../../component/global/FundRaising Sidebar/fund-raising-gmd_sidebar";

import $ from "jquery";
//import Autocomplete from "material-ui/AutoComplete";
//import { makeStyles } from "material-ui/styles";
import { Editor } from "@tinymce/tinymce-react";
//import window from "react-global";
import "../create-deal/create-deal.css";
import { Link } from "react-router-dom";
import Fetch from "../../component/global/CommonComponent/useFetch";
import loader from "../../assets/images/loader.gif";
import {
  matchCompany,
  matchSector,
} from "../../component/global/investment-intellegence-screener/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Signin from "../../pages/signin/signinIndex";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
const animatedComponents = makeAnimated();

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});
class Fundraising_Edit extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.onChangePage = this.onChangePage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportedDate = this.handleReportedDate.bind(this);
    this.changeTermMultiSelect = this.changeTermMultiSelect.bind(this);
    this.changeTermMultiSelects = this.changeTermMultiSelects.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.handleFilters = this.handleFilters.bind(this);

    this.state = {
      dealheadline: "",
      funddomicile: "",
      dealcodename: "",
      companydealbriefdescription: "",
      refresh: "",
      status: "",
      loading: "",
      stage: [],
      filterdata: "",
      category: [],
      sub_type: [],
      prop_type: [],
      regions: [],
      //editorState: EditorState.createEmpty(),
      user: "",
      lease_type: [],
      itemcurrency: [],
      name_info: "",
      title: "",
      emailentry: "",
      email: "",
      contactnumber: "",
      labelItem: false,
      labelitem1: false,
      youauthorizedcreatedealbehalfcompany: "",
      items: [],
      keyValue: false,
      company: [],
      sector_data: "",
      result: false,
      company_details: [],
      details: [],
      country: [],
      sector: [],
      sector_name: "",
      filter: "",
      subIndustry: [],
      old_route: "",
      pageSize: 25,
      count: 0,
      file: "",
      id: "",
      fundname: "",
      fundraisingstage: "",
      fundtargetsize: "",
      fundtargetreturn: "",
      amounttoraise: "",
      fundmanagercountry: "",
      fundmanagerURL: "",
      fundgeographicfocus: "",
      companydealbriefdescription: "",
      fundmanagername: "",
      fundlife: "",
      file_teaser: [],
      fileother: "",
      filepicture: "",
      file_other: [],
      reportedDate: new Date(),
      reported_date: "",
    };
    //this.onChange = (editorState) => this.setState({ editorState });
  }
  handleFilters = (filterdata) => {
    this.setState({
      filterdata: filterdata,
      is_filter: true,
    });

    this.handleDetail(filterdata);
  };

  handleDetail = (filterdata) => {
    api({
      url:
        "https://api.analyzemarkets.com/gdmfundraisingsingle/" +
        this.props.match.params.dealheadline +
        "/?" +
        filterdata,
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, "Fund Edit");
      this.setState({
        details: res.data,
        id: res.data.id,
      });
    });
  };

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  refreshButton = () => {
    this.setState({
      refresh: true,
    });
  };
  getLabel = () => {
    const { details } = this.state;
    this.setState({
      labelitem1: true,
      labelitem: true,
    });
  };

  handleState = () => {
    this.setState({
      result: false,
    });
  };
  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  changeTermMultiSelect = (sector_name) => {
    const state = this.state;
    state.sector_name = [];
    sector_name.forEach((option) => {
      state.sector_name.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({
      sector_name: state.sector_name,
      sector_data: null,
    });
    console.log(sector_name);
  };
  changeTermMultiSelects = (companysubindustry) => {
    const state = this.state;
    state.companysubindustry = [];
    companysubindustry.forEach((option) => {
      state.companysubindustry.push(option.label);
    });
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({ companysubindustry: state.companysubindustry });
    console.log(companysubindustry);
  };
  handleEditorChange = (e) => {
    this.setState({
      companydealbriefdescription: e.target.getContent(),
    });
    console.log("Content was updated:", this.state.companydealbriefdescription);
  };
  handleReportedDate = (date) => {
    console.log(date.getDate(), date.getMonth() + 1, date.getFullYear());
    this.setState({
      reported_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      reportedDate: date,
      last_days: "",
    });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const len = event.target.files.length;

    this.setState({
      [name]: event.target.files[0],
      keyValue: true,
    });
    console.log(len, "length");
    console.log(" file:", this.state.file);

    //.log(" file:", event.target.files[0]);
  }
  onSubmit(e) {
    e.preventDefault();
    const { dealheadline } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set("id", this.state.id);

    bodyFormData.set(
      "dealheadline",
      dealheadline == "" ? this.state.details.dealheadline : dealheadline
    );
    bodyFormData.set(
      "dealcodename",
      this.state.dealcodename == ""
        ? this.state.details.dealcodename
        : this.state.dealcodename
    );
    bodyFormData.set(
      "fundname",
      this.state.fundname == ""
        ? this.state.details.fundname
        : this.state.fundname
    );
    bodyFormData.set(
      "funddomicile",
      this.state.funddomicile == ""
        ? this.state.details.funddomicile
        : this.state.funddomicile
    );
    bodyFormData.set(
      "fundcategory",
      this.state.fundcategory == ""
        ? this.state.details.fundcategory
        : this.state.fundcategory
    );
    bodyFormData.set(
      "userdealstatus",
      this.state.status == ""
        ? this.state.details.userdealstatus
        : this.state.status
    );

    bodyFormData.set(
      "fundraisingstage",
      this.state.fundraisingstage == ""
        ? this.state.details.fundraisingstage
        : this.state.fundraisingstage
    );
    bodyFormData.set(
      "fundtargetsize",
      this.state.fundtargetsize == ""
        ? this.state.details.fundtargetsize
        : this.state.fundtargetsize
    );
    bodyFormData.set(
      "fundtargetreturn",
      this.state.fundtargetreturn == ""
        ? this.state.details.fundtargetreturn
        : this.state.fundtargetreturn
    );
    bodyFormData.set(
      "amounttoraise",
      this.state.amounttoraise == ""
        ? this.state.details.amounttoraise
        : this.state.amounttoraise
    );
    bodyFormData.set(
      "fundmanagername",
      this.state.fundmanagername == ""
        ? this.state.details.fundmanagername
        : this.state.fundmanagername
    );
    bodyFormData.set(
      "fundmanagercountry",
      this.state.fundmanagercountry == ""
        ? this.state.details.fundmanagercountry
        : this.state.fundmanagercountry
    );
    bodyFormData.set(
      "fundmanagerURL",
      this.state.fundmanagerURL == ""
        ? this.state.details.fundmanagerURL
        : this.state.fundmanagerURL
    );
    bodyFormData.set(
      "fundgeographicfocus",
      this.state.fundgeographicfocus == ""
        ? this.state.details.fundgeographicfocus
        : this.state.fundgeographicfocus
    );
    bodyFormData.set(
      "fundlife",
      this.state.fundlife == ""
        ? this.state.details.fundlife
        : this.state.fundlife
    );
    bodyFormData.set(
      "dealbriefdescription",
      this.state.companydealbriefdescription == ""
        ? this.state.details.dealbriefdescription
        : this.state.companydealbriefdescription
    );
    bodyFormData.set(
      "name",
      this.state.name_info == ""
        ? this.state.details.name
        : this.state.name_info
    );
    bodyFormData.set(
      "title",
      this.state.title == "" ? this.state.details.title : this.state.title
    );
    bodyFormData.set(
      "email",
      this.state.emailentry == ""
        ? this.state.details.email
        : this.state.emailentry
    );
    bodyFormData.set(
      "contactnumber",
      this.state.contactnumber == ""
        ? this.state.details.contactnumber
        : this.state.contactnumber
    );
    bodyFormData.set(
      "youauthorizedcreatedealbehalfcompany",
      this.state.youauthorizedcreatedealbehalfcompany == ""
        ? this.state.details.youauthorizedcreatedealbehalfcompany
        : this.state.youauthorizedcreatedealbehalfcompany
    );
    if (this.state.keyValue === true) {
      bodyFormData.set(
        "teaserresentation",
        this.state.file == ""
          ? this.state.details.teaserresentation
          : this.state.file
      );
    }
    if (this.state.keyValue === true) {
      bodyFormData.set(
        "otherdocumentstoupload",
        this.state.fileother == ""
          ? this.state.details.otherdocumentstoupload
          : this.state.fileother
      );
    }
    if (this.state.keyValue === true) {
      bodyFormData.set(
        "projectpicturesvideotoupload",
        this.state.filepicture == ""
          ? this.state.details.projectpicturesvideotoupload
          : this.state.filepicture
      );
    }

    bodyFormData.set("posted_by", this.state.user);

    axios
      .post(
        "https://api.analyzemarkets.com/gdmfundraisingform/",
        bodyFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        this.setState({
          loading: true,
        });

        console.log(res.data, "Data");
        if (res.status === 200) {
          this.setState({
            result: true,
            loading: false,
          });
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };
  componentDidMount = () => {
    $(function () {
      $("select option")
        .filter(function () {
          return $.trim($(this).text()) == "null";
        })
        .remove();
    });
    api({
      url: "https://api.analyzemarkets.com/sectorman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ sector: res.data.results });
      }
    });
    if (this.state.filterdata !== "") {
      this.handleDetail(this.state.filterdata);
    }

    api({
      url: "https://api.analyzemarkets.com/fundcategory/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ category: res.data });
      }
    });

    api({
      url: "https://api.analyzemarkets.com/regman/",
      method: "get",
    }).then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ regions: res.data.results });
      }
    });
    axios
      .get("https://api.analyzemarkets.com/compmgmtlimited/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ company: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/reportingcurrency/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ itemcurrency: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/fundraisingstage/")
      .then((res) => {
        console.log(res, 23);
        this.setState({ stage: res.data });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action, user });
  };

  render() {
    //const classes = useStyles();
    const data = this.state.details;
    const options = [
      { title: "Energy" },
      { title: "Material" },
      { title: "Industrail" },
      { title: "Type" },
    ];
    const sec = this.state.sector;
    const subIn = this.state.subIndustry;
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            <Container>
              <Fetch applyFilters={this.handleFilters}></Fetch>

              <Navfilter />
              <Row className="centerAlign">
                {/* <Col className="w-30p col-sm-3 p-4">
              <div className="widget"></div>
            </Col> */}

                <Col className="">
                  <div>
                    <div class="container">
                      <form onSubmit={this.onSubmit}>
                        <div className="iis greyborder2px">
                          <h4 className="text-left">Edit a Deal</h4>
                          <div className="pdg15">
                            <fieldset>
                              <legend>
                                <b className="upercase">
                                  About Fundraising Deal
                                </b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="dealheadline"
                                    type="text"
                                    defaultValue={data.dealheadline}
                                    placeholder="Deal Headline* (Max 100 characters)
                                  e.g. An established private equity firm fundraising for its buyout fund"
                                    onChange={this.changeTerm}
                                    maxLength="100"
                                  />
                                </Col>
                              </Row>

                              <Row className="">
                                <Col>
                                  <Form.Control
                                    name="dealcodename"
                                    defaultValue={data.dealcodename}
                                    type="text"
                                    placeholder="Deal Code Name *e.g. Project Sun"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    name="fundname"
                                    type="text"
                                    defaultValue={data.fundname}
                                    placeholder="Fund Name"
                                    onChange={this.changeTerm}
                                  />
                                </Form.Group>

                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    as="select"
                                    name="funddomicile"
                                    defaultValue={data.funddomicile}
                                    onChange={this.changeTerm}
                                    placeholder="Fund Domicile*"
                                    rows="3"
                                  >
                                    <option value={data.funddomicile}>
                                      {data.funddomicile}
                                    </option>

                                    <option value="" className="">
                                      Fund Domicile*{" "}
                                    </option>
                                    {this.state.country.map((item, i) => (
                                      <option key={i} value={item.country}>
                                        {item.country}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Row>

                              <Row className="">
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="fundcategory"
                                    defaultValue={data.fundcategory}
                                    onChange={this.changeTerm}
                                    placeholder="Fund Category*"
                                    rows="3"
                                  >
                                    {" "}
                                    <option value={data.fundcategory}>
                                      {data.fundcategory}
                                    </option>
                                    <option value="" className="">
                                      Fund Category*
                                    </option>
                                    {this.state.category.map((item, i) => (
                                      <option key={i} value={item.type}>
                                        {item.type}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    as="select"
                                    name="fundraisingstage"
                                    defaultValue={data.fundraisingstage}
                                    onChange={this.changeTerm}
                                    placeholder="Fundraising Stage*"
                                    rows="3"
                                  >
                                    <option value={data.fundraisingstage}>
                                      {data.fundraisingstage}
                                    </option>

                                    <option value="" className="">
                                      Fundraising Stage*{" "}
                                    </option>
                                    {this.state.stage.map((item, i) => (
                                      <option key={i} value={item.type}>
                                        {item.type}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Col>
                                  <Form.Control
                                    name="fundtargetsize"
                                    defaultValue={data.fundtargetsize}
                                    onChange={this.changeTerm}
                                    placeholder="Fund Target Size* (In USD million)"
                                  />{" "}
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="fundtargetreturn"
                                    defaultValue={data.fundtargetreturn}
                                    onChange={this.changeTerm}
                                    placeholder="Fund Target Return (in %)"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="amounttoraise"
                                    defaultValue={data.amounttoraise}
                                    onChange={this.changeTerm}
                                    placeholder="Amount to Raise* (in USD million)"
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="fundmanagername"
                                    defaultValue={data.fundmanagername}
                                    onChange={this.changeTerm}
                                    placeholder="Fund Manager Name*"
                                  />
                                </Col>
                              </Row>

                              <Row className="">
                                <Col>
                                  <Form.Control
                                    as="select"
                                    name="fundmanagercountry"
                                    defaultValue={data.fundmanagercountry}
                                    onChange={this.changeTerm}
                                    placeholder="Fund Manager Country*"
                                    rows="3"
                                  >
                                    <option value={data.fundmanagercountry}>
                                      {data.fundmanagercountry}
                                    </option>

                                    <option value="" className="">
                                      Fund Manager Country*
                                    </option>
                                    {this.state.country.map((item, i) => (
                                      <option key={i} value={item.country}>
                                        {item.country}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    name="fundmanagerURL"
                                    defaultValue={data.fundmanagerURL}
                                    onChange={this.changeTerm}
                                    placeholder="Fund Manager URL"
                                  />
                                </Form.Group>

                                <Col>
                                  <Form.Control
                                    name="fundlife"
                                    defaultValue={data.fundlife}
                                    onChange={this.changeTerm}
                                    placeholder="Fund Life (in years)"
                                  />{" "}
                                </Col>
                              </Row>

                              <Row className="">
                                <Form.Group as={Col} controlId="ChooseCountry">
                                  <Form.Control
                                    name="fundgeographicfocus"
                                    defaultValue={data.fundgeographicfocus}
                                    onChange={this.changeTerm}
                                    placeholder="Fund Geographic Focus* (write regions or countries)"
                                  />{" "}
                                </Form.Group>
                              </Row>

                              <div className="form-group">
                                <Row className="">
                                  <Col>
                                    <Editor
                                      initialValue={data.dealbriefdescription}
                                      value={data.dealbriefdescription}
                                      apiKey="l7d3ab4ybjfthcy2c4ya6vsj9rlub27d3sdqk433oyis6ild"
                                      init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                          "advlist autolink lists link image",
                                          "charmap print preview anchor help",
                                          "searchreplace visualblocks code",
                                          "insertdatetime media table paste wordcount",
                                        ],
                                        toolbar:
                                          "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help",
                                      }}
                                      onChange={this.handleEditorChange}
                                    />

                                    {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                  as="textarea"
                                  name="companydealbriefdescription"
                                  rows="7"
                                  maxLength="100"
                                  onChange={this.changeTerm}
                                  placeholder="Company/Deal Brief Description* (Maximum 100 words)"
                                />
                              </Form.Group> */}
                                  </Col>{" "}
                                </Row>
                              </div>
                              <legend>
                                <b className="upercase">
                                  Requirement Documents
                                </b>
                              </legend>
                              <div className="form-group">
                                {this.state.labelitem1 && (
                                  <label> Teaser/Presentation</label>
                                )}

                                <Row>
                                  {this.state.labelitem1 === false && (
                                    <Col>
                                      <label> Teaser/Presentation</label>
                                      <div class="custom-file">
                                        <input
                                          type="file"
                                          multiple
                                          class="custom-file-input"
                                          id="inputGroupFile01"
                                          name="file"
                                          aria-describedby="inputGroupFileAddon01"
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="inputGroupFile01"
                                        >
                                          {data.teaserresentationname}
                                        </label>
                                      </div>{" "}
                                    </Col>
                                  )}
                                  {this.state.labelitem1 && (
                                    <Col>
                                      <input
                                        type="file"
                                        id="myfile"
                                        name="file"
                                        onChange={this.handleChange}
                                      />{" "}
                                    </Col>
                                  )}
                                  {console.log(this.state.file, "File Name")}
                                  <span onClick={this.getLabel}>
                                    <i
                                      class="fa fa-pencil pencil1"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Row>
                              </div>
                              <div className="form-group">
                                {this.state.labelitem1 && (
                                  <label>Other Documents to upload:</label>
                                )}
                                <Row>
                                  {this.state.labelitem1 === false && (
                                    <Col>
                                      <label>Other Documents to upload:</label>
                                      <div class="custom-file">
                                        <input
                                          type="file"
                                          multiple
                                          class="custom-file-input"
                                          id="inputGroupFile01"
                                          name="fileother"
                                          aria-describedby="inputGroupFileAddon01"
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="inputGroupFile01"
                                        >
                                          {data.otherdocumentstouploadname}
                                        </label>
                                      </div>{" "}
                                      {console.log(
                                        " file count:",
                                        this.state.fileother
                                      )}
                                    </Col>
                                  )}
                                  {this.state.labelitem1 && (
                                    <Col>
                                      <input
                                        type="file"
                                        id="myfile"
                                        name="fileother"
                                        onChange={this.handleChange}
                                      />{" "}
                                    </Col>
                                  )}
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                  <span onClick={this.getLabel}>
                                    <i
                                      class="fa fa-pencil pencil1"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Row>{" "}
                              </div>
                              <div className="form-group">
                                {this.state.labelitem1 && (
                                  <label> Picture and video to upload</label>
                                )}{" "}
                                <Row>
                                  {this.state.labelitem1 === false && (
                                    <Col>
                                      <label>
                                        {" "}
                                        Picture and video to upload
                                      </label>
                                      <div class="custom-file">
                                        <input
                                          type="file"
                                          multiple
                                          class="custom-file-input"
                                          id="inputGroupFile01"
                                          name="filepicture"
                                          aria-describedby="inputGroupFileAddon01"
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="inputGroupFile01"
                                        >
                                          {
                                            data.projectpicturesvideotouploadname
                                          }
                                        </label>
                                      </div>{" "}
                                      {console.log(
                                        " file count:",
                                        this.state.file
                                      )}
                                    </Col>
                                  )}
                                  {this.state.labelitem1 && (
                                    <Col>
                                      <input
                                        type="file"
                                        id="myfile"
                                        name="filepicture"
                                        onChange={this.handleChange}
                                      />{" "}
                                    </Col>
                                  )}
                                  {console.log(
                                    this.state.file,
                                    "Type of Audit"
                                  )}
                                  <span onClick={this.getLabel}>
                                    <i
                                      class="fa fa-pencil pencil1"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Row>
                              </div>

                              <legend>
                                <b className="upercase">
                                  Contact Information For Potential Investors
                                </b>
                              </legend>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="name_info"
                                    defaultValue={data.name}
                                    type="text"
                                    placeholder="Name*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    name="title"
                                    defaultValue={data.title}
                                    type="text"
                                    placeholder="Title*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <Form.Control
                                    name="emailentry"
                                    defaultValue={data.email}
                                    type="email"
                                    placeholder="Email*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>

                                <Col>
                                  <Form.Control
                                    name="contactnumber"
                                    defaultValue={data.contactnumber}
                                    type="tel"
                                    placeholder="Contact number*"
                                    onChange={this.changeTerm}
                                  />
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col>
                                  <label>
                                    Are you authorized to create this deal on
                                    behalf of the Company*:{" "}
                                  </label>
                                  <Form.Control
                                    as="select"
                                    name="youauthorizedcreatedealbehalfcompany"
                                    onChange={this.changeTerm}
                                    // placeholder="Are you Authorized to create this deal"
                                  >
                                    {data.youauthorizedcreatedealbehalfcompany !==
                                    null ? (
                                      <option
                                        value={
                                          data.youauthorizedcreatedealbehalfcompany
                                        }
                                        className=""
                                      >
                                        {
                                          data.youauthorizedcreatedealbehalfcompany
                                        }
                                      </option>
                                    ) : (
                                      <option value={""} className="">
                                        Are you authorized to create the deal
                                      </option>
                                    )}
                                    <option
                                      value={
                                        "Yes" ===
                                        data.youauthorizedcreatedealbehalfcompany
                                          ? "null"
                                          : "Yes"
                                      }
                                    >
                                      Yes
                                    </option>
                                    <option
                                      value={
                                        "No" ===
                                        data.youauthorizedcreatedealbehalfcompany
                                          ? "null"
                                          : "No"
                                      }
                                    >
                                      No
                                    </option>
                                  </Form.Control>
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <Col>
                                  <label>Deal Status</label>

                                  <Form.Control
                                    as="select"
                                    name="status"
                                    onChange={this.changeTerm}
                                  >
                                    {data.userdealstatus !== null ? (
                                      <option
                                        value={data.userdealstatus}
                                        className=""
                                      >
                                        {data.userdealstatus}
                                      </option>
                                    ) : (
                                      <option value={""} className="">
                                        Deal Status
                                      </option>
                                    )}

                                    <option
                                      value={
                                        "Active" === data.userdealstatus
                                          ? "null"
                                          : "Active"
                                      }
                                    >
                                      Active
                                    </option>
                                    <option
                                      value={
                                        "Inactive" === data.userdealstatus
                                          ? "null"
                                          : "Inactive"
                                      }
                                    >
                                      Inactive
                                    </option>
                                  </Form.Control>
                                </Col>
                              </Row>

                              <Row className="form-group">
                                <Col sm={3}>
                                  <Button
                                    variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                                    type="submit"
                                    size="sm"
                                  >
                                    Publish Deal
                                  </Button>
                                </Col>
                                <Col>
                                  <Link
                                    className="btn btn-blue mt-4 mb-4 shadow"
                                    onClick={this.refreshButton}
                                  >
                                    Clear
                                  </Link>
                                </Col>
                              </Row>
                              {this.state.refresh && window.location.reload()}
                              {this.state.loading && (
                                <div className="borderColors1">
                                  <img src={loader} className=""></img>
                                </div>
                              )}
                              {this.state.result && (
                                <div className="borderColors1">
                                  <span
                                    className="buttonFloat"
                                    onClick={this.handleState}
                                  >
                                    <i className="fa fa-times-circle"></i>
                                  </span>
                                  <span>
                                    <i
                                      class="fa fa-check-circle"
                                      style={{ color: "green" }}
                                    ></i>
                                  </span>
                                  &nbsp;
                                  <span>
                                    <h4>
                                      {" "}
                                      <b className="colorError">
                                        Your Deal is Successfully Submitted
                                      </b>
                                    </h4>
                                    <br></br>
                                  </span>
                                </div>
                              )}
                            </fieldset>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}
export default Fundraising_Edit;
