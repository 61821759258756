import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ClientSideComponent } from "react-prerendered-component";
import "./index.css";
import App from "../App";
import createStore from "./store";

const store = createStore(window.REDUX_DATA);

ReactDOM.hydrate(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
