import React, { Component, Fragment } from "react";
import Followus from "../../component/global/Followus_wire/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import Iws from "../../component/global/investment-wire-screener/investment-wire-screener";
import { Container, Grid, Row, Col, Table } from "react-bootstrap";
import CompanylogoS from "../../assets/images/clogo-s.jpg";
import CompanylogoB from "../../assets/images/clogo-b.jpg";
import { Link } from "react-router-dom";
import Iis from "../../component/global/investment-intellegence-screener/investment-Intelligence-screener";
import "./press-release.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import Moment from "react-moment";
import Pagination from "../../component/global/pagination/index";
import { Helmet } from "react-helmet";
import NavFilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class PressreleaseData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giw: [],
      token: "",
      exampleItems: [],
      company: [],
      page: 0,
      is_first: true,
      current: 0,
      country: [],
      filter: "",
      old_route: "",
      pageSize: 25,
      count: 0,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }

  handleFilter = (filter) => {
    console.log(filter, 31);
    this.setState({
      filter: filter,
      is_filter: true,
    });
    this.getData(this.state.old_route, filter, 0, true);
  };

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    // var current_route = this.props.match.params.id;
    if (page != this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    console.log(this.props.match.params.id);
    var current_route = this.props.match.params.id;
    if (current_route == undefined) {
      current_route = "";
    }

    this.setState({
      old_route: current_route,
    });
    this.getData(current_route, "", 1, true);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };

  getData = (route, filter, page, is_first) => {
    console.log(89, route);

    api({
      url:
        "https://api.analyzemarkets.com/giwm/?name=" +
        route +
        "&start=" +
        page +
        "" +
        filter,
      method: "get",
    }).then(async (res) => {
      console.log(res, "press release");
      if (res.status === 200) {
        this.setState({
          giw: res.data.results,
          page: res.data.count,
          current: page,
          is_first: false,
        });
      }

      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
      }
      console.log(res.data.count + "Count Data");
    });
    api({
      url: "https://api.analyzemarkets.com/country/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        country: res.data.results,
        show_100: true,
      });

      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
  };

  render() {
    const data = this.state.company;
    return (
      <Fragment>
        <Header></Header>
        <Container>
          <Helmet>
            <title>
              AnalyzeMarkets - Global Investment Intelligence Platform
            </title>
            <meta
              name="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              property="og:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              itemprop="name"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              itemprop="description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta
              name="twitter:title"
              content="AnalyzeMarkets - Global Investment Intelligence Platform"
            />

            <meta
              name="twitter:description"
              content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>
          <div>
            <NavFilter />
            <Fragment>
              <h4 className="text-uppercase pt-3">
                &nbsp; ALL Global Investment Wire:
              </h4>
              <br></br>

              {!this.state.is_first && (
                <Row>
                  <Col className="w-70p">
                    {this.state.giw.map((item, key) => (
                      <div className="greyborder1_4px placeholder">
                        <Row className="show-grid">
                          <Col md={2}>
                            {item.wire_provider.map((company, key) => (
                              <img
                                src={company.image}
                                className="clogo-s"
                                alt=""
                              />
                            ))}
                          </Col>
                          <Col md={9}>
                            {/* <div className="company-name"> {item.company_name.map((company, key) => (
                                     <Link to={'/en/global-company-database-details/'+company.api_url+'/'>
                                                        <u className="underline">{company.name}</u>
                                                        </Link>
                                                     ))}</div> */}

                            <div className="company-name">
                              {" "}
                              {item.wire_provider.map((company, key) => (
                                <div>
                                  {company.api_url != null ? (
                                    <Link
                                      to={
                                        "/en/global-company-database-details/" +
                                        company.api_url +
                                        "/"
                                      }
                                    >
                                      <u className="underline">
                                        {company.name}
                                      </u>
                                    </Link>
                                  ) : (
                                    <Link>
                                      <u className="underline">
                                        {company.name}
                                      </u>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </div>

                            <strong className="title">
                              <Link
                                to={
                                  "/en/wire/" +
                                  item.url +
                                  "/" +
                                  this.state.token
                                }
                              >
                                {item.title}
                              </Link>
                            </strong>
                            <div>
                              {" "}
                              {item.category.map((cat, key) => (
                                <span className="newstags">
                                  {key > 0 ? " | " + cat.name : cat.name}
                                </span>
                              ))}
                            </div>

                            <div className="date">
                              <Moment format="DD-MMM-YYYY">
                                {item.created_at}
                              </Moment>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <div className="products-view__options justify-content-between mt-4">
                      {/* {this.state.count>10 &&
                                    <div className="view-options__control">
                                        <label>Results Per Page</label>
                                        <div>
                                        
                                        </div>
                                    </div>
                                } */}
                      <Pagination
                        items={this.state.exampleItems}
                        onChangePage={this.onChangePage}
                        pageSize={this.state.pageSize}
                      ></Pagination>
                    </div>
                  </Col>
                  <Col className="w-30p p-4test">
                    <div className="widget">
                      <Iws applyFilter={this.handleFilter} />
                    </div>
                    <div className="widget"></div>
                    {/* <div className="widget">
                      <Downloadapp />
                    </div>
                    <div className="widget">
                      <SubscriberAccsss />
                    </div> */}
                  </Col>
                </Row>
              )}
            </Fragment>

            {this.state.is_first && (
              <Fragment>
                <Col className="w-70p">
                  <Row>
                    <div className="ph-col-12">
                      <div className="ph-row mt-3">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                    <Col md="3">
                      <div className="ph-picture"></div>
                    </Col>

                    <Col md="6">
                      <div className="ph-row bdrph">
                        <div className="ph-col-8"></div>
                        <div className="ph-col-8 empty"></div>
                        <div className="ph-col-8"></div>
                      </div>
                    </Col>

                    <div className="ph-col-12">
                      <div className="ph-row bdrph"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>

                    <Col md="3" className="mt-3">
                      <div className="ph-picture"></div>
                    </Col>

                    <Col md="6">
                      <div className="ph-row bdrph">
                        <div className="ph-col-8"></div>
                        <div className="ph-col-8 empty"></div>
                        <div className="ph-col-8"></div>
                      </div>
                    </Col>

                    <div className="ph-col-12">
                      <div className="ph-row bdrph"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>

                    <Col md="3" className="mt-3">
                      <div className="ph-picture"></div>
                    </Col>

                    <Col md="6">
                      <div className="ph-row bdrph">
                        <div className="ph-col-8"></div>
                        <div className="ph-col-8 empty"></div>
                        <div className="ph-col-8"></div>
                      </div>
                    </Col>

                    <div className="ph-col-12">
                      <div className="ph-row bdrph"></div>
                      <div className="ph-row">
                        <div className="ph-col-10"></div>
                      </div>
                    </div>
                    <Col md="3" className="mt-3">
                      <div className="ph-picture"></div>
                    </Col>

                    <Col md="6">
                      <div className="ph-row bdrph">
                        <div className="ph-col-8"></div>
                        <div className="ph-col-8 empty"></div>
                        <div className="ph-col-8"></div>
                      </div>
                    </Col>
                    <div className="ph-col-12">
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </Row>
                </Col>
              </Fragment>
            )}
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default PressreleaseData;
