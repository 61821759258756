import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import capchaimg from "../../assets/images/captcha-img.jpg";
import { Container, Row, Col, Form, Button } from "react-bootstrap/";
import "./signin.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  Route,
  Switch,
  NavLink,
  BrowserRouter as Router,
} from "react-router-dom";
import Header from "../../component/global/header/index";
import HomeAfterLogin from "../../pages/homeAfterLogin/homeafterLogin";
class SigninIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signin: [],
      email: "",
      rememberMe: true,
      item: [],
      message: "",
      password: "",
      count: "",
      id: "",
      login: [],
      login_flag: "True",
      logout_flag: "False",
      results: "",
      result: "",
      token: "",
      refresh: "",
      response: "",
      user: "",
      action: true,
      hasError: false,
      hasErrors: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount = () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, action });
  };
  onChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onSubmit(e) {
    e.preventDefault();
    const { email, rememberMe } = this.state;
    localStorage.setItem("rememberMe", true);

    var bodyFormData = new FormData();
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("password", this.state.password);
    bodyFormData.set("login_flag", this.state.login_flag);
    bodyFormData.set("logout_flag", this.state.logout_flag);

    axios
      .post("https://api.analyzemarkets.com/signin/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data, "Data");
        if (res.status === 200) {
          if (res.data === "User does not exists") {
            this.setState({
              hasError: true,
              hasErrors: res.data,
              error: true,
            });
          } else {
            console.log(res.data.email, "Email Info");
            this.setState({
              response: res.data.email,
              token: res.data.token,
              user: res.data.name,
              action: res.data.isactive,
              id: res.data.id,
              result: true,
              refresh: true,
            });
            if (this.state.result === true) {
              localStorage.setItem("email", this.state.email); //Email Login
            }

            localStorage.setItem("response", this.state.response); //Email Login
            localStorage.setItem("token", this.state.token); //Token Login
            localStorage.setItem("user", this.state.user);
            localStorage.setItem("id", this.state.id);
            localStorage.setItem("action", this.state.action); //Flag
          }
        } else {
          this.setState({
            result: (
              <div class="">
                <hr />
                <i class="fa fa-exclamation-triangle  btext"></i> You are
                already Signed!
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        //on error
        alert(error);
      });
  }

  render() {
    return (
      <div>
        {" "}
        <Fragment>
          <Header />

          <Container>
            <Helmet>
              <title>AnalyzeMarkets Sign In</title>
              <meta
                name="description"
                content="Intelligence and databases covering M&A, real estate, venture capital, joint-venture, private equity, fundraising, and IPOs. Global Deals Marketplace. Global Investors Database. Global Companies Database."
              />
              <meta property="og:title" content="AnalyzeMarkets Sign In " />
              <meta
                property="og:image"
                content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
              />
              <meta
                name="og:description"
                content="Being a subscriber, please enter your email id along with your password and click the 'Sign In' button to access all exclusive features"
              />
              <meta name="og:url" content="https://www.analyzemarkets.com" />
              <meta name="og:site_name" content="AnalyzeMarkets" />
              <meta name="og:locale" content="en" />
              <meta name="og:type" content="website" />

              <meta
                name="image"
                content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
              />
              <meta itemprop="name" content="AnalyzeMarkets Sign In " />

              <meta
                itemprop="description"
                content="Being a subscriber, please enter your email id along with your password and click the 'Sign In' button to access all exclusive features"
              />
              <meta
                itemprop="image"
                content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
              />

              <meta name="twitter:card" content="summary_large_image" />

              <meta name="twitter:title" content="AnalyzeMarkets Sign In " />

              <meta
                name="twitter:description"
                content="Being a subscriber, please enter your email id along with your password and click the 'Sign In' button to access all exclusive features"
              />

              <meta name="twitter:site" content="@AnalyzeMarkets" />
              <meta name="twitter:creator" content="@AnalyzeMarkets" />
              <meta
                name="twitter:image"
                content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
              />
            </Helmet>
            {/* <Router>
              {console.log(this.state.message, "Messgae dtat")}
              <Switch>
                <Route path="/Home" component={HomeAfterLogin}></Route>
              </Switch>
              ))}
            </Router> */}

            <Row className="justify-content-center">
              <Col xl={7} md={12}>
                <div className="registration-form-container">
                  {this.state.hasError && (
                    <div className="error-message">
                      <div className="error-text">
                        Username or Password is Incorrect
                      </div>
                    </div>
                  )}
                  {this.state.result && window.location.reload()}

                  {this.state.result === "" && (
                    <div className="registration-form">
                      <h2 className="pb-5 padup">Sign In</h2>
                      <Form onSubmit={this.onSubmit}>
                        <Form.Group as={Col}>
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            required
                            type="email"
                            name="email"
                            id="email"
                            value={this.state.email}
                            onChange={this.onChange}
                          ></Form.Control>
                          <div
                            style={{ color: "red" }}
                            className="animationMode"
                          >
                            {this.state.hasErrors}
                          </div>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            required
                            type="password"
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.onChange}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicCheckbox" as={Col}>
                          <Form.Check
                            name="rememberMe"
                            checked={this.state.rememberMe}
                            type="checkbox"
                            onChange={this.onChange}
                            label="Remember Me"
                          />
                        </Form.Group>

                        <Col className="text-right">
                          <Link to={"/en/forgot-password"} className="font600">
                            Forgot Password?
                          </Link>
                        </Col>
                        <Form.Group controlId="formBasicCheckbox" as={Col}>
                          <div className="capchaCode">
                            <div
                              className="g-recaptcha"
                              data-sitekey="6LcjIsQUAAAAAPYjdLFm1hP-EgdGAJFQrUxmRGkc"
                            ></div>
                          </div>
                          <Button
                            variant="btn btn-blue w-100 mt-4 mb-4 shadow"
                            type="submit"
                            size="lg"
                          >
                            Sign In
                          </Button>

                          <div className="registration-terms">
                            <p>
                              By SigninIndexg in you agree to our{" "}
                              <Link to="terms-of-use" className="font600">
                                Terms of use
                              </Link>
                              ,{" "}
                              <Link to="privacy-policy" className="font600">
                                Privacy Policy
                              </Link>
                              .
                            </p>
                            <p>
                              For Account/Login Help and Support: Contact your
                              dedicated account manager or email us at
                              info@analyzemarkets.com
                            </p>

                            {/* {this.state.results && <Redirect to={"/Home"} />} */}
                          </div>
                        </Form.Group>
                      </Form>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Fragment>
      </div>
    );
  }
}
export default SigninIndex;
