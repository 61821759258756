import React, { Component, Fragment } from "react";
import Followus from "../../component/global/followus/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import Audiopthumb from "../../component/global/audio-thumb";
import Newslettersubs from "../../component/global/newsletter-subscription/index";
import { Container, Row, Col } from "react-bootstrap/";
import NewsletterThumb from "../../assets/images/newsletter.jpg";
import NewsletterDaily from "../../assets/images/daily-brief.jpg";
import NewsletterWeekly from "../../assets/images/weekly-brief.jpg";
import "./newsletters.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import Header from "../../component/global/header/index";

class Newsletters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      podcast: [],
      pageSize: 25,
      count: 0,
      exampleItems: [],
      weekly: false,
      daily: false,
      result: "",
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    console.log(e.target.name, e.target.checked);
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  handleFilter = (email) => {
    console.log(email);

    if (email) {
      var bodyFormData = new FormData();

      bodyFormData.set("weekly", this.state.weekly);
      bodyFormData.set("daily", this.state.daily);
      bodyFormData.set("email", email);

      axios
        .post("https://api.analyzemarkets.com/subscribe_news/", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res, "result status");
          if (res.status === 204) {
            console.log(30);
            this.setState({
              result: (
                <div class="subsbription_section_alt pt-5">
                  <hr />
                  <i class="fa fa-check btext"></i> You Are already subscribed!
                </div>
              ),
            });
          } else {
            this.setState({
              result: (
                <div class="subsbription_section_alt pt-5">
                  <hr />
                  <i class="fa fa-check btext"></i> Thank you for subscribing.
                  Please check your inbox and confirm email address
                </div>
              ),
            });
          }
        })
        .catch((error) => {
          //on error
          // alert("There is an error in API call.");
        });
    } else {
      alert("Please Fill Email Address1");
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email1 = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email1, rememberMe, response, token, action });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <Container>
          <Helmet>
            <title>AnalyzeMarkets - Newsletters</title>
            <meta
              name="description"
              content="Subscribe to our daily & weekly briefing with the biggest and important investment intelligence, delivered directly to your inbox"
            />
            <meta property="og:title" content="AnalyzeMarkets - Newsletters" />
            <meta
              property="og:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta
              name="og:description"
              content="Subscribe to our daily & weekly briefing with the biggest and important investment intelligence, delivered directly to your inbox"
            />
            <meta name="og:url" content="https://www.analyzemarkets.com" />
            <meta name="og:site_name" content="AnalyzeMarkets" />
            <meta name="og:locale" content="en" />
            <meta name="og:type" content="website" />

            <meta
              name="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
            <meta itemprop="name" content="AnalyzeMarkets - Newsletters" />

            <meta
              itemprop="description"
              content="Subscribe to our daily & weekly briefing with the biggest and important investment intelligence, delivered directly to your inbox"
            />
            <meta
              itemprop="image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />

            <meta name="twitter:card" content="summary_large_image" />

            <meta name="twitter:title" content="AnalyzeMarkets - Newsletters" />

            <meta
              name="twitter:description"
              content="Subscribe to our daily & weekly briefing with the biggest and important investment intelligence, delivered directly to your inbox"
            />

            <meta name="twitter:site" content="@AnalyzeMarkets" />
            <meta name="twitter:creator" content="@AnalyzeMarkets" />
            <meta
              name="twitter:image"
              content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
            />
          </Helmet>

          <Row>
            <Col className="w-70p">
              <hr />
              <h2 className="text-uppercase mb-4">Newsletters</h2>
            </Col>
          </Row>
          <Row>
            <Col className="w-70p">
              <article className="mb-2">
                <div className="two-item align-items-lg-center">
                  <div className="two-image-holder">
                    <img src={NewsletterDaily} className="img-fluid" alt="" />
                  </div>
                  <div className="two-content-holder">
                    <div className="d-flex">
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-4"
                        type="checkbox"
                        checked={this.state.daily}
                        onChange={this.handleChange}
                        name="daily"
                      />
                      <label htmlFor="styled-checkbox-4"></label>
                      <div>
                        <h5>Daily Global Investment Intelligence Brief</h5>
                        <p>
                          A daily briefing with the biggest and important
                          investment intelligence of the day, delivered directly
                          to your inbox
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <hr />
              <article className="mb-2">
                <div className="two-item align-items-lg-center">
                  <div className="two-image-holder">
                    <img src={NewsletterWeekly} className="img-fluid" alt="" />
                  </div>
                  <div className="two-content-holder">
                    <div className="d-flex">
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-5"
                        type="checkbox"
                        checked={this.state.weekly}
                        onChange={this.handleChange}
                        name="weekly"
                      />
                      <label htmlFor="styled-checkbox-5"></label>
                      <div>
                        <h5>Weekly Global Investment Intelligence Brief</h5>
                        <p>
                          A weekly briefing with the biggest and important
                          investment intelligence of the week, delivered
                          directly to your inbox
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              {this.state.result === "" && (
                <Newslettersubs applyFilter={this.handleFilter} />
              )}
              <article>
                <div className="text-center">{this.state.result}</div>
              </article>
            </Col>
            <Col className="w-30p">
              <div className="widget pts-2">
                <Followus />
              </div>
              <div className="widget">
                <Downloadapp />
              </div>
              <div className="widget">
                <SubscriberAccsss />
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Newsletters;
