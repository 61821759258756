import React, { Component, Fragment, useLayoutEffect } from "react";
import Freesubs from "../../component/global/free-subscription/subscription";
import CompanyDetails from "../../component/global/company-details/company";
import Followus from "../../component/global/Followus_wire/followus";
import Downloadapp from "../../component/global/download-app/downloadapp";
import SubscriberAccsss from "../../component/global/subscribers-access/subscribers-access";
import Newspost from "../../component/global/news-post/index";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import Signin from "../../pages/signin/signinIndex";

// import './news-details.css';
import axios from "axios";
import Moment from "react-moment";
import ReactAudioPlayer from "react-audio-player";
import WeeklyInfo from "../../component/global/weekly-info/weekly-info";

import { setupCache } from "axios-cache-adapter";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import { FacebookProvider, ShareButton } from "react-facebook";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import { Helmet } from "react-helmet";
// import renderHTML from 'react-render-html';

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

function getFormatted(stringData){
  let formattedString = ''
  if(stringData){
    if(stringData.length > 1){
      let temp = stringData
      for(let i=0;i< temp.length -1;i++){
        formattedString+= temp[i]+', ';
      }
      formattedString+= temp[temp.length-1]
    }
    else
      formattedString = stringData[0];
  }
  return formattedString
}

class GiwDetail_Index extends Component {
  state = {
    item: [],
    items: [],
    wire: [],
    news: {
      global_investment_management: [],
      global_investment_wire_management: [],
      giim_tableview: [],
    },
    first_row: [],
    action: "",
    token: "",
    response: "",
    second_row: [],
    selected: "custom_date",
    show_100: false,
    dataLoaded: false,
    title: "",
    det: [],
    id: [],
    category: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    console.log(this.props.match.params.name, 444);
    window.scrollTo(0, 0);
    // this.getRepos();
    api({
      url:
        "https://api.analyzemarkets.com/giwmsingle/" +
        this.props.match.params.name +
        "/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        news: res.data,
        wire: res.data.wire_provider,
        det: res.data.category,

        show_100: true,
      });
      console.log(this.state.det.length, "category");
      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });

    api({
      url: "https://api.analyzemarkets.com/category/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, 63333);
      this.setState({
        category: res.data.results,
        show_100: true,
      });
      console.log(this.state.id);
    });
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");
    const response = localStorage.getItem("response");
    const user = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    console.log('token: ', token);
    this.setState({ email, rememberMe, response, token, action });
  };

  // componentWillReceiveProps=(nextProps)=>{

  //     var current_route = nextProps.match.params.name;
  //     window.scrollTo(0, 0);
  //     this.setState({
  //       dataLoaded:false
  //     })

  //     api({
  //       url: 'https://api.analyzemarkets.com/giimsingle/' + current_route + '/',
  //       method: 'get'
  //     }).then(async (res) => {
  //       // Do something fantastic with response.data \o/
  //       console.log('Request response:', res)
  //       if(res.data.single_news.global_investment_management.length===0){
  //                 console.log(34,'0----');
  //                 this.setState({show_100: true});
  //             }
  //             this.setState({news: res.data.single_news,
  //                 first_row: res.data.read_more_first,
  //                 second_row: res.data.read_more_second,
  //                 dataLoaded:true
  //             });

  //       // Interacting with the store, see `localForage` API.
  //       const length = await cache.store.length()

  //       console.log('Cache store length:', length)
  //     })
  // }

  render() {
    const data = this.state.news;
    const ent = this.state.items;
    const name = this.state.title;
    var i = 1;
    const url = "https://www.analyzemarkets.com/en/wire/" + data.url;
    return (
      <div>
        {this.state.action && this.state.response === this.state.email ? (
          <Fragment>
            <Header></Header>
            {
              this.state.token != null
              ? <Navfilter id="sector-desktop"></Navfilter>
              : <div></div>
            }

            <Container>
              <Helmet>
                <title>{data.title}</title>
                <meta name="description" content={data.second_para} />
                <meta property="og:title" content={data.title} />
                <meta
                  property="og:image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta property="og:description" content={data.second_para} />
                <meta property="og:url" content={url} />
                <meta property="og:site_name" content="AnalyzeMarkets" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta
                  name="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta
                  itemprop="image"
                  content="https://www.analyzemarkets.com/static/media/logo.42312e13.svg"
                />
                <meta itemprop="name" content={data.title} />
                <meta itemprop="description" content={data.second_para} />

                <meta name="twitter:card" content="summary_large_image" />

                <meta name="twitter:title" content={data.title} />

                <meta name="twitter:description" content={data.second_para} />

                <meta name="twitter:site" content="@AnalyzeMarkets" />
                <meta name="twitter:creator" content="@AnalyzeMarkets" />
                <meta name="twitter:image" content={data.image} />
              </Helmet>

              <Row>
                <Col className="w-70p">
                  <hr className="mb-1" />
                  <Row>
                    <Col sm={6}>
                      {" "}
                      <b className="block-date">
                        <Moment format="DD-MMM-YYYY">{data.created_at}</Moment>
                      </b>
                    </Col>
                    <Col sm={6}>
                      <span className="floatRight">
                        {" "}
                        {"Category: " + getFormatted(data.category)}
                      </span>
                    </Col>
                  </Row>
                  <Row className="companyname newstagd"></Row>
                  <span className="newstagd mb-3 d-block">
                    {" "}
                    {data.news_country_category_sector_details}
                  </span>
                  <h1 className="h2">{data.title}</h1>
                  {data.tagline == "" || data.tagline == null ? "" : <hr />}
                  <p className="font-18">
                    {" "}
                    <b>
                      {data.tagline != null || data.tagline != ""
                        ? data.tagline
                        : ""}
                    </b>
                  </p>
                  <hr />
                  <Row>
                    <Col
                      lg="7"
                      className={
                        "font18 " + (this.state.show_100 ? "wdt100p" : "")
                      }
                    >
                      <p
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                      <p
                        dangerouslySetInnerHTML={{ __html: data.information }}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between align-items-end mt-5 flex-wrap">
                    <p className="m-0 floatHide">© {new Date().getFullYear()} AnalyzeMarkets ltd</p>
                    {/* <div className="floatRight">
                      <h6 className="font600">Share:</h6>
                      <ul className="social smalls">
                        <li>
                          <TwitterShareButton
                            url={
                              "https://www.analyzemarkets.com/en/intelligence/" +
                              data.url
                            }
                          >
                            <Button
                              id="https://www.twitter.com/me/"
                              title="Twitter"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </TwitterShareButton>
                        </li>
                        <li>
                          <LinkedinShareButton
                            url={
                              "https://www.analyzemarkets.com/en/wire/" +
                              data.url
                            }
                          >
                            <Button
                              id="https://www.linkedin.com/me/"
                              title="Linkedin"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </LinkedinShareButton>
                        </li>
                        <li>
                          <FacebookShareButton
                            url={
                              "https://www.analyzemarkets.com/en/wire/" +
                              data.url
                            }
                            quote={"Facebook"}
                            className="share"
                          >
                            <Button
                              id="https://www.facebook.com/me/"
                              title="Facebook"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </FacebookShareButton>
                        </li>
                        {/* <li>
                                    <Button id="https://www.instagram.com/" title="Instagram" variant="">&nbsp;</Button>
                                </li>                         <li>
                          <WhatsappShareButton
                            url={
                              "https://www.analyzemarkets.com/en/wire/" +
                              data.url
                            }
                          >
                            <Button
                              id="https://web.whatsapp.com/"
                              title="Whatsapp"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </WhatsappShareButton>
                        </li>
                        <li>
                          <EmailShareButton
                            url={
                              "https://www.analyzemarkets.com/en/wire/" +
                              data.url
                            }
                            subject={data.title}
                            body={data.description}
                          >
                            <Button
                              id="https://envelope.com/"
                              title="Message"
                              variant=""
                            >
                              &nbsp;
                            </Button>
                          </EmailShareButton>
                        </li>
                      </ul>
                    </div>
                      */}
                    <p className="m-0 floatShow">© {new Date().getFullYear()} AnalyzeMarkets ltd</p>
                  </div>
                </Col>
                <Col className="w-30p">
                  <div className="widget mt-18">
                    <WeeklyInfo />
                  </div>

                  {/* <div className="widget">
                    <Followus />  </div>{" "}
                    */}
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <Signin />
        )}
      </div>
    );
  }
}

export default GiwDetail_Index;
