import React, { Component, Fragment, useState } from "react";
import { Row, Table, Col, Container, Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import "../create-deal/create-deal.css";
import Header from "../../component/global/headerAfterLogin/indexAfterLogin";
import Navfilter from "../../component/global/NavFilterAfterLogin/NavFilterDealsDBMain";
import Autocomplete from "react-autocomplete";
import axios, { post } from "axios";
import "../create-deal/create-deal.css";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Popup from "reactjs-popup";
import { setupCache } from "axios-cache-adapter";
import Iws from "../../component/global/investment-wire-screener/ui-lookup-search";

import {
  matchSubIndustry,
  matchCountry,
  matchSector,
  matchCompany,
  matchStatus,
  matchDeal,
  matchDealHeadline,
  matchRegion,
  matchGeography,
} from "../../component/global/investment-intellegence-screener/data";
import Pagination from "../../component/global/pagination/index";
import $ from "jquery";
const animatedComponents = makeAnimated();
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class UiLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: [],
      items: [],
      country: [],
      country_name: "",
      company: [],
      emailfrequency: "",
      signinlink: localStorage.getItem("id"),
      show: false,
      alerts: [],
      setShow: false,
      companys: [],
      companyand_data: "",
      result: false,
      is_filter: false,

      comapany_name: "",
      geography_name: "",
      briefdescriptionproject: "",
      projecttimelinedays: "",
      file: "",
      name: "",
      id: "",
      email: "",
      contactnumber: "",
      user: "",
      token: "",
      posted_by: "",
      exampleItems: [],
      selections: [],
      page: 0,
      current: 0,
      filter: "",
      old_route: "",
      pageSize: 25,
      count: 0,
      is_first: true,
      token: "",
      response: "",
      user: "",
      count: "",
    };
    this.changeTerm = this.changeTerm.bind(this);
    this.handleFilter = this.handleFilter.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.changeTermMultiSelect = this.changeTermMultiSelect.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeTermMultiSelects = this.changeTermMultiSelects.bind(this);
    this.handleState = this.handleState.bind(this);
    this.refreshButton = this.refreshButton.bind(this);
  }
  handleDropdownChange(e) {
    this.setState({
      pageSize: e.target.value,
    });
  }
  handleShow(id) {
    this.setState({
      comapany_name: id,
    });
  }

  handleClose() {
    this.setState({
      setShow: false,
    });
  }

  onChangePage = (pageOfItems, page) => {
    console.log(47, page, pageOfItems);
    this.setState({
      page: page,
      current: page - 1,
    });
    if (page != this.state.current) {
      this.getData("", this.state.filter, page, false);
      console.log(55);
    }
  };
  handleFilter = (filter) => {
    console.log(filter, 31);
    this.setState({
      filter: filter,
      is_filter: true,
    });
    this.getData(this.state.old_route, filter, 0, true);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    var favorite = [];

    var current_route = "";
    if (current_route == undefined) {
      current_route = "";
    }
    this.setState({
      old_route: current_route,
    });
    this.getData(current_route, "", 1, true);

    axios
      .get("https://api.analyzemarkets.com/regman/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ region: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    axios
      .get("https://api.analyzemarkets.com/companymanagementprivate/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ companys: res.data.result });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });

    axios
      .get("https://api.analyzemarkets.com/companymanagementprivate/")
      .then((res) => {
        let tmpArray = [];
        for (var i = 0; i < res.data.result.length; i++) {
          tmpArray.push({
            label: res.data.result[i].name,
            value: res.data.result[i].name,
          });
        }
        console.log(tmpArray, "tmArray");
        this.setState({
          company: tmpArray,
        });
        console.log(this.state.company, "tmArray");
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    // api({
    //   url: "https://api.analyzemarkets.com/companymanagementprivate/",
    //   method: "get",
    // }).then((res) => {
    //   // Here you need to use an temporary array to store NeededInfo only
    //   let tmpArray = [];
    //   for (var i = 0; i < res.data.results.length; i++) {
    //     tmpArray.push({
    //       label: res.data.results[i].country,
    //       value: res.data.results[i].country,
    //     });
    //   }
    //   //console.log(tmpArray, "tmArray");
    //   this.setState({
    //     country: tmpArray,
    //   });
    //   //console.log(this.state.country, "tmArray");
    // });

    axios
      .get("https://api.analyzemarkets.com/country/")
      .then((res) => {
        console.log(res.data, 23);
        this.setState({ country: res.data.results });
      })
      .catch((error) => {
        //on error
        console.log("Error !");
      });
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    this.setState({
      user,
      token,
    });
  }
  getData = (route, filter, page, is_first) => {
    api({
      url:
        "https://api.analyzemarkets.com/companymanagementprivate/?name=" +
        route +
        "&start=" +
        page +
        "" +
        filter,

      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log(res.data, "Company Data");
      this.setState({
        items: res.data.result,
        page: res.data.count,
        current: page,
        is_first: false,
      });
      if (is_first) {
        this.setState({
          exampleItems: [...Array(res.data.count).keys()].map((i) => ({
            id: i + 1,
            name: "Item " + (i + 1),
          })),
          count: res.data.count,
        });
      }
      console.log(res.data.length + "Count Data");
    });
  };

  changeTerm(event) {
    const name = event.target.name;
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  }
  handleState = () => {
    this.setState({
      result: false,
    });
  };

  changeTermMultiSelects(event) {
    const array1 = [];
    const array2 = [];
    const value = event.target.value;

    const length = [event.target.value].length;
    const checkeds = document.getElementsByName("selections");
    const checkedsalert = document.getElementsByName("alerts");

    for (let i = 0; i < checkeds.length; i++) {
      if (checkeds[i].checked) {
        array1.push(checkeds[i].value);
      }
    }
    for (let i = 0; i < checkedsalert.length; i++) {
      if (checkedsalert[i].checked) {
        array2.push(checkedsalert[i].value);
      }
    }
    // console.log(length, "length");
    // for (let i = 0; i < length; i++) {
    //   array1.push(value);
    // }
    this.setState({
      selections: array1,
      alerts: array2,
      sector_data: null,
    });
    console.log(this.state.selections);
    console.log(this.state.alerts);
  }
  changeTermMultiSelect = (comapany_names) => {
    const state = this.state;
    state.comapany_name = [];
    if (comapany_names !== null) {
      comapany_names.forEach((option) => {
        state.comapany_name.push(option.label);
      });
    }
    //const sub = "";
    //this.state.sub = state.companysubindustry;
    this.setState({
      comapany_name: state.comapany_name,
      sector_data: null,
    });
    console.log(this.state.comapany_name);
  };

  handleChange(event) {
    this.setState({ file: event.target.files });

    // const value = event.target.value;
    // const name = event.target.name;
    // const id = event.target.id;
    // const formData = new FormData();

    // const len = event.target.files.length;
    // const fileData = event.target.files;
    // const blob = new Blob([fileData]);
    // const reader = new FileReader();
    // var data_Array;
    // for (let i = 0; i < fileData.length; i++) {
    //   this.setState({
    //     [name]: fileData[i],
    //   });
    //   //console.log(fileData[i]);
    // }
    // console.log(formData.getAll("file"));
    // reader.readAsDataURL(blob);
  }
  // this.setState({
  //   [name]: event.target.files[0],
  //   file_teaser: event.target.files.name,
  //   file_other: event.target.files.name,
  // });

  //.log("Selected file:", event.target.files[0]);

  refreshButton = () => {
    this.setState({
      country_name: "",
      geography_name: "",
      briefdescriptionproject: "",
      projecttimelinedays: "",
      file: "",
      name: "",
      email: "",
      contactnumber: "",
      posted_by: "",
    });
  };

  render() {
    const { show, setShow } = this.state;

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return (
      <Fragment>
        <Container>
          <div className="">
            <Row>
              <Col>
                <Iws applyFilter={this.handleFilter} />
              </Col>
            </Row>

            <Table size="sm" responsive="lg" bordered striped>
              <thead>
                <tr>
                  <th className="colorWhite">Company Name</th>
                  <th className="colorWhite">Country</th>
                  <th className="colorWhite">Sector</th>
                </tr>
              </thead>
              <tbody>
                {this.state.items.map((item, i) => (
                  <tr>
                    <td>
                      <Link
                        className="list-group-item"
                        key={i}
                        onClick={() => this.handleShow(item.name)}
                      >
                        {" "}
                        {item.name}{" "}
                      </Link>{" "}
                    </td>
                    <td>{item.country}</td>
                    <td>{item.sector}</td>
                  </tr>
                ))}
                {console.log(this.state.comapany_name, "company_name")}
              </tbody>
            </Table>
            <div className="justify-content-between mt-4">
              {/* {this.state.count > 10 && (
                <div className="view-options__control">
                  <label>Results Per Page</label>
                  <div>
                    <select onChange={this.handleDropdownChange}>
                      <option value="10">10</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              )}*/}
              <Pagination
                items={this.state.exampleItems}
                onChangePage={this.onChangePage}
                pageSize={this.state.pageSize}
              ></Pagination>
            </div>
          </div>
        </Container>
      </Fragment>
    );
  }
}
export default UiLookup;
