import React, { Component } from "react";
import Logo from "../logo/logoAfterLogin";
import Topnav from "../topNavAfterLogin/topnavafterlogin";
import Language from "../language/language";
import Search from "../searchtopAfterLogin/searchlogin";
import Usernav from "../usernav/usernavAfterLogin";
import "../header/header.css";

class HeaderAfterLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onLoad: false,
    };
  }

  render() {
    return (
      <header>
        <div className="top--bar">
          <div className="container">
            <div className="d-flex align-items-center justify-content-end">
              <Topnav />

              <ul className="navbar-nav mr-lg-2">
                <li className="nav-item nav-lang d-lg-block">
                  <Search />
                </li>
              </ul>

              <ul className="navbar-nav ml-auto">
                <li className="nav-item d-lg-block">
                  <Usernav />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Logo />
      </header>
    );
  }
}

export default HeaderAfterLogin;
