import React, { Component } from "react";
import { Link, BroserRouter } from "react-router-dom";
import "../NavFilterAfterLogin/navfilterafterLogin.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";

import {
  Button,
  Nav,
  Navbar,
  NavDropdown,
  MenuItem,
  NavItem,
} from "react-bootstrap";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

class NavfilterLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: "",
      region: [1, 2, 3, 4, 5],
      category: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      regionActive: null,
      sectorActive: null,
      is_first: true,
      is_second: false,
      is_third: false,
      token: "",
      is_fourth: false,
      is_fifth: true,
      menu: false,
      url: "",
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.sectorActive = this.sectorActive.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleToggle1 = this.handleToggle1.bind(this);
    this.handleToggle2 = this.handleToggle2.bind(this);
  }

  handleClick = (item) => {
    console.log(item, 22);
    this.setState({ regionActive: "region" + item.id });
  };
  handleToggle2 = () => {
    this.setState({
      is_fourth: !this.state.is_fourth,
      is_second: false,
      is_third: false,
      is_fifth: false,
    });
  };

  handleToggle3 = () => {
    this.setState({
      is_second: false,
      is_fourth: false,
      is_third: false,
      is_fifth: !this.state.is_fifth,
    });
  };
  handleToggle1 = () => {
    this.setState({
      is_third: !this.state.is_third,
      menu: false,
      is_second: false,
      is_fourth: false,
      is_fifth: false,
    });
  };

  handleToggle = () => {
    this.setState({
      is_second: !this.state.is_second,
      menu: true,
      is_fourth: false,
      is_third: false,
    });
  };
  sectorActive = (id) => {
    console.log(id, 27);
    this.setState({ sectorActive: "category" + id });
  };

  componentDidMount = () => {
    api({
      url: "https://api.analyzemarkets.com/navfilter/",
      method: "get",
    }).then(async (res) => {
      // Do something fantastic with response.data \o/
      console.log("Request response Nav Filter:", res);
      this.setState({
        category: res.data.category,
        region: res.data.region,
        is_first: false,
      });
      // Interacting with the store, see `localForage` API.
      const length = await cache.store.length();

      console.log("Cache store length:", length);
    });
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = localStorage.getItem("email");

    const response = localStorage.getItem("response");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const action = localStorage.getItem("action");
    this.setState({ email, rememberMe, response, token, user, action });
  };

  handleDropdownChange(type, e) {
    console.log(JSON.parse(e.target.value), type);
    var data = JSON.parse(e.target.value);
    console.log(data);
    this.props.greetChild(data, type);
  }
  render() {
    var greetChild = this.props.greetChild;

    return (
      <div className=" mt-1 mb-5 ">
        {this.state.is_first && (
          <nav className="fn-nav d-none d-lg-block">
            <ul>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        )}
        {!this.state.is_first && (
          <nav className="fn-nav d-none d-lg-block">
            <ul>
              <li>
                <Link
                  to={"/Home/"}
                  onClick={this.handleToggle3}
                  id="home"
                  className="uppercase active1"
                >
                  <i class="fa fa-home blueIcon"></i>
                </Link>
              </li>

              <li>
                <Link
                  to={"/en/m_a_deals/"}
                  onClick={this.handleToggle}
                  className={("region", "uppercase")}
                >
                  Global Deals Database
                </Link>
              </li>

              <li>
                <Link to="#">Global Company Database</Link>
              </li>

              <li>
                <Link
                  to="#"
                  onClick={this.handleToggle1}
                  className={("region", "uppercase")}
                >
                  Global Deals MarketPlace
                </Link>
              </li>

              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Global Investors Database
                </Link>
              </li>

              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Global Research Report Database
                </Link>
              </li>

              <li>
                <Link
                  to={"/en/gross-professional-database/"}
                  className={("region", "uppercase")}
                >
                  Global Professional Database
                </Link>
              </li>

              <li>
                <Link
                  to={"/en/on-demand_research/"}
                  className={("region", "uppercase")}
                >
                  ON-Demand Research And Analysis
                </Link>
              </li>
              <li>
                <Link
                  to="#4"
                  onClick={this.handleToggle2}
                  className={("region", "uppercase")}
                >
                  Manage Alerts
                </Link>
              </li>
            </ul>
          </nav>
        )}

        {this.state.is_first && (
          <nav className="fn-nav d-none d-lg-block">
            <ul>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ph-item w-100px mb-0">
                  <div className="ph-col-12">
                    <div className="ph-row mb-0">
                      <div className="ph-col-12 filterph"></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        )}
        <nav className="fn-nav d-none d-lg-block">
          {this.state.is_second ? (
            <ul id="test" className=" bordersBottom">
              <li>
                <Link to={"/en/m_a_deals/"} className="uppercase active">
                  M&A Deals
                </Link>
              </li>

              <li>
                <Link to={"/en/vc-deals/"} className={("region", "uppercase")}>
                  Venture Capital Deals
                </Link>
              </li>

              <li>
                <Link
                  to={"/en/real-estate-deals/"}
                  className={("region", "uppercase")}
                >
                  Real Estate Deals
                </Link>
              </li>

              <li>
                <Link to={"/en/jv-deals"} className={("region", "uppercase")}>
                  Join Venture Deals
                </Link>
              </li>
              <li>
                <Link
                  to={"/en/ipo-monitor/"}
                  className={("region", "uppercase")}
                >
                  Ipo Monitor
                </Link>
              </li>

              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Debt Deals
                </Link>
              </li>

              <li>
                <Link to="#" className={("region", "uppercase")}>
                  FundRaising Deals
                </Link>
              </li>

              <li>
                <Link
                  to={"/en/fund-raising-deals/"}
                  className={("region", "uppercase")}
                >
                  FundRaising Deals
                </Link>
              </li>
            </ul>
          ) : (
            ""
          )}
        </nav>

        <nav className="fn-nav d-none d-lg-block">
          {this.state.is_third ? (
            <ul id="test" className="">
              <li>
                <Link to={"/en/m_a_deals/"} className="uppercase active1">
                  M&A Deals
                </Link>
              </li>
              <li>
                <Link
                  to={"/en/real-estate-deals/"}
                  className={("region", "uppercase")}
                >
                  Real Estate Deals
                </Link>
              </li>

              <li>
                <Link
                  to={"/en/debt-deals/"}
                  className={("region", "uppercase")}
                >
                  Debt Deals
                </Link>
              </li>
              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Startup/Venture Capital Deals
                </Link>
              </li>

              <li>
                <Link to="#" className={("region", "uppercase")}>
                  FundRaising Deals
                </Link>
              </li>

              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Join Venture Deals
                </Link>
              </li>
            </ul>
          ) : (
            ""
          )}
        </nav>

        <nav className="fn-nav d-none d-lg-block">
          {this.state.is_fourth && (
            <ul id="3" className="bordersBottom">
              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Global Investment Intelligence
                </Link>
              </li>
              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Global Investment Wire
                </Link>
              </li>
              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Global Deals Database
                </Link>
              </li>
              <li>
                <Link
                  to={"/en/company-database/"}
                  className={("region", "uppercase")}
                >
                  Global Company Database
                </Link>
              </li>
              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Global Research Reports
                </Link>
              </li>
              <li>
                <Link to="#" className={("region", "uppercase")}>
                  Global Deals MarketPlace
                </Link>
              </li>
            </ul>
          )}
        </nav>

        <nav className="fn-nav d-none d-lg-block">
          {this.state.is_fifth && (
            <ul id="4" className="bordersBottom">
              <li>
                <Link to={"/en/global-deals-database/"} className="active1">
                  What's New! Global Deals Database
                </Link>
              </li>
              <li>
                <Link to="#">What's New! Global Deals Marketplace</Link>
              </li>
            </ul>
          )}
        </nav>
        {/* <div className="fn-nav d-block d-lg-none sector-mobile">
          <select
            className="browser-default custom-select"
            id="dropdown"
            onChange={e => this.handleDropdownChange("region", e)}
          >
            <option value="">Select Region</option>
            {this.state.region.map((item, i) => (
              <option key={i} value={JSON.stringify(item)}>
                {item.region}
              </option>
            ))}
          </select>
        </div>
        <div className="fn-nav d-block d-lg-none sector-mobile"> 
          <select
            className="browser-default custom-select"
            id="dropdown"
            onChange={e => this.handleDropdownChange("category", e)}
          >
            <option value="">Select Category</option>
            {this.state.category.map((item, i) => (
              <option key={i} value={JSON.stringify(item)}>
                {item.name}
              </option>
            ))}
          </select>
        </div>*/}
      </div>
    );
  }
}

export default NavfilterLogin;
